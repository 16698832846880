import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';
import { Col, Row } from 'reactstrap';
import { setAuthError } from '@JS/actions/userActions';
import { processIDPAuthRequest } from '@API/AuthAPI/AuthAPI';
import LoadingScreen from '@Base/Loading/LoadingScreen';

function IDPAuthResponse() {
  const { search } = useLocation();
  // eslint-disable-next-line camelcase
  const { code, state, session_state } = queryString.parse(search);

  async function processIDPAuth() {
    await processIDPAuthRequest(code, state, session_state);

    window.close();
  }

  useEffect(() => {
    processIDPAuth();
  }, []);

  return (
    <div className="app flex-row align-items-center auth-page">
      <Row>
        <Col>
          <LoadingScreen />
        </Col>
      </Row>
    </div>
  );
}
IDPAuthResponse.propTypes = {};

IDPAuthResponse.defaultProps = {};

function mapStateToProps(state) {
  return { authError: state.userData.userAuth.authError, failedAuthCount: state.userData.failedAuthCount };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatchAuthError: (error) => {
      dispatch(setAuthError(error));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(IDPAuthResponse);
