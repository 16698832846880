/* eslint-disable no-nested-ternary */
import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { Button, Card, CardBody, Col, Row } from 'reactstrap';
import moment from 'moment';
import { toast } from 'react-toastify';
import { useLanguagePack, useMounted, useOutsideClick } from '@Base/hooks';
import { EmailForm } from '../../Communication';
import RightToWorkRequestedContainer from './RightToWorkRequestedContainer';
import { sendRTWReminder } from '@API/RTWAPI/RTWAPI';
import { retryableAPICall } from '@API/common-api-utils';
import { LoadingScreen } from '@Base/Loading';
import RightToWorkCompletedContainer from './RightToWorkCompletedContainer';
import { downloadFiles } from '@API/FileAPI/FileAPI';
import { HeadingPushRight } from '@Base/Headings';
import { getRTWCheckData } from './utils';
import { ActionButton, IconButton } from '@Base/Buttons';
import ActionToBePerformedNoDataState from '../Other/ActionToBePerformedNoDataState';
import idchecknodata from '../../../assets/img/nodata/idchecknodata2.png';
import { checkPermissions } from '@JS/auth/AuthUtils';
import { IconSVG } from '@Base/SVG';

function RightToWorkDetails({ summary, updateSummary, candidate, isTouch, onSuccess, onError }) {
  const languagePack = useLanguagePack('rtw-details');
  const [rtwStatus, setRtwStatus] = useState(undefined);
  const [rtwDetails, setRtwDetails] = useState(undefined);
  const [requestingDetails] = useState(false);
  const [rtwReqInfoExpanded, setRtwReqInfoExpanded] = useState(false);
  const [loading, setLoading] = useState(false);
  const [downloadingPDF, setDownloadingPDF] = useState(false);
  const [reminderSending, setReminderSending] = useState(false);
  const [isExpired, setIsExpired] = useState(false);
  const [isActionOpen, setIsActionOpen] = useState(false);
  const wrapperRef = useRef();

  useOutsideClick(
    wrapperRef,
    () => {
      setIsActionOpen(false);
    },
    '.slide-pane.candidate-slider',
  );

  const isMounted = useMounted();

  const { status } = summary;

  useEffect(() => {
    if (isMounted()) {
      if (status) {
        setLoading(true);
        setRtwStatus(status);

        getData()
      } else {
        setRtwStatus('NOT_REQUESTED');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [candidate.id, isMounted, onError, status]);

  function getData() {
    getRTWCheckData(
      candidate.id,
      (resp) => {
        setRtwDetails(resp);
        setIsExpired(moment(resp.expiryDate, 'YYYY-MM-DD').isBefore(moment(), 'day'));
        setLoading(false);
      },
      () => {
        setLoading(false);
        onError(languagePack.rtwLoadError || 'Could not load RTW information please try again later');
      },
    );
  }
  function handleError() {
    onError(languagePack.rtwReqError);
  }

  function handleRTWSubmissionSuccess(message, rtwResponse) {
    setRtwReqInfoExpanded(!rtwReqInfoExpanded);
    setRtwDetails(rtwResponse);
    updateSummary(rtwResponse.status);
    setRtwStatus(rtwResponse.status);
    onSuccess(languagePack.rtwReqSuccess);
  }

  async function downloadPDF() {
    setDownloadingPDF(true);
    await downloadFiles([rtwDetails.documentCheckResult.summaryPDFFileId], {
      fileNames: {
        [rtwDetails.documentCheckResult.summaryPDFFileId]:
          `${candidate?.applicantProfile?.personalDetails?.formattedName} - RTW Summary.pdf`,
      },
    });
    setDownloadingPDF(false);
  }

  async function sendReminder() {
    setReminderSending(true);
    const result = await retryableAPICall(() => sendRTWReminder(candidate.id));
    if (typeof result !== 'string') {
      onSuccess(languagePack.rtwSuccessfullySentReminderMessage);
      updateSummary(result.status);
    } else {
      onError(languagePack.rtwErrorSendingReminderMessage);
    }
    setReminderSending(false);
  }

  const menuItems = [];

  if (rtwStatus === 'RECEIVED') {
    menuItems.push({
      action: async () => {
        downloadPDF();
        setIsActionOpen(false);
      },
      icon: 'File',
      id: 3,
      isDisabled: downloadingPDF,
      label: downloadingPDF ? languagePack.rtwSummaryDlInProgressBtn : languagePack.rtwSummaryDlBtn,
      permissions: ['rtw:create'],
    });
  }

  if (rtwStatus === 'NOT_REQUESTED') {
    menuItems.push({
      action: async () => {
        setRtwReqInfoExpanded(!rtwReqInfoExpanded);
        setIsActionOpen(false);
      },
      icon: 'Paper Plane',
      id: 2,
      label: requestingDetails ? languagePack.rtwRequestingLabel : languagePack.rtwReqInfoBtn,
      permissions: ['rtw:create'],
    });
  } else if (isExpired) {
    menuItems.push({
      action: async () => {
        setRtwReqInfoExpanded(!rtwReqInfoExpanded);
        setIsActionOpen(false);
      },
      icon: 'Paper Plane',
      id: 2,
      label: requestingDetails ? languagePack.rtwRequestingLabel : languagePack.rtwReRequestBtnLabel,
      permissions: ['rtw:create'],
    });
  } else if (rtwDetails?.documentCheckResult?.rightToWorkStatus === 'REJECTED') {
    menuItems.push({
      action: async () => {
        setRtwReqInfoExpanded(!rtwReqInfoExpanded);
        setIsActionOpen(false);
      },
      icon: 'Paper Plane',
      id: 2,
      isDisabled: downloadingPDF,
      label: requestingDetails ? languagePack.rtwRequestingLabel : languagePack.rtwReRequestBtnLabel,
      permissions: ['rtw:create'],
    });
  }
  const headerExclusionStatuses = ['NOT_REQUESTED', 'REQUESTED', 'REMINDER_1_SENT', 'REMINDER_2_SENT'];
  return (
    <Card>
      <CardBody>
        {loading ? (
          <LoadingScreen notFixed />
        ) : (
          <>
            {(isExpired || !headerExclusionStatuses.includes(rtwStatus)) && !rtwReqInfoExpanded && (
              <HeadingPushRight
                className="mb-4"
                headingAs="h4"
                headingText={`${languagePack.rtwTitle || 'Right To Work'} - ${languagePack[rtwStatus] || 'Unknown'}`}
                isTouch={isTouch}
              >
                {menuItems.length === 1 && checkPermissions(menuItems[0].permissions) && (
                  <IconButton
                    action={menuItems[0].action}
                    className="ms-2"
                    disabled={menuItems[0].isDisabled}
                    label={menuItems[0].label}
                    SVGIcon={<IconSVG name={menuItems[0].icon} />}
                  />
                )}
                {menuItems.length > 1 && (
                  <div ref={wrapperRef}>
                    <ActionButton
                      buttonLabel={languagePack.actionBtnLabel || 'Actions'}
                      className="ellipsis-opts-btn"
                      iconName="Ellipsis"
                      iconOnly={false}
                      isOpen={isActionOpen}
                      menuItems={menuItems}
                      onToggle={(isOpen) => {
                        setIsActionOpen(isOpen);
                      }}
                      title="Actions"
                    />
                  </div>
                )}
              </HeadingPushRight>
            )}
            {rtwStatus === 'NOT_REQUESTED' && !rtwReqInfoExpanded && (
              <ActionToBePerformedNoDataState
                image={idchecknodata}
                title="Right To Work Not Requested"
                description="You have not yet requested a Right To Work check from this candidate"
                button={
                  <Button
                    onClick={() => {
                      setRtwReqInfoExpanded(!rtwReqInfoExpanded);
                      setIsActionOpen(false);
                    }}
                    className="ms-2"
                    disabled={menuItems[0].isDisabled}
                    label={menuItems[0].label}
                  >
                    {menuItems[0].label}
                  </Button>
                }
              />
            )}
            {rtwReqInfoExpanded && (
              <Row>
                <Col>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <img
                      src={idchecknodata}
                      alt="No Data"
                      style={{ maxWidth: '100%', marginBottom: '2px', opacity: 0.7, width: '80px' }}
                    />
                    <h2 style={{ marginTop: '0', marginLeft: '10px' }}>{languagePack.rtwRequestTitle}</h2>
                  </div>
                  <EmailForm
                    actionType="RIGHT_TO_WORK_REQUEST"
                    applicants={[
                      {
                        applicantEmail: candidate.applicantProfile.communication.emailAddress,
                        applicantId: candidate.id,
                        funnelId: candidate.funnelId,
                      },
                    ]}
                    onError={handleError}
                    onSuccess={handleRTWSubmissionSuccess}
                    sendBtnLabel={languagePack.rtwRequestBtnLabel}
                    wrapperClassName="mt-4"
                  />
                </Col>
              </Row>
            )}
            {['REQUESTED', 'REMINDER_1_SENT', 'REMINDER_2_SENT'].includes(rtwStatus) && (
              <RightToWorkRequestedContainer
                isExpired={isExpired}
                languagePack={languagePack}
                rtwDetails={rtwDetails}
                sendReminder={sendReminder}
                downloadingPDF={downloadingPDF}
                rtwStatus={rtwStatus}
                reminderSending={reminderSending}
              />
            )}
            {rtwStatus === 'RECEIVED' && (
              <RightToWorkCompletedContainer
                candidate={candidate}
                refreshData={getData}
                isLoading={setLoading}
                languagePack={languagePack}
                rtwDetails={rtwDetails}
              />
            )}
          </>
        )}
      </CardBody>
    </Card>
  );
}

RightToWorkDetails.propTypes = {
  candidate: PropTypes.shape(),
  isTouch: PropTypes.bool,
  onError: PropTypes.func,
  onSuccess: PropTypes.func,
  summary: PropTypes.shape(),
  updateSummary: PropTypes.func,
};

RightToWorkDetails.defaultProps = {
  candidate: {},
  isTouch: false,
  onError: () => {},
  onSuccess: () => {},
  summary: {},
  updateSummary: () => {},
};

export default RightToWorkDetails;
