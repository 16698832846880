/* eslint-disable no-nested-ternary */
import React, { useContext, useRef, useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { Col, Form, Row } from 'reactstrap';
import { Link } from 'react-router-dom';
import { useFormKeypress, useMounted } from '@Base/hooks';
import IntegrationInput from './IntegrationInput';
import { retryableAPICall } from '@API/common-api-utils';
import { WorkflowContext } from '@Base/contexts/WorflowContext';
import Tippy from '@tippyjs/react';

import {
  getLinkedAccount,
  getGlobalConfig,
  getSiteLevelPayTypes,
  getSiteLevelPositions,
  submitToS4,
  checkS4Submission,
} from '@API/Integrations/S4API';
import s4InputConfig from './s4InputConfig';
import { CancelButton, CreateButton } from '@Base/Buttons';
import { addS4User, addGlobalConfig } from '@JS/actions/s4Actions';
// import { cbInputConfig } from '../../../Admin/Client/S4';
import validation, { mapErrors } from '@JS/utils/validation';
import { requestStatuses } from '@JS/constants/requestStatuses';

async function checkS4Status(onSuccess = () => {}, candidateId) {
  let resp;

  if (candidateId) {
    resp = await retryableAPICall(() => checkS4Submission(candidateId));
  } else {
    resp = await retryableAPICall(() => getLinkedAccount());
  }

  if (typeof resp === 'string' && resp.length) {
    if (resp !== 'NOT_FOUND_ERROR') {
      toast.error('Error fetching S4 information. Please try again later or contact support');
    }
  } else {
    onSuccess(resp);
  }
}

// function composeApiCall(apiCallName) {
//   return (args) => async () => apiCallName(...args);
// }

function isFetchSuccess(resp, respName) {
  if (typeof resp === 'string') {
    if (resp !== 'NOT_FOUND_ERROR') {
      toast.error(`Error fetching S4 ${respName}. Please try again later or contact support`);
    }

    return false;
  }

  return true;
}

async function fetchServerConfigs(onSuccess = () => {}, applicantAccountId = null) {
  const fetchCalls = [getSiteLevelPositions, getGlobalConfig, getSiteLevelPayTypes];

  const [posResp, cfgResp, payResp] = await Promise.all(
    fetchCalls.map((fetchCall) => retryableAPICall(() => fetchCall(applicantAccountId))),
  );

  const isPosSuccess = isFetchSuccess(posResp, 'Positions');
  const isCfgSuccess = isFetchSuccess(cfgResp, 'Config');
  const isPaySuccess = isFetchSuccess(payResp, 'PayTypes');

  const compiledObject = {
    ...(isCfgSuccess ? { ...cfgResp } : {}),
    ...(isPosSuccess ? { positions: posResp } : {}),
    ...(isPaySuccess ? { payTypes: payResp } : {}),
  };

  if (isPosSuccess || isCfgSuccess || isPaySuccess) onSuccess(compiledObject);
}

// function getAdvancedSettings(settingsObj) {
//   return Object.entries(settingsObj).reduce((acc, [key, val]) => {
//     const idx = cbInputConfig.findIndex(({ id }) => id === key);
//     return {
//       ...acc,
//       ...(~idx ? { [key]: val } : {}),
//     };
//   }, {});
// }

function mapConfigToOptions(configArr = []) {
  return configArr.map(({ ID, name, value, label }) => ({
    value: ID || value,
    label: name || label,
  }));
}

const initFormData = s4InputConfig.reduce(
  (acc, { id, type, defaultValue }) => ({ ...acc, [id]: type === 'number' ? defaultValue : '' }),
  {},
);

function isCreateRestricted(res, candidateProfileSummary) {
  const buttonRestrictions = res?.find(({ component }) => component === 's4_submit_button') || {};

  if (!buttonRestrictions?.restrictions?.length) return false;

  const isRestricted = buttonRestrictions.restrictions.some(({ component, enableState }) => {
    if (component === 'EDOC') {
      return candidateProfileSummary.edoc.length > 0
        ? candidateProfileSummary.edoc.every((edoc) => {
            return enableState.some((state) => state !== edoc.status);
          })
        : false;
    }
    if (component === 'FLEXI_FORM') {
      return candidateProfileSummary.genericForm.length > 0
        ? candidateProfileSummary.genericForm.every((edoc) => {
            return enableState.some((state) => state !== edoc.status);
          })
        : false;
    }
  });

  return isRestricted ? buttonRestrictions.restrictionMsg : false;
}

function S4Form({
  applicantId,
  applicantAccountId,
  connected,
  // user,
  accountIdToSiteId: accSiteMap,
  setS4User,
  globalConfig,
  setGlobalConfig,
  candidateProfileSummary,
}) {
  const { data, isLoading } = useContext(WorkflowContext);
  const isMounted = useMounted();
  const formRef = useFormKeypress();
  const [isAuthorised, setIsAuthorised] = useState(false);
  const [formData, setFormData] = useState(initFormData);
  const [isSaving, setIsSaving] = useState(false);
  const [formKey, setFormKey] = useState('');
  const [errors, setErrors] = useState({});
  const [submissionStatus, setSubmissionStatus] = useState('INCOMPLETE');
  const [failReason, setFailReason] = useState();
  const validationConfig = useRef([]);

  const checkStatus = useCallback(
    (noFormData) => {
      checkS4Status((resp) => {
        const { statusReason, status, ...fData } = resp;

        if (status === 'FAILED') {
          setSubmissionStatus('FAILED');
          setFailReason(statusReason);
          if (noFormData) toast.error('Error submitting form, please try again or contact support');
        } else if (status === 'SUCCESS') {
          setSubmissionStatus('COMPLETE');
          setFailReason();
          if (noFormData) toast.success('Form submitted successfully');
        }

        if (!noFormData && !!Object.keys(fData).length) {
          setFormData(fData);
        }

        // console.log(resp, JSON.stringify(resp, null, '\t'));
      }, applicantId);
    },
    [applicantId],
  );

  useEffect(() => {
    if (connected) {
      setIsAuthorised(true);

      if (isMounted()) {
        fetchServerConfigs((resp) => {
          const keyLen = Object.keys(resp).length;

          if (keyLen) {
            const { positions, payTypes, defaultContractedDays, defaultContractedHolidays, defaultContractedHours } =
              resp;

            const hasPositions = !!(Array.isArray(positions) && positions.length);
            const hasPayTypes = !!(Array.isArray(payTypes) && payTypes.length);
            const hasDays = defaultContractedDays && defaultContractedDays > 0;
            const hasHolidays = defaultContractedHolidays && defaultContractedHolidays > 0;
            const hasHours = defaultContractedHours && defaultContractedHours > 0;
            const hasRequiredFields = hasPositions && hasPayTypes && hasDays && hasHolidays && hasHours;
            setGlobalConfig(resp, keyLen > 4 && hasRequiredFields);
          }
        }, applicantAccountId);
      }

      validationConfig.current = s4InputConfig.reduce((acc, { id, type, required, inputProps }) => {
        const { min, max } = inputProps || {};
        return [...acc, { id, required, ...(type === 'number' ? { type, min, max } : {}) }];
      }, []);
    }
  }, [connected, isMounted, setGlobalConfig, applicantAccountId]);

  useEffect(() => {
    if (connected && applicantId && isMounted()) {
      checkStatus();
    }
  }, [applicantId, checkStatus, connected, isMounted]);

  useEffect(() => {
    if (isMounted() && !connected) {
      checkS4Status((resp) => {
        const { login, organisationId, accountIdToSiteId } = resp;
        // const advSettings = getAdvancedSettings(rest);
        setS4User(login, organisationId, accountIdToSiteId);
      });
    }
  }, [connected, isMounted, setS4User]);

  // useEffect(() => {
  //   if (isMounted() && connected) {
  //     const serverData = s4InputConfig.reduce((acc, { type, configId, id }) => ({
  //       ...acc,
  //       ...(type === 'number' && configId && globalConfig[configId] ? {
  //         [id]: globalConfig[configId],
  //       } : {}),
  //     }), {});

  //     setFormData((prevState) => ({ ...prevState, ...serverData }));
  //   }
  // }, [connected, globalConfig, isMounted]);

  function handleChange(id, value) {
    let updatedData = { ...formData, [id]: value };

    if (id === 'payType') {
      if (/^2|3|4$/.test(value)) {
        updatedData = {
          ...updatedData,
          contractedDays: updatedData.contractedDays || 5,
          contractedHours: updatedData.contractedHours || 48,
        };
      } else {
        updatedData = {
          ...updatedData,
          contractedDays: updatedData.contractedDays || 0,
          contractedHours: updatedData.contractedHours || 0,
        };
      }
    }

    setFormData(updatedData);
  }

  async function handleSave() {
    setIsSaving(true);

    const errObj = validation(validationConfig.current, formData);
    const { messages, hasErrors } = mapErrors(errObj);
    setErrors(messages);

    if (!hasErrors) {
      const serverData = {
        ...formData,
        candidateId: applicantId,
      };
      // console.log(serverData);

      const resp = await retryableAPICall(() => submitToS4(serverData));

      if (typeof resp === 'string' && resp === requestStatuses.GENERAL_ERROR) {
        toast.error('Error submitting form, please try again or contact support');
      }

      checkStatus(true);
    }

    setIsSaving(false);
  }

  if (!isAuthorised) return null;

  const isComplete = submissionStatus === 'COMPLETE';
  const isFailed = submissionStatus === 'FAILED';
  // const isPending = submissionStatus === 'PENDING';
  // const hasFormData = Object.values(formData).some((val) => !!val?.length);

  function reset() {
    setFormData({ ...initFormData });
    setErrors({});
    setFormKey(Math.random().toString(10));
  }

  const isCreateButtonRestricted = isSaving || isLoading || isCreateRestricted(data, candidateProfileSummary);

  return (
    <>
      <hr />
      <Row>
        <Col>
          <div className="d-flex align-items-center mb-2">
            <h4 className="mb-0 me-auto">{`S4 - ${isComplete ? 'Completed' : isFailed ? 'Failed' : 'Pending'}`}</h4>
          </div>
          {!Object.keys(accSiteMap).length && (
            <div className="alert alert-warning">
              <p>
                Account site mapping is not set up.{' '}
                <Link to="/settings/s4">Please go to S4 settings to create a site mapping</Link>
              </p>
            </div>
          )}
          {failReason && (
            <div className="alert alert-danger">
              <p>{failReason}</p>
            </div>
          )}
          <Form key={formKey} innerRef={formRef}>
            {s4InputConfig.map(({ id, label, type, required, inputProps, configId }) => {
              let options = [];

              if (type === 'select') {
                if (globalConfig[configId]) {
                  options = mapConfigToOptions(globalConfig[configId]);
                }
              }

              let value = formData[id] || '';
              if (type === 'number' && !formData[id]) value = 0;

              return (
                <IntegrationInput
                  key={id}
                  error={errors[id]}
                  id={id}
                  inputProps={inputProps}
                  label={label}
                  onChange={(val) => {
                    handleChange(id, val);
                  }}
                  options={options}
                  readOnly={isComplete}
                  required={required}
                  type={type}
                  value={value}
                />
              );
            })}
            {!isComplete && (
              <div className="float-end d-flex">
                <CancelButton
                  className="mt-2"
                  label="Clear"
                  isLoading={isSaving}
                  disabled={isSaving}
                  action={() => reset()}
                />
                <Tippy
                  // eslint-disable-next-line max-len
                  content={isCreateButtonRestricted ? isCreateButtonRestricted || 'Cannot submit at this time' : null}
                  theme="ats"
                  disabled={!isCreateButtonRestricted}
                >
                  <div>
                    <CreateButton
                      className="mt-2"
                      label={isSaving ? 'Submitting data...' : 'Submit Candidate to S4'}
                      isLoading={isSaving || isLoading}
                      disabled={isCreateButtonRestricted}
                      action={(e) => {
                        e.preventDefault();
                        handleSave();
                      }}
                    />
                  </div>
                </Tippy>
              </div>
            )}
          </Form>
        </Col>
      </Row>
    </>
  );
}

S4Form.propTypes = {
  // user: PropTypes.shape({
  //   login: PropTypes.string,
  // }),
  candidateProfileSummary: PropTypes.shape(),
  applicantId: PropTypes.string,
  applicantAccountId: PropTypes.string,
  connected: PropTypes.bool,
  accountIdToSiteId: PropTypes.shape(),
  setS4User: PropTypes.func,
  globalConfig: PropTypes.shape(),
  setGlobalConfig: PropTypes.func,
};

S4Form.defaultProps = {
  // user: {},
  applicantId: null,
  candidateProfileSummary: null,
  applicantAccountId: null,
  connected: false,
  accountIdToSiteId: {},
  setS4User: () => {},
  globalConfig: {},
  setGlobalConfig: () => {},
};

function mapStateToProps(state) {
  const {
    s4: {
      // user,
      connected,
      accountIdToSiteId,
      globalConfig,
    },
  } = state;

  return {
    // user,
    connected,
    accountIdToSiteId,
    globalConfig,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setS4User: (login, organisationId, accountIdToSiteId) => {
      dispatch(addS4User(login, organisationId, accountIdToSiteId));
    },
    setGlobalConfig: (configObj, isComplete) => {
      dispatch(addGlobalConfig(configObj, isComplete));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(S4Form);
