import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { toast } from 'react-toastify';
import InterviewHeading from './InterviewHeading';
import InterviewNotes from './InterviewNotes';
import { BuildDetails } from '../Candidates/CandidateDetails';
import config from '../../../config/config';
import { copy } from '@JS/utils/clipboard';

const { web } = config;
const { bookingInInterviewUrl } = web;
const DATE_TIME_FORMAT = 'YYYY-MM-DD HH:mm:ss';
const DISPLAY_FORMAT = 'DD-MM-YYYY HH:mm:ss';

function InterviewCards({ applicant, invites, languagePack, candidateId }) {
  if (!Object.keys(invites).length || !invites?.data?.length) return null;

  return invites.data
    .sort((a, b) => moment(b.createdDateTime) - moment(a.createdDateTime))
    .map((invite) => {
      const { id, slotDetails } = invite;

      return (
        <div key={id} className="interview-invite">
          <InterviewHeading invite={invite} dateTimeFormat={DATE_TIME_FORMAT} />
          {slotDetails && (
            <div className="interview-date-time">
              <BuildDetails title={languagePack.timeAndDate} titleColumnSize={3}>
                {moment.utc(slotDetails.startDate, DATE_TIME_FORMAT).local().format(DISPLAY_FORMAT)}
              </BuildDetails>
              <BuildDetails title={languagePack.locationLabel} titleColumnSize={3}>
                {slotDetails.locationData?.location?.formattedAddress}
              </BuildDetails>
              <BuildDetails title={languagePack.eventTypeLabel} titleColumnSize={3}>
                {slotDetails.eventName}
              </BuildDetails>
              {slotDetails.slotMedium === 'VIDEO' && (
                <BuildDetails title={languagePack.videoLinkLabel} titleColumnSize={3}>
                  <a target="_blank" href={`/video/interview?id=${slotDetails.videoRoomId}&iid=${id}`}>
                    Go to room
                  </a>
                  {' | '}
                  <a
                    onClick={async (e) => {
                      e.preventDefault();
                      await copy(`${bookingInInterviewUrl}?id=${slotDetails.videoRoomId}&cId=${candidateId}`, () =>
                        toast.success(languagePack.successfullyCopiedVideoLink),
                      );
                    }}
                    href=""
                  >
                    Copy Candidate Link
                  </a>
                </BuildDetails>
              )}
            </div>
          )}
          <InterviewNotes applicant={applicant} invite={invite} dateTimeFormat={DATE_TIME_FORMAT} />
        </div>
      );
    });
}

InterviewCards.propTypes = {
  invites: PropTypes.shape({
    data: PropTypes.arrayOf(
      PropTypes.shape({
        createdDateTime: PropTypes.string,
        slotDetails: PropTypes.shape({
          startDate: PropTypes.string,
          locationData: PropTypes.shape({
            location: PropTypes.shape({
              formattedAddress: PropTypes.string,
            }),
          }),
          eventName: PropTypes.string,
        }),
      }),
    ),
  }),
  languagePack: PropTypes.shape(),
  candidateId: PropTypes.string,
  noteProps: PropTypes.shape({
    onEdit: PropTypes.func,
    onBooking: PropTypes.func,
    onModal: PropTypes.func,
    onFormChange: PropTypes.func,
    onActiveInviteId: PropTypes.func,
    interviewFormOptions: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.string,
        label: PropTypes.string,
      }),
    ),
  }),
};

InterviewCards.defaultProps = {
  invites: {},
  candidateId: undefined,
  languagePack: {},
  noteProps: {},
};

export default InterviewCards;
