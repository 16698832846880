import React, { useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Input, Alert } from 'reactstrap';
import { toast } from 'react-toastify';
import { Loader } from '@Base/Loading';
import { retryableAPICall } from '@API/common-api-utils';
import { submitATR } from '@API/ATRAPI/ATRAPI';

import styled from 'styled-components';

const StyledLoaderContainer = styled.div`
  min-height: 100px;
`;

const ActionButton = ({ onSubmit, disabled, id }) => {
  const [modal, setModal] = useState(false);
  const [reason, setReason] = useState('');
  const [submitting, setSubmitting] = useState(false);

  const toggleModal = () => {
    if (submitting) return;
    setModal(!modal);
  };

  const handleSubmit = async (approved) => {
    setSubmitting(true);

    try {
      const result = await retryableAPICall(() => submitATR(id, approved ? 'APPROVED' : 'REJECTED', reason));

      if (typeof result === 'string') {
        toast.error(`Error submitting, please try again or contact support`);
      } else {
        onSubmit(approved);
      }
    } catch (error) {
      toast.error(`Error submitting, please try again or contact support`);
    } finally {
      setSubmitting(false);
      setModal(false);
    }
  };

  return (
    <>
      <div className="d-flex gap-3 mt-auto mb-auto">
        <Button color="danger" onClick={toggleModal} disabled={!disabled}>
          Reject
        </Button>
        <Button color="success" onClick={() => handleSubmit(true)} disabled={!disabled}>
          Approve
        </Button>
      </div>
      <Modal isOpen={modal} toggle={toggleModal}>
        <ModalHeader toggle={toggleModal}>Reason for Rejection</ModalHeader>
        <ModalBody>
          {submitting ? (
            <StyledLoaderContainer>
              <Loader />
            </StyledLoaderContainer>
          ) : (
            <Input
              type="textarea"
              placeholder="Enter rejection reason..."
              value={reason}
              onChange={(e) => setReason(e.target.value)}
            />
          )}
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggleModal} disabled={submitting}>
            Cancel
          </Button>
          <Button color="primary" onClick={() => handleSubmit(false)} disabled={submitting}>
            Submit Rejection
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default ActionButton;
