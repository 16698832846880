import React, { Fragment, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import styled from 'styled-components';
import cx from 'classnames';
import IconSVG from '../SVG/IconSVG';
import { ACTION_COLOUR } from '@JS/utils/colours';

const Heading = styled.h6``;
const ChildWrapper = styled.section``;

function SectionToggle({
  children,
  heading,
  className,
  sectionClassName,
  isCollapsed,
  onToggle,
  sectionAs,
  headingAs,
  chevronFullRight,
  hasToggle,
}) {
  const [collapsed, setCollapsed] = useState(hasToggle);

  useEffect(() => {
    if (hasToggle) setCollapsed(isCollapsed);
  }, [hasToggle, isCollapsed]);

  function handleToggle() {
    if (hasToggle) {
      setCollapsed(!collapsed);
      onToggle();
    }
  }

  const headingProps = {
    ...(hasToggle
      ? {
          role: 'button',
          tabIndex: '0',
          onClick: handleToggle,
          onKeyUp: handleToggle,
        }
      : {}),
  };

  return (
    <Fragment>
      <div className={cx('d-flex', 'align-items-center', 'my-1', className, 'section-toggle-header')}>
        <div
          className={cx({ 'btn-heading': hasToggle }, { 'me-auto': chevronFullRight })}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...headingProps}
        >
          {typeof heading === 'function' ? (
            heading()
          ) : (
            <Heading as={headingAs} className="mb-0">
              {heading}
            </Heading>
          )}
        </div>
        {hasToggle && (
          <Button
            size="sm"
            className="py-0"
            color="link"
            onClick={(e) => {
              e.preventDefault();
              handleToggle();
            }}
          >
            <IconSVG
              name="Chevron"
              fill={ACTION_COLOUR}
              className={cx('btn-chevron-toggle', { 'toggle-up': !collapsed })}
            />
          </Button>
        )}
      </div>
      <ChildWrapper as={sectionAs} className={cx(sectionClassName, { 'd-none': collapsed }, 'section-toggle-body')}>
        {children}
      </ChildWrapper>
    </Fragment>
  );
}

SectionToggle.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  heading: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  className: PropTypes.string,
  sectionClassName: PropTypes.string,
  isCollapsed: PropTypes.bool,
  onToggle: PropTypes.func,
  sectionAs: PropTypes.string,
  headingAs: PropTypes.string,
  chevronFullRight: PropTypes.bool,
  hasToggle: PropTypes.bool,
};

SectionToggle.defaultProps = {
  children: null,
  heading: null,
  className: null,
  sectionClassName: null,
  isCollapsed: true,
  onToggle: () => {},
  sectionAs: null,
  headingAs: null,
  chevronFullRight: true,
  hasToggle: true,
};

export default SectionToggle;
