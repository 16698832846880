/* eslint-disable jsx-a11y/label-has-for */
import React, { useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Form } from 'reactstrap';
import Tippy from '@tippyjs/react';
import moment from 'moment';
import InlineInput from './InlineInput';
import EmailBodyInput from './EmailBodyInput';
import EmailAttachments from './EmailAttachments';
import { TextButton, CreateButton } from '@Base/Buttons';
import { retryableAPICall } from '@API/common-api-utils';
import { sendEmail } from '@API/CandidateAPI/CandidateAPI';
import { inviteToApply } from '@API/JobSeekerAPI/JobSeekerAPI';
import validation from '@JS/utils/validation';
import { getArrayOfObjectValuesBy } from '@JS/utils/general-utils';
import { onboardingRequest } from '@API/CandidateAPI/OnboardingAPI';
import emailFormInputConfig from './emailFormInputConfig';
import EmailToolbar from './EmailToolbar';
import EmailDelaySend from './EmailDelaySend';
import { useMounted } from '@Base/hooks';
import { requestRTWDocumentation } from '@API/RTWAPI/RTWAPI';
import { requestPersonalityTestDocumentation } from '@API/PersonalityTestAPI/PersonalityTestAPI';
import { eDocRequest } from '@API/CandidateAPI/EDocAPI';
import { genericFormRequest } from '@API/CandidateAPI/GenericFormsAPI';
import { requestInterviewAI } from '@API/InterviewerAIAPI/InterviewerAIAPI';

async function proxySend(fields, type, data) {
  const {
    fromName,
    replyTo,
    subject,
    message,
    applicantIds,
    ccAddresses,
    bccAddresses,
    attachments,
    vacancyId,
    delay,
    docFormIds,
    parameters,
  } = fields;

  const mappedParams = {};
  let finalParams = [];
  if (parameters) {
    Object.entries(parameters).forEach(([key, value]) => {
      const formName = key.substring(0, key.indexOf(':'));
      const fieldName = key.substring(key.indexOf(':') + 1);
      // eslint-disable-next-line no-prototype-builtins
      if (!mappedParams.hasOwnProperty(formName)) {
        mappedParams[formName] = [];
      }
      let val;
      if (moment.isMoment(value)) {
        val = value.format('DD-MM-YYYY');
      } else {
        val = value;
      }
      mappedParams[formName].push({ name: fieldName, value: val });
    });
    finalParams = Object.entries(mappedParams).map(([key, value]) => ({
      formId: docFormIds[key],
      parameterValues: value,
    }));
  }

  switch (type) {
    case 'INVITE_TO_INTERVIEW':
    case 'EVENT_INVITE':
      return inviteToApply(applicantIds, vacancyId, replyTo, subject, message);
    case 'RIGHT_TO_WORK_REQUEST':
      return requestRTWDocumentation(applicantIds[0], subject, message, replyTo, fromName);
    case 'PERSONALITY_TEST_REQUEST':
      return requestPersonalityTestDocumentation(applicantIds[0], subject, message, replyTo, fromName);
    case 'INTERVIEWER_AI_REQUEST':
      return requestInterviewAI(applicantIds[0], data["interviewId"], subject, message, replyTo, fromName);
    case 'ONBOARDING':
      return onboardingRequest(
        applicantIds,
        subject,
        message,
        replyTo,
        fromName,
        attachments,
        ccAddresses,
        bccAddresses,
        delay,
      );
    case 'EDOC':
      return eDocRequest(
        applicantIds[0],
        finalParams,
        docFormIds,
        subject,
        message,
        replyTo,
        fromName,
        attachments,
        ccAddresses,
        bccAddresses,
        delay,
      );
    case 'GENERIC_FORM':
      return genericFormRequest(applicantIds[0], docFormIds, subject, message, replyTo, fromName, attachments);
    case 'CANDIDATE_CONTACT':
    default:
      return sendEmail(
        fromName,
        replyTo,
        subject,
        message,
        applicantIds,
        ccAddresses,
        bccAddresses,
        attachments,
        delay,
      );
  }
}

/**
 *     {
      candidateId,
      parameters,
      eDocUrls,
      emailContext: {
        subject,
        htmlContent: content,
        replyTo,
        fromName,
      },
 * @param applicants
 * @returns {[]|*}
 */

function toFieldArray(applicants) {
  let displayValues = getArrayOfObjectValuesBy('applicantEmail', applicants);

  if (!displayValues.length) {
    displayValues = getArrayOfObjectValuesBy('applicantName', applicants);
  }

  return displayValues;
}

const { message: messageConfig } = emailFormInputConfig;
let { inputs, attachments: attachmentsConfig } = emailFormInputConfig;
const formDefaultState = {
  attachments: [],
  bccAddresses: [],
  ccAddresses: [],
  delay: 0,
  fromName: '',
  message: '',
  replyTo: '',
  replyToMessageId: null,
  subject: '',
  to: '',
};

function EmailForm({
  applicants,
  companyName,
  contactEmail,
  onSuccess,
  onError,
  data,
  onChange,
  onSend,
  isReadOnly,
  isSaveAsAllowed,
  isTextEditAllowed,
  subjectLine,
  messageTemplate,
  vacancyId,
  sendBtnLabel,
  actionType,
  wrapperClassName,
  externalSend,
  externalSubmitted,
  removeInputs,
  hideInputs,
  noTemplates,
  isChildForm,
  parameters,
  docFormIds,
  disableSend,
}) {
  const formRef = useRef();
  const isMounted = useMounted();
  const cachedForm = useRef({});
  const [formState, setFormState] = useState({ ...formDefaultState });
  // const [messagePlainText, setMessagePlainText] = useState('');
  const [errors, setErrors] = useState({});
  const [sending, setSending] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  useEffect(() => {
    if (isMounted()) {
      const toField = toFieldArray(applicants);

      const presetFormData = {
        ...formDefaultState,
        fromName: companyName,
        replyTo: contactEmail,
        to: toField,
        ...data,
      };

      if (subjectLine) presetFormData.subject = subjectLine;
      if (messageTemplate) presetFormData.message = messageTemplate;

      setFormState(presetFormData);

      cachedForm.current = {
        fromName: companyName,
        replyTo: contactEmail,
        to: toField,
      };
    }
  }, [
    applicants,
    companyName,
    contactEmail,
    data,
    messageTemplate,
    subjectLine,
    externalSend,
    externalSubmitted,
    isMounted,
  ]);

  // eslint-disable-next-line default-param-last
  function handleChange(val, id, inpConfig = {}, altVal) {
    if (submitted && Object.keys(inpConfig).length) {
      const inpErrs = validation([inpConfig], { [id]: altVal || val });
      setErrors({ ...errors, ...inpErrs });
    }

    const updatedData = { ...formState, [id]: val };

    let errObj = {};
    if (externalSend) {
      errObj = validation([...inputs, messageConfig], { ...formState, [id]: altVal || val });
    }

    const hasErrors = Object.values(errObj).some(({ invalid }) => invalid);

    setFormState(updatedData);
    onChange(updatedData, { errors: errObj, isValid: !hasErrors });
  }

  function templateChange(templateConfig) {
    if (submitted && Object.keys(templateConfig).length) {
      const inpErrs = Object.keys(templateConfig).reduce((memo, key) => {
        const isValid = validation([templateConfig], { [key]: templateConfig[key] });
        if (!isValid) {
          memo[key].push(isValid);
        }
        return memo;
      }, []);
      setErrors({ ...errors, ...inpErrs });
    }

    const updatedData = { ...formState, ...templateConfig };

    let errObj = {};
    if (externalSend) {
      errObj = validation([...inputs, messageConfig], { ...formState, ...templateConfig });
    }

    const hasErrors = Object.values(errObj).some(({ invalid }) => invalid);

    setFormState(updatedData);
    onChange(updatedData, { errors: errObj, isValid: !hasErrors });
  }

  async function handleSubmit(successCb = () => {}) {
    setSubmitted(true);

    const errObj = validation([...inputs, messageConfig], {
      ...formState,
      // [messageConfig.id]: messagePlainText,
    });
    setErrors(errObj);

    const hasErrors = Object.values(errObj).some(({ invalid }) => invalid);

    if (!hasErrors) {
      const { fromName, replyTo, subject, message, ccAddresses, bccAddresses, attachments, replyToMessageId, delay } =
        formState;

      const applicantIds = getArrayOfObjectValuesBy('applicantId', applicants);

      if (!applicantIds.length) {
        onError('Error finding applicants');
      } else {
        setSubmitted(false);
        setSending(true);

        const response = await retryableAPICall(() =>
          proxySend(
            {
              applicantIds,
              attachments,
              bccAddresses,
              ccAddresses,
              delay,
              docFormIds,
              fromName,
              message,
              parameters,
              replyTo,
              replyToMessageId,
              subject,
              vacancyId,
            },
            actionType,
            data,
          ),
        );

        if (Array.isArray(response)) {
          const emailErrors = response.reduce(
            (acc, { applicantId: appId, status, errorReason, applicantFirstName, applicantSurname }) => {
              if (status === 'CREATE_ERROR') {
                acc.push({
                  appId,
                  applicantName: `${applicantFirstName} ${applicantSurname}`,
                  errorReason,
                });
              }
              return acc;
            },
            [],
          );

          if (emailErrors.length) {
            // eslint-disable-next-line max-len
            onError(
              `There were errors sending emails to the following candidates: ${emailErrors
                .map((err) => err.applicantName)
                .join(', ')}`,
            );
          } else {
            onSuccess('Email sent successfully', {
              attachments,
              body: message,
              ...response,
              title: subject,
            });
            onChange({
              ...formDefaultState,
              fromName: companyName,
              replyTo: contactEmail,
              to: toFieldArray(applicants),
            });
            onSend(response);
            successCb();
          }
        } else if (typeof response === 'object' && response !== null) {
          onSuccess('Email sent successfully', {
            ...response,
            attachments,
            body: message,
            title: subject,
          });
          onChange({
            ...formDefaultState,
            fromName: companyName,
            replyTo: contactEmail,
            to: toFieldArray(applicants),
          });
          onSend(response);
          successCb();
        } else {
          onError('There was an error sending this email');
        }

        setSending(false);
      }
    }
  }

  const bodyInvalid = 'message' in errors && errors.message.invalid;
  const bodyErrorMsg = bodyInvalid ? errors.message.errors[0] : '';

  let renderAttachments = true;
  if (removeInputs.length) {
    // remove requested inputs before render
    inputs = inputs.filter((inpObj) => !removeInputs.includes(inpObj.id));
    // attachments processed separately
    renderAttachments = !removeInputs.includes('attachments');
  }

  if (hideInputs.length) {
    hideInputs.forEach((hid) => {
      const idx = inputs.findIndex((inp) => inp.id === hid);
      inputs[idx].isHidden = true;
    });
  }

  attachmentsConfig = {
    ...attachmentsConfig,
    hasToggle: !isReadOnly,
  };

  const toggleButtons = [...inputs, renderAttachments ? attachmentsConfig : {}].reduce((acc, inp) => {
    const { hasToggle, label, id, isHidden } = inp;
    if (hasToggle) acc.push({ id, isHidden, label });
    return acc;
  }, []);

  return (
    <div className={wrapperClassName || ''}>
      {formState?.replyToMessageId && (
        <div className="email-reply-to">
          <p>Replying to message - </p>
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <a
            href=""
            onClick={(e) => {
              e.preventDefault();
              handleChange(null, 'replyToMessageId');
            }}
          >
            Cancel
          </a>
        </div>
      )}
      <EmailToolbar
        formRef={formRef}
        noTemplates={noTemplates}
        onError={onError}
        onTemplateChange={(templateObj) => {
          setFormState({ ...formState, ...templateObj });
          templateChange(templateObj);
        }}
        onTemplateCreate={(msg) => onSuccess(msg, false)}
        onValidate={() => {
          const errObj = validation([...inputs, messageConfig], {
            ...formState,
            // [messageConfig.id]: messagePlainText,
          });

          const hasErrors = Object.values(errObj).some(({ invalid }) => invalid);

          return { formState, isValid: !hasErrors };
        }}
        renderToolbar={!isReadOnly}
        templateType={actionType}
        toggleButtons={toggleButtons}
      >
        {({ openPrompt, isTemplateSelected, deselectTemplate }) => {
          const attachmentsCfgObj = {
            ...attachmentsConfig,
            isHidden: !formState.attachments?.length,
          };

          return (
            <Form
              className="comms-email-form clearfix"
              innerRef={formRef}
              noValidate
              onSubmit={(e) => {
                e.preventDefault();
                handleSubmit(deselectTemplate);
              }}
              tag={isChildForm ? 'div' : 'form'}
            >
              <>
                {inputs.map((inp) => {
                  const { id, hasToggle, isMulti } = inp;
                  let { isHidden } = inp;

                  const isDisabled = isReadOnly || (!isTextEditAllowed && id === 'subject');
                  const invalid = id in errors && errors[id].invalid;
                  const errorMsg = invalid ? errors[id].errors[0] : '';

                  // if Cc / Bcc have value display them
                  if (isMulti && formState[id] && formState[id].length) isHidden = false;

                  return (
                    <Tippy
                      key={id}
                      content="Read only field. Editing is disabled"
                      disabled={!isDisabled}
                      maxWidth="150px"
                      placement="bottom"
                      theme="ats"
                    >
                      <div>
                        <InlineInput
                          {...inp}
                          errorMessage={errorMsg}
                          invalid={invalid}
                          isHidden={isHidden}
                          isReadOnly={isDisabled}
                          isToggled={hasToggle}
                          onChange={(val) => handleChange(val, id, inp)}
                          value={formState[id]}
                        />
                      </div>
                    </Tippy>
                  );
                })}
                {renderAttachments && (
                  <EmailAttachments
                    files={(formState.attachments || []).map((file) => ({
                      ...file,
                      name: file.fileName || file.attachmentName,
                    }))}
                    id={attachmentsCfgObj.id}
                    isHidden={attachmentsCfgObj.isHidden}
                    isToggled
                    label={attachmentsCfgObj.label}
                    multiple
                    onChange={(files) => {
                      const normalizedFiles = files.map(
                        ({ name, type, dataUrl, contentType, dateStored, encoding, id, ...rest }) => ({
                          contentType: contentType || type,
                          ...(dataUrl ? { data: dataUrl } : {}),
                          ...(rest.data ? { data: rest.data } : {}),
                          ...(dateStored ? { dateStored } : {}),
                          ...(encoding ? { encoding } : {}),
                          ...(id ? { id } : {}),
                          fileName: name,
                        }),
                      );

                      handleChange(normalizedFiles, attachmentsCfgObj.id, attachmentsCfgObj);
                    }}
                  />
                )}
                <Tippy
                  content="Read only field. Editing is disabled"
                  disabled={!isReadOnly && isTextEditAllowed}
                  maxWidth="150px"
                  placement="bottom-end"
                  theme="ats"
                >
                  <div>
                    <EmailBodyInput
                      errorMsg={bodyErrorMsg}
                      invalid={bodyInvalid}
                      isReadOnly={isReadOnly || !isTextEditAllowed}
                      // eslint-disable-next-line no-unused-vars
                      onChange={(val, plainText) => {
                        // setMessagePlainText(plainText);
                        handleChange(
                          val,
                          messageConfig.id,
                          messageConfig,
                          // plainText,
                        );
                      }}
                      value={formState.message}
                    />
                  </div>
                </Tippy>
                <EmailDelaySend onChange={(delay) => handleChange(delay, 'delay')} value={formState.delay} />
              </>
              {isTemplateSelected && (
                <TextButton
                  action={(e) => {
                    e.preventDefault();
                    setFormState({
                      ...formDefaultState,
                      ...cachedForm.current,
                    });
                    deselectTemplate();
                  }}
                  floatRight
                  label="Reset"
                />
              )}
              {!externalSend && (
                <TextButton
                  className="me-2"
                  disabled={sending || disableSend}
                  floatRight
                  isLoading={sending}
                  label={sending ? 'Sending...' : sendBtnLabel || 'Send Email'}
                  type="submit"
                />
              )}
              {!isReadOnly && !externalSend && isSaveAsAllowed && (
                <CreateButton
                  action={(e) => {
                    e.preventDefault();
                    openPrompt();
                  }}
                  className="me-2"
                  label="Save as template"
                />
              )}
            </Form>
          );
        }}
      </EmailToolbar>
    </div>
  );
}

EmailForm.propTypes = {
  actionType: PropTypes.string,
  applicants: PropTypes.arrayOf(
    PropTypes.shape({
      applicantEmail: PropTypes.string,
      applicantId: PropTypes.string.isRequired,
      applicantName: PropTypes.string,
    }),
  ).isRequired,
  companyName: PropTypes.string.isRequired,
  contactEmail: PropTypes.string.isRequired,
  data: PropTypes.shape(),
  disableSend: PropTypes.bool,
  docFormIds: PropTypes.shape(),
  externalSend: PropTypes.bool,
  externalSubmitted: PropTypes.bool,
  hideInputs: PropTypes.arrayOf(PropTypes.string),
  isChildForm: PropTypes.bool,
  isReadOnly: PropTypes.bool,
  isSaveAsAllowed: PropTypes.bool,
  isTextEditAllowed: PropTypes.bool,
  messageTemplate: PropTypes.string,
  noTemplates: PropTypes.bool,
  onChange: PropTypes.func,
  onError: PropTypes.func,
  onSend: PropTypes.func,
  onSuccess: PropTypes.func,
  parameters: PropTypes.shape(),
  removeInputs: PropTypes.arrayOf(PropTypes.string),
  sendBtnLabel: PropTypes.string,
  subjectLine: PropTypes.string,
  vacancyId: PropTypes.string,
  wrapperClassName: PropTypes.string,
};

EmailForm.defaultProps = {
  actionType: 'CANDIDATE_CONTACT',
  data: {},
  disableSend: false,
  docFormIds: {},
  externalSend: false,
  externalSubmitted: false,
  hideInputs: [],
  isChildForm: false,
  isReadOnly: false,
  isSaveAsAllowed: true,
  isTextEditAllowed: true,
  messageTemplate: null,
  noTemplates: false,
  onChange: () => {},
  onError: () => {},
  onSend: () => {},
  onSuccess: () => {},
  parameters: {},
  removeInputs: [],
  sendBtnLabel: 'Send Email',
  subjectLine: null,
  vacancyId: null,
  wrapperClassName: null,
};

function mapStateToProps(state) {
  const {
    userData: {
      userDetails: { email },
    },
    companyData: {
      companyDetails: { companyName },
    },
  } = state;
  return { companyName, contactEmail: email };
}

export default connect(mapStateToProps)(EmailForm);
