export const cookieNames = {
  CANDIDATE_VIEW: 'candidateView',
  REMEMBER_ME: 'rememberMe',
  RPT_ACCOUNT_ACTIVITY_CUSTOM_COLOURS: 'rptAccountActivityCustomColours',
  RPT_ACCOUNT_ACTIVITY_EXPAND_TOGGLE: 'rptAccountActivityExpandToggle',
  RPT_ACCOUNT_ACTIVITY_HEATMAP_TOGGLE: 'rptAccountActivityHeatmapToggle',
  TWO_FACTOR_TRUST_ID: 'twoFactorTrustId',
};

export const localStorageNames = {
  ADMIN_SECTION: 'adminSection',
  DASHBOARD: 'dashboard',
  DATA_TABLE: 'dataTable',
  LOGOUT_URL: 'logoutUrl',
  SCHEDULE_TAB: 'scheduleTab',
  USERNAME: 'username',
};
