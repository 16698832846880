import React, { Fragment, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import { ModalPopup } from '@Base/Modal';
import LocationEditor from '../../../Admin/Client/AccountSettings/Locations/LocationEditor';
import { SelectInput } from '@Base/Forms/Custom/CommonComponents';

function LocationSelect({ selectProps, disabled, eventFormat, locationType, updateOptionsOnSave, useDefaultLocation }) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [updatedOptions, setUpdatedOptions] = useState([]);

  useEffect(() => {
    // if no value
    if (!selectProps.value.length && useDefaultLocation) {
      // find the option that is set as default
      const defaultOpt = selectProps.options.find((opt) => opt.default);
      // if there is a default
      if (defaultOpt && Object.keys(defaultOpt).length) {
        // set the value in the parent form
        selectProps.onChange(defaultOpt.value, defaultOpt);
      }
    }
  }, [selectProps, useDefaultLocation]);

  return (
    <Fragment>
      <SelectInput
        {...selectProps}
        updatedOptions={updatedOptions}
        useDefaultOption={useDefaultLocation}
        disabled={disabled}
      />
      <Button color="link" size="sm" className="px-0" onClick={() => setIsModalOpen(true)} disabled={disabled}>
        Create Location
      </Button>
      <ModalPopup
        title="Create Location"
        isOpen={isModalOpen}
        onToggle={(isOpen) => setIsModalOpen(isOpen)}
        hideCancelButton
        hideOkayButton
        // allowOverflow
      >
        <LocationEditor
          locationType={locationType}
          eventFormat={eventFormat}
          onCancel={() => setIsModalOpen(false)}
          onSave={(newLoc) => {
            const { id, name } = newLoc;

            if (updateOptionsOnSave) {
              setUpdatedOptions([...updatedOptions, { value: id, label: name }]);
            }

            selectProps.onChange(id, { ...newLoc, value: id, label: name }, true);

            setIsModalOpen(false);
          }}
          hideDefault
        />
      </ModalPopup>
    </Fragment>
  );
}

LocationSelect.propTypes = {
  selectProps: PropTypes.shape(),
  eventFormat: PropTypes.string,
  locationType: PropTypes.string,
  updateOptionsOnSave: PropTypes.bool,
  useDefaultLocation: PropTypes.bool,
  disabled: PropTypes.bool,
};

LocationSelect.defaultProps = {
  selectProps: {},
  eventFormat: null,
  locationType: 'EVENT',
  updateOptionsOnSave: true,
  useDefaultLocation: true,
  disabled: false,
};

export default LocationSelect;
