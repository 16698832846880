/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, Fragment, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { toast } from 'react-toastify';

import EditInline from '@Base/Forms/Custom/EditInline/EditInline';
import { useMounted } from '@Base/hooks';
import { ModalPopup, Confirmation } from '@Base/Modal';
import { DeleteButton } from '@Base/Buttons';

import { retryableAPICall } from '@API/common-api-utils';
import { getFlexiFormList, getFlexiForm } from '@API/FormsAPI/ATSFormsAPI';

import appFormLangPack from '@JS/language/pages/default-application-form';
import { translateFormSchema, getLanguagePackSync } from '@JS/language/language-pack';

import { setFormToOptions, updateObject, updateArray } from './utils';
import { FormSectionEditor, LanguageToggle } from './components';

const arrayFieldLookup = {
  employmentHistory: 'history',
};

function wrapArrayItems(schemaProps, uiSchema) {
  const arraySections = Object.entries(schemaProps).reduce((acc, [key, props]) => {
    if (props.type === 'array') acc.push(key);
    return acc;
  }, []);

  return Object.entries(uiSchema).reduce((acc, [key, props]) => {
    const isArray = arraySections.includes(key);
    const field = isArray && arrayFieldLookup[key];

    const clone = {
      ...acc,
      [key]: isArray
        ? {
            ...(field ? { 'ui:field': field } : {}),
            'ui:options': { orderable: false },
            items: { ...props },
          }
        : props,
    };
    return clone;
  }, {});
}

function setFieldStatus(fields, required = [], props = {}, notOpt = []) {
  return Object.keys(fields).reduce((acc, key, i) => {
    const isNotOptional = notOpt.includes(key);
    let status = 'optional';

    if (required.includes(key)) status = 'required';
    if (!(key in props)) status = 'disabled';

    acc[key] = {
      status,
      isNotOpt: isNotOptional,
      toggleState: i === 0 ? 'display' : 'hidden',
      // hide: (isNotOptional && !notOpt[key]),
    };
    return acc;
  }, {});
}

function EditStandardForm({
  onChange,
  notOptionalSections,
  notOptionalFields,
  optionalOnlyFields,
  formSections,
  formFields,
  formSchema,
  formUiSchemaProps,
  sectionOrder,
  formName: name,
  fieldNotes,
  attachedFormSchemas,
  isDragDisabled,
  isEditInlineDisabled,
  selectedLanguage,
}) {
  const { properties: formProps, required = [] } = formSchema;

  const [formName, setFormName] = useState('');
  const [sections, setSections] = useState({});
  const [sectionsUi, setSectionsUi] = useState({});
  const [sectionStates, setSectionStates] = useState(
    setFieldStatus(formFields, required, formProps, notOptionalSections),
  );
  const [sectionEntries, setSectionEntries] = useState([]);
  const [attachModalOptions, setAttachModalOptions] = useState([]);
  const [attachedForm, setAttachedForm] = useState(attachedFormSchemas);
  const [isAttachModalOpen, setIsAttachModalOpen] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const [langPrefix, setLangPrefix] = useState('en');
  const isMounted = useMounted();

  const requestData = useCallback(
    async (type = '') => {
      const resp = await retryableAPICall(() => getFlexiFormList([type]));

      if (typeof resp === 'string') {
        toast.error('Error loading forms');
      } else if (isMounted()) {
        setAttachModalOptions(setFormToOptions(resp));
      }
    },
    [isMounted],
  );

  const handleChange = useCallback(
    (sectionObject, sectionUiObject, sectionState, nameStr, attached, lang) => {
      const stateEntries = Object.entries(sectionState);

      const updatedProps = updateObject(stateEntries, sectionObject);
      const updatedRequired = updateArray(stateEntries, required, true);

      const updatedUiSchema = updateObject(stateEntries, sectionUiObject);
      const updatedOrder = updateArray(stateEntries, sectionOrder);

      const schema = {
        ...formSchema,
        title: nameStr,
        required: updatedRequired,
        properties: updatedProps,
      };

      const uiSchema = wrapArrayItems(updatedProps, {
        'ui:order': updatedOrder,
        ...updatedUiSchema,
      });

      const formSchemas = { schema, uiSchema, formName: nameStr };

      onChange(formSchemas, attached, lang);
    },
    [formSchema, onChange, required, sectionOrder],
  );

  const requestForm = useCallback(
    async (id) => {
      if (id) {
        const resp = await retryableAPICall(() => getFlexiForm(id));

        if (typeof resp === 'string') {
          toast.error('Error loading form');
        } else {
          // don't want createDateTime in the form sent
          const { createdDateTime, ...attached } = resp;
          setAttachedForm(attached);
          handleChange(sections, sectionsUi, sectionStates, formName, attached, langPrefix);
          setIsAttachModalOpen(false);
        }
      }
    },
    [formName, handleChange, langPrefix, sectionStates, sections, sectionsUi],
  );

  useEffect(() => {
    requestData('GENERIC');
  }, [requestData]);

  useEffect(() => {
    if (isMounted()) {
      setFormName(name);
      setSections(formSchema.properties);
      setSectionsUi(formUiSchemaProps);
      setLangPrefix(selectedLanguage);
      setSectionEntries(Object.entries(formSections));
    }
  }, [formSchema.properties, formSections, formUiSchemaProps, isMounted, name, selectedLanguage]);

  function handleSectionChange(sectionKey, sectionSchema, sectionUiSchema, sectionStatus) {
    if (sectionSchema.required.includes('postalAddress')) {
      // eslint-disable-next-line no-param-reassign
      sectionSchema.properties.postalAddress.required = ['formattedAddress'];
    } else if (sectionSchema.properties.postalAddress) {
      // eslint-disable-next-line no-param-reassign
      delete sectionSchema.properties.postalAddress.required;
    }
    const updatedProps = {
      ...sections,
      [sectionKey]: {
        ...sectionSchema,
      },
    };

    const updatedUiSchema = {
      ...sectionsUi,
      [sectionKey]: { ...sectionUiSchema },
    };

    const stateObj = {
      ...sectionStates,
      [sectionKey]: {
        ...sectionStates[sectionKey],
        status: sectionStatus,
      },
    };

    setSections(updatedProps);
    setSectionsUi(updatedUiSchema);
    setSectionStates(stateObj);
    handleChange(updatedProps, updatedUiSchema, stateObj, formName, attachedForm, langPrefix);
  }

  function handleNameChange(newName) {
    setFormName(newName);
    handleChange(sections, sectionsUi, sectionStates, newName, attachedForm, langPrefix);
  }

  function handleLanguageChange(lang) {
    setLangPrefix(lang);

    const langPack = getLanguagePackSync(appFormLangPack, lang);
    const { formName: langFormName, ...rest } = langPack;

    const updatedFormName = langFormName || formName;
    setFormName(updatedFormName);

    const updatedSections = Object.entries(sections).reduce(
      (acc, [sectionKey, sectionProps]) => ({
        ...acc,
        [sectionKey]: rest[sectionKey] ? translateFormSchema(sectionProps, rest[sectionKey]) : sectionProps,
      }),
      {},
    );
    setSections(updatedSections);

    const updatedSectionEntries = sectionEntries.map(([sectionKey, defaultSchema]) => [
      sectionKey,
      translateFormSchema(defaultSchema, rest[sectionKey]),
    ]);
    setSectionEntries(updatedSectionEntries);

    handleChange(updatedSections, sectionsUi, sectionStates, updatedFormName, attachedForm, lang);
  }

  function handleViewToggle(hide, sectionKey) {
    const stateObj = Object.entries(sectionStates).reduce(
      (acc, [key, obj]) => {
        const display = key === sectionKey && !hide;
        return {
          ...acc,
          [key]: {
            ...obj,
            toggleState: display ? 'display' : 'hidden',
          },
        };
      },
      { ...sectionStates },
    );

    setSectionStates(stateObj);
  }

  const hasAttachedForm = !!Object.keys(attachedForm).length;

  return (
    <Fragment>
      <div className="d-flex justify-content-end">
        <LanguageToggle
          languages={['en', 'de']}
          onChange={handleLanguageChange}
          selectedLanguage={langPrefix}
          confirmProps={{
            content: 'Changes will be lost are you sure you want to switch the language?',
          }}
        />
      </div>
      <h5>{isEditInlineDisabled ? formName : <EditInline value={formName} onChange={handleNameChange} />}</h5>
      {sectionEntries.map(([sectionKey, defaultSchema], i) => (
        <Fragment key={sectionKey}>
          <FormSectionEditor
            sectionKey={sectionKey}
            title={formProps[sectionKey] ? formProps[sectionKey].title : defaultSchema.title}
            sectionSchema={formProps[sectionKey] || defaultSchema}
            formFields={formFields[sectionKey] || {}}
            uiSchemaProps={formUiSchemaProps[sectionKey] || {}}
            notOptionalFields={notOptionalFields[sectionKey]}
            optionalOnlyFields={optionalOnlyFields[sectionKey]}
            sectionStatus={sectionStates[sectionKey]}
            fieldNotes={fieldNotes[sectionKey]}
            onChange={(schema, uiSchema, status) => handleSectionChange(sectionKey, schema, uiSchema, status)}
            onViewToggle={(hide) => handleViewToggle(hide, sectionKey)}
            isEditInlineDisabled={isEditInlineDisabled}
            isDragDisabled={isDragDisabled}
          />
          {i + 1 < sectionEntries.length && <hr />}
        </Fragment>
      ))}
      {Boolean(attachModalOptions.length) && (
        <Fragment>
          <hr />
          <div className="d-flex align-items-center">
            <a
              href="#openmodal"
              id="open-modal"
              onClick={(e) => {
                e.preventDefault();
                setIsAttachModalOpen(true);
              }}
            >
              {hasAttachedForm ? `${attachedForm.name} added` : 'Add an existing form'}
            </a>
            {hasAttachedForm && (
              <DeleteButton
                label="Remove form"
                action={() => setShowConfirm(true)}
                className="btn-sm xs ms-2"
                iconOnly
                floatRight={false}
              />
            )}
          </div>
          <ModalPopup
            title="Add existing form"
            isOpen={isAttachModalOpen}
            onToggle={(isOpen) => setIsAttachModalOpen(isOpen)}
            className="existing-form-modal"
            hideOkayButton
            allowOverflow
          >
            <Select
              classNamePrefix="react-select"
              placeholder="Select Form"
              value={() => ({ id: attachedForm.id, label: attachedForm.name })}
              options={attachModalOptions}
              onChange={({ id }) => requestForm(id)}
            />
          </ModalPopup>
          <Confirmation
            show={showConfirm}
            title="Delete Form?"
            content="Are you sure you want to delete the form?"
            confirmCallback={() => {
              setAttachedForm({});
              handleChange(sections, sectionsUi, sectionStates, formName, {});
              setShowConfirm(false);
            }}
            cancelCallback={() => setShowConfirm(false)}
          />
        </Fragment>
      )}
    </Fragment>
  );
}

EditStandardForm.propTypes = {
  onChange: PropTypes.func,
  notOptionalSections: PropTypes.arrayOf(PropTypes.string),
  notOptionalFields: PropTypes.shape(),
  optionalOnlyFields: PropTypes.shape(),
  formFields: PropTypes.shape().isRequired,
  formSections: PropTypes.shape().isRequired,
  formSchema: PropTypes.shape().isRequired,
  formUiSchemaProps: PropTypes.shape().isRequired,
  sectionOrder: PropTypes.arrayOf(PropTypes.string).isRequired,
  formName: PropTypes.string.isRequired,
  fieldNotes: PropTypes.shape(),
  attachedFormSchemas: PropTypes.shape().isRequired,
  isDragDisabled: PropTypes.bool,
  isEditInlineDisabled: PropTypes.bool,
  selectedLanguage: PropTypes.string,
};

EditStandardForm.defaultProps = {
  notOptionalSections: [],
  optionalOnlyFields: {},
  notOptionalFields: {},
  fieldNotes: {},
  onChange: () => {},
  isDragDisabled: true,
  isEditInlineDisabled: true,
  selectedLanguage: 'en',
};

export default EditStandardForm;
