import React from 'react';
import PropTypes from 'prop-types';
import { optionSchemas } from '../../Admin/Client/AccountSettings/Workflow';

export const STATUS_OPTIONS = optionSchemas.eventStates.filter(({ value }) => value !== 'INVITED');

function InterviewStatusSelect({ id, value, onChange }) {
  return (
    <select
      id={id}
      className="select"
      value={value}
      onChange={(e) => {
        const cleanValueObj = STATUS_OPTIONS.find(({ value: val }) => val === e.target.value);
        let cleanVal = '';
        if (cleanValueObj) cleanVal = cleanValueObj.label;
        onChange(e.target.value, cleanVal);
      }}
    >
      {STATUS_OPTIONS.map(({ value: val, label }) => (
        <option key={val} value={val}>
          {label}
        </option>
      ))}
    </select>
  );
}

InterviewStatusSelect.propTypes = {
  id: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
};

InterviewStatusSelect.defaultProps = {
  id: null,
  value: null,
  onChange: () => {},
};

export default InterviewStatusSelect;
