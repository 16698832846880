import { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Col, Row, Card, CardBody } from 'reactstrap';
import { toast } from 'react-toastify';
import { connect } from 'react-redux';
import Tippy from '@tippyjs/react';
import moment from 'moment';
import LoadingScreen from '@Base/Loading/LoadingScreen';
import { useLanguagePack, useMounted } from '@Base/hooks';
import { retryableAPICall } from '@API/common-api-utils';
import { getTemplatesByType } from '@API/TemplateAPI';
import GenericFormsRequestCreation from './GenericFormsRequestCreation';
import {
  downloadGenericForms,
  getGenericFormDetails,
  sendGenericFormReminder,
} from '@API/CandidateAPI/GenericFormsAPI';
import { MappedAnswers } from '../Candidates/CandidateDetails';
import { FormModal } from '@Base/Modal';
import config from '../../../config/config';
import CopyToClipboardButton from '@Base/Buttons/CopyToClipboardButton';
import { HeadingPushRight } from '@Base/Headings';
import { generateToken } from '../Onboarding/utils';
import { TextButton } from '@Base/Buttons';

const { web } = config;

const DATE_FORMAT = 'DD-MM-YYYY HH:mm:ss';

function GenericFormsContainer({ candidate, vacancyTitle, updateSummary }) {
  const languagePack = useLanguagePack('generic-forms-container');
  const [genericForms, setGenericForms] = useState([]);
  const [selectedToRequestEDocs] = useState([]);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(true);
  const [hasReminderEmailTemplate, setHasReminderEmailTemplate] = useState(false);
  const [downloadingDocument, setDownloadingDocument] = useState(null);
  const [modalConfig, setModalConfig] = useState({ isOpen: false, url: '' });
  const [sendingReminder, setSendingReminder] = useState(false);
  const isMounted = useMounted();
  const [genericFormsActionOpen, setGenericFormsActionOpen] = useState(false);

  const getData = useCallback(
    async (callback = () => {}) => {
      const result = await retryableAPICall(() => getGenericFormDetails(candidate.id));
      const requestChaseEmailTemplate = await retryableAPICall(() => getTemplatesByType('GENERIC_FORM_REQUEST_CHASE'));

      if (isMounted()) {
        if (typeof result === 'string') {
          setError(true);
        } else {
          setGenericForms(result);
        }

        setHasReminderEmailTemplate(requestChaseEmailTemplate && requestChaseEmailTemplate.length !== 0);

        setTimeout(() => {
          setLoading(false);
        }, 75);
        if (typeof callback === 'function') {
          callback(result.status);
        }
      }
    },
    [candidate.id, isMounted],
  );

  const menuItems = [
    {
      action: () => {
        generateToken(GenericFormsContainer.completedFormId, (token) => {
          setModalConfig({
            isOpen: true,
            url: `${web.formsWebUrl}?token=${token}`,
          });
          setGenericFormsActionOpen(false);
        });
      },
      icon: 'Pencil',
      id: 1,
      isDisabled: false,
      label: languagePack.editLabel,
      permissions: ['candidate:onboarding:read'],
    },
  ];

  useEffect(() => {
    if (candidate && candidate.id) getData();
  }, [candidate, getData]);

  async function handleSendReminder(id) {
    setSendingReminder(true);

    try {
      const result = await sendGenericFormReminder(id);
      if (typeof result === 'string') {
        toast.error(languagePack.errorSendingReminder);
      } else {
        toast.success(languagePack.successSendingReminder);
        getData();
      }
    } catch (e) {
      toast.error(languagePack.errorSendingReminder);
    }

    setSendingReminder(false);
  }

  function getReminderLabel(status) {
    let reminderBtnLabel;

    if (sendingReminder) {
      reminderBtnLabel = languagePack.sendingReminderLabel;
    } else if (!sendingReminder && status === 'REQUESTED') {
      reminderBtnLabel = languagePack.sendReminder1;
    } else if (!sendingReminder && (status === 'REMINDER_1_SENT' || status === 'PART_RECEIVED')) {
      reminderBtnLabel = languagePack.sendReminder2;
    }

    return reminderBtnLabel;
  }

  const selEFormIds = {};
  selectedToRequestEDocs.forEach((edoc) => {
    selEFormIds[edoc.label] = edoc.id;
  });

  function handleUpdate() {
    updateSummary();
    getData();
    setLoading(false);
  }

  async function handleDownload(id, formName) {
    setDownloadingDocument(id);
    const result = await retryableAPICall(() =>
      downloadGenericForms([id], {
        [id]: `${candidate?.applicantProfile?.personalDetails?.formattedName} - ${formName}.pdf`,
      }),
    );

    if (typeof result === 'string') {
      toast.error(languagePack.downloadPDFError);
    } else {
      toast.success(languagePack.downloadPDFSuccess);
    }
    setDownloadingDocument(null);
  }

  return (
    <Card>
      <CardBody>
        {loading && <LoadingScreen notFixed />}
        {!loading && (
          <>
            <h4>{languagePack.title || 'Flexi-Forms'}</h4>
            <hr />
            {error && (
              <h5 className="text-danger mb-0">
                {languagePack.loadingError || 'An error occurred when loading the candidates flexi-form information'}
              </h5>
            )}
            {!error && !loading && genericForms.length === 0 && (
              <Row>
                <Col>
                  <h5>{languagePack.noFormsDataStateTitle}</h5>
                </Col>
              </Row>
            )}
            <GenericFormsRequestCreation
              alreadySelectedFormIds={genericForms
                .map((genFormReq) => genFormReq.genericForms.map((genForm) => genForm.flexiFormViewId))
                .flat()}
              candidate={candidate}
              hasReminderEmailTemplate={hasReminderEmailTemplate}
              languagePack={languagePack}
              onUpdate={handleUpdate}
              vacancyTitle={vacancyTitle}
            />
            {!error && genericForms.length > 0 && (
              <>
                <hr />
                <h5>{languagePack.previouslyRequestedDocumentsTitle}</h5>
              </>
            )}
            {!error &&
              genericForms.map((genFormReq) => {
                const isReceived = genFormReq.status === 'RECEIVED';

                return (
                  <Row key={genFormReq.id}>
                    <Col>
                      <div className="documents-requested-container">
                        <Row>
                          <Col>
                            <p style={!isReceived ? { lineHeight: '30px' } : {}}>
                              {`${languagePack.documentsRequestedOnTitle}: ${moment
                                .utc(genFormReq.createdDateTime)
                                .local()
                                .format(DATE_FORMAT)}`}
                            </p>
                          </Col>

                          {!isReceived && genFormReq.status !== 'REMINDER_2_SENT' && (
                            <Col>
                              <Tippy
                                content={languagePack.needToSetReminderEmailTemplate}
                                disabled={hasReminderEmailTemplate}
                                theme="ats"
                              >
                                <TextButton
                                  action={() => handleSendReminder(genFormReq.id)}
                                  disabled={!hasReminderEmailTemplate}
                                  floatRight
                                  label={getReminderLabel(genFormReq.status)}
                                />
                              </Tippy>
                            </Col>
                          )}
                        </Row>
                        {genFormReq?.genericForms?.map((genForm) => {
                          const formUrl = `${web.formsWebUrl}?id=${genForm.flexiFormViewId}&cid=${genFormReq.candidateId}&iid=${genFormReq.id}`;
                          const isFormReceived = genForm.status === 'RECEIVED';

                          return (
                            <div key={genForm.name} className="document-requested-container">
                              <Row className="align-items-center mb-2">
                                <Col>
                                  <h5>{genForm.name}</h5>
                                </Col>
                                {!isFormReceived && (
                                  <Col className="d-flex justify-content-end">
                                    <CopyToClipboardButton label={languagePack.formUrl} textToCopy={formUrl} />
                                    <TextButton
                                      action={() => {
                                        setModalConfig({
                                          isOpen: true,
                                          url: formUrl,
                                        });
                                      }}
                                      disabled={!hasReminderEmailTemplate}
                                      label={languagePack.completeFormLabel}
                                    />
                                  </Col>
                                )}
                              </Row>
                              <Row>
                                <Col>
                                  <h5>
                                    {`${languagePack.documentStatusTitle}: ${
                                      languagePack[genForm.status] || genForm.status.replace('_', ' ')
                                    } - `}
                                    <span className="heading-note ms-0">
                                      {moment.utc(genForm.statusLastUpdatedDateTime).local().format(DATE_FORMAT)}
                                    </span>
                                  </h5>
                                </Col>
                              </Row>
                              {isFormReceived && (
                                <>
                                  <Row className="mb-2">
                                    <Col>
                                      <MappedAnswers
                                        answers={genForm.mappedAnswers}
                                        attachments={genForm.attachments}
                                        candidateId={genFormReq.candidateId}
                                      />
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col>
                                      <TextButton
                                        action={() =>
                                          generateToken(genForm.completedFormId, (token) => {
                                            setModalConfig({
                                              isOpen: true,
                                              url: `${web.formsWebUrl}?token=${token}`,
                                            });
                                          })
                                        }
                                        className="me-1"
                                        label={languagePack.editLabel}
                                      />
                                      <TextButton
                                        action={() => handleDownload(genForm.id, genForm.name)}
                                        disabled={downloadingDocument === genForm.id}
                                        label={
                                          downloadingDocument === genForm.id
                                            ? languagePack.viewDownloadDocumentInProgressLabel
                                            : languagePack.viewDownloadDocumentLabel
                                        }
                                      />
                                    </Col>
                                  </Row>
                                </>
                              )}
                            </div>
                          );
                        })}
                      </div>
                    </Col>
                  </Row>
                );
              })}
          </>
        )}
      </CardBody>
      {modalConfig.isOpen && (
        <FormModal
          contentUrl={modalConfig.url}
          id="onboard-details"
          isOpen={modalConfig.isOpen}
          toggle={(closeMethod, isOpen, frameId) => {
            if (closeMethod === 'IFRAME' && isOpen && frameId === 'onboard-details') {
              setLoading(true);
              getData((status) => {
                setModalConfig({ isOpen: false, url: '' });
                //  onUpdate(status);
              });
            } else {
              setModalConfig({ isOpen: false, url: '' });
            }
          }}
          warnOnClickClose
        />
      )}
    </Card>
  );
}

GenericFormsContainer.propTypes = {
  candidate: PropTypes.shape(),
  updateSummary: PropTypes.func,
  vacancyTitle: PropTypes.string,
};

GenericFormsContainer.defaultProps = {
  candidate: null,
  updateSummary: () => {},
  vacancyTitle: null,
};

function mapStateToProps(state) {
  const {
    userData: { userDetails },
  } = state;
  return { userDetails };
}

export default connect(mapStateToProps, null)(GenericFormsContainer);
