import React, { useState, useEffect } from 'react';
import { FormGroup, Label, Input, Spinner } from 'reactstrap';
import Select from 'react-select';
import { toast } from 'react-toastify';

import { retryableAPICall } from '@API/common-api-utils';
import { getRejectionReasons } from '@API/CandidateAPI/CandidateAPI';

import { Loader } from '@Base/Loading';

import EmailTemplateSelectInput from './EmailTemplateSelectInput';

const stageType = [
  { label: 'Default', value: 'DEFAULT' },
  { label: 'DBS', value: 'DBS' },
  { label: 'eDoc', value: 'EDOC' },
  { label: 'Hired', value: 'HIRED' },
  { label: 'Interview', value: 'INTERVIEW' },
  { label: 'Onboarding', value: 'ONBOARDING' },
  { label: 'Personality Test', value: 'PERSONALITY_TEST' },
  { label: 'References', value: 'REFERENCES' },
  { label: 'Rejected', value: 'REJECTED' },
  { label: 'RTW', value: 'RTW' },
];

function CandidateRejection({ option, setOption }) {
  const [rejectionReasons, setRejectionReasons] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    loadRejectionReasons();
  }, []);

  async function loadRejectionReasons() {
    try {
      setIsLoading(true);
      const resp = await retryableAPICall(() => getRejectionReasons());

      if (typeof resp === 'string') {
        toast.error('Could not load rejection reasons');
      } else {
        setRejectionReasons(
          resp?.map((a) => {
            return { value: a.name, label: a.name };
          }),
        );
      }
    } catch (error) {
      toast.error('Error loading rejection reasons');
    } finally {
      setIsLoading(false);
    }
  }

  const handleReasonChange = (selectedOption) => {
    setOption({
      stageReason: selectedOption?.value,
    });
  };

  const handleCustomReasonChange = (e) => {
    const value = e.target.value;
    setOption({
      stageReason: value,
    });
  };

  if (isLoading) {
    return <Spinner />;
  }
  return (
    <>
      <FormGroup required>
        <Label>Funnel Stage Type</Label>
        <Select
          className="react-select"
          isMulti
          required
          placeholder="Select Funnel Stage"
          options={stageType}
          value={stageType.reduce((acc, cur) => {
            if (option?.stageTypes?.includes(cur.value)) acc.push(cur);
            return acc;
          }, [])}
          onChange={(val) => {
            setOption({ stageTypes: val.map((a) => a.value) });
          }}
        />
      </FormGroup>
      <EmailTemplateSelectInput
        actionType={'REJECTION'}
        id="emailTemplateConfig"
        onChange={(templateId) => setOption({ templateId })}
        value={option?.templateId || ''}
        isClearable
      />
      <FormGroup>
        <Label>Rejection Reason</Label>
        {isLoading ? (
          <Loader />
        ) : rejectionReasons.length > 0 ? (
          <Select
            options={rejectionReasons}
            value={rejectionReasons.find((rej) => rej.value === option.stageReason)}
            required
            onChange={handleReasonChange}
            placeholder="Select a rejection reason"
            className="react-select"
          />
        ) : (
          <Input
            type="text"
            required
            className="react-select"
            placeholder="Enter rejection reason"
            value={option.stageReason}
            onChange={handleCustomReasonChange}
          />
        )}
      </FormGroup>
    </>
  );
}

export default CandidateRejection;
