import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Col, Row, Input } from 'reactstrap';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { AccountSelectorModal } from '@Base/Account';
import { ModalPopup } from '@Base/Modal';
import { usePopstate } from '@Base/hooks';

import DashboardHeader from './DashboardHeader';
import DashboardSideMenu from './DashboardSideMenu';
import DashboardContent from './DashboardContent';
import { getLocalStore, setLocalStore } from '../Common/utils';
import { titleLookup, sectionLookup, accountSwitchSections } from './dashboardConstants';

function ClientAdminDashboard({ activeAccountName, allowAccountSwitch, userType, email }) {
  const { pathname } = useLocation();

  const [activeTab, setActiveTab] = useState();
  const [isCreateMode, setIsCreateMode] = useState(false);
  const [isImportModalOpen, setIsImportModalOpen] = useState(false);
  const [uploadError, setUploadError] = useState(null);
  const [initialData, setInitialData] = useState(null);
  const [searchTerm, setSearchTerm] = useState(null);
  const [accountSwitchOpen, setAccountSwitchOpen] = useState(false);
  const [openSections, setOpenSections] = useState([]);
  const pushHistory = usePopstate();
  const [titleSubItems, setTitleSubItems] = useState([]);

  useEffect(() => {
    const pName = pathname?.replace('/settings', '') || '';
    const storedState = getLocalStore();
    const { page } = storedState;
    let { sections } = storedState;

    const selectedTab = pName.length ? pName.slice(1) : page;
    setActiveTab(selectedTab);

    const parentSection = Object.entries(sectionLookup).reduce((acc, [name, value]) => {
      if (value?.submenu.find(({ uri }) => uri === selectedTab)) return name;
      return acc;
    }, '');

    if (parentSection.length && !sections.includes(parentSection)) {
      sections = [...sections, parentSection];
    }

    setOpenSections(sections);
  }, [pathname]);

  function handleSectionToggle(sectionName) {
    let updatedSections = [...openSections];

    const isOpen = updatedSections.includes(sectionName);
    if (isOpen) {
      updatedSections = openSections.filter((section) => section !== sectionName);
    } else {
      updatedSections = [...openSections, sectionName];
    }

    setOpenSections(updatedSections);
    setLocalStore(updatedSections, activeTab);
  }

  function handleClick(page) {
    // if the tab changes we want to reset the header sub items
    // they will be set again once the subsection has loaded
    setTitleSubItems([]);
    pushHistory({ page }, `/settings/${page}`);
    setActiveTab(page);
    setLocalStore(openSections, page);
    window.scrollTo({ behavior: 'smooth', top: 0 });
  }

  return (
    <>
      <Row className="pb-0 mb-0">
        <Col>
          <DashboardHeader
            pageTitle={titleLookup[activeTab] || 'Settings'}
            titleSubItems={titleSubItems}
            activeTab={activeTab}
            setIsCreateMode={setIsCreateMode}
            isCreateMode={isCreateMode}
            setSearchTerm={setSearchTerm}
            setIsImportModalOpen={setIsImportModalOpen}
            allowAccountSwitch={allowAccountSwitch}
            accountSwitchSections={accountSwitchSections}
            activeAccountName={activeAccountName}
            setAccountSwitchOpen={setAccountSwitchOpen}
            userType={userType}
          />
        </Col>
      </Row>
      <div className="flex-grow-1">
        <DashboardSideMenu
          activeTab={activeTab}
          title={titleLookup[activeTab] || 'Settings'}
          openSections={openSections}
          handleClick={handleClick}
          userType={userType}
          handleSectionToggle={handleSectionToggle}
        >
          <DashboardContent
            activeTab={activeTab}
            isCreateMode={isCreateMode}
            setIsCreateMode={setIsCreateMode}
            userType={userType}
            searchTerm={searchTerm}
            setTitleSubItems={setTitleSubItems}
            initialData={initialData}
            setIsImportModalOpen={setIsImportModalOpen}
          />
        </DashboardSideMenu>
      </div>
      <AccountSelectorModal isOpen={accountSwitchOpen} onToggle={() => setAccountSwitchOpen(false)} />
      <ModalPopup
        className="m-auto"
        disableOkayButton={!initialData}
        isOpen={isImportModalOpen}
        onCancel={() => {
          setInitialData(null);
        }}
        onOkay={() => {
          setIsCreateMode(true);
        }}
        onToggle={(isOpen) => {
          setIsImportModalOpen(isOpen);
          setUploadError(null);
        }}
        title="Import a form"
      >
        <Input
          accept=".json"
          className="m-auto h-100 mt-4"
          onChange={(event) => {
            setUploadError(null);
            if (event.target.files[0]) {
              // Read and parse the JSON file
              const fileReader = new FileReader();
              fileReader.readAsText(event.target.files[0], 'UTF-8');
              fileReader.onload = (e) => {
                try {
                  const json = JSON.parse(e.target.result);

                  const hasRequiredFields =
                    json.formSchema && json.name && json.rules && json.sharedWith && json.type && json.uiSchema;

                  if (!hasRequiredFields) {
                    setUploadError(
                      `Please upload a valid form file. It should contain: formSchema, name, rules, sharedWith, type, uiSchema.`,
                    );
                  } else {
                    setInitialData(json);
                  }
                } catch (error) {
                  console.error('Error parsing JSON:', error);
                }
              };
            }
          }}
          type="file"
        />
        {uploadError ? <h5 className="text-danger mt-4">{uploadError}</h5> : null}
      </ModalPopup>
    </>
  );
}

ClientAdminDashboard.propTypes = {
  activeAccountName: PropTypes.string,
  allowAccountSwitch: PropTypes.bool,
  email: PropTypes.string,
  location: PropTypes.shape(),
  userType: PropTypes.string,
};

ClientAdminDashboard.defaultProps = {
  activeAccountName: null,
  allowAccountSwitch: false,
  email: null,
  location: {},
  userType: null,
};

function mapStateToProps(state) {
  const {
    userData: {
      userDetails: {
        activeAccountName,
        data: { accountAccess = [], userType },
        email,
      },
    },
  } = state;

  return {
    activeAccountName,
    allowAccountSwitch: accountAccess.length > 1,
    email,
    userType,
  };
}

export default connect(mapStateToProps)(ClientAdminDashboard);
