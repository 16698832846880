import React from 'react';
import { Col } from 'reactstrap';

import { LoadingScreen } from '@Base/Loading';
import ErrorMessage from '@Base/ErrorScreen/ErrorMessage';
import Can from '@Base/RBAC/Can/Can';

import { checkPermissions } from '@JS/auth/AuthUtils';

import { BrandingForm } from '../Branding';
import { AccountAdmin, UserAdmin, SingleSignOn } from '../AccountManagement';
import {
  ATR,
  CompanyProfile,
  EmailTemplates,
  FunnelAdmin,
  AdvancedWorkflow,
  FlexiForms,
  Workflows,
  LocationsAdmin,
  ScheduleAdmin,
  RejectionReasons,
  TagManager,
  VacancySettings,
  VacancyTemplates,
} from '../AccountSettings';
import {
  S4Admin,
  FourthAdmin,
  PolarisAdmin,
  HollwegAdmin,
  AccessAdmin,
  RotaReadyAdmin,
  DbsCheckAdmin,
} from '../Integrations';
import { JobBoardSettings, PersonalInformation } from '../PersonalPreferences';

const RestrictedBrandingForm = Can(BrandingForm);
const RestrictedFlexiForms = Can(FlexiForms);
const RestrictedFunnels = Can(FunnelAdmin);
const RestrictedEmailTemplates = Can(EmailTemplates);
const RestrictedVacancyTemplates = Can(VacancyTemplates);
const RestrictedRejectionReasons = Can(RejectionReasons);
const RestrictedSchedule = Can(ScheduleAdmin);
const RestrictedTags = Can(TagManager);
const RestrictedWorkflows = Can(Workflows);
const RestrictedAdvancedWorkflows = Can(AdvancedWorkflow);
const RestrictedATR = Can(ATR);
const RestrictedAccountAdmin = Can(AccountAdmin);
const RestrictedUserAdmin = Can(UserAdmin);
const RestrictedFourth = Can(FourthAdmin);
const RestrictedS4 = Can(S4Admin);
const RestrictedPolaris = Can(PolarisAdmin);
const RestrictedHollweg = Can(HollwegAdmin);
const RestrictedAccess = Can(AccessAdmin);
const RestrictedRotaReady = Can(RotaReadyAdmin);
const RestrictedDbsCheck = Can(DbsCheckAdmin);
const RestrictedSSO = Can(SingleSignOn);

function DashboardContent({
  activeTab,
  isCreateMode,
  setIsCreateMode,
  setIsImportModalOpen,
  searchTerm,
  setTitleSubItems,
  initialData,
  userType,
}) {
  if (!activeTab) {
    return (
      <Col>
        <LoadingScreen isEmbeded />
      </Col>
    );
  }

  return (
    <>
      {activeTab === 'personal-information' && <PersonalInformation />}
      {activeTab === 'job-boards' && <JobBoardSettings />}
      {activeTab === 'company-profile' && (
        <CompanyProfile
          onClose={() => setIsCreateMode(false)}
          openSlider={isCreateMode}
          setIsCreateMode={setIsCreateMode}
        />
      )}
      {activeTab === 'branding' && <RestrictedBrandingForm permissions={['admin:branding']} />}
      {activeTab === 'forms' && (
        <RestrictedFlexiForms
          canCreateForm={checkPermissions(['admin:forms:create'])}
          deniedDisplay={<ErrorMessage error={403} />}
          initialData={initialData}
          isCreateMode={isCreateMode}
          onClose={() => setIsCreateMode(false)}
          setIsCreateMode={setIsCreateMode}
          setIsImportModalOpen={setIsImportModalOpen}
          permissions={['admin:forms:read']}
          restrictedToFormTypes={[
            'APPLICATION_FORM',
            'ONBOARDING',
            'INTERVIEW',
            'REFERENCE',
            'GENERAL',
            'REFEREE',
            'GENERIC',
          ]}
        />
      )}
      {activeTab === 'edocs' && (
        <RestrictedFlexiForms
          deniedDisplay={<ErrorMessage error={403} />}
          editorType="EDOC"
          formTypeTitleName="eDoc"
          isCreateMode={isCreateMode}
          onClose={() => setIsCreateMode(false)}
          setIsCreateMode={setIsCreateMode}
          permissions={['admin:edoc:read']}
          restrictedToFormTypes={['EDOC']}
        />
      )}
      {activeTab === 'funnels' && (
        <RestrictedFunnels
          setIsCreateMode={setIsCreateMode}
          isCreateMode={isCreateMode}
          onClose={() => setIsCreateMode(false)}
          permissions={[]}
        />
      )}
      {activeTab === 'email-templates' && (
        <RestrictedEmailTemplates
          isCreateMode={isCreateMode}
          onClose={() => setIsCreateMode(false)}
          permissions={['admin:emails:read']}
          searchTerm={searchTerm}
          updateCreateMode={(val) => setIsCreateMode(val)}
          setIsCreateMode={setIsCreateMode}
        />
      )}
      {activeTab === 'vacancy-templates' && (
        <RestrictedVacancyTemplates
          isCreateMode={isCreateMode}
          // searchTerm={searchTerm}
          onClose={() => setIsCreateMode(false)}
          permissions={['admin:vacancytemplates:read']}
          setIsCreateMode={setIsCreateMode}
        />
      )}
      {activeTab === 'vacancy' && (
        <VacancySettings
          // searchTerm={searchTerm}
          isCreateMode={isCreateMode}
          onClose={() => setIsCreateMode(false)}
          setIsCreateMode={setIsCreateMode}
        />
      )}
      {activeTab === 'rejection-reasons' && (
        <RestrictedRejectionReasons
          // searchTerm={searchTerm}
          isCreateMode={isCreateMode}
          onClose={() => setIsCreateMode(false)}
          permissions={['admin:rejectionreasons:update']}
        />
      )}
      {activeTab === 'schedule' && <RestrictedSchedule permissions={['admin:schedule:read']} />}
      {activeTab === 'locations' && checkPermissions(['admin:locations:read']) && (
        <LocationsAdmin
          // permissions={['admin:locations:read']}
          isCreateMode={isCreateMode}
          onClose={() => setIsCreateMode(false)}
          setIsCreateMode={setIsCreateMode}
        />
      )}
      {activeTab === 'tags' && <RestrictedTags permissions={['candidate:tags']} />}
      {activeTab === 'workflows' && (
        <RestrictedWorkflows
          isCreateMode={isCreateMode}
          onClose={() => setIsCreateMode(false)}
          permissions={['admin:workflow:read']}
          setIsCreateMode={setIsCreateMode}
        />
      )}
      {activeTab === 'advanced-workflows' && <RestrictedAdvancedWorkflows permissions={['admin:workflow:read']} />}
      {activeTab === 'atr' && (
        <RestrictedATR
          isCreateMode={isCreateMode}
          onClose={() => setIsCreateMode(false)}
          permissions={['admin:atr:read']}
        />
      )}
      {activeTab === 'accounts' && userType !== 'JOBSITE_ATS' && (
        <RestrictedAccountAdmin
          isCreateMode={isCreateMode}
          // searchTerm={searchTerm}
          onClose={() => setIsCreateMode(false)}
          permissions={['admin:usermanagement:read']}
          setTitleSubItems={setTitleSubItems}
          userType={userType}
          setIsCreateMode={setIsCreateMode}
        />
      )}
      {activeTab === 'users' && (
        <RestrictedUserAdmin
          isCreateMode={isCreateMode}
          onClose={() => setIsCreateMode(false)}
          permissions={['admin:usermanagement:read']}
          searchTerm={searchTerm}
          setTitleSubItems={setTitleSubItems}
          setIsCreateMode={setIsCreateMode}
        />
      )}
      {activeTab === 'SSO' && <RestrictedSSO permissions={[]} />}
      {activeTab === 'fourth' && <RestrictedFourth permissions={['fourth:admin']} />}
      {activeTab === 'polaris' && <RestrictedPolaris permissions={['polaris:admin']} />}
      {activeTab === 'hollweg' && (
        <RestrictedHollweg
          deniedDisplay={
            <Col className="mt-2">
              <ErrorMessage error={403} />
            </Col>
          }
          permissions={['pth:create']}
        />
      )}
      {activeTab === 'access' && (
        <RestrictedAccess
          deniedDisplay={
            <Col className="mt-2">
              <ErrorMessage error={403} />
            </Col>
          }
          permissions={['admin:accessgrp']}
        />
      )}
      {activeTab === 's4' && <RestrictedS4 permissions={['s4:admin']} />}
      {activeTab === 'rota-ready' && (
        <RestrictedRotaReady
          deniedDisplay={
            <Col className="mt-2">
              <ErrorMessage error={403} />
            </Col>
          }
          permissions={['admin:rota']}
        />
      )}
      {activeTab === 'dbs-check' && (
        <RestrictedDbsCheck
          deniedDisplay={
            <Col className="mt-2">
              <ErrorMessage error={403} />
            </Col>
          }
          permissions={['dbs:admin']}
        />
      )}
    </>
  );
}

export default DashboardContent;
