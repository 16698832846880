import PropTypes from 'prop-types';
import React from 'react';
import moment from 'moment';
import { Cell, Label, Pie, PieChart } from 'recharts';
import { Col, Row } from 'reactstrap';
import { ErrorMessage } from '@Base/ErrorScreen';
import { IconSVG } from '@Base/SVG';

function grade(score) {
  if (score < 30) {
    return 'bad';
  }
  if (score < 50) {
    return 'ok';
  }
  if (score < 70) {
    return 'pass';
  }
  return 'great';
}

const colours = {
  bad: '#DD2700',
  ok: '#DD9600',
  pass: '#B6DD00',
  great: '#48DD00',
};

// Date format is 2025-03-18T14:33:24.712
const DATE_TIME_FORMAT = 'YYYY-MM-DDTHH:mm:ss.SSS';

const DISPLAY_FORMAT = 'DD-MM-YYYY HH:mm:ss';

function GaugePieChart({ score, label }) {
  return (
    <PieChart height={260} width={400}>
      <Pie
        blendStroke
        cy="70%"
        data={[{ value: score }, { value: 100 - score }]}
        dataKey="value"
        endAngle={0}
        innerRadius="55%"
        isAnimationActive={false}
        labelLine={false}
        startAngle={180}
      >
        <Label dx={0} dy={-60} value={label} />
        <Cell fill={colours[grade(score)]} />
        <Cell fill="#eaeaea" />
        <Label dx={0} dy={60} fill={colours[grade(score)]} fontSize="30" fontWeight="bold" value={score} />
      </Pie>
    </PieChart>
  );
}

GaugePieChart.propTypes = {
  score: PropTypes.number,
  label: PropTypes.string,
};

GaugePieChart.defaultProps = {
  score: 0,
  label: '',
};

function VideoInterviewResultsContainer({ resultSummary, languagePack }) {
  
  const hasResults = resultSummary?.results && resultSummary.results.overallScore !== undefined;
  const status = resultSummary?.status || '';

  if (!hasResults) {
    return <ErrorMessage />;
  }

  const { results } = resultSummary;
  const completedDate = results.completedDateTime
    ? moment.utc(results.completedDateTime, DATE_TIME_FORMAT).local().format(DISPLAY_FORMAT)
    : 'N/A';

  const mainScores = [
    { key: 'overallScore', label: languagePack.videoInterviewOverallScore || 'Overall Score', icon: 'Star' },
    { key: 'totalVideoScore', label: languagePack.videoInterviewTotalScore || 'Total Video Score', icon: 'Video' },
  ];

  const detailedScores = [
    {
      key: 'professionalism',
      label: languagePack.videoInterviewProfessionalism || 'Professionalism',
      icon: 'Briefcase',
    },
    {
      key: 'positiveAttitude',
      label: languagePack.videoInterviewPositiveAttitude || 'Positive Attitude',
      icon: 'Smile',
    },
    { key: 'communication', label: languagePack.videoInterviewCommunication || 'Communication', icon: 'MessageSquare' },
    { key: 'sociability', label: languagePack.videoInterviewSociability || 'Sociability', icon: 'Users' },
    {
      key: 'contentRelevancy',
      label: languagePack.videoInterviewContentRelevancy || 'Content Relevancy',
      icon: 'FileText',
    },
    { key: 'resumeScore', label: languagePack.videoInterviewResumeScore || 'Resume Score', icon: 'FileCheck' },
  ];

  const getStatusIcon = () => {
    switch (status) {
      case 'COMPLETED':
        return <IconSVG name="CheckCircle" className="tw-text-green-500 tw-mr-2" />;
      case 'REQUESTED':
        return <IconSVG name="Clock" className="tw-text-amber-500 tw-mr-2" />;
      case 'IN_PROGRESS':
        return <IconSVG name="Loader" className="tw-text-blue-500 tw-mr-2" />;
      case 'FAILED':
        return <IconSVG name="XCircle" className="tw-text-red-500 tw-mr-2" />;
      case 'EXPIRED':
        return <IconSVG name="AlertTriangle" className="tw-text-orange-500 tw-mr-2" />;
      default:
        return <IconSVG name="HelpCircle" className="tw-text-gray-500 tw-mr-2" />;
    }
  };

  return (
    <>
      <Row className="tw-mt-4">
        <Col style={{ maxWidth: '150px' }} className="tw-font-medium">
          {languagePack.videoInterviewStatusLabel || 'Status'}
        </Col>
        <Col>
          <div className="tw-flex tw-items-center">
            {getStatusIcon()}
            <span className="tw-font-medium">{languagePack[status] || status}</span>
          </div>
        </Col>
      </Row>

      <Row className="tw-mt-3">
        <Col style={{ maxWidth: '150px' }} className="tw-font-medium">
          {languagePack.videoInterviewCompletedLabel || 'Completed On'}
        </Col>
        <Col>
          <div className="tw-flex tw-items-center">
            <IconSVG name="Calendar" className="tw-text-gray-500 tw-mr-2" />
            <p className="tw-m-0">{completedDate}</p>
          </div>
        </Col>
      </Row>

      <div className="tw-mt-6 tw-bg-gray-50 tw-p-4 tw-rounded-lg">
        <Row>
          <Col>
            <Row className="tw-mt-2">
              {mainScores.map(
                (scoreItem) =>
                  results[scoreItem.key] !== undefined && (
                    <Col sm="6" key={scoreItem.key}>
                      <div className="tw-flex tw-flex-col tw-items-center">
                        <div className="tw-flex tw-items-center tw-mb-2">
                          <IconSVG name={scoreItem.icon} className="tw-text-blue-500 tw-mr-2" />
                          <span className="tw-font-semibold">{scoreItem.label}</span>
                        </div>
                        <GaugePieChart label="" score={results[scoreItem.key]} />
                      </div>
                    </Col>
                  ),
              )}
            </Row>
          </Col>
        </Row>
      </div>

      <div className="tw-mt-6">
        <h4 className="tw-text-lg tw-font-semibold tw-mb-4">
          {languagePack.videoInterviewDetailedScores || 'Detailed Scores'}
        </h4>
        <div className="tw-grid tw-grid-cols-1 md:tw-grid-cols-2 lg:tw-grid-cols-3 tw-gap-4">
          {detailedScores.map(
            (scoreItem) =>
              results[scoreItem.key] !== undefined && (
                <div
                  key={scoreItem.key}
                  className="tw-bg-white tw-rounded-lg tw-shadow-sm tw-p-4 tw-border tw-border-gray-200"
                >
                  <div className="tw-flex tw-flex-col tw-items-center">
                    <div className="tw-flex tw-items-center tw-mb-2">
                      <IconSVG name={scoreItem.icon} className="tw-text-blue-500 tw-mr-2" />
                      <span className="tw-font-semibold">{scoreItem.label}</span>
                    </div>
                    <div className="tw-text-center tw-mt-2">
                      <span
                        className={`tw-text-4xl tw-font-bold ${
                          results[scoreItem.key] < 30
                            ? 'tw-text-red-500'
                            : results[scoreItem.key] < 50
                              ? 'tw-text-orange-500'
                              : results[scoreItem.key] < 70
                                ? 'tw-text-yellow-500'
                                : 'tw-text-green-500'
                        }`}
                      >
                        {results[scoreItem.key]}
                      </span>
                      <span className="tw-text-lg">/100</span>
                    </div>
                    <div className="tw-w-full tw-bg-gray-200 tw-rounded-full tw-h-2.5 tw-mt-3">
                      <div
                        className={`tw-h-2.5 tw-rounded-full ${
                          results[scoreItem.key] < 30
                            ? 'tw-bg-red-500'
                            : results[scoreItem.key] < 50
                              ? 'tw-bg-orange-500'
                              : results[scoreItem.key] < 70
                                ? 'tw-bg-yellow-500'
                                : 'tw-bg-green-500'
                        }`}
                        style={{ width: `${results[scoreItem.key]}%` }}
                      ></div>
                    </div>
                  </div>
                </div>
              ),
          )}
        </div>
      </div>
    </>
  );
}

VideoInterviewResultsContainer.propTypes = {
  languagePack: PropTypes.shape(),
  resultSummary: PropTypes.shape({
    candidateId: PropTypes.string,
    status: PropTypes.string,
    results: PropTypes.shape({
      professionalism: PropTypes.number,
      positiveAttitude: PropTypes.number,
      communication: PropTypes.number,
      sociability: PropTypes.number,
      contentRelevancy: PropTypes.number,
      resumeScore: PropTypes.number,
      totalVideoScore: PropTypes.number,
      overallScore: PropTypes.number,
      completedDateTime: PropTypes.string,
    }),
  }),
};

VideoInterviewResultsContainer.defaultProps = {
  languagePack: {},
  resultSummary: {},
};

export default VideoInterviewResultsContainer;
