/* eslint-disable import/no-unresolved */
/* eslint-disable jsx-a11y/label-has-for */
import PropTypes from 'prop-types';
import React from 'react';
import { Col, FormGroup, Label, Input } from 'reactstrap';
import ColorInput from '../../../../../Branding/ColorInput';
import UploadFile from '../FileUpload';

require('rc-collapse/assets/index.css');

function Images({
  backgroundColour,
  changeBackgroundColour,
  logo,
  changeLogo,
  backgroundImage,
  changeBackgroundImage,
}) {
  return (
    <>
      <FormGroup row>
        <Label for="logo">Logo:</Label>
        <Col>
          <UploadFile
            onChange={(data) => {
              changeLogo(data);
            }}
            value={logo}
          />
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label for="logo">Background Image:</Label>
        <Col>
          <UploadFile
            onChange={(data) => {
              changeBackgroundImage(data);
            }}
            value={backgroundImage}
          />
        </Col>
      </FormGroup>
      <FormGroup>
        <Label for="backgroundColour">Background Colour:</Label>
        <ColorInput
          className="w-50"
          id="backgroundColour"
          isDisabled={backgroundColour === null}
          onChange={(val) => {
            changeBackgroundColour(val);
          }}
          value={backgroundColour || '#ffffff'}
        />
        <Label check className="pt-4">
          <Input
            checked={backgroundColour === null}
            className="standard-checkbox small position-relative ms-1 me-2"
            onChange={() => {
              if (backgroundColour === null) {
                changeBackgroundColour('#ffffff');
              } else {
                changeBackgroundColour(null);
              }
            }}
            type="checkbox"
          />
          <span style={{ bottom: 0 }}>Transparent Background?</span>
        </Label>
      </FormGroup>
    </>
  );
}

Images.propTypes = {
  currentMUITheme: PropTypes.shape(),
  changeStyle: PropTypes.func,
  logo: PropTypes.string,
  changeLogo: PropTypes.func,
  backgroundImage: PropTypes.string,
  changeBackgroundColour: PropTypes.func,
  backgroundColour: PropTypes.string,
  changeBackgroundImage: PropTypes.func,
};

Images.defaultProps = {
  currentMUITheme: {},
  changeStyle: () => {},
  logo: '',
  changeLogo: () => {},
  backgroundImage: '',
  changeBackgroundImage: () => {},
  changeBackgroundColour: () => {},
  backgroundColour: '',
};

export default Images;
