/* eslint-disable no-nested-ternary */
import React, { useContext, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Card, CardBody, Col, Row } from 'reactstrap';
import { useLanguagePack, useMounted, useOutsideClick } from '@Base/hooks';
import { retryableAPICall } from '@API/common-api-utils';
import { LoadingScreen } from '@Base/Loading';
import { HeadingPushRight } from '@Base/Headings';
import { ActionButton, IconButton } from '@Base/Buttons';
import ActionToBePerformedNoDataState from '../Other/ActionToBePerformedNoDataState';
import idchecknodata from '../../../assets/img/nodata/idchecknodata2.png';
import { checkPermissions } from '@JS/auth/AuthUtils';
import { IconSVG } from '@Base/SVG';
import { checkIfRoleSupportsDbsCheck, getDbsCheckRequestStatus, requestDbsCheck } from '@API/DbsAPI/DbsAPI';
import DbsCheckRequestedContainer from './DbsCheckRequestedContainer';
import DbsCheckCompletedContainer from './DbsCheckCompletedContainer';
import DbsCheckNotPermittedContainer from './DbsCheckNotPermittedContainer';

function DbsCheckDetails({
  summary,
  updateSummary,
  candidate,
  vacancy,
  isTouch,
  onSuccess,
  onError,
  onboardingStatus,
}) {
  const languagePack = useLanguagePack('dbs-details');
  const [dbsCheckStatus, setDbsCheckStatus] = useState(undefined);
  const [dbsCheckDetails, setDbsCheckDetails] = useState(undefined);
  const [dbsCheckPermittedForJobRole, setDbsCheckPermittedForJobRole] = useState(false);
  const [DbsCheckReqInfoExpanded, setDbsCheckReqInfoExpanded] = useState(false);
  const [loading, setLoading] = useState(true);
  const [isActionOpen, setIsActionOpen] = useState(false);
  const wrapperRef = useRef();
  const [isMinimumDataPresentForDbsCheck, setIsMinimumDataPresentForDbsCheck] = useState(false);

  const requestingDetails = false;

  useOutsideClick(
    wrapperRef,
    () => {
      setIsActionOpen(false);
    },
    '.slide-pane.candidate-slider',
  );

  const isMounted = useMounted();

  const { status } = summary;

  const STATUS_NOT_REQUESTED = 'NOT_REQUESTED';
  const STATUS_REQUESTED = 'REQUESTED';
  const STATUS_FAILED = 'FAILED';
  const STATUS_ERROR = 'WITHDRAWN';
  const STATUS_PASSED = 'SUCCESS';

  useEffect(() => {
    const doUseEffect = async () => {
      if (isMounted()) {
        if (status) {
          setDbsCheckStatus(status);
          setDbsCheckDetails(summary);
        } else {
          setLoading(true);
          const dbsCheckDetails = await retryableAPICall(() => getDbsCheckRequestStatus(candidate.id));
          if (typeof dbsCheckDetails === 'string') {
            if (dbsCheckDetails === 'NOT_FOUND_ERROR') {
              setDbsCheckStatus(STATUS_NOT_REQUESTED);
            } else {
              onError(languagePack.dbsLoadError || 'Could not load DBS Check information please try again later');
            }
          } else {
            setDbsCheckStatus(dbsCheckDetails?.status);
          }
        }
        const dbsCheckPermittedRequest = await retryableAPICall(() => checkIfRoleSupportsDbsCheck(vacancy.id));
        setDbsCheckPermittedForJobRole(dbsCheckPermittedRequest?.permitted);

        setIsMinimumDataPresentForDbsCheck('RECEIVED' === onboardingStatus);

        setLoading(false);
      }
    };
    doUseEffect();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [candidate.id, isMounted, onError, status]);

  function handleDbsCheckSubmissionSuccess(message, dbsCheckRequestResponse) {
    setDbsCheckReqInfoExpanded(!DbsCheckReqInfoExpanded);
    setDbsCheckDetails(dbsCheckRequestResponse);
    updateSummary(dbsCheckRequestResponse.status);
    onSuccess(languagePack.rtwReqSuccess);
  }

  async function requestDbsCheckForCandidate() {
    const result = await retryableAPICall(() => requestDbsCheck(candidate.id, vacancy.id));
    if (typeof result === 'string') {
      onError(languagePack.dbsReqError || 'Could request DBS Check at this time please try again later');
    } else {
      handleDbsCheckSubmissionSuccess(languagePack.dbsReqSuccess, result);
    }
  }

  const menuItems = [];
  if (dbsCheckStatus === STATUS_NOT_REQUESTED) {
    menuItems.push({
      action: async () => {
        await requestDbsCheckForCandidate();
        setDbsCheckReqInfoExpanded(!DbsCheckReqInfoExpanded);
        setIsActionOpen(false);
      },
      icon: 'Paper Plane',
      id: 2,
      label: languagePack.dbsReqInfoBtn,
      permissions: ['dbs:create'],
      isDisabled: !isMinimumDataPresentForDbsCheck,
    });
  }
  const headerExclusionStatuses = [STATUS_NOT_REQUESTED, STATUS_REQUESTED];

  return (
    <Card>
      <CardBody>
        {loading ? (
          <LoadingScreen notFixed />
        ) : (
          <>
            {dbsCheckPermittedForJobRole ? (
              <>
                {!headerExclusionStatuses.includes(dbsCheckStatus) && !DbsCheckReqInfoExpanded && (
                  <HeadingPushRight
                    className="mb-4"
                    headingAs="h4"
                    headingText={`${languagePack.dbsTitle || 'DBS Check'} - ${languagePack[dbsCheckStatus] || 'Unknown'}`}
                    isTouch={isTouch}
                  >
                    {menuItems.length === 1 && checkPermissions(menuItems[0].permissions) && (
                      <IconButton
                        action={menuItems[0].action}
                        className="ms-2"
                        disabled={menuItems[0].isDisabled}
                        label={menuItems[0].label}
                        SVGIcon={<IconSVG name={menuItems[0].icon} />}
                      />
                    )}
                    {menuItems.length > 1 && (
                      <div ref={wrapperRef}>
                        <ActionButton
                          buttonLabel={languagePack.actionBtnLabel || 'Actions'}
                          className="ellipsis-opts-btn"
                          iconName="Ellipsis"
                          iconOnly={false}
                          isOpen={isActionOpen}
                          menuItems={menuItems}
                          onToggle={(isOpen) => {
                            setIsActionOpen(isOpen);
                          }}
                          title="Actions"
                        />
                      </div>
                    )}
                  </HeadingPushRight>
                )}
                {dbsCheckStatus === STATUS_NOT_REQUESTED && !DbsCheckReqInfoExpanded && (
                  <ActionToBePerformedNoDataState
                    image={idchecknodata}
                    title="DBS Screening Not Requested"
                    description="Please ensure the candidate has completed onboarding before submitting."
                    button={
                      <Button
                        onClick={async () => {
                          await requestDbsCheckForCandidate();
                          setDbsCheckReqInfoExpanded(!DbsCheckReqInfoExpanded);
                          setIsActionOpen(false);
                        }}
                        className="ms-2"
                        disabled={menuItems[0].isDisabled}
                        label={menuItems[0].label}
                      >
                        {menuItems[0].label}
                      </Button>
                    }
                  />
                )}
                {DbsCheckReqInfoExpanded && (
                  <Row>
                    <Col>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <img
                          src={idchecknodata}
                          alt="No Data"
                          style={{ maxWidth: '100%', marginBottom: '2px', opacity: 0.7, width: '80px' }}
                        />
                        <h2 style={{ marginTop: '0', marginLeft: '10px' }}>{languagePack.dbsRequestTitle}</h2>
                      </div>
                    </Col>
                  </Row>
                )}
                {[STATUS_REQUESTED].includes(dbsCheckStatus) && (
                  <DbsCheckRequestedContainer
                    isLoading={loading}
                    languagePack={languagePack}
                    details={dbsCheckDetails}
                    status={dbsCheckStatus}
                  />
                )}
                {[STATUS_PASSED, STATUS_FAILED, STATUS_ERROR].includes(dbsCheckStatus) && (
                  <DbsCheckCompletedContainer
                    candidate={candidate}
                    isLoading={loading}
                    languagePack={languagePack}
                    details={dbsCheckDetails}
                  />
                )}
              </>
            ) : (
              <DbsCheckNotPermittedContainer languagePack={languagePack} />
            )}
          </>
        )}
      </CardBody>
    </Card>
  );
}

DbsCheckDetails.propTypes = {
  candidate: PropTypes.shape(),
  vacancy: PropTypes.shape(),
  isTouch: PropTypes.bool,
  onError: PropTypes.func,
  onSuccess: PropTypes.func,
  summary: PropTypes.shape(),
  updateSummary: PropTypes.func,
  onboardingStatus: PropTypes.string,
};

DbsCheckDetails.defaultProps = {
  candidate: {},
  vacancy: {},
  isTouch: false,
  onError: () => {},
  onSuccess: () => {},
  summary: {},
  updateSummary: () => {},
  onboardingStatus: undefined,
};

export default DbsCheckDetails;
