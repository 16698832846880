import React from 'react';
import SiteWrapper from './SiteWrapper';
import { ErrorMessage } from '@Base/ErrorScreen';
import { ResolveLogo } from '@Base/SVG';
import useDeviceDetection from '@JS/utils/useDeviceDetection';

function ErrorHeader() {
  return (
    <header className="app-header container-fluid">
      <div className="app-header-inner">
        <ResolveLogo svgWidth="2.5em" className="me-4" />
        <p className="large-blue-text">Talent-Funnel</p>
      </div>
    </header>
  );
}

export default function ErrorPage(props) {
  const device = useDeviceDetection();
  const isTouch = device === 'Mobile' || device === 'Tablet';

  return (
    <SiteWrapper isTouch={isTouch} header={<ErrorHeader />} styles={isTouch ? { minWidth: '100vw' } : {}}>
      <ErrorMessage {...props} />
    </SiteWrapper>
  );
}
