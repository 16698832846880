import React, { useState, useEffect } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import { connect } from 'react-redux';
import { submitFeedback } from '@API/AnalyticsAPI/AnalyticsAPI';
import Cookies from 'js-cookie';
import styled from 'styled-components';

const FeedbackContainer = styled.div`
  background: white;
  border-radius: 12px;
  padding: 1.5rem;
  box-shadow:
    0 4px 6px -1px rgb(0 0 0 / 0.1),
    0 2px 4px -2px rgb(0 0 0 / 0.1);
`;

const EmojiGrid = styled.div`
  display: flex;
  gap: 1rem;
  justify-content: center;
  margin-bottom: 1rem;
`;

const EmojiButton = styled.button`
  padding: 0.75rem;
  border-radius: 50%;
  border: 2px solid ${(props) => (props.$isSelected ? '#3B82F6' : 'transparent')};
  background: ${(props) => (props.$isSelected ? '#EFF6FF' : 'transparent')};
  transition: all 0.2s ease;
  font-size: 1.5rem;
  cursor: pointer;
  position: relative;

  &:hover {
    transform: scale(1.1);
    background: ${(props) => (props.$isSelected ? '#EFF6FF' : '#F3F4F6')};
  }
`;

const StyledTextarea = styled.textarea`
  width: 100%;
  min-height: 100px;
  padding: 0.75rem;
  border: 1px solid #e5e7eb;
  border-radius: 8px;
  resize: none;
  margin-bottom: 1rem;
  font-family: inherit;
  transition: border-color 0.2s ease;

  &:focus {
    outline: none;
    border-color: #3b82f6;
    box-shadow: 0 0 0 3px rgba(59, 130, 246, 0.1);
  }

  &::placeholder {
    color: #9ca3af;
  }
`;

const SubmitButton = styled.button`
  width: 100%;
  padding: 0.75rem;
  background: #3b82f6;
  color: white;
  border: none;
  border-radius: 8px;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.2s ease;

  &:hover {
    background: #2563eb;
  }

  &:disabled {
    background: #d1d5db;
    cursor: not-allowed;
  }
`;

const FeedbackToastContainer = styled(ToastContainer)`
  &.feedback-toast-container {
    width: 400px;
    max-width: 100vw;
  }

  .Toastify__toast {
    padding: 0;
    background: none;
  }

  .Toastify__toast-body {
    padding: 0;
    margin: 0;
  }

  .Toastify__close-button {
    position: absolute;
    right: 8px;
    top: 8px;
    color: #6b7280;
    opacity: 0.7;

    &:hover {
      opacity: 1;
    }
  }
`;

const FeedbackWidget = ({ id }) => {
  useEffect(() => {
    const feedbackGiven = Cookies.get('is_feedback_given');

    if (!feedbackGiven) {
      const ToastContent = () => {
        const [rating, setRating] = useState(null);
        const [comment, setComment] = useState('');

        return (
          <FeedbackContainer>
            <h4>How are we doing?</h4>
            <EmojiGrid>
              {[
                { value: 1, emoji: '😭' },
                { value: 2, emoji: '😐' },
                { value: 3, emoji: '🙂' },
                { value: 4, emoji: '😍' },
              ].map((item) => (
                <EmojiButton
                  key={item.value}
                  onClick={() => setRating(item.value)}
                  $isSelected={rating === item.value}
                  aria-label={`Rate ${item.value} out of 4`}
                >
                  {item.emoji}
                </EmojiButton>
              ))}
            </EmojiGrid>

            <StyledTextarea
              value={comment}
              onChange={(e) => setComment(e.target.value)}
              placeholder="Tell us what could be better (optional)"
            />

            <SubmitButton
              onClick={async () => {
                if (rating) {
                  try {
                    await submitFeedback({
                      feature: 'GENERAL_FEEDBACK',
                      action: 'RATING',
                      rating: rating,
                      comment: comment,
                      platform: 'ATS',
                      uniqueId: id,
                    });
                    Cookies.set('is_feedback_given', 'true', { expires: 90 });
                    toast.success('Thank you for your feedback!');
                    toast.dismiss();
                  } catch (error) {
                    toast.error('Failed to submit feedback. Please try again.');
                  }
                } else {
                  toast.warn('Please select a rating');
                }
              }}
              disabled={!rating}
            >
              Submit Feedback
            </SubmitButton>
          </FeedbackContainer>
        );
      };

      toast(<ToastContent />, {
        position: 'bottom-right',
        autoClose: false,
        closeOnClick: false,
        draggable: false,
        closeButton: true,
        containerId: 'feedback-toast',
        onClose: () => {
          Cookies.set('is_feedback_given', 'true', { expires: 90 });
        },
      });
    }
  }, []);

  return (
    <FeedbackToastContainer
      containerId="feedback-toast"
      className="feedback-toast-container"
      enableMultiContainer
      limit={1}
    />
  );
};

function mapStateToProps(state) {
  const {
    userData: {
      userDetails: { id },
    },
  } = state;
  return { id };
}

export default connect(mapStateToProps)(FeedbackWidget);
