import React, { Children } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const TitleWrapper = styled.div`
  display: flex;
  margin-bottom: 1rem;
  padding-bottom: 0.5rem;
  border-bottom: 1px solid #e0e0e0;
  justify-content: space-between;
  width: 100%;
`;

const TitleIcon = styled.div`
  color: #4a90e2;
  margin-right: 0.75rem;
`;

const Title = styled.h4`
  color: #333;
  font-weight: 600;
  margin: 0;
  font-size: 1.25rem;
  padding-bottom: 3px;
`;

const Subtitle = styled.span`
  color: #666;
  font-size: 0.875rem;
`;

const EnhancedCardTitle = ({ title, subtitle, icon: Icon, children }) => (
  <TitleWrapper>
    {/*<TitleIcon>*/}
    {/*  <Icon size={24} />*/}
    {/*</TitleIcon>*/}
    <div className="d-flex flex-column">
      <Title>{title}</Title>
      {subtitle && <Subtitle>{subtitle}</Subtitle>}
    </div>
    {children}
  </TitleWrapper>
);

EnhancedCardTitle.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  icon: PropTypes.elementType,
};

EnhancedCardTitle.defaultProps = {
  subtitle: '',
};

export default EnhancedCardTitle;
