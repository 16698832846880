/* eslint-disable jsx-a11y/label-has-for */
import React from 'react';

const Form = ({ children, className = '', innerRef, onSubmit }) => {
  return (
    <form ref={innerRef} onSubmit={onSubmit} className={`tw-w-full ${className}`}>
      {children}
    </form>
  );
};

export default Form;
