import React from 'react';
import PropTypes from 'prop-types';

import { findIndexes } from '../utils';

function LogicQuestionSelect({ sections, sectionId, questionId, value, onChange }) {
  const { sectionIndex, questionIndex } = findIndexes(sections, sectionId, questionId);

  function handleChange(select) {
    const [selected] = [...select.selectedOptions];
    const { type } = selected.dataset;

    const valObj = {
      value: select.value,
      type,
    };

    onChange(valObj);
  }

  return (
    <select className="select" value={value} onChange={(e) => handleChange(e.target)}>
      <option value="" disabled>
        Select Question
      </option>
      {sections.map((section, i) => {
        const { id, name, questions } = section;
        const label = name.length ? name : `Section ${i + 1}`;

        // don't show sections after current section OR
        // if first question in current section
        if (i > sectionIndex || (questionIndex === 0 && i >= sectionIndex)) return null;

        return (
          <optgroup key={id} label={label}>
            {questions.map(({ id: qId, number, text, type }, j) => {
              // don't show questions equal to or after current question in any section
              if (j >= questionIndex && i >= sectionIndex) return null;

              return (
                <option key={qId} value={`${id}.${qId}`} data-type={type}>
                  {`Q ${number || j + 1}${text.length ? `: ${text}` : ''}`}
                </option>
              );
            })}
          </optgroup>
        );
      })}
    </select>
  );
}

LogicQuestionSelect.propTypes = {
  sections: PropTypes.arrayOf(PropTypes.shape()),
  sectionId: PropTypes.string,
  questionId: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
};

LogicQuestionSelect.defaultProps = {
  sections: [],
  sectionId: '',
  questionId: '',
  value: '',
  onChange: () => {},
};

export default LogicQuestionSelect;
