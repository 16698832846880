import React, { useState, useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button } from 'reactstrap';
import { STATUS_OPTIONS } from '../../../Applications/Interviews/InterviewStatusSelect';
import BookingStatusItem from './BookingStatusItem';
import { removeSlot } from '@JS/actions/bookingActions';
import { useMounted } from '@Base/hooks';

function BookingStatus({ slotId, resetSlot, slotBookings, bookedInCandidates }) {
  const isMounted = useMounted();
  const [bookings, setBookings] = useState([]);

  useEffect(() => {
    if (slotId && isMounted()) {
      const sortedBookings = bookedInCandidates.sort((a, b) => a.firstName?.localeCompare(b.firstName));

      setBookings(sortedBookings);
      // reset cached slot
      resetSlot(slotId);
    }
  }, [bookedInCandidates, isMounted, resetSlot, slotId]);

  return (
    <Fragment>
      <ul className="list-unstyled mb-0">
        {STATUS_OPTIONS.map(({ value, label }) => (
          <li key={value} className="candidate-status">
            <BookingStatusItem
              status={value}
              label={label}
              candidates={bookings.filter((bking) => bking.status === value)}
              onStatusChange={(status, candidateIds) => {
                const updatedBookings = bookings.reduce((acc, booking) => {
                  acc.push({
                    ...booking,
                    ...(candidateIds.includes(booking.candidateId) && status ? { status } : {}),
                  });
                  return acc;
                }, []);

                setBookings(updatedBookings);
              }}
            />
          </li>
        ))}
      </ul>
      {!!(slotBookings[slotId] || []).length && (
        <div className="d-flex justify-content-end">
          <Button color="link" onClick={() => window.open(`/candidate?qId=${slotId}`)}>
            View detailed candidate information
          </Button>
        </div>
      )}
    </Fragment>
  );
}

BookingStatus.propTypes = {
  slotId: PropTypes.string,
  resetSlot: PropTypes.func,
  slotBookings: PropTypes.objectOf(PropTypes.arrayOf(PropTypes.string)),
  bookedInCandidates: PropTypes.arrayOf(PropTypes.shape()),
};

BookingStatus.defaultProps = {
  slotId: null,
  resetSlot: () => {},
  slotBookings: {},
  bookedInCandidates: [],
};

function mapStateToProps(state) {
  const {
    bookings: { slotBookings },
  } = state;
  return { slotBookings };
}

function mapDispatchToProps(dispatch) {
  return {
    resetSlot: (id) => dispatch(removeSlot(id)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(BookingStatus);
