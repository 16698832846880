import React, { memo, useContext } from 'react';
import PropTypes from 'prop-types';
import { Droppable } from 'react-beautiful-dnd';
import cx from 'classnames';
import styled from 'styled-components';
import StageLabel from './StageLabel';
import { WorkflowContext } from '@Base/contexts/WorflowContext';
import Tippy from '@tippyjs/react';

export function isStageRestricted(restrictions, profileSummary, stageId) {
  const filteredRestrictions = restrictions?.filter(({ id }) => id === stageId);

  if (!filteredRestrictions?.length) return false;

  const isRestricted = filteredRestrictions[0]?.restrictions?.some(({ component, enableState, id }) => {
    if (component === 'RTW') {
      const statesToCheck = ['REJECTED', 'CONTINUOUS', 'TIME_LIMITED'];
      const shouldCheckOutcomeStatus = statesToCheck.some((state) => enableState.includes(state));

      if (shouldCheckOutcomeStatus) {
        return profileSummary?.rightToWork?.data?.outcomeStatus
          ? !enableState.some((state) => state === profileSummary.rightToWork.data.outcomeStatus)
          : true;
      }

      return profileSummary?.rightToWork?.status
        ? !enableState.some((state) => state === profileSummary.rightToWork.status)
        : true;
    }

    if (component === 'FLEXIFORM') {
      return profileSummary?.genericForm?.length > 0
        ? profileSummary?.genericForm?.every((genericForm) => {
            return enableState.some((state) => state !== genericForm.status);
          })
        : true;
    }

    if (component === 'ONBOARDING') {
      return profileSummary?.onboarding?.status
        ? !enableState.some((state) => state === profileSummary.onboarding.status)
        : true;
    }

    if (component === 'EDOC') {
      return profileSummary?.edoc?.length > 0
        ? profileSummary?.edoc.every((edoc) => {
            return enableState.some((state) => state !== edoc.status);
          })
        : true;
    }
  });

  return isRestricted ? filteredRestrictions[0]?.restrictionMsg : false;
}

const StageElm = styled.div`
  ${({ stageWidth }) => `
    width: ${stageWidth}px;
    flex-basis: ${stageWidth}px;
  `}
`;

const Dropzone = styled.div`
  width: ${({ stageWidth, endStage }) => stageWidth - (endStage ? 5 : 10)}px;
`;

function FunnelStage({
  profileSummary,
  id,
  label,
  title,
  children,
  index,
  total,
  isCurrent,
  className,
  onDoubleClick,
  stageWidth,
}) {
  const { data } = useContext(WorkflowContext);
  const isRestricted = isStageRestricted(data, profileSummary, id);

  const elmstyle = isRestricted ? { backgroundColor: '#acacac' } : {};
  return (
    <Tippy
      // eslint-disable-next-line max-len
      content={isRestricted}
      delay={[1000, null]}
      maxWidth="250px"
      theme="ats"
      disabled={!isRestricted}
    >
      <StageElm
        id={id}
        className={cx(['stage', className, { 'is-current': isCurrent }])}
        data-stage-index={index}
        data-stage-total={total}
        stageWidth={stageWidth}
      >
        <Droppable droppableId={id} isDropDisabled={!!isRestricted}>
          {(provided, snapshot) => (
            <Dropzone
              ref={provided.innerRef}
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...provided.droppableProps}
              className={cx('stage-dropzone', { 'is-dragging': snapshot.isDraggingOver })}
              onDoubleClick={() => {
                if (!isCurrent && !isRestricted) onDoubleClick(id);
              }}
              isDragDisabled={isRestricted}
              stageWidth={stageWidth}
              endStage={index === total}
              style={elmstyle}
            >
              {children}
              <StageLabel className="drop-label" title={title}>
                {label}
              </StageLabel>
              {provided.placeholder}
            </Dropzone>
          )}
        </Droppable>
      </StageElm>
    </Tippy>
  );
}

FunnelStage.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  title: PropTypes.string,
  index: PropTypes.number,
  total: PropTypes.number,
  isCurrent: PropTypes.bool,
  className: PropTypes.string,
  onDoubleClick: PropTypes.func,
  stageWidth: PropTypes.number,
  profileSummary: PropTypes.shape(),
};

FunnelStage.defaultProps = {
  label: null,
  children: null,
  title: null,
  index: 0,
  total: 0,
  isCurrent: false,
  className: null,
  onDoubleClick: () => {},
  stageWidth: 120,
  profileSummary: {},
};

export default memo(FunnelStage);
