import React from 'react';
import PropTypes from 'prop-types';

function FlexEnd({ children, className }) {
  return <div className={`d-flex justify-content-end${className ? ` ${className}` : ''}`}>{children}</div>;
}

FlexEnd.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  className: PropTypes.string,
};

FlexEnd.defaultProps = {
  className: null,
};

export default FlexEnd;
