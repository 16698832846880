import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { ModalPopup } from '@Base/Modal';
import { TextButton } from '@Base/Buttons';
import { useLanguagePack } from '@Base/hooks';
import { EmailForm } from '../../Communication/email';
import { retryableAPICall } from '@API/common-api-utils';
import { cancelInvite } from '@API/SchedulingAPI/InterviewAPI';

async function doCancelInvite(inviteId, emailDetails = {}, onSuccess = () => {}, onError = () => {}) {
  if (inviteId) {
    const resp = await retryableAPICall(() => cancelInvite(inviteId, emailDetails));

    if (typeof resp === 'string' && resp.length) {
      onError();
    } else {
      onSuccess();
    }
  }
}

function CancellationModal({ isOpen, inviteId, candidate, onClose, onOkay, isTouch }) {
  const languagePack = useLanguagePack('interviews-container');
  const [sendingEmail, setSendingEmail] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [showEmailForm, setShowEmailForm] = useState(false);
  const [isEmailDataValid, setIsEmailDataValid] = useState(false);
  const [emailData, setEmailData] = useState();

  const { cancellation: langPack = {} } = languagePack;

  function reset() {
    setSubmitted(false);
    setSendingEmail(false);
    setShowEmailForm(false);
    setIsEmailDataValid(false);
    setEmailData();
  }

  const invalidEmail = !isEmailDataValid && submitted;
  let okayLabel = showEmailForm ? langPack.sendEmailOkayButtonLabel : langPack.okayButtonLabel;
  if (sendingEmail) okayLabel = langPack.okayButtonLabelAction;

  return (
    <ModalPopup
      isOpen={isOpen}
      title={langPack.cancelInviteTitle}
      closeOnOkay={false}
      onToggle={() => {
        onClose();
        reset();
      }}
      cancelLabel={langPack.cancelButtonLabel}
      okayLabel={okayLabel}
      onOkay={() => {
        setSubmitted(true);
        // if form enabled make sure there is valid data

        if ((showEmailForm && !emailData) || (showEmailForm && !isEmailDataValid)) return;

        let emailMsg;
        if (emailData) {
          const { message, ...rest } = emailData;
          emailMsg = { ...rest, htmlContent: emailData?.message };
        }
        doCancelInvite(
          inviteId,
          emailMsg,
          () => {
            toast.success(langPack.cancelSuccess);
            onOkay();
            reset();
          },
          () => toast.error(langPack.sendError),
        );
      }}
      footer={({ OkayButton, CancelButton }) => (
        <Fragment>
          <CancelButton />
          <OkayButton disabled={sendingEmail} />
        </Fragment>
      )}
      maxHeight={`${isTouch ? 100 : 75}vh`}
    >
      {langPack.cancelInviteContent && <p>{langPack.cancelInviteContent}</p>}
      <TextButton
        label={showEmailForm ? langPack.closeEmailButtonLabel : langPack.composeButtonLabel || 'Show Email'}
        size="sm"
        floatRight={false}
        className="my-2"
        action={() => {
          reset();
          setShowEmailForm(!showEmailForm);
        }}
      />
      {showEmailForm && (
        <Fragment>
          <h5 className="mt-2">{langPack.emailHeading}</h5>
          <div className="invalid-feedback" style={{ display: invalidEmail ? 'block' : 'none' }}>
            {langPack.emailError}
          </div>
          <EmailForm
            data={{ ...emailData }}
            wrapperClassName="mt-3"
            actionType="INVITE_WITHDRAWN"
            removeInputs={['ccAddresses', 'bccAddresses', 'attachments']}
            externalSend
            externalSubmitted={submitted}
            applicants={[candidate]}
            onChange={(formData, validity) => {
              const { fromName, replyTo, subject, message, delay } = formData;

              setEmailData({
                fromName,
                replyTo,
                subject,
                message,
                delay,
              });
              setIsEmailDataValid(validity.isValid);
            }}
          />
        </Fragment>
      )}
    </ModalPopup>
  );
}

CancellationModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  onOkay: PropTypes.func,
  inviteId: PropTypes.string,
  candidate: PropTypes.shape({
    applicantId: PropTypes.string.isRequired,
    applicantEmail: PropTypes.string,
  }).isRequired,
  isTouch: PropTypes.bool,
};

CancellationModal.defaultProps = {
  isOpen: false,
  onClose: () => {},
  onOkay: () => {},
  inviteId: null,
  isTouch: false,
};

export default CancellationModal;
