import axios from 'axios';
import { getCommonHeaders } from '../common-api-utils';
import config from '../../config/config';

const { lms, api } = config;

export async function getLMSSummary(candidateId) {
  const result = await axios.get(`${api.middlewareAPIURL}${lms.basePath}/${candidateId}`, {
    headers: getCommonHeaders(),
  });

  if (result.status === 201 || result.status === 200) return result.data;
  return null;
}

export async function updateLMSSummary(courses) {
  const result = await axios.put(`${api.middlewareAPIURL}${lms.basePath}/modules/update`, courses, {
    headers: getCommonHeaders(),
  });

  if (result.status === 201 || result.status === 200) return result.data;
  return null;
}
