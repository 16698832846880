import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Tippy from '@tippyjs/react';
import EnhancedCard from '../../Common/EnhancedCard';

function JobBoardEntry({
  logo,
  name,
  description,
  onChange,
  alreadyPublished,
  id,
  children,
  canToggle,
  type,
  disablable,
}) {
  const [isSelected, setIsSelected] = useState(false);

  useEffect(() => {
    setIsSelected(alreadyPublished);
  }, [alreadyPublished]);

  return (
    <EnhancedCard className="mb-3">
      <div className="tw-flex tw-items-center">
        <img src={logo} alt={`${name} logo`} className="tw-h-10 tw-w-auto" />
        <div className="tw-ml-4">
          <h6 className="tw-text-lg tw-font-semibold">{name}</h6>
          <p className="tw-text-sm tw-text-gray-600">{description}</p>
        </div>
        {canToggle && (
          <div class="form-check form-switch ms-auto fit-content">
            <Tippy
              content="Indeed require all vacancies published to your career site to also be published to Indeed"
              theme="ats"
              disabled={type !== 'INDEED' || !disablable}
            >
              <div className="tw-w-10 tw-inline-block">
                <input
                  id={`toggle-details-for-${name ? name.replace(' ', '') : ''}`}
                  class="text-muted rememberme-switch form-check-input float-end"
                  type="checkbox"
                  disabled={type === 'INDEED' && disablable}
                  checked={isSelected}
                  onChange={() => {
                    setIsSelected(!isSelected);
                    onChange(id, !isSelected);
                  }}
                />
              </div>
            </Tippy>
          </div>
        )}
      </div>
      {isSelected && children && (
        <Fragment>
          <hr className="tw-my-3 tw-border-gray-300" />
          <div className="tw-grid tw-grid-cols-1 tw-mt-3">{children}</div>
        </Fragment>
      )}
    </EnhancedCard>
  );
}

JobBoardEntry.propTypes = {
  canToggle: PropTypes.bool,
  logo: PropTypes.string.isRequired,
  name: PropTypes.string,
  description: PropTypes.string,
  onChange: PropTypes.func,
  id: PropTypes.string,
  width: PropTypes.string,
  alreadyPublished: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
};

JobBoardEntry.defaultProps = {
  canToggle: true,
  name: null,
  description: null,
  onChange: () => {},
  id: null,
  width: 'auto',
  alreadyPublished: false,
  children: null,
};

export default JobBoardEntry;
