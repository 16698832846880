import React from 'react';
import { CreateButton } from '@Base/Buttons';
import { Button } from 'reactstrap';

const ActionToBePerformedNoDataState = ({ image, title, subTitle, button, description }) => {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '20px' }}>
      <img src={image} alt="No Data" style={{ maxWidth: '100%', marginBottom: '2px', opacity: 0.7 }} width="200px" />
      <h2 style={{ marginTop: '5px' }}>{title}</h2>
      {subTitle && <h5 style={{ marginTop: '5px', marginBottom: '15px' }}>{subTitle}</h5>}
      {description && <p style={{ textAlign: 'center', maxWidth: '65%', marginBottom: '15px' }}>{description}</p>}
      {button}
    </div>
  );
};

export default ActionToBePerformedNoDataState;
