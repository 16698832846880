import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import { connect } from 'react-redux';
import { TagDisplay } from '@Base/Tags';
import { IconSVG } from '@Base/SVG';
import { BuildDetails } from '../Candidates/CandidateDetails';

function OtherApplication({ app, languagePack, accountAccess }) {
  const { id, funnelHistory, vacancyRef, vacancyName, appliedOnDate, accountName, tags, vacancyId } = app;
  const hasAccountAccess = accountAccess.find((acc) => acc.accountName === accountName);

  const {
    vacancyReferenceLabel,
    vacancyTitleLabel,
    appliedOnLabel,
    funnelStageLabel,
    funnelStageReason,
    accountNameLabel,
    tagsLabel,
    viewAppLabel,
  } = languagePack;

  const latestStage = funnelHistory && funnelHistory[app.funnelHistory.length - 1];

  const application = [
    { title: vacancyReferenceLabel, value: vacancyRef },
    { title: vacancyTitleLabel, value: vacancyName },
    { title: appliedOnLabel, value: appliedOnDate },
    { title: funnelStageLabel, value: latestStage ? latestStage.stageName : null },
    // eslint-disable-next-line max-len
    {
      title: funnelStageReason,
      value:
        latestStage && latestStage.stageReason
          ? latestStage.stageReason.charAt(0).toUpperCase() + latestStage.stageReason.slice(1)
          : null,
    },
    { title: accountNameLabel, value: accountName },
  ];

  if (tags && tags.length) {
    application.push({
      title: tagsLabel,
      value: app.tags.map((tag) => <TagDisplay key={tag.id} deleteTag={false} tag={tag} />),
      childTag: 'div',
    });
  }

  return (
    <Fragment>
      {hasAccountAccess && (
        <Button
          size="sm"
          className="position-absolute"
          style={{ right: '0', top: '0', zIndex: 1 }}
          onClick={() => window.open(`/vacancy/view?id=${vacancyId}&action=openSlider&actionId=${id}`)}
        >
          <IconSVG name="Paper Plane" className="me-2" />
          {viewAppLabel}
        </Button>
      )}
      {application.map(({ title, value, childTag }, i) => (
        <BuildDetails
          // eslint-disable-next-line react/no-array-index-key
          key={`app-details-${i}`}
          title={title}
          titleWidth={5}
          touchTitleColumnSize={5}
          childTag={childTag}
        >
          {value}
        </BuildDetails>
      ))}
    </Fragment>
  );
}

OtherApplication.propTypes = {
  app: PropTypes.shape(),
  languagePack: PropTypes.shape(),
  accountAccess: PropTypes.arrayOf(PropTypes.shape()),
};

OtherApplication.defaultProps = {
  app: null,
  languagePack: null,
  accountAccess: [],
};

function mapStateToProps(state) {
  const {
    userData: {
      userDetails: {
        data: { accountAccess },
      },
    },
  } = state;
  return { accountAccess };
}

export default connect(mapStateToProps)(OtherApplication);
