import { FormGroup, Input, Label } from 'reactstrap';
import Select from 'react-select';
import React from 'react';

const comparisons = [
  { label: 'Less Than', value: 'LT' },
  { label: 'Equal To', value: 'EQ' },
  { label: 'Greater Than', value: 'GT' },
];

function IAIDecisionCriteria({ option, setOption }) {
  return (
    <>
      <FormGroup>
        <Label for="decision-criteria" style={{ marginTop: '0px', marginBottom: '3px' }}>
          Choose which evaluation you'd like to use
        </Label>
        <Select
          classNamePrefix="react-select"
          id="filter-question-select"
          isClearable
          onChange={(selectedOpt) => {
            setOption({ operation: selectedOpt?.value, value: '' });
          }}
          options={comparisons}
          placeholder="Choose Evaluation"
          value={comparisons.find((a) => a.value === option?.condition?.operation)}
        />
      </FormGroup>
      <FormGroup>
        <Label for="decision-criteria" style={{ marginTop: '0px', marginBottom: '3px' }}>
          Enter the overall score
        </Label>
        <Input
          id="decision-criteria"
          className="mt-2"
          type="number"
          max="100"
          min="1"
          placeholder="Overall Score"
          value={option?.condition?.value}
          onChange={(e) => {
            setOption({ value: e.target.value, operation: option?.condition?.operation });
          }}
        />
      </FormGroup>
    </>
  );
}

export default IAIDecisionCriteria;
