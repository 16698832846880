import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import momentTimezonePlugin from '@fullcalendar/moment-timezone';

import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import bootstrapPlugin from '@fullcalendar/bootstrap';
import moment from 'moment';
import { dateTimeUtils } from '../../../utils';
import { checkPermissions } from '@JS/auth/AuthUtils';
import CalendarSlot from '../../Calendar/CalendarSlot';
import styled from 'styled-components';

const StyledCalendar = styled.div`
  ${({ height }) => `
  height: ${height}px;
`}

  .fc-event-resizable {
    pointer-events: none;
  }
`;

function ReducedCalendar({ events, activeAccountId, onEventCreate, onRender, modalRef, timezone }) {
  const calRef = useRef(null);
  const [wrapperHeight, setWrapperHeight] = useState(0);
  const [calKey, setCalKey] = useState('');

  useEffect(() => {
    // when accountId changes force calendar refresh using key prop as activeAccountId
    setCalKey((prevState) => (prevState !== activeAccountId ? activeAccountId : prevState));
  }, [activeAccountId]);

  useEffect(() => {
    if (modalRef.current) {
      const modalHeight = modalRef.current.offsetHeight - 300;
      const { top } = modalRef.current.getBoundingClientRect();
      const availableSpace = modalHeight - Math.ceil(top) - 50; // Adjust this as needed
      setWrapperHeight(Math.max(availableSpace, 500));
    }
  }, [modalRef]);

  const currentTime = new Date();
  const formattedTime = `${currentTime.getHours()}:${currentTime.getMinutes()}}`;

  return (
    <StyledCalendar id="reduced-calendar" className="mt-4" height={wrapperHeight} key={calKey}>
      <FullCalendar
        ref={calRef}
        events={events}
        initialView="timeGridWeek"
        firstDay={1}
        timeZone={timezone}
        plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin, bootstrapPlugin, momentTimezonePlugin]}
        themeSystem="bootstrap5"
        height="100%"
        slotDuration="00:15:00"
        slotMinTime="00:00:00"
        slotMaxTime="24:00:00"
        disableResizing={true}
        eventDurationEditable={false}
        eventStartEditable={false}
        views={{
          timeGrid: {
            dayHeaderContent: ({ date }) => moment(date).format('ddd D/M'),
          },
        }}
        editable={false}
        selectable
        selectMirror
        scrollTime={formattedTime}
        longPressDelay={500}
        select={(dateInfo) => onEventCreate(dateInfo)}
        selectAllow={(dateInfo) => {
          if (checkPermissions(['scheduler:calendar:create'])) {
            return dateTimeUtils.isFuture(dateInfo.start);
          }
          return false;
        }}
        eventContent={(eventInfo) => <CalendarSlot {...eventInfo} isDisabled />}
        datesSet={({ view }) => {
          const { type, title, activeStart, activeEnd } = view;
          onRender({
            type,
            title,
            activeStart,
            activeEnd,
          });
        }}
        eventTimeFormat={{
          hour: 'numeric',
          minute: '2-digit',
          meridiem: 'short',
        }}
        headerToolbar={{
          left: 'prev,next',
          center: 'title',
          right: '',
        }}
      />
    </StyledCalendar>
  );
}

ReducedCalendar.propTypes = {
  events: PropTypes.arrayOf(
    PropTypes.shape({
      start: PropTypes.instanceOf(Date),
      end: PropTypes.instanceOf(Date),
      allDay: PropTypes.bool,
      title: PropTypes.string,
      extendedProps: PropTypes.shape(),
    }),
  ),
  activeAccountId: PropTypes.string,
  onEventCreate: PropTypes.func,
  onRender: PropTypes.func,
  modalRef: PropTypes.object.isRequired, // Add modalRef as a required prop
};

ReducedCalendar.defaultProps = {
  events: [],
  activeAccountId: '',
  onEventCreate: () => {},
  onRender: () => {},
};

export default ReducedCalendar;
