import React, { useState } from 'react';
import { ModalPopup } from '@Base/Modal';
import TimezoneDropdown from './TimezoneDropdown';

import { getTimeZones } from '../../utils/dateTimeUtils';

const TimezonePicker = ({ currentTimezone, onTimezoneChange }) => {
  const [isOpen, setIsOpen] = useState(false);

  const timeZones = getTimeZones();

  const displayZone = timeZones.find((a) => a.value === currentTimezone);

  return (
    <>
      <button onClick={() => setIsOpen(true)} className=" tw-font-normal tw-text-[12px]">
        Time Zone:{' '}
        <span className="tw-hover:text-blue-800 tw-underline" style={{ color: '#3788d8' }}>
          {displayZone?.label}
        </span>
      </button>
      <ModalPopup
        hideCancelButton
        isOpen={isOpen}
        minHeight="55px"
        allowOverflow={true}
        onToggle={() => setIsOpen(false)}
        title="Select Timezone"
        size="md"
      >
        <TimezoneDropdown onTimezoneChange={onTimezoneChange} currentTimezone={currentTimezone} />
      </ModalPopup>
    </>
  );
};

export default TimezonePicker;
