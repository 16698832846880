import React from 'react';
import { FormGroup, Label, Button } from 'reactstrap';
import Select from 'react-select';
import { Trash } from 'lucide-react';
import Decision from './index';
import { CreateButton } from '@Base/Buttons';
import styled from 'styled-components';

const BetaPill = styled.span`
  background-color: #6366f1;
  color: white;
  padding: 2px 6px;
  border-radius: 12px;
  font-size: 0.7rem;
  margin-left: 8px;
  font-weight: 500;
`;

const OptionWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 8px 12px;

  &:hover {
    cursor: pointer;
  }
`;

const CustomOption = ({ children, ...props }) => {
  const { innerProps, isSelected, isFocused } = props;
  const isAIDecision = props.data.value === 'aiDecision';

  return (
    <OptionWrapper
      {...innerProps}
      style={{
        backgroundColor: isSelected ? '#2684FF' : isFocused ? '#DEEBFF' : 'transparent',
        color: isSelected ? 'white' : 'inherit',
      }}
    >
      <span>{children}</span>
      {isAIDecision && <BetaPill style={{ color: isSelected ? 'white' : 'white' }}>BETA</BetaPill>}
    </OptionWrapper>
  );
};

const optionsList = [
  { label: 'Application Questions', value: 'applicationQuestion' },
  { label: 'Onboarding Questions', value: 'onboardingQuestion' },
  { label: 'Interview Questions', value: 'interviewQuestion' },
  { label: 'AI Decision', value: 'aiDecision' },
  { label: 'DBS Status', value: 'dbsCheckSummary.currentStatus.status' },
  { label: 'E-document Status', value: 'edocSummary.currentStatus.status' },
  { label: 'Event Status', value: 'inviteSummary.currentStatus.status' },
  { label: 'FlexiForm Status', value: 'genericFormSummary.currentStatus.status' },
  { label: 'Funnel Status', value: 'funnelStatus' },
  { label: 'Interviewer AI Score', value: 'overallScore' },
  { label: 'Onboarding Status', value: 'onboardingSummary.currentStatus.status' },
  { label: 'Personality Test Status', value: 'personalityTestSummary.currentStatus.status' },
  { label: 'Reference Status', value: 'referenceSummary.currentStatus.status' },
  { label: 'RTW Status', value: 'rtwSummary.currentStatus.status' },
];

const OperatorList = [
  { label: 'AND', value: 'AND' },
  { label: 'OR', value: 'OR' },
];

const StyledContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

const ModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const StyledNodeContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const ConditionContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  border: 1px solid lightGray;
  border-radius: 5px;
  padding: 10px;
  margin-bottom: 10px;
`;

const ConditionalBox = ({ selectedNode, onChange }) => {
  const nodes = {
    conditions: selectedNode?.conditions || [],
    conditionLogic: selectedNode.conditionLogic || 'AND',
  };

  const handleRemoveCondition = (index) => {
    const newNodes = nodes.conditions.filter((_, i) => i !== index);
    onChange({ conditions: newNodes });
  };

  return (
    <ModalWrapper>
      <StyledContainer>
        <FormGroup className="w-100">
          <Label>Logic Type</Label>
          <Select
            options={OperatorList}
            value={OperatorList.find((a) => a.value === nodes.conditionLogic)}
            onChange={(a) => {
              onChange({ conditionLogic: a.value });
            }}
            className="w-100 react-select"
          />
        </FormGroup>
      </StyledContainer>
      <StyledNodeContainer>
        {nodes?.conditions?.map((group, index) => (
          <ConditionContainer key={index}>
            <FormGroup className="w-100" style={{ flexGrow: 1, display: 'flex', gap: '1rem' }}>
              <Select
                className="react-select w-100"
                options={optionsList}
                value={optionsList.find((a) => a.value === group?.value)}
                components={{
                  Option: CustomOption,
                }}
                onChange={(updateValue) => {
                  const newNodes = nodes.conditions.map((curr, curIdx) =>
                    curIdx === index ? { value: updateValue?.value, condition: undefined } : curr,
                  );
                  onChange({ conditions: newNodes });
                }}
              />
              <Button color="danger" size="sm" onClick={() => handleRemoveCondition(index)}>
                <Trash size={16} />
              </Button>
            </FormGroup>
            <div className="w-100">
              <Decision
                option={group}
                setOption={(updateValue) => {
                  const newNodes = nodes.conditions.map((curr, currIndex) => {
                      debugger
                      return currIndex === index ? { value: curr.value, condition: updateValue } : curr
                    }
                  );
                  onChange({ conditions: newNodes });
                }}
              />
            </div>
          </ConditionContainer>
        ))}
      </StyledNodeContainer>
      <CreateButton
        className="w-100 mb-3"
        label="Add Condition"
        action={() =>
          onChange({
            conditions: [...nodes.conditions, { value: 'funnelStatus', condition: 'HIRED' }],
          })
        }
      />
    </ModalWrapper>
  );
};

export default ConditionalBox;
