import { useEffect, useCallback, useState } from 'react';
import getLanguagePack from '@JS/language/language-pack';

export default function useLanguagePack(page) {
  const [pack, setPack] = useState({});

  const getPack = useCallback(async () => {
    const lang = await getLanguagePack(page);
    if (!Object.keys(pack).length) setPack(lang);
  }, [pack, page]);

  useEffect(() => {
    if (page) getPack();
  }, [getPack, page]);

  return pack;
}
