/* eslint-disable jsx-a11y/label-has-for */
// eslint-disable-next-line no-unused-vars
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'reactstrap';
import { Mention, MentionsInput } from 'react-mentions';
import { TextButton } from '@Base/Buttons';
import { retryableAPICall } from '@API/common-api-utils';
import { createNote } from '@API/CandidateAPI/CandidateAPI';
import validation from '@JS/utils/validation';
import styling from './mentions-style';
import mentionStyling from './mention-style';
import tagStyling from './tag-style';
import { findUsersForAccount } from '@API/AccountAPI';
import { getTags } from '@Base/Tags/utils';

function NoteForm({ accountId, applicantId, onSuccess, onError, data, onChange, languagePack, pushChange }) {
  const [sending, setSending] = useState(false);
  const [errors, setErrors] = useState({});
  const [content, setContent] = useState(data);
  const [submitted, setSubmitted] = useState(false);
  const [tags, setTags] = useState([]);

  const contentConfig = { id: 'content', required: true };

  function handleChange(val, id) {
    if (submitted) {
      const inpErrs = validation([contentConfig], { [id]: val });
      setErrors({ ...errors, ...inpErrs });
    }

    setContent(val);
    onChange(val);
  }

  async function handleCreate(errCount, convertedContent) {
    if (errCount > 0) return;

    setSending(true);

    const response = await retryableAPICall(() => createNote(convertedContent, [applicantId]));

    if (typeof response === 'string') {
      onError(languagePack.noteError);
    } else {
      pushChange(response);
      onSuccess(languagePack.noteSuccess);
      setContent('');
      onChange('');
    }

    setSending(false);
  }

  function handleSubmit() {
    setSubmitted(true);

    const errObj = validation([contentConfig], { content });
    setErrors(errObj);

    const errCount = Object.values(errObj).filter(({ invalid }) => invalid).length;

    handleCreate(errCount, content);
  }

  async function fetchUsers(searchTerm, callback) {
    const result = await findUsersForAccount(searchTerm, accountId);

    callback(result.splice(0, 10).map((user) => ({ display: user.name, id: user.id })));
  }

  useEffect(() => {
    getTags((result) => {
      setTags(result.map((tag) => ({ display: tag.name, id: tag.id })));
    });
  }, []);

  return (
    <div>
      <Form
        className="clearfix"
        noValidate
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit();
        }}
      >
        <MentionsInput
          className="mentions_textarea"
          multiline
          onChange={(val) => handleChange(val.target.value, contentConfig.id)}
          placeholder="Type @ to mention and notify someone or # to tag this candidate via a note"
          style={styling}
          value={content}
        >
          <Mention
            data={fetchUsers}
            displayTransform={(id, name) => `@${name}`}
            markup="@[__display__](__id__)"
            style={mentionStyling}
            trigger="@"
          />
          <Mention
            data={tags}
            displayTransform={(id, name) => `#${name}`}
            markup="#[__display__](__id__)"
            style={tagStyling}
            trigger="#"
          />
        </MentionsInput>
        <TextButton
          className="mt-2"
          disabled={sending || content.trim().length === 0}
          floatRight
          isLoading={sending}
          label={sending ? `${languagePack.buttonSaving}` : `${languagePack.createNote}`}
          type="submit"
        />
      </Form>
    </div>
  );
}

NoteForm.propTypes = {
  accountId: PropTypes.string.isRequired,
  applicantId: PropTypes.string.isRequired,
  data: PropTypes.string,
  languagePack: PropTypes.shape(),
  onChange: PropTypes.func,
  onError: PropTypes.func,
  onSuccess: PropTypes.func,
  pushChange: PropTypes.func,
};

NoteForm.defaultProps = {
  data: '',
  languagePack: {},
  onChange: () => {},
  onError: () => {},
  onSuccess: () => {},
  pushChange: () => {},
};

export default NoteForm;
