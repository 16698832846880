import React, { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { submitFeedback } from '@API/AnalyticsAPI/AnalyticsAPI';

const Alert = ({ className = '', children }) => {
  return (
    <div className={`tw-p-4 tw-rounded-lg tw-border ${className}`} role="alert">
      {children}
    </div>
  );
};

const AlertDescription = ({ className = '', children }) => {
  return <div className={`tw-text-sm ${className}`}>{children}</div>;
};

const FullPageFeedback = () => {
  const [searchParams] = useSearchParams();
  const [rating, setRating] = useState(null);
  const [userId, setUserId] = useState(null);
  const [comment, setComment] = useState('');
  const [inputId, setInputId] = useState('');
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitStatus, setSubmitStatus] = useState(null);

  useEffect(() => {
    const scoreFromUrl = searchParams.get('score');
    const id = searchParams.get('id');

    if (scoreFromUrl && ['1', '2', '3', '4'].includes(scoreFromUrl)) {
      setRating(parseInt(scoreFromUrl));
    }

    if (id) {
      setUserId(id);
      handleInitialSubmit(parseInt(scoreFromUrl), id);
    }
  }, [searchParams]);

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const validateForm = () => {
    const newErrors = {};

    if (!userId && !inputId) {
      newErrors.email = 'Email address is required';
    } else if (!userId && !validateEmail(inputId)) {
      newErrors.email = 'Please enter a valid email address';
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleInitialSubmit = async (rating, id) => {
    if (id && rating) {
      try {
        await submitFeedback({
          feature: 'GENERAL_FEEDBACK',
          action: 'RATING',
          rating: rating,
          platform: 'ATS',
          uniqueId: id,
        });
      } catch (error) {
        setSubmitStatus('error');
      }
    }
  };

  const handleCommentSubmit = async () => {
    if (!validateForm()) return;

    setIsSubmitting(true);
    setSubmitStatus(null);

    try {
      await submitFeedback({
        feature: 'GENERAL_FEEDBACK',
        action: 'RATING',
        rating: rating,
        comment: comment.trim(),
        platform: 'ATS',
        uniqueId: inputId || userId,
      });
      setComment('');
      setSubmitStatus('success');
    } catch (error) {
      setSubmitStatus('error');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="tw-min-h-screen tw-flex tw-flex-col tw-items-center tw-justify-center tw-p-8 tw-bg-gray-50">
      <div className="tw-bg-white tw-rounded-2xl tw-p-8 tw-shadow-md tw-max-w-2xl tw-w-full">
        <h1 className="tw-text-2xl tw-font-semibold tw-text-gray-800 tw-mb-6 tw-text-center">
          Thank you for your feedback!
        </h1>

        <div className="tw-flex tw-gap-6 tw-justify-center tw-mb-8">
          {[
            { value: 1, emoji: '😭' },
            { value: 2, emoji: '😐' },
            { value: 3, emoji: '🙂' },
            { value: 4, emoji: '😍' },
          ].map((item) => (
            <button
              key={item.value}
              className={`tw-p-4 tw-rounded-full tw-text-4xl tw-transition-all ${
                rating === item.value
                  ? 'tw-border-2 tw-border-blue-500 tw-bg-blue-50'
                  : 'tw-border-2 tw-border-transparent'
              } tw-opacity-40 tw-cursor-default`}
              aria-label={`Rate ${item.value} out of 4`}
            >
              {item.emoji}
            </button>
          ))}
        </div>

        {!userId && (
          <div className="tw-mb-6">
            <label htmlFor="email-input" className="tw-block tw-text-sm tw-font-medium tw-text-gray-700 tw-mb-2">
              Email address<span className="tw-text-red-500">*</span>
            </label>
            <input
              id="email-input"
              type="email"
              value={inputId}
              onChange={(e) => {
                setInputId(e.target.value);
                if (errors.email) setErrors({});
              }}
              placeholder="Enter your email address"
              className={`tw-w-full tw-p-4 tw-border tw-rounded-lg tw-transition-colors ${
                errors.email ? 'tw-border-red-500' : 'tw-border-gray-200'
              } tw-focus:outline-none tw-focus:border-blue-500 tw-focus:ring-2 tw-focus:ring-blue-100`}
            />
            {errors.email && (
              <p className="tw-mt-2 tw-text-sm tw-text-red-600" id="email-error">
                {errors.email}
              </p>
            )}
          </div>
        )}

        <div className="tw-mb-6">
          <label htmlFor="comment-input" className="tw-block tw-text-sm tw-font-medium tw-text-gray-700 tw-mb-2">
            Additional comments
            <span className="tw-text-gray-500 tw-font-normal"> (optional)</span>
          </label>
          <textarea
            id="comment-input"
            value={comment}
            onChange={(e) => setComment(e.target.value)}
            placeholder="Share any additional thoughts or suggestions"
            className="tw-w-full tw-min-h-[150px] tw-p-4 tw-border tw-border-gray-200 tw-rounded-lg tw-resize-none tw-focus:outline-none tw-focus:border-blue-500 tw-focus:ring-2 tw-focus:ring-blue-100"
          />
        </div>

        {submitStatus === 'success' && (
          <Alert className="tw-mb-6 tw-border-green-500 tw-bg-green-50">
            <AlertDescription className="tw-text-green-800">
              Thank you! Your feedback has been submitted successfully.
            </AlertDescription>
          </Alert>
        )}

        {submitStatus === 'error' && (
          <Alert className="tw-mb-6 tw-border-red-500 tw-bg-red-50">
            <AlertDescription className="tw-text-red-800">
              There was an error submitting your feedback. Please try again.
            </AlertDescription>
          </Alert>
        )}

        <button
          onClick={handleCommentSubmit}
          disabled={isSubmitting || (!comment.trim() && !(userId || inputId))}
          className="tw-w-full tw-p-4 tw-bg-blue-500 tw-text-white tw-rounded-lg tw-font-medium tw-text-base tw-transition-colors hover:tw-bg-blue-600 disabled:tw-bg-gray-300 disabled:tw-cursor-not-allowed"
        >
          {isSubmitting ? 'Submitting...' : 'Submit Additional Feedback'}
        </button>
      </div>
    </div>
  );
};

export default FullPageFeedback;
