import React, { useState } from 'react';
import { Search, X } from 'lucide-react';
import 'react-dates/initialize';

const withSearch = (WrappedComponent) => {
  return function WithSearchComponent(props) {
    const [searchTerm, setSearchTerm] = useState('');
    return <WrappedComponent {...props} searchTerm={searchTerm} setSearchTerm={setSearchTerm} />;
  };
};

const BaseCheckboxFilter = ({ options = [], value = [], onChange, searchTerm, setSearchTerm }) => {
  const filteredOptions = options.filter((option) => option.label.toLowerCase().includes(searchTerm.toLowerCase()));

  const allFilteredSelected = filteredOptions.every((option) => value.includes(option.value));
  const noFilteredSelected = filteredOptions.every((option) => !value.includes(option.value));

  const handleSelectAll = () => {
    const optionsToSelect = searchTerm ? filteredOptions : options;
    const newValues = [...new Set([...value, ...optionsToSelect.map((o) => o.value)])];
    onChange(newValues);
  };

  const handleRemoveAll = () => {
    const optionsToRemove = searchTerm ? filteredOptions : options;
    const removeValues = new Set(optionsToRemove.map((o) => o.value));
    const newValue = value.filter((v) => !removeValues.has(v));
    onChange(newValue);
  };

  return (
    <div className="tw-w-80 tw-border tw-rounded-lg tw-shadow-sm">
      <div className="tw-p-2 tw-border-b">
        <div className="tw-relative">
          <input
            type="text"
            className="tw-w-full tw-pl-8 tw-pr-8 tw-py-1.5 tw-text-sm tw-border tw-rounded-md focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-blue-500 focus:tw-border-blue-500"
            placeholder="Search"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <Search className="tw-absolute tw-left-2 tw-top-1/2 -tw-translate-y-1/2 tw-h-4 tw-w-4 tw-text-gray-400" />
          {searchTerm && (
            <button
              onClick={() => setSearchTerm('')}
              className="tw-absolute tw-right-2 tw-top-1/2 -tw-translate-y-1/2 tw-flex tw-items-center tw-justify-center tw-text-gray-400 hover:tw-text-gray-600"
            >
              <X className="tw-h-4 tw-w-4" />
            </button>
          )}
        </div>
      </div>
      <div className="tw-flex tw-gap-2 tw-p-2 tw-border-b">
        <button
          onClick={handleSelectAll}
          disabled={allFilteredSelected}
          className={`tw-flex-1 tw-text-xs tw-font-medium ${
            allFilteredSelected ? 'tw-text-gray-400 tw-cursor-default' : 'tw-text-blue-600 hover:tw-text-blue-700'
          }`}
        >
          Select all
        </button>
        <div className="tw-w-px tw-bg-gray-300" />
        <button
          onClick={handleRemoveAll}
          disabled={noFilteredSelected}
          className={`tw-flex-1 tw-text-xs tw-font-medium ${
            noFilteredSelected ? 'tw-text-gray-400 tw-cursor-default' : 'tw-text-blue-600 hover:tw-text-blue-700'
          }`}
        >
          Remove all
        </button>
      </div>
      <div className="tw-max-h-60 tw-overflow-y-auto">
        <div className="tw-flex tw-flex-col">
          {filteredOptions.map((option) => (
            <label
              key={option.value}
              className="tw-flex tw-items-center tw-px-4 tw-py-2 hover:tw-bg-gray-100 tw-cursor-pointer tw-min-w-0"
            >
              <input
                type="checkbox"
                checked={value.includes(option.value)}
                className="tw-mr-3 tw-shrink-0"
                onChange={() => {
                  const newValue = value.includes(option.value)
                    ? value.filter((v) => v !== option.value)
                    : [...value, option.value];
                  onChange(newValue);
                }}
              />
              <span className="tw-truncate bottom-0">{option.label}</span>{' '}
            </label>
          ))}
        </div>
      </div>
    </div>
  );
};

const CheckboxFilter = withSearch(BaseCheckboxFilter);

export default CheckboxFilter;
