import PropTypes from 'prop-types';
import React, { useRef, useState, useCallback } from 'react';
import { toast } from 'react-toastify';
import { Button, Card, CardBody, Col, Row } from 'reactstrap';
import { IconSVG } from '@Base/SVG';
import { LoadingScreen } from '@Base/Loading';
import { checkPermissions } from '@JS/auth/AuthUtils';
import { ActionButton, IconButton } from '@Base/Buttons';
import { HeadingPushRight } from '@Base/Headings';
import { useLanguagePack, useOutsideClick, useMounted } from '@Base/hooks';
import { EmailForm } from '../../Communication';
import InterviewerAICompletedContainer from './InterviewerAICompletedContainer';
import InterviewerAIRequestedContainer from './InterviewerAIRequestedContainer';
import ActionToBePerformedNoDataState from '../Other/ActionToBePerformedNoDataState';
import personalityTest from '../../../assets/img/nodata/personality-test.png';
import InterviewSelect from './InterviewSelect';

function InterviewerAIDetails({ candidate, isTouch, onSuccess, onError, interviewStatus, updateStatus, updateInterviewLink }) {
  const status = interviewStatus.status;
  // State
  const languagePack = useLanguagePack('interviewer-ai');
  const [isLoading, setIsLoading] = useState(false);
  const [selectedInterview, setSelectedInterview] = useState(null);
  const [isActionOpen, setIsActionOpen] = useState(false);
  const [isInfoSectionExpanded, setIsInfoSectionExpanded] = useState(false);

  // Refs
  const wrapperRef = useRef();

  // Error Handlers
  const handleError = useCallback((message) => {
    onError(message || languagePack.interviewerAILoadError || 'Could not load Interviewer AI information please try again later');
  }, [onError, languagePack.interviewerAILoadError]);

  const handleRequestError = useCallback((error) => {
    const errorMessage = error?.message || languagePack.interviewerAIRequestError || 'Failed to request Interviewer AI interview. Please try again later.';
    onError(errorMessage);
    setIsLoading(false);
  }, [onError, languagePack.interviewerAIRequestError]);

  // Event Handlers
  const handleSubmissionSuccess = useCallback((message, data) => {
    updateStatus(data.status, data.interviewLink);
    onSuccess(languagePack.interviewerAIReqSuccess);
    setIsLoading(false);
    setIsInfoSectionExpanded(false);
  }, [languagePack.interviewerAIReqSuccess, onSuccess, updateStatus]);

  // Menu Items
  const menuItems = [];

  if (['REQUESTED', 'REMINDER_1_SENT', 'REMINDER_2_SENT'].includes(status) && interviewStatus?.interviewLink) {
    menuItems.push({
      action: () => {
        navigator.clipboard.writeText(interviewStatus.interviewLink).then(
          () => toast.success(`${languagePack.interviewerAIURL} ${languagePack.copiedToClipboard}`),
          () => toast.error(languagePack.copyToClipboardError)
        );
        setIsActionOpen(false);
      },
      icon: 'Paper Clip',
      id: 1,
      isDisabled: false,
      label: 'Copy URL to clipboard',
      permissions: ['iai:read'],
      tooltipContent: 'You are unable to edit records that are over 12 months old',
    });
  }

  if (status === 'NOT_REQUESTED' || status === 'FAILED') {
    menuItems.push({
      action: () => {
        setIsInfoSectionExpanded(!isInfoSectionExpanded);
        setIsActionOpen(false);
      },
      icon: 'Paper Plane',
      id: 2,
      label: languagePack.interviewerAIRequestingLabel,
      permissions: ['iai:create'],
    });
  }

  // Render Methods
  const renderHeader = () => (
    status !== 'NOT_REQUESTED' &&
    status !== 'REQUESTED' &&
    status !== 'REMINDER_1_SENT' &&
    status !== 'REMINDER_2_SENT' && (
      <HeadingPushRight
        headingAs="h4"
        headingClassName="d-flex align-items-center"
        headingText={`${languagePack.interviewerAITitle || 'Interviewer AI'} - ${languagePack[status] || 'Unknown'}`}
        isTouch={isTouch}
      >
        {menuItems.length === 1 && checkPermissions(menuItems[0].permissions) && (
          <IconButton
            action={menuItems[0].action}
            className="ms-2"
            disabled={menuItems[0].isDisabled}
            label={menuItems[0].label}
            SVGIcon={<IconSVG name={menuItems[0].icon} />}
          />
        )}
        {menuItems.length > 1 && (
          <div ref={wrapperRef}>
            <ActionButton
              buttonLabel={languagePack.actionBtnLabel || 'Actions'}
              className="ellipsis-opts-btn"
              iconName="Ellipsis"
              iconOnly={false}
              isOpen={isActionOpen}
              menuItems={menuItems}
              onToggle={(isOpen) => setIsActionOpen(isOpen)}
              title="Actions"
              tooltipContent={languagePack.tooltipContent || 'Select an Action for the Interviewer AI'}
            />
          </div>
        )}
      </HeadingPushRight>
    )
  );

  const renderNotRequestedState = () => (
    !isInfoSectionExpanded && status === 'NOT_REQUESTED' && (
      <Row className="mb-4">
        <Col>
          <ActionToBePerformedNoDataState
            image={personalityTest}
            title="Interviewer AI Interview Not Requested"
            button={
              <Button
                onClick={menuItems[0].action}
                className="ms-2"
                disabled={menuItems[0].isDisabled}
                label={menuItems[0].label}
              >
                Request AI Interview
              </Button>
            }
            description="The candidate has not been requested to complete an Interviewer AI yet."
          />
        </Col>
      </Row>
    )
  );

  const renderRequestForm = () => (
    isInfoSectionExpanded && (
      <Row>
        <Col>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <img
              src={personalityTest}
              alt="No Data"
              style={{ maxWidth: '100%', marginBottom: '2px', opacity: 0.7, width: '80px' }}
            />
            <h2 style={{ marginTop: '0', marginLeft: '10px' }}>{languagePack.requestInterviewerAITitle}</h2>
          </div>
          <div className="tw-mt-4">
            <InterviewSelect
              selectedInterview={selectedInterview}
              onInterviewSelect={setSelectedInterview}
              onError={handleError}
            />
            {!!selectedInterview && (
              <div className="tw-border-t tw-pt-4">
                <h4 className="tw-mb-3">Email Details</h4>
                <EmailForm
                  actionType="INTERVIEWER_AI_REQUEST"
                  applicants={[
                    {
                      applicantEmail: candidate.applicantProfile.communication.emailAddress,
                      applicantId: candidate.id,
                      funnelId: candidate.funnelId,
                    },
                  ]}
                  data={{
                    interviewId: selectedInterview.value,
                  }}
                  onError={handleRequestError}
                  onSuccess={(message, data) => {
                    if (data?.status === 'FAILED') {
                      toast.error(
                        languagePack[data?.reason] ||
                          'Could not request AI interview at this time. Please try again later.',
                      );
                    } else {
                      handleSubmissionSuccess(message, data);
                    }
                  }}
                  sendBtnLabel={languagePack.interviewerAIRequestBtnLabel}
                  wrapperClassName="mt-4"
                />
              </div>
            )}
          </div>
        </Col>
      </Row>
    )
  );

  const renderStatusContainers = () => (
    <>
      {['FAILED'].includes(status) && (
        <Row>
          <Col className="!tw-mt-4">
            <div className="tw-bg-red-50 tw-rounded tw-p-3">
              <div className="tw-flex tw-items-center tw-gap-2">
                <p>{languagePack.interviewerAIFailed}</p>
                <span className="tw-text-red-700">{interviewStatus?.statusMessage}</span>
              </div>
            </div>
          </Col>
        </Row>
      )}
      {['REQUESTED', 'REMINDER_1_SENT', 'REMINDER_2_SENT'].includes(status) && (
        <InterviewerAIRequestedContainer
          status={status}
          languagePack={languagePack}
          interviewLink={interviewStatus?.interviewLink}
        />
      )}
      {['RECEIVED'].includes(status) && (
        <InterviewerAICompletedContainer 
          languagePack={languagePack} 
          resultSummary={interviewStatus} 
        />
      )}
    </>
  );

  return (
    <Card>
      <CardBody>
        {isLoading ? (
          <LoadingScreen notFixed />
        ) : (
          <>
            {renderHeader()}
            {renderNotRequestedState()}
            {renderRequestForm()}
            {renderStatusContainers()}
          </>
        )}
      </CardBody>
    </Card>
  );
}

InterviewerAIDetails.propTypes = {
  candidate: PropTypes.shape(),
  isTouch: PropTypes.bool,
  onError: PropTypes.func,
  onSuccess: PropTypes.func,
  updateStatus: PropTypes.func,
  interviewStatus: PropTypes.shape({
    status: PropTypes.string,
    results: PropTypes.shape(),
  }),
};

InterviewerAIDetails.defaultProps = {
  candidate: {},
  isTouch: false,
  onError: () => {},
  onSuccess: () => {},
  interviewStatus: {
    status: 'NOT_REQUESTED',
    results: {},
  },
  updateStatus: () => {},
};

export default InterviewerAIDetails;
