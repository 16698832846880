import { Component } from 'react';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import withRouter from '../../../hoc/withRouter';
import { isAuthenticated } from '@JS/auth/AuthUtils';
import config from '../../../config/config';

const { maintenance } = config;

class AuthWrapper extends Component {
  constructor(props) {
    super(props);

    const {
      location: { search },
    } = props;
    const { ignoreMaintenance } = queryString.parse(search);

    this.state = {
      isAuthed: false,
      serverReturned: false,
      isMaintenance: maintenance && ignoreMaintenance !== 'true',
    };
  }

  async componentDidMount() {
    try {
      const isAuthed = await isAuthenticated();
      this.setState({ isAuthed, serverReturned: true });
    } catch (error) {
      this.setState({ isAuthed: false, serverReturned: true });
    }
  }

  render() {
    const { children } = this.props;
    const { isAuthed, serverReturned, isMaintenance } = this.state;
    return children({ isAuthed, serverReturned, isMaintenance });
  }
}

AuthWrapper.propTypes = {
  children: PropTypes.func.isRequired,
  location: PropTypes.shape(),
};

AuthWrapper.defaultProps = {
  location: {},
};

export default withRouter(AuthWrapper);
