import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { FormGroup, Input, Button } from 'reactstrap';

import { DEFAULT_OPTION } from '../utils';
import FormManagerContext from './FormManagerContext';
import ErrorMessage from './ErrorMessage';

function Option({
  type: inputType,
  index,
  name,
  className,
  option,
  screening,
  onScreeningChange,
  isSubmitted,
  // invalid,
  onDelete,
  onChange,
  disabled,
}) {
  function handleChange(type, value) {
    const optionData = { ...option, [type]: value };
    onChange(optionData);
  }

  const isDropdown = inputType === 'dropdown';

  return (
    <FormManagerContext.Consumer>
      {({ newOptions }) => {
        const isInvalid = isSubmitted && !option.text.length && !newOptions.includes(option.id);

        return (
          <Fragment>
            <FormGroup className={`question-option-group ${className || ''}`}>
              {isDropdown ? (
                `${index + 1}.`
              ) : (
                <Input
                  type={inputType === 'multipleChoice' ? 'radio' : 'checkbox'}
                  className="standard-checkbox small dummy"
                  name={name}
                  checked={option.checked}
                  disabled
                  onChange={(e) => handleChange('checked', e.target.checked)}
                />
              )}
              <Input
                type="text"
                value={option.text}
                // invalid={invalid || isInvalid}
                onChange={(e) => handleChange('text', e.target.value)}
                disabled={disabled}
              />
              {!disabled && (
                <Button
                  close
                  onClick={(e) => {
                    e.preventDefault();
                    onDelete(!!option.text.length);
                  }}
                />
              )}
              {screening.isScreeningQuestion && (
                <div className="ms-2 mt-2">
                  <Input
                    type={'checkbox'}
                    disabled={disabled}
                    className="standard-checkbox"
                    name={'isScreeningQuestion'}
                    checked={screening?.answers?.includes(option.text) || false}
                    onChange={(e) => {
                      onScreeningChange(option.text);
                    }}
                  />
                  <span>Reject on answer</span>
                </div>
              )}
            </FormGroup>
            <ErrorMessage className="option-feedback" isInvalid={isInvalid}>
              Option value required
            </ErrorMessage>
          </Fragment>
        );
      }}
    </FormManagerContext.Consumer>
  );
}

Option.propTypes = {
  type: PropTypes.string,
  index: PropTypes.number,
  name: PropTypes.string,
  className: PropTypes.string,
  option: PropTypes.shape({
    id: PropTypes.string,
    text: PropTypes.string,
    checked: PropTypes.bool,
  }),
  screening: PropTypes.shape({
    answers: PropTypes.arrayOf(PropTypes.string),
    field: PropTypes.string,
    isScreeningQuestion: PropTypes.bool,
  }),
  onScreeningChange: PropTypes.func,
  isSubmitted: PropTypes.bool,
  // invalid: PropTypes.bool,
  onDelete: PropTypes.func,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
};

Option.defaultProps = {
  type: 'checkboxes',
  index: 0,
  name: null,
  className: null,
  option: DEFAULT_OPTION,
  screening: {
    answers: [],
    field: '',
    isScreeningQuestion: false,
  },
  onScreeningChange: () => {},
  isSubmitted: false,
  // invalid: false,
  onDelete: () => {},
  onChange: () => {},
  disabled: false,
};

export default Option;
