import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { Input, Button } from 'reactstrap';
import styled from 'styled-components';

import { DeleteButton } from '@Base/Buttons';
import { FormGroup } from '@Base/Forms/Custom/CommonComponents';
import { IconSVG } from '@Base/SVG';
import ErrorFeedback from '@Base/ErrorFeedback/ErrorFeedback';
import { DropdownManager } from '@Base/Dropdowns';

import { checkPermissions } from '@JS/auth/AuthUtils';

import StageInfoDisplaySettings from './StageInfoDisplaySettings';

const StyledInput = styled(Input)`
  .form-select {
    width: fit-content;
  }
`;

function StageInputsGroup({
  name,
  supplementalInfo,
  id,
  type,
  stageConfig,
  length,
  order,
  onChange,
  onDelete,
  onOrder,
  allowDelete,
  error,
  languagePack,
  helpText,
}) {
  const [showStageOptions, setShowStageOptions] = useState(false);
  const [displaySettings, setDisplaySettings] = useState(false);
  const hasTalenthubPermission = checkPermissions(['talenthub:plus']);

  function onSettingsUpdate(settings) {
    setDisplaySettings(settings);
    setShowStageOptions(false);
    let computedType;
    if (/^(REJECTED|DEFAULT|HIRED)$/.test(type)) {
      computedType = type;
    } else {
      computedType = document.getElementById(`${id}-name-type`).value;
    }
    onChange(
      id,
      document.getElementById(`${id}-name`).value,
      computedType,
      settings,
      document.getElementById(`${id}-sup-title`).value,
      document.getElementById(`${id}-sup-url`).value,
      document.getElementById(`${id}-sup-message`).value,
    );
  }

  return (
    <div className="stage-input-group mb-4">
      <FormGroup
        id={`${id}-name`}
        row
        required
        helpIconText={helpText}
        label={'Name'}
        labelProps={{ sm: '2', display: 'flex' }}
        colProps={{ sm: '10', className: 'stage-input-group-col' }}
      >
        <Input
          id={`${id}-name`}
          value={name}
          onChange={(e) =>
            onChange(
              id,
              e.target.value,
              /^(REJECTED|DEFAULT|HIRED)$/.test(type) ? type : document.getElementById(`${id}-name-type`)?.value,
              displaySettings,
              document.getElementById(`${id}-sup-title`) ? document.getElementById(`${id}-sup-title`).value : null,
              document.getElementById(`${id}-sup-url`) ? document.getElementById(`${id}-sup-url`).value : null,
              document.getElementById(`${id}-sup-message`) ? document.getElementById(`${id}-sup-message`).value : null,
            )
          }
        />
        {!/^(REJECTED|DEFAULT|HIRED)$/.test(type) && (
          <Fragment>
            <div className="stage-input-controls">
              <Button color="link" size="sm" disabled={order === 2} onClick={() => onOrder(order, order - 1)}>
                <IconSVG name="Chevron Up" width="1.5em" />
              </Button>
              <Button
                color="link"
                size="sm"
                disabled={length && order === length - 2}
                onClick={() => onOrder(order, order + 1)}
              >
                <IconSVG name="Chevron" width="1.5em" />
              </Button>
              {allowDelete && (
                <DeleteButton
                  label={languagePack.deleteStageBtnLabel}
                  action={() => onDelete(id)}
                  floatRight
                  iconOnly
                  className="ms-2"
                />
              )}
            </div>
          </Fragment>
        )}
        <DropdownManager
          position="left"
          onDocumentClick={() => {}}
          menuComponent={<StageInfoDisplaySettings stageConfig={stageConfig} type={type} onUpdate={onSettingsUpdate} />}
          trigger={({ ref }) => (
            <Button
              innerRef={ref}
              onClick={() => setShowStageOptions(!showStageOptions)}
              iconOnly
              isDisabled={false}
              tooltipContent=""
              className="ms-2"
            >
              <IconSVG name="Gear" />
            </Button>
          )}
          title={languagePack.stageConfigTitle}
          menuClassName="action-button-menu"
          menuStyle={{ width: '300px' }}
          showMenu={showStageOptions}
        />
      </FormGroup>
      {!/^(REJECTED|DEFAULT|HIRED)$/.test(type) && (
        <FormGroup
          row
          required
          label={'Type'}
          labelProps={{ sm: '2', display: 'flex' }}
          colProps={{ sm: '10', className: 'stage-input-group-col' }}
        >
          <StyledInput
            id={`${id}-name-type`}
            type="select"
            className="m-0 w-50"
            value={type}
            onChange={(e) =>
              onChange(
                id,
                document.getElementById(`${id}-name`).value,
                e.target.value,
                displaySettings,
                document.getElementById(`${id}-sup-title`) ? document.getElementById(`${id}-sup-title`).value : null,
                document.getElementById(`${id}-sup-url`) ? document.getElementById(`${id}-sup-url`).value : null,
                document.getElementById(`${id}-sup-message`)
                  ? document.getElementById(`${id}-sup-message`).value
                  : null,
              )
            }
          >
            <option value="CUSTOM" selected={type === 'CUSTOM'}>
              Custom
            </option>
            <option value="INTERVIEW" selected={type === 'INTERVIEW'}>
              Interview
            </option>
            <option value="REFERENCES" selected={type === 'REFERENCES'}>
              References
            </option>
            <option value="ONBOARDING" selected={type === 'ONBOARDING'}>
              Onboarding
            </option>
            <option value="RTW" selected={type === 'RTW'}>
              RTW
            </option>
          </StyledInput>
        </FormGroup>
      )}
      {hasTalenthubPermission && (
        <FormGroup
          row
          label="Title"
          labelProps={{ sm: '2', display: 'flex' }}
          colProps={{ sm: '10', className: 'stage-input-group-col' }}
        >
          <Input
            id={`${id}-sup-title`}
            className="w-50"
            value={supplementalInfo?.title}
            onChange={(e) => {
              onChange(
                id,
                document.getElementById(`${id}-name`).value,
                /^(REJECTED|DEFAULT|HIRED)$/.test(type) ? type : document.getElementById(`${id}-name-type`)?.value,

                displaySettings,
                e.target.value,
                document.getElementById(`${id}-sup-url`).value,
                document.getElementById(`${id}-sup-message`).value,
              );
            }}
          />
        </FormGroup>
      )}
      {hasTalenthubPermission && (
        <FormGroup
          row
          label="URL"
          labelProps={{ sm: '2', display: 'flex' }}
          colProps={{ sm: '10', className: 'stage-input-group-col' }}
        >
          <Input
            id={`${id}-sup-url`}
            className="w-50"
            value={supplementalInfo?.url}
            onChange={(e) => {
              onChange(
                id,
                document.getElementById(`${id}-name`).value,
                /^(REJECTED|DEFAULT|HIRED)$/.test(type) ? type : document.getElementById(`${id}-name-type`)?.value,

                displaySettings,
                document.getElementById(`${id}-sup-title`).value,
                e.target.value,
                document.getElementById(`${id}-sup-message`).value,
              );
            }}
          />
        </FormGroup>
      )}
      {hasTalenthubPermission && (
        <FormGroup
          row
          label={'Message'}
          labelProps={{ sm: '2', display: 'flex' }}
          colProps={{ sm: '10', className: 'stage-input-group-col' }}
        >
          <Input
            id={`${id}-sup-message`}
            className="w-50"
            style={{ minHeight: '4rem' }}
            type="textarea"
            value={supplementalInfo?.message}
            onChange={(e) => {
              onChange(
                id,
                document.getElementById(`${id}-name`).value,
                /^(REJECTED|DEFAULT|HIRED)$/.test(type) ? type : document.getElementById(`${id}-name-type`)?.value,

                displaySettings,
                document.getElementById(`${id}-sup-title`).value,
                document.getElementById(`${id}-sup-url`).value,
                e.target.value,
              );
            }}
          />
        </FormGroup>
      )}
      <ErrorFeedback message={error} />
    </div>
  );
}

StageInputsGroup.propTypes = {
  name: PropTypes.string,
  id: PropTypes.string,
  type: PropTypes.string,
  stageConfig: PropTypes.shape(),
  length: PropTypes.number,
  order: PropTypes.number,
  onChange: PropTypes.func,
  onDelete: PropTypes.func,
  onOrder: PropTypes.func,
  allowDelete: PropTypes.bool,
  error: PropTypes.string,
  languagePack: PropTypes.shape(),
  helpText: PropTypes.string,
  supUrl: PropTypes.string,
  supTitle: PropTypes.string,
  supMessage: PropTypes.string,
};

StageInputsGroup.defaultProps = {
  name: null,
  id: null,
  type: null,
  stageConfig: null,
  length: 0,
  order: 0,
  onChange: () => {},
  onDelete: () => {},
  onOrder: () => {},
  allowDelete: true,
  error: null,
  languagePack: {},
  helpText: null,
  supUrl: null,
  supTitle: null,
  supMessage: null,
};

export default StageInputsGroup;
