import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useTable, useSortBy, useFlexLayout, useRowSelect, useResizeColumns } from 'react-table';
import cx from 'classnames';
import { storeTablePref, getColWidth } from '../utils';
import { getLocalObject } from '@JS/utils/storeObject';
import { IconSVG } from '../../SVG';
import TablePagination from './TablePagination';
import TableLoading from './TableLoading';
import TableNoData from './TableNoData';
import TableRow from './TableRow';

const headerProps = (props, { column }) => [props, { style: { ...column.headerStyle } }];

function TableLayout({
  id: tableId,
  columns,
  data,
  loading,
  paginationProps,
  sortProps,
  className,
  noDataText,
  onRowSelect,
  initialSelectedCandidates,
}) {
  const [colReset, setColReset] = useState(true);
  const tableData = useMemo(() => data, [data]);
  const tableCols = useMemo(() => getColWidth(columns, tableId, columns), [columns, colReset]);

  const storedDataTable = getLocalObject('dataTable');
  const hasCustomColWidth =
    storedDataTable &&
    storedDataTable[tableId]?.colWidths &&
    Object.keys(storedDataTable[tableId]?.colWidths).length > 0;

  const initialState = {};

  if (initialSelectedCandidates) {
    initialState.selectedRowIds = initialSelectedCandidates;
  }
  const { getTableProps, headerGroups, rows, prepareRow, state, resetResizing } = useTable(
    {
      columns: tableCols,
      data: tableData,
      manualPagination: true,
      getRowId: (row) => row.id,
      initialState,
    },
    useFlexLayout,
    useSortBy,
    useRowSelect,
    useResizeColumns,
  );

  useEffect(() => {
    if (
      state.columnResizing &&
      state.columnResizing.columnWidths &&
      Object.keys(state.columnResizing.columnWidths).length > 0
    ) {
      storeTablePref(tableId, 'colWidths', state.columnResizing.columnWidths, true);
    }
  }, [state.columnResizing]);

  const onResetColWidth = () => {
    storeTablePref(tableId, 'colWidths', {});
    resetResizing();
    setColReset(!colReset);
  };

  useEffect(() => {
    if (typeof onRowSelect === 'function') {
      onRowSelect(Object.keys(state.selectedRowIds));
    }
  }, [onRowSelect, state.selectedRowIds]);

  const { sortId, isSorted, isSortedDesc, onSort } = sortProps;
  const { showPagination, ...restPaginationProps } = paginationProps;

  function handleSort(e, column) {
    e.stopPropagation();
    const { id, disableSortBy } = column;
    // only sort if sortBy allowed
    if (!disableSortBy) onSort({ id, desc: !isSortedDesc }, column);
  }

  return (
    <div className={cx('ReactTable', className)} id={tableId}>
      <div {...getTableProps()} className="rt-table">
        <div className="rt-thead -header">
          {headerGroups.map((headerGroup) => (
            <div {...headerGroup.getHeaderGroupProps()} className="rt-tr">
              {headerGroup.headers.map((column) => {
                const { id, disableSortBy, visible, disableResize } = column;

                if (!visible) return null;

                let sortClassName;
                if (isSorted && sortId === id) {
                  sortClassName = `-sort-${isSortedDesc ? 'desc' : 'asc'}`;
                }

                return (
                  <div
                    {...column.getHeaderProps(column.getSortByToggleProps(headerProps))}
                    className={cx('rt-th', column.headerClassName, sortClassName)}
                  >
                    <div
                      {...(!disableSortBy
                        ? {
                            onClick: (e) => handleSort(e, column),
                            onKeyUp: (e) => handleSort(e, column),
                            role: 'button',
                            tabIndex: '-1',
                          }
                        : {})}
                    >
                      {column.render('Header')}
                    </div>
                    <div
                      {...(!disableResize ? { ...column.getResizerProps() } : {})} // Add the div resizer props
                      className={cx({ resizer: !disableResize }, { isResizing: column.isResizing })}
                    />
                  </div>
                );
              })}
            </div>
          ))}
        </div>
        <div className="rt-tbody">
          {rows.length ? (
            rows.map((row, i) => {
              prepareRow(row);
              const isOdd = (i + 1) % 2;
              return <TableRow key={row.id} isOdd={isOdd} row={row} />;
            })
          ) : (
            <TableNoData>{noDataText}</TableNoData>
          )}
        </div>
      </div>
      {showPagination && !!rows.length && (
        <div className="pagination-bottom">
          <TablePagination
            {...restPaginationProps}
            hasCustomColWidth={hasCustomColWidth}
            onResetColWidth={onResetColWidth}
          />
        </div>
      )}
      {!showPagination && !!rows.length && hasCustomColWidth && (
        <div className="pagination-bottom">
          <div className="table__page-management justify-content-start">
            {hasCustomColWidth && (
              <div className="table__column-width-reset float-left">
                <button
                  className={cx('table__pageButton')}
                  onClick={onResetColWidth}
                  title="Reset Column Widths"
                  type="button"
                >
                  <IconSVG name="Undo" />
                </button>
              </div>
            )}
          </div>
        </div>
      )}
      <TableLoading loading={loading} />
    </div>
  );
}

TableLayout.propTypes = {
  id: PropTypes.string,
  columns: PropTypes.arrayOf(PropTypes.shape()),
  data: PropTypes.arrayOf(PropTypes.shape()),
  loading: PropTypes.bool,
  paginationProps: PropTypes.shape(),
  sortProps: PropTypes.shape({
    showPagination: PropTypes.bool,
    sortId: PropTypes.string,
    isSorted: PropTypes.bool,
    isSortedDesc: PropTypes.bool,
    onSort: PropTypes.func,
  }),
  onRowSelect: PropTypes.func,
  initialSelectedCandidates: PropTypes.shape(),
  className: PropTypes.string,
  noDataText: PropTypes.string,
};

TableLayout.defaultProps = {
  id: null,
  columns: [],
  data: [],
  loading: false,
  paginationProps: {
    showPagination: true,
  },
  onRowSelect: () => {},
  sortProps: {
    sortId: null,
    isSorted: false,
    isSortedDesc: true,
    onSort: () => {},
  },
  className: null,
  noDataText: null,
};

export default TableLayout;
