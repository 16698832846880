import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Card,
  CardBody,
  CardGroup,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupText,
  Row,
} from 'reactstrap';
import { useNavigate } from 'react-router-dom';
import Cookies from 'universal-cookie';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { setAuthError } from '@JS/actions/userActions';
import { ResolveLogo } from '@Base/SVG';
import { getIDPConfiguration, getIDPLoginRequest, pollForIDPLoginUpdate } from '@API/AuthAPI/AuthAPI';
import { useInterval } from '@JS/utils/UseInterval';
import { doLogin } from '@JS/auth/AuthUtils';
import { cookieNames } from '@JS/constants/storageNames';
import { requestStatuses } from '@JS/constants/requestStatuses';

function IDPLogin({ onBackToLogin }) {
  const [formData, setFormData] = useState({ username: undefined });
  const [isLoggingIn, setIsLoggingIn] = useState(false);
  const [error, setError] = useState(undefined);
  const [config, setConfig] = useState(undefined);
  const cookies = new Cookies();
  const navigate = useNavigate();

  function handleChange(e) {
    const updatedData = { ...formData };
    updatedData[e.target.name] = e.target.value.trim();
    setFormData(updatedData);
  }

  const popupCenter = ({ url, title, w, h }) => {
    // Fixes dual-screen position                             Most browsers      Firefox
    const dualScreenLeft = window.screenLeft !== undefined ? window.screenLeft : window.screenX;
    const dualScreenTop = window.screenTop !== undefined ? window.screenTop : window.screenY;

    const width = window.innerWidth
      ? window.innerWidth
      : document.documentElement.clientWidth
        ? document.documentElement.clientWidth
        : screen.width;
    const height = window.innerHeight
      ? window.innerHeight
      : document.documentElement.clientHeight
        ? document.documentElement.clientHeight
        : screen.height;

    const systemZoom = width / window.screen.availWidth;
    const left = (width - w) / 2 / systemZoom + dualScreenLeft;
    const top = (height - h) / 2 / systemZoom + dualScreenTop;
    const newWindow = window.open(
      url,
      title,
      `
      scrollbars=yes,
      width=${w / systemZoom},
      height=${h / systemZoom},
      top=${top},
      left=${left}
      `,
    );

    if (window.focus) newWindow.focus();

    return newWindow;
  };

  async function getIDPConfig(e) {
    e.preventDefault();

    const conf = await getIDPConfiguration(formData.username, undefined);
    if (typeof conf !== 'string') {
      const { authUrl, clientId, redirectUri, stateId } = conf;
      setConfig(conf);
      const authWindow = popupCenter({
        h: 600,
        title: 'login',
        url: `${authUrl}?response_type=code&scope=openid&client_id=${clientId}&state=${stateId}&redirect_uri=${redirectUri}&login_hint=${formData.username}`,
        w: 500,
      });

      if (!authWindow || authWindow.closed || typeof authWindow.closed === 'undefined') {
        toast.error('You must allow popups in order to login');
      } else {
        setIsLoggingIn(true);
      }
    } else if (conf === requestStatuses.NOT_FOUND_ERROR) {
      setError(
        'Sorry you not able to login with this email address. If you believe this is a mistake please contact your administrator.',
      );
    } else if (conf === requestStatuses.GENERAL_ERROR) {
      setError('There has been a problem attempting to login. Please try again later');
    }
  }

  async function getIDPLoginReq(stateId) {
    setError(undefined);
    const result = await getIDPLoginRequest(stateId);

    if (typeof result === 'string' && result === requestStatuses.NOT_FOUND_ERROR) {
      setError(
        'Sorry you not able to login with this email address. If you believe this is a mistake please contact your administrator.',
      );
    } else if (typeof result === 'string' && result === requestStatuses.GENERAL_ERROR) {
      setError('There has been a problem attempting to login. Please try again later');
    } else if (result.status === 'AUTHENTICATED') {
      doLogin(null, null, null, null, cookies.get(cookieNames.TWO_FACTOR_TRUST_ID), null, result, navigate);
    } else if (result.status === 'AUTH_FAILED') {
      setError('Sorry we have been unable to log you in with the details you have provided');
      setIsLoggingIn(false);
    }
  }

  useInterval(() => {
    // Your custom logic here
    if (isLoggingIn && config?.stateId) {
      getIDPLoginReq(config.stateId);
    }
  }, 1000);

  return (
    <div className="app flex-row align-items-center auth-page login-background-image">
      <Container>
        <Row className="justify-content-center">
          <Col lg={6} md={7} sm={10} xl={5} xs={12}>
            <CardGroup>
              <Card className="p-4 rounded login-box-bg">
                <CardBody>
                  <div className="text-center mb-3">
                    <ResolveLogo loginPage />
                  </div>
                  <>
                    <Row className="pb-3">
                      <Col className="text-center">
                        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                        <a
                          href="#"
                          onClick={(e) => {
                            e.preventDefault();
                            onBackToLogin();
                          }}
                        >
                          {`< Back to login`}
                        </a>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <p style={{ textAlign: 'center' }}>
                          Enter your email address below and you will be sent to your organisations login page to
                          authenticate
                        </p>
                      </Col>
                    </Row>
                    <hr />
                  </>
                  <Form>
                    {error && (
                      <Row className="mb-3">
                        <Col>
                          <p className="text-danger text-center">{error}</p>
                        </Col>
                      </Row>
                    )}
                    {!isLoggingIn && (
                      <>
                        <Row>
                          <Col>
                            <FormGroup className="pb-2" row>
                              <Col>
                                <InputGroup>
                                  <div className="input-group-prepend">
                                    <span className="input-group-text">
                                      <i className="icon-user" />
                                    </span>
                                  </div>
                                  <Input
                                    autoComplete="username"
                                    defaultValue={formData.username}
                                    name="username"
                                    onChange={handleChange}
                                    placeholder="Username"
                                    type="email"
                                  />
                                </InputGroup>
                              </Col>
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col className="text-center">
                            <Button
                              className="px-4 me-auto"
                              disabled={isLoggingIn}
                              onClick={(e) => {
                                getIDPConfig(e);
                              }}
                              type="submit"
                            >
                              <i className="fa fa-sign-in me-2" />
                              Login
                            </Button>
                          </Col>
                        </Row>
                      </>
                    )}
                    {isLoggingIn && (
                      <div className="text-center">
                        <p>Please login using the new window.</p>
                      </div>
                    )}
                  </Form>
                </CardBody>
              </Card>
            </CardGroup>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

IDPLogin.propTypes = {
  onBackToLogin: PropTypes.func,
};

IDPLogin.defaultProps = {
  onBackToLogin: () => {},
};

function mapStateToProps(state) {
  return { authError: state.userData.userAuth.authError, failedAuthCount: state.userData.failedAuthCount };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatchAuthError: (error) => {
      dispatch(setAuthError(error));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(IDPLogin);
