import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Select from 'react-select';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { setRRData } from '@JS/actions/rotaReadyActions';
import { getCommonHeaders } from '@API/common-api-utils';
import config from '../../../../config/config';

const { api } = config;
function DynamicSelect({ id, value, onChange, isClearable, url, dataMapper, setRRDataz }) {
  const [data, setData] = useState([]);

  useEffect(() => {
    const getData = async () => {
      let newData = [];
      try {
        if (url !== undefined) {
          const response = await axios.get(`${api.middlewareAPIURL}/${url}`, { headers: getCommonHeaders() });
          if (response !== undefined) {
            if (dataMapper) {
              newData = response.data.map(dataMapper);
            } else {
              newData = response.data;
            }
            const obj = {};
            obj[id] = newData;
            setRRDataz(obj);
          }
        }
      } catch (error) {
        console.log(`could not fetch data with error: ${error}`);
      }

      setData(newData);
    };

    getData();
  }, [url]);

  const selectedOpt = data.find(({ value: val }) => val === value);

  return (
    <Select
      classNamePrefix="react-select"
      id={id}
      isClearable={isClearable}
      onChange={(selected) => onChange(selected ? selected.value : '')}
      options={data}
      value={selectedOpt || null}
    />
  );
}

DynamicSelect.propTypes = {
  dataMapper: PropTypes.func,
  id: PropTypes.string,
  isClearable: PropTypes.bool,
  onChange: PropTypes.func,
  setRRDataz: PropTypes.func,
  url: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
};

DynamicSelect.defaultProps = {
  dataMapper: null,
  id: '',
  isClearable: true,
  onChange: () => {},
  setRRDataz: () => {},
  url: '',
  value: null,
};

function mapDispatchToProps(dispatch) {
  return {
    setRRDataz: (data) => {
      dispatch(setRRData(data));
    },
  };
}

export default connect(null, mapDispatchToProps)(DynamicSelect);
