import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import ReducedCalendar from './ReducedCalendar';
import { EventEditor } from '../..';
import * as utils from '../../../utils';
import { Confirmation } from '@Base/Modal';
import Can from '@Base/RBAC/Can/Can';
import ErrorMessage from '@Base/ErrorScreen/ErrorMessage';
import { startPolling, stopPolling } from '@JS/actions/eventNotificationActions';
import { ModalPopup } from '@Base/Modal';
import styled from 'styled-components';

const RestrictedCalendar = Can(ReducedCalendar);

const Styledcons = styled.div`
  .modal-body {
    padding-bottom: 1rem;
  }
`;

const StyledSchedule = styled.div`
  padding-bottom: 1rem;
  margin-top: -1.5rem;
`;

function ModalScheduleView({
  applicantActiveAccountId,
  startPoll,
  stopPoll,
  modalRef,
  eventTypeId,
  isPrivateEvent,
  onEventCreated,
  timezone,
}) {
  const [isSliderOpen, setIsSliderOpen] = useState(false);
  const [eventObj, setEventObj] = useState({});
  const [calendarEvents, setCalendarEvents] = useState([]);
  const [showConfirm, setShowConfirm] = useState(false);
  const viewObj = useRef({});

  let title = 'Create a New Event';

  return (
    <StyledSchedule className="schedule-view">
      <Styledcons>
        <RestrictedCalendar
          modalRef={modalRef}
          timezone={timezone}
          activeAccountId={applicantActiveAccountId}
          deniedDisplay={<ErrorMessage error={403} />}
          events={calendarEvents}
          onEventCreate={(eventInfo) => {
            setEventObj(eventInfo);
            setIsSliderOpen(true);
          }}
          onRender={async (calInfo) => {
            const { activeStart, activeEnd, type } = calInfo;
            const isoStart = utils.dateTimeUtils.getIsoDate(activeStart);
            const isoEnd = utils.dateTimeUtils.getIsoDate(activeEnd);

            if (
              viewObj.current.type !== type ||
              viewObj.current.isoStart !== isoStart ||
              viewObj.current.isoEnd !== isoEnd ||
              viewObj.current.activeAccountId !== applicantActiveAccountId
            ) {
              // cache to stop infinite render loop
              viewObj.current = {
                type,
                isoStart,
                isoEnd,
                applicantActiveAccountId,
              };

              const eventArr = await utils.fetchUtils.getEventData(
                isoStart,
                isoEnd,
                applicantActiveAccountId,
                false,
                () => {
                  toast.error('Error fetching event data. Please try again');
                },
              );

              if (eventArr.length && typeof eventArr !== 'string') {
                const clientEvents = eventArr?.map((evtObj) => utils.dataUtils.clientEvent(evtObj));
                setCalendarEvents(clientEvents);
              } else {
                setCalendarEvents([]);
              }
            }
          }}
          onViewToggle={(isListView) => {
            if (isListView) {
              stopPoll();
            } else {
              startPoll();
            }
          }}
          permissions={['scheduler:calendar:read']}
        />
      </Styledcons>
      <ModalPopup
        isOpen={isSliderOpen}
        onToggle={() => setIsSliderOpen(false)}
        title={title}
        hideCancelButton
        hideOkayButton
      >
        <div className="pb-5 h-100">
          <EventEditor
            timeZone={timezone}
            activeAccountId={applicantActiveAccountId}
            eventObj={eventObj}
            eventTypeId={eventTypeId}
            isPrivateEvent={isPrivateEvent}
            onCancel={() => {
              setIsSliderOpen(false);
            }}
            onSave={(eventData) => {
              toast.success(`Slot successfully created`);

              let clientEvents = [];

              clientEvents = eventData.map((evtObj) => utils.dataUtils.clientEvent(evtObj));
              setCalendarEvents([...calendarEvents, ...clientEvents]);

              setIsSliderOpen(false);
              onEventCreated();
            }}
          />
        </div>
      </ModalPopup>
      <Confirmation
        cancelCallback={() => {
          setShowConfirm(false);
        }}
        confirmCallback={() => {
          setIsSliderOpen(false);
          setShowConfirm(false);
        }}
        content="Your data will be lost if you close the slider"
        show={showConfirm}
        title="Warning"
      />
    </StyledSchedule>
  );
}

ModalScheduleView.propTypes = {
  applicantActiveAccountId: PropTypes.string,
  startPoll: PropTypes.func,
  stopPoll: PropTypes.func,
  onEventCreated: PropTypes.func.isRequired,
};

ModalScheduleView.defaultProps = {
  applicantActiveAccountId: null,
  startPoll: () => {},
  stopPoll: () => {},
  onEventCreated: () => {},
};

function mapDispatchToProps(dispatch) {
  return {
    startPoll: () => {
      dispatch(startPolling());
    },
    stopPoll: () => {
      dispatch(stopPolling());
    },
  };
}

export default connect(undefined, mapDispatchToProps)(ModalScheduleView);
