import React from 'react';
import { serviceDisplayNameMap, logoMap } from '../constants';
import { Check } from 'lucide-react';

const SyncCalendarDropdown = ({
  syncedCalendars,
  isServiceSynced,
  handleSyncOptionClick,
  handleEditOptionClick,
  handleUnsyncOptionClick,
}) => {
  return (
    <div className="sync-calendar-dropdown">
      {Object.entries(serviceDisplayNameMap).map(([serviceType, displayName]) => {
        const calendar = syncedCalendars.find((calendar) => calendar.type === serviceType);
        const isSynced = isServiceSynced(serviceType);

        return (
          <div key={serviceType} className={`dropdown-item ${isSynced ? 'synced' : ''}`}>
            <button
              onClick={() =>
                isSynced
                  ? handleEditOptionClick(serviceType, displayName, calendar)
                  : handleSyncOptionClick(serviceType, displayName)
              }
              className="sync-button"
            >
              <img src={logoMap[serviceType]} alt={`${displayName} logo`} className="service-logo" />
              <span>{displayName}</span>
              {isSynced && <Check size={18} className="check-icon" />}
            </button>
            {isSynced && (
              <button onClick={() => handleUnsyncOptionClick(serviceType)} className="unsync-button">
                Unsync
              </button>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default SyncCalendarDropdown;
