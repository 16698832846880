import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { Card, CardBody } from 'reactstrap';
import AttachmentEntry from './AttachmentEntry';
import { Confirmation } from '@Base/Modal';
import { retryableAPICall } from '@API/common-api-utils';
import { deleteFile } from '@API/CandidateAPI/CandidateAPI';
import { useLanguage } from '@Base/hooks';
import { attachmentsContainer } from '@JS/language/pages';

function AttachmentsContainer({ applicantId, attachments, pushDelete, onError, onSuccess }) {
  const { langPack: languagePack } = useLanguage(attachmentsContainer);
  const [showConfirmClose, setShowConfirmClose] = useState(false);
  const [fileToBeDeleted, setFileToBeDeleted] = useState({});

  async function doDeleteFile() {
    const result = await retryableAPICall(() => deleteFile(applicantId, fileToBeDeleted.id));

    if (typeof result === 'string') {
      onError(`${fileToBeDeleted.fileName} ${languagePack.deleteFailed}`);
    } else {
      pushDelete(fileToBeDeleted);
      onSuccess(`${fileToBeDeleted.fileName} ${languagePack.deleteSuccess}`);
    }
    setShowConfirmClose(false);
    setFileToBeDeleted({});
  }

  return (
    <Fragment>
      <Card>
        <CardBody>
          <Fragment>
            <h4>{languagePack.fullAttachmentsTitle}</h4>
            <div className="attachments-wrapper">
              {attachments.map((entry) => (
                <AttachmentEntry
                  key={entry.id}
                  applicantId={applicantId}
                  attachment={entry}
                  onDelete={(file) => {
                    setFileToBeDeleted(file);
                    setShowConfirmClose(true);
                  }}
                />
              ))}
            </div>
          </Fragment>
        </CardBody>
      </Card>
      <Confirmation
        type="danger"
        title="Delete File?"
        show={showConfirmClose}
        confirmCallback={() => {
          doDeleteFile();
        }}
        cancelCallback={() => {
          setShowConfirmClose(false);
          setFileToBeDeleted({});
        }}
        // eslint-disable-next-line max-len,no-mixed-operators
        content={`Are you sure you want to delete ${fileToBeDeleted.fileName || 'file'}`}
      />
    </Fragment>
  );
}

AttachmentsContainer.propTypes = {
  applicantId: PropTypes.string,
  attachments: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      content: PropTypes.shape(),
    }),
  ),
  pushDelete: PropTypes.func,
  onError: PropTypes.func,
  onSuccess: PropTypes.func,
};

AttachmentsContainer.defaultProps = {
  applicantId: null,
  attachments: [],
  pushDelete: () => {},
  onError: () => {},
  onSuccess: () => {},
};

export default AttachmentsContainer;
