import React, { useState, useEffect, useMemo, useRef } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { v4 as uuidv4 } from 'uuid';
import cx from 'classnames';
import ReactGA from 'react-ga4';
import { Checkbox, Shortlist, TableLayout, ColumnManager } from './components';
import { getSelectedRows, allIds, selectedIds, updateShortlist, columnPresets, storeTablePref } from './utils';
import { LoadingScreen } from '../Loading';
import { setTablePageSize, setModalTablePageSize } from '@JS/actions/tablePrefsActions';
import { localStorageNames } from '@JS/constants/storageNames';
import { stopPolling, startPolling } from '@JS/actions/eventNotificationActions';

function Table(props) {
  const {
    id: tableId,
    isLoading: isDataLoading,
    isRejected: isDataRejected,
    data,
    totalResults,
    totalPages,
    pageSize: pSize,
    vacancyId,
    currentPage,
    hasSelectColumn,
    isSelectRadio,
    selectHeaderLabel,
    hasShortlistColumn,
    shortlistKey,
    shortListErrorText,
    onChange,
    onSelect,
    onSelectHelper,
    selected: selectedData,
    noDataText,
    errorText,
    applySetPageSize,
    isModal,
    showPagination,
    showPageSize,
    className,
    columnManager,
    columnManagerIsOpen,
    onColumnManagerClose,
    pausePoll,
    startPoll,
  } = props;
  let { columns } = props;

  const localStoreNs = `${localStorageNames.DATA_TABLE}${tableId ? `-${tableId}` : ''}`;

  const [isTablePending, setIsTablePending] = useState(false);
  const [isTableRejected, setIsTableRejected] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [shortlistedRows, setShortlistedRows] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [tablePage, setTablePage] = useState(currentPage);
  const [rowsPerPage, setRowsPerPage] = useState(pSize);
  const [sortBy, setSortBy] = useState();
  const [sortDesc, setSortDesc] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [tableColumns, setTableColumns] = useState([]);
  const uuid = useMemo(() => uuidv4(), []);
  const cachedColumns = useRef([]);

  useEffect(() => {
    setTableData(data);
    // shortlisted rows from server
    const shortlist = data.reduce((acc, obj) => {
      const { userId, id } = obj;
      const selectedId = id || userId;
      if (obj[shortlistKey] && selectedId) acc.push(selectedId);
      return acc;
    }, []);
    setShortlistedRows(shortlist);

    setSelectedRows(selectedData);
  }, [data, selectedData, shortlistKey]);

  function dataChange(reqObj) {
    setIsTableRejected(false);

    return new Promise((resolve, reject) => {
      onChange(reqObj, (error) => {
        if (error) {
          setIsTableRejected(true);
          reject(error);
        }
        resolve();
      });
    });
  }

  useEffect(() => {
    if (columnManager && columnManagerIsOpen) {
      setIsModalOpen(columnManagerIsOpen);
      pausePoll();
    }
  }, [columnManager, columnManagerIsOpen, pausePoll]);

  async function handlePageChange(page) {
    const prevPage = tablePage;
    setIsTablePending(true);
    setTablePage(page);

    const dataObj = { changeType: 'page', page, pageSize: rowsPerPage };
    if (sortBy) dataObj.sort = [{ field: sortBy, order: sortDesc ? 'DESC' : 'ASC' }];
    // revert on error
    await dataChange(dataObj).catch(() => setTablePage(prevPage));
    setIsTablePending(false);
  }

  async function handlePageSizeChange(pageSize) {
    setIsTablePending(true);
    setTablePage(0);
    setRowsPerPage(pageSize);
    applySetPageSize(pageSize, isModal);

    const dataObj = { pageSize, changeType: 'pageSize' };
    if (sortBy) dataObj.sort = [{ field: sortBy, order: sortDesc ? 'DESC' : 'ASC' }];
    await dataChange(dataObj).catch(() => {});
    setIsTablePending(false);
  }

  async function handleSort(sortObj) {
    //  setIsTablePending(true);

    const { id, desc } = sortObj;
    setSortBy(id);
    setSortDesc(desc);
    setTablePage(0);

    await dataChange({
      sort: [{ field: `${id}`, order: desc ? 'DESC' : 'ASC' }],
      pageSize: rowsPerPage,
      changeType: 'sort',
    }).catch(() => {});
    //   setIsTablePending(false);

    storeTablePref(tableId, 'sort', [{ field: `${id}`, order: desc ? 'DESC' : 'ASC' }]);
  }

  function handleSelectAll(isChecked) {
    if (!isSelectRadio) {
      const selected = isChecked ? allIds(tableData) : [];
      ReactGA.event({
        action: 'VACANCY_TABLE_MULTI_SELECT',
        category: 'USER_ACTION',
        label: 'ALL',
      });
      setSelectedRows(selected);
      // only run util if checked
      onSelect(isChecked ? getSelectedRows(tableData, selected, onSelectHelper) : []);
    }
  }

  function handleSelectChange(isChecked, id) {
    const selected = selectedIds(selectedRows, id, isChecked, isSelectRadio);
    setSelectedRows(selected);
    ReactGA.event({
      action: 'VACANCY_TABLE_MULTI_SELECT',
      category: 'USER_ACTION',
      label: 'SINGLE',
    });
    onSelect(getSelectedRows(tableData, selected, onSelectHelper));
  }

  function handleShortlistAll(isChecked) {
    const cloneShortlist = [...shortlistedRows];
    const selected = allIds(tableData);
    setShortlistedRows(isChecked ? selected : []);

    updateShortlist(vacancyId, selected, isChecked, () => {
      toast.error(shortListErrorText);
      // onError reverse change
      setShortlistedRows(cloneShortlist);
    });
  }

  function handleShortlistChange(isChecked, id) {
    const selected = selectedIds(shortlistedRows, id, isChecked);
    setShortlistedRows(selected);

    updateShortlist(vacancyId, [id], isChecked, () => {
      toast.error(shortListErrorText);
      // onError reverse change
      setShortlistedRows(selectedIds(shortlistedRows, id, !isChecked));
    });
  }

  // eslint-disable-next-line max-len
  // https://spectrum.chat/react-table/general/add-columns-dynamically~5263b287-aa54-4403-9708-c017a3dfa349?m=MTU0OTQ2NzU5MzYxNA==
  columns = [...columns];

  if (hasShortlistColumn) {
    columns.unshift({
      id: 'favourite',
      className: 'favourite-cell',
      headerClassName: 'text-center',
      width: 50,
      Header: () => (
        <Shortlist
          isChecked={tableData.length > 0 && shortlistedRows.length === tableData.length}
          onChange={handleShortlistAll}
        />
      ),
      // eslint-disable-next-line react/prop-types
      Cell: ({ row: { original } }) => {
        // eslint-disable-next-line react/prop-types
        const { userId, id } = original;
        const selectedId = id || userId;

        return (
          <Shortlist
            isChecked={shortlistedRows.includes(selectedId)}
            onChange={(isChecked) => handleShortlistChange(isChecked, selectedId)}
          />
        );
      },
    });
  }

  if (hasSelectColumn) {
    columns.unshift({
      disableResize: true,
      id: 'select',
      className: 'select-cell',
      headerClassName: 'text-center',
      width: selectHeaderLabel ? 100 : 50,
      Header: () => {
        if (isSelectRadio) return selectHeaderLabel;

        return (
          <Checkbox
            isChecked={tableData.length > 0 && selectedRows.length === tableData.length}
            onChange={handleSelectAll}
          />
        );
      },
      // eslint-disable-next-line react/prop-types
      Cell: ({ row: { original } }) => {
        // eslint-disable-next-line react/prop-types
        const { userId, id } = original;
        const selectedId = id || userId;

        return (
          <Checkbox
            isChecked={selectedRows.includes(selectedId)}
            isRadio={isSelectRadio}
            name={`table-cb-${uuid}`}
            onChange={(isChecked) => handleSelectChange(isChecked, selectedId)}
          />
        );
      },
    });
  }

  cachedColumns.current = useMemo(() => columnPresets(columns), [columns]);
  columns = useMemo(() => columnPresets(columns, localStoreNs), [columns, localStoreNs]);

  // let tablePageSize = rowsPerPage;
  // let minRows = 0;
  // if (totalResults === 0) {
  //   tablePageSize = 3;
  //   minRows = 3;
  // }
  // else if (totalResults < rowsPerPage) {
  //   tablePageSize = totalPages;
  // }

  if (isDataLoading && !isModal) return <LoadingScreen isEmbeded />;

  const cols = columnManager && tableColumns.length ? tableColumns : columns;

  return (
    <>
      <div className="table-wrapper">
        <TableLayout
          className={cx(className, '-highlight', { 'has-pagination': showPagination })}
          columns={cols}
          data={tableData}
          id={tableId}
          loading={(isModal && isDataLoading) || isTablePending}
          noDataText={isTableRejected || isDataRejected ? errorText : noDataText}
          paginationProps={{
            showPagination,
            pageSizeOptions: [5, 10, 25, 50, 100, 250, 500, 1000, 5000],
            pageSize: rowsPerPage,
            pageSizeOptionsDisabled: totalResults < 5,
            pages: totalPages,
            page: tablePage,
            showPageSizeOptions: showPageSize,
            onPageSizeChange: handlePageSizeChange,
            onPageChange: handlePageChange,
            columnManager,
            onColumnManagerClick: () => {
              setIsModalOpen(true);
              pausePoll();
            },
            ns: localStoreNs,
          }}
          sortProps={{
            sortId: sortBy,
            isSorted: !!sortBy,
            isSortedDesc: sortDesc,
            onSort: handleSort,
          }}
        />
      </div>
      {columnManager && (
        <ColumnManager
          columns={cols}
          isOpen={isModalOpen}
          ns={localStoreNs}
          onClose={() => {
            setIsModalOpen(false);
            onColumnManagerClose();
            startPoll();
          }}
          onOkay={(columnArr) => setTableColumns(columnArr)}
          onReset={() => setTableColumns(cachedColumns.current)}
        />
      )}
    </>
  );
}

Table.propTypes = {
  id: PropTypes.string,
  isLoading: PropTypes.bool,
  isRejected: PropTypes.bool,
  columns: PropTypes.arrayOf(PropTypes.shape()),
  data: PropTypes.arrayOf(PropTypes.shape()),
  totalResults: PropTypes.number,
  totalPages: PropTypes.number,
  pageSize: PropTypes.number,
  vacancyId: PropTypes.string,
  currentPage: PropTypes.number,
  hasSelectColumn: PropTypes.bool,
  isSelectRadio: PropTypes.bool,
  selectHeaderLabel: PropTypes.string,
  hasShortlistColumn: PropTypes.bool,
  shortlistKey: PropTypes.string,
  shortListErrorText: PropTypes.string,
  onChange: PropTypes.func,
  onSelect: PropTypes.func,
  onSelectHelper: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  selected: PropTypes.arrayOf(PropTypes.string),
  noDataText: PropTypes.string,
  errorText: PropTypes.string,
  applySetPageSize: PropTypes.func,
  isModal: PropTypes.bool,
  showPagination: PropTypes.bool,
  showPageSize: PropTypes.bool,
  className: PropTypes.string,
  columnManager: PropTypes.bool,
  columnManagerIsOpen: PropTypes.bool,
  onColumnManagerClose: PropTypes.func,
  pausePoll: PropTypes.func,
  startPoll: PropTypes.func,
};

Table.defaultProps = {
  id: null,
  isLoading: false,
  isRejected: false,
  columns: [],
  data: [],
  totalResults: 0,
  totalPages: 1,
  pageSize: 25,
  vacancyId: '',
  currentPage: 0,
  hasSelectColumn: true,
  isSelectRadio: false,
  selectHeaderLabel: null,
  hasShortlistColumn: false,
  shortlistKey: 'favourite',
  shortListErrorText: 'Error shortlisting candidate',
  onChange: () => {},
  onSelect: () => {},
  onSelectHelper: false,
  selected: [],
  noDataText: 'No records found',
  errorText: 'Oops! Something went wrong',
  applySetPageSize: () => {},
  isModal: false,
  showPagination: true,
  showPageSize: true,
  className: null,
  columnManager: false,
  columnManagerIsOpen: false,
  onColumnManagerClose: () => {},
  pausePoll: () => {},
  startPoll: () => {},
};

function mapDispatchToProps(dispatch) {
  return {
    applySetPageSize: (pageSize, isModal) => {
      if (isModal) {
        dispatch(setModalTablePageSize(pageSize));
      } else {
        dispatch(setTablePageSize(pageSize));
      }
    },
    pausePoll: () => {
      dispatch(stopPolling());
    },
    startPoll: () => {
      dispatch(startPolling());
    },
  };
}

export default connect(null, mapDispatchToProps)(Table);
