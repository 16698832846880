import React, { useState } from 'react';
import { Star, Award, ChevronDown, FileText, Briefcase, Clock, GraduationCapIcon } from 'lucide-react';
import { Col, Row } from 'reactstrap';
import FeedbackWidget from '@Base/Feedback/FeedbackWidget';
import TextToSpeechPlayer from '@Base/AI/TextToSpeechPlayer';
import CareerExperience from './CareerExperience'; // Import the CareerExperience component
import ExpandableSection from './ExpandableSection';
import { calculateAverageTimeInRole } from './utils';
import { Education } from '../index';
import EducationSummary from './EducationSummary';
import CandidateScore from './CandidateScore';
import { checkPermissions } from '../../../../../js/auth/AuthUtils'; // Import the ExpandableSection component

const AISummarySection = ({ applicant }) => {
  const [showAllSkills, setShowAllSkills] = useState(false);

  // Function to convert months to years (rounded to nearest 0.5)
  const monthsToYears = (months) => {
    const years = months / 12;
    return Math.round(years * 2) / 2; // Rounds to nearest 0.5
  };

  // Map and sort skills
  const allSkills =
    (applicant.skills || applicant.applicantProfile?.skills)
      ?.map((skill) => ({
        name: skill.name,
        years: monthsToYears(skill.experienceMonths),
      }))
      .sort((a, b) => b.years - a.years) || [];

  const strengths = applicant.strengths || applicant.applicantProfile?.strengths;

  // Get employment data
  const employmentData = applicant.employment ||
    applicant.applicantProfile?.employment ||
    [];

  // Calculate average time in role using the utility function
  const avgTimeInAllRoles = calculateAverageTimeInRole(employmentData, { abbreviated: false });

  // Career meta component with improved labeling
  const careerMeta = avgTimeInAllRoles ? (
    <div className="career-avg-time">
      <Clock size={14} className="time-icon" style={{marginRight: '5px'}} />
      <span>Avg. time across roles: <b>{avgTimeInAllRoles}</b></span>
    </div>
  ) : null;

  // Check if we have employment data to show
  const hasCareerData = employmentData && employmentData.length > 0;

  return (
    <div className="app-summary-section">
      {/* Summary Header */}
      <div className="app-summary-section__header">
        <h4 className="app-summary-section__title">Candidate Summary</h4>
        <span className="app-summary-section__ai-badge">AI Generated</span>
        <TextToSpeechPlayer text={applicant.candidateSummary || applicant.applicantProfile?.candidateSummary} />
      </div>

      {/* Two-column layout for summary text and score */}
      <div style={{
        display: 'flex',
        alignItems: 'flex-start',
        gap: '24px',
        marginBottom: '20px'
      }}>
        {/* Left column - Summary Text */}
        <div style={{ flex: '1' }}>
          <p className="app-summary-section__text" style={{ margin: 0 }}>
            {applicant.candidateSummary || applicant.applicantProfile?.candidateSummary}
          </p>
        </div>

        {checkPermissions(['hidden:man']) &&
        <div style={{
          flexShrink: 0,
          marginTop: '-35px' // Add negative margin to move it up
        }}>
          <CandidateScore candidateId={applicant.id} />
        </div>
        }
      </div>

      {/* Skills Section */}
      {allSkills.length > 0 && (
        <div className="app-summary-section__skills">
          <div className="app-summary-section__section-header">
            <Star className="app-summary-section__icon" size={16} />
            <span>Skills</span>
          </div>
          <div className="app-summary-section__pills">
            {(showAllSkills ? allSkills : allSkills.slice(0, 5)).map((skill) => (
              <span key={skill.name} className="app-summary-section__skill-pill">
                {skill.name}
                <span className="app-summary-section__year-badge">{skill.years}yr</span>
              </span>
            ))}
          </div>
          {allSkills.length > 5 && (
            <button className="app-summary-section__show-more" onClick={() => setShowAllSkills(!showAllSkills)}>
              {showAllSkills ? 'Show Less' : `Show ${allSkills.length - 5} More Skills`}
              <ChevronDown className={showAllSkills ? 'rotate-180' : ''} size={16} />
            </button>
          )}
        </div>
      )}

      {/* Strengths Section */}
      {strengths?.length > 0 && (
        <div className="app-summary-section__strengths">
          <div className="app-summary-section__section-header">
            <Award className="app-summary-section__icon" size={16} />
            <span>Key Strengths</span>
          </div>
          <div className="app-summary-section__pills">
            {strengths.map((strength) => (
              <span key={strength} className="app-summary-section__strength-pill">
                {strength}
              </span>
            ))}
          </div>
        </div>
      )}

      {/* Career Experience Section */}
      {hasCareerData && (
        <ExpandableSection
          sectionId="career-experience"
          title="Career Experience"
          icon={<Briefcase size={16} />}
          meta={careerMeta}
          hideWhenEmpty={true}
        >
          <CareerExperience applicant={applicant} />
        </ExpandableSection>
      )}

      <EducationSummary applicant={applicant} />

      <Row style={{ marginTop: '24px' }}>
        <Col style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <FeedbackWidget
            size="md"
            uniqueId={applicant.id}
            feature="AI_CV_SUMMARY"
            label="Let us know your feedback"
            style={{
              padding: '10px 5px 5px 5px',
              backgroundColor: '#f8fafc',
              transition: 'all 0.2s ease',
            }}
            txtStyle={{
              fontSize: '14px',
              color: '#4b5563',
              fontWeight: 500,
              marginRight: '8px'
            }}
          />
        </Col>
      </Row>
    </div>
  );
};

export default AISummarySection;
