import React from 'react';
import PropTypes from 'prop-types';

import { ClientAdminNoDataMessage } from '../../Common';

function NoEmailTemplateSplash({ onButtonClick, tab }) {
  let message;
  let title;

  if (tab.toLowerCase() === 'system') {
    title = 'There are currently no system templates for you to use';
    message = "Templates will appear as they are added. If you're expecting a template please contact support.";
  } else {
    title = `You currently have no ${tab.toLowerCase()} templates`;
    message = 'Create a template to start using it in your workflows!';
  }

  return (
    <ClientAdminNoDataMessage
      title={title}
      message={message}
      btnProps={{
        onClick: onButtonClick,
        label: 'Create a template',
      }}
    />
  );
}

NoEmailTemplateSplash.propTypes = {
  onButtonClick: PropTypes.func,
  tab: PropTypes.string,
};

NoEmailTemplateSplash.defaultProps = {
  onButtonClick: () => {},
  tab: null,
};

export default NoEmailTemplateSplash;
