import React, { useState } from 'react';
import { Row, Col } from 'reactstrap';
import { useLocation } from 'react-router-dom';
import browserStore from 'store2';

import Tabs from '@Base/Tabs/Tabs';

import { localStorageNames } from '@JS/constants/storageNames';

import ScheduleDescriptions from './ScheduleDescriptions';
import ScheduleEventTypes from './ScheduleEventTypes';
import ScheduleCommunication from './ScheduleCommunication';
import { PageColumn } from '../../Common';

const { SCHEDULE_TAB } = localStorageNames;

function ScheduleAdmin() {
  const pageLocation = useLocation();
  const { hash } = pageLocation;
  const lastTab = browserStore.get(SCHEDULE_TAB);

  const [activeTab, setActiveTab] = useState((hash || lastTab || 'eventtypes').replace('#', ''));

  return (
    <PageColumn className={'pt-0'}>
      <Row className="tab-row" style={{ maxWidth: '1600px', width: '100%', margin: '0 auto' }}>
        <Col className="ps-0">
          <Tabs
            activeTab={activeTab}
            className="scheduler-settings-tabs"
            onClick={(id) => {
              setActiveTab(id);
              browserStore(SCHEDULE_TAB, id);
            }}
            positionAbsolute={false}
            tabs={[
              { anchor: 'eventtypes', label: 'Event Types' },
              { anchor: 'eventdescription', label: 'Descriptions' },
              { anchor: 'communication', label: 'Communication' },
            ]}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          {activeTab === 'eventtypes' && <ScheduleEventTypes />}
          {activeTab === 'eventdescription' && <ScheduleDescriptions />}
          {activeTab === 'communication' && <ScheduleCommunication />}
        </Col>
      </Row>
    </PageColumn>
  );
}

export default ScheduleAdmin;
