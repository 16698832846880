import axios from 'axios';
import config from '../config/config';
import { getCommonHeaders } from './common-api-utils';

const {
  workflows: { workflowPath, tenantWorkflowPath },
  api: { middlewareAPIURL },
} = config;

export async function getAllWorkflows(searchName, filterStatus) {
  let path = `${middlewareAPIURL}/v2${workflowPath}`;

  const params = [];

  if (searchName) {
    params.push(`searchName=${encodeURIComponent(searchName)}`);
  }

  if (filterStatus) {
    params.push(`status=${encodeURIComponent(filterStatus)}`);
  }

  if (params.length > 0) {
    path += `?${params.join('&')}`;
  }
  const result = await axios.get(path, { headers: getCommonHeaders() });

  if (result.status === 201 || result.status === 200) return result.data;

  return null;
}

export async function getWorkflowById(id) {
  const result = await axios.get(`${middlewareAPIURL}/v2${workflowPath}/${id}`, { headers: getCommonHeaders() });

  if (result.status === 201 || result.status === 200) return result.data;

  return null;
}

export async function createWorkflow(data = {}) {
  if (Object.keys(data).length) {
    const result = await axios.post(
      `${middlewareAPIURL}/v2${workflowPath}`,
      { ...data },
      { headers: getCommonHeaders() },
    );

    if (result.status === 200 || result.status === 201) return result.data;
  }

  return null;
}

export async function updateWorkflow(data = {}) {
  if (Object.keys(data).length) {
    const result = await axios.put(
      `${middlewareAPIURL}/v2${workflowPath}`,
      { ...data },
      { headers: getCommonHeaders() },
    );

    if (result.status === 200 || result.status === 201) return result.data;
  }

  return null;
}

export async function updateStatus(id, status) {
  if (!id || !status) return null;

  const result = await axios.put(
    `${middlewareAPIURL}/v2${workflowPath}/status/${id}`,
    { status },
    { headers: getCommonHeaders() },
  );

  if (result.status === 200 || result.status === 201) return result.data;

  return null;
}

export async function deleteWorkflow(id) {
  if (id) {
    const result = await axios.delete(`${middlewareAPIURL}/v2${workflowPath}/${id}`, { headers: getCommonHeaders() });

    if (result.status === 201 || result.status === 200) return result;
  }

  return null;
}

export async function getTenantWorkflow() {
  const result = await axios.get(`${middlewareAPIURL}/v2${tenantWorkflowPath}`, { headers: getCommonHeaders() });

  if (result.status === 201 || result.status === 200) return result.data;

  return null;
}
