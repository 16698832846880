import React from 'react';
import PropTypes from 'prop-types';
import { EditButton } from '@Base/Buttons';
import { dateTimeUtils } from '../utils';

function EventEditButton({ eventObj, onClick, label, isEditable }) {
  const { start } = eventObj;

  if (!isEditable || dateTimeUtils.isPast(start)) return null;

  return <EditButton size="sm ms-3" action={onClick} label={label} />;
}

EventEditButton.propTypes = {
  eventObj: PropTypes.shape({
    start: PropTypes.instanceOf(Date),
  }),
  onClick: PropTypes.func,
  label: PropTypes.string,
  isEditable: PropTypes.bool,
};

EventEditButton.defaultProps = {
  eventObj: {},
  onClick: () => {},
  label: 'Edit',
  isEditable: false,
};

export default EventEditButton;
