export const schema = {
  type: 'object',
  required: [
    'name',
    //'contactEmail',
    // 'country',
    // 'postCode',
  ],
  type: 'object',
  required: ['name'],
  dependencies: {
    disabled: {
      oneOf: [
        {
          properties: {
            disabled: { const: true },
            hideCompany: {
              type: 'boolean',
              default: true,
              readOnly: true,
            },
          },
        },
        {
          properties: {
            disabled: { const: false },
            hideCompany: {
              type: 'boolean',
              default: false,
            },
          },
        },
      ],
    },
  },
  properties: {
    name: {
      type: 'string',
      title: 'Name',
    },
    // businessType: {
    //   type: 'string',
    //   title: 'Business Type',
    // },
    contactEmail: {
      type: 'string',
      title: 'Contact Email Address',
      format: 'email',
    },
    country: {
      type: 'string',
      title: 'Country',
    },
    postCode: {
      type: 'string',
      title: 'Post Code',
      maxLength: 8,
      // minLength: 6,
      // pattern: '^[a-zA-Z]{1,2}\\d[a-z\\d]?\\s*\\d[a-zA-Z]{2}$',
    },
    disabled: {
      type: 'boolean',
      title: 'Disable account',
      default: false,
    },
    restrictAppsPerAccount: {
      type: 'boolean',
      title: 'Account level unique applications enforced',
      default: false,
    },
    hideCompany: {
      type: 'boolean',
      title: 'Account is hidden',
      default: false,
    },
    // users: {
    // type: 'array',
    // title: 'Add remove users accessing this account',
    // },
  },
};

export const uiSchema = {
  'ui:order': Object.keys(schema.properties),
  'ui:rootFieldId': 'accountAdmin',
  name: {
    classNames: 'account-name',
  },
  contactEmail: {
    classNames: 'contact-email',
  },
  country: {
    'ui:widget': 'countrySelect',
  },
  restrictAppsPerAccount: {
    classNames: 'restrict-apps-account',
    'ui:help':
      'If the above box is checked it ensures that a candidate can only apply once across all active ' +
      'vacancies posted by this account. This check is performed on email address ' +
      'so does not eliminate the possibility a candidate can apply more than once with different email addresses. ' +
      'PLEASE NOTE: it can take several hours for this setting to take effect.',
  },
  hideCompany: {
    classNames: 'is-hidden',
    'ui:help': "If the above box is checked companies won't appear on the careers site and ATS system",
  },
  disabled: {
    classNames: 'is-disabled',
  },
};
