/**
 * Time calculation utilities for consistent time formatting across components
 */

/**
 * Converts a time period in days to a formatted string showing years and months
 * @param {number} days - Number of days
 * @param {Object} options - Formatting options
 * @param {boolean} options.abbreviated - Whether to use abbreviated units (yr/mth vs yrs/mths)
 * @param {boolean} options.singular - Whether to use singular form for single units (yr vs yrs)
 * @returns {string} Formatted time string
 */
export const formatDaysToYearsMonths = (days, options = {}) => {
  const {
    abbreviated = false,
    singular = false
  } = options;

  const years = Math.floor(days / 365);
  const months = Math.floor((days % 365) / 30);

  // Define unit labels based on options
  const yearUnit = singular && years === 1 ? 'yr' : (abbreviated ? 'yr' : 'yrs');
  const monthUnit = singular && months === 1 ? 'mth' : (abbreviated ? 'mth' : 'mths');

  if (years > 0) {
    return months > 0 ? `${years} ${yearUnit} ${months} ${monthUnit}` : `${years} ${yearUnit}`;
  }
  return `${months} ${monthUnit}`;
};

/**
 * Normalizes a time value to days based on its temporal unit
 * @param {number} timeValue - The time value
 * @param {string} temporalUnit - The unit of time (DAY, MONTH, YEAR, etc.)
 * @returns {number} The time value converted to days
 */
export const normalizeToDays = (timeValue, temporalUnit) => {
  if (!timeValue || timeValue === 0) return 0;

  switch (temporalUnit?.toUpperCase()) {
    case 'DAY':
      return timeValue;
    case 'MONTH':
      return timeValue * 30; // Approximate
    case 'YEAR':
      return timeValue * 365; // Approximate
    case 'WEEK':
      return timeValue * 7;
    default:
      // If unknown unit, return the original value
      return timeValue;
  }
};

/**
 * Formats a time in position based on the time value and unit
 * @param {number} timeInPosition - The time value
 * @param {string} temporalUnit - The unit of time (DAY, MONTH, YEAR, etc.)
 * @param {Object} options - Formatting options
 * @returns {string|null} Formatted time string or null if invalid input
 */
export const formatTimeInPosition = (timeInPosition, temporalUnit, options = {}) => {
  if (!timeInPosition || timeInPosition === 0) return null;

  // For day units, convert to years/months format
  if (temporalUnit?.toUpperCase() === 'DAY') {
    return formatDaysToYearsMonths(timeInPosition, options);
  }

  // For other units, display as-is with the unit name
  const singularUnit = temporalUnit?.toLowerCase() || '';
  const pluralUnit = singularUnit ? (singularUnit.endsWith('s') ? singularUnit : `${singularUnit}s`) : '';
  const unit = timeInPosition === 1 ? singularUnit : pluralUnit;

  return `${timeInPosition} ${unit}`;
};

/**
 * Calculates average time in role from employment data
 * @param {Array} employmentData - Array of job objects with timeInPosition and temporalUnit properties
 * @param {Object} options - Formatting options
 * @returns {string|null} Formatted average time or null if no valid data
 */
export const calculateAverageTimeInRole = (employmentData, options = {}) => {
  if (!employmentData || !Array.isArray(employmentData) || employmentData.length === 0) {
    return null;
  }

  const validJobs = employmentData.filter(job =>
    job.timeInPosition && job.timeInPosition > 0
  );

  if (validJobs.length === 0) return null;

  // Convert all times to days for accurate calculation
  const totalDays = validJobs.reduce((sum, job) => {
    return sum + normalizeToDays(job.timeInPosition, job.temporalUnit);
  }, 0);

  const avgDays = totalDays / validJobs.length;

  return formatDaysToYearsMonths(avgDays, options);
};
