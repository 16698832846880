import React from 'react';
import PropTypes from 'prop-types';
import { Col } from 'reactstrap';
import cx from 'classnames';

import { LoadingScreen } from '@Base/Loading';
import { ErrorMessage } from '@Base/ErrorScreen';

import { checkPermissions } from '@JS/auth/AuthUtils';

function PageColumn({ state, className, permissions, children }) {
  if (!checkPermissions(permissions)) return null;

  if (state.isPending) return <LoadingScreen isEmbeded />;

  return (
    <Col className={cx('p-3', className)}>
      {state.isRejected ? <ErrorMessage error={state.rejectionStatus} /> : children}
    </Col>
  );
}

PageColumn.propTypes = {
  state: PropTypes.shape(),
  className: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  permission: PropTypes.shape(),
};

PageColumn.defaultProps = {
  state: {},
  className: null,
  children: null,
  permissions: [],
};

export default PageColumn;
