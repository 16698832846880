// eslint-disable-next-line no-unused-vars
import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { downloadFiles } from '@API/FileAPI/FileAPI';

function ValueDisplay(props) {
  const { isMultiline, isFile } = props;
  let { value } = props;

  // true = Yes / false = No
  if (typeof value === 'boolean') value = value ? 'Yes' : 'No';

  if (!isFile) {
    return (
      <div
        className={cx(
          'value-display',
          { 'd-inline-block': !isMultiline },
          { 'd-block': isMultiline },
          { 'mt-1': isMultiline },
        )}
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{ __html: value }}
        style={{ ...(isMultiline ? { height: 'auto' } : {}) }}
      />
    );
  }
  return (
    // eslint-disable-next-line jsx-a11y/anchor-is-valid
    <a
      className={cx(
        'value-display',
        { 'd-inline-block': !isMultiline },
        { 'd-block': isMultiline },
        { 'mt-1': isMultiline },
      )}
      href="#"
      onClick={() => downloadFiles([value])}
    >
      Download
    </a>
  );
}

ValueDisplay.propTypes = {
  isFile: PropTypes.bool,
  isMultiline: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
};

ValueDisplay.defaultProps = {
  isFile: false,
  isMultiline: false,
  value: '',
};

export default ValueDisplay;
