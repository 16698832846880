/* eslint-disable jsx-a11y/label-has-for */
import React, { Fragment, useState } from 'react';
import { Form, FormGroup, Label, Col, Input, InputGroup, InputGroupText, FormText } from 'reactstrap';
import { toast } from 'react-toastify';
import { useFormKeypress, useLanguagePack } from '@Base/hooks';
import Required from '@Base/Forms/Custom/Required';
import ErrorFeedback from '@Base/ErrorFeedback/ErrorFeedback';
import { EditButton, CreateButton, CancelButton } from '@Base/Buttons';
import IconSVG from '@Base/SVG/IconSVG';
import validation from '@JS/utils/validation';
import { retryableAPICall } from '@API/common-api-utils';
import { saveUserPassword } from '@API/AccountAPI';
import { requestStatuses } from '@JS/constants/requestStatuses';
import { ValueDisplay } from '@Base/ValueDisplay';

import { EnhancedCardTitle } from '../../Common';

function validate(formData, onlyMatch) {
  let errObj = {};

  if (!onlyMatch) {
    errObj = validation(
      [
        { id: 'oldPassword', required: true },
        { id: 'password', required: true, password: true },
        { id: 'confirmPassword', required: true, password: true },
      ],
      formData,
    );
  }

  if (formData.password !== formData.confirmPassword) {
    errObj = {
      ...errObj,
      confirmPassword: {
        ...errObj.confirmPassword,
        invalid: true,
        errors: [...((errObj.confirmPassword || {}).errors || []), 'Value does not match password'],
      },
    };
  }

  return errObj;
}

function mapErrors(errObj) {
  return Object.entries(errObj).reduce((acc, [fieldId, { invalid, errors: fieldErrs }]) => {
    const [errMsg] = fieldErrs;
    if (invalid) acc[fieldId] = errMsg;
    return acc;
  }, {});
}

const defaultData = { oldPassword: '', password: '', confirmPassword: '' };

function PasswordForm() {
  const formRef = useFormKeypress();
  const languagePack = useLanguagePack('personal-information');
  const [isEditing, setIsEditing] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [formData, setFormData] = useState({ ...defaultData });
  const [errors, setErrors] = useState({});
  const [showText, setShowText] = useState({ oldPassword: false, password: false, confirmPassword: false });

  function handleChange(id, value) {
    setFormData({ ...formData, [id]: value });
  }

  function handleBlur() {
    setErrors(mapErrors(validate(formData, true)));
  }

  async function handleSave() {
    setIsSaving(true);

    const errObj = validate(formData);
    setErrors(mapErrors(errObj));

    const hasErrors = Object.values(errObj).some(({ invalid }) => invalid);

    if (!hasErrors) {
      const { oldPassword, password } = formData;

      const resp = await retryableAPICall(() =>
        saveUserPassword({
          currentPassword: oldPassword,
          newPassword: password,
        }),
      );

      if (typeof resp === 'string' && resp.length) {
        const errStr = resp === requestStatuses.PERMISSION_DENIED ? 'passwordError403' : 'passwordError';
        toast.error(languagePack[errStr]);
      } else {
        toast.success(languagePack.passwordSuccess);
        setFormData({ ...defaultData });
        setIsEditing(false);
      }
    }

    setIsSaving(false);
  }

  return (
    <Fragment>
      <EnhancedCardTitle title={languagePack.securityPageHeading} subtitle="Manage your password">
        {isEditing ? null : (
          <EditButton
            floatRight={false}
            label={languagePack.updatePassword || 'Update Password'}
            action={() => setIsEditing(true)}
            className="mb-auto"
          />
        )}
      </EnhancedCardTitle>
      <FormGroup row className="disabled-margin-bottom pb-2">
        <Label sm="2" for="email">{`${languagePack.passwordTitle || 'Password'}:`}</Label>
        <Col sm="6">
          <ValueDisplay value="************" />
        </Col>
      </FormGroup>
      {!isEditing ? null : (
        <Form innerRef={formRef}>
          <FormGroup row className="disabled-margin-bottom pb-1">
            <Label sm="2" for="oldPassword">
              {`${languagePack.oldPassword || 'Existing Password'}:`}
              <Required />
            </Label>
            <Col sm="3" xs="4">
              <InputGroup>
                <Input
                  type={showText.oldPassword ? 'text' : 'password'}
                  id="password"
                  value={formData.oldPassword}
                  onChange={(e) => handleChange('oldPassword', e.target.value)}
                />
                <div className="input-group-append">
                  <InputGroupText
                    onClick={() => setShowText({ ...showText, oldPassword: !showText.oldPassword })}
                    style={{ cursor: 'pointer' }}
                  >
                    <IconSVG name={showText.oldPassword ? 'Eye Slash' : 'Eye'} />
                  </InputGroupText>
                </div>
              </InputGroup>
              <ErrorFeedback message={errors.oldPassword} />
            </Col>
          </FormGroup>
          <FormGroup row className="disabled-margin-bottom pb-1">
            <Label sm="2" for="password">
              {`${languagePack.password || 'New Password'}:`}
              <Required />
            </Label>
            <Col sm="3" xs="4">
              <InputGroup>
                <Input
                  type={showText.password ? 'text' : 'password'}
                  id="password"
                  value={formData.password}
                  onChange={(e) => handleChange('password', e.target.value)}
                />
                <div className="input-group-append">
                  <InputGroupText
                    onClick={() => setShowText({ ...showText, password: !showText.password })}
                    style={{ cursor: 'pointer' }}
                  >
                    <IconSVG name={showText.password ? 'Eye Slash' : 'Eye'} />
                  </InputGroupText>
                </div>
              </InputGroup>
              <ErrorFeedback message={errors.password} />
            </Col>
          </FormGroup>
          <FormGroup row className="disabled-margin-bottom pb-1">
            <Label sm="2" for="confirmPassword">
              {`${languagePack.confirmPassword || 'Confirm New Password'}:`}
              <Required />
            </Label>
            <Col sm="3" xs="4">
              <InputGroup>
                <Input
                  type={showText.confirmPassword ? 'text' : 'password'}
                  id="confirmPassword"
                  value={formData.confirmPassword}
                  onChange={(e) => handleChange('confirmPassword', e.target.value)}
                  onBlur={handleBlur}
                />
                <div className="input-group-append">
                  <span
                    className="input-group-text"
                    onClick={() => setShowText({ ...showText, confirmPassword: !showText.confirmPassword })}
                  >
                    <IconSVG name={showText.confirmPassword ? 'Eye Slash' : 'Eye'} />
                  </span>
                </div>
              </InputGroup>
              <ErrorFeedback message={errors.confirmPassword} />
            </Col>
          </FormGroup>
          <FormText color="muted">{languagePack.passwordHint}</FormText>
          <FormGroup className="disabled-margin-bottom pb-1">
            <CreateButton
              className="mt-2"
              label={isSaving ? 'Saving...' : 'Save'}
              isLoading={isSaving}
              disabled={isSaving}
              floatRight={false}
              action={(e) => {
                e.preventDefault();
                handleSave();
              }}
            />
            <CancelButton
              className="mt-2 ms-2"
              floatRight={false}
              action={() => {
                setIsEditing(false);
                // reset
                setErrors({});
                setFormData({ ...defaultData });
              }}
            />
          </FormGroup>
        </Form>
      )}
    </Fragment>
  );
}

export default PasswordForm;
