import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useLanguagePack, useMounted } from '@Base/hooks';
import { retryableAPICall } from '@API/common-api-utils';
import { getApplicantNotes } from '@API/CandidateAPI/CandidateAPI';
import TimelineContainer from './TimelineContainer';
import TimelineEntry from './TimelineEntry';

async function getNotes(applicantId, onSuccess = () => {}, onError = () => {}) {
  if (applicantId) {
    const resp = await retryableAPICall(() => getApplicantNotes(applicantId));

    if (typeof resp === 'string') {
      onError();
    } else {
      onSuccess(resp);
    }
  }
}

function CandidateNotes({ applicantId }) {
  const isMounted = useMounted();
  const languagePack = useLanguagePack('notes-container');
  const [notesList, setNotesList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();

  useEffect(() => {
    if (isMounted() && applicantId) {
      setLoading(true);

      getNotes(
        applicantId,
        (resp) => {
          setNotesList(
            resp.map((note) => ({
              ...note,
              createdDateTime: moment.utc(note.createdDateTime).local().format('DD-MM-YYYY HH:mm:ss'),
            })),
          );
          setLoading(false);
        },
        () => setError(true),
      );
    }
  }, [applicantId, isMounted]);

  return (
    <TimelineContainer
      className="candidate-notes"
      error={error}
      errorMessage={languagePack.notesError || 'Error loading Notes'}
      loading={loading}
      title={languagePack.fullNotesTitle || 'Notes'}
    >
      {notesList.map((note) => {
        const { id, authorName, content, createdDateTime } = note;

        return (
          <TimelineEntry
            key={id}
            content={content
              .replace(/@\[(.*?)]/g, '<strong>@$1</strong>')
              .replace(/#\[(.*?)]/g, '<strong>#$1</strong>')}
            createdDateTime={createdDateTime}
            title={`Author: ${authorName}`}
            type="NOTE"
          />
        );
      })}
    </TimelineContainer>
  );
}

CandidateNotes.propTypes = {
  applicantId: PropTypes.string,
};

CandidateNotes.defaultProps = {
  applicantId: null,
};

export default CandidateNotes;
