import googleLogo from '../../../assets/img/brand/logo/google-logo.jpg';
import outlookLogo from '../../../assets/img/brand/logo/outlook-logo.png';

export const serviceDisplayNameMap = {
  GOOGLE: 'Google',
  OUTLOOK: 'Outlook',
};

export const logoMap = {
  GOOGLE: googleLogo,
  OUTLOOK: outlookLogo,
};

export const googleCalendarColours = [
  { value: 1, label: 'Lavender', colour: '#7986cb' },
  { value: 2, label: 'Sage', colour: '#33b679' },
  { value: 3, label: 'Grape', colour: '#8e24aa' },
  { value: 4, label: 'Flamingo', colour: '#e67c73' },
  { value: 5, label: 'Banana', colour: '#f6c026' },
  { value: 6, label: 'Tangerine', colour: '#f5511d' },
  { value: 7, label: 'Peacock', colour: '#039be5' },
  { value: 8, label: 'Graphite', colour: '#616161' },
  { value: 9, label: 'Blueberry', colour: '#3f51b5' },
  { value: 10, label: 'Basil', colour: '#0b8043' },
  { value: 11, label: 'Tomato', colour: '#d60000' },
];

export const menuStyles = {
  option: (styles) => ({ ...styles, colour: 'black' }),
  control: (styles) => ({ ...styles, backgroundColor: 'white' }),
  menuList: (provided) => ({ ...provided, maxHeight: `130px` }),
};
