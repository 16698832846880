import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import useDeviceDetection from '@JS/utils/useDeviceDetection';

function Import({ touch, desktop, children }) {
  const [Component, setComponent] = useState(null);
  const device = useDeviceDetection();

  useEffect(() => {
    const importCb = device === 'Mobile' ? touch : desktop;
    importCb().then((componentDetails) => setComponent(componentDetails));
  }, [device, desktop, touch]);

  return children(Component ? Component.default : () => null);
}

Import.propTypes = {
  touch: PropTypes.func,
  desktop: PropTypes.func,
  children: PropTypes.func,
};

Import.defaultProps = {
  touch: () => {},
  desktop: () => {},
  children: () => {},
};

export default Import;
