/* eslint-disable jsx-a11y/label-has-for */
// eslint-disable-next-line no-unused-vars
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Form, FormGroup, Label, Input, Row, Col } from 'reactstrap';
import isEmpty from 'lodash.isempty';

import { CreateButton, IconButton } from '@Base/Buttons';
import { Confirmation } from '@Base/Modal';
import { useFormKeypress } from '@Base/hooks';
import { AccountFormSelect } from '@Base/Account';

import { defaultApplicationSection } from '@JS/model/form-schemas/flexiforms/default-application-form';

import { spliceItem, createBaseSection, wrapperRefPropType, FORM_TYPES, moveQuestion } from '../utils';
import Section from './Section';
import FormManagerContext from './FormManagerContext';
import ErrorMessage from './ErrorMessage';
import LogicModal from './LogicModal';
import { MoveQuestionProvider } from './MoveQuestionContext';

function FormManager({
  formThemes,
  isModal,
  wrapperRef,
  formSections,
  themeId,
  formName: fName,
  formType: fType,
  sharedWith,
  readOnly,
  schema,
  formTypeCount,
  isSubmitted,
  isEditing,
  onChange,
  totalAccounts,
  openPreviewForm,
}) {
  const [formName, setFormName] = useState('');
  const [formType, setFormType] = useState('');
  const [formReadOnly, setFormReadOnly] = useState(false);
  const [formShare, setFormShare] = useState([]);
  const [formThemeId, setFormThemeId] = useState(themeId);
  const [sections, setSections] = useState([]);
  const [showConfirm, setShowConfirm] = useState(false);
  const [deleteSectionNum, setDeleteSectionNum] = useState(0);
  const [isLogicModalOpen, setIsLogicModalOpen] = useState(false);
  const [logicQuestion, setLogicQuestion] = useState();
  const [logicQuestionsIds, setLogicQuestionsIds] = useState();
  const formRef = useFormKeypress();

  useEffect(() => {
    setFormName(fName);
    setFormType(fType);
    setSections(formSections);
    setFormReadOnly(readOnly);
    setFormShare(sharedWith);
    setFormThemeId(themeId);

    // We use the logic id to determine if screening can be applied to a question.
    const logicIds = formSections.reduce((memo, section) => {
      memo.push(
        ...section.questions.reduce((mem, question) => {
          if (question.config?.logic?.conditions?.field?.questionId) {
            mem.push(question.config.logic.conditions.field.questionId);
          }
          return mem;
        }, []),
      );
      return memo;
    }, []);

    setLogicQuestionsIds(logicIds);
  }, [fName, fType, formSections, readOnly, sharedWith, themeId]);

  function handleDeleteSection(secIdx) {
    const updatedSections = spliceItem(sections, secIdx);
    setSections(updatedSections);
    onChange(updatedSections, formName, formType, formReadOnly, formShare, formThemeId);
  }

  function handleChangeSection(updatedSection, secIdx, idsToSync) {
    const updatedSections = spliceItem(sections, secIdx, updatedSection);
    if (idsToSync) {
      sections.forEach((section) => {
        section.questions.forEach((question) => {
          if (
            !isEmpty(question?.config?.logic) &&
            question.config.logic.conditions.field.questionId === idsToSync?.oldId
          ) {
            // eslint-disable-next-line no-param-reassign
            question.config.logic.conditions.field.questionId = idsToSync.newId;
          }
        });
      });
    }

    setSections(updatedSections);
    onChange(updatedSections, formName, formType, formReadOnly, formShare, formThemeId);
  }

  function handleAddSection(updatedSectionArr) {
    setSections(updatedSectionArr);
    onChange(updatedSectionArr, formName, formType, formReadOnly, formShare, formThemeId);
  }

  function handleOrderChange(from, to) {
    const updatedSections = moveQuestion(sections, from, to);
    setSections(updatedSections);
    onChange(updatedSections, formName, formType, formReadOnly, formShare, formThemeId);
  }

  function handleMoveQuestion(currentPosition, newPosition) {
    const newSections = [...sections];
    const sectionToMove = newSections[currentPosition];

    newSections[currentPosition] = newSections[newPosition];
    newSections[newPosition] = sectionToMove;
    setSections(newSections);
    onChange(newSections, formName, formType, formReadOnly, formShare, formThemeId);
  }

  return (
    <FormManagerContext.Consumer>
      {({ onSectionAdd, onSectionEdit, onQuestionAdd }) => (
        <>
          <Form className="flexiform" innerRef={formRef}>
            <Row>
              <Col>
                <h5>Form Settings</h5>
              </Col>
              <Col className="d-flex justify-content-end">
                <IconButton
                  action={openPreviewForm}
                  label="Preview Form"
                  iconClassName="fa fa-eye"
                  disabled={Object.keys(schema).length === 0}
                />
              </Col>
            </Row>
            <Row form>
              <Col>
                <FormGroup>
                  <Label for="form-name">Form name</Label>
                  <Input
                    id="form-name"
                    name="formName"
                    onChange={(e) => {
                      const {
                        target: { value },
                      } = e;
                      setFormName(value);
                      onChange(sections, value, formType, formReadOnly, formShare, formThemeId);
                    }}
                    type="text"
                    // invalid={isSubmitted && !formName.length}
                    value={formName}
                  />
                  <ErrorMessage isInvalid={isSubmitted && !formName.length}>Form name required</ErrorMessage>
                </FormGroup>
              </Col>
              <Col>
                {!isEditing && (
                  <FormGroup>
                    <Label for="form-type">Form type</Label>
                    <Input
                      id="form-type"
                      onChange={(e) => {
                        const {
                          target: { value },
                        } = e;
                        setFormType(value);
                        let newSect = sections;

                        // Application form has some fields that we must have
                        if (value === 'APPLICATION_FORM') {
                          newSect = defaultApplicationSection;
                        }

                        onChange(newSect, formName, value, formReadOnly, formShare, formThemeId);
                      }}
                      type="select"
                      value={formType}
                    >
                      {FORM_TYPES.map(({ type, value: val, max }) => {
                        if (max && formTypeCount[type] && formTypeCount[type] >= max && type !== formType) {
                          return null;
                        }
                        return (
                          <option key={type} value={type}>
                            {val}
                          </option>
                        );
                      })}
                    </Input>
                  </FormGroup>
                )}
              </Col>
            </Row>
            <Row form>
              <Col sm="6">
                <FormGroup>
                  <Label for="form-style">Form style</Label>
                  <Input
                    id="form-style"
                    onChange={(e) => {
                      const {
                        target: { value },
                      } = e;
                      setFormThemeId(value);
                      onChange(sections, formName, formType, formReadOnly, formShare, value, formThemeId);
                    }}
                    type="select"
                    value={formThemeId}
                  >
                    <option value={null}>Default Style</option>
                    {formThemes.map(({ id, name }) => (
                      <option key={id} value={id}>
                        {name}
                      </option>
                    ))}
                  </Input>
                </FormGroup>
              </Col>
            </Row>
            {totalAccounts > 1 && (
              <Row>
                <Col>
                  <FormGroup>
                    <Label check>
                      <Input
                        checked={!formReadOnly}
                        className="standard-checkbox small position-relative ms-0"
                        onChange={(e) => {
                          onChange(sections, formName, formType, !e.target.checked, formShare, formThemeId);
                        }}
                        type="checkbox"
                      />
                      <span>Editable</span>
                    </Label>
                  </FormGroup>
                  <FormGroup>
                    <AccountFormSelect
                      className="account-opts disabled-margin-bottom pb-2"
                      currentAccounts={(formShare || []).map(({ accountId, accountName }) => ({
                        id: accountId,
                        name: accountName,
                      }))}
                      disableActiveAccount
                      id="sharedWith"
                      isMulti
                      ignoreActiveAccount
                      label="Shared With"
                      onChange={(accountObjs = []) => {
                        const accounts = accountObjs.map(({ value, label }) => ({
                          accountId: value,
                          accountName: label,
                        }));
                        onChange(sections, formName, formType, formReadOnly, accounts, formThemeId);
                      }}
                    />
                  </FormGroup>
                </Col>
              </Row>
            )}

            <h5>Form Details</h5>
            <MoveQuestionProvider onChange={handleOrderChange} sections={sections}>
              {sections.map((sectionProps, i) => (
                <Section
                  key={sectionProps.id}
                  index={i}
                  isApplicationForm={formType === 'APPLICATION_FORM' && i === 0}
                  {...sectionProps}
                  isLastItem={sections.length - 1 === i}
                  isSubmitted={isSubmitted}
                  logicQuestionsIds={logicQuestionsIds}
                  onChange={(updatedSection, idsToSync) => {
                    onSectionEdit(sectionProps.id);

                    handleChangeSection(updatedSection, i, idsToSync);
                  }}
                  onDelete={() => {
                    setDeleteSectionNum(i);
                    setShowConfirm(true);
                  }}
                  onMoveItem={handleMoveQuestion}
                  onSetLogic={(sectionId, questionId, logic) => {
                    setLogicQuestion({ logic, questionId, sectionId });
                    setIsLogicModalOpen(true);
                  }}
                  questionProps={{ isModal, wrapperRef }}
                  formType={formType}
                />
              ))}
            </MoveQuestionProvider>
            <CreateButton
              action={(e) => {
                e.preventDefault();
                const newSection = createBaseSection();
                newSection.questions.forEach(({ id: qId }) => onQuestionAdd(qId));
                onSectionAdd(newSection.id);
                handleAddSection([...sections, { ...newSection }]);
              }}
              className="btn-sm"
              floatRight={false}
              label="Add Section"
            />
          </Form>
          <Confirmation
            cancelCallback={() => setShowConfirm(false)}
            confirmCallback={() => {
              handleDeleteSection(deleteSectionNum);
              setShowConfirm(false);
            }}
            content="Are you sure you want to delete this section?"
            show={showConfirm}
            title="Delete Section?"
          />
          <LogicModal
            currentQuestion={logicQuestion}
            isOpen={isLogicModalOpen}
            onChange={(updatedSections) => {
              setSections(updatedSections);
              onChange(updatedSections, formName, formType, formReadOnly, formShare);
            }}
            onClose={() => {
              setIsLogicModalOpen(false);
              setLogicQuestion();
            }}
            sections={sections}
          />
        </>
      )}
    </FormManagerContext.Consumer>
  );
}

FormManager.propTypes = {
  formName: PropTypes.string,
  formThemes: PropTypes.arrayOf(PropTypes.shape()),
  formSections: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    }),
  ),
  themeId: PropTypes.string,
  formType: PropTypes.string,
  formTypeCount: PropTypes.objectOf(PropTypes.number),
  isEditing: PropTypes.bool,
  isModal: PropTypes.bool,
  isSubmitted: PropTypes.bool,
  onChange: PropTypes.func,
  openPreviewForm: PropTypes.func,
  readOnly: PropTypes.bool,
  schema: PropTypes.shape(),
  sharedWith: PropTypes.arrayOf(PropTypes.shape()),
  totalAccounts: PropTypes.number,
  wrapperRef: wrapperRefPropType,
};

FormManager.defaultProps = {
  formThemes: [],
  formName: '',
  themeId: null,
  formSections: [],
  formType: 'GENERIC',
  formTypeCount: {},
  isEditing: false,
  isModal: false,
  isSubmitted: false,
  onChange: () => {},
  openPreviewForm: () => {},
  readOnly: false,
  schema: {},
  sharedWith: [],
  totalAccounts: 0,
  wrapperRef: null,
};

export default FormManager;
