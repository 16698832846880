// components/DashboardHeader.js
import React from 'react';
import PropTypes from 'prop-types';

import { TopDetailBar } from '@Base/DetailBar';

import FlexEnd from './FlexEnd';

const DashboardHeader = ({
  pageTitle,
  activeTab,
  allowAccountSwitch,
  accountSwitchSections,
  activeAccountName,
  setAccountSwitchOpen,
}) => {
  const renderAccountSwitch = () => {
    if (!allowAccountSwitch || !accountSwitchSections.includes(activeTab)) {
      return null;
    }

    return (
      <FlexEnd className="align-items-center">
        <p>
          {`Currently viewing ${pageTitle} in the ${activeAccountName} account `}
          <a
            href="#switch-account"
            onClick={(e) => {
              e.preventDefault();
              setAccountSwitchOpen(true);
            }}
          >
            Switch Account
          </a>
        </p>
      </FlexEnd>
    );
  };

  return (
    <TopDetailBar>
      <div className="ms-auto">{renderAccountSwitch()}</div>
    </TopDetailBar>
  );
};

DashboardHeader.propTypes = {
  pageTitle: PropTypes.string.isRequired,
  titleSubItems: PropTypes.arrayOf(PropTypes.shape({})),
  activeTab: PropTypes.string,
  setIsCreateMode: PropTypes.func.isRequired,
  isCreateMode: PropTypes.bool,
  setSearchTerm: PropTypes.func.isRequired,
  setIsImportModalOpen: PropTypes.func.isRequired,
  allowAccountSwitch: PropTypes.bool,
  accountSwitchSections: PropTypes.arrayOf(PropTypes.string),
  activeAccountName: PropTypes.string,
  setAccountSwitchOpen: PropTypes.func.isRequired,
  isReSyncing: PropTypes.bool,
  userType: PropTypes.string,
};

DashboardHeader.defaultProps = {
  titleSubItems: [],
  activeTab: '',
  isCreateMode: false,
  allowAccountSwitch: false,
  accountSwitchSections: [],
  activeAccountName: '',
  isReSyncing: false,
  userType: '',
};

export default DashboardHeader;
