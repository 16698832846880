/* eslint-disable jsx-a11y/label-has-for */
import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Form, FormGroup, Label, Col, Input } from 'reactstrap';
import { toast } from 'react-toastify';
import QRCode from 'qrcode.react';

import { useFormKeypress, useLanguagePack } from '@Base/hooks';
import { ValueDisplay } from '@Base/ValueDisplay';
import { updateUserDetails } from '@JS/actions/userActions';
import { CreateButton, EditButton, CancelButton, TextButton } from '@Base/Buttons';
import Required from '@Base/Forms/Custom/Required';
import ErrorFeedback from '@Base/ErrorFeedback/ErrorFeedback';
import { Confirmation } from '@Base/Modal';

import { retryableAPICall } from '@API/common-api-utils';
import { saveUserProfile } from '@API/AccountAPI';
import { check2Factor, disable2FA, enable2FA, getQRCodeFor2FA } from '@API/AuthAPI/AuthAPI';

import validation, { mapErrors } from '@JS/utils/validation';
import { requestStatuses } from '@JS/constants/requestStatuses';

import PasswordForm from './PasswordForm';
import { EnhancedCard, PageColumn, EnhancedCardTitle } from '../../Common';

function PersonalInformation({ userDetails, applyUserDetails }) {
  const formRef = useFormKeypress();
  const languagePack = useLanguagePack('personal-information');
  const cachedFormData = useRef({});
  const [isEditing, setIsEditing] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [formData, setFormData] = useState({});
  const [errors, setErrors] = useState({});
  const [showConfirm, setShowConfirm] = useState(false);
  const [twoFactorEnabled, setTwoFactorEnable] = useState(false);
  const [showTwoFactorSetUp, setShowTwoFactorSetUp] = useState(false);
  const [twoFactorQRURI, setTwoFactorQRURI] = useState(null);
  const [twoFactorOTP, setTwoFactorOTP] = useState(null);
  const [twoFactorSecret, setTwoFactorSecret] = useState(null);
  const [disableChallenge, setDisableChallenge] = useState(false);
  const [twoFactorToggled, setTwoFactorToggled] = useState(false);

  async function check2FA() {
    const result = await retryableAPICall(() => check2Factor());

    if (typeof result === 'string' || typeof result !== 'boolean') {
      toast.error(languagePack.twoFactorCheckError);
    } else {
      setTwoFactorEnable(result);
      setTwoFactorToggled(result);
    }
  }

  useEffect(() => {
    check2FA();
  }, []);

  useEffect(() => {
    const { firstName, lastName, email } = userDetails;
    if (!Object.keys(cachedFormData.current).length) cachedFormData.current = { firstName, lastName, email };
    setFormData({ firstName, lastName, email });
  }, [userDetails]);

  function handleChange(id, value) {
    setFormData({ ...formData, [id]: value });
  }

  async function saveUserDetails(callback = () => {}) {
    const { firstName, lastName } = formData;

    const resp = await retryableAPICall(() =>
      saveUserProfile({
        firstName,
        surname: lastName,
      }),
    );

    if (typeof resp === 'string') {
      toast.error(languagePack.errorMessage);
      setIsSaving(false);
    } else {
      toast.success(languagePack.successMessage);
      applyUserDetails(formData);
      callback();
    }
  }

  function handleSave() {
    setIsSaving(true);

    const errObj = validation(
      [
        { id: 'firstName', required: true },
        { id: 'lastName', required: true },
      ],
      formData,
    );
    const { messages, hasErrors } = mapErrors(errObj);
    setErrors(messages);

    if (!hasErrors) setShowConfirm(true);
  }

  async function handle2FAToggle() {
    if (!twoFactorEnabled && !showTwoFactorSetUp) {
      setTwoFactorToggled(true);
      const qrData = await retryableAPICall(() => getQRCodeFor2FA());
      if (typeof qrData !== 'string') {
        setTwoFactorSecret(qrData.secret);
        setTwoFactorQRURI(qrData.QRUri);
        setShowTwoFactorSetUp(true);
      } else {
        toast.error(`${languagePack.twoFactorQRGenerationError}`);
      }
    } else if (!twoFactorEnabled && showTwoFactorSetUp) {
      setTwoFactorToggled(false);
      setShowTwoFactorSetUp(false);
      setTwoFactorOTP(undefined);
    } else if (twoFactorEnabled && disableChallenge) {
      setTwoFactorToggled(true);
      setDisableChallenge(false);
      setTwoFactorOTP(undefined);
    } else {
      setTwoFactorToggled(false);
      setDisableChallenge(true);
    }
  }

  async function handleEnable2FA() {
    if (twoFactorOTP && twoFactorOTP.length === 6) {
      const result = await retryableAPICall(() => enable2FA(twoFactorOTP, twoFactorSecret));
      if (typeof result !== 'string' && typeof result === 'boolean' && result) {
        toast.success(`${languagePack.successfullyEnabledTwoFactor}`);
        setTwoFactorEnable(true);
        setShowTwoFactorSetUp(false);
        setTwoFactorOTP(undefined);
      } else if (result === requestStatuses.INVALID_CREDENTIALS) {
        toast.error(`${languagePack.twoFactorIncorrectCodeError}`);
      } else {
        toast.error(`${languagePack.twoFactorQRGenerationError}`);
      }
    }
  }

  async function handleDisable2FA() {
    if (twoFactorOTP && twoFactorOTP.length === 6) {
      const result = await retryableAPICall(() => disable2FA(twoFactorOTP));
      if (typeof result !== 'string' && typeof result === 'boolean' && result) {
        toast.success(`${languagePack.successfullyDisabledTwoFactor}`);
        setTwoFactorEnable(false);
        setDisableChallenge(false);
        setTwoFactorOTP(undefined);
      } else if (result === requestStatuses.INVALID_CREDENTIALS) {
        toast.error(`${languagePack.twoFactorIncorrectCodeError}`);
      } else {
        toast.error(`${languagePack.twoFactorQRGenerationError}`);
      }
    }
  }

  return (
    <PageColumn permissions={[]}>
      <EnhancedCard className="mb-3">
        <EnhancedCardTitle title={languagePack.pageHeading} subtitle="Update your personal details">
          {!isEditing && <EditButton action={() => setIsEditing(true)} className="mb-auto" floatRight={false} />}
        </EnhancedCardTitle>
        <Form innerRef={formRef} noMargin>
          <FormGroup className="disabled-margin-bottom pb-2" row>
            <Label for="firstName" sm="2">
              {`${languagePack.firstName || 'First name'}:`}
              {isEditing && <Required />}
            </Label>
            <Col sm="3" xs="4">
              {isEditing ? (
                <>
                  <Input
                    id="firstName"
                    onChange={(e) => handleChange('firstName', e.target.value)}
                    type="text"
                    value={formData.firstName}
                  />
                  <ErrorFeedback message={errors.firstName} />
                </>
              ) : (
                <ValueDisplay value={formData.firstName} />
              )}
            </Col>
          </FormGroup>
          <FormGroup className="disabled-margin-bottom pb-2" row>
            <Label for="lastName" sm="2">
              {`${languagePack.lastName || 'Last name'}:`}
              {isEditing && <Required />}
            </Label>
            <Col sm="3" xs="4">
              {isEditing ? (
                <>
                  <Input
                    id="lastName"
                    onChange={(e) => handleChange('lastName', e.target.value)}
                    type="text"
                    value={formData.lastName}
                  />
                  <ErrorFeedback message={errors.lastName} />
                </>
              ) : (
                <ValueDisplay value={formData.lastName} />
              )}
            </Col>
          </FormGroup>
          <FormGroup className="disabled-margin-bottom pb-2" row>
            <Label for="email" sm="2">{`${languagePack.email || 'Email'}:`}</Label>
            <Col sm="3" xs="4">
              <ValueDisplay value={formData.email} />
            </Col>
          </FormGroup>
          {isEditing && (
            <>
              <CreateButton
                action={(e) => {
                  e.preventDefault();
                  handleSave();
                }}
                className="mt-2"
                disabled={isSaving}
                floatRight={false}
                isLoading={isSaving}
                label={isSaving ? 'Saving...' : 'Save'}
              />

              <CancelButton
                action={() => {
                  setFormData(cachedFormData.current);
                  setIsEditing(false);
                }}
                className="mt-2 ms-2"
                floatRight={false}
              />
            </>
          )}
        </Form>
      </EnhancedCard>
      <EnhancedCard className="mb-3 tw-mt-2">
        <PasswordForm />
        <Confirmation
          cancelCallback={() => {
            setFormData(cachedFormData.current);
            setIsEditing(false);
            setShowConfirm(false);
            setIsSaving(false);
          }}
          confirmCallback={() => {
            saveUserDetails(() => {
              setIsEditing(false);
              setShowConfirm(false);
              setIsSaving(false);
            });
          }}
          content={languagePack.confirmMessage}
          show={showConfirm}
        />
      </EnhancedCard>
      <EnhancedCard className="mb-3 tw-mt-2">
        <EnhancedCardTitle title={languagePack.twoFactorAuth} subtitle="Enable or manage 2-Factor Authentication" />
        <div className="mt-3">
          <p>{languagePack.twoFactorAuthDesc}</p>
          <div className="d-flex mt-2">
            <div
              className="d-flex pr-1 mt-1"
              style={{
                display: 'inline-block',
                fontSize: '18px',
                padding: '5px 5px 5px 0px',
                position: 'relative',
              }}
            >
              {languagePack.enableTwoFactorAuth}
            </div>
            <div className="form-check form-switch mt-2 flex-grow-1 ms-1">
              <input
                checked={twoFactorToggled}
                className="text-muted rememberme-switch form-check-input float-start"
                onChange={() => {
                  handle2FAToggle(!twoFactorEnabled);
                }}
                type="checkbox"
              />
            </div>
          </div>
          {showTwoFactorSetUp && (
            <>
              <p className="mt-2">{languagePack.twoFactorInstructions}</p>
              {twoFactorQRURI && (
                <div className="d-block mt-3">
                  <QRCode value={twoFactorQRURI} />
                </div>
              )}
              <div className="d-block mt-3">
                <p>Enter the 6 digit code</p>
                <input
                  autoComplete="off"
                  className="form-control"
                  id="code"
                  max={6}
                  onChange={(e) => {
                    setTwoFactorOTP(e.currentTarget.value.substring(0, 6));
                  }}
                  onKeyUp={(e) => {
                    if (e.keyCode === 13) {
                      e.preventDefault();
                      handleEnable2FA();
                    }
                  }}
                  style={{ width: '150px' }}
                  value={twoFactorOTP}
                />
                <TextButton
                  action={handleEnable2FA}
                  className="btn-default mt-2"
                  disabled={!twoFactorOTP || twoFactorOTP.length !== 6}
                  label={languagePack.enableBtnLabel}
                />
              </div>
            </>
          )}
          {disableChallenge && (
            <div>
              <p className="mt-2 mb-2">{languagePack.disablePromptText}</p>
              <input
                autoComplete="off"
                className="form-control"
                id="code"
                max={6}
                onChange={(e) => {
                  setTwoFactorOTP(e.currentTarget.value.substring(0, 6));
                }}
                onKeyUp={(e) => {
                  if (e.keyCode === 13) {
                    e.preventDefault();
                    handleDisable2FA();
                  }
                }}
                style={{ width: '150px' }}
                value={twoFactorOTP}
              />
              <TextButton
                action={handleDisable2FA}
                className="btn-default mt-2"
                disabled={!twoFactorOTP || twoFactorOTP.length !== 6}
                label={languagePack.disableBtnLabel}
              />
            </div>
          )}
          {twoFactorEnabled && !disableChallenge && <p className="mt-2">{languagePack.twoFactorEnabled}</p>}
        </div>
      </EnhancedCard>
    </PageColumn>
  );
}

PersonalInformation.propTypes = {
  userDetails: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    email: PropTypes.string,
  }),
  applyUserDetails: PropTypes.func,
};

PersonalInformation.defaultProps = {
  userDetails: {
    firstName: '',
    lastName: '',
    email: '',
  },
  applyUserDetails: () => {},
};

function mapStateToProps(state) {
  const {
    userData: { userDetails },
  } = state;
  return { userDetails };
}

function mapDispatchToProps(dispatch) {
  return {
    applyUserDetails: (userDetails) => dispatch(updateUserDetails(userDetails)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(PersonalInformation);
