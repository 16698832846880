import React, { useState } from 'react';
import { ChevronDown, Award, ClipboardList, Clock } from 'lucide-react';
import moment from 'moment';
import { formatTimeInPosition } from './utils'; // Import the time utilities

// Helper function to get border color based on index
const getBorderColor = (index) => {
  // Using more specific border-left-color with RGB values instead of Tailwind classes
  const colors = [
    'tw-border-l-[#5B7BE5]',  // Blue #5B7BE5
    'tw-border-l-[#6D28D9]',  // Purple #6D28D9
    'tw-border-l-[#15803d]',  // Green #15803d
    'tw-border-l-[#D97706]',  // Orange #D97706
    'tw-border-l-[#DC2626]',  // Red #DC2626
  ];

  return index < colors.length ? colors[index] : 'tw-border-l-[#4B5563]'; // Default to gray
};

const CareerExperience = ({ applicant }) => {
  const [expandedSections, setExpandedSections] = useState({});
  const [expandedJobs, setExpandedJobs] = useState({ 0: true });
  const [showAllJobs, setShowAllJobs] = useState(false);

  // Get employment data from the correct location
  const employmentData = applicant.employment ||
    applicant.applicantProfile?.employment ||
    [];

  // Toggle expanded state for a specific section
  const toggleSectionExpand = (jobIndex, sectionType) => {
    const key = `${jobIndex}-${sectionType}`;
    setExpandedSections(prev => ({
      ...prev,
      [key]: !prev[key]
    }));
  };

  // Check if a section is expanded
  const isSectionExpanded = (jobIndex, sectionType) => {
    const key = `${jobIndex}-${sectionType}`;
    return expandedSections[key] || false;
  };

  // Toggle collapse/expand state for a job
  const toggleJobExpand = (jobIndex) => {
    setExpandedJobs(prev => ({
      ...prev,
      [jobIndex]: !prev[jobIndex]
    }));
  };

  // Check if a job is expanded
  const isJobExpanded = (jobIndex) => {
    return expandedJobs[jobIndex] || false;
  };

  // Get visible jobs based on current state
  const getVisibleJobs = () => {
    if (showAllJobs) {
      return employmentData;
    }
    return employmentData.slice(0, 3);
  };

  if (!employmentData || employmentData.length === 0) {
    return null;
  }

  return (
    <div className="tw-flex tw-flex-col tw-gap-4">
      {getVisibleJobs().map((job, jobIndex) => (
        <div
          key={jobIndex}
          className={`tw-bg-white tw-rounded-lg tw-shadow-sm tw-border tw-border-gray-100
                      tw-overflow-hidden tw-transition-all tw-duration-200 tw-ease-in-out
                      tw-animate-[slideIn_0.3s_ease] tw-border-l-4 ${getBorderColor(jobIndex)}
                      hover:tw-shadow-md`}
          style={{borderLeftWidth: '4px'}} // Ensuring the left border is visible
        >
          <div
            className="tw-px-4 tw-py-3 tw-bg-gradient-to-r tw-from-gray-50 tw-to-blue-50
                      tw-border-b tw-border-blue-100/10 tw-flex tw-justify-between tw-items-start
                      tw-cursor-pointer"
            onClick={() => toggleJobExpand(jobIndex)}
          >
            <div className="tw-flex-1">
              <h4 className="tw-text-base tw-font-semibold tw-text-gray-900 tw-m-0 tw-mb-0.5 tw-flex tw-items-center tw-gap-2">
                {job.positionTitle}
                {job.current && (
                  <span className="tw-inline-block tw-bg-green-100 tw-text-green-700 tw-text-xs tw-px-2 tw-py-0.5
                                  tw-rounded-full tw-font-medium tw-shadow-sm tw-animate-pulse">
                    Current
                  </span>
                )}
              </h4>
              <div className="tw-text-sm tw-text-gray-500 tw-font-medium">{job.organisation?.name}</div>
              <div className="tw-text-xs tw-text-gray-400 tw-font-light">
                {job.startDate ? moment(job.startDate).format('MM-YYYY') : ''}
                {job.startDate && job.endDate ? ' - ' : ''}
                {job.endDate ? moment(job.endDate).format('MM-YYYY') : ''}
              </div>
            </div>
            <div className="tw-flex tw-items-center tw-gap-2">
              {formatTimeInPosition(job.timeInPosition, job.temporalUnit, { singular: true }) && (
                <span className="tw-bg-purple-50 tw-text-purple-700 tw-px-2 tw-py-0.5 tw-rounded-full
                                tw-text-xs tw-font-medium tw-whitespace-nowrap tw-shadow-sm">
                  <Clock size={16} className="tw-text-gray-500 tw-mr-1 tw-inline" />
                  {formatTimeInPosition(job.timeInPosition, job.temporalUnit, { singular: true })}
                </span>
              )}
              <ChevronDown
                size={18}
                className={`tw-text-gray-500 tw-transition-transform tw-duration-300 ${isJobExpanded(jobIndex) ? 'tw-rotate-180' : ''}`}
              />
            </div>
          </div>

          <div className={`tw-overflow-hidden tw-transition-all tw-duration-300 tw-ease-in-out ${isJobExpanded(jobIndex) ? 'tw-max-h-[2000px]' : 'tw-max-h-0'}`}>
            <div className="tw-p-4">
              {job.achievements && job.achievements.length > 0 && (
                <div className="tw-mb-4 tw-bg-blue-500/5 tw-p-3 tw-rounded-lg
                              tw-shadow-[inset_0_0_0_1px_rgba(91,123,229,0.1)]
                              tw-transition-all tw-duration-200 hover:tw-bg-blue-500/8">
                  <h5 className="tw-text-sm tw-font-semibold tw-text-gray-600 tw-m-0 tw-mb-2
                                tw-pb-1 tw-border-b tw-border-dashed tw-border-gray-200
                                tw-flex tw-items-center tw-gap-1.5">
                    <Award size={14} className="tw-text-blue-500" />
                    Key Achievements
                  </h5>
                  <ul className="tw-m-0 tw-pl-6">
                    {(isSectionExpanded(jobIndex, 'achievements')
                      ? job.achievements
                      : job.achievements.slice(0, 5)).map((achievement, i) => (
                      <li
                        key={i}
                        className="tw-text-sm tw-text-gray-600 tw-mb-1.5 tw-leading-relaxed tw-relative
                                  before:tw-content-[''] before:tw-absolute before:tw-left-[-14px]
                                  before:tw-top-[8px] before:tw-w-1.5 before:tw-h-1.5
                                  before:tw-rounded-full before:tw-bg-blue-500"
                      >
                        {achievement}
                      </li>
                    ))}
                  </ul>
                  {job.achievements.length > 5 && (
                    <button
                      className="tw-inline-flex tw-items-center tw-gap-1 tw-text-gray-500 tw-text-sm
                                tw-bg-transparent tw-border-none tw-p-1 tw-px-2 tw-cursor-pointer
                                tw-transition-all tw-duration-200 tw-mt-1 tw-rounded hover:tw-bg-blue-500/10
                                hover:tw-text-blue-500"
                      onClick={(e) => {
                        e.stopPropagation();
                        toggleSectionExpand(jobIndex, 'achievements');
                      }}
                    >
                      {isSectionExpanded(jobIndex, 'achievements')
                        ? 'Show Less'
                        : `Show ${job.achievements.length - 5} More`}
                      <ChevronDown
                        className={`tw-transition-transform tw-duration-200 ${isSectionExpanded(jobIndex, 'achievements') ? 'tw-rotate-180' : ''}`}
                        size={16}
                      />
                    </button>
                  )}
                </div>
              )}

              {job.responsibilities && job.responsibilities.length > 0 && (
                <div className="tw-bg-purple-700/5 tw-p-3 tw-rounded-lg
                              tw-shadow-[inset_0_0_0_1px_rgba(109,40,217,0.1)]
                              tw-transition-all tw-duration-200 hover:tw-bg-purple-700/8">
                  <h5 className="tw-text-sm tw-font-semibold tw-text-gray-600 tw-m-0 tw-mb-2
                                tw-pb-1 tw-border-b tw-border-dashed tw-border-gray-200
                                tw-flex tw-items-center tw-gap-1.5">
                    <ClipboardList size={14} className="tw-text-purple-700" />
                    Responsibilities
                  </h5>
                  <ul className="tw-m-0 tw-pl-6">
                    {(isSectionExpanded(jobIndex, 'responsibilities')
                      ? job.responsibilities
                      : job.responsibilities.slice(0, 5)).map((responsibility, i) => (
                      <li
                        key={i}
                        className="tw-text-sm tw-text-gray-600 tw-mb-1.5 tw-leading-relaxed tw-relative
                                  before:tw-content-[''] before:tw-absolute before:tw-left-[-14px]
                                  before:tw-top-[8px] before:tw-w-1.5 before:tw-h-1.5
                                  before:tw-rounded-full before:tw-bg-purple-700"
                      >
                        {responsibility}
                      </li>
                    ))}
                  </ul>
                  {job.responsibilities.length > 5 && (
                    <button
                      className="tw-inline-flex tw-items-center tw-gap-1 tw-text-gray-500 tw-text-sm
                                tw-bg-transparent tw-border-none tw-p-1 tw-px-2 tw-cursor-pointer
                                tw-transition-all tw-duration-200 tw-mt-1 tw-rounded hover:tw-bg-purple-700/10
                                hover:tw-text-purple-700"
                      onClick={(e) => {
                        e.stopPropagation();
                        toggleSectionExpand(jobIndex, 'responsibilities');
                      }}
                    >
                      {isSectionExpanded(jobIndex, 'responsibilities')
                        ? 'Show Less'
                        : `Show ${job.responsibilities.length - 5} More`}
                      <ChevronDown
                        className={`tw-transition-transform tw-duration-200 ${isSectionExpanded(jobIndex, 'responsibilities') ? 'tw-rotate-180' : ''}`}
                        size={16}
                      />
                    </button>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      ))}

      {/* Show more jobs button - only displayed when there are more than 3 jobs */}
      {employmentData.length > 3 && (
        <div className="tw-text-center tw-mt-2">
          <button
            onClick={() => setShowAllJobs(!showAllJobs)}
            className="tw-inline-flex tw-items-center tw-gap-2 tw-bg-gray-50 tw-text-gray-700
                      tw-border tw-border-gray-200 tw-rounded-full tw-px-4 tw-py-2
                      tw-text-sm tw-font-medium tw-transition-all tw-duration-200
                      hover:tw-bg-gray-100 hover:tw-shadow-sm"
          >
            {showAllJobs ? (
              <>
                Show Fewer Jobs
                <ChevronDown className="tw-rotate-180" size={16} />
              </>
            ) : (
              <>
                Show {employmentData.length - 3} More Jobs
                <ChevronDown size={16} />
              </>
            )}
          </button>
        </div>
      )}
    </div>
  );
};

export default CareerExperience;
