import { retryableAPICall } from '@API/common-api-utils';
import { getPersonalityTestData } from '@API/PersonalityTestAPI/PersonalityTestAPI';

export async function getPTData(candidateId, onSuccess = () => {}, onError = () => {}) {
  const resp = await retryableAPICall(() => getPersonalityTestData(candidateId));

  if (typeof resp === 'string') {
    onError();
  } else {
    onSuccess(resp);
  }
}
