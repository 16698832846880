import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import Iframe from 'react-iframe';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import { getFlexiForm } from '@API/FormsAPI/ATSFormsAPI';
import { getAllFormStyles } from '@API/FormsAPI/ATSFormsStylesAPI';
import { retryableAPICall } from '@API/common-api-utils';
import { ErrorMessage } from '@Base/ErrorScreen';
import config from '../../../../../../config/config';
import { LoadingScreen } from '@Base/Loading';

const {
  forms: { genericStylingForm },
} = config;

const IFRAME_TIMEOUT = 5000;

export default function PreviewFormModal({ isOpen, onClose, id, formConfig }) {
  const [isLoaded, setIsLoaded] = useState(false);
  const [iFrameState, setIFrameState] = useState('UNKNOWN');
  const [iFrameHeight, setIFrameHeight] = useState(700);
  const iframe = document.getElementById('form');

  useEffect(() => {
    // Only our forms will send this message so we
    // can safely assume that the iframe has loaded
    function successfullyLoadIframe(e) {
      if (e.data === 'successfully_loaded') {
        setIFrameState('SUCCESS');
      }
    }

    if (isOpen) {
      window.addEventListener('message', successfullyLoadIframe);
    } else {
      window.removeEventListener('message', successfullyLoadIframe);
    }

    return () => {
      window.removeEventListener('message', successfullyLoadIframe);
    };
  }, [isOpen]);

  // If we don't get a message from the iframe it means either the url is wrong
  // or there are error in the ats-forms code
  useEffect(() => {
    let timeOutId = null;
    if (iframe) {
      timeOutId = setTimeout(() => {
        setIFrameState(iFrameState === 'UNKNOWN' ? 'FAILED' : 'SUCCESS');
      }, IFRAME_TIMEOUT);
    }
    return () => {
      if (timeOutId) clearTimeout(timeOutId);
    };
  }, [iframe]);

  useEffect(() => {
    let timeOutId = null;

    const loadData = async () => {
      let schema = formConfig || {};
      let theme = {};

      if (id) {
        const resp = await retryableAPICall(() => getFlexiForm(id));

        if (resp) {
          schema = resp;
        }
      }
      if (schema.themeId) {
        const respStyle = await retryableAPICall(() => getAllFormStyles());

        const foundTheme = respStyle.find((a) => a.id === schema.themeId);

        if (foundTheme) {
          theme = foundTheme;
        }
      }

      if (iframe && iframe.contentWindow) {
        timeOutId = setTimeout(
          () =>
            iframe.contentWindow.postMessage(
              {
                backgroundColour: theme.backgroundColour,
                backgroundImage: theme.backgroundImage,
                fonts: theme.customFonts,
                formConfig: {
                  formId: null,

                  formSchema: {
                    formSchema: {
                      $schema: 'http://json-schema.org/draft-07/schema#',
                      definitions: { previewForm: schema.formSchema },
                      properties: {
                        previewForm: {
                          $ref: '#/definitions/previewForm',
                        },
                      },
                      type: 'object',
                    },
                    rules: [],
                    uiSchema: { previewForm: schema.uiSchema },
                  },
                  formStyle: {},
                  formTitle: null,
                  queryParams: { devmode: true },
                  successPageId: null,
                },
                logo: theme.logo,
                muiTheme: theme.muiTheme,
                type: schema.type,
              },
              '*',
            ),
          100,
        );
      }
    };

    if (iFrameState === 'SUCCESS' && iframe && isOpen) {
      loadData();
    }
    return () => {
      if (timeOutId) clearTimeout(timeOutId);
    };
  }, [id, formConfig, iFrameState, iframe, isOpen]);

  useEffect(() => {
    const handleIframe = (e) => {
      const { data } = e;

      if (typeof data === 'number' && data > 0) {
        setIFrameHeight(data);
        setTimeout(() => {
          setIsLoaded(true);
        }, 300);
      } else if (data === 'doClose') {
        setIsLoaded(false);
        onClose();
      }
    };

    if (isOpen) {
      window.addEventListener('message', handleIframe);
    } else {
      window.removeEventListener('message', handleIframe);
    }

    return () => {
      setIFrameHeight(700);
      setIsLoaded(false);
      window.removeEventListener('message', handleIframe);
    };
  }, [isOpen]);

  return (
    <Modal
      centered
      className="forms-modal-iframe"
      isOpen={isOpen}
      size="lg"
      toggle={() => {
        setIFrameState('UNKNOWN');
        setIsLoaded(false);
        setIFrameHeight(700);
        onClose();
      }}
    >
      <ModalHeader
        toggle={() => {
          setIFrameState('UNKNOWN');
          setIsLoaded(false);
          setIFrameHeight(700);
          onClose();
        }}
      >{`Previewing ${formConfig?.name} Form`}</ModalHeader>
      <ModalBody>
        {!isLoaded && iFrameState !== 'FAILED' && <LoadingScreen isModal />}
        {iFrameState === 'FAILED' && (
          <div className="h-100 w-100 d-flex position-absolute m-auto bg-white py-5">
            <ErrorMessage error={500} />
          </div>
        )}
        <Iframe
          allow="camera;microphone"
          frameBorder="0"
          height={`${iFrameHeight}px`}
          id="form"
          onLoad={() => {
            setIsLoaded(false);
          }}
          scrolling="no"
          seamless="seamless"
          src={genericStylingForm}
          width="100%"
        />
      </ModalBody>
    </Modal>
  );
}

PreviewFormModal.propTypes = {
  formConfig: PropTypes.shape(),
  id: PropTypes.string,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
};

PreviewFormModal.defaultProps = {
  formConfig: null,
  id: null,
  isOpen: false,
  onClose: () => {},
};
