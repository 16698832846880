// eslint-disable-next-line import/prefer-default-export
export const words = {
  en: {
    ADDITIONAL_INFO_REQUESTED: 'Referees Requested',
    NOT_REQUESTED: 'Not Requested',
    PART_RECEIVED: 'Part Received',
    AWAITING_REPORTS: 'Awaiting Reports',
    RECEIVED: 'Received',
    WITHDRAWN: 'Withdrawn',
    FAILED: 'Failed',
    REFEREES_REQUESTED: 'Referees Requested',
    REMINDERS_SENT: 'Reminders Sent',
    REMINDER_1_SENT: 'Reminder 1 Sent',
    REMINDER_2_SENT: 'Reminder 2 Sent',
    REQUESTED: 'Requested',
    SMS: 'SMS',
    SUBMITTED: 'Submitted',
    applicationDetails: 'Application Details',
    personalityTest: 'Personality Test',
    attachments: 'Attachments',
    buttonSaving: 'Saving...',
    chat: 'Chat',
    copiedToClipboard: 'copied to clipboard',
    copy: 'Copy',
    copyToClipboardError: 'Copying to clipboard failed',
    createNote: 'Create Note',
    editNotesError: 'Could not edit notes at this time',
    edocs: 'eDocs',
    email: 'Email',
    funnelStage: 'Funnel Stage',
    genericforms: 'Flexi-Forms',
    interview: 'Interview',
    interviews: 'Interviews',
    noInterviewFormsAffix: 'to create one.',
    noInterviewFormsPrefix: 'You currently have no interview forms, go to',
    noInterviewFormsSettings: 'settings',
    noteError: 'There was an error saving the note',
    noteSuccess: 'Note saved successfully',
    notes: 'Notes',
    onboarding: 'Onboarding',
    phone: 'Phone',
    postcode: 'Postcode',
    references: 'References',
    rtwCheck: 'Right To Work',
    dbsCheck: 'DBS Screening',
    lms: 'Learning Modules',
    startButtonLabel: 'Start Notes',
    timeline: 'Timeline',
    under18: 'Applicant under 18',
    updateStatusFailureMessage: 'Could not update invite status at this time',
    updateStatusSuccessMessage: 'Successfully updated invite status to',
  },
  es: {
    SMS: 'SMS',
    applicationDetails: 'Detalles de la aplicación',
    attachments: 'Archivos adjuntos',
    email: 'Correo',
    funnelStage: 'Etapa de embudo',
    interview: 'Entrevista',
    notes: 'Notas',
    phone: 'Teléfono',
    postcode: 'Código postal',
    timeline: 'Cronograma',
  },
  enEdulinks: {},
};

export default words;
