import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { Card, CardBody, Col, Row } from 'reactstrap';
import { retryableAPICall } from '@API/common-api-utils';
import {
  getReferences,
  requestRefereeDetails,
  sendReferenceReminder,
  sendReferenceRequests,
} from '@API/CandidateAPI/ReferenceAPI';
import { requestStatuses } from '@JS/constants/requestStatuses';
import { LoadingScreen } from '@Base/Loading';
import { useLanguagePack, useMounted } from '@Base/hooks';
import ReferencesHeader from './ReferencesHeader';
import Referees from './Referees';
import ActionToBePerformedNoDataState from '../Other/ActionToBePerformedNoDataState';
import waiting from '../../../assets/img/nodata/waiting.png';
import moment from 'moment/moment';
import { ActionButton } from '@Base/Buttons';
import config from '../../../config/config';
import { fetchFormId } from './utils';
import { FormModal } from '@Base/Modal';
import referenceNotRequestedImg from '../../../assets/img/nodata/reference_not_requested_img_2.png';

const {
  web: { formsWebUrl },
} = config;

function ReferenceDetailsContainer({ applicant: candidate, onApplicantUpdate, isTouch, userDetails }) {
  const isMounted = useMounted();
  const languagePack = useLanguagePack('references-container');
  const [referenceRequest, setReferenceRequest] = useState(null);
  const [status, setStatus] = useState(null);
  const [loading, setLoading] = useState(true);
  const [modalConfig, setModalConfig] = useState({ isOpen: false, url: '' });
  const [refereeActionOpen, setRefereeActionOpen] = useState(false);
  const [formId, setFormId] = useState();
  const refereeFormUrl = `${formsWebUrl}?id=${formId}&cid=${candidate.id}&userid=${userDetails.id}&username=${userDetails.firstName}%20${userDetails.lastName}`;
  const [reqBtnLoading, setReqBtnLoading] = useState(false);
  const [requestedRefereeDate, setRequestedRefereeDate] = useState(null);

  const menuItems = [
    {
      action: async () => {
        setRefereeActionOpen(false);
        setReqBtnLoading(true);
        await requestReferees();
        setReqBtnLoading(false);
      },
      icon: 'Pencil',
      id: 1,
      isDisabled: false,
      label: reqBtnLoading ? languagePack.requestRefereeInformationBtnLoad : languagePack.requestRefereeInformationBtn,
      permissions: ['candidate:references:create'],
    },

    {
      action: async () => {
        setRefereeActionOpen(false);
        setModalConfig({
          isOpen: true,
          // eslint-disable-next-line max-len
          url: `${formsWebUrl}?id=${formId}&cid=${candidate.id}&userid=${userDetails.id}&username=${userDetails.firstName}%20${userDetails.lastName}`,
        });
      },
      icon: 'File',
      id: 2,
      label: languagePack.refereeSelfFillButton,
      permissions: ['candidate:references:create'],
    },
    {
      action: async () => {
        navigator.clipboard.writeText(refereeFormUrl).then(
          () => {
            toast.success(`${languagePack.refereeCopyFormButtonSuccessTxt} ${languagePack.copiedToClipboard}`);
          },
          () => {
            toast.error(languagePack.copyToClipboardError);
          },
        );
        setRefereeActionOpen(false);
      },
      icon: 'File',
      id: 3,
      label: languagePack.refereeCopyFormButtonTxt,
      permissions: ['candidate:references:create'],
    },
  ];

  useEffect(() => {
    if (isMounted() && ['REFEREES_REQUESTED', 'NO_REFEREES'].includes(status)) {
      fetchFormId((id) => setFormId(id));
    }
  }, [isMounted, status]);

  const getData = useCallback(async () => {
    const { id, requestedRefereeDateTime } = candidate;

    const result = await retryableAPICall(() => getReferences(id));

    if (isMounted()) {
      if (typeof result !== 'string') {
        setReferenceRequest(result);
        setStatus(result.referenceRequestStatus);
        const refereeDate = result.statusHistory?.find((status) => status.status === 'REQUESTED')?.statusDateTime;
        setRequestedRefereeDate(refereeDate);
      } else if (result === requestStatuses.NOT_FOUND_ERROR && requestedRefereeDateTime) {
        setStatus('REFEREES_REQUESTED');
        const refereeDate = result.statusHistory?.find((status) => status.status === 'REQUESTED')?.statusDateTime;
        setRequestedRefereeDate(refereeDate);
      } else if (result === '') {
        setStatus('NO_REFEREES');
      } else if (result === requestStatuses.NOT_FOUND_ERROR) {
        setStatus('NO_REFEREES');
      } else {
        toast.error(
          languagePack.errorRequestingReferenceInfo || 'Could not get the reference information please try again later',
        );
      }

      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [candidate, isMounted]);

  useEffect(() => {
    if (candidate.requestedRefereeDateTime) setStatus('REFEREES_REQUESTED');
    if (candidate) getData();
  }, [candidate, getData]);

  async function requestReferees() {
    const result = await retryableAPICall(() => requestRefereeDetails(candidate.id));

    if (typeof result !== 'string' || result === '') {
      onApplicantUpdate({
        ...candidate,
        requestedRefereeDateTime: new Date(),
      });
      setStatus('REFEREES_REQUESTED');
      toast.success(languagePack.requestRefereeSuccess);
    } else {
      toast.error(languagePack.errorRequestingReferees);
    }
  }

  async function requestReferences(emails) {
    const result = await retryableAPICall(() => sendReferenceRequests(referenceRequest.id, emails));

    if (typeof result !== 'string' || result === '') {
      setReferenceRequest(result);
      setStatus(result.referenceRequestStatus);
      onApplicantUpdate();
      toast.success(languagePack.requestReferenceSuccess);
    } else {
      toast.error(languagePack.errorRequestingReferences);
    }
  }

  async function doReminder(refereeId) {
    const result = await retryableAPICall(() => sendReferenceReminder(candidate.id, refereeId));

    if (typeof result !== 'string' || result === '') {
      const { refereeId: refId, status: resStatus } = result;

      const referenceReq = {
        ...referenceRequest,
        referees: referenceRequest.referees.map((ref) => {
          if (ref.id === refId) return { ...ref, refereeRequestStatus: resStatus };
          return ref;
        }),
      };

      setReferenceRequest({ ...referenceReq });
      setStatus(resStatus);
      onApplicantUpdate();
      toast.success(languagePack.reminderSentSuccessfully);
    } else {
      toast.error(languagePack.errorRequestingReferences);
    }
  }

  return (
    <>
      <Card>
        <CardBody>
          {loading ? (
            <LoadingScreen notFixed />
          ) : (
            <>
              {status !== 'NOT_REQUESTED' && status !== 'REFEREES_REQUESTED' && status !== 'NO_REFEREES' && (
                <>
                  <ReferencesHeader
                    candidate={candidate}
                    completedFormId={referenceRequest?.completedFormId}
                    doRefresh={getData}
                    doRequest={requestReferences}
                    isTouch={isTouch}
                    languagePack={languagePack}
                    referees={referenceRequest?.referees}
                    referenceRequestId={referenceRequest?.id}
                    requestRef={requestReferees}
                    status={status}
                    userDetails={userDetails}
                    requestedRefereeDate={requestedRefereeDate}
                  />
                </>
              )}
              {status === 'NOT_REQUESTED' ||
                (status === 'REFEREES_REQUESTED' && (
                  <Row className="mb-1">
                    <Col>
                      <ActionToBePerformedNoDataState
                        image={waiting}
                        title={languagePack.waitingOnRefereeDetailsHeader}
                        subTitle={`${languagePack.refereeInformationRequested || 'Requested'} ${languagePack.on}
      ${moment.utc(candidate.requestedRefereeDateTime).local().format('DD-MM-YYYY HH:mm')}`}
                        description="Currently waiting for the candidate to return their nominated referees.
                      If you have received the referees already you can enter them yourself."
                        button={
                          <ActionButton
                            buttonLabel={languagePack.actionBtnLabel || 'Actions'}
                            className="ellipsis-opts-btn"
                            iconName="Ellipsis"
                            iconOnly={false}
                            isOpen={refereeActionOpen}
                            menuItems={menuItems.filter((itm) => itm.id !== 1)}
                            onToggle={(isOpen) => {
                              setRefereeActionOpen(isOpen);
                            }}
                            title="Actions"
                            tooltipContent={languagePack.tooltipContent || 'Perform actions'}
                          />
                        }
                      />
                    </Col>
                  </Row>
                ))}
              {status === 'NO_REFEREES' && (
                <Row className="mb-4">
                  <Col>
                    <ActionToBePerformedNoDataState
                      image={referenceNotRequestedImg}
                      title={languagePack.refereesNotYetRequested}
                      description={languagePack.refereesNotYetRequestedDescription}
                      button={
                        <ActionButton
                          buttonLabel={languagePack.actionBtnLabel || 'Actions'}
                          className="ellipsis-opts-btn"
                          iconName="Ellipsis"
                          iconOnly={false}
                          isOpen={refereeActionOpen}
                          menuItems={menuItems}
                          onToggle={(isOpen) => {
                            setRefereeActionOpen(isOpen);
                          }}
                          title="Actions"
                          tooltipContent={languagePack.tooltipContent || 'Perform actions'}
                        />
                      }
                    />
                  </Col>
                </Row>
              )}
              {referenceRequest && referenceRequest.referees.length > 0 && (
                <Referees
                  candidate={candidate}
                  doRefresh={getData}
                  isTouch={isTouch}
                  languagePack={languagePack}
                  onReminder={async (refId, callback) => {
                    await doReminder(refId);
                    if (callback) callback();
                  }}
                  referees={referenceRequest.referees}
                  requestReferences={requestReferences}
                  status={status}
                  userDetails={userDetails}
                />
              )}
            </>
          )}
        </CardBody>
      </Card>
      <FormModal
        contentUrl={modalConfig.url}
        id="referee-header"
        isOpen={modalConfig.isOpen}
        toggle={(closeMethod, isOpen, frameId) => {
          if (closeMethod === 'IFRAME' && isOpen && frameId === 'referee-header') {
            getData();
          }
          setModalConfig({ isOpen: false, url: '' });
        }}
        warnOnClickClose
      />
    </>
  );
}

ReferenceDetailsContainer.propTypes = {
  applicant: PropTypes.shape(),
  isTouch: PropTypes.bool,
  onApplicantUpdate: PropTypes.func,
  userDetails: PropTypes.shape(),
};

ReferenceDetailsContainer.defaultProps = {
  applicant: null,
  isTouch: false,
  onApplicantUpdate: () => {},
  userDetails: {},
};

function mapStateToProps(state) {
  const {
    userData: { userDetails },
  } = state;
  return { userDetails };
}

export default connect(mapStateToProps)(ReferenceDetailsContainer);
