import React, { Fragment, useEffect, useState } from 'react';
import Select from 'react-select';
import { toast } from 'react-toastify';
import { ReactMultiEmail, isEmail } from 'react-multi-email';
import { Modal, ModalHeader, ModalBody, ModalFooter, Input, FormGroup, Label, FormText } from 'reactstrap';
import { Confirmation } from '@Base/Modal';
import { retryableAPICall } from '@API/common-api-utils';
import { getUserListForAccount } from '@API/AccountAPI';
import { CreateButton, CancelButton } from '@Base/Buttons';
import { AccountFormSelect } from '@Base/Account';
import UserSelector from '@Base/Users/UserSelector';
import { TagPill } from '@Base/Forms/Custom/EmailPills';

const getRandomColor = () => {
  const letters = '0123456789ABCDEF';
  let color = '#';
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
};

const exitConditionsOptions = [
  { label: 'Rejected', value: 'REJECTED' },
  { label: 'Hired', value: 'HIRED' },
];

const failureRecipientOptions = [
  { label: 'Email Address', value: 'DIRECT' },
  { label: 'ATS User', value: 'ATS_USER' },
  { label: 'Vacancy Assignee', value: 'VACANCY_ASSIGNEE' },
];

function SettingsModal({ isOpen, toggle, onSave, initialValues }) {
  const [showConfirm, setShowConfirm] = useState(false);
  const [values, setValues] = useState(initialValues);
  const [tempValues, setTempValues] = useState(initialValues);
  const [users, setUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const resp = await retryableAPICall(() => getUserListForAccount());
        const formattedUsers = resp.map((user) => ({
          value: user.id,
          label: user.name,
          email: user.email,
          color: getRandomColor(),
          initials: user.name
            .split(' ')
            .map((n) => n[0])
            .join('')
            .toUpperCase(),
        }));
        setUsers(formattedUsers);
      } catch (error) {
        console.error('Error fetching users:', error);
        toast.error('Failed to fetch users. Please try again.');
      } finally {
        setIsLoading(false);
      }
    };
    fetchUsers();
  }, [isLoading]);

  const handleChange = (name, value) => {
    setTempValues({
      ...tempValues,
      [name]: value,
    });
  };

  const handleSave = (e) => {
    e.preventDefault();
    onSave(tempValues);
    setValues(tempValues); // Update the saved values
    toggle();
  };

  const handleCancel = () => {
    setTempValues(values);
    toggle();
  };

  const handleClose = () => {
    if (JSON.stringify(tempValues) !== JSON.stringify(values)) {
      setShowConfirm(true);
    } else {
      toggle();
    }
  };

  useEffect(() => {
    setTempValues(initialValues);
  }, [initialValues]);

  const confirmClose = () => {
    setShowConfirm(false);
    handleCancel();
  };

  return (
    <Fragment>
      <Modal centered isOpen={isOpen} toggle={handleClose} backdrop="static">
        <ModalHeader toggle={handleClose}>Settings</ModalHeader>
        <ModalBody
          style={{
            overflowY: 'visible',
          }}
        >
          <FormGroup>
            <Label>Name</Label>
            <Input
              id="name"
              onChange={(e) => {
                handleChange('name', e.target.value);
              }}
              type="text"
              value={tempValues.name || ''}
            />
          </FormGroup>
          <AccountFormSelect
            className="account-opts"
            classNamePrefix="react-select"
            currentAccounts={(tempValues.sharedWith || []).map(({ accountId, accountName, readOnly }) => ({
              id: accountId,
              name: accountName,
              readOnly: readOnly,
            }))}
            id="sharedWith"
            isMulti
            label="Shared With"
            onChange={(accountObjs = []) => {
              const accounts = accountObjs.map(({ value, label, readOnly }) => ({
                accountId: value,
                accountName: label,
                readOnly,
              }));
              handleChange('sharedWith', accounts);
            }}
          />
          <FormGroup>
            <Label>Exit Conditions</Label>
            <Select
              classNamePrefix="react-select"
              isMulti
              onChange={(val) => {
                handleChange(
                  'exitConditions',
                  val.map(({ value }) => value),
                );
              }}
              options={exitConditionsOptions}
              placeholder="Choose Category"
              value={tempValues.exitConditions?.map((value) =>
                exitConditionsOptions.find((option) => option.value === value),
              )}
            />
            <FormText>
              If a candidate is in the workflow and meets the exit conditions, they will be removed from the workflow.
            </FormText>
          </FormGroup>
          <FormGroup>
            <Label>Failure Recipients</Label>
            <Select
              classNamePrefix="react-select"
              onChange={(val) => {
                handleChange('failureRecipients', {
                  type: val.value,
                });
              }}
              options={failureRecipientOptions}
              placeholder="Choose Recipent Type"
              value={
                tempValues?.failureRecipients?.type
                  ? failureRecipientOptions.find((option) => option.value === tempValues.failureRecipients.type)
                  : null
              }
            />
            {tempValues?.failureRecipients?.type === 'DIRECT' && (
              <div className="mt-3">
                <Label>Email Addresses</Label>
                <ReactMultiEmail
                  emails={tempValues?.failureRecipients?.recipients}
                  getLabel={(email, index, removeEmail) => (
                    <TagPill key={index} index={index} removeTag={removeEmail}>
                      {email}
                    </TagPill>
                  )}
                  onChange={(emails) => {
                    handleChange('failureRecipients', {
                      type: tempValues?.failureRecipients.type,
                      recipients: emails,
                    });
                  }}
                  validateEmail={(email) => isEmail(email)}
                />
              </div>
            )}
            {tempValues?.failureRecipients?.type === 'ATS_USER' && (
              <div className="mt-3">
                <Label>Users</Label>
                <UserSelector
                  users={users}
                  onChange={(selected) => {
                    handleChange('failureRecipients', {
                      type: tempValues?.failureRecipients.type,
                      recipients: selected.map((user) => user.value),
                    });
                  }}
                  defaultValue={users.reduce((acc, curr) => {
                    if (tempValues?.failureRecipients?.recipients?.includes(curr.value)) {
                      acc.push(curr);
                    }
                    return acc;
                  }, [])}
                />
              </div>
            )}
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <CancelButton action={handleClose} />
          <CreateButton label="Confirm" action={handleSave} />
        </ModalFooter>
      </Modal>
      <Confirmation
        content="Your data will be lost if you close the modal"
        show={showConfirm}
        cancelCallback={() => setShowConfirm(false)}
        confirmCallback={confirmClose}
      />
    </Fragment>
  );
}

export default SettingsModal;
