import React, { useState } from 'react';
import { Menu, PanelRightOpen } from 'lucide-react';
import { checkPermissions } from '@JS/auth/AuthUtils';
import { sectionLookup } from './dashboardConstants';
import DashboardMenu from './DashboardMenu';

const DashboardSideMenu = ({ title, activeTab, openSections, handleClick, handleSectionToggle, children }) => {
  const [isOpen, setIsOpen] = useState(true);

  const handleMenuItemClick = (itemUri, sectionUri) => {
    if (!isOpen) {
      setIsOpen(true);
      // If the section isn't already open, open it
      if (sectionUri && !openSections.includes(sectionUri)) {
        handleSectionToggle(sectionUri);
      }
    }
    handleClick(itemUri);
  };

  return (
    <div className="tw-flex tw-h-100" style={{ width: 'calc(100% + 20px)' }}>
      <div
        className={`
          tw-bg-white tw-text-gray-800
          tw-shadow-[0_2px_15px_-3px_rgba(0,0,0,0.1)]
          tw-transition-all tw-duration-300 tw-ease-in-out
          ${isOpen ? 'tw-w-64' : 'tw-w-16'}
          ${isOpen ? 'tw-min-w-[260px]' : 'tw-w-16'}
          tw-min-h-screen tw-p-3
          tw-overflow-hidden
        `}
        style={{ marginLeft: '-20px' }}
      >
        <div className="tw-flex tw-justify-between tw-items-center tw-mb-3">
          {isOpen ? (
            <div className="tw-overflow-hidden tw-text-nowrap tw-ms-3 tw-font-bold tw-text-lg tw-text-[#1a2e56]">
              {title}
            </div>
          ) : null}
          <button
            onClick={() => setIsOpen(!isOpen)}
            className="tw-p-2 tw-rounded-lg tw-hover:bg-gray-700 tw-transition-colors"
          >
            {isOpen ? <PanelRightOpen size={20} /> : <Menu size={20} />}
          </button>
        </div>
        <nav>
          <ul className="tw-pl-0">
            {[sectionLookup['personal-prefs']].map((item, index) => (
              <li key={index}>
                <DashboardMenu
                  item={item}
                  uri={'personal-prefs'}
                  isOpen={isOpen}
                  activeTab={activeTab}
                  handleClick={handleMenuItemClick}
                  handleSectionToggle={handleSectionToggle}
                  openSections={openSections}
                />
              </li>
            ))}
          </ul>
          {checkPermissions([
            'admin:forms:read',
            'admin:emails:read',
            'admin:schedule:read',
            'admin:vacancytemplates:read',
            'admin:rejectionreasons:update',
            'admin:branding',
            'admin:locations:read',
            'candidate:tags',
            'admin:workflow:read',
          ]) && (
            <ul className="tw-pl-0">
              {[sectionLookup['acc-settings']].map((item, index) => (
                <li key={index}>
                  <DashboardMenu
                    item={item}
                    uri={'acc-settings'}
                    isOpen={isOpen}
                    activeTab={activeTab}
                    handleClick={handleMenuItemClick}
                    handleSectionToggle={handleSectionToggle}
                    openSections={openSections}
                  />
                </li>
              ))}
            </ul>
          )}
          {checkPermissions(['admin:usermanagement:read']) && (
            <ul className="tw-pl-0">
              {[sectionLookup['acc-management']].map((item, index) => (
                <li key={index}>
                  <DashboardMenu
                    item={item}
                    uri={'acc-management'}
                    isOpen={isOpen}
                    activeTab={activeTab}
                    handleClick={handleMenuItemClick}
                    handleSectionToggle={handleSectionToggle}
                    openSections={openSections}
                  />
                </li>
              ))}
            </ul>
          )}
          {checkPermissions([
            'admin:accessgrp',
            'fourth:admin',
            's4:admin',
            'admin:rota',
            'polaris:admin',
            'same-system:admin',
            'ubeya:admin',
            'pth:create',
          ]) && (
            <ul className="tw-pl-0">
              {[sectionLookup['integrations']].map((item, index) => (
                <li key={index}>
                  <DashboardMenu
                    item={item}
                    uri={'integrations'}
                    isOpen={isOpen}
                    activeTab={activeTab}
                    handleClick={handleMenuItemClick}
                    handleSectionToggle={handleSectionToggle}
                    openSections={openSections}
                  />
                </li>
              ))}
            </ul>
          )}
        </nav>
      </div>
      <div className="tw-flex-1">{children}</div>
    </div>
  );
};

export default DashboardSideMenu;
