import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { useLanguagePack, useMounted } from '@Base/hooks';

import { retryableAPICall } from '@API/common-api-utils';
import {
  createPublisherBoardAccount,
  deletePublisherBoardAccount,
  getPublisherAccounts,
} from '@API/PublishingAPI/PublishingSettingsAPI';

import { requestStatuses } from '@JS/constants/requestStatuses';

import vpLogo from '../../../../../assets/img/brand/logo/vplogo.gif';
import indeedLogo from '../../../../../assets/img/brand/logo/indeedlogo.gif';
import googleLogo from '../../../../../assets/img/brand/logo/google-logo.png';
import talentFunnelLogo from '../../../../../assets/img/brand/logo/tflogo.png';

import config from '../../../../../config/config';

import IndeedSettings from './IndeedSettings';
import IndeedSourceSettings from './IndeedSourceSettings';
import { PageColumn } from '../../Common';
import VacancyPosterSettings from './VacancyPosterSettings';
import JobBoardEntry from './JobBoardEntry';

const { publishing } = config;

async function getData(onSuccess = () => {}, onError = () => {}) {
  const publisherAccounts = await retryableAPICall(() => getPublisherAccounts());

  const data = {};

  // eslint-disable-next-line no-unused-expressions
  publisherAccounts?.forEach((pa) => {
    data[pa.type] = pa;
  });

  onSuccess(data);
}

function JobBoardSettings({ userDetails, companyLogo }) {
  const isMounted = useMounted();
  const languagePack = useLanguagePack('client-settings');
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (isMounted()) {
      setLoading(true);
      getData(
        (resp) => {
          setData(resp);
          setLoading(false);
        },
        (err) => {
          if (err !== requestStatuses.NOT_FOUND_ERROR) {
            toast.error(languagePack.errorLoadingProfile);
          }
          setLoading(false);
        },
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMounted]);

  return (
    <PageColumn
      state={{
        isPending: loading,
      }}
      permissions={[]}
    >
      <JobBoardEntry
        alreadyPublished={data.GOOGLE_FOR_JOBS_INDEX}
        description={languagePack.googleJobsDescription}
        id={data.GOOGLE_FOR_JOBS_INDEX?.id}
        languagePack={languagePack}
        logo={googleLogo}
        name={languagePack.googleJobsName}
        onChange={() => {
          if (!data.GOOGLE_FOR_JOBS_INDEX) {
            createPublisherBoardAccount({ name: 'Google Jobs', type: 'GOOGLE_FOR_JOBS_INDEX' }).then((result) => {
              data.GOOGLE_FOR_JOBS_INDEX = { id: result.account.id };
            });
          } else {
            deletePublisherBoardAccount(data.GOOGLE_FOR_JOBS_INDEX?.id, 'GOOGLE_FOR_JOBS_INDEX');
          }
        }}
        simpleJobBoard
        type="GOOGLE_FOR_JOBS_INDEX"
      />
      <JobBoardEntry
        alreadyPublished={data.JOBSITE_SEARCH}
        description={`${languagePack.talentFunnelFeedDescription} ${publishing.vacancyFeedUrl}/general/latest/${userDetails.data.tenant}.json`}
        id={data.JOBSITE_SEARCH?.id}
        languagePack={languagePack}
        logo={talentFunnelLogo}
        name={languagePack.talentFunnelFeedName}
        onChange={() => {
          if (!data.JOBSITE_SEARCH) {
            createPublisherBoardAccount({ name: 'Talent Funnel Job Feed', type: 'JOBSITE_SEARCH' }).then((result) => {
              data.JOBSITE_SEARCH = { id: result.account.id };
            });
          } else {
            deletePublisherBoardAccount(data.JOBSITE_SEARCH?.id, 'JOBSITE_SEARCH');
          }
        }}
        simpleJobBoard
        type="JOBSITE_SEARCH"
      />
      <JobBoardEntry
        alreadyPublished={data.INDEED}
        description={languagePack.jobBoardsIndeedDescription}
        id={data.INDEED?.id}
        languagePack={languagePack}
        logo={indeedLogo}
        name={languagePack.jobBoardsIndeedName}
        onChange={() => {
          if (!data.INDEED) {
            createPublisherBoardAccount({ name: 'Indeed', type: 'INDEED' }).then((result) => {
              data.INDEED = { id: result.account.id };
            });
          } else {
            deletePublisherBoardAccount(data.INDEED?.id, 'INDEED');
          }
        }}
        type="INDEED"
      >
        <IndeedSettings initFormData={data.INDEED} languagePack={languagePack} />
        <IndeedSourceSettings languagePack={languagePack} />
      </JobBoardEntry>
      <JobBoardEntry
        alreadyPublished={data.VACANCY_POSTER}
        description={languagePack.jobBoardsVacancyPosterDescription}
        id={data.VACANCY_POSTER?.id}
        languagePack={languagePack}
        logo={vpLogo}
        name={languagePack.jobBoardsVacancyPosterName}
        onChange={() => {
          if (!data.VACANCY_POSTER) {
            createPublisherBoardAccount({ name: 'Vacancy Poster', type: 'VACANCY_POSTER' }).then((result) => {
              data.VACANCY_POSTER = { id: result.account.id };
            });
          } else {
            deletePublisherBoardAccount(data.VACANCY_POSTER?.id, 'VACANCY_POSTER');
          }
        }}
        type="VACANCY_POSTER"
      >
        <VacancyPosterSettings initFormData={data.VACANCY_POSTER} />
      </JobBoardEntry>
      <JobBoardEntry
        alreadyPublished={userDetails?.tenant}
        canToggle={false}
        description={
          <>
            {languagePack.careersSiteFeedDescription}:&nbsp;
            <a href={`${publishing.vacancyFeedUrl}/general/latest/all_vacancies_${userDetails?.data?.tenant}.json`}>
              {`${publishing.vacancyFeedUrl}/general/latest/all_vacancies_${userDetails?.data?.tenant}.json`}
            </a>
          </>
        }
        languagePack={languagePack}
        logo={companyLogo}
        name={languagePack.careersSiteFeedName}
        type="CARREER_SITE_FEED"
      />
      <JobBoardEntry
        alreadyPublished={data.INTERNAL_JOBS}
        description={languagePack.internalJobsDescription}
        id={data.INTERNAL_JOBS?.id}
        languagePack={languagePack}
        logo={companyLogo}
        name={languagePack.internalJobsName}
        onChange={() => {
          if (!data.INTERNAL_JOBS) {
            createPublisherBoardAccount({ name: 'Internal Vacancies', type: 'INTERNAL_JOBS' }).then((result) => {
              data.INTERNAL_JOBS = { id: result.account.id };
            });
          } else {
            deletePublisherBoardAccount(data.INTERNAL_JOBS?.id, 'INTERNAL_JOBS');
          }
        }}
        simpleJobBoard
        type="INTERNAL_JOBS"
      />
    </PageColumn>
  );
}

function mapStateToProps(state) {
  const {
    userData: { userDetails },
    companyData: {
      companyDetails: { companyLogo },
    },
  } = state;
  return { userDetails, companyLogo };
}

JobBoardSettings.propTypes = {
  companyLogo: PropTypes.string,
  userDetails: PropTypes.shape(),
};

JobBoardSettings.defaultProps = {
  companyLogo: '',
  userDetails: {},
};

export default connect(mapStateToProps, null)(JobBoardSettings);
