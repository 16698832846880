import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Card, CardBody, Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';
import { DatePickerInput } from '@Base/Forms/Custom/DatePicker';

import { useLanguagePack, useMounted } from '@Base/hooks';
import { retryableAPICall } from '@API/common-api-utils';
import { LoadingScreen } from '@Base/Loading';
import { HeadingPushRight } from '@Base/Headings';
import { IconSVG } from '@Base/SVG';
import trainingNoData from '../../../assets/img/nodata/personality-test.png';
import ActionToBePerformedNoDataState from '../Other/ActionToBePerformedNoDataState';
import { getLMSSummary, updateLMSSummary } from '@API/LMSAPI/LMSAPI';
import moment from 'moment';

function EditableLMSDetails({ candidate, onError }) {
  const languagePack = useLanguagePack('lms');
  const [courseCompletionStatus, setCourseCompletionStatus] = useState(undefined);
  const [loading, setLoading] = useState(true);
  const [editingCourseIndex, setEditingCourseIndex] = useState(null);
  const [savingCourse, setSavingCourse] = useState(null);
  const [tempCompletedDate, setTempCompletedDate] = useState(null);
  const isMounted = useMounted();

  const STATUS_NOT_REQUESTED = 'NOT_REQUESTED';
  const STATUS_REQUESTED = 'REQUESTED';
  const STATUS_PART_RECEIVED = 'PART_RECEIVED';
  const STATUS_COMPLETE = 'COMPLETE';
  const STATUS_RECEIVED = 'RECEIVED';

  useEffect(() => {
    const fetchCourseDetails = async () => {
      if (isMounted()) {
        setLoading(true);

        try {
          const courseCompletionDetails = await retryableAPICall(() => getLMSSummary(candidate.id));

          if (typeof courseCompletionDetails === 'string') {
            if (courseCompletionDetails === 'NOT_FOUND_ERROR') {
              setCourseCompletionStatus({ status: STATUS_NOT_REQUESTED });
            } else {
              onError(
                languagePack.courseLoadError || 'Could not load Course Completion information please try again later',
              );
            }
          } else {
            setCourseCompletionStatus(courseCompletionDetails);
          }
        } catch (error) {
          onError(error.message || 'An error occurred while fetching course details');
        }
        setLoading(false);
      }
    };

    fetchCourseDetails();
  }, [candidate.id, isMounted, onError]);

  const handleDateSave = async () => {
    if (!courseCompletionStatus?.courses || tempCompletedDate === null || editingCourseIndex === null) return;

    const updatedCourses = [...courseCompletionStatus.courses];
    const courseToUpdate = { ...updatedCourses[editingCourseIndex] };

    courseToUpdate.completedDateTime = tempCompletedDate.format('YYYY-MM-DD HH:mm:ss');
    updatedCourses[editingCourseIndex] = courseToUpdate;

    setSavingCourse(courseToUpdate.name);

    try {
      const updatedStatus = await retryableAPICall(() => {
        return updateLMSSummary({
          candidateId: candidate.id,
          courses: [
            {
              id: courseToUpdate.id,
              completionDate: tempCompletedDate.format('YYYY-MM-DD HH:mm:ss'),
            },
          ],
        });
      });

      setCourseCompletionStatus(updatedStatus);
      setEditingCourseIndex(null);
      setTempCompletedDate(null);
    } catch (error) {
      onError(error.message || 'Could not update course completion date');
    } finally {
      setSavingCourse(null);
    }
  };

  const openDateEditModal = (courseIndex) => {
    const course = courseCompletionStatus.courses[courseIndex];
    setEditingCourseIndex(courseIndex);
    setTempCompletedDate(course.completedDateTime ? moment(course.completedDateTime) : null);
  };

  const renderDateEditModal = (course, index) => (
    <Modal isOpen={editingCourseIndex === index} toggle={() => setEditingCourseIndex(null)}>
      <ModalHeader toggle={() => setEditingCourseIndex(null)}>Add Completion Date for {course.name}</ModalHeader>
      <ModalBody
        style={{
          overflowY: 'visible',
        }}
      >
        <DatePickerInput
          id="logic-date-picker"
          value={tempCompletedDate}
          onChange={(date) => setTempCompletedDate(date)}
          showClearDate={false}
          pastDates
          futureDates={false}
        />
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={() => setEditingCourseIndex(null)}>
          Cancel
        </Button>
        <Button color="primary" onClick={handleDateSave} disabled={!tempCompletedDate || savingCourse === course.name}>
          {savingCourse === course.name ? 'Saving...' : 'Confirm'}
        </Button>
      </ModalFooter>
    </Modal>
  );

  const getStatusIcon = (status) => {
    switch (status) {
      case 'RECEIVED':
      case 'COMPLETE':
        return <IconSVG name="Check Circle" className="tw-text-green-500 tw-mr-2" />;
      case 'REQUESTED':
        return <IconSVG name="Clock" className="tw-text-amber-500 tw-mr-2" />;
      case 'FAILED':
        return <IconSVG name="X Circle" className="tw-text-red-500 tw-mr-2" />;
      default:
        return <IconSVG name="Help Circle" className="tw-text-gray-500 tw-mr-2" />;
    }
  };

  const getStatusClass = (status) => {
    switch (status) {
      case 'RECEIVED':
      case 'COMPLETE':
        return 'tw-text-green-600 tw-font-medium';
      case 'REQUESTED':
        return 'tw-text-amber-600 tw-font-medium';
      case 'FAILED':
        return 'tw-text-red-600 tw-font-medium';
      default:
        return 'tw-text-gray-600 tw-font-medium';
    }
  };

  const formatDate = (dateString) => {
    if (!dateString) return 'N/A';
    const date = new Date(dateString);
    return date.toLocaleDateString('en-GB', {
      day: '2-digit',
      month: 'short',
      year: 'numeric',
    });
  };

  const countOutstandingCourses = () => {
    if (!courseCompletionStatus?.courses) return 0;
    return courseCompletionStatus.courses.filter(
      (course) => course.status !== 'RECEIVED' && course.status !== 'COMPLETE',
    ).length;
  };

  const getStatusText = (status) => {
    switch (status) {
      case STATUS_NOT_REQUESTED:
        return languagePack.statusNotRequested || 'Not Requested';
      case STATUS_REQUESTED:
        return languagePack.statusRequested || 'Requested';
      case STATUS_PART_RECEIVED:
        return languagePack.statusPartReceived || 'Partially Complete';
      case STATUS_COMPLETE:
      case STATUS_RECEIVED:
        return languagePack.statusComplete || 'Complete';
      default:
        return status || 'Unknown';
    }
  };
  return (
    <Card>
      <CardBody>
        {loading ? (
          <LoadingScreen notFixed />
        ) : (
          <>
            {courseCompletionStatus?.status === STATUS_NOT_REQUESTED ? (
              <ActionToBePerformedNoDataState
                image={trainingNoData}
                title={languagePack.courseNotRequestedTitle || 'Course Completion Not Requested'}
                description={
                  languagePack.courseNotRequestedDesc ||
                  'No course completion requests have been made for this candidate.'
                }
              />
            ) : (
              <>
                <HeadingPushRight
                  className="tw-mb-4"
                  headingAs="h4"
                  headingText={`${languagePack.courseTitle || 'Course Completion'} - ${getStatusText(courseCompletionStatus?.status)}`}
                />

                {courseCompletionStatus?.courses && courseCompletionStatus.courses.length > 0 && (
                  <>
                    <div className="tw-mb-4 tw-flex tw-items-center tw-p-3 tw-bg-blue-50 tw-border tw-border-blue-200 tw-rounded-md">
                      <IconSVG name="Info" className="tw-text-blue-500 tw-mr-2" />
                      <span className="tw-text-blue-700">
                        {countOutstandingCourses() > 0
                          ? `${countOutstandingCourses()} out of ${courseCompletionStatus.courses.length} courses still outstanding`
                          : 'All courses have been completed'}
                      </span>
                    </div>

                    <div className="tw-overflow-x-auto">
                      <table className="tw-min-w-full tw-border-collapse tw-border tw-border-gray-200 tw-rounded-lg">
                        <thead className="tw-bg-gray-100">
                          <tr>
                            <th className="tw-py-3 tw-px-4 tw-text-left tw-font-semibold tw-text-gray-700">
                              Course Name
                            </th>
                            <th className="tw-py-3 tw-px-4 tw-text-left tw-font-semibold tw-text-gray-700">Status</th>
                            <th className="tw-py-3 tw-px-4 tw-text-left tw-font-semibold tw-text-gray-700">
                              Requested Date
                            </th>
                            <th className="tw-py-3 tw-px-4 tw-text-left tw-font-semibold tw-text-gray-700">
                              Completed Date
                            </th>
                            <th className="tw-py-3 tw-px-4 tw-text-left tw-font-semibold tw-text-gray-700">
                              Last Updated
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {courseCompletionStatus.courses.map((course, index) => {
                            const lastStatusChange =
                              course.statusHistory && course.statusHistory.length > 0
                                ? course.statusHistory[course.statusHistory.length - 1]
                                : null;

                            return (
                              <tr key={index} className={index % 2 === 0 ? 'tw-bg-white' : 'tw-bg-gray-50'}>
                                <td className="tw-py-3 tw-px-4 tw-border-t tw-border-gray-200">{course.name}</td>
                                <td className="tw-py-3 tw-px-4 tw-border-t tw-border-gray-200">
                                  <div className="tw-flex tw-items-center">
                                    {getStatusIcon(course.status)}
                                    <span className={getStatusClass(course.status)}>
                                      {getStatusText(course.status)}
                                    </span>
                                  </div>
                                </td>
                                <td className="tw-py-3 tw-px-4 tw-border-t tw-border-gray-200">
                                  {formatDate(course.requestedDateTime)}
                                </td>
                                <td className="tw-py-3 tw-px-4 tw-border-t tw-border-gray-200">
                                  {formatDate(course.completedDateTime)}
                                  {!course.completedDateTime && (
                                    <Button
                                      color="link"
                                      className="tw-ml-1 tw-mb-1.5"
                                      disabled={savingCourse === course.name}
                                      onClick={() => openDateEditModal(index)}
                                    >
                                      <IconSVG name="Edit" className="tw-text-gray-500" />
                                    </Button>
                                  )}
                                </td>
                                <td className="tw-py-3 tw-px-4 tw-border-t tw-border-gray-200">
                                  {lastStatusChange ? formatDate(lastStatusChange.statusDateTime) : 'N/A'}
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>

                    {courseCompletionStatus.courses.map((course, index) => renderDateEditModal(course, index))}
                  </>
                )}
              </>
            )}
          </>
        )}
      </CardBody>
    </Card>
  );
}

EditableLMSDetails.propTypes = {
  candidate: PropTypes.shape({
    id: PropTypes.string,
  }),
  onError: PropTypes.func,
};

EditableLMSDetails.defaultProps = {
  candidate: {},
  onError: () => {},
};

export default EditableLMSDetails;
