import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { TextButton } from '@Base/Buttons';
import { retryableAPICall } from '@API/common-api-utils';
import { downloadLargeFile } from '@API/FileAPI/FileAPI';
import BuildDetails from './BuildDetails';
import { toast } from 'react-toastify';

export default function AttachmentDownload({ data, titleWidth, touchTitleColumnSize, title, btnLabel }) {
  if (!data || !data.fileName) return null;

  const [isDownloading, setIsDownloading] = useState(false);
  const [showLongDownloadMessage, setShowLongDownloadMessage] = useState(false);

  useEffect(() => {
    let timer;
    if (isDownloading) {
      timer = setTimeout(() => {
        setShowLongDownloadMessage(true);
      }, 10000); // 10 seconds
    } else {
      setShowLongDownloadMessage(false);
    }

    return () => clearTimeout(timer);
  }, [isDownloading]);

  const handleDownload = async () => {
    setIsDownloading(true);
    try {
      await retryableAPICall(() => downloadLargeFile(data.id));
    } catch (error) {
      toast.error('There was an error when downloading the file. Please try again later.');
    } finally {
      setIsDownloading(false);
    }
  };

  return (
    <BuildDetails title={title} titleColumnSize={titleWidth} touchTitleColumnSize={touchTitleColumnSize || titleWidth}>
      <TextButton
        floatRight={false}
        label={isDownloading ? 'Downloading...' : btnLabel || 'Download'}
        className="btn-outline"
        size="sm"
        action={handleDownload}
        disabled={isDownloading}
      />
      {showLongDownloadMessage && (
        <p className="mt-2 text-sm text-gray-600">
          The download may take a long time if the file is large. Please don't navigate away from this page.
        </p>
      )}
    </BuildDetails>
  );
}

AttachmentDownload.propTypes = {
  title: PropTypes.string,
  btnLabel: PropTypes.string,
  data: PropTypes.shape({
    content: PropTypes.shape({
      fileName: PropTypes.string,
      contentType: PropTypes.string,
    }),
    name: PropTypes.string,
    type: PropTypes.string,
    id: PropTypes.string,
  }),
  titleWidth: PropTypes.number,
  touchTitleColumnSize: PropTypes.number,
};

AttachmentDownload.defaultProps = {
  title: null,
  btnLabel: null,
  data: {},
  titleWidth: 3,
  touchTitleColumnSize: 4,
};
