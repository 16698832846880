import React from 'react';
import { Card, CardBody, CardGroup, Col, Container, Row } from 'reactstrap';
import { ResolveLogo } from '@Base/SVG';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';

function PasswordChangeRequired() {
  const { search } = useLocation();
  // eslint-disable-next-line camelcase
  const { reason } = queryString.parse(search);

  return (
    <div className="app flex-row align-items-center auth-page">
      <Container>
        <Row className="justify-content-center">
          <Col lg={6} md={7} sm={10} xl={5} xs={12}>
            <CardGroup>
              <Card className="p-4 rounded">
                <CardBody>
                  <div className="text-center">
                    <ResolveLogo />
                    <h4 className="mt-2 mb-4 text-muted">Password Change Required</h4>
                  </div>
                  <Row>
                    <Col className="text-center">
                      {!reason && (
                        <p className="mt-1">
                          Your password needs to be changed in order to continue. Please check your email account for
                          instructions.
                        </p>
                      )}
                      {reason === 'Administrative' && (
                        <p className="mt-1">
                          The administrator of this account has requested your password is changed before you continue.
                          Please check your email account for instructions.
                        </p>
                      )}
                      {reason === 'Breached' && (
                        <>
                          <p className="mt-1">
                            As part of our continuous commitment to security, our proactive monitoring systems have
                            detected that a password associated with your account may have been used on other online
                            platforms that might be at risk of security breaches.
                          </p>
                          <br />
                          <p className="mt-1">
                            To ensure the protection of your account with us, we require you to reset your password. We
                            also highly recommend that you update your password on any other platforms where the same
                            credentials have been utilised. For optimal security, it is best practice to use a unique
                            password for each service.
                          </p>
                          <br />
                          <p className="mt-1">
                            Instructions to reset your password have been dispatched to your email. Please check your
                            inbox, and follow the steps provided to update your credentials promptly.
                          </p>
                        </>
                      )}
                      {reason === 'Expired' && (
                        <p className="mt-1">
                          Your password has expired and must be changed before you can continue. Please check your email
                          account for instructions.
                        </p>
                      )}
                      {reason === 'Validation' && (
                        <p className="mt-1">
                          Your password fails to meet the minimum requirements and must be changed before you can
                          continue. Please check your email account for instructions.
                        </p>
                      )}
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </CardGroup>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

PasswordChangeRequired.propTypes = {};

PasswordChangeRequired.defaultProps = {};

export default PasswordChangeRequired;
