import React, { useCallback, useRef, useState } from 'react';
import {
  ReactFlow,
  Controls,
  Background,
  useNodesState,
  useEdgesState,
  reconnectEdge,
  addEdge,
  getOutgoers,
  useReactFlow,
} from '@xyflow/react';
import { Spinner } from 'reactstrap';
import styled from 'styled-components';
import './workflow.css';
import NotesNode from './CustomNodes/NotesNode';
import DecisionNode from './CustomNodes/DecisionNode';
import EndNode from './CustomNodes/EndNode';
import StartNode from './CustomNodes/StartNode';
import ActionNode from './CustomNodes/ActionNode';
import NodeSelector from './NodeSelector';
import YesEdge from './CustomEdges/YesEdge';
import NoEdge from './CustomEdges/NoEdge';
import DefaultEdge from './CustomEdges/DefaultEdge';
import WaitNode from './CustomNodes/WaitNode';
import { toast } from 'react-toastify';
import { getNextId, findClosestNode } from './utils';

import '@xyflow/react/dist/style.css';

const nodeTypes = {
  notesNode: NotesNode,
  decisionNode: DecisionNode,
  waitNode: WaitNode,
  endNode: EndNode,
  startNode: StartNode,
  actionNode: ActionNode,
};

const edgeTypes = {
  yesEdge: YesEdge,
  noEdge: NoEdge,
  DefaultEdge: DefaultEdge,
};

const Container = styled.div`
  flex-grow: 1;
  min-height: 60vh;
  max-height: 70vh;
  position: relative;
`;

const StyledLoader = styled.div`
  z-index: 3;
  height: 100%;
  position: absolute;
  background-color: rgba(255, 255, 255, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

const WorkflowWrapper = ({ initialEdges, initialNodes, isSaving }) => {
  const [nodes, setNodes, onNodesChange] = useNodesState(initialNodes);
  const [edges, setEdges, onEdgesChange] = useEdgesState(initialEdges);
  const [highlightNode, setHighlightNode] = useState(null);
  const reactFlowInstance = useRef(null);
  const edgeUpdateSuccessful = useRef(true);

  const { getNodes, getEdges } = useReactFlow();

  const onConnect = useCallback(
    (params) => {
      const sourceNode = nodes.find((node) => node.id === params.source);
      const isDecisionNode = sourceNode && sourceNode.type === 'decisionNode';

      const existingEdgesFromSource = edges.filter((edge) => edge.source === params.source).length;

      if (isDecisionNode || existingEdgesFromSource === 0) {
        let newEdge = {
          id: `e${params.source}-${params.target}`,
          source: params.source,
          target: params.target,
          type: 'DefaultEdge',
        };

        if (isDecisionNode) {
          newEdge = {
            ...newEdge,
            label: params.sourceHandle === 'yes' ? 'Yes' : 'No',
            sourceHandle: params.sourceHandle,
            type: params.sourceHandle === 'yes' ? 'yesEdge' : 'noEdge',
          };
        }

        setEdges((eds) => eds.concat(newEdge));
      } else {
        toast.error('Only decision nodes can have multiple outgoing edges.');
      }
    },
    [nodes, edges, setEdges],
  );

  const onReconnectStart = useCallback(() => {
    edgeUpdateSuccessful.current = false;
  }, []);

  const onReconnect = useCallback(
    (oldEdge, newConnection) => {
      const { source, target, type } = newConnection;
      const newEdge = {
        type,
        source,
        target,
        id: `e${source}-${target}`, // Update the edge ID
      };

      setEdges((els) => els.map((edge) => (edge.id === oldEdge.id ? newEdge : edge)));
    },
    [setEdges],
  );
  const onReconnectEnd = useCallback(
    (_, edge) => {
      if (!edgeUpdateSuccessful.current) {
        setEdges((eds) => eds.filter((e) => e.id !== edge.id));
      }
      edgeUpdateSuccessful.current = true;
    },
    [setEdges],
  );

  const onDragOver = useCallback(
    (event) => {
      event.preventDefault();
      event.dataTransfer.dropEffect = 'move';

      const reactFlowBounds = reactFlowInstance.current.screenToFlowPosition({
        x: event.clientX,
        y: event.clientY,
      });

      const proximityNode = findClosestNode(reactFlowBounds, nodes);

      if (proximityNode) {
        setHighlightNode(proximityNode);
      } else {
        setHighlightNode(null);
      }
    },
    [setHighlightNode, nodes],
  );

  const onDrop = useCallback(
    (event) => {
      event.preventDefault();

      if (reactFlowInstance.current) {
        const type = event.dataTransfer.getData('application/reactflow');
        const label = event.dataTransfer.getData('label');
        const actionType = event.dataTransfer.getData('actionType');

        const position = reactFlowInstance.current.screenToFlowPosition({
          x: event.clientX,
          y: event.clientY,
        });

        const newNode = {
          id: getNextId(nodes),
          type,
          position,
          data: { label, actionType },
        };

        if (type === 'startNode') {
          const endNode = {
            id: getNextId([...nodes, newNode]),
            type: 'endNode', // Create an end node
            position: {
              x: position.x,
              y: position.y + 200,
            },
            data: { label: 'Exit Workflow', actionType: 'EXIT' },
          };

          setNodes((nds) => [...nds, newNode, endNode]);
        } else {
          setNodes((nds) => nds.concat(newNode));
        }

        if (highlightNode && highlightNode?.type !== 'endNode') {
          const sourceNode = nodes.find((node) => node.id === highlightNode.id);
          const isDecisionNode = sourceNode.type === 'decisionNode';
          const existingEdgesFromSource = edges.filter((edge) => edge.source === highlightNode.id).length;

          if (!isDecisionNode && existingEdgesFromSource === 0) {
            const newEdge = {
              id: `e${highlightNode?.id}-${newNode.id}`,
              source: highlightNode?.id,
              target: newNode.id,
              type: 'DefaultEdge',
            };
            setEdges((eds) => eds.concat(newEdge));
          }
        }

        setHighlightNode(null);
      }
    },
    [setNodes, setEdges, nodes, edges, highlightNode],
  );

  const getNodeStyle = (nodeId) => ({
    background: nodeId === highlightNode?.id ? '#ffcc00' : undefined,
    border: nodeId === highlightNode?.id ? '2px solid yellow' : 'none',
  });

  const isValidConnection = useCallback(
    (connection) => {
      const nodes = getNodes();
      const edges = getEdges();
      const target = nodes.find((node) => node.id === connection.target);
      const hasCycle = (node, visited = new Set()) => {
        if (visited.has(node.id)) return false;

        visited.add(node.id);

        for (const outgoer of getOutgoers(node, nodes, edges)) {
          if (outgoer.id === connection.source) return true;
          if (hasCycle(outgoer, visited)) return true;
        }
      };

      if (target.id === connection.source) return false;
      return !hasCycle(target);
    },
    [getNodes, getEdges],
  );

  return (
    <Container className="dndflow">
      <NodeSelector nodes={nodes} />
      <ReactFlow
        nodes={nodes.map((node) => ({
          ...node,
          style: { ...node.style, ...getNodeStyle(node.id) },
        }))}
        nodeTypes={nodeTypes}
        edges={edges}
        edgeTypes={edgeTypes}
        onNodesChange={(a) => {
          onNodesChange(a);
        }}
        isValidConnection={isValidConnection}
        onReconnect={onReconnect}
        onReconnectStart={onReconnectStart}
        onReconnectEnd={onReconnectEnd}
        onEdgesChange={(a) => {
          onEdgesChange(a);
        }}
        onConnect={onConnect}
        onInit={(instance) => (reactFlowInstance.current = instance)}
        fitView
        onDragOver={onDragOver}
        onDrop={onDrop}
        attributionPosition="top-right"
        defaultEdgeOptions={{
          deletable: true,
          selectable: true,
          focusable: true,
        }}
        style={{
          'min-height': '80vh',
        }}
      >
        <Background />
        <Controls position="bottom-right" />
      </ReactFlow>
      {isSaving ? (
        <StyledLoader>
          <Spinner />
        </StyledLoader>
      ) : null}
    </Container>
  );
};

export default WorkflowWrapper;
