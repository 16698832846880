import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Form from 'react-jsonschema-form';
import { toast } from 'react-toastify';

import { schema, uiSchema } from '@JS/model/form-schemas/DescriptionSchema';
import { transformErrors, validateFields } from '@JS/utils/validation-helper';

import RTE from '@Base/Forms/Custom/RTE/RTE';
import { CancelButton, CreateButton } from '@Base/Buttons';

import { retryableAPICall } from '@API/common-api-utils';
import { createDescription, updateDescription } from '@API/SchedulingAPI/DescriptionsAPI';

async function proxyCall(data, id) {
  if (id) return updateDescription({ ...data, id });
  return createDescription(data);
}

function DescriptionEditor({ isEditing, data, onSave, onCancel }) {
  const { name, description, id } = data;
  const mappedData = {
    descriptionName: name,
    descriptionContent: description,
  };
  const [clientData, setClientData] = useState(mappedData);
  const [isSaving, setIsSaving] = useState(false);

  async function handleSave(formData) {
    setIsSaving(true);
    setClientData(formData);

    const { descriptionName, descriptionContent } = formData;

    const serverData = {
      name: descriptionName,
      description: descriptionContent,
    };

    const resp = await retryableAPICall(() => proxyCall(serverData, id));

    if (typeof resp === 'string') {
      toast.error(`Error ${isEditing ? 'updating' : 'creating'} description`);
    } else {
      toast.success(`Description successfully ${isEditing ? 'updated' : 'created'}`);
      onSave(resp);
    }

    setIsSaving(false);
  }

  const saveBtnText = isSaving ? 'Saving...' : `${isEditing ? 'Update' : 'Create'} Description`;

  return (
    <Form
      formData={clientData}
      onSubmit={({ formData }) => handleSave(formData)}
      schema={schema}
      uiSchema={uiSchema}
      fields={{ rte: RTE }}
      noHtml5Validate
      showErrorList={false}
      validate={validateFields}
      transformErrors={transformErrors}
    >
      <CreateButton isLoading={isSaving} disabled={isSaving} label={saveBtnText} type="submit" />
      <CancelButton label="Cancel" disabled={isSaving} action={onCancel} />
    </Form>
  );
}

DescriptionEditor.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    description: PropTypes.string,
  }),
  isEditing: PropTypes.bool,
  onSave: PropTypes.func,
  onCancel: PropTypes.func,
};

DescriptionEditor.defaultProps = {
  data: {},
  isEditing: false,
  onSave: () => {},
  onCancel: () => {},
};

export default DescriptionEditor;
