import React, { useState } from 'react';
import { toast } from 'react-toastify';
import * as utils from '../../utils';
import { connect } from 'react-redux';

async function fetchEvents(accountAccess) {
  const isoStart = utils.dateTimeUtils.getIsoDate(new Date());

  const eventPromises = accountAccess.map(async (accountId) => {
    try {
      return await utils.fetchUtils.getEventData(
        isoStart,
        isoStart,
        accountId,
        () => {
          toast.error(`Error fetching event data for account ${accountId}. Please try again`);
        },
        null,
        null,
        null,
        true,
      );
    } catch (error) {
      console.error(`Error fetching events for account ${accountId}:`, error);
      return [];
    }
  });

  const eventArrays = await Promise.all(eventPromises);

  return eventArrays.flat();
}

const DownloadCandidatesButton = ({ accountAccess }) => {
  const [isLoading, setIsLoading] = useState(false);

  const handleDownload = async () => {
    try {
      setIsLoading(true);

      const accountIds = accountAccess.map(({ accountId }) => accountId);

      if (accountIds.length === 0) {
        toast.warn('No accounts available');
        return;
      }

      const events = await fetchEvents(accountIds);

      let allCandidates = [];

      events.forEach((event) => {
        if (event.bookedInCandidates && event.bookedInCandidates.length > 0) {
          event.bookedInCandidates.forEach((candidate) => {
            allCandidates.push({
              firstName: candidate.firstName || '',
              surname: candidate.surname || '',
              email: candidate.email || '',
              status: candidate.status || '',
              vacancyId: candidate.vacancyDetails?.vacancyId || '',
              vacancyTitle: candidate.vacancyDetails?.vacancyTitle || '',
              startDate: event.startDate || '',
              endDate: event.endDate || '',
              eventName: event.eventName || '',
            });
          });
        }
      });

      if (allCandidates.length === 0) {
        toast.info('No candidates found for today');
        setIsLoading(false);
        return;
      }

      // Convert to CSV
      const headers = [
        'First Name',
        'Surname',
        'Email',
        'Status',
        'Vacancy ID',
        'Vacancy Title',
        'Start Date',
        'End Date',
        'Event Name',
      ];
      const csvContent = [
        headers.join(','),
        ...allCandidates.map((candidate) =>
          [
            `"${candidate.firstName}"`,
            `"${candidate.surname}"`,
            `"${candidate.email}"`,
            `"${candidate.status}"`,
            `"${candidate.vacancyId}"`,
            `"${candidate.vacancyTitle}"`,
            `"${candidate.startDate}"`,
            `"${candidate.endDate}"`,
            `"${candidate.eventName}"`,
          ].join(','),
        ),
      ].join('\n');

      const blob = new Blob([csvContent], { type: 'text/csv' });
      const url = URL.createObjectURL(blob);
      const link = document.createElement('a');

      const today = new Date().toISOString().split('T')[0];
      link.download = `interviews-${today}.csv`;
      link.href = url;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      URL.revokeObjectURL(url);

      toast.success(`${allCandidates.length} candidates downloaded successfully`);
    } catch (error) {
      toast.error('Error downloading candidate data');
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="tw-inline-block tw-ml-2">
      <button
        onClick={handleDownload}
        disabled={isLoading}
        className="tw-bg-gray-100 tw-text-gray-700 tw-py-2 tw-px-4 tw-rounded-lg tw-flex tw-items-center tw-gap-2 tw-border tw-border-gray-200 tw-hover:bg-gray-200 tw-transition-colors tw-duration-200 tw-ml-2"
      >
        {isLoading ? (
          <>
            <svg
              className="tw-animate-spin tw-h-5 tw-w-5 tw-text-gray-600"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle className="tw-opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
              <path
                className="tw-opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              ></path>
            </svg>
            <span>Loading...</span>
          </>
        ) : (
          <>
            <svg
              className="tw-h-5 tw-w-5"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M12 10v6m0 0l-3-3m3 3l3-3m2 8H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"
              />
            </svg>
            Download Candidates
          </>
        )}
      </button>
    </div>
  );
};

function mapStateToProps(state) {
  const {
    userData: {
      userDetails: {
        data: { accountAccess = [] },
      },
    },
  } = state;

  return { accountAccess };
}

export default connect(mapStateToProps)(DownloadCandidatesButton);
