/* eslint-disable import/no-unresolved */
/* eslint-disable jsx-a11y/label-has-for */
import PropTypes from 'prop-types';
import React from 'react';
import { Col, FormGroup, Label } from 'reactstrap';
import ColorInput from '../../../../../Branding/ColorInput';

require('rc-collapse/assets/index.css');

function Colours({ currentMUITheme, changeStyle }) {
  return (
    <>
      <FormGroup>
        <Label for="text-colour">Text Colour:</Label>
        <Col>
          <ColorInput
            className="w-50"
            id="text-colour"
            onChange={(val) => {
              changeStyle('palette.text.primary', val);
            }}
            value={currentMUITheme?.palette?.text.primary}
          />
        </Col>
      </FormGroup>
      <FormGroup>
        <Label for="label-text-colour">Input Label Text Colour:</Label>
        <ColorInput
          className="w-50 mb-0"
          id="label-text-colour"
          onChange={(val) => {
            changeStyle('palette.text.secondary', val);
          }}
          swatchClassName="mt-0"
          value={currentMUITheme?.palette?.text.secondary}
        />
      </FormGroup>
      <FormGroup>
        <Label for="error-text-colour">Error Text Colour:</Label>
        <ColorInput
          className="w-50 "
          id="error-text-colour"
          onChange={(val) => {
            changeStyle('palette.error.main', val);
          }}
          swatchClassName="mt-0"
          value={currentMUITheme?.palette?.error.main}
        />
      </FormGroup>
      <FormGroup>
        <Label for="button-colour">Input Colour:</Label>
        <ColorInput
          className="w-50"
          id="button-colour"
          onChange={(val) => {
            changeStyle('components.MuiInputBase.styleOverrides.root.background', val);
          }}
          swatchClassName="mt-0"
          value={currentMUITheme?.components?.MuiInputBase?.styleOverrides?.root?.background}
        />
      </FormGroup>
      <FormGroup>
        <Label for="primary-colour">Focused Input Outline Colour:</Label>
        <ColorInput
          className="w-50"
          id="primary-colour"
          onChange={(val) => {
            changeStyle('palette.primary.main', val);
          }}
          value={currentMUITheme?.palette?.primary.main}
        />
      </FormGroup>
    </>
  );
}

Colours.propTypes = {
  changeStyle: PropTypes.func,
  currentMUITheme: PropTypes.shape(),
};

Colours.defaultProps = {
  changeStyle: () => {},
  currentMUITheme: {},
};

export default Colours;
