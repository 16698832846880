import React, { useState } from 'react';
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  Input,
  InputGroup,
  InputGroupText,
  Label,
  Row,
} from 'reactstrap';
import { toast } from 'react-toastify';
import { Checkbox } from '@Base/Tables/components';
import { registerTenant } from '@API/AccountAPI/TenantAdminUserAPI';

export default function Register() {
  const [companyName, setCompanyName] = useState('');
  const [firstName, setFirstname] = useState();
  const [surname, setSurname] = useState();
  const [email, setEmail] = useState();
  const [careerSiteUrl, setCareerSiteUrl] = useState();
  const [careerSiteName, setCareerSiteName] = useState();
  const [vacancyPagePath, setVacancyPagePath] = useState('/jobs');
  const [password, setPassword] = useState('');
  const [passwordConf, setPasswordConf] = useState('');
  const [passwordError, setPasswordError] = useState(undefined);
  const [fieldErrors, setFieldErrors] = useState([]);
  const [hostedCareerSiteChecked, setHostedCareerSiteChecked] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  async function handleSubmit(e) {
    e.preventDefault();
    setFieldErrors([]);

    const fieldErrCp = fieldErrors;

    if (!companyName || companyName.length === 0) {
      fieldErrCp.companyName = 'Company name is required';
    } else {
      delete fieldErrCp.companyName;
    }
    if (!firstName || firstName.length === 0) {
      fieldErrCp.firstName = 'First name is required';
    } else {
      delete fieldErrCp.companyName;
    }
    if (!surname || surname.length === 0) {
      fieldErrCp.surname = 'Surname name is required';
    } else {
      delete fieldErrCp.companyName;
    }

    if (!companyName || companyName.length === 0) {
      fieldErrCp.email = 'Email is required';
    } else {
      delete fieldErrCp.companyName;
    }
    setFieldErrors(fieldErrCp);

    if (password.length < 6) {
      setPasswordError('Your password needs a minimum of six characters');
    } else if (password.search(/[A-Z]/) < 0 || password.search(/[0-9]/) < 0) {
      setPasswordError('Your password needs at least 1 uppercase character and 1 number');
    } else if (password !== passwordConf) {
      setPasswordError('Your passwords do not match please try again');
    } else if (fieldErrCp.length === 0) {
      const submitObj = {
        accountBillingType: 'BASIC',
        businessType: 'Hospitality',
        companyCountry: 'United Kingdom',
        companyName,
        email,
        firstName,
        jobsiteConfig: {
          description: careerSiteName,
          hosted: hostedCareerSiteChecked,
          name: careerSiteName,
          url: careerSiteUrl,
          vacancyPagePath,
        },
        password,
        surname,
        tenantType: 'ATS',
        trial: false,
      };
      setIsSubmitting(true);
      try {
        const result = await registerTenant(submitObj);

        if (typeof result === 'string') {
          toast.error('Could not create account at this time please try again later');
        } else {
          toast.success('Created account!');
        }
      } catch (exception) {
        toast.error('Could not create account at this time please try again later');
      } finally {
        setIsSubmitting(false);
      }
    }
  }

  return (
    <div className="app flex-row align-items-center auth-page">
      <Container>
        <Row className="justify-content-center">
          <Col md="6">
            <Card className="mx-4">
              <CardBody className="p-4">
                <Form>
                  <h1>Register</h1>
                  <p className="text-muted">Create your free ATS account and start managing your applications</p>
                  <InputGroup className="mb-3">
                    <Input
                      autoComplete="companyName"
                      onChange={(e) => {
                        setCompanyName(e.currentTarget.value);
                      }}
                      placeholder="Company Name"
                      type="text"
                    />
                    {fieldErrors.companyName && <p className="text-danger w-100">{fieldErrors.companyName}</p>}
                  </InputGroup>
                  <InputGroup className="mb-3">
                    <Input
                      autoComplete="firstName"
                      onChange={(e) => {
                        setFirstname(e.currentTarget.value);
                      }}
                      placeholder="First Name"
                      type="text"
                    />
                    {fieldErrors.firstName && <p className="text-danger w-100">{fieldErrors.firstName}</p>}
                  </InputGroup>
                  <InputGroup className="mb-3">
                    <Input
                      autoComplete="surname"
                      onChange={(e) => {
                        setSurname(e.currentTarget.value);
                      }}
                      placeholder="Surname"
                      type="text"
                    />
                    {fieldErrors.surname && <p className="text-danger w-100">{fieldErrors.surname}</p>}
                  </InputGroup>
                  <InputGroup className="mb-3">
                    <Input
                      autoComplete="email"
                      onChange={(e) => {
                        setEmail(e.currentTarget.value);
                      }}
                      placeholder="Email"
                      type="text"
                    />
                    {fieldErrors.email && <p className="text-danger w-100">{fieldErrors.email}</p>}
                  </InputGroup>
                  <InputGroup className="mb-3">
                    <Input
                      autoComplete="new-password"
                      onChange={(e) => {
                        setPassword(e.currentTarget.value);
                      }}
                      placeholder="Password"
                      type="password"
                    />
                    {passwordError && <p className="text-danger w-100">{passwordError}</p>}
                    <p>Password must be at least 6 characters, contain one upper case letter and number</p>
                  </InputGroup>
                  <InputGroup className="mb-3">
                    <Input
                      autoComplete="new-password"
                      onChange={(e) => {
                        setPasswordConf(e.currentTarget.value);
                      }}
                      placeholder="Repeat password"
                      type="password"
                    />
                  </InputGroup>
                  <InputGroup className="mb-3">
                    {/* eslint-disable-next-line jsx-a11y/label-has-for */}
                    <Label check id="hosted-site-id">
                      <Checkbox
                        isChecked={hostedCareerSiteChecked}
                        name="hosted-site"
                        onChange={() => setHostedCareerSiteChecked(!hostedCareerSiteChecked)}
                      />
                      <span>Host your career site?</span>
                    </Label>
                  </InputGroup>
                  {hostedCareerSiteChecked && (
                    <>
                      <InputGroup className="mb-3">
                        <Input
                          autoComplete="name"
                          onChange={(e) => {
                            setCareerSiteName(e.currentTarget.value);
                          }}
                          placeholder="Name of career site"
                          type="text"
                        />
                        {fieldErrors.url && <p className="text-danger w-100">{fieldErrors.url}</p>}
                      </InputGroup>
                      <p>{`Leave blank to a free domain - ${companyName.replaceAll(' ', '-')}.applyto.us`} </p>
                      <InputGroup className="mb-3">
                        <Input
                          autoComplete="url"
                          onChange={(e) => {
                            setCareerSiteUrl(e.currentTarget.value);
                          }}
                          placeholder="Address of career site"
                          type="text"
                        />
                        {fieldErrors.url && <p className="text-danger w-100">{fieldErrors.url}</p>}
                      </InputGroup>
                      <InputGroup className="mb-3">
                        <Input
                          autoComplete="url"
                          onChange={(e) => {
                            setVacancyPagePath(e.currentTarget.value);
                          }}
                          placeholder="Path to vacancy page"
                          type="text"
                          value={vacancyPagePath}
                        />
                        {fieldErrors.url && <p className="text-danger w-100">{fieldErrors.url}</p>}
                      </InputGroup>
                    </>
                  )}
                  <Button block color="success" disabled={isSubmitting} onClick={(e) => handleSubmit(e)}>
                    {isSubmitting && 'Creating account...'}
                    {!isSubmitting && 'Create Account'}
                  </Button>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
