import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { FormGroup, FormFeedback, Label, Col, Button } from 'reactstrap';
import cx from 'classnames';
import { uploadUtils } from '@Base/Forms/Custom/FileUpload';
import { TagPill as Tag } from '@Base/Forms/Custom/EmailPills';

const { processFiles, truncateFilename, stripBase64Prefix } = uploadUtils;

export default function Attachments({ id, label, isToggled, isHidden, accept, multiple, onChange, files }) {
  const [attachedFiles, setAttachedFiles] = useState([]);
  const [errorArray, setErrorArray] = useState([]);
  const uploadFileRef = useRef();

  useEffect(() => {
    if (files) setAttachedFiles(files);
  }, [files]);

  function handleChange(e) {
    const {
      target: { files: selectedFiles },
    } = e;
    // bail if no files
    if (!selectedFiles.length) return;

    const maxSize = 1e7; // 10MB in bytes

    const oversizedFiles = Array.from(selectedFiles).filter((file) => file.size > maxSize);
    const acceptableFiles = Array.from(selectedFiles).filter((file) => file.size <= maxSize);

    if (oversizedFiles.length > 0) {
      setErrorArray([...oversizedFiles.map((file) => `${file.name} exceeds the 10MB size limit`)]);
      toast.error(`${oversizedFiles.map((file) => ` ${file.name} exceeds the 10MB size limit`)}`);
    }

    processFiles(acceptableFiles, {
      isBase64: true,
      maxSize: 2.5e7,
    }).then((processedFiles) => {
      const errors = [];

      const validFiles = processedFiles
        .reduce((acc, file) => {
          if (typeof file === 'string') {
            errors.push(file);
          } else {
            acc.push(file);
          }

          return acc;
        }, [])
        // remove data:{fileType};base64, from dataUrl
        .map((f) => ({ ...f, dataUrl: stripBase64Prefix(f.dataUrl) }))
        // ensure mapping worked and reject if not
        .filter((f) => f.dataUrl.length);

      setErrorArray([...errors]);

      const allFiles = [...attachedFiles, ...validFiles];
      if (validFiles.length && !errors.length) setAttachedFiles(allFiles);
      onChange(allFiles);
    });
  }

  function handleRemoveFile(index) {
    const clone = [...attachedFiles];
    clone.splice(index, 1);
    setAttachedFiles([...clone]);
    onChange(clone);
  }

  const invalid = Boolean(errorArray.length);
  const isEmpty = !attachedFiles.length;

  return (
    <FormGroup
      className={cx({
        'is-hidden': isHidden,
        'is-toggle': isToggled,
      })}
      data-input-id={id}
      row
    >
      {/* eslint-disable-next-line jsx-a11y/label-has-for */}
      {label && (
        <Label for={id} sm={3}>
          Attachments:
        </Label>
      )}
      <Col sm={9}>
        <div className={cx('form-control', { 'is-invalid': invalid }, { 'is-empty': isEmpty })}>
          <input
            ref={uploadFileRef}
            accept={accept.join(',')}
            multiple={multiple}
            name="file"
            onChange={handleChange}
            style={{ display: 'none' }}
            type="file"
          />
          <div className="tag-wrapper">
            {attachedFiles.map(({ name }, i) => (
              <Tag
                // eslint-disable-next-line react/no-array-index-key
                key={`${name}-${i}`}
                index={i}
                removeTag={handleRemoveFile}
              >
                {truncateFilename(name)}
              </Tag>
            ))}
          </div>
        </div>
        <Button
          color={invalid ? 'danger' : 'secondary'}
          onClick={(e) => {
            e.preventDefault();
            uploadFileRef.current.click();
          }}
          size="sm"
        >
          <span className="fa fa-upload fa-btn" /> Upload
        </Button>
        <FormFeedback style={{ display: invalid ? 'block' : 'none' }}>
          There was an error processing one or more of your files
        </FormFeedback>
      </Col>
    </FormGroup>
  );
}

Attachments.propTypes = {
  accept: PropTypes.arrayOf(PropTypes.string),
  files: PropTypes.arrayOf(
    PropTypes.shape({
      dataUrl: PropTypes.string,
      id: PropTypes.string,
      name: PropTypes.string,
      size: PropTypes.number,
      type: PropTypes.string,
    }),
  ),
  id: PropTypes.string.isRequired,
  isHidden: PropTypes.bool,
  isToggled: PropTypes.bool,
  label: PropTypes.string,
  multiple: PropTypes.bool,
  onChange: PropTypes.func,
};

Attachments.defaultProps = {
  accept: [
    'application/pdf',
    'application/msword',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/rtf',
    'image/jpeg',
    'image/png',
    'image/gif',
  ],
  files: [],
  isHidden: false,
  isToggled: false,
  label: null,
  multiple: false,
  onChange: () => {},
};
