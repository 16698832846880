import React from 'react';
import PropTypes from 'prop-types';
import { retryableAPICall } from '@API/common-api-utils';
import { createTemplate } from '@API/TemplateAPI';
import { Prompt } from '@Base/Modal';

async function createTemplateFromEmail(formData, onSuccess = () => {}, onError = () => {}) {
  const resp = await retryableAPICall(() => createTemplate(formData));

  if (typeof resp === 'string') {
    onError();
  } else {
    onSuccess(resp);
  }
}

function TemplatePrompt({ templateType, isOpen, closePrompt, onError, onSuccess, validate }) {
  return (
    <Prompt
      title="Template Name"
      isOpen={isOpen}
      onCancel={closePrompt}
      closeOnOkay={false}
      onOkay={(val) => {
        if (val.length) {
          const { formState, isValid } = validate();

          const { fromName, replyTo, ccAddresses, bccAddresses, subject, message, attachments } = formState;

          if (!isValid) {
            onError('Template contains errors, please check required fields');
          } else {
            createTemplateFromEmail(
              {
                type: templateType,
                group: 'PERSONAL',
                name: val,
                fromName,
                replyTo,
                ccAddresses,
                bccAddresses,
                subject,
                content: message,
                attachments,
              },
              (newTemplate) => {
                onSuccess(newTemplate);
                closePrompt();
              },
              () => {
                onError('There was an error creating the template');
              },
            );
          }
        }
      }}
    />
  );
}

TemplatePrompt.propTypes = {
  templateType: PropTypes.string,
  isOpen: PropTypes.bool,
  closePrompt: PropTypes.func,
  onError: PropTypes.func,
  onSuccess: PropTypes.func,
  validate: PropTypes.func,
};

TemplatePrompt.defaultProps = {
  templateType: 'CANDIDATE_CONTACT',
  isOpen: false,
  closePrompt: () => {},
  onError: () => {},
  onSuccess: () => {},
  validate: () => {},
};

export default TemplatePrompt;
