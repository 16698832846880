import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'reactstrap';
import Tippy from '@tippyjs/react';

function UTMRow({ isTouch, title, value }) {
  if (!value) return null;

  return (
    <Row>
      {isTouch ? (
        <Fragment>
          <Col xs="4">
            <p className="detail-title">{title}</p>
          </Col>
          <Col>
            <p className="detail-value">{value}</p>
          </Col>
        </Fragment>
      ) : (
        <Col className="d-flex">
          <p className="detail-title me-3">{title}</p>
          <div className="tw-flex-1 tw-min-w-0">
            <Tippy
              maxWidth={'700px'}
              theme="ats"
              content={<div style={{ wordBreak: 'break-all' }}>{value}</div>}
              delay={[2000, null]}
            >
              <p className="detail-value tw-whitespace-nowrap tw-overflow-hidden tw-text-ellipsis">{value}</p>
            </Tippy>
          </div>
        </Col>
      )}
    </Row>
  );
}

UTMRow.propTypes = {
  isTouch: PropTypes.bool,
  title: PropTypes.string,
  value: PropTypes.string,
};

UTMRow.defaultProps = {
  isTouch: false,
  title: null,
  value: null,
};

function UTMDisplay({ utm, isTouch }) {
  if (!Object.keys(utm).length) return null;

  const { utmSource, utmMedium, utmCampaign } = utm;

  return (
    <Fragment>
      <UTMRow isTouch={isTouch} title="Referred" value={utmSource} />
      <UTMRow isTouch={isTouch} title="Medium" value={utmMedium} />
      <UTMRow isTouch={isTouch} title="Campaign" value={utmCampaign} />
    </Fragment>
  );
}

UTMDisplay.propTypes = {
  utm: PropTypes.shape(),
  isTouch: PropTypes.bool,
};

UTMDisplay.defaultProps = {
  utm: {},
  isTouch: false,
};

export default UTMDisplay;
