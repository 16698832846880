import React, { useEffect, useState } from 'react';
import { CardBody, Table } from 'reactstrap';
import { toast } from 'react-toastify';
import { v4 as uuidv4 } from 'uuid';

import { useLanguagePack, useMounted, usePageLoading } from '@Base/hooks';
import { CreateButton, IconButton } from '@Base/Buttons';
import { Confirmation } from '@Base/Modal';

import { retryableAPICall } from '@API/common-api-utils';
import { getRejectionReasons, saveRejectionReasons } from '@API/CandidateAPI/CandidateAPI';

import { PageColumn, EnhancedCard, EnhancedCardTitle } from '../../Common';

function RejectionReasons() {
  const languagePack = useLanguagePack('rejection-reasons');
  const isMounted = useMounted();
  const { pageState, setPageResolved, setPageRejected } = usePageLoading();
  const [rejectionReasons, setRejectionReasons] = useState([]);
  const [newRejectionReasonValue, setNewRejectionReasonValue] = useState('');
  const [rejectionReasonToRemove, setRejectionReasonToRemove] = useState(undefined);

  async function loadRejectionReasons() {
    try {
      const resp = await retryableAPICall(() => getRejectionReasons());

      if (isMounted()) {
        if (typeof resp === 'string') {
          setPageRejected(resp);
        } else {
          setRejectionReasons(resp);
          setPageResolved();
        }
      }
    } catch (error) {
      if (isMounted()) {
        setPageRejected(error);
      }
    }
  }

  useEffect(() => {
    loadRejectionReasons();
  }, [isMounted]);

  async function doSaveRejectionReasons(rejectionReasonsToSave) {
    try {
      const response = await saveRejectionReasons(rejectionReasonsToSave);
      setRejectionReasons(response);
    } catch (error) {
      toast.error(languagePack.couldNotUpdateRejectionReasons);
    }
  }

  function removeRejectionReason() {
    const updatedCategories = rejectionReasons.filter(
      (rejectionReason) => rejectionReason.id !== rejectionReasonToRemove.id,
    );

    doSaveRejectionReasons(updatedCategories).finally(() => {
      setRejectionReasonToRemove(undefined);
    });
  }

  function addRejectionReason() {
    const updatedRejectionReasons = [...rejectionReasons, { id: uuidv4(), name: newRejectionReasonValue }];

    doSaveRejectionReasons(updatedRejectionReasons).then(() => {
      setNewRejectionReasonValue('');
    });
  }

  return (
    <PageColumn state={pageState}>
      <EnhancedCard>
        <EnhancedCardTitle
          title="Rejection Reasons"
          subtitle="Customise your rejection reasons to give deeper insights when reporting"
        />
        <div className="tw-mb-4">
          <p className="tw-text-gray-600">{languagePack.rolesExplanation}</p>
        </div>
        {rejectionReasons.length > 0 && (
          <Table className="tw-max-w-[500px] rejection-reasons">
            <tbody>
              {rejectionReasons.map((rejectionReason, index) => (
                <tr key={`${rejectionReason.id}-${index}`}>
                  <td>{rejectionReason.name}</td>
                  <td className="tw-w-[50px]">
                    <IconButton
                      iconOnly
                      iconClassName="fa fa-times"
                      className="rejection-reason-delete"
                      size="sm"
                      action={() => setRejectionReasonToRemove(rejectionReason)}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        )}

        {rejectionReasons.length === 0 && !pageState.isRejected && (
          <div className="tw-mb-3">
            <p className="tw-font-bold">{languagePack.noRejectionReasonsLabel}</p>
          </div>
        )}

        {!pageState.isPending && !pageState.isRejected && (
          <div className="tw-space-y-2">
            <input
              placeholder={languagePack.addRejectionReasonPlaceholder}
              className="tw-w-[500px] tw-px-3 tw-py-2 tw-mb-1 tw-border tw-border-gray-300 tw-rounded-lg tw-focus:ring-2 tw-focus:ring-blue-200"
              value={newRejectionReasonValue}
              onChange={(e) => setNewRejectionReasonValue(e.currentTarget.value)}
              onKeyDown={(e) => {
                if (e.key === 'Enter') addRejectionReason();
              }}
            />
            <div>
              <CreateButton
                floatRight={false}
                label={languagePack.addRejectionReasonButtonLabel}
                action={addRejectionReason}
                disabled={!newRejectionReasonValue || newRejectionReasonValue === ''}
              />
            </div>
          </div>
        )}
      </EnhancedCard>

      <Confirmation
        content={languagePack.removeAreYouSureRejectionReason}
        title={`${languagePack.removeAreYouSureRejectionReasonTitle} ${rejectionReasonToRemove?.name}`}
        confirmCallback={removeRejectionReason}
        show={!!rejectionReasonToRemove}
        cancelCallback={() => setRejectionReasonToRemove(undefined)}
      />
    </PageColumn>
  );
}

export default RejectionReasons;
