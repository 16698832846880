import React from 'react';
import PropTypes from 'prop-types';
import { Badge, Col, Row } from 'reactstrap';
import moment from 'moment';
import Tippy from '@tippyjs/react';
import IconSVG from '@Base/SVG/IconSVG';

function NotificationStatusText({ notification, doDelete, toggleViewed, isViewedMode, languagePack }) {
  const { updatedDateTime, id, isViewed } = notification;

  return (
    <Row>
      <Col>
        <p className="small" style={{ color: '#919191', marginLeft: '19px' }}>
          {moment(updatedDateTime).fromNow()}
          {isViewed === false && ' - '}
          {isViewed === false && (
            <Badge key={`${id}_badge`} className="ms-1 p-1" style={{ lineHeight: '10px' }} color="success">
              New
            </Badge>
          )}
          <span>
            <Tippy content={isViewed ? languagePack.markAsUnread : languagePack.markAsRead} theme="ats">
              <a
                href="#toggleView"
                onClick={(e) => {
                  e.preventDefault();
                  toggleViewed();
                }}
              >
                <IconSVG
                  name={isViewed ? 'Eye Slash' : 'Eye'}
                  styles={{ color: 'rgb(88 85 85)' }}
                  className="notif-action-icon"
                />
              </a>
            </Tippy>
            {isViewedMode && (
              <Tippy content={languagePack.deleteLabel} theme="ats">
                <a
                  href="#delete"
                  onDoubleClick={(e) => {
                    e.preventDefault();
                    doDelete();
                  }}
                >
                  <IconSVG name="Bin" styles={{ color: '#b10404' }} className="notif-action-icon" />
                </a>
              </Tippy>
            )}
          </span>
        </p>
      </Col>
    </Row>
  );
}

NotificationStatusText.propTypes = {
  notification: PropTypes.shape(),
  languagePack: PropTypes.shape(),
  doDelete: PropTypes.func,
  toggleViewed: PropTypes.func,
  isViewedMode: PropTypes.bool,
};

NotificationStatusText.defaultProps = {
  notification: {},
  languagePack: {},
  doDelete: () => {},
  toggleViewed: () => {},
  isViewedMode: false,
};

export default NotificationStatusText;
