import React, { useState, useCallback, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import cx from 'classnames';
import { toast } from 'react-toastify';
import styled from 'styled-components';

import { useMounted, usePageLoading } from '@Base/hooks';
import { DataTable, useTableState } from '@Base/Tables';
import { ActionButton, CreateButton } from '@Base/Buttons';
import { TippyTruncate } from '@Base/Truncate';
import { Confirmation } from '@Base/Modal';
import Can from '@Base/RBAC/Can/Can';

import { deleteObjFromArray, updateObjInArray, addObjToArray } from '@JS/utils/arrayOfObjects';
import { retryableAPICall } from '@API/common-api-utils';
import { getWorkflows, deleteWorkflow } from '@API/WorkflowAPI';

import WorkflowEditor from './WorkflowEditor';
import { checkPermissions } from '@JS/auth/AuthUtils';
import { triggerTypes } from './options-schemas';
import {
  EnhancedCard,
  EnhancedCardTitle,
  ClientAdminNoDataMessage,
  PageColumn,
  ClientAdminSlider,
  SearchInput,
  utils,
} from '../../Common';

import { useDebounce } from '../../Common/utils';

const RestrictedCreateButton = Can(CreateButton);

const StyledTable = styled(DataTable)`
  .rt-thead {
    background-color: #f8f9fa !important;
    padding: 0.35rem 0;
  }
`;

async function deleteAction(id, onSuccess = () => {}, onError = () => {}) {
  if (id) {
    const resp = await retryableAPICall(() => deleteWorkflow(id));

    if (typeof resp === 'string' && resp.length) {
      onError();
    } else {
      onSuccess();
    }
  }
}
function Workflows({ isCreateMode, onClose, totalAccounts, activeAccountId, tablePrefs, setIsCreateMode }) {
  const isMounted = useMounted();
  const { pageState, setPageResolved, setPageRejected } = usePageLoading();
  const { tableState, setTableData, setTableRejected } = useTableState({ rowsPerPage: tablePrefs.pageSize });
  const [showConfirm, setShowConfirm] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [isSliderOpen, setIsSliderOpen] = useState(false);
  const [actionMenuId, setActionMenuId] = useState('');
  const [deleteObj, setDeleteObj] = useState();
  const [workflowObj, setWorkflowObj] = useState({});
  const [searchTerm, setSearchTerm] = useState('');
  const [originalData, setOriginalData] = useState([]);
  const debouncedSearchTerm = useDebounce(searchTerm, 300);

  const requestData = useCallback(
    async (callback = () => {}) => {
      const resp = await retryableAPICall(() => getWorkflows());

      if (isMounted()) {
        if (typeof resp === 'string') {
          setTableRejected();
          callback(resp);
        } else {
          setOriginalData(resp);
          setTableData({
            data: resp,
            totalResults: resp.length,
          });

          callback();
        }
      }
    },
    [isMounted, setTableData, setTableRejected],
  );

  // Filter data based on search term
  const filteredData = useMemo(() => {
    if (!debouncedSearchTerm) return originalData;

    return originalData.filter((workflow) => {
      const searchLower = debouncedSearchTerm.toLowerCase();
      const nameMatch = workflow.name?.toLowerCase().includes(searchLower);

      // Find trigger type label for searching
      const triggerObj = triggerTypes.find(({ value }) => value === workflow.type);
      const triggerLabel = triggerObj?.label || workflow.type || '';
      const triggerMatch = triggerLabel.toLowerCase().includes(searchLower);

      return nameMatch || triggerMatch;
    });
  }, [debouncedSearchTerm, originalData]);

  // Update table data when filtered data changes
  useEffect(() => {
    setTableData({
      data: filteredData,
      totalResults: filteredData.length,
    });
  }, [filteredData, setTableData]);

  useEffect(() => {
    requestData((errorStatus) => {
      if (errorStatus) {
        setPageRejected(errorStatus);
      } else {
        setPageResolved();
      }
    });
  }, [requestData, setPageRejected, setPageResolved]);

  useEffect(() => {
    setIsSliderOpen(isCreateMode);
    if (isCreateMode) setIsEditing(false);
  }, [isCreateMode]);

  function handleCloseSlider() {
    setIsSliderOpen(false);
    if (isCreateMode) onClose();
  }

  // ... existing columns definition ...

  const columns = [
    {
      Header: 'Workflow Name',
      id: 'actionName',
      resizable: false,
      sortable: false,
      filterable: false,
      // eslint-disable-next-line react/prop-types
      Cell: ({ row: { original } }) => {
        // eslint-disable-next-line react/prop-types
        const { name, accountId, readOnly } = original;

        if (checkPermissions(['admin:workflow:update']) && utils.isEditable(accountId, activeAccountId, readOnly)) {
          return (
            <a
              href="#workflowDetails"
              onClick={(e) => {
                e.preventDefault();
                setWorkflowObj(original);
                setIsEditing(true);
                setIsSliderOpen(true);
              }}
            >
              {name}
            </a>
          );
        }

        return name;
      },
    },
    {
      Header: 'Trigger Type',
      id: 'tiggerType',
      resizable: false,
      sortable: false,
      filterable: false,
      // eslint-disable-next-line react/prop-types
      Cell: ({ row: { original } }) => {
        // eslint-disable-next-line react/prop-types
        const { type } = original;
        const triggerObj = triggerTypes.find(({ value }) => value === type);
        if (triggerObj) return triggerObj.label;
        return '';
      },
    },
  ];

  if (totalAccounts > 1) {
    columns.push(
      {
        Header: 'EDITABLE',
        id: 'readOnly',
        width: 100,
        resizable: false,
        sortable: false,
        filterable: false,
        headerClassName: 'text-center',
        className: 'text-center',
        accessor: (r) => (r.readOnly ? 'No' : 'Yes'),
      },
      {
        Header: 'SHARED WITH',
        id: 'share',
        width: 200,
        resizable: false,
        sortable: false,
        filterable: false,
        // eslint-disable-next-line react/prop-types
        Cell: ({ row: { original } }) => {
          // eslint-disable-next-line react/prop-types
          let { sharedWith = [] } = original;

          // eslint-disable-next-line react/prop-types
          sharedWith = sharedWith.filter(({ accountId }) => accountId !== activeAccountId);

          if (!sharedWith.length) return null;

          if (sharedWith.length === totalAccounts - 1) {
            return 'All';
          }

          const accountNames = sharedWith.map(({ accountName }) => accountName).join(', ');

          return <TippyTruncate>{accountNames}</TippyTruncate>;
        },
      },
    );
  }

  if (checkPermissions(['admin:workflow:update', 'admin:workflow:delete'])) {
    columns.push({
      id: 'action',
      resizable: false,
      sortable: false,
      filterable: false,
      width: 63,
      className: 'action-cell',
      // eslint-disable-next-line react/prop-types
      Cell: ({ row: { original } }) => {
        // eslint-disable-next-line react/prop-types
        const {
          // eslint-disable-next-line react/prop-types
          id,
          // eslint-disable-next-line react/prop-types
          name,
          // eslint-disable-next-line react/prop-types
          readOnly,
          // eslint-disable-next-line react/prop-types
          accountId,
        } = original;

        // eslint-disable-next-line react/prop-types
        const editable = utils.isEditable(accountId, activeAccountId, readOnly);
        // eslint-disable-next-line react/prop-types
        const deletable = utils.isDeletable(accountId, activeAccountId);

        const menuItems = [
          {
            id: 0,
            label: 'Edit',
            icon: 'Pencil',
            permissions: ['admin:workflow:update'],
            action: () => {
              setWorkflowObj(original);
              setIsEditing(true);
              setIsSliderOpen(true);
              setActionMenuId('');
            },
          },
          {
            id: 1,
            label: 'Delete',
            icon: 'Bin',
            permissions: ['admin:workflow:delete'],
            isDisabled: !deletable,
            tooltipContent: 'Only the owner can delete this',
            action: () => {
              setDeleteObj({ id, name });
              setActionMenuId('');
              setShowConfirm(true);
            },
          },
        ];

        return (
          <ActionButton
            isDisabled={!editable}
            isOpen={actionMenuId === id}
            menuItems={menuItems}
            menuStyle={{ width: '150px' }}
            onToggle={(isOpen) => setActionMenuId(isOpen ? id : '')}
            position="left"
            subMenuProps={{
              menuStyle: { width: '100px' },
              position: 'left',
            }}
            title="Action"
            tooltipContent="Only the owner can edit this"
          />
        );
      },
    });
  }

  return (
    <>
      <PageColumn className={cx({ 'h-scroll-table': tableState.data.length })} state={pageState}>
        <EnhancedCard>
          <EnhancedCardTitle title="Automated Workflows" subtitle="Create and update your automated workflows">
            <RestrictedCreateButton
              action={() => setIsCreateMode(true)}
              floatRight={false}
              label="Create a new Workflow"
              permissions={['admin:workflow:create']}
              className="mb-auto"
            />
          </EnhancedCardTitle>

          <div className="d-flex align-items-center gap-3 mb-4">
            <SearchInput
              value={searchTerm}
              className="w-100"
              type="text"
              placeholder="Search workflows by name or trigger type"
              onChange={(e) => setSearchTerm(e)}
            />
          </div>

          {!tableState.data.length ? (
            <ClientAdminNoDataMessage
              title={searchTerm ? 'No workflows match your search' : 'You currently have no Workflows set up'}
              message={searchTerm ? 'Try adjusting your search terms' : ''}
              btnProps={
                !searchTerm
                  ? {
                      onClick: () => setIsSliderOpen(true),
                      label: 'Create Automated Workflow',
                    }
                  : {}
              }
            />
          ) : (
            <StyledTable
              className="mt-3"
              data={tableState.data}
              errorText="There has been an error loading Workflows, please try again later"
              hasSelectColumn={false}
              id="workflows"
              isLoading={tableState.isLoading}
              isRejected={tableState.isRejected}
              noDataText="You currently have no Workflows"
              pageSize={tableState.rowsPerPage}
              showPagination={false}
              columns={columns}
              totalResults={tableState.totalResults}
            />
          )}
        </EnhancedCard>
      </PageColumn>

      <ClientAdminSlider
        closeSlider={handleCloseSlider}
        isSliderOpen={isSliderOpen}
        title={`${isEditing ? 'Edit' : 'Create'} Workflow`}
      >
        <WorkflowEditor
          data={isEditing ? workflowObj : {}}
          isEditing={isEditing}
          onCancel={handleCloseSlider}
          onSave={(wrkflwObj) => {
            handleCloseSlider();

            const updatedArr = isEditing
              ? updateObjInArray(originalData, wrkflwObj, wrkflwObj.id)
              : addObjToArray(originalData, wrkflwObj);

            setOriginalData(updatedArr);
            setTableData({
              data: updatedArr,
              totalResults: updatedArr.length,
            });
          }}
        />
      </ClientAdminSlider>
      <Confirmation
        cancelCallback={() => {
          setShowConfirm(false);
          setDeleteObj();
        }}
        confirmCallback={() => {
          deleteAction(
            deleteObj.id,
            () => {
              toast.success('Workflow successfully deleted');

              const updatedTableData = deleteObjFromArray(originalData, deleteObj.id);
              setOriginalData(updatedTableData);
              setTableData({
                data: updatedTableData,
                totalResults: updatedTableData.length,
              });
            },
            () => {
              toast.error('Error deleting workflow');
            },
          );

          setShowConfirm(false);
        }}
        content={`Are you sure you want to delete ${(deleteObj || {}).name || ''} workflow?`}
        show={showConfirm}
      />
    </>
  );
}

Workflows.propTypes = {
  isCreateMode: PropTypes.bool,
  onClose: PropTypes.func,
  totalAccounts: PropTypes.number,
  activeAccountId: PropTypes.string,
  tablePrefs: PropTypes.shape({
    pageSize: PropTypes.number,
  }),
};

Workflows.defaultProps = {
  isCreateMode: false,
  totalAccounts: 0,
  activeAccountId: null,
  onClose: () => {},
  tablePrefs: {
    pageSize: 25,
  },
};

function mapStateToProps(state) {
  const {
    tablePrefs,
    userData: {
      userDetails: {
        data: { accountAccess = [] },
        activeAccountId,
      },
    },
  } = state;

  return {
    tablePrefs,
    totalAccounts: accountAccess.length,
    activeAccountId,
  };
}

export default connect(mapStateToProps)(Workflows);
