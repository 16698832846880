/* eslint-disable jsx-a11y/label-has-for */
import React, { useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Form, Button } from 'reactstrap';
import SMSBodyInput from './SMSBodyInput';
import { TextButton } from '@Base/Buttons';
import { retryableAPICall } from '@API/common-api-utils';
import { sendSms } from '@API/CandidateAPI/CandidateAPI';
import validation from '@JS/utils/validation';
import { getArrayOfObjectValuesBy } from '@JS/utils/general-utils';
import { TagPill as Tag } from '@Base/Forms/Custom/EmailPills';
import { smsForm } from '@JS/language/pages';
import { useLanguage } from '@Base/hooks';

function GroupSMSToggle({ applicants, languagePack }) {
  const [showNames, setShowNames] = useState(false);

  const phoneNumberCount = applicants.filter(({ applicantPhone }) => typeof applicantPhone !== 'string');
  const warningText = phoneNumberCount.length
    ? ` (${phoneNumberCount.length} ${languagePack.doNotHaveAPhoneNumber})`
    : '';

  return (
    <Fragment>
      <p className="mb-2">{`${applicants.length} ${languagePack.applicantsSelected}${warningText}`}</p>
      <Button size="sm" className="mb-3 btn-outline" onClick={() => setShowNames(!showNames)}>
        {`${!showNames ? languagePack.view : languagePack.hide} ${languagePack.applicants}`}
      </Button>
      {showNames && (
        <div className="tag-wrapper mb-3">
          {applicants.map(({ applicantId, applicantName, applicantPhone }, i) => (
            <Tag key={applicantId} index={i} showRemove={false} isError={typeof applicantPhone !== 'string'}>
              {applicantName}
            </Tag>
          ))}
        </div>
      )}
    </Fragment>
  );
}

GroupSMSToggle.propTypes = {
  applicants: PropTypes.arrayOf(
    PropTypes.shape({
      applicantId: PropTypes.string.isRequired,
      applicantName: PropTypes.string,
      applicantPhone: PropTypes.string,
    }),
  ).isRequired,
  languagePack: PropTypes.shape(),
};

GroupSMSToggle.defaultProps = {
  languagePack: {},
};

function SMSForm({ applicants, onSuccess, onError, data, onChange, isGroupSms }) {
  const { langPack: languagePack } = useLanguage(smsForm);
  const [sending, setSending] = useState(false);
  const [errors, setErrors] = useState({});
  const [message, setMessage] = useState(data);
  const [submitted, setSubmitted] = useState(false);
  const messageConfig = { id: 'message', required: true };
  const limit = 250;

  function handleChange(val, id) {
    if (submitted) {
      const inpErrs = validation([messageConfig], { [id]: val });
      setErrors({ ...errors, ...inpErrs });
    }

    setMessage(val);
    onChange(val);
  }

  async function handleSend(errCount, convertedMessage) {
    if (errCount > 0) return;

    setSending(true);

    const applicantIds = getArrayOfObjectValuesBy('applicantId', applicants);

    if (!applicantIds.length) {
      onError(languagePack.findError);
      return;
    }

    const response = await retryableAPICall(() => sendSms(convertedMessage, applicantIds));

    if (Array.isArray(response)) {
      const smsErrors = response.reduce(
        (acc, { applicationId: appId, status, errorReason, applicantFirstName, applicantSurname }) => {
          if (status === 'CREATE_ERROR') {
            acc.push({
              appId,
              errorReason,
              applicantName: `${applicantFirstName} ${applicantSurname}`,
            });
          }
          return acc;
        },
        [],
      );

      if (smsErrors.length) {
        // eslint-disable-next-line max-len
        onError(`${languagePack.smsUserError} ${smsErrors.map((err) => err.applicantName).join(', ')}`);
      } else {
        onSuccess(languagePack.sendSuccess, { ...response, body: message });
        setMessage('');
        onChange('');
      }
    } else {
      onError(languagePack.sendError);
    }

    setSending(false);
  }

  function handleSubmit() {
    setSubmitted(true);

    const errObj = validation([messageConfig], { message });
    setErrors(errObj);

    const errCount = Object.values(errObj).filter(({ invalid }) => invalid).length;

    handleSend(
      errCount,
      message
        .replace(/<[^>]*>?/gm, '')
        .replace(/\uFEFF/g, '')
        .replace(/&amp;/g, '&'), // if there is url given in the string it needs to be converted back to &
    );
  }

  const bodyInvalid = 'message' in errors && errors.message.invalid;
  const bodyErrorMsg = bodyInvalid ? errors.message.errors[0] : '';

  if (!isGroupSms && applicants.length === 1 && !applicants[0].applicantPhone) {
    return <p className="text-center">{languagePack.phoneNumbersNotFound}</p>;
  }

  return (
    <div>
      <Form
        className="comms-sms-form clearfix"
        noValidate
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit();
        }}
      >
        {isGroupSms && <GroupSMSToggle applicants={applicants} languagePack={languagePack} />}
        <SMSBodyInput
          isLoading={sending}
          limit={limit}
          value={message}
          invalid={bodyInvalid}
          errorMsg={bodyErrorMsg}
          onChange={(val) => handleChange(val.replace(/<[^>]*>?/gm, ''), messageConfig.id)}
        />
        <TextButton
          isLoading={sending}
          disabled={sending || message.replace(/<[^>]*>?/gm, '').trim().length === 0}
          label={sending ? languagePack.buttonSending || 'Sending...' : languagePack.buttonStatic || 'Send SMS'}
          type="submit"
          floatRight
        />
      </Form>
    </div>
  );
}

SMSForm.propTypes = {
  applicants: PropTypes.arrayOf(
    PropTypes.shape({
      applicantId: PropTypes.string.isRequired,
      applicantPhone: PropTypes.string,
    }),
  ).isRequired,
  onSuccess: PropTypes.func,
  onError: PropTypes.func,
  data: PropTypes.string,
  onChange: PropTypes.func,
  isGroupSms: PropTypes.bool,
};

SMSForm.defaultProps = {
  onSuccess: () => {},
  onError: () => {},
  data: '',
  onChange: () => {},
  isGroupSms: false,
};

export default SMSForm;
