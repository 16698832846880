// eslint-disable-next-line no-unused-vars
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import Select from 'react-select';
// eslint-disable-next-line import/no-cycle
import { useMounted } from '../../hooks';
import { retryableAPICall } from '@API/common-api-utils';
import { findUsersForAccount } from '@API/AccountAPI';

function AssignedToFilter({ onSelect }) {
  const isMounted = useMounted();
  const [optionData, setOptionData] = useState([]);

  async function getUserList() {
    const users = await retryableAPICall(() => findUsersForAccount(null, 'all'));

    if (typeof users !== 'string') {
      const valMap = users.map(({ id, name }) => ({
        label: name,
        value: id,
      }));
      setOptionData(valMap.sort((a, b) => a.label.localeCompare(b.label)));
    } else {
      toast.error('Could not load users so unable to filter on assigned user. Please try again later.');
    }
  }
  useEffect(() => {
    if (isMounted()) {
      getUserList();
    }
  }, [isMounted]);

  const customStyles = {
    menu: (styles) => ({
      ...styles,
      backgroundColor: '#fff',
      width: '500px',
    }),
  };
  return (
    <Select
      autoSize
      classNamePrefix="react-select"
      id="filter-question-select"
      isClearable
      menuPosition="fixed"
      onChange={(val) => {
        onSelect(val);
      }}
      options={optionData}
      placeholder="Search Users"
      styles={customStyles}
    />
  );
}

AssignedToFilter.propTypes = {
  onSelect: PropTypes.func,
};

AssignedToFilter.defaultProps = {
  onSelect: () => {},
};

export default AssignedToFilter;
