import React, { useState, useEffect } from 'react';
import { ChevronDown } from 'lucide-react';
import Cookies from 'universal-cookie';

const cookies = new Cookies();

/**
 * A reusable expandable section component
 * @param {Object} props Component props
 * @param {React.ReactNode} props.children Content to show when expanded
 * @param {string} props.sectionId Unique identifier for the section (used for cookies)
 * @param {string} props.title Section title
 * @param {React.ReactNode} props.icon Optional icon to show next to title
 * @param {React.ReactNode} props.meta Optional metadata to show on the right side
 * @param {boolean} props.defaultExpanded Whether section should be expanded by default
 * @param {boolean} props.hideWhenEmpty Whether to hide the section when there's no content
 */
const ExpandableSection = ({
                             children,
                             sectionId,
                             title,
                             icon,
                             meta,
                             defaultExpanded = false,
                             hideWhenEmpty = false
                           }) => {
  const [isExpanded, setIsExpanded] = useState(defaultExpanded);

  // Don't render if we should hide when empty and there are no children
  if (hideWhenEmpty && !children) {
    return null;
  }

  // Load expanded state from cookie on component mount
  useEffect(() => {
    const savedState = cookies.get(`section-expanded-${sectionId}`);
    if (savedState !== undefined) {
      setIsExpanded(savedState === 'true');
    }
  }, [sectionId]);

  // Save expanded state to cookie when changed
  useEffect(() => {
    cookies.set(`section-expanded-${sectionId}`, isExpanded.toString());
  }, [isExpanded, sectionId]);

  return (
    <div className="tw-mt-4 tw-border-t tw-border-black/5 tw-pt-3">
      <div
        className="tw-flex tw-items-center tw-justify-between tw-cursor-pointer tw-p-2
                   tw-rounded-md tw-transition-all tw-duration-200 tw-bg-gray-100/50
                   hover:tw-bg-gray-100/90 tw-select-none"
        onClick={() => setIsExpanded(!isExpanded)}
      >
        <div className="tw-flex tw-items-center tw-gap-1.5">
          {icon && <span className="tw-text-blue-500 tw-flex tw-items-center">{icon}</span>}
          <span className="tw-text-gray-900 tw-text-sm tw-font-semibold">{title}</span>
        </div>

        <div className="tw-flex tw-items-center tw-gap-2">
          {meta && <div className="tw-text-gray-500 tw-text-xs tw-flex tw-items-center tw-gap-1">{meta}</div>}
          <div className="tw-w-6 tw-h-6 tw-flex tw-items-center tw-justify-center
                          tw-text-blue-500 tw-rounded-full tw-transition-all tw-duration-200
                          group-hover:tw-bg-blue-500/10">
            <ChevronDown
              size={16}
              className={`tw-transition-transform tw-duration-200 ${isExpanded ? 'tw-rotate-180' : ''}`}
            />
          </div>
        </div>
      </div>

      {isExpanded && (
        <div className="tw-py-3 tw-px-0 tw-animate-[fadeContentIn_0.2s_ease]"
             style={{
               animation: 'fadeContentIn 0.2s ease'
             }}>
          {children}
        </div>
      )}

      {/* Add keyframes for the animation */}
      <style jsx>{`
        @keyframes fadeContentIn {
          from { opacity: 0; transform: translateY(-3px); }
          to { opacity: 1; transform: translateY(0); }
        }
      `}</style>
    </div>
  );
};

export default ExpandableSection;
