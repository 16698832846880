import React from 'react';
import PropTypes from 'prop-types';
import { updateArr } from '@JS/utils/arrayOfObjects';
import WorkflowAction, { defaultAction } from './WorkflowAction';
import { CreateButton } from '@Base/Buttons';

function WorkflowActions({ data, onChange, errors, maxActions }) {
  if (!data.type || !data.actions.length) return null;

  function handleChange(i, action) {
    const updated = updateArr(data.actions, i, { ...action, order: i });
    onChange(updated);
  }

  function handleAddAction() {
    const updated = [...data.actions, { ...defaultAction, order: data.actions.length }];
    onChange(updated);
  }

  function handleRemoveAction(idx) {
    const dupe = [...data.actions];
    dupe.splice(idx, 1);
    onChange(dupe);
  }

  return (
    <div className="workflow-actions">
      {data.actions.map((action, i) => (
        <WorkflowAction
          // eslint-disable-next-line react/no-array-index-key
          key={`action-${i}`}
          id={`action-${i}`}
          workflowType={data.type}
          data={action}
          onChange={(val) => handleChange(i, val)}
          errors={errors[i] || {}}
          canDelete={data.actions.length > 1}
          onDelete={() => handleRemoveAction(i)}
        />
      ))}
      {maxActions > data.actions.length && (
        <CreateButton label="Add Action" floatRight={false} size="sm" action={handleAddAction} />
      )}
    </div>
  );
}

WorkflowActions.propTypes = {
  data: PropTypes.shape(),
  onChange: PropTypes.func,
  errors: PropTypes.arrayOf(PropTypes.shape()),
  maxActions: PropTypes.number,
};

WorkflowActions.defaultProps = {
  data: {},
  onChange: () => {},
  errors: [],
  maxActions: 1,
};

export default WorkflowActions;
