import axios from 'axios';
import { getCommonHeaders } from '../common-api-utils';
import config from '../../config/config';

const {
  api,
  integrations: { fourthV2 },
} = config;

export async function submitToFourth(data = {}, applicantId) {
  if (Object.keys(data).length) {
    const result = await axios.post(
      `${api.middlewareAPIURL}${fourthV2.submitPath}/${applicantId}`,
      { dataFields: { ...data } },
      { headers: getCommonHeaders() },
    );
    if (result.status === 201 || result.status === 200) return result.data;
  }
  return null;
}

export async function getFourthSubmissionData(candidateId) {
  if (candidateId) {
    const result = await axios.get(`${api.middlewareAPIURL}${fourthV2.submitPath}/${candidateId}`, {
      headers: getCommonHeaders(),
    });
    if (result.status === 201 || result.status === 200) return result.data;
  }
  return null;
}
