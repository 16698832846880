import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { Col, Row } from 'reactstrap';
import { ActionButton } from '@Base/Buttons';
import ActionToBePerformedNoDataState from '../Other/ActionToBePerformedNoDataState';
import waiting from '../../../assets/img/nodata/waiting.png';

function InterviewerAIRequestedContainer({ status, interviewLink, languagePack }) {
  const [actionBtnOpen, setActionButtonOpen] = useState(false);

  const menuItems = [
    // TODO: Add reminder functionality
    // {
    //   action: async () => {
    //     sendReminder();
    //     setActionButtonOpen(false);
    //   },
    //   icon: 'Paper Plane',
    //   id: 2,
    //   isDisabled: downloadingPDF,
    //   label: reminderSending
    //     ? languagePack.rtwSendReminderSendingBtnLabel
    //     : rtwStatus === 'REQUESTED'
    //       ? languagePack.rtwSendReminderBtnLabel
    //       : languagePack.rtwSendFinalReminderBtnLabel,
    //   permissions: ['rtw:create'],
    //   isHidden: /^REMINDER_2_SENT/.test(rtwStatus),
    // },
    {
      action: () => {
        window.open(`${interviewLink}`);
        setActionButtonOpen(false);
      },
      icon: 'Pencil',
      id: 1,
      label: 'Go to InterviewerAI Interview',
      permissions: ['iai:read'],
      tooltipContent: 'You are unable to edit records that are over 12 months old',
    },
    {
      action: () => {
        navigator.clipboard.writeText(interviewLink).then(
          () => {
            toast.success(`${languagePack.interviewerAIURL} ${languagePack.copiedToClipboard}`);
          },
          () => {
            toast.error(languagePack.copyToClipboardError);
          },
        );
        setActionButtonOpen(false);
      },
      icon: 'Paper Clip',
      id: 2,
      isDisabled: false,
      label: `Copy Interview URL to clipboard`,
    },
  ];

  let reminderText;

  if (status === 'REMINDER_1_SENT') {
    reminderText = 'First Reminder Sent';
  } else if (status === 'REMINDER_2_SENT') {
    reminderText = 'Final Reminder Sent';
  }

  return (
    <Row>
      <Col>
        <ActionToBePerformedNoDataState
          image={waiting}
          title="InterviewerAI Interview Requested"
          subTitle={reminderText}
          description="An InterviewerAI interview has been requested and we are waiting for the candidate to complete it."
          button={
            interviewLink &&
              <ActionButton
                buttonLabel={languagePack.actionBtnLabel || 'Actions'}
                className="ellipsis-opts-btn"
                iconName="Ellipsis"
                iconOnly={false}
                isOpen={actionBtnOpen}
                menuItems={menuItems}
                onToggle={(isOpen) => {
                  setActionButtonOpen(isOpen);
                }}
                title="Actions"
                tooltipContent={languagePack.tooltipContent || 'Perform actions'}
            />
        }
      />
      </Col>
    </Row>
  );
}

InterviewerAIRequestedContainer.propTypes = {
  status: PropTypes.string,
  languagePack: PropTypes.shape(),
  interviewLink: PropTypes.string,
};

InterviewerAIRequestedContainer.defaultProps = {
  languagePack: {},
  interviewLink: '',
  status: 'NOT_REQUESTED',
};

export default InterviewerAIRequestedContainer;
