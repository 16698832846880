import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap';
import { AccountSelectorModal } from '@Base/Account';
import { ResolveLogo } from '@Base/SVG';

function AccountDropdown({ allowAccountSwitch, companyLogo }) {
  const [accountSwitchOpen, setAccountSwitchOpen] = useState(false);

  return (
    <>
      <UncontrolledDropdown className="account-name-dropdown">
        <DropdownToggle className="logo-link" nav>
          <ResolveLogo imgSrc={companyLogo} imgWidth="40" svgWidth="2em" />
        </DropdownToggle>
        {allowAccountSwitch && (
          <DropdownMenu
            modifiers={[
              {
                name: 'flip',
                options: {
                  fallbackPlacements: ['top'],
                },
              },
              {
                name: 'offset',
                options: {
                  offset: [0, 0],
                },
              },
            ]}
          >
            <DropdownItem
              onClick={() => {
                setAccountSwitchOpen(true);
              }}
            >
              <i className="fa fa-user" />
              Switch Account
            </DropdownItem>
          </DropdownMenu>
        )}
      </UncontrolledDropdown>
      <AccountSelectorModal isOpen={accountSwitchOpen} onToggle={() => setAccountSwitchOpen(false)} />
    </>
  );
}

AccountDropdown.propTypes = {
  allowAccountSwitch: PropTypes.bool,
  companyLogo: PropTypes.string,
};

AccountDropdown.defaultProps = {
  allowAccountSwitch: false,
  companyLogo: null,
};

function mapStateToProps(state) {
  const {
    userData: {
      userDetails: {
        data: { accountAccess = [] },
      },
    },
    companyData: {
      companyDetails: { companyLogo },
    },
    styles,
  } = state;
  const { logoUrl } = styles;

  return {
    allowAccountSwitch: accountAccess.length > 1,
    companyLogo: logoUrl || companyLogo,
  };
}

export default connect(mapStateToProps)(AccountDropdown);
