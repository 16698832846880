import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { ModalPopup } from '@Base/Modal';
import ConfirmRejection from './ConfirmRejection';

function RejectionModal({ isOpen, title, applicants, rejectionReasons, onClose, type, onOkay }) {
  const [sendingEmail, setSendingEmail] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [rejection, setRejection] = useState(type);
  const [emailData, setEmailData] = useState();
  const [isEmailDataValid, setIsEmailDataValid] = useState(false);
  const [sendEmail, setSendEmail] = useState(false);

  function reset() {
    setSubmitted(false);
    setRejection();
    setEmailData();
    setIsEmailDataValid(false);
    setSendEmail(false);
    setSendingEmail(false);
  }

  return (
    <ModalPopup
      isOpen={isOpen}
      title={title}
      closeOnOkay={false}
      onToggle={() => {
        onClose();
        reset();
      }}
      okayLabel={sendingEmail ? 'Rejecting...' : 'Reject'}
      onOkay={() => {
        setSubmitted(true);
        // no rejection reason nothing happens
        if (!rejection) return;

        if (sendEmail) {
          // if form enabled make sure there is valid data
          if (!isEmailDataValid) return;
          setSendingEmail(true);
        }

        onOkay(rejection, emailData, reset);
      }}
      footer={({ OkayButton, CancelButton }) => (
        <Fragment>
          <CancelButton />
          <OkayButton disabled={sendingEmail} />
        </Fragment>
      )}
    >
      <ConfirmRejection
        applicants={applicants}
        onChange={(value, emailEnabled, emailObj, isEmailValid) => {
          setRejection(value);
          setEmailData(emailObj);
          setIsEmailDataValid(isEmailValid);
          setSendEmail(emailEnabled);
        }}
        rejectionReasons={rejectionReasons}
        confirmSubmit={submitted}
        invalidReason={!rejection && submitted}
        invalidEmail={!isEmailDataValid && submitted}
      />
    </ModalPopup>
  );
}

RejectionModal.propTypes = {
  isOpen: PropTypes.bool,
  title: PropTypes.string,
  applicants: PropTypes.arrayOf(
    PropTypes.shape({
      applicantId: PropTypes.string,
      applicantEmail: PropTypes.string,
      applicantName: PropTypes.string,
    }),
  ),
  rejectionReasons: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    }),
  ),
  onClose: PropTypes.func,
  reason: PropTypes.string,
  type: PropTypes.string,
  onOkay: PropTypes.func,
};

RejectionModal.defaultProps = {
  isOpen: false,
  title: 'Reason For Rejection',
  applicants: [],
  rejectionReasons: [],
  reason: null,
  type: null,
  onClose: () => {},
  onOkay: () => {},
};

export default RejectionModal;
