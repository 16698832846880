import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { CardBody, Card } from 'reactstrap';
import Select from 'react-select';
import { LoadingScreen } from '@Base/Loading';

function TimelineContainer({ loading, error, errorMessage, className, title, children, types, onTypeChange }) {
  const [selectedType, setSelectedType] = useState(null);
  return (
    <Card className={className}>
      <CardBody>
        {error ? (
          <h5 className="text-danger">{errorMessage}</h5>
        ) : (
          <>
            {loading ? (
              <LoadingScreen notFixed />
            ) : (
              <>
                <div className="clearfix clearboth" style={{ display: 'block' }}>
                  {title && (
                    <div style={{ display: 'inline-block', maxWidth: '220px' }}>
                      <h4>{title}</h4>
                    </div>
                  )}
                  {types && types.length > 0 && (
                    <div style={{ display: 'inline-block', float: 'right', maxWidth: '63%', minWidth: '260px' }}>
                      <Select
                        classNamePrefix="react-select"
                        id="filter-question-select"
                        isClearable
                        isMulti
                        onChange={(vals) => {
                          setSelectedType(vals);
                          onTypeChange(vals?.map((val) => val.value) || null);
                        }}
                        options={[...new Set(types)]
                          .map((type) => ({
                            label: type
                              .replaceAll('_', ' ')
                              .toLowerCase()
                              .split(' ')
                              .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                              .join(' '),
                            value: type,
                          }))
                          .sort((a, b) => (a.label > b.label ? 1 : -1))}
                        placeholder="Filter Event Type"
                        value={selectedType}
                      />
                    </div>
                  )}
                </div>
                <div className={`${className}-timeline timeline-wrapper`}>{children}</div>
              </>
            )}
          </>
        )}
      </CardBody>
    </Card>
  );
}

TimelineContainer.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  className: PropTypes.string,
  error: PropTypes.bool,
  errorMessage: PropTypes.string,
  loading: PropTypes.bool,
  onTypeChange: PropTypes.func,
  title: PropTypes.string,
  types: PropTypes.arrayOf(PropTypes.string),
};

TimelineContainer.defaultProps = {
  className: null,
  error: false,
  errorMessage: null,
  loading: false,
  onTypeChange: () => {},
  title: null,
  types: [],
};

export default TimelineContainer;
