import { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Input } from 'reactstrap';
import Tippy from '@tippyjs/react';
import { connect } from 'react-redux';
import isEmpty from 'lodash.isempty';
import { retryableAPICall } from '@API/common-api-utils';
import { getFunnelForVacancy } from '@API/CandidateAPI/CandidateAPI';
import { deleteObjFromArray, addObjToArray } from '@JS/utils/arrayOfObjects';
import { Loader } from '../../Loading';
import { useLanguagePack, useMounted } from '../../hooks';
import { searchCandidates } from '@API/SearchAPI/SearchAPI';

function aggregateItems(items) {
  return Object.values(
    items.reduce((acc, item) => {
      const normalizedName = item.name.trim().toLowerCase();

      if (acc[normalizedName]) {
        acc[normalizedName].stageIds.push(item.stageId);
      } else {
        acc[normalizedName] = {
          ...item,
          stageIds: [item.stageId],
        };
      }

      return acc;
    }, {}),
  );
}

function getCountsByStageName(stages, aggregations) {
  return stages.reduce((acc, currentStage) => {
    const currentAgg = aggregations.filter(
      (agg) => agg.field.trim().toLowerCase() === currentStage.name.trim().toLowerCase(),
    );

    const count = currentAgg.reduce((accs, agg) => {
      return accs + agg.count;
    }, 0);

    acc[currentStage.name] = count;
    return acc;
  }, {});
}

export async function getFunnelStages(
  funnelId,
  vacancyId,
  globalFunnelStages,
  resultAggs,
  onComplete = () => {},
  onError = () => {},
) {
  let resp;
  let stages = [];

  if (funnelId && vacancyId) {
    resp = await retryableAPICall(() => getFunnelForVacancy(funnelId, vacancyId));

    if (typeof resp === 'string') {
      onError();
    } else {
      stages = resp.stages.map((stage) => {
        let count = 0;
        const foundStage = resultAggs?.find((agg) => agg?.subAggs[0] && agg?.subAggs[0]?.key === stage?.stageId);

        if (foundStage) {
          count = foundStage.count;
        }

        return {
          ...stage,
          numApplicants: count,
        };
      });
    }
  } else {
    stages = globalFunnelStages;
  }

  onComplete(aggregateItems(stages));
}

function FunnelList({
  funnelId,
  isSelectDropdown,
  stageIds,
  onChange,
  vacancyId,
  updateFilters,
  onFilterUpdate,
  globalFunnelStages,
  resultAggs,
}) {
  const isMounted = useMounted();
  const langPack = useLanguagePack('filters');
  const [funnelStages, setFunnelStages] = useState([]);
  const [countsByStageName, setCountsByStageName] = useState({});
  const [selectedStages, setSelectedStages] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);

  const isShowingFunnelsForVacancy = funnelId && vacancyId;

  const fetchData = useCallback(
    (callback = () => {}) => {
      getFunnelStages(
        funnelId,
        vacancyId,
        globalFunnelStages,
        resultAggs,
        (stageArr) => {
          setFunnelStages(stageArr);
          callback();
        },
        () => setIsError(true),
      );
    },
    [funnelId, vacancyId],
  );

  useEffect(() => {
    getFunnelStages(
      funnelId,
      vacancyId,
      globalFunnelStages,
      resultAggs,
      (stageArr) => {
        setFunnelStages(stageArr);
      },
      () => setIsError(true),
    );
  }, [resultAggs]);

  useEffect(() => {
    if (!isShowingFunnelsForVacancy) {
      const fetchCandidates = async () => {
        const candidates = await retryableAPICall(() =>
          searchCandidates(undefined, undefined, [], [], [], 0, 1, {
            mainField: 'funnelStageName',
            subFields: ['funnelStageId'],
          }),
        );

        if (!isEmpty(candidates.aggs)) {
          const unfilteredCountsByFilterName = getCountsByStageName(globalFunnelStages, candidates.aggs);
          setCountsByStageName(unfilteredCountsByFilterName);
        }
      };
      if (!isEmpty(resultAggs)) {
        const filteredCountsByStageName = getCountsByStageName(globalFunnelStages, resultAggs);
        setCountsByStageName(filteredCountsByStageName);
      } else {
        fetchCandidates();
      }
    }
  }, [resultAggs]);

  useEffect(() => {
    if (isMounted()) fetchData(() => setIsLoading(false));
  }, [fetchData, isMounted]);

  useEffect(() => {
    if (isMounted() && updateFilters) fetchData(() => onFilterUpdate());
  }, [fetchData, isMounted, onFilterUpdate, updateFilters]);

  useEffect(() => {
    if (isMounted()) {
      const selected = funnelStages.reduce((acc, { stageId, name }) => {
        if (stageIds.includes(stageId)) acc.push({ id: stageId, label: name });
        return acc;
      }, []);

      setSelectedStages(selected);
    }
  }, [funnelStages, isMounted, stageIds]);

  function handleChange(stage, checked) {
    let updatedStages = [];
    if (isSelectDropdown) {
      updatedStages = selectedStages;
      updatedStages = stage?.id?.length > 0 ? stage.id.map((stag) => ({ id: stag })) : [];
    } else if (!checked) {
      updatedStages = selectedStages;
      stage.ids.forEach((stag) => {
        updatedStages = deleteObjFromArray(updatedStages, stag);
      });
    } else {
      updatedStages = selectedStages;
      stage.ids.forEach((stag) => {
        updatedStages = addObjToArray(updatedStages, { id: stag, label: stage.label });
      });
    }

    setSelectedStages(updatedStages);
    onChange(updatedStages);
  }

  if (isLoading) return <Loader size="sm" />;

  if (!isLoading && isError) return <p className="mt-2 text-danger">{langPack.funnelFilterLoadError}</p>;

  if (isSelectDropdown) {
    return (
      <div className="funnel-filter-dropdown">
        <Input
          type="select"
          onChange={(e) => {
            const ids = funnelStages.find(({ stageId }) => stageId === e.target.value)?.stageIds;
            handleChange({ id: ids }, true);
          }}
          value={selectedStages[0]?.id}
        >
          <option value="">Funnel Stage</option>
          {funnelStages.map(({ stageId, name }) => (
            <option key={stageId} value={stageId}>
              {name}
            </option>
          ))}
        </Input>
      </div>
    );
  }
  return (
    <ul className="list-unstyled funnel-filter-list">
      {/* eslint-disable-next-line no-shadow */}
      {funnelStages.map(({ stageIds, name, numApplicants }) => {
        const isChecked = !!~selectedStages.findIndex(({ id }) => stageIds.includes(id));
        return (
          <li key={stageIds[0]} className={cx('funnel-filter-stage', { 'is-selected': isChecked })}>
            {/* eslint-disable-next-line jsx-a11y/label-has-for */}
            <label className="stage-inner" htmlFor={`funnel-filter-${stageIds[0]}`}>
              <Input
                checked={isChecked}
                className="standard-checkbox"
                id={`funnel-filter-${stageIds[0]}`}
                onChange={(e) => {
                  handleChange({ ids: stageIds, label: name }, e.target.checked);
                }}
                type="checkbox"
                value={stageIds[0]}
              />
              <Tippy content={name} maxWidth="200px" theme="ats">
                <span className="text-truncate">{name}</span>
              </Tippy>
              <div className="funnel-filter-app-count">
                <span>{isShowingFunnelsForVacancy ? numApplicants : countsByStageName[name]}</span>
              </div>
            </label>
          </li>
        );
      })}
    </ul>
  );
}

FunnelList.propTypes = {
  funnelId: PropTypes.string,
  globalFunnelStages: PropTypes.arrayOf(PropTypes.shape()),
  isSelectDropdown: PropTypes.bool,
  onChange: PropTypes.func,
  onFilterUpdate: PropTypes.func,
  resultAggs: PropTypes.arrayOf(PropTypes.shape()),
  stageIds: PropTypes.arrayOf(PropTypes.string),
  updateFilters: PropTypes.bool,
  vacancyId: PropTypes.string,
};

FunnelList.defaultProps = {
  funnelId: null,
  globalFunnelStages: [],
  isSelectDropdown: false,
  onChange: () => {},
  onFilterUpdate: () => {},
  resultAggs: [],
  stageIds: [],
  updateFilters: false,
  vacancyId: null,
};

function mapStateToProps(state) {
  const {
    funnelStages: { stages },
  } = state;
  return { globalFunnelStages: stages };
}

export default connect(mapStateToProps, null)(FunnelList);
