import React, { useEffect, useState } from 'react';
import { useNodes, useEdges } from '@xyflow/react';
import { Button, Input } from 'reactstrap';
import { toast } from 'react-toastify';
import isEqual from 'lodash.isequal';
import { connect } from 'react-redux';
import { createWorkflow, updateStatus, updateWorkflow, deleteWorkflow } from '@API/AdvancedWorkflowAPI'; // Import delete API
import { retryableAPICall } from '@API/common-api-utils';
import { requestStatuses } from '@JS/constants/requestStatuses';
import { Confirmation } from '@Base/Modal';
import SettingsModal from './DragAndDrop/Sliders/SettingsModal/SettingsModal';
import { convertWorkflowToServer, convertWorkflowFromServer } from './DragAndDrop/utils';
import validateWorkflow from './DragAndDrop/validation';
import ActiveDraftToggle from './DragAndDrop/Sliders/ActiveDraftToggle';
import styled from 'styled-components';
import { useHover } from './HoverContext';
import { ArrowLeft, Trash2, Save, Settings, CheckCircle, XCircle, Pencil } from 'lucide-react';

const BackButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.25rem 0.75rem;
  font-size: 0.8rem;
  background-color: white;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  color: #5285ef;
  cursor: pointer;

  &:hover {
    background-color: #f9f9f9;
    border-color: #d1d1d1;
  }
`;

const BackIcon = styled(ArrowLeft)`
  margin-right: 0.25rem;
`;

const StyledHeaderPanel = styled.div`
  padding: 0.5rem 1rem;
  background-color: white;
  border-bottom: 1px solid #e0e0e0;
`;

const DeleteButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem;
  background-color: white;
  border: 1px solid #f0e0e0;
  border-radius: 8px;
  cursor: pointer;

  &:hover {
    background-color: #ffe5e5;
    border-color: #ffcccc;
  }
`;

const TrashIcon = styled(Trash2)`
  color: #ff4f4f;
`;

const SaveIcon = styled(Save)`
  color: ${(props) => props.color || '#333'};
`;

const SettingsIcon = styled(Settings)`
  color: ${(props) => props.color || '#333'};
`;
const ValidationContainer = styled.div`
  position: relative;
  display: inline-block;
`;

const ValidationButton = styled.button`
  background: ${(props) => (props.isInvalid ? '#fff5f5' : '#f5f5f5')};
  border: ${(props) => (props.isInvalid ? '1px solid #ff4d4f' : '1px solid #dcdcdc')};
  padding: 0.4rem 0.8rem;
  border-radius: 8px;
  cursor: pointer;
  color: ${(props) => (props.isInvalid ? '#ff4d4f' : '#333')};
  font-weight: 500;
  font-size: 0.8rem;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;

  &:hover {
    background-color: ${(props) => (props.isInvalid ? '#fff0f0' : '#f0f0f0')};
    border-color: #ff4d4f;
  }

  &:disabled {
    pointer-events: none;
  }
`;

const DropdownMenu = styled.ul`
  position: absolute;
  top: 110%;
  left: 0;
  width: 305px;
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 0.4rem 0;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  list-style: none;
  z-index: 10;
  transition: all 0.3s ease;
`;

const EditableName = styled(Input)`
  font-size: 17.5px;

  color: #1a2e56;
  font-family: 'Open Sans', sans-serif;
  font-weight: 500;
  line-height: 1.2;
  margin-bottom: 0rem;

  height: auto;
  width: 300px;

  &:focus {
    outline: none;
    border-color: #5285ef;
  }
`;

const StyledNameWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  font-size: 0.8rem;
  background-color: white;
  cursor: pointer;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  border-radius: 8px;

  svg {
    cursor: pointer;
    color: transparent;
  }

  &:hover {
    background-color: #f9f9f9;
  }

  &:hover svg {
    color: #5285ef;
  }
`;
const DropdownItem = styled.li`
  padding: 0.4rem 1rem;
  cursor: pointer;
  color: #1a2e56;
  background-color: ${(props) => (props.isHovered ? '#f5f5f5' : 'white')};
  transition: background-color 0.2s ease;
  font-size: 0.75rem;
  font-weight: 500;

  &:hover {
    background-color: #ffe5e5; /* Highlight with soft red to indicate error */
    color: #ff4d4f;
  }
`;

const ValidationItem = styled.span`
  color: #1a2e56;
  font-size: 0.75rem;
  font-weight: 500;
`;

const Validation = ({ errors }) => {
  const [isOpen, setIsOpen] = useState(false);
  const { setHoveredId } = useHover();

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  return (
    <ValidationContainer>
      <ValidationButton isInvalid={errors.length !== 0} disabled={errors.length === 0} onClick={toggleDropdown}>
        {errors.length !== 0 ? (
          <div className="d-flex gap-1 ">
            Invalid
            <XCircle size={16} className="m-auto" />
          </div>
        ) : (
          <div className="d-flex gap-1 ">
            Valid
            <CheckCircle size={16} color="#4CAF50" className="m-auto" />
          </div>
        )}
      </ValidationButton>
      {isOpen && errors.length !== 0 ? (
        <DropdownMenu>
          {errors.map((item, index) => (
            <DropdownItem
              key={index}
              onMouseEnter={() => setHoveredId(item.id)}
              onMouseLeave={() => setHoveredId(null)}
            >
              <ValidationItem>{item.message}</ValidationItem>
            </DropdownItem>
          ))}
        </DropdownMenu>
      ) : null}
    </ValidationContainer>
  );
};

function isDiff(
  initialWorkflow,
  nodes,
  edges,
  name,
  activeAccountId,
  activeAccountName,
  sharedWith,
  failureRecipients,
) {
  const startNodes = nodes.map((nodes) => {
    return {
      data: nodes.data,
      id: nodes.id,
      measured: nodes.measured,
      position: nodes.position,
      type: nodes.type,
      selected: false,
    };
  });

  const allSharedWith = Array.from(
    new Map(
      [...(sharedWith || []), { accountId: activeAccountId, accountName: activeAccountName }]
        .filter(({ accountId }) => accountId)
        .map((item) => [item.accountId, item]),
    ).values(),
  );

  return !isEqual(
    {
      edges: initialWorkflow.edges,
      name: initialWorkflow.name,
      nodes: initialWorkflow.nodes,
      sharedWith: initialWorkflow?.sharedWith?.map(({ accountId }) => accountId),
      failureRecipients: initialWorkflow.failureRecipients,
    },
    {
      edges,
      name,
      nodes: startNodes,
      sharedWith: allSharedWith.map(({ accountId }) => accountId),
      failureRecipients,
    },
  );
}

function HeaderPanel({
  goBackToEdit,
  initialWorkflow,
  onSave,
  onDelete,
  setIsSaving,
  isSaving,
  isDeleting,
  setIsDeleting,
  activeAccountId,
  activeAccountName,
}) {
  const [isOptionsModalOpen, setIsOptionsModalOpen] = useState(false);
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const [name, setName] = useState(initialWorkflow.name);
  const [sharedWith, setSharedWith] = useState(initialWorkflow.sharedWith);
  const [exitConditions, setExitConditions] = useState(initialWorkflow.exitConditions);
  const [failureRecipients, setFailureRecipients] = useState(initialWorkflow.failureRecipients);
  const [isEditingName, setIsEditingName] = useState(false);

  const nodes = useNodes();
  const edges = useEdges();

  const isWFDiff = isDiff(
    initialWorkflow,
    nodes,
    edges,
    name,
    activeAccountId,
    activeAccountName,
    sharedWith,
    failureRecipients,
  );
  const errors = validateWorkflow(nodes, edges);

  useEffect(() => {
    setName(initialWorkflow.name);
    setSharedWith(initialWorkflow.sharedWith);
    setExitConditions(initialWorkflow.exitConditions);
    setFailureRecipients(initialWorkflow.failureRecipients);
    setIsEditingName(!initialWorkflow.id);
  }, [initialWorkflow]);

  const handleNameChange = (e) => {
    setName(e.target.value);
  };

  const handleNameDoubleClick = () => {
    setIsEditingName(true); // Enable name editing on double click
  };

  const handleNameBlur = () => {
    setIsEditingName(false);
  };

  async function handleSave() {
    setIsSaving(true);

    const allSharedWith = [
      ...(sharedWith || []),
      { accountId: activeAccountId, accountName: activeAccountName },
    ].filter(({ accountId }) => accountId);

    const convertedWorkflow = convertWorkflowToServer({
      nodes,
      edges,
      name,
      activeAccountId,
      sharedWith: allSharedWith,
      exitConditions,
      failureRecipients,
    });

    if (initialWorkflow.id) {
      try {
        convertedWorkflow.id = initialWorkflow.id;
        const resp = await retryableAPICall(() => updateWorkflow(convertedWorkflow));

        if (typeof resp === 'string') {
          let reasonStr = '';

          if (resp === requestStatuses.ALREADY_EXISTS_ERROR) {
            reasonStr = '. Unable to create workflow as this name has already been used';
          }

          toast.error(`Error updating workflow :${reasonStr}`);
        } else {
          try {
            const convertedWf = convertWorkflowFromServer(resp);
            if (convertedWf) {
              toast.success(`Workflow successfully updated`);
              onSave(convertedWf, initialWorkflow.id);
            } else {
              toast.error(`Error updating workflow`);
            }
          } catch (error) {
            console.error(error);
            toast.error(`Error updating workflow: ${error}`);
          }
        }
      } catch (error) {
        console.error(error);
        toast.error(`Error updating workflow: ${error}`);
      } finally {
        setIsSaving(false);
      }
    } else {
      try {
        const resp = await retryableAPICall(() => createWorkflow(convertedWorkflow));

        if (typeof resp === 'string') {
          if (resp === requestStatuses.ALREADY_EXISTS_ERROR) {
            toast.error(`Unable to create workflow as this name has already been used`);
          } else {
            toast.error(`Error creating workflow`);
          }
        } else {
          try {
            const convertedWf = convertWorkflowFromServer(resp);
            if (convertedWf) {
              toast.success(`Workflow successfully created`);
              onSave(convertedWf, resp.id, true);
            } else {
              toast.error(`Error creating workflow`);
            }
          } catch (error) {
            console.error(error);
            toast.error(`Error creating workflow and converting : ${error}`);
          }
        }
      } catch (error) {
        console.error(error);
        toast.error(`Error workflow genersl: ${error}`);
      } finally {
        setIsSaving(false);
      }
    }
  }

  async function onToggleDraft(status) {
    setIsSaving(true);

    try {
      const resp = await retryableAPICall(() => updateStatus(initialWorkflow.id, status));

      if (typeof resp === 'string') {
        let reasonStr = '';

        if (resp === requestStatuses.ALREADY_EXISTS_ERROR) {
          reasonStr = '. Unable to create workflow as this name has already been used';
        }

        toast.error(`Error saving workflow: ${reasonStr}`);
      } else {
        try {
          const convertedWf = {
            ...initialWorkflow,
            status: status,
          };
          if (convertedWf) {
            toast.success(`Workflow successfully updated`);
            onSave(convertedWf);
          } else {
            toast.error(`Error updating workflow.`);
          }
        } catch (error) {
          console.error(error);

          toast.error(`Error workflow here: ${error} `);
        }
      }
    } catch (error) {
      console.error(error);
      toast.error(`Error over hereworkflow`);
    } finally {
      setIsSaving(false);
    }
  }

  async function handleDelete() {
    setIsDeleting(true);

    try {
      const resp = await retryableAPICall(() => deleteWorkflow(initialWorkflow.id));

      if (resp?.status === 200) {
        toast.success('Workflow successfully deleted');
        onDelete(initialWorkflow.id);
      } else {
        toast.error('Error deleting workflow');
      }
    } catch (error) {
      console.error(error);
      toast.error('Error deleting workflow');
    } finally {
      setIsDeleting(false);
    }
  }

  return (
    <>
      <StyledHeaderPanel className="d-flex pb-3 justify-content-between w-100">
        <div className="d-flex gap-3" id="workflow-name">
          <BackButton onClick={() => goBackToEdit()} id="workflow-back-button">
            <BackIcon size={16} />
            Back
          </BackButton>
          {!isEditingName ? (
            <StyledNameWrapper>
              <h5
                style={{ marginTop: '5px', marginBottom: '5px', marginRight: '10px' }}
                onClick={handleNameDoubleClick}
                id="workflow-edit-name-text"
              >
                {name}
              </h5>
              <Pencil size={14} onClick={handleNameDoubleClick} />
            </StyledNameWrapper>
          ) : (
            <EditableName autoFocus value={name} onChange={handleNameChange} onBlur={handleNameBlur} />
          )}
        </div>
        <div className="d-flex gap-2">
          <Validation errors={errors} />
          <DeleteButton
            onClick={() => setIsOptionsModalOpen(true)}
            disabled={isSaving || isDeleting}
            id="setting-button"
          >
            <SettingsIcon size={16} />
          </DeleteButton>
          <DeleteButton
            onClick={() => setShowConfirmDelete(true)}
            disabled={isSaving || isDeleting || !initialWorkflow.id}
            id="delete-button"
          >
            <TrashIcon size={16} />
          </DeleteButton>
          <DeleteButton
            onClick={handleSave}
            disabled={isSaving || isDeleting || !isWFDiff || errors?.length > 0}
            id="save-button"
          >
            <SaveIcon size={16} />
          </DeleteButton>
          <ActiveDraftToggle
            isDisabled={isWFDiff || !initialWorkflow.id || errors?.length > 0 || isDeleting || isSaving}
            onChange={onToggleDraft}
            initialStatus={initialWorkflow.status}
          />
        </div>
      </StyledHeaderPanel>
      <Confirmation
        content="Are you sure you want to delete this workflow? This action cannot be undone."
        show={showConfirmDelete}
        cancelCallback={() => setShowConfirmDelete(false)}
        confirmCallback={() => {
          setShowConfirmDelete(false);
          handleDelete();
        }}
      />
      <SettingsModal
        isOpen={isOptionsModalOpen}
        toggle={() => {
          setIsOptionsModalOpen(!isOptionsModalOpen);
        }}
        onSave={(value) => {
          setIsOptionsModalOpen(false);
          setName(value.name);
          setSharedWith(value.sharedWith);
          setExitConditions(value.exitConditions);
          setFailureRecipients(value.failureRecipients);
        }}
        initialValues={{
          sharedWith: sharedWith,
          name: name,
          failureRecipients: failureRecipients,
        }}
      />
    </>
  );
}

function mapStateToProps(state) {
  const {
    userData: {
      userDetails: { activeAccountId, activeAccountName },
    },
  } = state;

  return {
    activeAccountId,
    activeAccountName,
  };
}

export default connect(mapStateToProps)(HeaderPanel);
