/* eslint-disable react/jsx-props-no-spreading */
import React, { Fragment, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import cx from 'classnames';
import EditInline from '@Base/Forms/Custom/EditInline/EditInline';
import FormField from './FormField';
import InlineRadios from './InlineRadios';
import { updateArray, updateObject } from '../utils';

const RADIOS = [
  { id: 'required', value: 'required', label: 'Required' },
  { id: 'optional', value: 'optional', label: 'Optional' },
  { id: 'disabled', value: 'disabled', label: 'Disabled' },
];

function setFieldStatus(fields, required = [], props = {}, notOpt = {}, optOnly = [], fieldNotes = {}) {
  return Object.entries(fields).reduce((acc, [key, val]) => {
    const isNotOptional = key in notOpt;
    const isOptOnly = optOnly.includes(key);

    let status = !(key in props) ? 'disabled' : 'optional';
    if (required.includes(key)) status = 'required';

    acc[key] = {
      ...val,
      status,
      isNotOpt: isNotOptional,
      optOnly: isOptOnly,
      hide: isNotOptional && !notOpt[key],
      notes: fieldNotes[key],
    };
    return acc;
  }, {});
}

function reorder(arr, startIdx, endIdx) {
  const copy = [...arr];
  const [removed] = copy.splice(startIdx, 1);
  copy.splice(endIdx, 0, removed);
  return copy;
}

function FormSectionEditor({
  title,
  sectionKey,
  sectionSchema,
  formFields,
  uiSchemaProps,
  notOptionalFields,
  optionalOnlyFields,
  sectionStatus,
  fieldNotes,
  onChange,
  onViewToggle,
  isDragDisabled,
  isEditInlineDisabled,
}) {
  const { type, items = {} } = sectionSchema;
  const isArray = type === 'array';
  let { required, properties: formProps } = sectionSchema;
  if (!required && isArray) required = items.required;
  if (!formProps && isArray) formProps = items.properties;

  const [isDragging, setIsDragging] = useState(false);
  const [isTextEditing, setIsTextEditing] = useState(false);
  const [inputStates, setInputStates] = useState({});
  const [sectionName, setSectionName] = useState('');
  const [inputOrder, setInputOrder] = useState([]);
  const [sectionState, setSectionState] = useState('optional');

  useEffect(() => {
    setInputStates(setFieldStatus(formFields, required, formProps, notOptionalFields, optionalOnlyFields, fieldNotes));
    setSectionName(title);
    setInputOrder(Object.keys(formFields));
    setSectionState(sectionStatus.status);
  }, [fieldNotes, formFields, formProps, notOptionalFields, optionalOnlyFields, required, sectionStatus.status, title]);

  function handleChange(stateObj, orderArr, nameStr, status) {
    const stateEntries = Object.entries(stateObj);

    const updatedProps = updateObject(stateEntries, formFields);
    const updatedRequired = updateArray(stateEntries, required, true);

    const updatedUiSchema = updateObject(stateEntries, uiSchemaProps);
    const updatedOrder = updateArray(stateEntries, orderArr);

    const schema = {
      ...sectionSchema,
      title: nameStr,
      ...(!isArray ? { properties: updatedProps } : {}),
      ...(!isArray ? { required: updatedRequired } : {}),
      ...(isArray
        ? {
            items: {
              properties: updatedProps,
              required: updatedRequired,
            },
          }
        : {}),
    };

    const uiSchema = {
      ...updatedUiSchema,
      'ui:order': updatedOrder,
    };

    onChange(schema, uiSchema, status);
  }

  function handleFieldTitleChange(newVal, propName) {
    const stateObj = {
      ...inputStates,
      [propName]: {
        ...inputStates[propName],
        title: newVal,
      },
    };
    setInputStates(stateObj);
    handleChange(stateObj, inputOrder, sectionName, sectionState);
  }

  function handleRadioChange(newVal, propName) {
    const stateObj = {
      ...inputStates,
      [propName]: {
        ...inputStates[propName],
        status: newVal,
      },
    };

    setInputStates(stateObj);
    handleChange(stateObj, inputOrder, sectionName, sectionState);
  }

  function handleDragStart() {
    setIsDragging(true);
  }

  function handleDragEnd(droppedObj) {
    const { destination, source } = droppedObj;
    if (!destination) return;
    const order = reorder(inputOrder, source.index, destination.index);
    setInputOrder(order);
    setIsDragging(false);
    handleChange(inputStates, order, sectionName, sectionState);
  }

  const isHidden = sectionStatus.toggleState === 'hidden';
  const isDisabled = sectionState === 'disabled';

  return (
    <Fragment>
      <div>
        <div className="d-flex">
          <h6 className="mt-2 flex-grow-1">
            {isEditInlineDisabled || isDisabled ? (
              sectionName
            ) : (
              <EditInline
                value={sectionName}
                onChange={(newName) => {
                  setSectionName(newName);
                  handleChange(inputStates, inputOrder, newName, sectionState);
                }}
                onToggle={(isEdit) => setIsTextEditing(isEdit)}
              />
            )}
          </h6>
          {!sectionStatus.isNotOpt && (
            <InlineRadios
              name={sectionKey}
              radioBtns={RADIOS}
              value={sectionState}
              onChange={(newVal) => {
                setSectionState(newVal);
                handleChange(inputStates, inputOrder, sectionName, newVal);
              }}
              className={cx('me-0', { 'ms-2': isTextEditing })}
            />
          )}
        </div>
        <a
          href="#toggle"
          className="small"
          onClick={(e) => {
            e.preventDefault();
            onViewToggle(!isHidden);
          }}
        >
          {`${isHidden ? 'View' : 'Hide'} fields`}
        </a>
      </div>
      <DragDropContext onDragStart={handleDragStart} onDragEnd={handleDragEnd}>
        <Droppable droppableId="droppable">
          {/* eslint-disable-next-line no-unused-vars */}
          {(provided, snapshot) => (
            <div
              ref={provided.innerRef}
              {...provided.droppableProps}
              className={cx({ 'is-dragging': isDragging }, { 'd-none': isHidden })}
            >
              {inputOrder.map((propName, i) => {
                const field = inputStates[propName];
                if (!field || field.hide) return null;

                let radios = [...RADIOS];
                if (field.isNotOpt) {
                  radios = radios.filter(({ id }) => id === 'required');
                } else if (field.optOnly) {
                  radios = radios.filter(({ id }) => id === 'optional');
                }

                return (
                  <FormField
                    key={propName}
                    isDragDisabled={isDragDisabled}
                    isEditInlineDisabled={isEditInlineDisabled}
                    isDisabled={isDisabled}
                    index={i}
                    name={propName}
                    title={field.title}
                    notes={field.notes}
                    radioBtns={radios}
                    checkedValue={field.status}
                    onRadioChange={handleRadioChange}
                    onTitleChange={handleFieldTitleChange}
                  />
                );
              })}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </Fragment>
  );
}

FormSectionEditor.propTypes = {
  title: PropTypes.string,
  sectionKey: PropTypes.string.isRequired,
  sectionSchema: PropTypes.shape({
    type: PropTypes.string,
    title: PropTypes.string,
    properties: PropTypes.shape(),
    required: PropTypes.arrayOf(PropTypes.string),
    items: PropTypes.shape({
      type: PropTypes.string,
      properties: PropTypes.shape(),
      required: PropTypes.arrayOf(PropTypes.string),
    }),
  }).isRequired,
  formFields: PropTypes.objectOf(
    PropTypes.shape({
      type: PropTypes.string,
      title: PropTypes.string,
      format: PropTypes.string,
    }),
  ).isRequired,
  uiSchemaProps: PropTypes.objectOf(PropTypes.shape()).isRequired,
  notOptionalFields: PropTypes.shape(),
  optionalOnlyFields: PropTypes.arrayOf(PropTypes.string),
  sectionStatus: PropTypes.shape({
    status: PropTypes.string,
    isNotOpt: PropTypes.bool,
    toggleState: PropTypes.string,
  }),
  fieldNotes: PropTypes.shape(),
  onChange: PropTypes.func,
  onViewToggle: PropTypes.func,
  isDragDisabled: PropTypes.bool,
  isEditInlineDisabled: PropTypes.bool,
};

FormSectionEditor.defaultProps = {
  title: '',
  notOptionalFields: {},
  optionalOnlyFields: [],
  sectionStatus: {
    status: 'optional',
    isNotOpt: false,
  },
  fieldNotes: {},
  onChange: () => {},
  onViewToggle: () => {},
  isDragDisabled: true,
  isEditInlineDisabled: true,
};

export default FormSectionEditor;
