import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import IconSVG from '../SVG/IconSVG';
import TabItem from './TabItem';
import Can from '../RBAC/Can/Can';
import { checkPermissions } from '@JS/auth/AuthUtils';

const RestrictedTab = Can(TabItem);

export default function GroupTabItem({
  isActiveTab,
  onClick,
  label,
  anchor,
  isVertical,
  icon,
  hideLabels,
  className,
  labelComponent,
  items,
  activeTabId,
  handleTabClick,
  languagePack,
}) {
  // Filter items based on permissions
  const visibleItems = items.filter((item) => {
    if (!item.permissions) return true; // Always show if no permissions required
    return checkPermissions(item.permissions);
  });

  // If no visible items, don't render anything
  if (visibleItems.length === 0) {
    return null;
  }

  const hash = (anchor || label).toLowerCase().replace(' ', '_');

  return (
    <li
      className={cx('group-tab-item', className, { 'is-vertical': isVertical, 'tab-item-active': isActiveTab }, hash)}
    >
      <a href="#">
        {icon && <IconSVG name={icon} />}
        <span className={cx({ 'sr-only': hideLabels })}>{label}</span>
        {labelComponent}
        {isVertical && <IconSVG name="Chevron Left" width="1.75em" className="group-chevron" />}
      </a>
      <ul className={cx('group-items', { expanded: true })}>
        {visibleItems.map((item) => {
          const itemProps = {
            key: item.anchor || item.label,
            ...item,
            isGroupItem: true,
            isActiveTab: activeTabId === item.anchor,
            onClick: (e) => handleTabClick(item.anchor, e),
            isVertical,
            label: `${languagePack[item.label] || item.label}`,
            className: 'group-item',
          };

          if (item.permissions) {
            return <RestrictedTab {...itemProps} permissions={item.permissions} />;
          }

          return <TabItem {...itemProps} />;
        })}
      </ul>
    </li>
  );
}

GroupTabItem.propTypes = {
  isActiveTab: PropTypes.bool,
  onClick: PropTypes.func,
  label: PropTypes.string.isRequired,
  anchor: PropTypes.string,
  isVertical: PropTypes.bool,
  icon: PropTypes.string,
  hideLabels: PropTypes.bool,
  className: PropTypes.string,
  labelComponent: PropTypes.node,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      anchor: PropTypes.string,
      label: PropTypes.string,
      permissions: PropTypes.arrayOf(PropTypes.string),
    }),
  ).isRequired,
  activeTabId: PropTypes.string,
  handleTabClick: PropTypes.func.isRequired,
  languagePack: PropTypes.object,
};

GroupTabItem.defaultProps = {
  isActiveTab: false,
  onClick: () => {},
  anchor: null,
  isVertical: false,
  icon: null,
  hideLabels: false,
  className: '',
  labelComponent: null,
  activeTabId: null,
  languagePack: {},
};
