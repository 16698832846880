import React, { useEffect, useCallback, useState, forwardRef, useMemo } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import Tippy from '@tippyjs/react';
import styled from 'styled-components';

import { retryableAPICall } from '@API/common-api-utils';
import { deleteTemplate, emailTemplateSearch } from '@API/CommsAPI/EmailTemplateAPI';

import { useLanguagePack, useMounted, usePageLoading } from '@Base/hooks';
import { useTableState, DataTable } from '@Base/Tables';
import { DeleteButton, CreateButton } from '@Base/Buttons';
import { Confirmation } from '@Base/Modal';
import Can from '@Base/RBAC/Can/Can';

import { deleteObjFromArray, updateObjInArray, addObjToArray } from '@JS/utils/arrayOfObjects';
import { checkPermissions } from '@JS/auth/AuthUtils';

import {
  SearchInput,
  EnhancedCard,
  EnhancedCardTitle,
  ClientAdminNoDataMessage,
  PageColumn,
  ClientAdminSlider,
} from '../../Common';
import { CommunicationsEditor } from './components';

import { useDebounce } from '../../Common/utils';

const StyledTable = styled(DataTable)`
  .rt-thead {
    background-color: #f8f9fa !important;
    padding: 0.35rem 0;
  }
`;

const RestrictedCreateButton = Can(CreateButton);
const DeleteRefButton = forwardRef((props, ref) => <DeleteButton {...props} innerRef={ref} />);

async function deleteTemp(tempProps, onSuccess = () => {}, onError = () => {}) {
  const [id, group] = tempProps;

  if (id && group) {
    const resp = await retryableAPICall(() => deleteTemplate(id, group));

    if (typeof resp === 'string' && resp.length) {
      onError();
    } else {
      onSuccess();
    }
  }
}

function ScheduleCommunication({ tablePrefs }) {
  const isMounted = useMounted();
  const languagePack = useLanguagePack('client-settings');
  const { pageState, setPageResolved, setPageRejected } = usePageLoading();
  const { tableState, setTableData, setTableRejected } = useTableState({ rowsPerPage: tablePrefs.pageSize });
  const [showConfirm, setShowConfirm] = useState(false);
  const [deleteTempProps, setDeleteTempProps] = useState([]);
  const [templateObj, setTemplateObj] = useState({});
  const [isEditing, setIsEditing] = useState(false);
  const [isSliderOpen, setIsSliderOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const debouncedSearchTerm = useDebounce(searchTerm, 300);
  const [originalData, setOriginalData] = useState([]);

  const requestData = useCallback(
    async (callback = () => {}) => {
      const resp = await retryableAPICall(() =>
        emailTemplateSearch(null, [
          { field: 'group', operation: 'EQ', value: ['COMPANY'] },
          {
            field: 'type',
            operation: 'IN',
            value: [
              'EVENT_INVITE',
              'EVENT_CONFIRMATION',
              'EVENT_INVITE_REMINDER',
              'EVENT_BOOKING_REMINDER',
              'EVENT_PHONE_CONFIRMATION',
              'EVENT_VIDEO_CONFIRMATION',
              'EVENT_FACE_TO_FACE_CONFIRMATION',
              'EVENT_UPDATED',
              'EVENT_CANCELLED',
              'EVENT_REMINDER_24',
              'INVITE_WITHDRAWN',
            ],
          },
        ]),
      );

      if (isMounted()) {
        if (typeof resp === 'string') {
          setTableRejected();
          callback(resp);
        } else {
          const sortedCommunications = resp.sort((a, b) => a.name?.localeCompare(b.name));
          setOriginalData(sortedCommunications);
          setTableData({
            data: sortedCommunications,
            totalResults: resp.length,
          });

          callback();
        }
      }
    },
    [isMounted, setTableData, setTableRejected],
  );

  const filteredData = useMemo(() => {
    if (!debouncedSearchTerm) return originalData;

    return originalData.filter((template) => {
      const searchLower = debouncedSearchTerm.toLowerCase();
      const nameMatch = template.name?.toLowerCase().includes(searchLower);
      const subjectMatch = template.subject?.toLowerCase().includes(searchLower);
      const typeMatch = (languagePack[template.type] || template.type)?.toLowerCase().includes(searchLower);

      return nameMatch || subjectMatch || typeMatch;
    });
  }, [debouncedSearchTerm, originalData, languagePack]);

  useEffect(() => {
    setTableData({
      data: filteredData,
      totalResults: filteredData.length,
    });
  }, [filteredData, setTableData]);

  useEffect(() => {
    requestData((errorStatus) => {
      if (errorStatus) {
        setPageRejected(errorStatus);
      } else {
        setPageResolved();
      }
    });
  }, [requestData, setPageRejected, setPageResolved]);

  const columns = [
    {
      Header: 'Name',
      id: 'templateName',
      minWidth: 100,
      Cell: ({ row: { original } }) => {
        const { name } = original;

        if (checkPermissions(['admin:schedule:update'])) {
          return (
            <a
              href="#editTemplate"
              onClick={(e) => {
                e.preventDefault();
                setTemplateObj(original);
                setIsEditing(true);
                setIsSliderOpen(true);
              }}
            >
              {name}
            </a>
          );
        }

        return name;
      },
    },
    {
      Header: 'Subject',
      id: 'subject',
      accessor: 'subject',
      minWidth: 100,
    },
    {
      Header: 'Type',
      id: 'type',
      accessor: 'type',
      width: 160,
      Cell: ({ row: { original } }) => (
        <span>{languagePack[original.type] ? languagePack[original.type] : original.type}</span>
      ),
    },
  ];

  if (checkPermissions(['admin:schedule:delete'])) {
    columns.push({
      width: 63,
      id: 'action',
      className: 'action-cell',
      Cell: ({ row: { original } }) => {
        const { id, group, deletable } = original;

        return (
          <Tippy
            theme="ats"
            maxWidth="150px"
            content="This email is required by the system and can't be removed, if you wish to change the contents you can edit it"
            disabled={deletable}
          >
            <DeleteRefButton
              label="Delete Template"
              action={() => {
                setDeleteTempProps([id, group]);
                setShowConfirm(true);
              }}
              disabled={!deletable}
              className="btn-sm xs"
              iconOnly
              floatRight={false}
            />
          </Tippy>
        );
      },
    });
  }

  return (
    <PageColumn state={pageState}>
      <EnhancedCard className="mt-3" style={{ maxWidth: '1600px', width: '100%' }}>
        <EnhancedCardTitle title="Communication" subtitle="Manage your email templates and communications">
          <RestrictedCreateButton
            permissions={['admin:schedule:create']}
            action={() => {
              setIsEditing(false);
              setTemplateObj({
                typeChange: true,
              });
              setIsSliderOpen(true);
            }}
            label="Create a new Email Template"
            className="mb-auto"
          />
        </EnhancedCardTitle>

        <div className="d-flex align-items-center gap-3">
          <SearchInput
            value={searchTerm}
            className="w-100"
            type="text"
            placeholder="Search templates by name, subject or type"
            onChange={(e) => setSearchTerm(e)}
          />
        </div>

        {!tableState.data.length ? (
          <ClientAdminNoDataMessage
            title={searchTerm ? 'No templates match your search' : 'You currently have no Email Templates'}
            message={searchTerm ? 'Try adjusting your search terms' : ''}
            btnProps={
              !searchTerm
                ? {
                    onClick: () => {
                      setIsEditing(false);
                      setTemplateObj({ typeChange: true });
                      setIsSliderOpen(true);
                    },
                    label: 'Create Email Template',
                  }
                : {}
            }
          />
        ) : (
          <StyledTable
            id="sched-comms"
            className="mt-3"
            isLoading={tableState.isLoading}
            isRejected={tableState.isRejected}
            data={tableState.data}
            columns={columns}
            totalResults={tableState.totalResults}
            pageSize={tableState.rowsPerPage}
            noDataText="You currently have no Email Templates"
            errorText="There has been an error loading templates, please try again later"
            hasSelectColumn={false}
            showPagination={false}
          />
        )}
      </EnhancedCard>

      <ClientAdminSlider
        title={`${isEditing ? 'Edit' : 'Create'} Email Template`}
        isSliderOpen={isSliderOpen}
        closeSlider={() => setIsSliderOpen(false)}
      >
        <CommunicationsEditor
          isEditing={isEditing}
          data={templateObj}
          onSave={(tempObj) => {
            setIsSliderOpen(false);

            const updatedArr = isEditing
              ? updateObjInArray(tableState.data, tempObj, tempObj.id)
              : addObjToArray(tableState.data, tempObj);

            if (updatedArr.length) {
              setTableData({
                data: updatedArr,
                totalResults: updatedArr.length,
              });
              setOriginalData(updatedArr);
            }
          }}
          onCancel={() => setIsSliderOpen(false)}
        />
      </ClientAdminSlider>

      <Confirmation
        content="Are you sure you want to delete this template?"
        show={showConfirm}
        cancelCallback={() => setShowConfirm(false)}
        confirmCallback={() => {
          deleteTemp(
            deleteTempProps,
            () => {
              const [delTempId] = deleteTempProps;
              toast.success('Template successfully deleted');
              const updatedArr = deleteObjFromArray(tableState.data, delTempId);
              setTableData({
                data: updatedArr,
                totalResults: updatedArr.length,
              });
              setOriginalData(updatedArr);
            },
            () => {
              toast.error('Error deleting template');
            },
          );
          setShowConfirm(false);
        }}
      />
    </PageColumn>
  );
}

ScheduleCommunication.propTypes = {
  tablePrefs: PropTypes.shape({
    pageSize: PropTypes.number,
  }),
};

ScheduleCommunication.defaultProps = {
  tablePrefs: {
    pageSize: 25,
  },
};

export default ScheduleCommunication;
