import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { countryList } from '@JS/model/other/countries';

function CountrySelect(props) {
  const { id, onChange, value, isErr } = props;

  return (
    <>
      <label
        className={`tw-block tw-mb-2 tw-text-sm tw-font-medium ${isErr ? 'tw-text-red-500' : 'tw-text-gray-700'}`}
        htmlFor={id}
      >
        Country<span className="tw-text-red-500">*</span>
      </label>
      <Select
        id={id}
        options={countryList}
        className="tw-w-full"
        placeholder="Select..."
        value={countryList.find(({ value: val }) => val === value?.country)}
        onChange={(selected) => {
          if (selected) onChange(selected.value);
        }}
        classNamePrefix="react-select"
        isSearchable
        styles={{
          control: (base, state) => ({
            ...base,
            borderColor: isErr ? '#ef4444' : state.isFocused ? '#3b82f6' : '#d1d5db',
            boxShadow: state.isFocused ? '0 0 0 1px #3b82f6' : 'none',
            '&:hover': {
              borderColor: state.isFocused ? '#3b82f6' : '#9ca3af',
            },
          }),
        }}
      />
    </>
  );
}

CountrySelect.propTypes = {
  id: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  value: PropTypes.string,
  disabled: PropTypes.bool,
};

CountrySelect.defaultProps = {
  onChange: () => {},
  value: '',
  disabled: false,
};

export default CountrySelect;
