import React from 'react';
import PropTypes from 'prop-types';
import Tippy from '@tippyjs/react';
import IconSVG from '@Base/SVG/IconSVG';

function RestrictedMarker({ children, tooltipMessage }) {
  return (
    <Tippy content={tooltipMessage}>
      <span className="d-inline-flex">
        <IconSVG name="Exclamation Circle" width="1.25em" className="restricted-marker" />
        {`Restricted ${children}`}
      </span>
    </Tippy>
  );
}

RestrictedMarker.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  tooltipMessage: PropTypes.string.isRequired,
};

RestrictedMarker.defaultProps = {
  children: null,
};

export default RestrictedMarker;
