import { useEffect, useState } from 'react';
import { retryableAPICall } from '@API/common-api-utils';
import { getRejectionReasons } from '@API/CandidateAPI/CandidateAPI';
import useMounted from './useMounted';

async function fetchFunnelRejectionReasons(onComplete = () => {}, onError = () => {}) {
  try {
    const resp = await retryableAPICall(() => getRejectionReasons());
    if (typeof resp !== 'string') {
      onComplete(resp);
    } else {
      onError();
    }
  } catch (error) {
    console.error('Failed to fetch rejection reasons:', error);
    onError();
  }
}

export default function useFetchFunnelRejectionReasons() {
  const isMounted = useMounted();
  const [rejectionReasons, setRejectionReasons] = useState([]);

  useEffect(() => {
    if (isMounted()) {
      fetchFunnelRejectionReasons(
        (rejectionReasons) => {
          setRejectionReasons(rejectionReasons);
        },
        () => {
          setRejectionReasons([]);
        },
      );
    }
  }, [isMounted]);

  return [rejectionReasons];
}
