import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Modal from 'react-modal';
import SlidingPane from 'react-sliding-pane';
import { CloseButton } from '../Buttons';
import { LoadingScreen } from '../Loading';
import { useToast } from '../hooks';
import { ErrorMessage } from '../ErrorScreen';
import FadeIn, { FADE_TIMES } from '../FadeIn/FadeIn';
import { stopPolling, startPolling } from '@JS/actions/eventNotificationActions';
import SliderCard from './SliderCard';
import { ConditionalWrapper } from '../SectionDividers';

const isSafariUa = navigator.userAgent.includes('Safari');

function isAnyElementInFocus(elements) {
  // Convert NodeList to an array using `Array.from` and then use `some`
  return Array.from(elements).some((element) => element.contains(document.activeElement));
}

function setFormFocus() {
  const paneContent = document.querySelector('.slide-pane__content');
  if (paneContent) {
    paneContent.click();
    const forms = paneContent.querySelectorAll('form');
    if (isSafariUa && forms.length) {
      const inputs = forms[0].querySelectorAll('input');
      if (inputs.length) {
        const displayedInputs = [...inputs].filter((inp) => inp.offsetParent !== null);
        if (displayedInputs.length) {
          if (forms?.length > 0 && !isAnyElementInFocus(forms)) {
            // Check if none of the forms is in focus
            const firstInput = displayedInputs[0];
            firstInput.focus();
          }
        }
      }
    }
  }
}

function doClose(toggleOpen, isBgClick) {
  document.body.click();
  toggleOpen(isBgClick);
}

function Slider({
  isOpen,
  className,
  overlayClassName,
  errorMsg,
  successMsg,
  resetSuccess,
  resetError,
  toggleOpen,
  title,
  headerStyle,
  children,
  width,
  isLoading,
  isLoadingError,
  loadingErrorStatus,
  // hideTitleButton,
  TitleButton,
  fadeInChildren,
  fadeDelay,
  fadeDuration,
  focusForm,
  pauseNotificationsOnOpen,
  pausePoll,
  startPoll,
  childWrapperClassName,
  noWrapChildren,
  closeButtonLabel,
}) {
  const successAlert = useToast();
  const errorAlert = useToast('error');
  const [bgClickEnabled, setBgClickEnabled] = useState(false);

  useEffect(() => {
    if (!isOpen && pauseNotificationsOnOpen) startPoll();
  }, [isOpen, pauseNotificationsOnOpen, startPoll]);

  useEffect(() => {
    errorAlert(errorMsg, resetError);
    successAlert(successMsg, resetSuccess);
  }, [errorAlert, errorMsg, resetError, resetSuccess, successAlert, successMsg]);

  useEffect(() => {
    Modal.setAppElement('#root');
  }, []);

  return (
    <SlidingPane
      isOpen={isOpen}
      id="right-hand-slider"
      className={className || ''}
      overlayClassName={overlayClassName || ''}
      width={width}
      onRequestClose={() => {
        if (bgClickEnabled) {
          doClose(() => {
            toggleOpen();
          }, true);
          // setBgClickEnabled(false);
        }
      }}
      onAfterOpen={() => {
        if (pauseNotificationsOnOpen) pausePoll();
        if (!fadeInChildren && focusForm) setFormFocus();
        // stops unintended bgClick on double click specifically schedule calendar
        setTimeout(() => setBgClickEnabled(true), 2000);
      }}
    >
      {isLoading ? (
        <LoadingScreen />
      ) : (
        <>
          <div className="slider-edge" />
          <section className="slider-header">
            <ConditionalWrapper
              condition={!isLoadingError}
              wrapper={(child) => (
                <>
                  {title && <h2 style={headerStyle}>{title}</h2>}
                  {TitleButton}
                  {child}
                </>
              )}
            >
              <CloseButton
                onClick={() => {
                  doClose(() => {
                    toggleOpen();
                  });
                  setBgClickEnabled(false);
                }}
                label={closeButtonLabel}
              />
            </ConditionalWrapper>
          </section>
          {isLoadingError ? (
            <ErrorMessage error={loadingErrorStatus} />
          ) : (
            <section className="slider-body">
              {fadeInChildren ? (
                <FadeIn
                  onDisplay={() => {
                    if (focusForm) setFormFocus();
                  }}
                  delay={fadeDelay}
                  duration={fadeDuration}
                >
                  <SliderCard className={childWrapperClassName} noRenderCard={noWrapChildren}>
                    {children}
                  </SliderCard>
                </FadeIn>
              ) : (
                <SliderCard className={childWrapperClassName} noRenderCard={noWrapChildren}>
                  {children}
                </SliderCard>
              )}
            </section>
          )}
        </>
      )}
    </SlidingPane>
  );
}

Slider.propTypes = {
  isOpen: PropTypes.bool,
  className: PropTypes.string,
  overlayClassName: PropTypes.string,
  errorMsg: PropTypes.string,
  width: PropTypes.string,
  toggleOpen: PropTypes.func,
  successMsg: PropTypes.string,
  headerStyle: PropTypes.shape(),
  isLoading: PropTypes.bool,
  isLoadingError: PropTypes.bool,
  loadingErrorStatus: PropTypes.string,
  title: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node, PropTypes.func]),
  resetSuccess: PropTypes.func,
  resetError: PropTypes.func,
  // hideTitleButton: PropTypes.bool,
  TitleButton: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  fadeInChildren: PropTypes.bool,
  fadeDelay: PropTypes.number,
  fadeDuration: PropTypes.number,
  focusForm: PropTypes.bool,
  pauseNotificationsOnOpen: PropTypes.bool,
  pausePoll: PropTypes.func,
  startPoll: PropTypes.func,
  childWrapperClassName: PropTypes.string,
  noWrapChildren: PropTypes.bool,
  closeButtonLabel: PropTypes.string,
};

Slider.defaultProps = {
  isOpen: false,
  className: null,
  overlayClassName: null,
  errorMsg: null,
  width: '40%',
  headerStyle: {},
  toggleOpen: () => {},
  successMsg: null,
  isLoading: false,
  isLoadingError: false,
  loadingErrorStatus: null,
  title: null,
  children: null,
  resetSuccess: null,
  resetError: null,
  // hideTitleButton: false,
  TitleButton: null,
  fadeInChildren: true,
  fadeDelay: FADE_TIMES.delay,
  fadeDuration: FADE_TIMES.duration,
  focusForm: true,
  pauseNotificationsOnOpen: false,
  pausePoll: () => {},
  startPoll: () => {},
  childWrapperClassName: null,
  noWrapChildren: false,
  closeButtonLabel: 'Close',
};

function mapDispatchToProps(dispatch) {
  return {
    pausePoll: () => {
      dispatch(stopPolling());
    },
    startPoll: () => {
      dispatch(startPolling());
    },
  };
}

export default connect(null, mapDispatchToProps)(Slider);
