import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import Select from 'react-select';

import { getFlexiFormViewIdsByType } from '@API/FormsAPI/ATSFormsAPI';
import { retryableAPICall } from '@API/common-api-utils';

function SelectFormInput({ option, setOption }) {
const [docs, setDocs] = useState([]);
const [selectedDoc, setSelectedDoc] = useState(null);

  async function getForms() {
      const result = await retryableAPICall(() => getFlexiFormViewIdsByType("EDOC"));
  
      if (typeof result === 'string') {
          toast.error("Failed to get edocs");
      } else {
        const genericFormEntries = [];
        // eslint-disable-next-line no-restricted-syntax
        for (const [key, value] of Object.entries(result)) {
            genericFormEntries.push({ id: key, label: value, value: key });
        }
        setDocs(genericFormEntries);
        setSelectedDoc(genericFormEntries.find(a=>{
          return a.value ===option 
        }))
      }
    }
    
    useEffect(() => {
        getForms();
    }, []);

    useEffect(() => {
      setSelectedDoc(docs.find(a=>{
          return a.value ===option 
        }))
  }, [option]);
   
  return (
     <Select
      id="eDoc-form-select"
      onChange={(val) =>{
        setOption(val.value);
      }}
      options={docs}
      placeholder={"Select Edoc"}
      value={selectedDoc }
    />
  );
}

SelectFormInput.propTypes = {
  option: PropTypes.shape(),
  setOption: PropTypes.func,
};

SelectFormInput.defaultProps = {
  option: {},
  setOption: () => {},
};

export default SelectFormInput;
