import React, { createContext } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import LocalizedStrings from 'react-localization';
import { UPDATE_LANGUAGE } from '@JS/constants/actionTypes';

function getLanguagePack(pack, lang = 'en') {
  const strings = new LocalizedStrings(pack);
  strings.setLanguage(lang);
  return strings;
}

const LanguageContext = createContext({
  lang: '',
  switchLang: () => {},
  getLangPack: () => {},
});

function LanguageProvider({ children }) {
  const lang = useSelector((state) => state.userData.userSettings?.language?.languageCode || '');
  const dispatch = useDispatch();

  function switchLang(langStr) {
    dispatch({ type: UPDATE_LANGUAGE, payload: langStr });
  }

  function getLangPack(pack) {
    return getLanguagePack(pack, lang);
  }

  return <LanguageContext.Provider value={{ lang, switchLang, getLangPack }}>{children}</LanguageContext.Provider>;
}

LanguageProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};

export { LanguageContext as default, LanguageProvider };
