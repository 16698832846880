import React, { useState, useEffect } from 'react';
import { retryableAPICall } from '@API/common-api-utils';
import { getUserListForAccount } from '@API/AccountAPI';
import EmailTemplateSelectInput from './EmailTemplateSelectInput';
import { FormGroup, Label } from 'reactstrap';
import Select from 'react-select';
import { Loader } from '@Base/Loading';
import UserSelector from '@Base/Users/UserSelector';
import { ReactMultiEmail, isEmail } from 'react-multi-email';
import { TagPill } from '@Base/Forms/Custom/EmailPills';
import { toast } from 'react-toastify';

const getRandomColor = () => {
  const letters = '0123456789ABCDEF';
  let color = '#';
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
};

function SendEmailInput({ option, setOption }) {
  const [users, setUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (!option?.type) {
      setOption({ type: 'CANDIDATE_EMAIL' });
    }
  }, []);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const resp = await retryableAPICall(() => getUserListForAccount());
        const formattedUsers = resp.map((user) => ({
          value: user.id,
          label: user.name,
          email: user.email,
          color: getRandomColor(),
          initials: user.name
            .split(' ')
            .map((n) => n[0])
            .join('')
            .toUpperCase(),
        }));
        setUsers(formattedUsers);
      } catch (error) {
        console.error('Error fetching users:', error);
        toast.error('Failed to fetch users. Please try again.');
      } finally {
        setIsLoading(false);
      }
    };
    fetchUsers();
  }, [isLoading]);

  if (isLoading) {
    return <Loader size="sm" />;
  }

  return (
    <>
      <FormGroup required>
        <Label>Recipient Type</Label>
        <Select
          className="react-select"
          placeholder="Select Status"
          options={[
            { label: 'Email Candidate', value: 'CANDIDATE_EMAIL' },
            { label: 'Email Address', value: 'DIRECT' },
            { label: 'ATS User', value: 'ATS' },
            { label: 'Vacancy Assignee', value: 'VACANCY_ASSIGNEE' },
          ]}
          value={[
            { label: 'Email Address', value: 'DIRECT' },
            { label: 'ATS User', value: 'ATS' },
            { label: 'Email Candidate', value: 'CANDIDATE_EMAIL' },
            { label: 'Vacancy Assignee', value: 'VACANCY_ASSIGNEE' },
          ].find((a) => a.value === option?.type)}
          onChange={(val) => setOption({ type: val.value })}
        />
      </FormGroup>
      {option?.type === 'ATS' && (
        <FormGroup required>
          <Label>Users</Label>
          <UserSelector
            users={users}
            onChange={(selected) => {
              setOption({ recipients: selected.map(a => a.value) });
            }}
            defaultValue={users.filter(a=> option?.recipients?.includes(a.value))}
          />
        </FormGroup>
      )}
      {option?.type === 'DIRECT' && (
        <FormGroup required>
          <Label>Email Addresses</Label>
          <ReactMultiEmail
            emails={option.recipients}
            getLabel={(email, index, removeEmail) => (
              <TagPill key={index} index={index} removeTag={removeEmail}>
                {email}
              </TagPill>
            )}
            onChange={(emails) => setOption({ recipients: emails })}
            validateEmail={(email) => isEmail(email)}
          />
        </FormGroup>
      )}
      <EmailTemplateSelectInput
        actionType={option?.type === 'ATS' ? 'ATS_USER' : option?.actionType}
        id={`emailTemplateConfig-`}
        onChange={(templateId) => setOption({ templateId })}
        required
        value={option?.templateId || ''}
      />
    </>
  );
}

export default SendEmailInput;
