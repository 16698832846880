import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';

function splitPostcode(postcode = '') {
  const sPostcode = postcode.split(' ');
  return { pfx: sPostcode[0] || '', sfx: sPostcode[1] || '' };
}

function PostcodeInput({ id, disabled, value, onChange, formContext, isErr, onBlur, onFocus }) {
  const [postcode, setPostcode] = useState(splitPostcode(value));

  useEffect(() => {
    setPostcode(splitPostcode(value));
  }, [value]);

  const handleInputChange = (part, val) => {
    const newPostcode = { ...postcode, [part]: val.toUpperCase() };
    setPostcode(newPostcode);

    // Combine the parts to form the full postcode without applying validation here.
    const fullPostcode = `${newPostcode.pfx} ${newPostcode.sfx}`;
    onChange(fullPostcode); // Always send the updated value, regardless of its validity.
  };

  const inputClasses = `tw-px-3 tw-py-2 tw-text-sm tw-border tw-rounded-md tw-shadow-sm 
    ${isErr ? 'tw-border-red-500 tw-ring-red-500' : 'tw-border-gray-300'}
    ${disabled ? 'tw-bg-gray-100 tw-text-gray-500 tw-cursor-not-allowed' : ''}
    focus:tw-outline-none focus:tw-ring-1 focus:tw-border-blue-500 focus:tw-ring-blue-500`;

  return (
    <div className="tw-flex tw-flex-row tw-gap-2">
      <div>
        <input
          id={`${id}-pfx`}
          type="text"
          maxLength={4}
          className={inputClasses}
          disabled={disabled}
          autoComplete="off"
          value={postcode.pfx || ''}
          onBlur={onBlur}
          onFocus={onFocus}
          onChange={(e) => handleInputChange('pfx', e.target.value)}
        />
      </div>
      <div>
        <input
          id={`${id}-sfx`}
          type="text"
          maxLength={3}
          className={inputClasses}
          disabled={disabled}
          autoComplete="off"
          value={postcode.sfx || ''}
          onBlur={onBlur}
          onFocus={onFocus}
          onChange={(e) => handleInputChange('sfx', e.target.value)}
        />
      </div>
    </div>
  );
}

PostcodeInput.propTypes = {
  id: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  value: PropTypes.string,
  onChange: PropTypes.func,
  formContext: PropTypes.shape(),
  isErr: PropTypes.bool,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
};

PostcodeInput.defaultProps = {
  disabled: false,
  value: '',
  onChange: () => {},
  formContext: {},
  isErr: false,
  onBlur: () => {},
  onFocus: () => {},
};

export default PostcodeInput;
