import React from 'react';
import { PaginationButton, PaginationResultCount } from '@Base/CandidatePagination';

function CandidatePagination(props) {
  return (
    <div className="d-flex align-items-center">
      <PaginationButton {...props} />
      <PaginationResultCount {...props} />
      <PaginationButton {...props} isNextButton />
    </div>
  );
}

export default CandidatePagination;
