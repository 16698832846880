import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { Badge, Card, CardBody, Col, Row } from 'reactstrap';
import moment from 'moment';
import { downloadFileData } from '@API/FileAPI/FileAPI';
import { LoadingScreen } from '@Base/Loading';
import { ModalPopup } from '@Base/Modal';
import RightToWorkMessage from './RightToWorkMessage';
import RightToWorkImposterCheck from './RightToworkImposterCheck';
import { checkPermissions } from '../../../js/auth/AuthUtils';

function RightToWorkCompletedContainer({ rtwDetails, candidate, languagePack, refreshData }) {
  const [documents, setDocuments] = useState([]);
  const [loading, setLoading] = useState(false);
  const [documentViewModalOpen, setDocumentViewModalOpen] = useState(false);
  const [documentViewImage, setDocumentViewImage] = useState(false);

  const DATE_TIME_FORMAT = 'YYYY-MM-DD HH:mm:ss';
  const DATE_FORMAT = 'DD-MM-YYYY';
  const DISPLAY_FORMAT = 'DD-MM-YYYY HH:mm:ss';

  function getRTWStatusBadge() {
    switch (rtwDetails.documentCheckResult?.rightToWorkStatus) {
      case 'CONTINUOUS':
        return <Badge color="success">{languagePack[rtwDetails.documentCheckResult.rightToWorkStatus]}</Badge>;
      case 'TIME_LIMITED':
        return <Badge color="warning">{languagePack[rtwDetails.documentCheckResult.rightToWorkStatus]}</Badge>;
      case 'WITHDRAWN':
        return <Badge color="warning">{languagePack[rtwDetails.documentCheckResult.rightToWorkStatus]}</Badge>;
      case 'REJECTED':
        return <Badge color="danger">{languagePack[rtwDetails.documentCheckResult.rightToWorkStatus]}</Badge>;
      default:
        return <Badge color="info">{languagePack.UNKNOWN}</Badge>;
    }
  }

  function getWarningPills(doc) {
    const pills = [];
    if (
      doc.firstName &&
      doc.surname &&
      (doc.firstName.toUpperCase() !== candidate.applicantProfile.personalDetails.givenName.toUpperCase() ||
        doc.surname.toUpperCase() !== candidate.applicantProfile.personalDetails.familyName.toUpperCase())
    ) {
      pills.push(<RightToWorkMessage content={languagePack.rtwNamesDontMatchWarning} level="WARNING" key="0" />);
    }

    if (doc.documentInDate !== undefined && !doc.documentInDate && doc.expiryDate) {
      pills.push(<RightToWorkMessage content={languagePack.rtwDocumentExpiredWarning} level="WARNING" key="1" />);
    }

    if (doc.notes) {
      pills.push(<RightToWorkMessage content={`${languagePack.rtwAuditorNotes} ${doc.notes}`} level="INFO" key="2" />);
    }

    return pills;
  }
  async function getDocuments() {
    setLoading(true);
    const docs = [];
    // eslint-disable-next-line no-restricted-syntax
    for await (const doc of rtwDetails.documentCheckResult.documents) {
      const images = [];
      // eslint-disable-next-line no-restricted-syntax
      for await (const image of doc.images) {
        const dlImage = await downloadFileData([image.savedFileId]);
        images.push(
          <Col style={{ maxWidth: '350px' }} key={image.savedFileId}>
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <a
              href="#"
              onClick={(e) => {
                e.preventDefault();
                setDocumentViewImage(dlImage);
                setDocumentViewModalOpen(true);
              }}
            >
              <img src={dlImage} width={300} alt={`${doc.documentType}`} />
            </a>
          </Col>,
        );
      }

      const warningPills = getWarningPills(doc);

      docs.push(
        <Fragment key={doc.id}>
          <Row className="mt-2">
            <Col>
              <h5>{doc.documentType}</h5>
            </Col>
          </Row>
          {warningPills.length > 0 && (
            <Row className="mt-1 mb-2">
              <Col>{warningPills}</Col>
            </Row>
          )}
          <Row>{images}</Row>
        </Fragment>,
      );
    }

    setDocuments(docs);
    setLoading(false);
  }

  useState(() => {
    getDocuments();
  }, []);

  async function imposterCheckCompleted() {

  }

  if (loading) {
    return (
      <Card>
        <CardBody>
          <LoadingScreen notFixed />
        </CardBody>
      </Card>
    );
  }

  return (
    <Fragment>
      <Row>
        <Col>
          <div className="onboarding-container-center">
            <Row className="mt-2">
              <Col style={{ maxWidth: '200px' }}>{languagePack.rightToWorkStatusLabel}</Col>
              <Col>{getRTWStatusBadge()}</Col>
            </Row>
            {rtwDetails.documentCheckResult?.documents[0]?.expiryDate && (
              <Row className="mt-2">
                <Col style={{ maxWidth: '200px' }}>{languagePack.passportExpiryDateLabel}</Col>
                <Col>
                  <div className="d-flex gap-1 ">
                    <div style={{ lineHeight: '1.65rem' }}>
                      {moment(rtwDetails.documentCheckResult.documents[0].expiryDate).format(DATE_FORMAT)}{' '}
                    </div>
                    <Badge
                      color={
                        moment(rtwDetails.documentCheckResult.documents[0].expiryDate).isBefore(moment())
                          ? 'danger'
                          : 'success'
                      }
                    >
                      {moment(rtwDetails.documentCheckResult.documents[0].expiryDate).isBefore(moment())
                        ? 'Expired'
                        : 'Valid'}
                    </Badge>
                  </div>
                </Col>
              </Row>
            )}
            {rtwDetails.documentCheckResult?.rightToWorkStatusReason && (
              <Row className="mt-2">
                <Col style={{ maxWidth: '200px' }}>{languagePack.rightToWorkStatusReasonLabel}</Col>
                <Col>
                  <p>{rtwDetails.documentCheckResult.rightToWorkStatusReason}</p>
                </Col>
              </Row>
            )}
            {rtwDetails?.documentCheckResult?.rightToWorkRecheckDate && (
              <Row className="mt-2">
                <Col style={{ maxWidth: '200px' }}>{languagePack.rtwExpiresLabel}</Col>
                <Col>
                  <p>
                    {rtwDetails.documentCheckResult.rightToWorkRecheckDate}
                    {moment(rtwDetails.documentCheckResult.rightToWorkRecheckDate, DATE_FORMAT).isBefore(
                      moment.utc(rtwDetails.resultReceivedDateTime, DATE_TIME_FORMAT).add(6, 'month'),
                    ) && (
                      <span className="text-warning font-weight-bold">
                        {' - '}
                        {languagePack.rtwExpiresLessThan6Months}
                      </span>
                    )}
                  </p>
                </Col>
              </Row>
            )}
            <Row className="mt-2">
              <Col style={{ maxWidth: '200px' }}>{languagePack.rtwReceivedOnLabel}</Col>
              <Col>
                <p>{moment.utc(rtwDetails.resultReceivedDateTime, DATE_TIME_FORMAT).local().format(DISPLAY_FORMAT)}</p>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
      {checkPermissions(['rtw-imposter:create']) &&
      <Row className="mt-3">
        <Col>
          <RightToWorkImposterCheck onComplete={() => refreshData()} candidateId={candidate.id} rtwDetails={rtwDetails} />
        </Col>
      </Row>
      }
      <Row className="mt-3">
        <Col>
          <h4>Received Documents</h4>
        </Col>
      </Row>
      {documents}
      <ModalPopup
        title={languagePack.documentImageViewTitle}
        okayLabel={languagePack.close}
        isOpen={documentViewModalOpen}
        onToggle={() => setDocumentViewModalOpen(false)}
        hideCancelButton
        minHeight="500px"
      >
        <img src={documentViewImage} width="100%" alt={languagePack.documentImageViewTitle} />
      </ModalPopup>
    </Fragment>
  );
}

RightToWorkCompletedContainer.propTypes = {
  rtwDetails: PropTypes.shape(),
  candidate: PropTypes.shape(),
  languagePack: PropTypes.shape(),
  refreshData: PropTypes.func,
};

RightToWorkCompletedContainer.defaultProps = {
  rtwDetails: {},
  candidate: {},
  languagePack: {},
  refreshData: () => {},
  // onSuccess: () => {},
  // onError: () => {},
};

export default RightToWorkCompletedContainer;
