import { useEffect, useState } from 'react';
import { retryableAPICall } from '@API/common-api-utils';
import { getFunnel, updateFunnelStage } from '@API/CandidateAPI/CandidateAPI';
import useMounted from './useMounted';

async function updateFunnel(opts = {}, onSuccess = () => {}, onError = () => {}) {
  const { applicantIds, funnelId, stageId, stageReason, email } = opts;

  const result = await retryableAPICall(() => updateFunnelStage(applicantIds, funnelId, stageId, stageReason, email));

  if (typeof result === 'string') {
    onError();
  } else {
    onSuccess();
  }
}

async function fetchFunnels(funnelId, onComplete = () => {}) {
  const resp = await retryableAPICall(() => getFunnel(funnelId));
  if (resp && resp.stages) onComplete(resp.stages);
}

export function useUpdateFunnel() {
  return updateFunnel;
}

export default function useFetchFunnels(funnelId) {
  const isMounted = useMounted();
  const [cachedFunnelId, setCachedFunnelId] = useState();
  const [rejectedStageId, setRejectedStageId] = useState();
  const [funnelStages, setFunnelStages] = useState([]);

  useEffect(() => {
    if (isMounted() && funnelId && funnelId !== cachedFunnelId) {
      fetchFunnels(funnelId, (stageArr) => {
        setCachedFunnelId(funnelId);

        const stages = stageArr.reduce((acc, { stageId, name, order, type, workflowDetails }) => {
          if (type === 'REJECTED') setRejectedStageId(stageId);

          acc.splice(order, 0, {
            id: stageId,
            label: `${order + 1}. ${name}`,
            stageId,
            type,
            name,
            order,
            workflowDetails,
          });
          return acc;
        }, []);

        setFunnelStages(stages);
      });
    }
  }, [cachedFunnelId, funnelId, isMounted]);

  return [funnelStages, rejectedStageId, updateFunnel];
}
