import React, { Fragment, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Input, Row, Col } from 'reactstrap';
import { SectionToggle } from '@Base/SectionDividers';
import { FormGroup } from '@Base/Forms/Custom/CommonComponents';
import { CancelButton, CreateButton, EditButton } from '@Base/Buttons';
import { useMounted } from '@Base/hooks';
import { mapErrors } from '@JS/utils/validation';
import ErrorFeedback from '@Base/ErrorFeedback/ErrorFeedback';

function PolarisKeyMapping({ onUpdate, accountAccess, accountIdToSiteId, hiddenValueString }) {
  const isMounted = useMounted();
  const [keyMap, setKeyMap] = useState({});
  const [isUpdating, setIsUpdating] = useState(false);
  const [errors, setErrors] = useState({});
  const [mapChanged, setMapChanged] = useState(false);

  useEffect(() => {
    if (isMounted() && Object.keys(accountIdToSiteId).length) {
      setKeyMap((prevState) => ({ ...prevState, ...accountIdToSiteId }));
    }
  }, [accountIdToSiteId, isMounted]);

  function handleChange(id, value) {
    const updated = { ...keyMap, [id]: value };
    setKeyMap(updated);

    const hasChanged = Object.values(updated).some(
      (keys) => keys.private !== hiddenValueString || keys.public !== hiddenValueString,
    );
    setMapChanged(hasChanged);
  }

  function handleUpdate(update) {
    setIsUpdating(true);

    const trimmedData = Object.entries(keyMap).reduce(
      (acc, [accId, keys]) => ({
        ...acc,
        [accId]: {
          public: keys.public.trim().toLowerCase(),
          private: keys.private.trim().toLowerCase(),
        },
      }),
      {},
    );

    const validationObj = Object.entries(trimmedData).reduce((acc, [accId, keys]) => {
      const errMsgs = [];
      const { public: publicKey, private: privateKey } = keys;
      const isSet = publicKey === hiddenValueString && privateKey === hiddenValueString;

      if (!isSet) {
        if (publicKey.length && privateKey.length && publicKey === privateKey) {
          errMsgs.push('Public / Private keys cannot be the same');
        }

        if (publicKey.length && !privateKey.length) {
          errMsgs.push('Private key required');
        } else if (!publicKey.length && privateKey.length) {
          errMsgs.push('Public key required');
        }

        if (!/^[a-zA-Z0-9]*$/.test(publicKey)) {
          errMsgs.push('Public key must be alpha numeric only');
        }

        if (!/^[a-zA-Z0-9]*$/.test(privateKey)) {
          errMsgs.push('Private key must be alpha numeric only');
        }
      }

      return {
        ...acc,
        [accId]: {
          invalid: !!errMsgs.length,
          errors: errMsgs,
        },
      };
    }, {});

    const { messages, hasErrors } = mapErrors(validationObj);
    setErrors(messages);

    if (!hasErrors && Object.keys(keyMap).length) {
      onUpdate(
        keyMap,
        () => {
          setIsUpdating(false);
          setMapChanged(false);
        },
        update,
      );
    } else {
      setIsUpdating(false);
    }
  }

  const hasKeyMaps = Object.entries(accountIdToSiteId).reduce((acc, [accId, keys]) => {
    if (keys.private?.length && keys.public?.length) acc.push(accId);
    return acc;
  }, []);

  return (
    <Fragment>
      {!hasKeyMaps.length && (
        <div className="alert alert-warning">
          <p>Account Access Keys are not set up</p>
        </div>
      )}
      <SectionToggle heading="Access Keys" chevronFullRight={false}>
        {accountAccess.map((acc) => {
          const { accountId, accountName } = acc;

          return (
            <Fragment key={accountId}>
              <Row>
                <Col>
                  <h6>{accountName}</h6>
                </Col>
              </Row>
              <Row form>
                <Col xs="6">
                  <FormGroup id={`${accountId}-public`} label="Public Key">
                    <Input
                      id={`${accountId}-public`}
                      value={keyMap[accountId]?.public || ''}
                      onChange={(e) => {
                        handleChange(accountId, {
                          private: keyMap[accountId]?.private || '',
                          public: e.target.value,
                        });
                      }}
                    />
                  </FormGroup>
                </Col>
                <Col xs="6">
                  <FormGroup id={`${accountId}-private`} label="Private Key">
                    <Input
                      id={`${accountId}-private`}
                      value={keyMap[accountId]?.private || ''}
                      onChange={(e) => {
                        handleChange(accountId, {
                          public: keyMap[accountId]?.public || '',
                          private: e.target.value,
                        });
                      }}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <ErrorFeedback message={errors[accountId]} className="mb-3" />
                </Col>
              </Row>
            </Fragment>
          );
        })}
        {!hasKeyMaps.length ? (
          <CreateButton
            className="mt-2"
            floatRight={false}
            disabled={isUpdating}
            label={`${isUpdating ? 'Creating' : 'Create'} Access Keys`}
            action={() => handleUpdate()}
          />
        ) : (
          !!hasKeyMaps.length &&
          mapChanged && (
            <Fragment>
              <EditButton
                className="mt-2"
                floatRight={false}
                disabled={isUpdating}
                label={`${isUpdating ? 'Updating' : 'Update'} Access Keys`}
                action={() => handleUpdate(true)}
              />
              <CancelButton
                className="mt-2 ms-2"
                label="Reset"
                floatRight={false}
                disabled={isUpdating}
                action={() => {
                  setKeyMap(accountIdToSiteId);
                  setMapChanged(false);
                }}
              />
            </Fragment>
          )
        )}
      </SectionToggle>
    </Fragment>
  );
}

PolarisKeyMapping.propTypes = {
  accountAccess: PropTypes.arrayOf(PropTypes.shape()),
  onUpdate: PropTypes.func,
  accountIdToSiteId: PropTypes.shape(),
  hiddenValueString: PropTypes.string,
};

PolarisKeyMapping.defaultProps = {
  accountAccess: [],
  onUpdate: () => {},
  accountIdToSiteId: {},
  hiddenValueString: '*****',
};

export default PolarisKeyMapping;
