// Contains a set of API calls to middleware services in order to get data around events
import axios from 'axios';
import Poller from 'api-poll';
import config from '../../config/config';
import { store } from '@JS/store/index';
import { getCommonHeaders, mapErrorResponse, mapLoginErrorResponse } from '../common-api-utils';
import { requestStatuses } from '@JS/constants/requestStatuses';

function commonHeaders() {
  const { getState } = store;
  const {
    userData: {
      userAuth: { token },
    },
  } = getState();
  const {
    auth: { tenantId },
  } = config;

  return {
    Authorization: `Bearer ${token}`,
    'X-FusionAuth-TenantId': tenantId,
    'Content-Type': 'application/x-www-form-urlencoded',
  };
}

function commonHeadersNoAuth() {
  const {
    auth: { tenantId },
  } = config;

  return {
    Tenant: tenantId,
    'Content-Type': 'application/x-www-form-urlencoded',
  };
}

function getRefreshToken() {
  const { getState } = store;
  const {
    userData: {
      userAuth: { refreshToken },
    },
  } = getState();
  return refreshToken;
}

export const AuthAPI = {
  checkLogin: () =>
    axios
      .get(`${config.api.middlewareAPIURL}${config.api.accountServicePath}${config.account.userStatusPath}`, {
        headers: commonHeaders(),
      })
      .then(() => requestStatuses.OK)
      .catch((error) => mapErrorResponse(error.response)),
  logout: () => {
    const refreshToken = getRefreshToken();

    return axios
      .get(`${config.api.middlewareAPIURL}${config.auth.logoutPath}?refreshToken=${refreshToken}`, {
        headers: commonHeaders(),
      })
      .then(() => requestStatuses.OK)
      .catch((error) => mapErrorResponse(error.response));
  },
};

export async function logout() {
  return axios
    .get(`${config.api.middlewareAPIURL}${config.auth.logoutPath}?refreshToken=${getRefreshToken()}`, {
      headers: commonHeaders(),
    })
    .then((response) => response.data)
    .catch((error) => mapErrorResponse(error.response));
}

export async function getUser() {
  try {
    const result = await axios.get(`${config.api.middlewareAPIURL}${config.account.getUserPath}`, {
      headers: getCommonHeaders(),
    });

    if (result.status === 200) return result.data;
    return null;
  } catch (error) {
    return mapErrorResponse(error.response);
  }
}

export async function renewSession(token) {
  let refreshToken = token;

  // Refresh token no set
  if (!refreshToken) refreshToken = getRefreshToken();

  try {
    const result = await axios.get(
      // eslint-disable-next-line max-len
      `${config.api.middlewareAPIURL}${config.account.getUserPath}${config.auth.refreshTokenPath}?refreshToken=${refreshToken}`,
      { headers: commonHeadersNoAuth() },
    );
    if (result.status === 201 || result.status === 200) return result.data;
    return null;
  } catch (error) {
    return mapErrorResponse(error.response);
  }
}

export async function login(username, password, twoFactorTrustId) {
  try {
    const authStr = btoa(`${username}:${password}`);

    let url = `${config.api.middlewareAPIURL}${config.auth.authPath}`;
    if (twoFactorTrustId) url += `?twoFactorTrustId=${twoFactorTrustId}`;
    const result = await axios.get(url, {
      headers: {
        Authorization: `Basic ${authStr}`,
        Tenant: `${config.auth.tenantId}`,
      },
    });

    if (result.status === 201 || result.status === 200 || result.status === 203) return result.data;
    return null;
  } catch (error) {
    return mapLoginErrorResponse(error.response);
  }
}

export async function startPasswordlessLogin(email) {
  try {
    const url = `${config.api.middlewareAPIURL}${config.auth.startPasswordlessAuthPath}?emailAddress=${email}`;

    const result = await axios.get(url, {
      headers: {
        Tenant: `${config.auth.tenantId}`,
      },
    });

    if (result.status === 201 || result.status === 200) return result.data;
    return null;
  } catch (error) {
    return mapLoginErrorResponse(error.response);
  }
}

export async function passwordlessLogin(t, twoFactorTrustId) {
  try {
    let url = `${config.api.middlewareAPIURL}${config.auth.passwordlessAuthPath}?t=${t}`;
    if (twoFactorTrustId) url += `?twoFactorTrustId=${twoFactorTrustId}`;
    const result = await axios.get(url, {
      headers: {
        Tenant: `${config.auth.tenantId}`,
      },
    });

    if (result.status === 201 || result.status === 200) return result.data;
    return null;
  } catch (error) {
    return mapLoginErrorResponse(error.response);
  }
}

export async function twoFactorLogin(code, twoFactorId, trustBrowser) {
  try {
    const authObj = {
      code,
      twoFactorId,
      trustBrowser,
    };

    const result = await axios.post(`${config.api.middlewareAPIURL}${config.auth.twoFactorAuthPath}`, authObj, {
      headers: {
        Tenant: `${config.auth.tenantId}`,
      },
    });

    if (result.status === 201 || result.status === 200) return result.data;
    return null;
  } catch (error) {
    return mapLoginErrorResponse(error.response);
  }
}

export async function forgotPassword(email) {
  try {
    const result = await axios.get(
      `${config.api.middlewareAPIURL}${config.account.getUserPath}${config.auth.forgotPasswordPath}/${email}`,
      {
        headers: {
          Tenant: `${config.auth.tenantId}`,
        },
      },
    );

    if (result.status === 201 || result.status === 200) return result.status;
    return null;
  } catch (error) {
    return mapErrorResponse(error.response);
  }
}

export async function resetPassword(email, password, token, id) {
  try {
    const body = {
      email,
      password,
      token,
      userId: id,
    };

    const result = await axios.post(
      `${config.api.middlewareAPIURL}${config.account.getUserPath}${config.account.resetPasswordPath}`,
      body,
      {
        headers: {
          Tenant: `${config.auth.tenantId}`,
        },
      },
    );

    if (result.status === 201 || result.status === 200) return result.status;
    return null;
  } catch (error) {
    return mapErrorResponse(error.response);
  }
}

/**
 * Gets all the data we need to login the user
 */
export async function getUserLoginProfile() {
  const result = await axios.get(
    `${config.api.middlewareAPIURL}${config.account.getUserPath}${config.account.loginProfilePath}`,
    { headers: getCommonHeaders() },
  );

  if (result.status === 201 || result.status === 200) return result.data;
  return null;
}

export async function getQRCodeFor2FA() {
  try {
    const result = await axios.get(`${config.api.middlewareAPIURL}${config.account.getQRCodePath}`, {
      headers: getCommonHeaders(),
    });

    if (result.status === 200) return result.data;
    return null;
  } catch (error) {
    return mapErrorResponse(error.response);
  }
}

export async function enable2FA(code, secret) {
  try {
    const result = await axios.post(
      `${config.api.middlewareAPIURL}${config.account.enableTwoFactorPath}`,
      { code, secret },
      { headers: getCommonHeaders() },
    );

    if (result.status === 200) return result.data;
    return null;
  } catch (error) {
    return mapErrorResponse(error.response);
  }
}

export async function disable2FA(code) {
  try {
    const result = await axios.delete(`${config.api.middlewareAPIURL}${config.account.disableTwoFactorPath}/${code}`, {
      headers: getCommonHeaders(),
    });

    if (result.status === 200) return result.data;
    return null;
  } catch (error) {
    return mapErrorResponse(error.response);
  }
}

export async function check2Factor() {
  try {
    const result = await axios.get(`${config.api.middlewareAPIURL}${config.account.checkTwoFactorPath}`, {
      headers: getCommonHeaders(),
    });

    if (result.status === 200) return result.data;
    return null;
  } catch (error) {
    return mapErrorResponse(error.response);
  }
}

export async function getIDPConfiguration(email, twoFactorTrustId) {
  try {
    let url = `${config.api.middlewareAPIURL}${config.auth.idpConfigPath}?email=${email}`;
    if (twoFactorTrustId) url += `&twoFactorTrustId=${twoFactorTrustId}`;
    const result = await axios.get(url, {
      headers: {
        Tenant: `${config.auth.tenantId}`,
      },
    });

    if (result.status === 201 || result.status === 200) return result.data;
    return null;
  } catch (error) {
    return mapLoginErrorResponse(error.response);
  }
}

export async function processIDPAuthRequest(code, state, sessionState, twoFactorTrustId) {
  try {
    let url = `${config.api.middlewareAPIURL}${config.auth.idpBasePath}`;
    if (twoFactorTrustId) url += `&twoFactorTrustId=${twoFactorTrustId}`;
    const result = await axios.post(
      url,
      { code, sessionState, stateId: state },
      {
        headers: {
          Tenant: `${config.auth.tenantId}`,
        },
      },
    );

    if (result.status === 201 || result.status === 200) return result.data;
    return null;
  } catch (error) {
    return mapLoginErrorResponse(error.response);
  }
}

export async function getIDPLoginRequest(stateId) {
  try {
    const result = await axios.get(`${config.api.middlewareAPIURL}${config.auth.idpBasePath}?stateId=${stateId}`, {
      headers: {
        Tenant: `${config.auth.tenantId}`,
      },
    });

    if (result.status === 200) return result.data;
    return null;
  } catch (error) {
    return mapErrorResponse(error.response);
  }
}

export async function getZendeskSSO() {
  const result = await axios.get(`${config.api.middlewareAPIURL}${config.auth.zenDeskSSOPath}`, {
    headers: getCommonHeaders(),
  });

  if (result.status === 201 || result.status === 200) return result.data;
  return null;
}
