import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Form, FormGroup, Label, Col, Input } from 'reactstrap';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import { Loader } from '@Base/Loading';
import Required from '@Base/Forms/Custom/Required';
import Select from 'react-select';
import { useFormKeypress } from '@Base/hooks';
import ColorInput from './ColorInput';
import { getTalentHubStyleSettings, saveTalentHubStyleSettings } from '@API/AccountAPI';
import ImageUpload from '../AccountSettings/FlexiForms/components/FormStyles/FileUpload';
import { CreateButton, IconButton } from '@Base/Buttons';
import { retryableAPICall } from '@API/common-api-utils';
import { checkPermissions } from '@JS/auth/AuthUtils';
import { validateColors } from '@Base/ColorPicker/ColorPicker';
import ErrorFeedback from '@Base/ErrorFeedback/ErrorFeedback';
import EnhancedCard from '../Common/EnhancedCard';
import EnhancedCardTitle from '../Common/EnhancedCardTitle';

const StyledFormGroup = styled(FormGroup)`
  margin-bottom: 1rem;
`;

const StyledLabel = styled(Label)`
  font-weight: 400;
`;

const StyledInput = styled(Input)`
  border-radius: 8px;
  border: 1px solid #ced4da;
  &:focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  }
`;

function TalentHubBranding({ tenant }) {
  const formRef = useFormKeypress();
  const [allThemes, setAllThemes] = useState([]);
  const [formData, setFormData] = useState({});
  const [initialFormData, setInitialFormData] = useState({});
  const [errors, setErrors] = useState({});
  const [isSaving, setIsSaving] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  function handleColorChange(id, color) {
    const colorStr = color.startsWith('#') ? color : `#${color}`;
    const updatedData = {
      ...formData,
      theme: {
        ...formData?.theme,
        muiTheme: {
          ...formData?.theme?.muiTheme,
          data: {
            ...formData?.theme?.muiTheme?.data,
            palette: {
              ...formData?.theme?.muiTheme?.data.palette,
              [id]: {
                main: colorStr,
              },
            },
          },
        },
      },
    };

    setErrors({ ...errors, ...validateColors(id, colorStr) });
    setFormData(updatedData);
  }

  async function handleSave() {
    setIsSaving(true);

    try {
      let newErrors = {};

      if (!formData.privacyPolicyURL) {
        newErrors.privacyPolicyURL = 'Privacy Policy URL is required';
      }

      if (!formData.termsOfUseURL) {
        newErrors.termsOfUseURL = 'Terms of Use URL is required';
      }

      if (!formData.metaDescription) {
        newErrors.metaDescription = 'Meta Description is required';
      }

      if (!formData.title) {
        newErrors.title = 'Meta Title is required';
      }

      const primaryColour = validateColors('primary', formData?.theme?.muiTheme?.data?.palette?.primary?.main);
      const secondaryColour = validateColors('secondary', formData?.theme?.muiTheme?.data?.palette?.secondary?.main);
      const errorObj = { ...newErrors, ...primaryColour, ...secondaryColour };
      setErrors(errorObj);

      const hasErrors = Object.values(errorObj).some((errMsg) => !!errMsg.length);

      if (!hasErrors) {
        const resp = await retryableAPICall(() =>
          saveTalentHubStyleSettings({
            ...formData,
            host: formData.host || '',
            loginPath: formData.loginPath || '/login',
            llTokenPath: formData.llTokenPath || '/action',
            tenantId: formData.tenantId || tenant,
            tenantName: formData.tenantName || '',
          }),
        );

        if (typeof resp === 'string') {
          toast.error('Error saving styles');
        } else {
          const updatedThemes = allThemes.reduce((acc, a) => {
            if (a.uuid === formData?.uuid) {
              return [...acc, formData];
            }
            return [...acc, a];
          }, []);
          setAllThemes(updatedThemes);
          setInitialFormData(formData);
          toast.success('TalentHub styles saved successfully');
        }
      }
    } catch (e) {
      toast.error('Error saving styles');
    } finally {
      setIsSaving(false);
    }
  }

  useEffect(() => {
    const loadStyles = async () => {
      try {
        setIsLoading(true);
        const resp = await retryableAPICall(() => getTalentHubStyleSettings());
        if (typeof resp === 'string') {
          toast.error('Error saving styles');
        } else {
          setAllThemes(resp);
          const singleTheme = resp && resp.length > 0 ? resp[0] : {};
          setFormData(singleTheme);
          setInitialFormData(JSON.parse(JSON.stringify(singleTheme)));
        }
      } catch (e) {
        toast.error('Error Loading styles');
      } finally {
        setIsLoading(false);
      }
    };

    if (!isLoading && !Object.keys(formData).length) {
      loadStyles();
    }
  }, []);

  if (!checkPermissions(['admin:branding'])) return null;

  const isDiff = JSON.stringify(formData) !== JSON.stringify(initialFormData);
  const foundTheme = allThemes.find((a) => a.uuid === formData?.uuid);

  return (
    <EnhancedCard className="mt-3">
      <EnhancedCardTitle title="Talent Hub Settings" subtitle="Customise your Talent Hub experience" />
      {allThemes?.length > 1 && (
        <StyledFormGroup row>
          <StyledLabel sm="2" for="Url">
            Account:
          </StyledLabel>
          <Col sm="3">
            <FormGroup>
              <Select
                className="react-select"
                placeholder="Change Account"
                options={allThemes.map((a) => ({ label: a.host, value: a.uuid }))}
                value={{
                  value: foundTheme?.uuid,
                  label: foundTheme?.host,
                }}
                onChange={(val) => {
                  const selectedTheme = allThemes.find((a) => a.uuid === val.value);
                  setInitialFormData(selectedTheme);
                  setFormData(selectedTheme);
                }}
              />
            </FormGroup>
          </Col>
        </StyledFormGroup>
      )}
      {isLoading ? (
        <Loader className="my-5" />
      ) : (
        <Form innerRef={formRef}>
          <StyledFormGroup row>
            <StyledLabel sm="2" for="Url">
              URL:
            </StyledLabel>
            <Col sm="3">
              <FormGroup>
                <StyledInput disabled value={formData?.host ? `https://${formData?.host}` : ''} type="text" />
              </FormGroup>
            </Col>
          </StyledFormGroup>
          <StyledFormGroup row>
            <StyledLabel sm="2" for="primaryColour">
              Primary Color:
              <Required />
            </StyledLabel>
            <Col sm="3">
              <FormGroup>
                <ColorInput
                  id="primaryColour-text-colour"
                  onChange={(val) => handleColorChange('primary', val)}
                  value={formData?.theme?.muiTheme?.data?.palette?.primary?.main}
                />
                <ErrorFeedback message={errors.primary} />
              </FormGroup>
            </Col>
          </StyledFormGroup>
          <StyledFormGroup row>
            <StyledLabel sm="2" for="secondaryColour">
              Secondary Color:
              <Required />
            </StyledLabel>
            <Col sm="3">
              <FormGroup>
                <ColorInput
                  id="secondaryColour-text-colour"
                  onChange={(val) => handleColorChange('secondary', val)}
                  value={formData?.theme?.muiTheme?.data?.palette?.secondary?.main}
                />
                <ErrorFeedback message={errors.secondary} />
              </FormGroup>
            </Col>
          </StyledFormGroup>
          <StyledFormGroup row>
            <StyledLabel sm="2" for="logo">
              Logo:
            </StyledLabel>
            <Col sm="4">
              <ImageUpload
                onChange={(data) => {
                  setFormData({ ...formData, theme: { ...formData?.theme, logo: data } });
                }}
                value={formData?.theme?.logo}
              />
            </Col>
          </StyledFormGroup>
          <StyledFormGroup row>
            <StyledLabel sm="2" for="logo">
              Background Image:
            </StyledLabel>
            <Col sm="4">
              <ImageUpload
                onChange={(data) => {
                  setFormData({ ...formData, theme: { ...formData?.theme, background: data } });
                }}
                value={formData?.theme?.background}
              />
            </Col>
          </StyledFormGroup>
          <StyledFormGroup row>
            <StyledLabel sm="2" for="logo">
              Welcome Message:
              <Required />
            </StyledLabel>
            <Col sm="4">
              <StyledInput
                disabled={isSaving}
                value={formData.welcomeMessage || ''}
                onChange={(e) => setFormData({ ...formData, welcomeMessage: e.target.value })}
                type="text"
              />
              <ErrorFeedback message={errors.welcomeMessage} />
            </Col>
          </StyledFormGroup>
          <StyledFormGroup row>
            <StyledLabel sm="2" for="logo">
              Privacy Policy URL:
              <Required />
            </StyledLabel>
            <Col sm="4">
              <StyledInput
                disabled={isSaving}
                value={formData.privacyPolicyURL || ''}
                onChange={(e) => setFormData({ ...formData, privacyPolicyURL: e.target.value })}
                type="text"
              />
              <ErrorFeedback message={errors.privacyPolicyURL} />
            </Col>
          </StyledFormGroup>
          <StyledFormGroup row>
            <StyledLabel sm="2" for="logo">
              Terms of Use URL:
              <Required />
            </StyledLabel>
            <Col sm="4">
              <StyledInput
                disabled={isSaving}
                value={formData.termsOfUseURL || ''}
                onChange={(e) => setFormData({ ...formData, termsOfUseURL: e.target.value })}
                type="text"
              />
              <ErrorFeedback message={errors.termsOfUseURL} />
            </Col>
          </StyledFormGroup>
          <StyledFormGroup row>
            <StyledLabel sm="2" for="logo">
              Meta Description:
              <Required />
            </StyledLabel>
            <Col sm="4">
              <StyledInput
                disabled={isSaving}
                value={formData.metaDescription || ''}
                onChange={(e) => {
                  setFormData({ ...formData, metaDescription: e.target.value });
                }}
                type="text"
              />
              <ErrorFeedback message={errors.metaDescription} />
            </Col>
          </StyledFormGroup>
          <StyledFormGroup row>
            <StyledLabel sm="2" for="logo">
              Meta Title:
              <Required />
            </StyledLabel>
            <Col sm="4">
              <StyledInput
                disabled={isSaving}
                value={formData.title || ''}
                onChange={(e) => setFormData({ ...formData, title: e.target.value })}
                type="text"
              />
              <ErrorFeedback message={errors.title} />
            </Col>
          </StyledFormGroup>
          <StyledFormGroup row>
            <StyledLabel sm="2" for="favicon">
              Favicon:
            </StyledLabel>
            <Col sm="4">
              <ImageUpload
                onChange={(data) => {
                  setFormData({ ...formData, theme: { ...formData?.theme, favicon: data } });
                }}
                value={formData?.theme?.favicon}
              />
            </Col>
          </StyledFormGroup>
          <StyledFormGroup row>
            <StyledLabel sm="2" for="logo">
              Welcome Image:
            </StyledLabel>
            <Col sm="4">
              <ImageUpload
                onChange={(data) => {
                  setFormData({ ...formData, welcomeMediaUrl: data });
                }}
                value={formData?.welcomeMediaUrl}
              />
            </Col>
          </StyledFormGroup>
          <div className="mt-4">
            <CreateButton
              label={isSaving ? 'Saving...' : 'Save'}
              isLoading={isSaving}
              disabled={isSaving}
              floatRight={false}
              action={handleSave}
            />
            {isDiff && (
              <IconButton
                className="ms-2"
                iconClassName="btn-undo"
                label="Undo"
                disabled={isSaving}
                floatRight={false}
                action={() => setFormData({ ...initialFormData })}
              />
            )}
          </div>
        </Form>
      )}
    </EnhancedCard>
  );
}

TalentHubBranding.propTypes = {
  tenant: PropTypes.string,
};

TalentHubBranding.defaultProps = {
  tenant: '',
};

function mapStateToProps(state) {
  const {
    userData: {
      userDetails: {
        data: { tenant },
      },
    },
  } = state;

  return {
    tenant,
  };
}

export default connect(mapStateToProps)(TalentHubBranding);
