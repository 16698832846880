import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Col, Row } from 'reactstrap';
import { NoDataMessage } from '@Base/ErrorScreen';
import ResolveLogo from '@Base/SVG/ResolveLogo';
import { CreateButton } from '@Base/Buttons';

function ClientAdminNoDataMessage({ companyLogo, title, message, btnProps }) {
  const { onClick, label } = btnProps;

  return (
    <NoDataMessage
      img={<ResolveLogo imgSrc={companyLogo} imgWidth="60px" svgWidth="4em" />}
      title={title}
      message={message}
    >
      {label && (
        <Row className="mb-5">
          <Col>
            <CreateButton action={onClick} label={label} className="float-none mt-3" />
          </Col>
        </Row>
      )}
    </NoDataMessage>
  );
}

ClientAdminNoDataMessage.propTypes = {
  companyLogo: PropTypes.string,
  title: PropTypes.string,
  message: PropTypes.string,
  btnProps: PropTypes.shape({
    onClick: PropTypes.func,
    label: PropTypes.string,
  }),
};

ClientAdminNoDataMessage.defaultProps = {
  companyLogo: null,
  title: null,
  message: null,
  btnProps: {
    onClick: () => {},
  },
};

function mapStateToProps(state) {
  const {
    companyData: {
      companyDetails: { companyLogo },
    },
    styles,
  } = state;
  const { logoUrl } = styles;
  return { companyLogo: logoUrl || companyLogo };
}

export default connect(mapStateToProps)(ClientAdminNoDataMessage);
