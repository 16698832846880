import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { IndeterminateCheckbox } from '@Base/Forms/Custom/CommonComponents';
import { EditButton } from '@Base/Buttons';

import { checkPermissions } from '@JS/auth/AuthUtils';

function StageInfoDisplaySettings({ stageConfig, type, onUpdate }) {
  const options = [
    {
      id: 'showTags',
      label: 'Tags',
      value: 'showTags',
      defaultTypes: [
        'REJECTED',
        'INTERVIEW',
        'REFERENCES_REQUESTED',
        'ONBOARDING_REQUESTED',
        'ONBOARDING_RECIEVED',
        'HIRED',
        'CUSTOM',
        'DEFAULT',
      ],
    },
    {
      id: 'showAppliedOn',
      label: 'Applied On Date',
      value: 'showAppliedOn',
      defaultTypes: ['DEFAULT'],
    },
    {
      id: 'showEvent',
      label: 'Show Event/Invite Status',
      value: 'showEvent',
      defaultTypes: ['INTERVIEW'],
    },
    {
      id: 'showHiredOn',
      label: 'Hired On',
      value: 'showHiredOn',
      defaultTypes: ['HIRED'],
      hiddenTypes: [
        'REJECTED',
        'INTERVIEW',
        'REFERENCES_REQUESTED',
        'ONBOARDING_REQUESTED',
        'ONBOARDING_RECIEVED',
        'CUSTOM',
        'DEFAULT',
      ],
    },
  ];

  if (checkPermissions(['candidate:onboarding:read'])) {
    options.push({
      id: 'showOnboarding',
      label: 'Show Onboarding Status',
      value: 'showOnboarding',
      defaultTypes: ['ONBOARDING', 'ONBOARDING'],
    });
  }

  if (checkPermissions(['candidate:references:view'])) {
    options.push({
      id: 'showReference',
      label: 'Show Reference Status',
      value: 'showReference',
      defaultTypes: ['REFERENCES'],
    });
  }

  if (checkPermissions(['rtw:read'])) {
    options.push({
      id: 'showRTW',
      label: 'Show RTW Status',
      value: 'showRTW',
      defaultTypes: ['RTW'],
    });
  }

  const [checkboxState, setCheckboxState] = useState({});

  useEffect(() => {
    const initCheckboxState = {};

    options.forEach((opt) => {
      const { id, defaultTypes = [], hiddenTypes = [] } = opt;
      if (!stageConfig) {
        initCheckboxState[id] = defaultTypes.includes(type) && !hiddenTypes.includes(type) ? 1 : 0;
      } else {
        initCheckboxState[id] = stageConfig[id] ? 1 : 0;
      }
    });
    setCheckboxState(initCheckboxState);
  }, [stageConfig]);

  function handleChange(id, value) {
    const checkboxStateCp = { ...checkboxState };
    checkboxStateCp[id] = value;
    setCheckboxState(checkboxStateCp);
  }

  return (
    <div className="scroll-wrapper">
      <div className="tag-list">
        {options.map((opt) => {
          const { id, label, hiddenTypes = [] } = opt;
          if (hiddenTypes.includes(type)) {
            return null;
          }
          return (
            <div key={id} className="tag-list-cell">
              <IndeterminateCheckbox
                id={id}
                value={checkboxState[id]}
                className="p-2"
                labelClassName="tag-checkbox-label"
                onChange={(val) => handleChange(id, val)}
              >
                {label}
              </IndeterminateCheckbox>
            </div>
          );
        })}
      </div>
      <EditButton
        className="mt-2"
        floatRight={false}
        label="Update"
        action={() => {
          onUpdate(checkboxState);
        }}
      />
    </div>
  );
}

StageInfoDisplaySettings.propTypes = {
  stageConfig: PropTypes.shape(),
  type: PropTypes.string,
  onUpdate: PropTypes.func,
};

StageInfoDisplaySettings.defaultProps = {
  stageConfig: null,
  type: null,
  onUpdate: () => {},
};

export default StageInfoDisplaySettings;
