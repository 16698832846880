import React, { useState } from 'react';
import GooglePlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-google-places-autocomplete';
import styled from 'styled-components';
import ManualAddressInputs from './ManualAddressInputs';
import config from '../../../../../config/config';

const countryList = [
  { code: 'GB', name: 'United Kingdom' },
  { code: 'IE', name: 'Ireland' },
  { code: 'DE', name: 'Germany' },
  { code: 'US', name: 'United States' },
  { code: 'DK', name: 'Denmark' },
  { code: 'FR', name: 'France' },
  { code: 'HK', name: 'Hong Kong' },
  { code: 'NL', name: 'Netherlands' },
  { code: 'ES', name: 'Spain' },
  { code: 'JP', name: 'Japan' },
  { code: 'IT', name: 'Italy' },
  { code: 'KR', name: 'South Korea' },
  { code: 'XX', name: 'All' },
];

function countryCodeToFlagEmoji(countryCode) {
  const codePoints = countryCode
    .toUpperCase()
    .split('')
    .map((char) => 127397 + char.charCodeAt(0));
  return String.fromCodePoint(...codePoints);
}

const FlagContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px 0px 0px 0.7rem;
  cursor: pointer;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 10;

  &#XX {
    padding: 0px 0px 3px 5px;
  }
`;

const DialogOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: ${(props) => (props.isOpen ? 'flex' : 'none')};
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const DialogContent = styled.div`
  background-color: white;
  border-radius: 0.375rem;
  max-width: 28rem;
  width: 100%;
  max-height: 90vh;
  overflow: hidden;
  box-shadow:
    0 20px 25px -5px rgba(0, 0, 0, 0.1),
    0 10px 10px -5px rgba(0, 0, 0, 0.04);
`;

const DialogTitle = styled.h3`
  font-size: 1.125rem;
  font-weight: 600;
  padding: 1rem 1.5rem;
  border-bottom: 1px solid #e2e8f0;
  margin: 0;
`;

const DialogList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  max-height: 18.75rem;
  overflow-y: auto;
`;

const DialogItem = styled.li`
  padding: 0.75rem 1.5rem;
  cursor: pointer;
  &:hover {
    background-color: #f7fafc;
  }
`;

const customStyles = {
  control: (provided) => ({
    ...provided,
    paddingLeft: '2.5rem',
  }),
  noOptionsMessage: (provided) => ({
    ...provided,
    color: '#4a5568',
    fontSize: '0.875rem',
  }),
};

function InternationalAddressLookup({ onChange, formData, uiSchema, errorSchema, idSchema, formContext }) {
  const [address, setAddress] = useState(formData);
  const [manualEntry, setManualEntry] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState(uiSchema?.defaultCountry || 'GB');
  const [countryPickerOpen, setCountryPickerOpen] = useState(false);

  const handlePlaceSelect = (place) => {
    if (!place) {
      setAddress(null);
      onChange({});
      return;
    }

    // Use the built-in utility function to get the address details
    geocodeByAddress(place.value.description)
      .then((results) => {
        if (results && results.length > 0) {
          const addressDetails = {
            addressLine1: '',
            addressLine2: '',
            addressLine3: '',
            city: '',
            county: '',
            postCode: '',
            country: '',
            formattedAddress: results[0].formatted_address || '',
          };
          results[0].address_components.forEach((component) => {
            if (component.types.includes('street_number')) {
              addressDetails.addressLine1 = component.long_name;
            } else if (component.types.includes('route')) {
              addressDetails.addressLine2 = component.long_name;
            } else if (component.types.includes('sublocality_level_1')) {
              addressDetails.addressLine3 = component.long_name;
            } else if (component.types.includes('locality') || component.types.includes('postal_town')) {
              addressDetails.city = component.long_name;
            } else if (component.types.includes('administrative_area_level_2')) {
              addressDetails.county = component.long_name;
            } else if (component.types.includes('postal_code')) {
              addressDetails.postCode = component.long_name;
            } else if (component.types.includes('country')) {
              addressDetails.country = component.short_name;
            }
          });

          return getLatLng(results[0]).then((latLng) => {
            addressDetails.lat = latLng.lat;
            addressDetails.long = latLng.lng;

            setAddress(addressDetails);
            onChange(addressDetails);
          });
        }
      })
      .catch((error) => {
        console.error('Error geocoding address', error);
      });
  };

  const handleAddressChange = (newValue) => {
    setAddress(newValue);
    onChange(newValue);
  };

  const handleCountryChange = (countryCode) => {
    setSelectedCountry(countryCode);
  };

  const toggleCountryPicker = () => setCountryPickerOpen((prev) => !prev);
  const toggleManualEntry = () => setManualEntry((prev) => !prev);

  return (
    <div className="">
      {!address?.formattedAddress ? (
        <p
          className="tw-cursor-pointer tw-ml-auto tw-text-xs tw-text-right tw-absolute tw-right-8"
          onClick={toggleManualEntry}
        >
          Enter address manually
        </p>
      ) : null}
      <div className="tw-mb-4">
        <label className="tw-block tw-mb-2 tw-text-sm tw-font-medium tw-text-gray-700">
          Address <span className="tw-text-red-500">*</span>
        </label>
        <div className="tw-relative">
          <FlagContainer onClick={toggleCountryPicker} id={selectedCountry}>
            {countryCodeToFlagEmoji(selectedCountry)}
          </FlagContainer>
          <GooglePlacesAutocomplete
            apiKey={config.googleMaps.apiKey}
            selectProps={{
              value: address?.formattedAddress
                ? { label: address.formattedAddress, value: { description: address.formattedAddress } }
                : null,
              onChange: handlePlaceSelect,
              placeholder: 'Search for an address...',
              isClearable: true,
              components: {
                DropdownIndicator: () => null,
              },
              styles: {
                ...customStyles,
                control: (provided) => ({
                  ...provided,
                  paddingLeft: '2rem',
                  borderColor: errorSchema?.formattedAddress?.__errors ? '#ef4444' : provided.borderColor,
                }),
              },
              noOptionsMessage: ({ inputValue }) => {
                if (!inputValue) return 'Start typing to search for an address';
                if (inputValue.length < 3) return `Please type at least 3 characters (${inputValue.length}/3)`;
                return 'No results found, try a more specific search';
              },
              filterOption: () => true,
              onInputChange: (inputValue) => {
                if (inputValue.length < 3) {
                  return inputValue;
                }
                return inputValue;
              },
            }}
            autocompletionRequest={{
              componentRestrictions: {
                country: selectedCountry === 'XX' ? undefined : [selectedCountry],
              },
            }}
            minLengthAutocomplete={3}
            debounce={500}
            withSessionToken
          />
        </div>
        {errorSchema?.formattedAddress?.__errors && (
          <p className="tw-mt-1 tw-text-xs tw-text-red-500">{errorSchema.formattedAddress.__errors[0]}</p>
        )}
      </div>

      <DialogOverlay isOpen={countryPickerOpen} onClick={toggleCountryPicker}>
        <DialogContent onClick={(e) => e.stopPropagation()}>
          <DialogTitle>Location</DialogTitle>
          <DialogList>
            {countryList.map((country) => (
              <DialogItem
                key={country.code}
                onClick={() => {
                  handleCountryChange(country.code);
                  toggleCountryPicker();
                }}
              >
                {countryCodeToFlagEmoji(country.code)} {country.name}
              </DialogItem>
            ))}
          </DialogList>
        </DialogContent>
      </DialogOverlay>

      {(address?.country || manualEntry) && (
        <ManualAddressInputs
          country={selectedCountry}
          value={address}
          onChange={handleAddressChange}
          idNameSpace={idSchema['$id']}
          formContext={formContext}
          isRequired={true}
          errors={errorSchema}
        />
      )}
    </div>
  );
}

export default InternationalAddressLookup;
