// eslint-disable-next-line no-unused-vars
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'reactstrap';
import { TextButton } from '@Base/Buttons';
import { ModalPopup } from '@Base/Modal';
import Select from 'react-select';
import { LoadingScreen } from '../../Base/Loading';
import Iframe from 'react-iframe';
import { eDocPreview } from '../../../api/CandidateAPI/EDocAPI';
import moment from 'moment/moment';
import { Search as SearchIcon, AlertCircle as AlertIcon } from 'lucide-react';

function EDocPreview({
                       candidateId,
                       languagePack,
                       selectedToRequestEDocs = [],
                       disableSend = false,
                       parameters,
                       docFormIds,
                     }) {
  const [showPreviewDocumentModal, setShowPreviewDocumentModal] = useState(false);
  const [selectedPreviewDocument, setSelectedPreviewDocument] = useState(false);
  const [documentGenerated, setDocumentGenerated] = useState(false);
  const [previewUrl, setPreviewUrl] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);

  // Process parameters for API call
  const mappedParams = {};
  let finalParams = [];

  if (parameters) {
    Object.entries(parameters).forEach(([key, value]) => {
      const formName = key.substring(0, key.indexOf(':'));
      const fieldName = key.substring(key.indexOf(':') + 1);

      if (!mappedParams[formName]) {
        mappedParams[formName] = [];
      }

      let val = moment.isMoment(value) ? value.format('DD-MM-YYYY') : value;
      mappedParams[formName].push({ name: fieldName, value: val });
    });

    finalParams = Object.entries(mappedParams).map(([key, value]) => ({
      formId: docFormIds[key],
      parameterValues: value,
    }));
  }

  async function generatePreview() {
    try {
      setIsLoading(true);
      setDocumentGenerated(false);
      setError(false);

      const result = await eDocPreview(candidateId, finalParams, docFormIds);

      if (result && Object.values(result)[0]?.flexiFormView?.url) {
        const url = Object.values(result)[0].flexiFormView.url;
        setPreviewUrl(`${url}&hideSubmit=true`);
        setDocumentGenerated(true);
      } else {
        setError(true);
        console.error('Preview URL not found in response');
      }
    } catch (error) {
      setError(true);
      console.error('Error generating preview:', error);
    } finally {
      setIsLoading(false);
    }
  }

  const handleSelectChange = (val) => {
    setSelectedPreviewDocument(val);
    if (val) {
      generatePreview();
    }
  };

  return (
    <>
      <TextButton
        action={() => setShowPreviewDocumentModal(true)}
        className="btn-outline ms-2"
        disabled={disableSend}
        label={languagePack.previewEDocButtonLabel}
      />

      <ModalPopup
        closeOnOkay
        cancelLabel={languagePack.previewCloseButtonLabel}
        isOpen={showPreviewDocumentModal}
        hideOkayButton
        onCancel={() => {
          setShowPreviewDocumentModal(false);
          setDocumentGenerated(false);
          setPreviewUrl('');
          setSelectedPreviewDocument(false);
          setError(false);
        }}
        onToggle={() => setShowPreviewDocumentModal(false)}
        minHeight={550}
        maxWidth={800}
        title={languagePack.previewEDocLabelTitle}
      >
        <div className='mb-4'>
          <Row>
            <Col>
              <Select
                classNamePrefix='react-select'
                id='filter-category'
                onChange={handleSelectChange}
                options={selectedToRequestEDocs}
                placeholder='Select eDocument To Preview'
                value={selectedPreviewDocument}
              />
            </Col>
          </Row>

          <Row className='mt-4'>
            <Col>
              {isLoading && <LoadingScreen isEmbeded className='mt-4' />}
              {!isLoading && !selectedPreviewDocument && !error && (
                <div style={{ height: '350px' }}
                     className='bg-white text-center d-flex align-items-center justify-content-center'>
                  <div>
                    <SearchIcon
                      size={92}
                      style={{ color: '#e9ecef' }}
                      className='mb-4'
                      strokeWidth={1}
                    />
                    <p className='text-muted'>
                      Select a document above to preview it here
                    </p>
                  </div>
                </div>
              )}
              {error && (
                <div style={{ height: '350px' }}
                     className='bg-white text-center d-flex align-items-center justify-content-center'>
                  <div>
                    <AlertIcon
                      size={92}
                      style={{ color: '#dc3545' }}
                      className='mb-4'
                      strokeWidth={1}
                    />
                    <p className='text-danger'>
                      Unable to generate document preview. Please try again later.
                    </p>
                  </div>
                </div>
              )}
              {documentGenerated && previewUrl && !error && (
                <Iframe
                  url={previewUrl}
                  width='100%'
                  height='450px'
                  frameBorder='0'
                  scrolling='yes'
                  allow='camera;microphone'
                  id='edoc-preview-frame'
                  className='w-100'
                />
              )}
            </Col>
          </Row>
        </div>
      </ModalPopup>
    </>
  );
}

EDocPreview.propTypes = {
  candidateId: PropTypes.string.isRequired,
  languagePack: PropTypes.shape({
    previewEDocButtonLabel: PropTypes.string,
    previewEDocLabelTitle: PropTypes.string,
  }),
  selectedToRequestEDocs: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      label: PropTypes.string,
    }),
  ),
  disableSend: PropTypes.bool,
  parameters: PropTypes.object,
  docFormIds: PropTypes.object,
};

EDocPreview.defaultProps = {
  languagePack: {
    previewEDocButtonLabel: 'Preview',
    previewEDocLabelTitle: 'Preview Document',
  },
  selectedToRequestEDocs: [],
  disableSend: false,
  parameters: {},
  docFormIds: {},
};

export default EDocPreview;
