/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import PropTypes from 'prop-types';
import { Link, useLocation } from 'react-router-dom';
import cx from 'classnames';
import Can from '@Base/RBAC/Can/Can';
import { checkPermissions } from '@JS/auth/AuthUtils';

const RestrictedLink = Can(Link);

const NAV_ITEMS = [
  {
    to: '/',
    title: 'Home',
    label: 'Home',
    touch: true,
    desktop: false,
  },
  {
    to: '/dashboard',
    isHomePage: true,
    title: 'Dashboard',
    permissions: ['dashboard:view'],
    label: 'Dashboard',
    touch: false,
    desktop: true,
  },
  {
    to: '/vacancy',
    isHomePage: true,
    title: 'Vacancies',
    label: 'Vacancies',
    touch: true,
    desktop: true,
  },
  {
    to: '/candidate',
    title: 'Candidates',
    label: 'Candidates',
    permissions: ['candidate:view'],
    touch: true,
    desktop: true,
  },
  {
    to: '/schedule',
    title: 'Schedule',
    label: 'Schedule',
    permissions: ['scheduler:calendar:read'],
    desktop: true,
  },
  {
    to: '/reporting',
    title: 'Reporting',
    label: 'Reporting',
    permissions: ['reporting:read'],
    desktop: true,
  },
  {
    to: '/settings',
    title: 'Settings',
    label: 'Settings',
    permissions: [],
    desktop: true,
  },
];

function getMainPathname(location) {
  let { pathname } = location;

  if (pathname) {
    [pathname] = pathname.substring(1).split('/');
    return pathname ? `/${pathname}` : '/';
  }

  return '/';
}

function NavList({ className, linkClassName, onClick, isTouch }) {
  const location = useLocation();
  const linkClasses = cx('nav-link', linkClassName);
  const pathname = getMainPathname(location);
  const allowDashboard = checkPermissions(['dashboard:view']);

  return (
    <nav className={className}>
      {NAV_ITEMS.map((item) => {
        const { to, title, label, permissions, touch, desktop, isHomePage } = item;

        if ((!touch && isTouch) || (!desktop && !isTouch)) return null;

        let selected = pathname === to;

        if (!selected && isHomePage && !isTouch && pathname === '/') {
          selected = allowDashboard ? to === '/dashboard' : to === '/vacancy';
        }

        const linkProps = {
          key: to,
          className: cx(linkClasses, { selected }),
          to,
          onClick,
          title,
        };

        if (permissions && Array.isArray(permissions)) {
          return (
            <RestrictedLink {...linkProps} permissions={permissions}>
              <span>{label}</span>
            </RestrictedLink>
          );
        }

        return (
          <Link {...linkProps}>
            <span>{label}</span>
          </Link>
        );
      })}
    </nav>
  );
}

NavList.propTypes = {
  className: PropTypes.string,
  linkClassName: PropTypes.string,
  onClick: PropTypes.func,
  isTouch: PropTypes.bool,
};

NavList.defaultProps = {
  className: null,
  linkClassName: null,
  onClick: () => {},
  isTouch: false,
};

export default NavList;
