import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { ModalPopup } from '@Base/Modal';
import { EmailPillsInput } from '@Base/Forms/Custom/EmailPills';
import { generateExternalAccessToCandidates } from '@API/CandidateAPI/CandidateAPI';

function CandidateForwardModal({ isOpen, title, className, size, vacancyId, candidateIds, buttonLabel, onClose }) {
  const [emails, setEmails] = useState([]);

  async function handleForward() {
    if (vacancyId && candidateIds.length > 0 && emails.length > 0) {
      const result = await generateExternalAccessToCandidates(candidateIds, vacancyId, emails);

      if (typeof result !== 'string' || result.length === 0) {
        toast.success('Successfully forwarded candidates to external email addresses');
      } else {
        toast.error('There was an error completing the request. Please try again later');
      }
    } else {
      toast.error('No candidates selected to unable to forward');
    }

    setEmails([]);
    onClose();
  }
  return (
    <ModalPopup
      isOpen={isOpen}
      title={title}
      minHeight="0"
      size={size}
      className={`alert-box ${className || ''}`}
      onOkay={handleForward}
      hideCloseButton
      onToggle={onClose}
      closeOnOkay={false}
      disableOkayButton={emails.length === 0}
      okayLabel={buttonLabel}
    >
      <p className="mb-1">Enter email addresses to forward candidates to:</p>
      <EmailPillsInput onChange={setEmails} value={emails} />
    </ModalPopup>
  );
}

CandidateForwardModal.propTypes = {
  isOpen: PropTypes.bool,
  title: PropTypes.string,
  className: PropTypes.string,
  size: PropTypes.string,
  buttonLabel: PropTypes.string,
  vacancyId: PropTypes.string,
  candidateIds: PropTypes.arrayOf(PropTypes.string),
  onClose: PropTypes.func,
};

CandidateForwardModal.defaultProps = {
  isOpen: false,
  title: 'Forward candidates',
  className: '',
  size: '',
  buttonLabel: '',
  vacancyId: undefined,
  candidateIds: [],
  onClose: () => {},
};

export default CandidateForwardModal;
