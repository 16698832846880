import React, { Fragment, useEffect } from 'react';
import { Col, Row } from 'reactstrap';
import PropTypes from 'prop-types';
import { downloadCandidateWithAccessToken } from '@API/CandidateAPI/CandidateAPI';
import { PDFDisplay } from '@Base/PDF';

function CandidateDocumentView({ candidateData, langPack, token, accessCode }) {
  useEffect(() => {
    if (candidateData?.metadata?.candidateName) {
      document.title = `${candidateData?.metadata?.candidateName} ${langPack.application}`;
    }
  }, [candidateData, langPack]);

  return (
    <Fragment>
      {candidateData && (
        <Fragment>
          {candidateData?.metadata?.candidateName && (
            <Fragment>
              <Row className="mt-2 mb-2">
                <Col>
                  <h2 className="text-center">
                    {`${langPack.application} ${langPack.for} ${candidateData?.metadata?.candidateName}`}
                  </h2>
                </Col>
              </Row>
              <Row className="mt-2 mb-2 text-center">
                <Col>
                  <a
                    href="#download"
                    onClick={async (e) => {
                      e.preventDefault();
                      await downloadCandidateWithAccessToken(token, accessCode);
                    }}
                    className="text-center"
                  >
                    Download PDF
                  </a>
                </Col>
              </Row>
            </Fragment>
          )}
          {candidateData.data && (
            <Row className="external-candidate-view">
              <Col style={{ textAlign: 'center' }}>
                <PDFDisplay file={candidateData.data} isBase64 />
              </Col>
            </Row>
          )}
        </Fragment>
      )}
    </Fragment>
  );
}

CandidateDocumentView.propTypes = {
  candidateData: PropTypes.shape(),
  langPack: PropTypes.shape(),
  token: PropTypes.string,
  accessCode: PropTypes.string,
};

CandidateDocumentView.defaultProps = {
  candidateData: {},
  langPack: {},
  token: undefined,
  accessCode: undefined,
};

export default CandidateDocumentView;
