import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Input, Form, FormGroup, FormText, Label } from 'reactstrap';
import ModalPopup from './ModalPopup';

function gernerateTrackedURL(url, sourceId, mediumId, campaignId) {
  const params = [];

  if (sourceId) {
    params.push(`utm_source=${encodeURIComponent(sourceId)}`);
  }

  if (mediumId) {
    params.push(`utm_medium=${encodeURIComponent(mediumId)}`);
  }

  if (campaignId) {
    params.push(`utm_campaign=${encodeURIComponent(campaignId)}`);
  }

  if (params.length === 0) {
    return url;
  }

  return `${url}&atsApply=true&${params.join('&')}`;
}

function TrackedURLModal({ isOpen, onClose, url, onFillInFormNow }) {
  const [sourceId, setSourceId] = useState('');
  const [mediumId, setMediumId] = useState('');
  const [campaignId, setCampaignId] = useState('');

  useEffect(() => {
    setSourceId('');
    setMediumId('');
    setCampaignId('');
  }, [isOpen]);

  return (
    <ModalPopup
      title="Complete a tracked application"
      okayLabel="Complete Form now"
      onOkay={() => {
        const trURL = gernerateTrackedURL(url, sourceId, mediumId, campaignId);
        onFillInFormNow(trURL);
      }}
      isOpen={isOpen}
      onToggle={onClose}
      minHeight="300px"
    >
      <Form>
        <FormGroup>
          <Label for="sourceId">Source Id*</Label>
          <Input
            id="sourceId"
            value={sourceId}
            onChange={(e) => {
              setSourceId(e.target.value);
            }}
          />
          <FormText>The referrer (e.g. google, newsletter)</FormText>
        </FormGroup>
        <FormGroup>
          <Label for="mediumId">Medium Id</Label>
          <Input
            id="mediumId"
            value={mediumId}
            onChange={(e) => {
              setMediumId(e.target.value);
            }}
          />
          <FormText>Marketing medium (e.g. cpc, banner, email)</FormText>
        </FormGroup>
        <FormGroup>
          <Label for="campaignId">Campaign Id</Label>
          <Input
            id="campaignId"
            value={campaignId}
            onChange={(e) => {
              setCampaignId(e.target.value);
            }}
          />
          <FormText>The ads campaign id.</FormText>
        </FormGroup>
      </Form>
    </ModalPopup>
  );
}

TrackedURLModal.propTypes = {
  isOpen: PropTypes.bool,
  url: PropTypes.string,
  onClose: PropTypes.func,
  onFillInFormNow: PropTypes.func,
};

TrackedURLModal.defaultProps = {
  url: undefined,
  isOpen: false,
  onClose: () => {},
  onFillInFormNow: () => {},
};

export default TrackedURLModal;
