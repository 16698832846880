export default {
  en: {
    funnelName: "Funnel Name",
    companyName: 'Visible Company Name',
    jobDetails: 'Job Details',
    title: 'Title',
    reference: 'Reference',
    vacancyType: 'Vacancy Type',
    positionType: 'Position Type',
    contractTerm: 'Contract Term',
    industry: 'Industry',
    description: 'Description',
    descriptionSummary: 'Description Summary',
    openPositions: 'Open Positions',
    isBackfill: 'Is this a backfilled position?',
    backfillReason: 'Backfill Reason',
    locationTitle: 'Location',
    fullAddress: 'Full Address:',
    shownAddress: 'Shown Address:',
    salaryTitle: 'Salary',
    salaryType: 'Salary Type',
    currency: 'Currency',
    createdBy: 'Created By',
    rate: 'Rate',
    per: 'Per',
    skills: 'Skills Required',
    salaryRange: 'Salary Range',
    displayedSalary: 'Displayed Salary',
    getAppLink: 'Copy Application Link',
    quickAppQR: 'Quick App QR',
    appName: 'Application Form',
    copyJob: 'Copy Vacancy',
    editJob: 'Edit Vacancy',    
    qrVacancyContent:
      'You can save this QR code and use it when advertising this vacancy to allow ' +
      'candidates to instantly access the application form. ' +
      "Simply right click and 'Save image as...' to use this QR code wherever you like.",
    copyLink: 'Copy Link',
    copySuccess: 'Successfully copied application url to clipboard',
  },
  es: {
    companyName: 'Nombre de la empresa visible',
    jobDetails: 'Detalles del trabajo',
    title: 'Título',
    reference: 'Referencia',
    vacancyType: 'Tipo de vacante',
    positionType: 'Tipo de Posición',
    contractTerm: 'Duración del contrato',
    industry: 'Industria',
    description: 'Descripción',
    descriptionSummary: 'Resumen de descripción',
    openPositions: 'Posiciones abiertas',
    locationTitle: 'Ubicación',
    fullAddress: 'Dirección completa:',
    shownAddress: 'Dirección mostrada:',
    salaryTitle: 'Salario',
    salaryType: 'Tipo de salario',
    currency: 'Moneda',
    rate: 'Tarifa',
    per: 'Por',
    salaryRange: 'Rango salarial',
    displayedSalary: 'Salario exhibido',
  },
  enEdulinks: {
    companyName: 'Visible School Name',
    industry: 'Type of School',
  },
};
