import React, { Fragment, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Input } from 'reactstrap';
import SelectOption from './SelectOption';
import { checkPermissions } from '@JS/auth/AuthUtils';

const STATUS = {
  onboardingSummary: {
    label: 'Onboarding',
    permissions: ['candidate:onboarding:read'],
    states: [
      { value: 'REQUESTED', label: 'Requested' },
      { value: 'RECEIVED', label: 'Received' },
    ],
  },
  referenceSummary: {
    label: 'References',
    permissions: ['candidate:references:view'],
    states: [
      { value: 'REQUESTED', label: 'Referees Requested' },
      { value: 'NOT_REQUESTED', label: 'Not Requested' },
      { value: 'PART_RECEIVED', label: 'Part Received' },
      { value: 'RECEIVED', label: 'Received' },
    ],
  },
  inviteSummary: {
    label: 'Invites',
    permissions: [],
    states: [
      { label: 'Invited', value: 'INVITED' },
      { label: 'Accepted', value: 'ACCEPTED' },
      { label: 'Attended', value: 'ATTENDED' },
      { label: 'Declined', value: 'DECLINED' },
      { label: 'No Show', value: 'NO_SHOW' },
      { label: 'Withdrawn', value: 'WITHDRAWN' },
    ],
  },
  rtwSummary: {
    label: 'Right to Work',
    permissions: ['rtw:read'],
    states: [
      { label: 'Requested', value: 'REQUESTED' },
      { label: 'Received', value: 'RECEIVED' },
      // { value: 'PART_RECEIVED', label: 'Part Received' },
      // { value: 'REJECTED', label: 'Rejected' },
    ],
  },
  dbsCheckSummary: {
    label: 'DBS Screening',
    permissions: ['dbs:read'],
    states: [
      { label: 'Requested', value: 'REQUESTED' },
      { label: 'Passed', value: 'SUCCESS' },
      { label: 'Failed', value: 'FAILED' },
    ],
  },
  personalityTestSummary: {
    label: 'Personality Test',
    permissions: ['pt:read', 'pth:read'],
    states: [
      { label: 'Requested', value: 'REQUESTED' },
      { label: 'Received', value: 'RECEIVED' },
    ],
  },
  edocSummary: {
    label: 'eDocuments',
    permissions: ['candidate:edoc:read'],
    states: [
      { label: 'Requested', value: 'REQUESTED' },
      { label: 'Received', value: 'RECEIVED' },
      { label: 'Part Received', value: 'PART_RECEIVED' },
      // { value: 'REJECTED', label: 'Rejected' },
    ],
  },
  genericFormSummary: {
    label: 'Flexi-Forms',
    permissions: ['candidate:genericforms:read'],
    states: [
      { label: 'Requested', value: 'REQUESTED' },
      { label: 'Received', value: 'RECEIVED' },
      { label: 'Part Received', value: 'PART_RECEIVED' },
      // { value: 'REJECTED', label: 'Rejected' },
    ],
  },
};

function StatusFilter({ field, children }) {
  const [statusType, setStatusType] = useState();
  const [optionData, setOptionData] = useState([]);

  //def
  useEffect(() => {
    if (field) {
      const [type] = field.split('.');
      if (type) {
        setStatusType(type);
        setOptionData(STATUS[type]?.states || []);
      }
    }
  }, [field]);

  return (
    <>
      <div className="filter-information-option mb-3">
        <Input
          onChange={(e) => {
            const { value } = e.target;
            setStatusType(value);
            setOptionData(STATUS[value].states);
          }}
          type="select"
          value={statusType || 'DEFAULT'}
        >
          <>
            <SelectOption disabled value="DEFAULT">
              Choose...
            </SelectOption>
            {Object.entries(STATUS).map(([id, { label, permissions }]) => {
              if (!checkPermissions(permissions)) return null;

              return (
                <SelectOption key={id} value={id}>
                  {label}
                </SelectOption>
              );
            })}
          </>
        </Input>
      </div>
      {children({ optionData, statusType })}
    </>
  );
}

StatusFilter.propTypes = {
  field: PropTypes.string,
  children: PropTypes.func,
};

StatusFilter.defaultProps = {
  field: null,
  children: () => {},
};

export default StatusFilter;
