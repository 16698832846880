import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { Input, FormGroup, Label } from 'reactstrap';
import { retryableAPICall } from '@API/common-api-utils';
import { createFlexiForm } from '@API/FormsAPI/ATSFormsAPI';
import { compileFormsPayload } from '@JS/model/form-schemas/flexiforms/attachFlexiForm';
import ModalPopup from '@Base/Modal/ModalPopup';
import { SimpleRadios } from '@Base/Forms/Custom/RadioBtns';

async function createTemplateFromForm(formData, onSuccess, onError, activeAccount) {
  const resp = await retryableAPICall(() =>
    createFlexiForm(
      formData.name,
      {
        rules: formData.flexiForms[0].flexiForm.rules,
        schema: formData.flexiForms[0].flexiForm.formSchema,
        uiSchema: formData.flexiForms[0].flexiForm.uiSchema,
      },
      'APPLICATION_FORM',
      false,
      [...[], { accountId: activeAccount.id, accountName: activeAccount.name }],
      formData.themeId,
    ),
  );

  if (typeof resp === 'string') {
    onError();
  } else {
    onSuccess(resp);
  }
}

const radios = [
  { id: 'ONE_OFF', label: 'One off application form', value: 'ONE_OFF' },
  { id: 'SAVE', label: 'Save Application form', value: 'SAVE' },
];

function FormTemplatePrompt({ isOpen, onOkay, closePrompt, formSchemas, formType, activeAccount, themeId }) {
  const [inputValue, setInputValue] = useState(formSchemas?.applicationForm?.formName || '');
  const [displayTitleError, setDisplayTitleError] = useState(null);
  const [radioButton, setRadioButton] = useState('ONE_OFF');

  function emptyInput() {
    setTimeout(() => setInputValue(formSchemas?.applicationForm?.formName || ''), 500);
    setDisplayTitleError(null);
  }

  useEffect(() => {
    setInputValue(formSchemas?.applicationForm?.formName || '');
  }, [formSchemas]);

  return (
    <ModalPopup
      closeOnOkay={false}
      hideCloseButton
      isOpen={isOpen}
      minHeight="0"
      okayLabel="Save"
      onOkay={() => {
        if (radioButton === 'ONE_OFF') {
          onOkay();
          closePrompt();
        } else if (inputValue.length) {
          if (!Object.keys(formSchemas).length) {
            toast.error('There was an error with the form. Please try again');
          } else {
            const payload = {
              flexiForms: compileFormsPayload(formSchemas, formType),
              themeId,
              name: inputValue,
              type: formType,
              uniqueSubmission: true,
            };

            createTemplateFromForm(
              payload,
              (resp) => {
                toast.success('Template created successfully');
                onOkay(resp);
              },
              () => {
                toast.error('There was an error creating the template');
              },
              activeAccount,
            );
          }
        } else {
          setDisplayTitleError('Please enter a name for the template.');
        }
      }}
      onToggle={() => {
        closePrompt();
        emptyInput();
      }}
      size="md"
      title="Save Applicatifon form"
    >
      <FormGroup check inline>
        {radios.map((radio) => (
          <SimpleRadios
            key={radio.id}
            {...radio}
            checked={radioButton === radio.value}
            name="reason"
            onChange={(val) => {
              setRadioButton(val);
            }}
          />
        ))}
      </FormGroup>
      {radioButton === 'SAVE' ? (
        <FormGroup className="mt-3">
          <Label htmlFor="Name">Template Name</Label>
          <Input
            id="Name"
            onChange={(e) => {
              setInputValue(e.target.value);
              setDisplayTitleError(null);
            }}
            type="text"
            value={inputValue}
          />
        </FormGroup>
      ) : null}

      {displayTitleError && <p className="text-danger">{displayTitleError}</p>}
    </ModalPopup>
  );
}

FormTemplatePrompt.propTypes = {
  activeAccount: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
  }),
  closePrompt: PropTypes.func,
  formSchemas: PropTypes.shape({
    applicationForm: PropTypes.shape(),
    attachedForm: PropTypes.shape(),
  }),
  themeId: PropTypes.string,
  formType: PropTypes.string,
  isOpen: PropTypes.bool,
  onOkay: PropTypes.func,
};

FormTemplatePrompt.defaultProps = {
  activeAccount: {
    id: '',
    name: '',
  },
  closePrompt: () => {},
  formSchemas: {},
  themeId: null,
  formType: 'APPLICATION_FORM',
  isOpen: false,
  onOkay: () => {},
};

export default FormTemplatePrompt;
