import React, { useState, useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { connect } from 'react-redux';
import { Card, CardBody, Row, Col } from 'reactstrap';
import { FormModal } from '@Base/Modal';
import config from '../../../config/config';
import LoadingScreen from '@Base/Loading/LoadingScreen';
import { useLanguagePack, useMounted } from '@Base/hooks';
import InterviewCards from './InterviewCards';
import CancellationModal from './CancellationModal';
import InterviewContext from './InterviewContext';
import { getInterviewForms, getInviteNotesData, inviteStatusChange } from './interviewUtils';

const {
  web: { bookingInWebUrl, formsWebUrl },
} = config;

function InterviewsContainer({
  applicant,
  candidateId,
  candidateEmail,
  invites,
  onInviteChange,
  isTouch,
  userDetails,
}) {
  const isMounted = useMounted();
  const languagePack = useLanguagePack('interviews-container');
  const [showModal, setShowModal] = useState(false);
  const [selectedFormId, setSelectedFormId] = useState();
  const [interviewFormOptions, setInterviewFormOptions] = useState([]);
  const [notes, setNotes] = useState([]);
  const [activeInviteId, setActiveInviteId] = useState();
  const [isLoading, setIsLoading] = useState(true);
  // const [initDataLoad, setInitDataLoad] = useState(true);
  const [candidateBookInModalConfig, setCandidateBookInModalConfig] = useState({ open: false, invite: undefined });
  const [editModalConfig, setEditModalConfig] = useState({ open: false, otToken: undefined, invite: undefined });
  const [cancelInvitePopupConfig, setCancelInvitePopupConfig] = useState({ open: false, inviteId: undefined });

  useEffect(() => {
    if (isMounted()) {
      getInterviewForms(
        (resp) => setInterviewFormOptions(resp),
        () => {
          // eslint-disable-next-line max-len
          toast.error(
            languagePack.couldNotGetFormsError || 'Sorry we could not retrieve your interview notes forms at this time',
          );
        },
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMounted]);

  useEffect(() => {
    if (isMounted()) {
      getInviteNotesData(
        invites.data.map((invite) => invite.id),
        {
          onSuccess: (resp) => setNotes(resp),
          // eslint-disable-next-line max-len
          onError: () =>
            toast.error(languagePack.couldNotGetNotesError || 'Sorry we could not retrieve your notes at this time'),
          onComplete: () => setIsLoading(false),
        },
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invites.data, isMounted]);

  function handleInviteStatusChange(status, inviteId, sanitizedStatus) {
    inviteStatusChange(
      status,
      inviteId,
      () => {
        toast.success(`${languagePack.updateStatusSuccessMessage} ${sanitizedStatus}`);
        onInviteChange();
      },
      () => toast.error(languagePack.updateStatusFailureMessage),
    );
  }

  function getModalProps(isBookingOpen, isEditOpen) {
    let title = languagePack.makeNotesInvitePrefix;
    // eslint-disable-next-line max-len
    let contentUrl = `${formsWebUrl}?id=${selectedFormId}&cid=${candidateId}&iid=${activeInviteId}&userid=${userDetails.id}&username=${userDetails.firstName}%20${userDetails.lastName}`;

    if (isBookingOpen) {
      // eslint-disable-next-line max-len
      title = `${languagePack.bookCandidateInTitlePrefix} ${candidateBookInModalConfig?.invite?.candidate?.firstName} ${candidateBookInModalConfig.invite?.candidate?.surname} ${languagePack.bookCandidateInTitleSuffix}`;
      contentUrl = `${bookingInWebUrl}?id=${candidateBookInModalConfig.invite?.id}`;
    }

    if (isEditOpen) {
      // eslint-disable-next-line max-len
      title = `${languagePack.editModalHeadingTitlePrefix} ${editModalConfig.invite?.candidate?.firstName} ${editModalConfig.invite?.candidate?.surname}`;
      // eslint-disable-next-line max-len
      contentUrl = `${formsWebUrl}?token=${editModalConfig.otToken}&userid=${userDetails.id}&username=${userDetails.firstName}%20${userDetails.lastName}`;
    }

    return {
      title,
      contentUrl,
      usePostMessage: !isBookingOpen,
    };
  }

  return (
    <Fragment>
      <Card className="candidate-interviews">
        <CardBody>
          {isLoading ? (
            <LoadingScreen notFixed />
          ) : (
            <Fragment>
              <Row>
                <Col>
                  <h4 className="mb-0">{languagePack.interviewsTitle || 'Interviews'}</h4>
                </Col>
              </Row>
              <Row>
                <Col>
                  <InterviewContext.Provider
                    value={{
                      onInviteStatusChange: handleInviteStatusChange,
                      onCancelInvite: (inviteId) => setCancelInvitePopupConfig({ open: true, inviteId }),
                      onEdit: (otToken, invite) => setEditModalConfig({ open: true, otToken, invite }),
                      onBooking: (invite) => setCandidateBookInModalConfig({ open: true, invite }),
                      onModal: (isOpen) => setShowModal(isOpen),
                      onFormChange: (id) => setSelectedFormId(id),
                      onActiveInviteId: (id) => setActiveInviteId(id),
                      notes,
                      interviewFormOptions,
                      languagePack,
                    }}
                  >
                    <InterviewCards
                      applicant={applicant}
                      languagePack={languagePack}
                      invites={invites}
                      candidateId={candidateId}
                    />
                  </InterviewContext.Provider>
                </Col>
              </Row>
            </Fragment>
          )}
        </CardBody>
      </Card>
      <CancellationModal
        isOpen={cancelInvitePopupConfig.open}
        onClose={() => setCancelInvitePopupConfig({ open: false, inviteId: undefined })}
        onOkay={() => {
          onInviteChange();
          setCancelInvitePopupConfig({ open: false, inviteId: undefined });
        }}
        inviteId={cancelInvitePopupConfig.inviteId}
        candidate={{ applicantId: candidateId, applicantEmail: candidateEmail }}
        isTouch={isTouch}
      />
      {(candidateBookInModalConfig.open || editModalConfig.open || showModal) && (
        <FormModal
          id="interview-details"
          warnOnClickClose
          isOpen={candidateBookInModalConfig.open || editModalConfig.open || showModal}
          {...getModalProps(candidateBookInModalConfig.open, editModalConfig.open)}
          toggle={() => {
            onInviteChange();
            setCandidateBookInModalConfig({ invite: undefined, open: false });
            setEditModalConfig({ open: false, otToken: undefined, invite: undefined });
            setShowModal(false);
          }}
          usePostMessage={!candidateBookInModalConfig.open}
        />
      )}
    </Fragment>
  );
}

InterviewsContainer.propTypes = {
  candidateId: PropTypes.string,
  invites: PropTypes.shape({
    data: PropTypes.arrayOf(PropTypes.shape()),
  }),
  onInviteChange: PropTypes.func,
  candidateEmail: PropTypes.string,
  isTouch: PropTypes.bool,
  userDetails: PropTypes.shape(),
  applicant: PropTypes.shape(),
};

InterviewsContainer.defaultProps = {
  candidateId: null,
  invites: {
    data: [],
  },
  onInviteChange: () => {},
  candidateEmail: null,
  isTouch: false,
  userDetails: {},
  applicant: {},
};

function mapStateToProps(state) {
  const {
    userData: { userDetails },
  } = state;
  return { userDetails };
}

export default connect(mapStateToProps, null)(InterviewsContainer);
