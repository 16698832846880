import React, { useState, useEffect } from 'react';
import { PieChart, Pie, Cell } from 'recharts';

// Mock API service just for the candidate score
const mockScoreData = {
  overallScore: 85,
  breakdown: [
    { name: 'Skills Match', value: 92, color: '#4ade80', weight: 0.35 },
    { name: 'Experience', value: 78, color: '#60a5fa', weight: 0.30 },
    { name: 'Education', value: 85, color: '#c084fc', weight: 0.20 },
    { name: 'Culture Fit', value: 88, color: '#f97316', weight: 0.15 }
  ]
};

const CandidateScore = ({ candidateId }) => {
  const [isHovered, setIsHovered] = useState(false);
  const [scoreData, setScoreData] = useState(mockScoreData);

  // Simulate API call on mount - just to show how it would work
  useEffect(() => {
    // In a real implementation, you would fetch data here
    // For now, we're just using the mock data directly
    console.log(`Would fetch score data for candidate ID: ${candidateId}`);
  }, [candidateId]);

  const { overallScore, breakdown } = scoreData;

  // Calculate the percentage for the circle fill
  const percentage = overallScore / 100;
  const circumference = 2 * Math.PI * 40; // radius is 40
  const strokeDashoffset = circumference * (1 - percentage);

  // Determine score color based on value
  const getScoreColor = (score) => {
    if (score >= 90) return '#4ade80'; // Green for excellent
    if (score >= 75) return '#5B7BE5'; // Blue for good
    if (score >= 60) return '#f59e0b'; // Amber for average
    return '#ef4444'; // Red for below average
  };

  const scoreColor = getScoreColor(overallScore);

  return (
    <div className="tw-relative tw-flex tw-items-center tw-justify-center tw-ml-4"
         onMouseEnter={() => setIsHovered(true)}
         onMouseLeave={() => setIsHovered(false)}
    >
      <div className="tw-relative tw-flex tw-items-center tw-justify-center tw-cursor-pointer tw-transition-transform tw-duration-200 hover:tw-scale-105">
        <svg width="100" height="100" viewBox="0 0 100 100">
          {/* Background circle */}
          <circle
            cx="50"
            cy="50"
            r="40"
            fill="none"
            stroke="#f3f4f6"
            strokeWidth="10"
          />
          {/* Score circle fill */}
          <circle
            cx="50"
            cy="50"
            r="40"
            fill="none"
            stroke={scoreColor}
            strokeWidth="10"
            strokeDasharray={circumference}
            strokeDashoffset={strokeDashoffset}
            transform="rotate(-90 50 50)"
            strokeLinecap="round"
          />
          {/* Text in center */}
          <text
            x="50"
            y="50"
            textAnchor="middle"
            dominantBaseline="middle"
            fontSize="20"
            fontWeight="bold"
            fill="#111827"
          >
            {overallScore}%
          </text>
          <text
            x="50"
            y="65"
            textAnchor="middle"
            dominantBaseline="middle"
            fontSize="10"
            fill="#6b7280"
          >
            Match
          </text>
        </svg>
      </div>

      {/* Score breakdown popover */}
      {isHovered && (
        <div className="tw-absolute tw-top-full tw-right-0 tw-mt-3 tw-w-72 tw-bg-white tw-rounded-xl tw-shadow-lg tw-p-4 tw-z-50 tw-border tw-border-gray-100">
          <div className="tw-absolute tw-top-0 tw-right-7 tw--mt-2 tw-w-4 tw-h-4 tw-bg-white tw-transform tw-rotate-45 tw-border-l tw-border-t tw-border-gray-100"></div>
          <h5 className="tw-text-sm tw-font-semibold tw-text-gray-900 tw-m-0 tw-mb-3 tw-pb-2 tw-border-b tw-border-gray-100">Score Breakdown</h5>
          <div className="tw-flex tw-flex-col tw-gap-2.5 tw-mb-4">
            {breakdown.map((item, index) => (
              <div key={index} className="tw-w-full">
                <div className="tw-flex tw-justify-between tw-text-xs tw-mb-1">
                  <span className="tw-text-gray-600">
                    {item.name}
                    <span className="tw-text-gray-400 tw-ml-1">
                      ({Math.round(item.weight * 100)}%)
                    </span>
                  </span>
                  <span className="tw-font-semibold tw-text-gray-900">{item.value}%</span>
                </div>
                <div className="tw-w-full tw-h-2 tw-bg-gray-100 tw-rounded tw-overflow-hidden">
                  <div
                    className="tw-h-full tw-rounded"
                    style={{
                      width: `${item.value}%`,
                      backgroundColor: item.color
                    }}
                  />
                </div>
              </div>
            ))}
          </div>
          <div className="tw-flex tw-items-center tw-border-t tw-border-gray-100 tw-pt-3">
            <div className="tw-mr-4">
              <PieChart width={80} height={80}>
                <Pie
                  data={breakdown}
                  cx="50%"
                  cy="50%"
                  innerRadius={20}
                  outerRadius={35}
                  dataKey="value"
                  nameKey="name"
                >
                  {breakdown.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={entry.color} />
                  ))}
                </Pie>
              </PieChart>
            </div>
            <div className="tw-flex tw-flex-col tw-items-start">
              <div className="tw-text-xs tw-text-gray-500 tw-mb-1">Overall Match</div>
              <div className="tw-text-xl tw-font-bold" style={{ color: scoreColor }}>{overallScore}%</div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CandidateScore;
