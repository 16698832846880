import React from 'react';
import { Card, CardBody, CardGroup, Col, Container, Row } from 'reactstrap';
import { ResolveLogo } from '@Base/SVG';

function Pending() {
  return (
    <div className="app flex-row align-items-center auth-page">
      <Container>
        <Row className="justify-content-center">
          <Col lg={6} md={7} sm={10} xl={5} xs={12}>
            <CardGroup>
              <Card className="p-4 rounded">
                <CardBody>
                  <div className="text-center">
                    <ResolveLogo />
                    <h4 className="mt-2 mb-4 text-muted">Account Pending Approval</h4>
                  </div>
                  <Row>
                    <Col className="text-center">
                      <p className="mt-1">You will be able to login once an administrator approves your account</p>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </CardGroup>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

Pending.propTypes = {};

Pending.defaultProps = {};

export default Pending;
