import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { countryList } from '@JS/model/other/countries';

function CountrySelect(props) {
  const { id, onChange, value, disabled } = props;

  return (
    <Select
      id={id}
      value={countryList.find(({ value: val }) => val === value)}
      onChange={(selected) => {
        if (selected) onChange(selected.value);
      }}
      options={countryList}
      classNamePrefix="react-select"
      isDisabled={disabled}
    />
  );
}

CountrySelect.propTypes = {
  id: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  value: PropTypes.string,
  disabled: PropTypes.bool,
};

CountrySelect.defaultProps = {
  onChange: () => {},
  value: '',
  disabled: false,
};

export default CountrySelect;
