import React, { useState, useEffect } from 'react';
import { FormGroup, Spinner } from 'reactstrap';
import Select from 'react-select';
import { toast } from 'react-toastify';
import { retryableAPICall } from '@API/common-api-utils';
import { listAllPermissionGroups } from '@API/PermissionsAPI';

function PermissionGroupDropdown({ selectedGroup, setSelectedGroup }) {
  const [permissionGroups, setPermissionGroups] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const resp = await retryableAPICall(listAllPermissionGroups);
        if (resp) setPermissionGroups([...resp, { id: '', name: 'Custom' }]);
      } catch (error) {
        toast.error('Failed to load permission groups. Please try again later or contact support.');
      } finally {
        setIsLoading(false);
      }
    };
    fetchData();
  }, []);

  if (isLoading) {
    return <Spinner />;
  }

  const chosenGroup = permissionGroups.find((a) => a.id === selectedGroup.value);

  return (
    <FormGroup>
      <Select
        value={{ value: chosenGroup.id, label: chosenGroup.name }}
        onChange={({ value, label }) => setSelectedGroup({ value, label })}
        options={permissionGroups.map((group) => ({ value: group.id, label: group.name }))}
        classNamePrefix="react-select"
      />
    </FormGroup>
  );
}

export default PermissionGroupDropdown;
