const RightToWork = {
  title: 'Right to Work',
  nodes: [
    {
      id: '1',
      type: 'input',
      position: {
        x: 307.69642678170413,
        y: 45.35547886350864,
      },
      data: {
        label: 'Right To Work Status Change',
        actionType: '',
      },
      width: 150,
      height: 58,
      selected: false,
      positionAbsolute: {
        x: 307.69642678170413,
        y: 45.35547886350864,
      },
      dragging: false,
    },
    {
      id: '2',
      type: 'DecisionNode',
      position: {
        x: 308.51279797422865,
        y: 163.7293017795369,
      },
      data: {
        label: 'Right to work Status is Rejected?',
        actionType: '',
      },
      width: 223,
      height: 58,
      selected: false,
      positionAbsolute: {
        x: 308.51279797422865,
        y: 163.7293017795369,
      },
      dragging: false,
    },
    {
      id: '3',
      type: 'default',
      position: {
        x: 275.0415790807309,
        y: 311.4924876264413,
      },
      data: {
        label: 'Change funnel stage to Rejected',
        actionType: 'UPDATE_FUNNEL_STAGE',
      },
      width: 150,
      height: 40,
      selected: false,
      positionAbsolute: {
        x: 275.0415790807309,
        y: 311.4924876264413,
      },
      dragging: false,
    },
    {
      id: '4',
      type: 'output',
      position: {
        x: 389.5175865003003,
        y: 427.8127740502865,
      },
      data: {
        label: 'Exit Workflow',
        actionType: '',
      },
      width: 150,
      height: 40,
      selected: true,
      positionAbsolute: {
        x: 389.5175865003003,
        y: 427.8127740502865,
      },
      dragging: false,
    },
  ],
  edges: [
    {
      id: 'e1-2',
      source: '1',
      target: '2',
    },
    {
      id: 'e2-3',
      source: '2',
      target: '3',
      label: 'Yes',
      sourceHandle: 'a',
    },
    {
      id: 'e2-4',
      source: '2',
      target: '4',
      label: 'No',
      sourceHandle: 'b',
    },
    {
      id: 'e3-4',
      source: '3',
      target: '4',
    },
  ],
};

const InviteToInterview = {
  title: 'Invite to Interview',
  nodes: [
    {
      id: '1',
      type: 'input',
      position: {
        x: 292.9430035499123,
        y: 18.749199543510997,
      },
      data: {
        label: 'Funnel Status Change',
      },
      width: 150,
      height: 58,
      selected: false,
      positionAbsolute: {
        x: 292.9430035499123,
        y: 18.749199543510997,
      },
      dragging: false,
    },
    {
      id: '2',
      type: 'DecisionNode',
      position: {
        x: 278.6440616330964,
        y: 148.6312552879204,
      },
      data: {
        label: 'Is the user in Interview Stage?',
        actionType: '',
      },
      width: 151,
      height: 58,
      selected: false,
      positionAbsolute: {
        x: 278.6440616330964,
        y: 148.6312552879204,
      },
      dragging: false,
    },
    {
      id: '3',
      type: 'default',
      position: {
        x: 202.38303807674595,
        y: 308.30277335902946,
      },
      data: {
        label: 'Send User Email',
        actionType: 'SEND_USER_EMAIL',
      },
      width: 150,
      height: 40,
      selected: false,
      positionAbsolute: {
        x: 202.38303807674595,
        y: 308.30277335902946,
      },
      dragging: false,
    },
    {
      id: '4',
      type: 'output',
      position: {
        x: 371.58718409239873,
        y: 426.2690441727592,
      },
      data: {
        label: 'Exit Workflow',
        actionType: '',
      },
      width: 150,
      height: 40,
      selected: true,
      positionAbsolute: {
        x: 371.58718409239873,
        y: 426.2690441727592,
      },
      dragging: false,
    },
  ],
  edges: [
    {
      id: 'e1-2',
      source: '1',
      target: '2',
    },
    {
      id: 'e2-3',
      source: '2',
      target: '3',
      label: 'Yes',
      sourceHandle: 'a',
    },
    {
      id: 'e2-4',
      source: '2',
      target: '4',
      label: 'No',
      sourceHandle: 'b',
    },
    {
      id: 'e3-4',
      source: '3',
      target: '4',
    },
  ],
};

const ProcessNewCandidate = {
  title: 'Process new Candidate',
  nodes: [
    {
      id: '1',
      type: 'input',
      position: {
        x: 280.7561774284011,
        y: 78.82669775700629,
      },
      data: {
        label: 'New Candidate',
        actionType: '',
      },
      width: 150,
      height: 40,
      selected: false,
      positionAbsolute: {
        x: 280.7561774284011,
        y: 78.82669775700629,
      },
      dragging: false,
    },
    {
      id: '2',
      type: 'DecisionNode',
      position: {
        x: 309.32916916675276,
        y: 150.66736269914762,
      },
      data: {
        label: 'Does candidate have a phone number?',
        actionType: '',
      },
      width: 90,
      height: 58,
      selected: false,
      positionAbsolute: {
        x: 309.32916916675276,
        y: 150.66736269914762,
      },
      dragging: false,
    },
    {
      id: '3',
      type: 'default',
      position: {
        x: 204.01728533111373,
        y: 264.95932965255435,
      },
      data: {
        label: 'Send SMS',
        actionType: 'SEND_SMS',
      },
      width: 150,
      height: 40,
      selected: false,
      positionAbsolute: {
        x: 204.01728533111373,
        y: 264.95932965255435,
      },
      dragging: false,
    },
    {
      id: '4',
      type: 'default',
      position: {
        x: 383.6189476864672,
        y: 260.87747368993263,
      },
      data: {
        label: 'Send User Email',
        actionType: 'SEND_USER_EMAIL',
      },
      width: 150,
      height: 40,
      selected: false,
      positionAbsolute: {
        x: 383.6189476864672,
        y: 260.87747368993263,
      },
      dragging: false,
    },
    {
      id: '6',
      type: 'wait',
      position: {
        x: 303.94004870013043,
        y: 367.6487016985235,
      },
      data: {
        label: 'Wait 2 days',
        actionType: '',
      },
      width: 150,
      height: 40,
      selected: false,
      positionAbsolute: {
        x: 303.94004870013043,
        y: 367.6487016985235,
      },
      dragging: false,
    },
    {
      id: '7',
      type: 'default',
      position: {
        x: 297.67495509596006,
        y: 454.51960279218326,
      },
      data: {
        label: 'Specific Send FlexiForm Request',
        actionType: 'REQUEST_GENERIC_FORM',
      },
      width: 150,
      height: 58,
      selected: false,
      positionAbsolute: {
        x: 297.67495509596006,
        y: 454.51960279218326,
      },
      dragging: false,
    },
    {
      id: '8',
      type: 'wait',
      position: {
        x: 297.39212691259024,
        y: 564.0277091154461,
      },
      data: {
        label: 'Wait 3 Days',
        actionType: '',
      },
      width: 150,
      height: 40,
      selected: false,
      positionAbsolute: {
        x: 297.39212691259024,
        y: 564.0277091154461,
      },
      dragging: false,
    },
    {
      id: '9',
      type: 'default',
      position: {
        x: 193.6627369730462,
        y: 741.7105069124623,
      },
      data: {
        label: 'Update Funnel Stage to Interview Stage',
        actionType: 'UPDATE_FUNNEL_STAGE',
      },
      width: 150,
      height: 40,
      selected: false,
      positionAbsolute: {
        x: 193.6627369730462,
        y: 741.7105069124623,
      },
      dragging: false,
    },
    {
      id: '10',
      type: 'DecisionNode',
      position: {
        x: 358.30125809388073,
        y: 649.5315352434213,
      },
      data: {
        actionType: '',
        label: 'Has user Filled in form?',
      },
      width: 40,
      height: 40,
      selected: false,
      positionAbsolute: {
        x: 358.30125809388073,
        y: 649.5315352434213,
      },
      dragging: false,
    },
    {
      id: '11',
      type: 'output',
      position: {
        x: 400.04751192369633,
        y: 840.1036920321977,
      },
      data: {
        label: 'Exit Workflow',
        actionType: '',
      },
      width: 150,
      height: 40,
      selected: true,
      positionAbsolute: {
        x: 400.04751192369633,
        y: 840.1036920321977,
      },
      dragging: false,
    },
    {
      id: '12',
      type: 'AnatationNode',
      position: {
        x: 759.4461140789456,
        y: 292.41319594184165,
      },
      data: {
        label:
          'This is how we process every new candidate, we should send a welcome form for them to fill in and if so we will invite them to an interview.',
        actionType: '',
      },
      width: 225,
      height: 40,
      selected: false,
      positionAbsolute: {
        x: 759.4461140789456,
        y: 292.41319594184165,
      },
      dragging: false,
    },
  ],
  edges: [
    {
      id: 'e1-2',
      source: '1',
      target: '2',
    },
    {
      id: 'e2-3',
      source: '2',
      target: '3',
      label: 'Yes',
      sourceHandle: 'a',
    },
    {
      id: 'e2-4',
      source: '2',
      target: '4',
      label: 'No',
      sourceHandle: 'b',
    },
    {
      id: 'e3-6',
      source: '3',
      target: '6',
    },
    {
      id: 'e4-6',
      source: '4',
      target: '6',
    },
    {
      id: 'e6-7',
      source: '6',
      target: '7',
    },
    {
      id: 'e7-8',
      source: '7',
      target: '8',
    },
    {
      id: 'e8-10',
      source: '8',
      target: '10',
    },
    {
      id: 'e10-9',
      source: '10',
      target: '9',
      label: 'Yes',
      sourceHandle: 'a',
    },
    {
      id: 'e10-11',
      source: '10',
      target: '11',
      label: 'No',
      sourceHandle: 'b',
    },
    {
      id: 'e9-11',
      source: '9',
      target: '11',
    },
  ],
};

export { RightToWork, InviteToInterview, ProcessNewCandidate };
