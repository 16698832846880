import React, { useState, useRef, useEffect, useCallback, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import { uploadUtils } from '@Base/Forms/Custom/FileUpload';
import ErrorFeedback from '@Base/ErrorFeedback/ErrorFeedback';

const { processFiles, stripBase64Prefix, isImage } = uploadUtils;

function ImageInput({ value, onChange }) {
  const imageRef = useRef();
  const fileInpRef = useRef();
  const [imageUrl, setImageUrl] = useState('');
  const [errors, setErrors] = useState([]);
  const [isBase64, setIsBase64] = useState(false);
  const [inpKey, setInpKey] = useState('');

  const loadImage = useCallback((srcStr) => {
    const { current } = imageRef;

    if (srcStr && current) {
      const img = new Image();
      img.addEventListener(
        'load',
        () => {
          if (img.width > 700) {
            current.style.width = '100%';
            current.style.height = 'auto';
          } else {
            current.style.height = `${Math.min(img.height, 100)}px`;
          }
          current.style.opacity = 1;
        },
        false,
      );

      img.src = srcStr;
    }
  }, []);

  useEffect(() => {
    if (imageUrl) loadImage(imageUrl);
  }, [imageUrl, loadImage]);

  useEffect(() => {
    // when served from server
    if (typeof value === 'string') {
      setIsBase64(false);
      if (isImage) setImageUrl(value);
    }
    // when applied from upload as preview
    else if (value && typeof value === 'object') {
      setIsBase64(true);
      setImageUrl(`data:image/jpeg;base64,${value.data}`);
    }
  }, [value]);

  function handleChange(e) {
    const {
      target: { files: selectedFiles },
    } = e;
    // bail if no files
    if (!selectedFiles.length) return;

    processFiles(selectedFiles, {
      maxSize: 2.5e7,
      isBase64: true,
      maxHeight: 100,
      maxWidth: 100,
    }).then((processedFiles) => {
      const imgErrors = [];
      setImageUrl('');
      setIsBase64(false);

      const validFiles = processedFiles.reduce((acc, file) => {
        if (typeof file === 'string') {
          imgErrors.push(file);
        } else {
          acc.push(file);
        }

        return acc;
      }, []);

      setErrors([...imgErrors]);

      if (validFiles.length && !imgErrors.length) {
        const [currentImage] = validFiles;
        const { type: contentType, name: fileName, dataUrl } = currentImage;
        setImageUrl(dataUrl);

        onChange({
          data: stripBase64Prefix(dataUrl),
          contentType,
          fileName,
          attachmentType: 'OTHER',
        });
      }
    });
  }

  return (
    <>
      <input
        // used to reset input
        key={inpKey}
        ref={fileInpRef}
        accept="image/jpeg, image/png, image/gif"
        name="file"
        onChange={handleChange}
        style={{ display: 'none' }}
        type="file"
      />
      <div className="d-flex align-items-start">
        <div>
          <Button
            className="me-3 d-block"
            color="secondary"
            onClick={(e) => {
              e.preventDefault();
              fileInpRef.current.click();
            }}
            size="sm"
          >
            <span className="fa fa-upload fa-btn" /> Upload
          </Button>
          {!!imageUrl.length && isBase64 && (
            <Button
              className="mt-3 me-3 d-block"
              onClick={(e) => {
                e.preventDefault();
                setImageUrl('');
                onChange('');
                setIsBase64(false);
                // force input to rerender
                setInpKey(Math.random().toString(10));
              }}
              size="sm"
            >
              Remove
            </Button>
          )}
        </div>
        {!!imageUrl.length && (
          <img
            ref={imageRef}
            alt=""
            src={imageUrl}
            style={{
              height: 0,
              opacity: 0,
            }}
          />
        )}
      </div>
      {!!errors.length &&
        errors.map((error, i) => (
          // eslint-disable-next-line react/no-array-index-key
          <ErrorFeedback key={`error-${i}`} message={error} />
        ))}
    </>
  );
}

ImageInput.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      data: PropTypes.string,
      contentType: PropTypes.string,
      fileName: PropTypes.string,
      attachmentType: PropTypes.string,
    }),
  ]),
  onChange: PropTypes.func,
};

ImageInput.defaultProps = {
  value: null,
  onChange: () => {},
};

export default ImageInput;
