import { retryableAPICall } from '@API/common-api-utils';
import { getRTWCheck } from '@API/RTWAPI/RTWAPI';

export async function getRTWCheckData(candidateId, onSuccess = () => {}, onError = () => {}) {
  const resp = await retryableAPICall(() => getRTWCheck(candidateId));

  if (typeof resp === 'string') {
    onError();
  } else {
    onSuccess(resp);
  }
}
