import React, { useState, useCallback, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import cx from 'classnames';
import moment from 'moment';
import { toast } from 'react-toastify';
import styled from 'styled-components';

import { useMounted, usePageLoading } from '@Base/hooks';
import { DataTable, useTableState } from '@Base/Tables';
import { ActionButton, CreateButton, TextButton } from '@Base/Buttons';
import { Prompt } from '@Base/Modal';
import Can from '@Base/RBAC/Can/Can';

import { checkPermissions, doRenewSession } from '@JS/auth/AuthUtils';
import { addAccount, updateAccount, deleteAccount as removeAccount } from '@JS/actions/userActions';
import { deleteObjFromArray, updateObjInArray, addObjToArray } from '@JS/utils/arrayOfObjects';

import { listAccounts, reSyncAccounts, deleteAccount as deleteAcc } from '@API/AccountAPI';
import { retryableAPICall } from '@API/common-api-utils';

import AccountAdminEditor from './AccountAdminEditor';
import {
  SearchInput,
  EnhancedCard,
  EnhancedCardTitle,
  ClientAdminNoDataMessage,
  PageColumn,
  ClientAdminSlider,
} from '../../Common';
import { useDebounce } from '../../Common/utils';

const RestrictedCreateButton = Can(CreateButton);
const RestrictedTextButton = Can(TextButton);

const StyledTable = styled(DataTable)`
  .rt-thead {
    background-color: #f8f9fa !important;
    padding: 0.35rem 0;
  }
`;

async function deleteAccount(id, onSuccess = () => {}, onError = () => {}) {
  if (id) {
    const resp = await retryableAPICall(() => deleteAcc(id));

    if (typeof resp === 'string' && resp.length) {
      onError(resp);
    } else {
      onSuccess();
    }
  }
}

function AccountAdmin({
  isCreateMode,
  onClose,
  tablePrefs,
  addAcc,
  updateAcc,
  removeAcc,
  activeAccountId,
  setTitleSubItems,
  userType,
  setIsCreateMode,
  email,
}) {
  const isMounted = useMounted();
  const { pageState, setPageResolved, setPageRejected } = usePageLoading();
  const { tableState, setTableData, setTableRejected } = useTableState({ rowsPerPage: tablePrefs.pageSize });
  const [showPrompt, setShowPrompt] = useState(false);
  const [deleteAccountObj, setDeleteAccountObj] = useState();
  const [isEditing, setIsEditing] = useState(false);
  const [isSliderOpen, setIsSliderOpen] = useState(false);
  const [actionMenuId, setActionMenuId] = useState('');
  const [accountObj, setAccountObj] = useState({});
  const [isReSyncing, setIsResyncing] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [originalData, setOriginalData] = useState([]);
  const debouncedSearchTerm = useDebounce(searchTerm, 300);

  async function handleReSyncAccounts() {
    setIsResyncing(true);
    try {
      await reSyncAccounts(email);
      toast.success('Successfully reSynced accounts. Please refresh');
    } catch (e) {
      toast.error('Could not reSync accounts for your user');
    } finally {
      setIsResyncing(false);
    }
  }
  const requestData = useCallback(async () => {
    try {
      const resp = await retryableAPICall(() => listAccounts());

      if (isMounted()) {
        if (typeof resp === 'string') {
          setTableRejected();
          setPageRejected(resp);
        } else {
          setOriginalData(resp);
          setTableData({
            data: resp,
            totalResults: resp.length,
          });

          const activeAccounts = resp.filter((acc) => acc.status !== 'PENDING');
          setTitleSubItems([{ title: 'Active accounts', content: activeAccounts?.length || 0 }]);
          setPageResolved();
        }
      }
    } catch (error) {
      if (isMounted()) {
        setTableRejected();
        setPageRejected(error);
      }
    }
  }, [isMounted, setTableData, setTableRejected, setTitleSubItems, setPageResolved, setPageRejected]);

  const filteredData = useMemo(() => {
    if (!debouncedSearchTerm) return originalData;

    return originalData.filter((account) => {
      const searchLower = debouncedSearchTerm.toLowerCase();
      const nameMatch = account.name?.toLowerCase().includes(searchLower);
      const statusMatch = account.status?.toLowerCase().includes(searchLower);
      const uniqueAppsMatch = account.vacancySettings?.restrictAppsByAccount
        ? 'yes'.includes(searchLower)
        : 'no'.includes(searchLower);

      return nameMatch || statusMatch || uniqueAppsMatch;
    });
  }, [debouncedSearchTerm, originalData]);

  useEffect(() => {
    setTableData({
      data: filteredData,
      totalResults: filteredData.length,
    });
  }, [filteredData, setTableData]);

  useEffect(() => {
    requestData();
  }, [requestData]);

  useEffect(() => {
    setIsSliderOpen(isCreateMode);
    if (isCreateMode) setIsEditing(false);
  }, [isCreateMode]);

  function handleCloseSlider() {
    setIsSliderOpen(false);
    if (isCreateMode) onClose();
  }

  const columns = [
    {
      Header: 'Account Name',
      id: 'name',
      minWidth: 200,
      Cell: ({ row: { original } }) => {
        const { name } = original;

        if (checkPermissions(['admin:usermanagement:update'])) {
          return (
            <a
              href="#accountDetails"
              onClick={(e) => {
                e.preventDefault();
                setAccountObj(original);
                setIsEditing(true);
                setIsSliderOpen(true);
              }}
            >
              {name}
            </a>
          );
        }

        return name;
      },
    },
    {
      Header: 'Unique Apps',
      id: 'vacancySettings.restrictAppsByAccount',
      accessor: (r) => {
        return r.vacancySettings?.restrictAppsByAccount ? 'Yes' : 'No';
      },
      width: 50,
    },
    {
      Header: 'Status',
      id: 'status',
      accessor: (r) => r.status,
      width: 125,
    },
    {
      Header: 'CREATED',
      id: 'createdDateTime',
      accessor: (r) => moment(r.createdDateTime, 'YYYY-MM-DD HH:mm:ss').format('DD-MM-YYYY'),
      width: 125,
    },
  ];

  if (checkPermissions(['admin:usermanagement:update', 'admin:usermanagement:delete'])) {
    columns.push({
      id: 'action',
      width: 63,
      className: 'action-cell',
      Cell: ({ row: { original } }) => {
        const { id, name } = original;

        const noDelete = activeAccountId === id || tableState.totalResults === 1;

        const menuItems = [
          {
            id: 0,
            label: 'Edit',
            icon: 'Pencil',
            permissions: ['admin:usermanagement:update'],
            action: () => {
              setAccountObj(original);
              setIsEditing(true);
              setIsSliderOpen(true);
              setActionMenuId('');
            },
          },
          {
            id: 1,
            label: 'Delete',
            icon: 'Bin',
            permissions: ['admin:usermanagement:delete'],
            isDisabled: noDelete,
            tooltipContent: 'You cannot delete active account',
            action: () => {
              setDeleteAccountObj({ id, name });
              setActionMenuId('');
              setShowPrompt(true);
            },
          },
        ];

        return (
          <ActionButton
            isOpen={actionMenuId === id}
            menuItems={menuItems}
            menuStyle={{ width: '150px' }}
            onToggle={(isOpen) => setActionMenuId(isOpen ? id : '')}
            position="left"
            subMenuProps={{
              menuStyle: { width: '100px' },
              position: 'left',
            }}
            title="Action"
          />
        );
      },
    });
  }

  return (
    <>
      <PageColumn className={cx({ 'tw-h-scroll-table': tableState.data.length })} state={pageState}>
        <EnhancedCard className="tw-mb-4">
          <EnhancedCardTitle
            title="Account Management"
            subtitle="Create new accounts or update existing account details"
          >
            {userType === 'JOBSITE_ATS' ? null : (
              <div className="tw-mb-auto">
                <RestrictedTextButton
                  action={() => handleReSyncAccounts()}
                  className="tw-me-1"
                  disabled={isReSyncing}
                  floatRight={false}
                  label={isReSyncing ? 'ReSyncing...' : 'ReSync Accounts'}
                  permissions={['talentfunnel:admin']}
                />
                <RestrictedCreateButton
                  action={() => setIsCreateMode(true)}
                  floatRight={false}
                  label="Create a new Account"
                  permissions={['admin:usermanagement:create']}
                />
              </div>
            )}
          </EnhancedCardTitle>

          <div className="tw-flex tw-items-center tw-gap-3 tw-mb-4">
            <SearchInput
              value={searchTerm}
              className="tw-w-full"
              type="text"
              placeholder="Search accounts by name, status or unique apps setting"
              onChange={(e) => setSearchTerm(e)}
            />
          </div>

          {!tableState.data.length ? (
            <ClientAdminNoDataMessage
              title={searchTerm ? 'No accounts match your search' : 'You currently have no accounts set up'}
              message={searchTerm ? 'Try adjusting your search terms' : ''}
              btnProps={
                !searchTerm
                  ? {
                      onClick: () => setIsSliderOpen(true),
                      label: 'Create an Account',
                    }
                  : {}
              }
            />
          ) : (
            <StyledTable
              className="tw-mt-3"
              columns={columns}
              data={tableState.data}
              errorText="There has been an error loading accounts, please try again later"
              hasSelectColumn={false}
              id="account-admin"
              isLoading={tableState.isLoading}
              isRejected={tableState.isRejected}
              noDataText="You currently have no accounts"
              pageSize={tableState.rowsPerPage}
              rowHeight={53}
              showPagination={false}
              totalResults={tableState.totalResults}
            />
          )}
        </EnhancedCard>
      </PageColumn>
      <ClientAdminSlider
        closeSlider={handleCloseSlider}
        isSliderOpen={isSliderOpen}
        title={`${isEditing ? 'Edit' : 'Create'} Account`}
      >
        <AccountAdminEditor
          data={isEditing ? accountObj : {}}
          isEditing={isEditing}
          onCancel={handleCloseSlider}
          onSave={(accObj) => {
            handleCloseSlider();

            const updatedArr = isEditing
              ? updateObjInArray(originalData, accObj, accObj.id)
              : addObjToArray(originalData, accObj);

            setOriginalData(updatedArr);
            setTableData({
              data: updatedArr,
              totalResults: updatedArr.length,
            });

            // update redux
            if (isEditing) {
              updateAcc({
                accountId: accObj.id,
                accountName: accObj.name,
                restrictAppsPerAccount: accObj.restrictAppsPerAccount,
              });
            } else {
              addAcc({
                accountId: accObj.id,
                accountName: accObj.name,
                restrictAppsPerAccount: accObj.restrictAppsPerAccount,
              });
              doRenewSession();
            }
          }}
        />
      </ClientAdminSlider>
      <Prompt
        closeOnOkay={false}
        content={
          <>
            {deleteAccountObj?.name && <p className="tw-mb-3">{`Account: ${deleteAccountObj.name}`}</p>}
            <p className="tw-mb-3">
              Deleting this account will make all vacancies and candidates created under this account inaccessible. This
              is not reversible and all data under this account will be lost.
            </p>
          </>
        }
        isOpen={showPrompt}
        nameTitle="Type 'DELETE' to confirm your decision"
        noCacheValue
        onCancel={() => setShowPrompt(false)}
        onOkay={(val) => {
          const trimmedVal = (val || '').trim();

          if (trimmedVal.length && trimmedVal === 'DELETE') {
            deleteAccount(
              deleteAccountObj.id,
              () => {
                toast.success('Account successfully deleted');

                const updatedArr = deleteObjFromArray(originalData, deleteAccountObj.id);
                setOriginalData(updatedArr);
                setTableData({
                  data: updatedArr,
                  totalResults: updatedArr.length,
                });

                removeAcc(deleteAccountObj.id);
                doRenewSession();
              },
              (resp) => {
                let reasonStr = '';
                if (resp === 'NOT_FOUND_ERROR') {
                  reasonStr = ' Could not find account.';
                } else if (resp === 'PERMISSION_DENIED') {
                  reasonStr = ' You cannot delete the only account.';
                } else if (resp === 'PERMISSION_DENIED') {
                  reasonStr = ' You do not have permission to delete this account.';
                }
                toast.error(`Error deleting account.${reasonStr}`);
              },
            );

            setShowPrompt(false);
            setDeleteAccountObj();
          }
        }}
        title="Are you sure you want to delete this account?"
      />
    </>
  );
}

AccountAdmin.propTypes = {
  isCreateMode: PropTypes.bool,
  onClose: PropTypes.func,
  tablePrefs: PropTypes.shape({
    pageSize: PropTypes.number,
  }),
  addAcc: PropTypes.func,
  updateAcc: PropTypes.func,
  removeAcc: PropTypes.func,
  activeAccountId: PropTypes.string,
  setTitleSubItems: PropTypes.func,
};

AccountAdmin.defaultProps = {
  isCreateMode: false,
  onClose: () => {},
  tablePrefs: {
    pageSize: 25,
  },
  addAcc: () => {},
  updateAcc: () => {},
  removeAcc: () => {},
  activeAccountId: null,
  setTitleSubItems: () => {},
};

function mapStateToProps(state) {
  const {
    tablePrefs,
    userData: {
      userDetails: {
        // data: {
        //   accountAccess = [],
        // },
        activeAccountId,
        email,
      },
    },
  } = state;

  return {
    tablePrefs,
    // totalAccounts: accountAccess.length,
    activeAccountId,
    email,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    addAcc: (accObj) => {
      dispatch(addAccount(accObj));
    },
    updateAcc: (accObj) => {
      dispatch(updateAccount(accObj));
    },
    removeAcc: (accountId) => {
      dispatch(removeAccount(accountId));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AccountAdmin);
