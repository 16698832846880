import React, { useState, useCallback, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import cx from 'classnames';
import { toast } from 'react-toastify';
import styled from 'styled-components';

import { useMounted, usePageLoading } from '@Base/hooks';
import { DataTable, useTableState } from '@Base/Tables';
import { ActionButton, CreateButton } from '@Base/Buttons';
import { TippyTruncate } from '@Base/Truncate';
import { Confirmation } from '@Base/Modal';
import Can from '@Base/RBAC/Can/Can';

import { deleteObjFromArray, updateObjInArray, addObjToArray } from '@JS/utils/arrayOfObjects';
import { checkPermissions } from '@JS/auth/AuthUtils';

import { retryableAPICall } from '@API/common-api-utils';
import { deleteFunnel } from '@API/CandidateAPI/FunnelManagement';
import { getAvailableFunnels } from '@API/CandidateAPI/CandidateAPI';

import FunnelEditor from './FunnelEditor';
import {
  SearchInput,
  EnhancedCard,
  EnhancedCardTitle,
  ClientAdminNoDataMessage,
  PageColumn,
  ClientAdminSlider,
} from '../../Common';

import { useDebounce } from '../../Common/utils';

const RestrictedCreateButton = Can(CreateButton);

const StyledTable = styled(DataTable)`
  .rt-thead {
    background-color: #f8f9fa !important;
    padding: 0.35rem 0rem;
  }
`;

async function deleteAction(id, onSuccess = () => {}, onError = () => {}) {
  if (id) {
    const resp = await retryableAPICall(() => deleteFunnel(id));

    if (typeof resp === 'string' && resp.length) {
      onError();
    } else {
      onSuccess();
    }
  }
}

function FunnelAdmin({ isCreateMode, onClose, tablePrefs, setIsCreateMode }) {
  const isMounted = useMounted();
  const { pageState, setPageResolved, setPageRejected } = usePageLoading();
  const { tableState, setTableData, setTableRejected } = useTableState({ rowsPerPage: tablePrefs.pageSize });
  const [showConfirm, setShowConfirm] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [isSliderOpen, setIsSliderOpen] = useState(false);
  const [deleteObj, setDeleteObj] = useState();
  const [funnelObj, setFunnelObj] = useState({});
  const [actionMenuId, setActionMenuId] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const debouncedSearchTerm = useDebounce(searchTerm, 300);
  const [originalData, setOriginalData] = useState([]);

  const requestData = useCallback(
    async (callback = () => {}) => {
      const resp = await retryableAPICall(() => getAvailableFunnels());

      if (isMounted()) {
        if (typeof resp === 'string') {
          setTableRejected();
          callback(resp);
        } else {
          setOriginalData(resp);
          setTableData({
            data: resp,
            totalResults: resp.length,
          });

          callback();
        }
      }
    },
    [isMounted, setTableData, setTableRejected],
  );

  // Filter data based on search term
  const filteredData = useMemo(() => {
    if (!debouncedSearchTerm) return originalData;

    return originalData.filter((funnel) => {
      const searchLower = debouncedSearchTerm.toLowerCase();
      const nameMatch = funnel.name.toLowerCase().includes(searchLower);
      const stagesMatch = funnel.stages?.some((stage) => stage.name.toLowerCase().includes(searchLower));
      return nameMatch || stagesMatch;
    });
  }, [debouncedSearchTerm, originalData]);

  // Update table data when filtered data changes
  useEffect(() => {
    setTableData({
      data: filteredData,
      totalResults: filteredData.length,
    });
  }, [filteredData, setTableData]);

  useEffect(() => {
    requestData((errorStatus) => {
      if (errorStatus) {
        setPageRejected(errorStatus);
      } else {
        setPageResolved();
      }
    });
  }, [requestData, setPageRejected, setPageResolved]);

  useEffect(() => {
    setIsSliderOpen(isCreateMode);
    if (isCreateMode) setIsEditing(false);
  }, [isCreateMode]);

  function handleCloseSlider() {
    setIsSliderOpen(false);
    if (isCreateMode) onClose();
  }

  const columns = [
    {
      Header: 'Funnel Name',
      id: 'funnelName',
      resizable: false,
      sortable: false,
      filterable: false,
      Cell: ({ row: { original } }) => {
        const { name } = original;

        if (checkPermissions(['admin:funnel:update'])) {
          return (
            <a
              href="#funnelDetails"
              onClick={(e) => {
                e.preventDefault();
                setFunnelObj(original);
                setIsEditing(true);
                setIsSliderOpen(true);
              }}
            >
              {name}
            </a>
          );
        }

        return name;
      },
    },
    {
      Header: 'Stages',
      id: 'funnelStages',
      resizable: false,
      sortable: false,
      filterable: false,
      Cell: ({ row: { original } }) => {
        const { stages = [] } = original;
        const stageNames = stages.map(({ name }) => name).join(' ➞ ');
        return <TippyTruncate>{stageNames}</TippyTruncate>;
      },
    },
  ];

  if (checkPermissions(['admin:funnel:update', 'admin:funnel:delete'])) {
    columns.push({
      id: 'action',
      resizable: false,
      sortable: false,
      filterable: false,
      width: 63,
      className: 'action-cell',
      Cell: ({ row: { original } }) => {
        const { id, name } = original;

        const menuItems = [
          {
            id: 0,
            label: 'Edit',
            icon: 'Pencil',
            permissions: ['admin:funnel:update'],
            tooltipContent: 'You do not have permission to edit this funnel',
            action: () => {
              setFunnelObj(original);
              setIsEditing(true);
              setIsSliderOpen(true);
              setActionMenuId('');
            },
          },
          {
            id: 1,
            label: 'Delete',
            icon: 'Bin',
            permissions: ['admin:funnel:delete'],
            isDisabled: true,
            tooltipContent: 'You do not have permission to delete this funnel',
            action: () => {
              setDeleteObj({ id, name });
              setActionMenuId('');
              setShowConfirm(true);
            },
          },
        ];

        return (
          <ActionButton
            title="Action"
            position="left"
            isOpen={actionMenuId === id}
            onToggle={(isOpen) => setActionMenuId(isOpen ? id : '')}
            menuItems={menuItems}
            menuStyle={{ width: '150px' }}
            subMenuProps={{
              menuStyle: { width: '100px' },
              position: 'left',
            }}
          />
        );
      },
    });
  }

  return (
    <>
      <PageColumn state={pageState} className={cx({ 'h-scroll-table': tableState.data.length })}>
        <EnhancedCard>
          <EnhancedCardTitle title="Funnel Settings" subtitle="Create and update your recruitment flows">
            <RestrictedCreateButton
              action={() => setIsCreateMode(true)}
              floatRight={false}
              label="Create a new Funnel"
              permissions={['admin:funnel:create']}
              className="mb-auto"
            />
          </EnhancedCardTitle>
          <div className="d-flex align-items-center gap-3 ">
            <SearchInput
              value={searchTerm}
              className="w-100"
              type="text"
              placeholder="Search funnels by name or stage"
              onChange={(e) => setSearchTerm(e)}
            />
          </div>
          {!tableState.data.length ? (
            <ClientAdminNoDataMessage
              title={searchTerm ? 'No funnels match your search' : 'You currently have no Funnels set up'}
              message={searchTerm ? 'Try adjusting your search terms' : ''}
              btnProps={{
                onClick: () => setIsSliderOpen(true),
                label: searchTerm ? null : 'Create Funnel',
              }}
            />
          ) : (
            <StyledTable
              className="mt-3"
              isLoading={tableState.isLoading}
              isRejected={tableState.isRejected}
              data={tableState.data}
              columns={columns}
              rowHeight={53}
              id="funnels-admin"
              totalResults={tableState.totalResults}
              pageSize={tableState.rowsPerPage}
              noDataText="You currently have no Funnels"
              errorText="There has been an error loading Funnels, please try again later"
              hasSelectColumn={false}
              showPagination={false}
            />
          )}
        </EnhancedCard>
      </PageColumn>
      <ClientAdminSlider
        title={`${isEditing ? 'Edit' : 'Create'} Funnel`}
        isSliderOpen={isSliderOpen}
        closeSlider={handleCloseSlider}
      >
        <FunnelEditor
          isEditing={isEditing}
          data={isEditing ? funnelObj : {}}
          onSave={(fnlObj) => {
            handleCloseSlider();

            const updatedArr = isEditing
              ? updateObjInArray(tableState.data, fnlObj, fnlObj.id)
              : addObjToArray(tableState.data, fnlObj);

            setTableData({
              data: updatedArr,
              totalResults: updatedArr.length,
            });
            setOriginalData(updatedArr);
          }}
          onCancel={handleCloseSlider}
        />
      </ClientAdminSlider>
      <Confirmation
        show={showConfirm}
        content={`Are you sure you want to delete ${(deleteObj || {}).name || ''} funnel?`}
        confirmCallback={() => {
          deleteAction(
            deleteObj.id,
            () => {
              toast.success('Funnel successfully deleted');

              const updatedTableData = deleteObjFromArray(tableState.data, deleteObj.id);
              setTableData({
                data: updatedTableData,
                totalResults: updatedTableData.length,
              });
              setOriginalData(updatedTableData);
            },
            () => {
              toast.error('Error deleting funnel');
            },
          );

          setShowConfirm(false);
        }}
        cancelCallback={() => {
          setShowConfirm(false);
          setDeleteObj();
        }}
      />
    </>
  );
}

FunnelAdmin.propTypes = {
  isCreateMode: PropTypes.bool,
  onClose: PropTypes.func,
  tablePrefs: PropTypes.shape({
    pageSize: PropTypes.number,
  }),
  setIsCreateMode: PropTypes.func,
};

FunnelAdmin.defaultProps = {
  isCreateMode: false,
  onClose: () => {},
  tablePrefs: {
    pageSize: 25,
  },
  setIsCreateMode: () => {},
};

function mapStateToProps(state) {
  const {
    tablePrefs,
    userData: {
      userDetails: {
        data: { accountAccess = [] },
        activeAccountId,
      },
    },
  } = state;

  return {
    tablePrefs,
    totalAccounts: accountAccess.length,
    activeAccountId,
  };
}

export default connect(mapStateToProps)(FunnelAdmin);
