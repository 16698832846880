import LocalizedStrings from 'react-localization';
import merge from 'lodash.merge';
import { store } from '@JS/store';

export function translateFormSchema(formSchema = {}, langPack = {}) {
  if (Object.keys(formSchema).length && Object.keys(langPack).length) {
    merge(formSchema, langPack);
  }

  return formSchema;
}

export function getLanguagePackSync(pack, lang = 'en') {
  const strings = new LocalizedStrings(pack || pack.words || {});
  strings.setLanguage(lang);
  return strings;
}

async function getLanguagePack(pageName) {
  const languagePack = await import(`./pages/${pageName}.js`);

  let userSettings = store.getState().userData.userSettings;

  let strings = new LocalizedStrings(languagePack.words ? languagePack.words : {});

  if (userSettings.language && userSettings.language.languageCode) {
    strings.setLanguage(userSettings.language.languageCode);
  }

  return strings;
}

export default getLanguagePack;
