import moment from 'moment';
import { updateDateTime, SERVER_DATE_TIME_FORMAT, roundTimeDifference, isFuture } from './dateTimeUtils';
import { getEventTypeObj, getInviteEmailTemplate } from './fetchUtils';
import { CALENDAR } from '@JS/utils/colours';

export function getFormData(eventObj, isEditable, timezone) {
  const { id, start, end, allDay, title = '', extendedProps = {} } = eventObj;

  let timeZoneUsed = timezone;

  if (extendedProps.timeZone) {
    timeZoneUsed = extendedProps.timeZone;
  }

  const {
    locationId = '',
    totalSpaces = 1,
    notificationEmailAddresses = [],
    eventTypeId = '',
    eventDescription = '',
    recurringEvent = false,
    recurrenceType = 'null',
    inviteOnly = false,
    bookedSpaces = 0,
    recurrenceGroupingId,
    invites = {},
    videoRoomId = '',
    bookedInCandidates = [],
    defaultInviteEmailContext,
  } = extendedProps;

  const { candidateIds = [], sendSms = true, inviteType = 'SELF_BOOK', reminderDays = 0, emailDetails = {} } = invites;

  const { subject, htmlContent, fromName, replyTo, ...emailFields } = emailDetails;

  // default recurrence is month in the future
  let { recurrenceStartDate, recurrenceEndDate = moment().tz(timeZoneUsed).add(1, 'M') } = extendedProps;

  // Convert UTC dates to the specified timezone
  let startDate = moment.utc(start).tz(timeZoneUsed);
  let endDate = moment.utc(allDay ? start : end).tz(timeZoneUsed);

  // Handle recurrence dates with timezone
  recurrenceStartDate = moment.utc(recurrenceStartDate).tz(timeZoneUsed);
  recurrenceEndDate = moment.utc(recurrenceEndDate).tz(timeZoneUsed);

  let startTime = startDate.format('HH:mm');
  let endTime = endDate.format('HH:mm');

  const roundToCurrentTime = !isEditable && allDay;
  if (roundToCurrentTime) {
    const currentTime = moment().tz(timeZoneUsed);
    const nearest15Mins = Math.ceil(currentTime.minute() / 15) * 15;
    startTime = currentTime.minute(nearest15Mins).format('HH:mm');
    endTime = currentTime.minute(nearest15Mins + 15).format('HH:mm');
    startDate = updateDateTime(startDate, startTime);
    endDate = updateDateTime(endDate, endTime);
  }

  const data = {
    id,
    startDate,
    endDate,
    allDay: false, // as no allDay events default to false
    startTime,
    endTime,
    timeZone: timeZoneUsed,
    recurrenceGroupingId,
    recurringEvent,
    recurrenceType,
    recurrenceStartDate,
    recurrenceEndDate,
    eventName: title,
    locationId,
    videoRoomId,
    bookedInCandidates,
    numSpaces: totalSpaces,
    emailAddresses: notificationEmailAddresses,
    eventTypeId,
    eventDescription,
    inviteOnly: inviteOnly.toString(),
    bookedSpaces,
    candidateIds,
    emailDetails: {
      ...emailFields,
      subject,
      message: htmlContent || defaultInviteEmailContext?.htmlContent,
      ...(fromName ? { fromName } : {}),
      ...(replyTo ? { replyTo } : {}),
      ...defaultInviteEmailContext,
    },
    sendSms,
    inviteType,
    reminderDays,
  };

  return data;
}

export function serverEvent(dataObj, isUpdate) {
  const {
    id,
    allDay,
    startDate,
    endDate,
    startTime,
    endTime,
    timeZone,
    eventName,
    // recurrenceGroupingId,
    recurringEvent,
    recurrenceType,
    recurrenceEndDate,
    locationId,
    numSpaces,
    eventTypeId,
    emailAddresses,
    eventDescription,
    inviteOnly,
    // bookedSpaces,
    candidateIds = [],
    emailDetails = {},
    sendSms,
    inviteType,
    reminderDays,
  } = dataObj;
  const { message, ...emailFields } = emailDetails;

  // for ensuring reminder days is correct when
  const [num, unit] = roundTimeDifference(startDate);
  const diffDays = /minute|hour/.test(unit) ? 0 : num;

  const createProps = {
    // recurrenceGroupingId
    recurringEvent,
    recurrenceType: recurrenceType === 'null' ? null : recurrenceType,
    recurrenceEndDate: updateDateTime(moment(recurrenceEndDate).utc(), endTime).utc().format(SERVER_DATE_TIME_FORMAT),
    ...(candidateIds.length
      ? {
          invites: {
            candidateIds,
            eventTypeId,
            timeZone,
            sendSms,
            type: recurrenceType !== 'null' ? 'SELF_BOOK' : inviteType,
            // ...(inviteType === 'SPECIFIC' ? { id } : {}),
            emailDetails: {
              ...emailFields,
              htmlContent: message,
            },
            reminderDays: diffDays <= 2 && inviteType === 'SPECIFIC' ? 0 : Number(reminderDays),
          },
        }
      : { invites: null }),
  };

  const stDt = updateDateTime(startDate, startTime).utc();
  const edDt = updateDateTime(endDate, endTime).utc();

  return {
    ...(id ? { id } : {}),
    startDate: stDt.format(SERVER_DATE_TIME_FORMAT),
    endDate: edDt.format(SERVER_DATE_TIME_FORMAT),
    allDay,
    startTime: stDt.format('hh:mm'),
    endTime: edDt.format('hh:mm'),
    timeZone,
    eventName,
    locationId,
    numSpaces,
    notificationEmailAddresses: emailAddresses,
    eventTypeId,
    eventDescription,
    inviteOnly: inviteOnly === 'true',
    defaultInviteEmailContext: {
      ...emailFields,
      htmlContent: message,
    },
    // bookedSpaces,
    ...(isUpdate ? {} : { ...createProps }),
  };
}

export function clientEvent(dataObj) {
  const { id, allDay, startDate, endDate, eventName, startTime, endTime, isExternalEvent, ...rest } = dataObj;

  const { bookedSpaces, totalSpaces } = rest;
  let bgColor = CALENDAR.EVENT_BG;

  if (bookedSpaces > 0) {
    if (bookedSpaces < totalSpaces) bgColor = CALENDAR.EVENT_PARTIALLY_BOOKED_BG;
    if (bookedSpaces >= totalSpaces) bgColor = CALENDAR.EVENT_FULLY_BOOKED_BG;
  }

  if (isExternalEvent) {
    bgColor = CALENDAR.EXTERNAL_EVENT_BG;
  }
  return {
    id,
    allDay,
    start: moment.utc(startDate).local().toDate(),
    end: moment.utc(endDate).local().toDate(),
    title: eventName,
    startEditable: false,
    durationEditable: isFuture(startDate),
    backgroundColor: bgColor,
    borderColor: bgColor,
    isExternalEvent: isExternalEvent,
    extendedProps: { ...rest },
  };
}

export async function eventTypeEmailDetailsObject(eventTypeId) {
  if (eventTypeId) {
    const eventTypeObj = await getEventTypeObj(eventTypeId);
    const { name, description, invitationEmailId, format } = eventTypeObj;
    let emailDetails = {};

    if (invitationEmailId) {
      const emailTemplate = await getInviteEmailTemplate(invitationEmailId);

      if (Object.keys(emailTemplate).length) {
        const {
          attachments = [],
          bccAddresses = [],
          ccAddresses = [],
          fromName,
          content: message,
          replyTo,
          subject,
        } = emailTemplate;

        emailDetails = {
          attachments,
          bccAddresses,
          ccAddresses,
          fromName,
          message,
          replyTo,
          subject,
        };
      }
    }

    return {
      eventName: name,
      eventDescription: description,
      eventFormat: format,
      ...(Object.keys(emailDetails).length ? { emailDetails } : {}),
    };
  }

  return {};
}
