import React from 'react';
import { IconSVG } from '@Base/SVG';
import { PRIMARY_COLOUR } from '@JS/utils/colours';

export default function DefaultFooter() {
  const dt = new Date();

  return (
    <footer className="app-footer">
      {'Copyright '}
      &copy;
      {` ${dt.getFullYear()} Applicant Tracking System - `}
      <span>
        <a href="https://ats.talent-funnel.com">Talent-Funnel</a>
      </span>
      <IconSVG name="Talent Funnel" width="1.5em" fill={PRIMARY_COLOUR} className="ms-2" />
    </footer>
  );
}
