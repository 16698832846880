import React, { memo, useEffect, useState } from 'react';
import { ReactFlow, Background, Controls, MiniMap, Position, useEdgesState, useNodesState } from '@xyflow/react';
import PropTypes from 'prop-types';
import ExclamationCircle from '../SVG/Icons/ExclamationCircle';
import AnalyticsNode from './AnalyticsNode';
import { Email, Eye, Gear, PaperPlane, Pencil, Tick, User } from '../SVG/Icons';
import TickIcon from '../SVG/Icons/TickIcon';
import { retryableAPICall } from '@API/common-api-utils';
import { useMounted } from '../hooks';
import { LoadingScreen } from '../Loading';
import '@xyflow/react/dist/base.css';

async function fetchData(apiCall, onSuccess = () => {}, onError = () => {}) {
  if (apiCall) {
    const resp = await retryableAPICall(() => apiCall());

    if (typeof resp === 'string') {
      onError();
    } else {
      onSuccess(resp);
    }
  }
}

function AnalyticsFlowChart({ apiCall }) {
  const [error, setError] = useState(false);
  const [events, setEvents] = useState([]);
  const isMounted = useMounted();
  const [isLoading, setIsLoading] = useState(true);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);
  const [nodes, setNodes, onNodeChange] = useNodesState([]);
  useEffect(() => {
    fetchData(
      apiCall,
      (resp) => {
        if (isMounted()) {
          setEvents(resp.filter((event) => !['QUESTION_START', 'BROWSER_DATA'].includes(event.eventName)));
        }
      },
      () => {
        if (isMounted()) setError(true);
      },
    );
    setIsLoading(false);
  }, [apiCall, isMounted]);

  const groupValidationErrors = () => {
    const groupedEvents = [];
    let validationErrors = [];

    events.forEach((event, index) => {
      if (event.eventName === 'FORM_QUESTION_VALIDATION_FAILURE') {
        validationErrors.push(event);
      } else {
        if (validationErrors.length) {
          groupedEvents.push({
            eventName: 'FORM_QUESTION_VALIDATION_FAILURE',
            eventDateTime: validationErrors[0].eventDateTime,
            validationErrors,
          });
          validationErrors = [];
        }
        groupedEvents.push(event);
      }
    });

    return groupedEvents;
  };

  const groupedEvents = groupValidationErrors(events);

  function processEvents() {
    const nodeArr = [];
    let lastNodeId = null;
    let isFirstNode = true;
    groupedEvents.forEach((event, index) => {
      let nodeId = `node-${index}`;

      const isAbandon =
        event.eventName === 'FORM_EXIT' &&
        !events.find((itm) => itm.sessionId === event.sessionId && itm.eventName === 'FORM_SUBMIT_SUCCESS');
      const isFormEvent = ['STANDARD_FORM_EVENT', 'CHATBOT_FORM_EVENT', 'FORM_EVENT'].includes(event.eventCategory);
      const label = getLabelText(event, isAbandon);
      const isFirstNodeAfterSession =
        (!events[index - 1] || events[index - 1]?.sessionId !== event.sessionId) &&
        nodeArr.find((e) => e.id === events[index - 1]?.sessionId) !== undefined &&
        event.eventName !== 'FORM_QUESTION_VALIDATION_FAILURE';

      if (!nodeArr.find((node) => node.id === event.sessionId) && isFormEvent) {
        nodeArr.push({
          id: event.sessionId,
          position: getNodePosition(index, true),
          data: {
            label: 'Form Session',
          },
          style: {
            ...groupNodeStyle,
            width: '300px',
            height: `${events.filter((itm) => itm.sessionId === event.sessionId).length * 108}px`,
          },
        });
      }

      const isFirstGroupedNode =
        (!nodeArr.find((nde) => nde.parentId === event.sessionId) && isFormEvent) ||
        event.eventName === 'FORM_QUESTION_VALIDATION_FAILURE';

      let nodePos = getNodePosition(index, false, isFirstGroupedNode, isFirstNodeAfterSession);
      nodeArr.push({
        id: nodeId,
        type: 'analyticsNode',
        position: nodePos,
        parentId: ['STANDARD_FORM_EVENT', 'CHATBOT_FORM_EVENT', 'FORM_EVENT'].includes(event.eventName)
          ? event.sessionId
          : null,
        data: {
          label: label,
          icon: getEventIcon(event.eventName, isAbandon),
          eventDateTime: event.eventDateTime,
          isFirstNode: isFirstNode,
        },
      });
      if (event.validationErrors) {
        nodeArr.push({
          id: `${index}-validation`,
          type: 'analyticsNode',
          position: { ...nodePos, x: nodePos.x + 300 },
          parentId: ['STANDARD_FORM_EVENT', 'CHATBOT_FORM_EVENT', 'FORM_EVENT'].includes(event.eventName)
            ? event.sessionId
            : null,
          data: {
            label: 'Invalid Fields',
            isValidationErrorNode: true,
            validationErrors: event.validationErrors || undefined,
            icon: getEventIcon(event.eventName, isAbandon),
          },
        });
      }
      lastNodeId = nodeId;
      isFirstNode = false;
    });
    setNodes(nodeArr);
  }

  useEffect(() => {
    processEvents();
  }, [events]);

  let yPos = 0; // Initialize the yPos variable outside of the function

  const getNodePosition = (index, isGroup, isFirstGroupedNode, isFirstNodeAfterSession) => {
    const ySpacing = 100;

    const nodePosition = {
      x: isGroup ? 23 : 50,
      y: isFirstGroupedNode ? yPos + 45 : yPos + ySpacing,
    };

    if (isFirstNodeAfterSession) {
      nodePosition.y = nodePosition.y - 45;
    }

    yPos += ySpacing - (isFirstNodeAfterSession ? 45 : 0);

    return nodePosition;
  };

  const groupNodeStyle = {
    backgroundColor: '#ecf1f1',
    border: '1px solid #C4CFD8',
    borderRadius: '8px',
    padding: '12px',
    boxShadow: '0 2px 6px rgba(0, 0, 0, 0.1)',
    fontFamily: "'Segoe UI', Tahoma, Geneva, Verdana, sans-serif",
    fontSize: '0.875rem',
    fontStyle: 'italic',
    color: '#4A4F54',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
  };

  const getLabelText = (event, isAbandon) => {
    if (isAbandon) {
      return 'Abandoned Form';
    }
    switch (event.eventName) {
      case 'QUESTION_FINISH':
        return `Completed Question ${event.data.label}`;
      case 'FORM_SUBMITTED_PRESSED':
        return 'Pressed Submit';
      case 'FORM_SUBMIT_SUCCESS':
        return 'Successfully Submitted Form';
      case 'FORM_VIEW':
        return 'Loaded Form';
      case 'FORM_BEGIN':
        return 'Began form';
      case 'FORM_SUBMIT_FAIL':
        return 'Form Submission Failed';
      case 'FORM_QUESTION_VALIDATION_FAILURE':
        return `Validation Errors`;
      case 'EMAIL_PROCESSED':
        return `Email Sent`;
      case 'EMAIL_DELIVERED':
        return `Email Delivered`;
      case 'EMAIL_LINK_CLICKED':
        return `Linked Clicked In Email`;
      case 'FORM_EXIT':
        return `Closed Form`;
      default:
        return event.eventName;
    }
  };

  const getEventIcon = (eventName, isAbandon) => {
    if (isAbandon) {
      return <ExclamationCircle style={{ height: '30px' }} />;
    }
    const colours = { info: '#3399CC', success: '#33CC33', warning: '#FF9900', error: '#CC3333' };
    switch (eventName) {
      case 'FORM_EXIT':
        return <ExclamationCircle styles={{ width: '20px', color: colours.warning }} />;
      case 'FORM_QUESTION_VALIDATION_FAILURE':
        return <ExclamationCircle styles={{ width: '20px', color: colours.error }} />;
      case 'FORM_SUBMIT_FAIL':
        return <ExclamationCircle styles={{ width: '20px', color: colours.error }} />;
      case 'QUESTION_FINISH':
        return <TickIcon styles={{ width: '25px', color: colours.success }} />;
      case 'FORM_SUBMIT_SUCCESS':
        return <TickIcon styles={{ width: '25px', color: colours.success }} />;
      case 'FORM_BEGIN':
        return <Pencil styles={{ width: '25px', color: colours.info }} />;
      case 'FORM_VIEW':
        return <Eye styles={{ width: '25px', color: colours.info }} />;
      case 'EMAIL_PROCESSED':
        return <Email styles={{ width: '25px', color: colours.info }} />;
      case 'EMAIL_DELIVERED':
        return <Email styles={{ width: '25px', color: colours.success }} />;
      case 'EMAIL_LINK_CLICKED':
        return <Gear styles={{ width: '25px', color: colours.info }} />;
      case 'FORM_SUBMITTED_PRESSED':
        return <PaperPlane styles={{ width: '20px', color: colours.info }} />;
      default:
        return null;
    }
  };

  const centeredStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    height: '100vh',
  };

  const messageStyle = {
    margin: 0,
    fontSize: '1.3rem',
    fontWeight: 'normal',
    color: '#333',
  };

  if (edges.length === 0 && nodes.length === 0) {
    if (isLoading) {
      return (
        <div style={centeredStyle}>
          <LoadingScreen isEmbeded />
        </div>
      );
    } else if (isLoading) {
      return (
        <div style={centeredStyle}>
          <h1 style={messageStyle}>No Events To Show</h1>
        </div>
      );
    } else if (!isLoading && !error) {
      return (
        <div style={centeredStyle}>
          <h1 style={messageStyle}>An error has occurred while retrieving the events please try again later</h1>
        </div>
      );
    }
  }

  return (
    <div style={{ height: '100vh' }}>
      <ReactFlow
        nodes={nodes}
        fitView
        fitViewOptions={{ maxZoom: 1 }}
        preventScrolling={false}
        nodeTypes={{ analyticsNode: AnalyticsNode }}
      >
        <Controls />
        <Background />
      </ReactFlow>
    </div>
  );
}

AnalyticsFlowChart.propTypes = {
  events: PropTypes.arrayOf(
    PropTypes.shape({
      eventName: PropTypes.string.isRequired,
      eventDateTime: PropTypes.string,
    }),
  ).isRequired,
};

export default memo(AnalyticsFlowChart);
