import { FormGroup, Input } from 'reactstrap';
import Select from 'react-select';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { retryableAPICall } from '@API/common-api-utils';
import { searchQuestions } from '@API/FormsAPI/ATSFormsAPI';

function AppicationQuestions({ option, setOption }) {
  const [questions, setQuestions] = useState([]);

  useEffect(() => {
    const getItem = async () => {
      const resp = await retryableAPICall(() =>
        searchQuestions(
          '',
          [
            {
              field: 'formTypes',
              operation: 'EQ',
              value: ['APPLICATION_FORM'],
            },
          ],
          ['mappedAnswers'],
        ),
      );

      if (Array.isArray(resp)) {
        const aa = resp
          .map((opt) => ({ ...opt, value: opt.text, label: opt.text }))
          // filter out other field types
          .filter(({ fieldType }) => fieldType === 'string');
        // if field is already filtered don't allow it to be an options
        // this avoids duplicate filters

        setQuestions(aa);
      } else {
        toast.error('Error loading question list');
      }
    };

    getItem();
  }, []);

  return (
    <>
      <FormGroup>
        <Select
          classNamePrefix="react-select"
          id="filter-question-select"
          isClearable
          onChange={(selectedOpt) => {
            setOption({ question: selectedOpt?.value, answer: '' });
          }}
          options={questions}
          placeholder="Search Questions"
          value={questions.find((a) => a.value === option?.condition?.question)}
        />
        <Input
          className="mt-2"
          placeholder="Candidate Answer"
          value={option?.condition?.answer}
          onChange={(e) => {
            setOption({ answer: e.target.value, question: option?.condition.question });
          }}
        />
      </FormGroup>
    </>
  );
}

export default AppicationQuestions;
