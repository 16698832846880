import { retryableAPICall } from '@API/common-api-utils';
import { searchQuestions } from '@API/FormsAPI/ATSFormsAPI';
import { filterUtils } from '@Base/Filters';
import { tableUtils } from '@Base/Tables';
import { getFunnelStages } from '@Base/Filters/components/FunnelList';
import { cookieNames } from '@JS/constants/storageNames';
import { candidateViews } from '@JS/constants/viewNames';

export function getColumnAnswer(mappedAnswers = {}, textStr = '') {
  const { sections = [] } = mappedAnswers;

  if (sections.length) {
    return sections
      .reduce((acc, section) => {
        const { questions = [] } = section;
        return [...acc, ...questions];
      }, [])
      .reduce((a, b) => [...a, ...b], [])
      .filter((question) => question.text === textStr);
  }

  return [];
}

export async function fetchMappedAnswerColumns(filters, onSuccess = () => {}) {
  const resp = await retryableAPICall(() => searchQuestions('', filters, ['mappedAnswers']));

  if (Array.isArray(resp)) {
    const answerCols = resp
      .filter(({ fieldType }) => fieldType === 'string')
      .map(({ text }) => ({
        Cell: ({ row: { original } }) => {
          const { mappedAnswers } = original;
          if (mappedAnswers) {
            const [answer] = getColumnAnswer(mappedAnswers, text);
            if (answer?.value) return answer.value;
            return null;
          }

          return null;
        },
        Header: text,
        id: text.toLowerCase().replace(/\s+/, '-'),
        optional: true,
        visible: false,
        width: 200,
      }));

    onSuccess(answerCols);
  }
}

export function updateCandidateData(data = [], candidateIds = [], props = {}, opts = {}) {
  const { tags } = opts;

  return data.map((candidate) => {
    const { id } = candidate;
    // if the candidate is selected
    // change the funnel name value
    if (candidateIds.includes(id)) {
      return {
        ...candidate,
        ...props,
        ...(tags && tags[id] ? { tags: tags[id] } : {}),
      };
    }
    // else leave unchanged
    return candidate;
  });
}

export function getApplicantSelectObject(applicantObj) {
  const {
    id,
    firstName,
    surname,
    emailAddress,
    phoneNumber,
    accountId,
    vacancyId,
    latestFunnelStage,
    inviteSummary,
    referenceSummary,
    onboardingSummary,
    rtwSummary,
    dbsCheckSummary,
    lmsSummary,
    personalityTestSummary,
    edocSummary,
    genericFormSummary,
  } = applicantObj;

  return {
    accountId,
    applicantEmail: emailAddress,
    applicantId: id,
    applicantName: `${firstName} ${surname}`,
    applicantPhone: phoneNumber,
    funnelId: latestFunnelStage?.funnelId,
    vacancyId,
    profileSummary: {
      invites: inviteSummary,
      references: referenceSummary,
      onboarding: onboardingSummary,
      rightToWork: rtwSummary,
      dbsCheck: dbsCheckSummary,
      lms: lmsSummary,
      personalityTest: personalityTestSummary,
      edoc: edocSummary,
      genericForm: genericFormSummary,
    },
  };
}

export function handleSelectCandidates(data, callback, selectedCandidateIds = []) {
  if (Array.isArray(selectedCandidateIds)) {
    const selectedCandidates = data
      .filter((candidate) => {
        const { id, userId } = candidate;
        return selectedCandidateIds.includes(id) || selectedCandidateIds.includes(userId);
      })
      .map(getApplicantSelectObject);

    callback(selectedCandidates);
  }
}

export async function getSearchFields(
  keywords,
  pageSize,
  searchFields,
  funnelId,
  vacancyId,
  globalFunnelStages,
  resultAggs,
  activeFilters,
  filterName,
  appliedOn,
  appliedOnFrom,
  appliedOnTo,
  stageIds,
  qId,
  embedded,
  slotBookings,
) {
  if (window.localStorage.getItem(cookieNames.CANDIDATE_VIEW) === candidateViews.SWIMLANE && embedded) {
    pageSize = 5000;
  }

  let cachedSearchKeywords = keywords;
  let newSearchFields = [];
  // Get rid of all those nasty white spaces
  if (cachedSearchKeywords && cachedSearchKeywords.length > 0) {
    cachedSearchKeywords = cachedSearchKeywords.trim();
  }

  if (cachedSearchKeywords && !searchFields) {
    newSearchFields = [
      'applicantProfile.communication.emailAddress.keyword',
      'applicantProfile.personalDetails.givenName.keyword',
      'applicantProfile.personalDetails.familyName.keyword',
      'applicantProfile.personalDetails.formattedName.normalize',
    ];
  }
  let allowedStageIds = [];
  let allowedFilters = activeFilters[filterName];

  await getFunnelStages(
    funnelId,
    vacancyId,
    globalFunnelStages,
    resultAggs,
    (fil) => {
      allowedStageIds = fil?.map((f) => f.stageId) || [];
    },
    () => {},
  );

  if (allowedFilters && filterName === 'completedAppVacancyEmbededView') {
    allowedFilters = allowedFilters
      .map((item) => {
        let filteredValue = item.value ? item.value : [];

        // Only funnel stages need to be clear other filters can be left as is
        if (item.type === 'Funnel') {
          filteredValue = item.value.filter((id) => allowedStageIds.includes(id));
        }
        return { ...item, value: filteredValue };
      })
      .filter((item) => item.value.length !== 0);
  }

  const filters = filterUtils.formatFilters(allowedFilters);
  let hasEmbededVacFilter = false;

  if (vacancyId) {
    filters.push({
      field: 'vacancyId',
      operation: 'IN',
      value: Array.isArray(vacancyId) ? vacancyId : [vacancyId],
    });

    hasEmbededVacFilter = embedded;
  }

  if (appliedOn) {
    filters.push({
      field: 'appliedOnDate',
      operation: 'EQNK',
      value: [appliedOn],
    });
  }

  if (appliedOnFrom) {
    filters.push({
      field: 'appliedOnDate',
      operation: 'GTE',
      value: [appliedOnFrom],
    });
  }

  if (stageIds && stageIds.length > 0) {
    let parsedStageIds = stageIds;
    if (typeof stageIds === 'string') {
      parsedStageIds = [stageIds];
    }
    filters.push({
      field: 'funnelStageId',
      operation: 'IN',
      value: parsedStageIds,
    });
  }

  if (appliedOnTo) {
    filters.push({
      field: 'appliedOnDate',
      operation: 'LTE',
      value: [appliedOnTo],
    });
  }

  if (qId && slotBookings[qId]) {
    filters.push({
      field: 'id',
      operation: 'IN',
      value: slotBookings[qId],
    });

    pageSize = tableUtils.roundToPageOption(slotBookings[qId].length);
  }

  const fieldIds = [
    'id',
    'userId',
    'vacancyDetails',
    'applicantProfile',
    'funnelStageId',
    'funnelStageOrder',
    'funnelId',
    'accountName',
    'accountId',
    'funnelStageName',
    'applicationFormMetaData.applicationFormId',
    'createdDateTime',
    'appliedOnDate',
    'profileSummary',
    'submittedDateTime',
    'tags',
    'mappedAnswers',
  ];
  const aggs = { mainField: 'funnelStageName', subFields: ['funnelStageId'] };
  return { cachedSearchKeywords, newSearchFields, filters, fieldIds, pageSize, aggs, hasEmbededVacFilter };
}
