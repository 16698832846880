import React, { Fragment, useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import AsyncSelect from 'react-select/async';
import axios from 'axios';
import { Label } from 'reactstrap';
import { getCommonHeaders } from '@API/common-api-utils';
import config from '../../../../../config/config';
import { useMounted } from '../../../hooks';

const { api } = config;

function fetchData(path, opts) {
  if (!path) return;

  const { value, onSuccess = () => {} } = opts;

  let url = `${api.middlewareAPIURL}${path}`;
  if (value) url += `/${value}`;

  axios
    .get(url, {
      headers: getCommonHeaders(),
    })
    .then((result) => {
      if (result.status === 200) {
        let respArr = [];

        if (result.data && Array.isArray(result.data)) {
          respArr = result.data.map((opt) => ({ value: opt.id, label: opt.name }));
        }

        onSuccess(respArr);
      }
    });
}

function AsyncDDSelect(props) {
  const { formData, schema = {}, name, required, onChange, formContext, idSchema = {}, readonly = false } = props;

  const { title, isMultiple, path, fetchOnce, populateOnLoad = true } = schema;

  const { $id } = idSchema;
  let { asyncCache } = formContext;

  const isMounted = useMounted();
  const asyncCacheRef = useRef({});
  const [options, setOptions] = useState([]);
  const [selected, setSelected] = useState();
  const [disabled, setDisabled] = useState(false);

  // if no asyncCache set in formContext use a local cache
  if (!asyncCache) asyncCache = asyncCacheRef.current;
  // if not set, set the props related to this specific instance
  if (!asyncCache[$id]) asyncCache[$id] = { fetched: false, opts: [] };

  const setOpts = useCallback(
    (opts) => {
      setOptions(opts);

      if (opts.length === 1 && populateOnLoad) {
        setSelected(isMultiple ? [opts[0]] : opts[0]);
        onChange(isMultiple ? [opts[0].value] : opts[0].value);
      } else if (opts.length === 0) {
        setDisabled(true);
      }
    },
    [isMultiple, onChange, populateOnLoad],
  );

  useEffect(() => {
    if (fetchOnce) {
      // only fetch if hasn't already run
      if (!asyncCache[$id].fetched) {
        fetchData(path, {
          onSuccess: (resp) => {
            if (isMounted()) {
              // stop future runs
              asyncCache[$id] = { fetched: true, opts: [...resp] };
              setOpts(resp);
            }
          },
        });
      } else {
        // use cached option array
        // eslint-disable-next-line no-lonely-if
        if (isMounted()) setOpts(asyncCache[$id].opts);
      }
    }
  }, [$id, asyncCache, fetchOnce, isMounted, path, setOpts]);

  useEffect(() => {
    if (isMounted()) {
      let selectedOpt;

      if (formData) {
        if (isMultiple && Array.isArray(formData)) {
          selectedOpt = formData.map((value) => options.find((opt) => opt.value === value));
        } else {
          selectedOpt = options.find((opt) => opt.value === formData);
        }
      }

      setSelected(selectedOpt);
    }
  }, [formData, isMounted, isMultiple, options]);

  function handleChange(val) {
    let value;

    if (val) {
      if (Array.isArray(val)) {
        value = val.map((opt) => opt.value);
      } else {
        value = val.value;
      }
    }
    onChange(value);
  }

  function loadOptions(val, callback) {
    if (val.length > 1) {
      fetchData(path, {
        value: val,
        onSuccess: (resp) => {
          setTimeout(() => {
            if (resp && Array.isArray(resp)) {
              const opts = resp.map((opt) => {
                let result;
                if (opt.id) {
                  result = { value: opt.id, label: opt.name };
                } else if (opt.label) {
                  result = { value: opt.value, label: opt.label };
                }
                return result;
              });
              callback(opts);
            }
          }, 1000);
        },
      });
    }
  }

  return (
    <Fragment>
      {title && (
        <Label className="control-label" htmlFor={name}>
          {title}
          {required && <span className="required">*</span>}
        </Label>
      )}
      {fetchOnce ? (
        <Select
          id={`${name}-async-dd-select-fetchonce`}
          classNamePrefix="react-select"
          isMulti={isMultiple}
          inputId={name}
          options={options}
          onChange={handleChange}
          isDisabled={disabled || readonly}
          value={selected}
          placeholder={disabled ? 'No Options Available' : 'Please Select...'}
        />
      ) : (
        <AsyncSelect
          id={`${name}-async-dd-select`}
          classNamePrefix="react-select"
          isMulti={isMultiple}
          isDisabled={readonly}
          cacheOptions
          inputId={name}
          defaultOptions={[]}
          loadOptions={loadOptions}
          onChange={handleChange}
          placeholder="Start typing for options"
          value={selected}
        />
      )}
    </Fragment>
  );
}

AsyncDDSelect.propTypes = {
  formData: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.shape({
          value: PropTypes.string,
          label: PropTypes.string,
        }),
      ]),
    ),
    PropTypes.string,
    PropTypes.number,
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    }),
  ]),
  schema: PropTypes.shape({
    title: PropTypes.string,
    isMultiple: PropTypes.bool,
    path: PropTypes.string,
    fetchOnce: PropTypes.bool,
  }).isRequired,
  idSchema: PropTypes.shape().isRequired,
  formContext: PropTypes.shape().isRequired,
  name: PropTypes.string.isRequired,
  required: PropTypes.bool,
  onChange: PropTypes.func,
};

AsyncDDSelect.defaultProps = {
  formData: undefined,
  required: false,
  onChange: () => {},
};

export default AsyncDDSelect;
