import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col } from 'reactstrap';
import CandidateSlider from '@Base/Slider/CandidateSlider';
import { getJobSeekerProfile } from '@API/JobSeekerAPI/JobSeekerAPI';
import Communication from './Communication';
import Competencies from './Competencies';
import SkillsSummary from './SkillsSummary';
import EmploymentHistory from './EmploymentHistory';
import Education from './Education';
import ExecSummary from './ExecSummary';
// import Attachments from './Attachments';
import Tabs from '@Base/Tabs/Tabs';

function DetailsSlider({ onClose, ...sliderProps }) {
  const [activeDetailsTab, setActiveDetailsTab] = useState('employment');

  return (
    <CandidateSlider
      {...sliderProps}
      onClose={() => {
        onClose();
        setActiveDetailsTab('employment');
      }}
      title="Candidate Details"
      apiMethod={getJobSeekerProfile}
    >
      {({ isLoading, fullName, applicantData }) => {
        if (isLoading) return null;

        let candidateName = fullName;

        const {
          communication,
          competencies,
          skillsSummary,
          employment,
          // attachments,
          personalDetails = {},
          education,
          executiveSummary,
        } = applicantData;

        const { familyName = '', givenName = '' } = personalDetails;
        candidateName = `${givenName} ${familyName}`;

        const detailsTabs = [];
        if (employment && employment.length) detailsTabs.push({ anchor: 'employment', label: 'Employment' });
        if (education && education.length) detailsTabs.push({ anchor: 'education', label: 'Education' });
        if (competencies && competencies.length) detailsTabs.push({ anchor: 'competencies', label: 'Competencies' });

        return (
          <Container fluid>
            {Boolean(candidateName.length) && (
              <Row className="slider-header-row pb-4">
                <Col>
                  <h3>{candidateName}</h3>
                </Col>
              </Row>
            )}
            <Row className="slider-body-row">
              <Col>
                <Communication data={communication} />
                <ExecSummary data={executiveSummary} />
                <SkillsSummary data={skillsSummary} />
                <Row className="mt-3">
                  <Col sm="3">
                    <Tabs
                      isVertical
                      positionAbsolute={false}
                      updateUrl={false}
                      activeTab={activeDetailsTab}
                      onClick={(tabId) => setActiveDetailsTab(tabId)}
                      tabs={detailsTabs}
                    />
                  </Col>
                  <Col>
                    {activeDetailsTab === 'employment' && <EmploymentHistory data={employment} showTitle={false} />}
                    {activeDetailsTab === 'education' && <Education data={education} showTitle={false} />}
                    {activeDetailsTab === 'competencies' && <Competencies data={competencies} showTitle={false} />}
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
        );
      }}
    </CandidateSlider>
  );
}

DetailsSlider.propTypes = {
  onClose: PropTypes.func,
};

DetailsSlider.defaultProps = {
  onClose: () => {},
};

export default DetailsSlider;
