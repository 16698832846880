import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { retryableAPICall } from '@API/common-api-utils';
import { getAvailableFunnels } from '@API/CandidateAPI/CandidateAPI';
import { useMounted } from '../../hooks';

async function getFunnels(onSuccess = () => {}) {
  const resp = await retryableAPICall(() => getAvailableFunnels());

  if (typeof resp === 'string') {
    toast.error('There has been a problem loading the funnels');
  } else {
    onSuccess(resp);
  }
}

function FunnelIdFilter({ onSelect, selectedValue }) {
  const customStyles = {
    menu: (styles) => ({
      ...styles,
      backgroundColor: '#fff',
    }),
  };

  const isMounted = useMounted();
  const [optionData, setOptionData] = useState([]);

  useEffect(() => {
    if (isMounted()) {
      getFunnels(
        (funnels) => {
          const opts = funnels.map((fun) => {
            return {
              label: fun.name,
              value: fun.id,
            };
          });
          setOptionData(opts);
        },
        () => toast.error('Error fetching tags, please try again.'),
      );
    }
  }, [isMounted, getFunnels]);

  const selectedVals = optionData.reduce((a, b) => {
    if (selectedValue?.includes(b.value)) {
      a.push(b);
    }

    return a;
  }, []);
  return (
    <Select
      className="mt-2"
      classNamePrefix="react-select"
      id="filter-question-select"
      isMulti
      menuPosition="fixed"
      onChange={(vals) => {
        onSelect(vals, optionData);
      }}
      options={optionData}
      placeholder="Select Funnel(s)"
      styles={customStyles}
      value={selectedVals}
    />
  );
}

FunnelIdFilter.propTypes = {
  onSelect: PropTypes.func,
  selectedValue: PropTypes.shape(),
};

FunnelIdFilter.defaultProps = {
  onSelect: () => {},
  selectedValue: [],
};

export default FunnelIdFilter;
