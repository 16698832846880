import React from 'react';
import PropTypes from 'prop-types';
import { FormGroup } from 'reactstrap';
import { PillRadios } from '@Base/Forms/Custom/RadioBtns';

function InlineRadios({ name, radioBtns, value: checkedValue, onChange, className, isDisabled }) {
  return (
    <FormGroup inline check className={className}>
      {radioBtns.length &&
        radioBtns.map((radio) => {
          const { id, value } = radio;

          return (
            <PillRadios
              key={id}
              {...radio}
              id={`${id}-${name}`}
              name={name}
              checked={checkedValue === value}
              onChange={(newVal) => onChange(newVal)}
              disabled={isDisabled}
            />
          );
        })}
    </FormGroup>
  );
}

InlineRadios.propTypes = {
  name: PropTypes.string.isRequired,
  radioBtns: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      value: PropTypes.string,
      label: PropTypes.string,
    }),
  ),
  value: PropTypes.string,
  onChange: PropTypes.func,
  className: PropTypes.string,
  isDisabled: PropTypes.bool,
};

InlineRadios.defaultProps = {
  radioBtns: [],
  value: 'optional',
  onChange: () => {},
  className: null,
  isDisabled: false,
};

export default InlineRadios;
