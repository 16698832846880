import React, { useState, useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Form, FormGroup, Label, Input } from 'reactstrap';
import { toast } from 'react-toastify';

import { CancelButton, CreateButton } from '@Base/Buttons';
import { useFormKeypress } from '@Base/hooks';
import Required from '@Base/Forms/Custom/Required';
import { EmailPillsInput, UserEmailPillsInput } from '@Base/Forms/Custom/EmailPills';
import ErrorFeedback from '@Base/ErrorFeedback/ErrorFeedback';
import { SelectInput } from '@Base/Forms/Custom/CommonComponents';
import SingleUserEmailPillsInput from '@Base/Forms/Custom/EmailPills/SingleUserEmailPillsInput';

import { retryableAPICall } from '@API/common-api-utils';
import { updateTemplate, saveTemplate } from '@API/CommsAPI/EmailTemplateAPI';

import validation, { mapErrors } from '@JS/utils/validation';

import { EmailBodyInput, EmailAttachments } from '../../../../../Communication/email';

export const TEMPLATE_TYPES = {
  EVENT_INVITE: 'Invitation',
  EVENT_CONFIRMATION: 'Confirmation',
  EVENT_INVITE_REMINDER: 'Invite Reminder',
  EVENT_BOOKING_REMINDER: 'Booking Reminder',
  EVENT_PHONE_CONFIRMATION: 'Phone Confirmation',
  EVENT_VIDEO_CONFIRMATION: 'Video Confirmation',
  EVENT_FACE_TO_FACE_CONFIRMATION: 'Face to Face Confirmation',
  EVENT_UPDATED: 'Event Updated',
  EVENT_CANCELLED: 'Event Cancelled',
  EVENT_REMINDER_24: 'Event 24H Reminder',
  INVITE_WITHDRAWN: 'Invite Withdrawn',
};

const inputs = [
  {
    id: 'name',
    label: 'Template Name',
    type: 'text',
    required: true,
  },
  {
    id: 'type',
    label: 'Template Type',
    type: 'select',
    options: Object.entries(TEMPLATE_TYPES).map(([key, val]) => ({ value: key, label: val })),
    required: true,
  },
  {
    id: 'fromName',
    label: 'From Name',
    type: 'text',
    required: true,
  },
  {
    id: 'replyTo',
    label: 'Reply To Email',
    type: 'email',
    required: true,
    isUser: true,
  },
  {
    id: 'ccAddresses',
    label: 'Cc',
    type: 'email',
    isMulti: true,
    isUser: true,
    novalidate: true,
  },
  {
    id: 'bccAddresses',
    label: 'Bcc',
    type: 'email',
    isMulti: true,
    isUser: true,
    novalidate: true,
  },
  {
    id: 'subject',
    label: 'Subject Line',
    type: 'text',
    required: true,
  },
];

async function proxyCall(data, id) {
  if (id) return updateTemplate({ ...data, id });
  return saveTemplate(data);
}

function EmailPills({ isMulti, isUser, value, onChange }) {
  if (isUser) {
    if (isMulti) {
      return <UserEmailPillsInput value={value} onChange={onChange} />;
    }
    return <SingleUserEmailPillsInput value={value} onChange={onChange} />;
  }

  return <EmailPillsInput value={value} onChange={onChange} />;
}

function CommunicationsEditor({ isEditing, data, onSave, onCancel, companyName, contactEmail, strictType, groupType }) {
  const formRef = useFormKeypress();
  const [isSaving, setIsSaving] = useState(false);
  const [valObj, setValObj] = useState({});
  const [errors, setErrors] = useState({});
  // const [messageText, setMessageText] = useState('');

  useEffect(() => {
    const {
      id,
      name = '',
      fromName = companyName,
      replyTo = contactEmail,
      ccAddresses = [],
      bccAddresses = [],
      subject = '',
      attachments = [],
      content = '',
      typeChange,
      type = strictType || 'EVENT_INVITE',
      group = groupType || 'COMPANY',
      deletable,
    } = data;

    setValObj({
      id,
      name,
      fromName,
      replyTo,
      ccAddresses,
      bccAddresses,
      typeChange,
      subject,
      attachments,
      content,
      type,
      deletable,
      group,
    });
  }, [companyName, contactEmail, data, groupType, strictType]);

  function handleChange(id, val) {
    const updatedVals = { ...valObj, [id]: val };
    setValObj(updatedVals);
  }

  async function handleSave() {
    setIsSaving(true);

    const errObj = validation(
      [
        ...inputs,
        {
          id: 'content',
          required: true,
          maxStringSize: 1000000,
        },
      ],
      {
        ...valObj,
        // content: messageText,
      },
    );
    const { messages, hasErrors } = mapErrors(errObj);
    setErrors(messages);

    if (!hasErrors) {
      const { id, content, ...rest } = valObj;

      const resp = await retryableAPICall(() => proxyCall({ ...rest, message: content }, id));

      if (typeof resp === 'string') {
        toast.error(`Error ${isEditing ? 'updating' : 'creating'} template`);
      } else {
        toast.success(`Template successfully ${isEditing ? 'updated' : 'created'}`);
        onSave(resp);
      }
    }

    setIsSaving(false);
  }

  const saveBtnText = isSaving ? 'Saving...' : `${isEditing ? 'Update' : 'Create'} Template`;

  const filteredInputs = !valObj.typeChange ? inputs.filter((item) => item.id !== 'type') : inputs;

  return (
    <Form className="comms-editor comms-email-form" innerRef={formRef}>
      {filteredInputs.map(
        ({ id: inpId, label, required, type, isMulti, options, isUser }) =>
          (inpId !== 'type' || (inpId === 'type' && !strictType)) && (
            <FormGroup key={inpId}>
              <Label htmlFor={inpId}>
                {label}
                {required && <Required />}
              </Label>
              {type === 'email' ? (
                <EmailPills
                  value={valObj[inpId]}
                  onChange={(emails) => handleChange(inpId, emails)}
                  isMulti={isMulti}
                  isUser={isUser}
                />
              ) : (
                <Fragment>
                  {type === 'select' ? (
                    <SelectInput value={valObj[inpId]} onChange={(val) => handleChange(inpId, val)} options={options} />
                  ) : (
                    <Input
                      type="text"
                      id={inpId}
                      value={valObj[inpId] || ''}
                      onChange={(e) => handleChange(inpId, e.target.value)}
                    />
                  )}
                </Fragment>
              )}
              <ErrorFeedback message={errors[inpId]} />
            </FormGroup>
          ),
      )}
      <EmailAttachments
        id="attachments"
        label="Attachments"
        multiple
        isHidden={false}
        files={(valObj.attachments || []).map((file) => ({
          ...file,
          name: file.fileName,
          type: file.contentType,
          data: file.data,
        }))}
        onChange={(files) => {
          const normalizedFiles = files.map(({ name: fileName, type: contentType, id: fileId, dataUrl }) => ({
            fileName,
            id: fileId,
            contentType,
            data: dataUrl,
          }));
          handleChange('attachments', normalizedFiles);
        }}
      />
      <EmailBodyInput
        isLoading={isSaving}
        value={valObj.content}
        invalid={'content' in errors}
        errorMsg={errors.content}
        // eslint-disable-next-line no-unused-vars
        onChange={(val, plainText) => {
          // setMessageText(plainText);
          handleChange('content', val);
        }}
      />
      <CreateButton
        action={(e) => {
          e.preventDefault();
          handleSave();
        }}
        isLoading={isSaving}
        disabled={isSaving}
        label={saveBtnText}
      />
      <CancelButton label="Cancel" disabled={isSaving} action={onCancel} />
    </Form>
  );
}

CommunicationsEditor.propTypes = {
  companyName: PropTypes.string,
  contactEmail: PropTypes.string,
  data: PropTypes.shape(),
  isEditing: PropTypes.bool,
  onSave: PropTypes.func,
  onCancel: PropTypes.func,
  strictType: PropTypes.string,
  groupType: PropTypes.string,
};

CommunicationsEditor.defaultProps = {
  companyName: null,
  contactEmail: null,
  data: {},
  isEditing: false,
  onSave: () => {},
  onCancel: () => {},
  strictType: null,
  groupType: null,
};

function mapStateToProps(state) {
  const {
    companyData: {
      companyDetails: { companyName, contactEmail },
    },
  } = state;
  return { companyName, contactEmail };
}

export default connect(mapStateToProps)(CommunicationsEditor);
