import { toast } from 'react-toastify';
import fileDownload from 'js-file-download';
import { retryableAPICall } from '@API/common-api-utils';
import { searchCandidates } from '@API/SearchAPI/SearchAPI';
import { getEvents, deleteSlot } from '@API/SchedulingAPI/CalendarAPI';
import { getLocation } from '@API/LocationAPI';
import { inviteToInterview, inviteBooking } from '@API/SchedulingAPI/InterviewAPI';
import { getEventType } from '@API/SchedulingAPI/EventTypeAPI';
import { getTemplateById } from '@API/TemplateAPI';
import { getAvailableSlotCount } from '@API/SchedulingAPI/SlotAPI';

export async function getFormattedEventTypes() {
  const resp = await retryableAPICall(() => getEventType());

  if (typeof resp === 'string') {
    toast.error('Error loading Event Types, please try again later');
    return [];
  }

  return resp.map(({ id, name }) => ({ label: name, value: id }));
}

export async function getCandidates(activeAccountId, vacancyIds) {
  const filters = [];

  if (vacancyIds && Array.isArray(vacancyIds)) {
    filters.push({
      field: 'vacancyId',
      operation: 'IN',
      value: vacancyIds,
    });
  }

  if (activeAccountId) {
    filters.push({
      field: 'accountId',
      operation: 'IN',
      value: [activeAccountId],
    });
  }

  const resp = await retryableAPICall(() =>
    searchCandidates(
      undefined,
      undefined,
      [{ field: 'submittedDateTime', order: 'DESC' }],
      filters,
      [
        'id',
        'vacancyDetails',
        'applicantProfile',
        'funnelStageId',
        'funnelStageOrder',
        'funnelStageName',
        'funnelId',
        'accountName',
        'accountId',
        'applicationFormMetaData.applicationFormId',
        'createdDateTime',
        'appliedOnDate',
        'profileSummary',
        'submittedDateTime',
      ],
      undefined,
      1000,
    ),
  );

  if (typeof resp === 'string') {
    toast.error('Error loading Candidates, please try again later');
    return [];
  }

  return resp.results.map(({ id, firstName, surname }) => {
    return {
      label: `${firstName} ${surname}`,
      value: id,
    };
  });
}

export async function getEventData(
  startDate,
  endDate,
  accountId,
  onError,
  eventTypeId,
  isInviteOnly,
  onlyAvailable,
  onlyWithBookings,
) {
  if (startDate && endDate) {
    const resp = await retryableAPICall(() =>
      getEvents(startDate, endDate, accountId, eventTypeId, isInviteOnly, onlyAvailable, onlyWithBookings),
    );

    if (typeof resp === 'string' && typeof onError === 'function') {
      onError();
    } else {
      return resp;
    }
  }

  return [];
}

export async function deleteEventSlot(id, emailData, isRecurrence, onSuccess = () => {}, onError = () => {}) {
  const resp = await retryableAPICall(() => deleteSlot(id, emailData, isRecurrence));

  if (typeof resp === 'string' && resp.length) {
    onError();
  } else {
    onSuccess();
  }
}

export async function getLocations(locationType = 'EVENT') {
  const resp = await retryableAPICall(() => getLocation({ type: locationType }));

  if (typeof resp === 'string') {
    toast.error('Error loading Locations, please try again later');
    return [];
  }

  let defaultIdx = 0;

  return resp
    .map(({ default: def, id, name, eventFormat, location }, i) => {
      if (def) defaultIdx = i;
      return {
        default: def,
        eventFormat,
        label: name,
        location,
        value: id,
      };
    })
    .map((locObj, i) => ({ ...locObj, default: defaultIdx === i }));
}

export async function getOptions(id, args = []) {
  if (id === 'eventTypeId') return getFormattedEventTypes();
  if (id === 'candidateIds') return getCandidates(...args);
  if (id === 'slotId') return getEventData(...[...args, true]);
  if (id === 'locationId') return getLocations();
  return [];
}

export async function inviteCandidates(data = {}, onSuccess = () => {}, onError = () => {}) {
  const resp = await inviteToInterview(data);

  if (typeof resp === 'string') {
    onError();
  } else {
    onSuccess();
  }
}

export async function getAvailableSlotCountCall(eventTypeId, accountId, onSuccess = () => {}, onError = () => {}) {
  const resp = await retryableAPICall(() => getAvailableSlotCount(eventTypeId, accountId));

  if (typeof resp === 'string') {
    onError();
  } else {
    onSuccess(resp);
  }
}

export async function inviteBookingStatus(slotId, onSuccess = () => {}, onError = () => {}) {
  const resp = await retryableAPICall(() => inviteBooking(slotId));

  if (Array.isArray(resp)) {
    onSuccess(resp);
  } else if (typeof resp === 'string') {
    onError();
  }
}

export async function getEventTypeObj(id) {
  if (id) {
    const resp = await retryableAPICall(() => getEventType(id));
    if (typeof resp === 'string') {
      toast.error('Error fetching Event type');
      return {};
    }
    return resp;
  }
  return {};
}

export async function getInviteEmailTemplate(id) {
  if (id) {
    const resp = await retryableAPICall(() => getTemplateById(id));
    if (typeof resp === 'string') {
      toast.error('Error fetching Invite Email Template');
      return {};
    }
    return resp;
  }
  return {};
}

export async function downloadIcs(data, name) {
  const base64Str = await fetch(`data:text/calendar;base64,${btoa(data)}`);
  const blob = await base64Str.blob();
  return fileDownload(blob, name);
}
