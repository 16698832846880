import axios from 'axios';
import { getCommonHeaders } from '../common-api-utils';
import config from '../../config/config';

const { rtw, api } = config;
const basePath = api.middlewareAPIURL + rtw.basePath;

/**
 * All API calls expected to be called inside retryableAPICall function to manage error handling.
 */

export async function requestRTWDocumentation(candidateId, subject, content, replyTo, fromName, attachments) {
  const result = await axios.post(
    `${basePath}/request`,
    {
      candidateId,
      emailTemplate: {
        subject,
        content,
        replyTo,
        fromName,
        attachments,
      },
    },
    { headers: getCommonHeaders() },
  );

  if (result.status === 201 || result.status === 200) return result.data;
  return null;
}

export async function getRTWCheck(candidateId) {
  const result = await axios.get(
    `${basePath}/check/${candidateId}`,
    { headers: getCommonHeaders() },
  );

  if (result.status === 201 || result.status === 200) return result.data;
  return null;
}

export async function sendRTWReminder(candidateId) {
  const result = await axios.get(
    `${basePath}/request/reminder/${candidateId}`,
    { headers: getCommonHeaders() },
  );

  if (result.status === 201 || result.status === 200) return result.data;
  return null;
}

export async function performImposterCheck(candidateId, imposterCheck) {
  const result = await axios.post(
    `${basePath}/imposter-check/${candidateId}`,
    imposterCheck,
    { headers: getCommonHeaders() },
  );

  if (result.status === 201 || result.status === 200) return result.data;
  return null;
}
