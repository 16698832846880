import React, { useState, useRef, useEffect } from 'react';
import { Volume2, StopCircle } from 'lucide-react';
import { Button } from 'reactstrap';
import { toast } from 'react-toastify';
import { synthesizeSpeech } from '@API/AI/TTSAPI';

const TextToSpeechPlayer = ({ text }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const audioRef = useRef(null);
  const audioUrlRef = useRef(null);

  useEffect(() => {
    return () => {
      if (audioRef.current) {
        audioRef.current.pause();
        audioRef.current = null;
      }
      if (audioUrlRef.current) {
        URL.revokeObjectURL(audioUrlRef.current);
      }
    };
  }, []);

  const handleAudio = async () => {
    if (isPlaying) {
      audioRef.current?.pause();
      audioRef.current.currentTime = 0;
      setIsPlaying(false);
      return;
    }

    if (audioRef.current) {
      audioRef.current.play();
      setIsPlaying(true);
      return;
    }

    setIsLoading(true);
    try {
      const audioBlob = await synthesizeSpeech(text);
      if (!audioBlob) throw new Error('Failed to generate speech');

      audioUrlRef.current = URL.createObjectURL(audioBlob);
      const audio = new Audio(audioUrlRef.current);
      audioRef.current = audio;

      audio.onended = () => {
        setIsPlaying(false);
        audio.currentTime = 0;
      };

      await audio.play();
      setIsPlaying(true);
    } catch (error) {
      toast.error('Failed to play text-to-speech audio');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Button
      color="link"
      size="sm"
      onClick={handleAudio}
      disabled={isLoading}
      className="d-inline-flex align-items-center gap-1 p-0"
    >
      {isPlaying ? <StopCircle size={16} /> : <Volume2 className={isLoading ? 'animate-pulse' : ''} size={16} />}
      {isLoading ? 'Loading...' : isPlaying ? 'Stop' : 'Listen'}
    </Button>
  );
};

export default TextToSpeechPlayer;
