export function getAddressObject(locationObj) {
  if (locationObj) {
    const { latitude, longitude, lat, long, ...rest } = locationObj;
    const { poiName, country, countryName } = rest;

    return {
      ...rest,
      country: country || countryName,
      buildingName: poiName,
      addressLine1: rest.addressLine1,
      city: rest.city,
      postCode: rest.postCode,
      lat: lat || latitude,
      long: long || longitude,
    };
  }

  return null;
}

export function formatAddress(locationObj) {
  let addressStr = '';

  if (locationObj) {
    const { type, countryName, name, poiName, addressLine1, addressLine2, addressLine3, city, county, postCode } =
      locationObj;

    if (type === 'Country') {
      addressStr = countryName;
    } else if (/city|town/.test(type)) {
      addressStr = name;
    } else {
      if (poiName) addressStr = `${poiName}, `;
      if (addressLine1) addressStr += `${addressLine1}, `;
      if (addressLine2) addressStr += `${addressLine2}, `;
      if (addressLine3) addressStr += `${addressLine3}, `;
      if (city) addressStr += `${city}, `;
      if (county && type !== 'Municipality') addressStr += `${county}, `;
      if (postCode) addressStr += `${postCode}, `;

      addressStr = addressStr.substring(0, addressStr.length - 2);
    }
  }

  return addressStr;
}
