import React, { useState, useEffect } from 'react';
import { getSyncedCalendars, revokeCalendarAccess, syncCalendar } from '@API/SchedulingAPI/CalendarAPI';
import { toast } from 'react-toastify';
import { ModalPopup } from '@Base/Modal';
import { Button } from 'reactstrap';
import { startPolling } from '@JS/actions/eventNotificationActions';
import { connect } from 'react-redux';
import SyncCalendarDropdown from './SyncCalendarDropdown';
import SyncCalendarModal from './SyncCalendarModal';
import { googleCalendarColours, serviceDisplayNameMap } from '../constants';
import { Calendar } from 'lucide-react';

function SyncCalendarButton({ accountAccess }) {
  const [showDropdown, setShowDropdown] = useState(false);
  const [syncedCalendars, setSyncedCalendars] = useState([]);
  const [modalConfig, setModalConfig] = useState({ show: false, selectedCalendarService: undefined, editMode: false });
  const [syncOptions, setSyncOptions] = useState({
    selectedAccounts: [],
    selectedColor: undefined,
    syncType: 'BI',
    obfuscatedSync: false,
    onlyPersonal: false,
  });

  useEffect(() => {
    const fetchSyncedCalendars = async () => {
      try {
        const results = await getSyncedCalendars();
        setSyncedCalendars(results);
      } catch (error) {
        toast.error('Error fetching synced calendars');
      }
    };
    fetchSyncedCalendars();
  }, []);

  useEffect(() => {
    console.log(JSON.stringify(syncOptions));
  }, [syncOptions]);

  const handleSyncOptionClick = (serviceType, serviceName) => {
    setShowDropdown(false);
    setModalConfig({ show: true, selectedCalendarService: serviceName, editMode: false });
  };

  const handleEditOptionClick = (serviceType, serviceName, calendar) => {
    setShowDropdown(false);

    setSyncOptions({
      ...syncOptions,
      selectedAccounts: calendar.accountIds.map((id) => ({
        value: id,
        label: accountAccess.find((acc) => (acc.accountId = id))?.accountName,
      })),
      selectedColor: googleCalendarColours.find((colour) => colour.value === parseInt(calendar.colourCode)),
      syncType: calendar.syncType,
      obfuscatedSync: calendar.obfuscatedEvents,
      onlyPersonal: calendar.onlyPersonal,
    });
    setModalConfig({ show: true, selectedCalendarService: serviceName, editMode: true });
  };

  const handleSyncOptionConfirmed = async (serviceType) => {
    setShowDropdown(false);
    setModalConfig({ show: false, selectedCalendarService: undefined, editMode: false });

    try {
      let result = await syncCalendar(serviceType);
      localStorage.setItem('calendarSyncData', JSON.stringify(syncOptions));

      const url = new URL(result);
      const redirectUri = url.searchParams.get('redirect_uri');
      url.searchParams.set('redirect_uri', redirectUri);
      result = url.toString();

      const width = 500;
      const height = 600;
      const left = window.screen.width / 2 - width / 2 + window.screenX;
      const top = window.screen.height / 2 - height / 2 + window.screenY;

      const newWindow = window.open(result, 'googleAuth', `width=${width},height=${height},top=${top},left=${left}`);

      const handleWindowClosure = async () => {
        clearInterval(checkWindowClosed);
        try {
          const results = await getSyncedCalendars();
          setSyncedCalendars(results);
        } catch (error) {
          console.error('Failed to get synced calendars:', error);
          toast.error('Failed to update synced calendars. Please refresh the page.');
        }
      };

      // Check if the new window has been closed
      const checkWindowClosed = setInterval(() => {
        if (newWindow.closed) {
          handleWindowClosure();
        }
      }, 500);

      setSyncedCalendars([
        ...syncedCalendars,
        {
          type: serviceType,
          accountIds: syncOptions.selectedAccounts.map((acc) => acc.value),
          colourCode: syncOptions.selectedColor,
        },
      ]);
    } catch (error) {
      toast.error('Could not manage account syncing at this time due to an error. Please try again later.');
    }
  };

  const handleUnsyncOptionClick = async (serviceType) => {
    const isConfirmed =
      window.confirm(`Your calendar will disconnect from Talent Funnel. All existing Talent Funnel created events will remain in your external calendar, but all external calendar events in the schedule view will disappear.

Are you sure you want to disconnect ${serviceDisplayNameMap[serviceType]}?`);
    if (isConfirmed) {
      try {
        await revokeCalendarAccess(serviceType);
        const results = await getSyncedCalendars();

        setSyncedCalendars(results);
        setModalConfig({ show: false, selectedCalendarService: undefined, editMode: false });
        toast.success('Successfully disconnected the external calendar');
      } catch (error) {
        toast.error('Could not disconnect the external calendar at this time due to an error. Please try again later.');
      }
    }
  };

  const isServiceSynced = (serviceType) => syncedCalendars.some((calendar) => calendar.type === serviceType);

  return (
    <div className="tw-relative">
      <button
        className="tw-bg-gray-100 tw-text-gray-700 tw-py-2 tw-px-4 tw-rounded-lg tw-flex tw-items-center tw-gap-2 tw-border tw-border-gray-200 tw-hover:bg-gray-200 tw-transition-colors tw-duration-200"
        onClick={() => setShowDropdown((prev) => !prev)}
      >
        <Calendar size={18} />
        <span>Sync Calendar</span>
      </button>

      {showDropdown && (
        <SyncCalendarDropdown
          syncedCalendars={syncedCalendars}
          isServiceSynced={isServiceSynced}
          handleSyncOptionClick={handleSyncOptionClick}
          handleEditOptionClick={handleEditOptionClick}
          handleUnsyncOptionClick={handleUnsyncOptionClick}
        />
      )}

      <SyncCalendarModal
        modalConfig={modalConfig}
        accountAccess={accountAccess}
        selectedAccounts={syncOptions.selectedAccounts}
        setSyncOptions={setSyncOptions}
        syncOptions={syncOptions}
        handleSyncOptionConfirmed={handleSyncOptionConfirmed}
        handleUnsyncOptionClick={handleUnsyncOptionClick}
        onClose={() => {
          setModalConfig({ show: false, selectedCalendarService: undefined, editMode: false });
        }}
      />
    </div>
  );
}

function mapStateToProps(state) {
  const {
    userData: {
      userDetails: {
        data: { accountAccess = [] },
      },
    },
  } = state;
  return { accountAccess };
}

function mapDispatchToProps(dispatch) {
  return {
    startPoll: () => {
      dispatch(startPolling());
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SyncCalendarButton);
