import React, { useState } from 'react';
import Select from 'react-select';
import { ModalPopup } from '@Base/Modal';
import { Button, FormGroup, Label, Input } from 'reactstrap';
import { googleCalendarColours, menuStyles } from '../constants';
import schedule from '../../../../assets/img/brand/background/schedule.webp';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import { Info } from 'lucide-react';

const SyncCalendarModal = ({
  modalConfig,
  accountAccess,
  selectedAccounts,
  setSyncOptions,
  syncOptions,
  handleSyncOptionConfirmed,
  handleUnsyncOptionClick,
  onClose,
}) => {
  const [fullSync, setFullSync] = useState(true);
  const [obfuscateEvents, setObfuscateEvents] = useState(false);
  const [accountError, setAccountError] = useState('');
  const [colourError, setColourError] = useState('');

  const handleConfirm = () => {
    if (accountAccess.length > 1 && (!selectedAccounts || selectedAccounts.length === 0)) {
      setAccountError('Please select at least one account to sync.');
      return;
    }
    setAccountError('');
    handleSyncOptionConfirmed(modalConfig.selectedCalendarService, {
      syncType: fullSync ? 'full' : 'one-way',
      obfuscatedSync: obfuscateEvents,
    });
  };

  return (
    <ModalPopup
      isOpen={modalConfig.show}
      onCancel={onClose}
      minHeight="400px"
      hideOkayButton
      hideCancelButton
      onRequestClose={onClose}
      onToggle={onClose}
      style={{ display: 'flex', flexDirection: 'column', width: '100%', height: '100%' }}
    >
      <div style={{ display: 'flex', flex: 1, height: '400px' }}>
        <div
          style={{
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            padding: '20px',
          }}
        >
          <h3>{`Sync with ${modalConfig.selectedCalendarService} calendar`}</h3>

          {modalConfig.selectedCalendarService === 'Google' && (
            <div>
              <h6>Select calendar event color</h6>
              <Select
                options={googleCalendarColours}
                styles={menuStyles}
                value={syncOptions.selectedColour ? syncOptions.selectedColour : googleCalendarColours[0]}
                onChange={(val) => {
                  setSyncOptions({ ...syncOptions, selectedColour: val });
                }}
                getOptionLabel={(option) => (
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div
                      style={{
                        height: '15px',
                        width: '15px',
                        backgroundColor: option.colour,
                        marginRight: '10px',
                        borderRadius: '2px',
                      }}
                    />
                    {option.label}
                  </div>
                )}
              />
              {colourError && <div style={{ color: 'red', marginTop: '5px' }}>{colourError}</div>}
            </div>
          )}
          {accountAccess.length > 1 && (
            <div>
              <h6>Select accounts to sync</h6>
              <Select
                isMulti
                styles={menuStyles}
                options={accountAccess.map((acc) => ({ value: acc.accountId, label: acc.accountName }))}
                value={selectedAccounts}
                onChange={(val) => {
                  setSyncOptions({ ...syncOptions, selectedAccounts: val });
                  setAccountError('');
                }}
              />
              {accountError && <div style={{ color: 'red', marginTop: '5px' }}>{accountError}</div>}
            </div>
          )}

          <div style={{ height: '100px' }}>
            <FormGroup check className="d-flex align-items-center">
              <Label check className="d-flex align-items-center">
                <Input
                  type="checkbox"
                  checked={syncOptions.onlyPersonal}
                  onChange={(e) => setSyncOptions({ ...syncOptions, onlyPersonal: e.target.checked })}
                  className="mr-2"
                  style={{ width: '18px', height: '18px' }}
                />
                <span style={{ marginTop: '9px', marginLeft: '3px' }}>Only sync your events</span>
                <Tippy content="This will only sync events you have created in the schedule view in the accounts you've seleced to your external calendar">
                  <span className="ml-2">
                    <Info size={16} />
                  </span>
                </Tippy>
              </Label>
            </FormGroup>

            <FormGroup check className="d-flex align-items-center">
              <Label check className="d-flex align-items-center">
                <Input
                  type="checkbox"
                  checked={syncOptions.syncType === 'BI'}
                  onChange={(e) => setSyncOptions({ ...syncOptions, syncType: e.target.checked ? 'BI' : 'UNI' })}
                  className="mr-2"
                  style={{ width: '18px', height: '18px' }}
                />
                <span style={{ marginTop: '9px', marginLeft: '3px' }}>Sync both ways</span>
                <Tippy content="This will sync events between your external calendar and the schedule view">
                  <span className="ml-2">
                    <Info size={16} />
                  </span>
                </Tippy>
              </Label>
            </FormGroup>

            {syncOptions.syncType === 'BI' && (
              <FormGroup check className="d-flex align-items-center">
                <Label check className="d-flex align-items-center">
                  <Input
                    type="checkbox"
                    checked={syncOptions.obfuscatedSync}
                    onChange={(e) => setSyncOptions({ ...syncOptions, obfuscatedSync: e.target.checked })}
                    className="mr-2"
                    style={{ width: '18px', height: '18px' }}
                  />
                  <span style={{ marginTop: '9px', marginLeft: '3px' }}>Keep external event details private</span>
                  <Tippy content="Only event times will be visible, details of external events will not be stored">
                    <span className="ml-2">
                      <Info size={16} />
                    </span>
                  </Tippy>
                </Label>
              </FormGroup>
            )}
          </div>

          <Button color="primary" onClick={handleConfirm} className="mt-3">
            Continue
          </Button>
          {modalConfig.editMode && (
            <Button
              color="danger"
              style={{ marginTop: '10px' }}
              onClick={() => handleUnsyncOptionClick(modalConfig.selectedCalendarService)}
            >
              Unlink
            </Button>
          )}
        </div>

        <div
          style={{
            flex: 1,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            position: 'relative',
            overflow: 'hidden',
          }}
        >
          <div
            style={{
              position: 'absolute',
              top: 0,
              right: 0,
              bottom: 0,
              left: 0,
              backgroundImage: `url(${schedule})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              opacity: 0.8,
            }}
          />
        </div>
      </div>
    </ModalPopup>
  );
};

export default SyncCalendarModal;
