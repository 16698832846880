import React, { useState, useEffect } from 'react';
import {
  getNotificationConfiguration,
  updateNotificationConfiguration,
  createNotificationConfiguration,
  deleteNotificationConfiguration
} from '../../../../../api/NotificationAPI/NotificationAPI';

const NotificationConfigForm = () => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [config, setConfig] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [formData, setFormData] = useState({
    templateId: '',
    unsubscribeGroupId: ''
  });
  const [validationErrors, setValidationErrors] = useState({
    templateId: '',
    unsubscribeGroupId: ''
  });

  const validateForm = () => {
    const errors = {
      templateId: '',
      unsubscribeGroupId: ''
    };
    let isValid = true;

    // Template ID validation
    const templateIdRegex = /^d-[a-f0-9]{32}$/;
    if (!templateIdRegex.test(formData.templateId)) {
      errors.templateId = 'Template ID must be in a format like: d-3d28eedf21ea443195b293de1bb4026e';
      isValid = false;
    }

    // Unsubscribe Group ID validation
    const groupId = parseInt(formData.unsubscribeGroupId);
    if (isNaN(groupId) || !Number.isInteger(groupId) || groupId < 0) {
      errors.unsubscribeGroupId = 'Unsubscribe Group ID must be a positive integer';
      isValid = false;
    }

    setValidationErrors(errors);
    return isValid;
  };

  useEffect(() => {
    fetchConfig();
  }, []);

  const fetchConfig = async () => {
    try {
      const response = await getNotificationConfiguration();
      setConfig(response);
      if (response) {
        setFormData({
          templateId: response.templateId || '',
          unsubscribeGroupId: response.unsubscribeGroupId?.toString() || ''
        });
      }
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;

    setLoading(true);
    try {
      const payload = {
        templateId: formData.templateId,
        unsubscribeGroupId: parseInt(formData.unsubscribeGroupId)
      };

      if (config) {
        await updateNotificationConfiguration(payload.templateId, payload.unsubscribeGroupId);
      } else {
        await createNotificationConfiguration(payload.templateId, payload.unsubscribeGroupId);
      }
      await fetchConfig();
      setIsEditing(false);
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async () => {
    setLoading(true);
    try {
      await deleteNotificationConfiguration();
      setConfig(null);
      setFormData({ templateId: '', unsubscribeGroupId: '' });
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <div className="tw-flex tw-items-center tw-justify-center tw-min-h-[200px]">
        <div className="tw-animate-spin tw-rounded-full tw-h-12 tw-w-12 tw-border-4 tw-border-blue-500 tw-border-t-transparent"></div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="tw-bg-red-50 tw-p-4 tw-rounded-md">
        <div className="tw-flex">
          <div className="tw-flex-shrink-0">
            <svg className="tw-h-5 tw-w-5 tw-text-red-400" viewBox="0 0 20 20" fill="currentColor">
              <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" clipRule="evenodd" />
            </svg>
          </div>
          <div className="tw-ml-3">
            <h3 className="tw-text-sm tw-font-medium tw-text-red-800">Error loading configuration</h3>
            <p className="tw-mt-2 tw-text-sm tw-text-red-700">{error}</p>
          </div>
        </div>
      </div>
    );
  }

  if (config && !isEditing) {
    return (
      <div>
        <div className="tw-flex tw-justify-between tw-items-center tw-mb-4">
          <h3 className="tw-text-lg tw-font-medium tw-text-gray-900">Current Configuration</h3>
          <div className="tw-space-x-3">
            <button
              onClick={() => setIsEditing(true)}
              className="tw-inline-flex tw-items-center tw-px-3 tw-py-2 tw-border tw-border-gray-300 tw-shadow-sm tw-text-sm tw-font-medium tw-rounded-md tw-text-gray-700 tw-bg-white hover:tw-bg-gray-50"
            >
              Edit Configuration
            </button>
            <button
              onClick={handleDelete}
              className="tw-inline-flex tw-items-center tw-px-3 tw-py-2 tw-border tw-border-red-300 tw-shadow-sm tw-text-sm tw-font-medium tw-rounded-md tw-text-red-700 tw-bg-white hover:tw-bg-red-50"
            >
              Delete
            </button>
          </div>
        </div>
        <dl className="tw-grid tw-grid-cols-1 tw-gap-4">
          <div className="tw-bg-gray-50 tw-px-4 tw-py-3 tw-rounded-md">
            <dt className="tw-text-sm tw-font-medium tw-text-gray-500">Template ID</dt>
            <dd className="tw-mt-1 tw-text-sm tw-text-gray-900">{config.templateId || 'Not set'}</dd>
          </div>
          <div className="tw-bg-gray-50 tw-px-4 tw-py-3 tw-rounded-md">
            <dt className="tw-text-sm tw-font-medium tw-text-gray-500">Unsubscribe Group ID</dt>
            <dd className="tw-mt-1 tw-text-sm tw-text-gray-900">{config.unsubscribeGroupId || 'Not set'}</dd>
          </div>
        </dl>
      </div>
    );
  }

  return (
    <form onSubmit={handleSubmit} className="tw-space-y-6">
      <div className="tw-space-y-4">
        <div>
          <label htmlFor="templateId" className="tw-block tw-text-sm tw-font-medium tw-text-gray-700">
            Template ID
          </label>
          <input
            type="text"
            id="templateId"
            value={formData.templateId}
            onChange={(e) => setFormData(prev => ({ ...prev, templateId: e.target.value }))}
            className={`tw-mt-1 tw-block tw-w-full tw-rounded-lg tw-border ${
              validationErrors.templateId ? 'tw-border-red-300' : 'tw-border-gray-300'
            } tw-bg-white tw-px-3 tw-py-2 tw-text-sm tw-text-gray-900 tw-shadow-sm tw-placeholder:text-gray-400 focus:tw-border-blue-500 focus:tw-outline-none focus:tw-ring-1 focus:tw-ring-blue-500`}
            required
          />
          {validationErrors.templateId && (
            <p className="tw-mt-1 tw-text-sm tw-text-red-600">{validationErrors.templateId}</p>
          )}
        </div>

        <div>
          <label htmlFor="unsubscribeGroupId" className="tw-block tw-text-sm tw-font-medium tw-text-gray-700">
            Unsubscribe Group ID
          </label>
          <input
            type="number"
            id="unsubscribeGroupId"
            value={formData.unsubscribeGroupId}
            onChange={(e) => setFormData(prev => ({ ...prev, unsubscribeGroupId: e.target.value }))}
            className={`tw-mt-1 tw-block tw-w-full tw-rounded-lg tw-border ${
              validationErrors.unsubscribeGroupId ? 'tw-border-red-300' : 'tw-border-gray-300'
            } tw-bg-white tw-px-3 tw-py-2 tw-text-sm tw-text-gray-900 tw-shadow-sm tw-placeholder:text-gray-400 focus:tw-border-blue-500 focus:tw-outline-none focus:tw-ring-1 focus:tw-ring-blue-500`}
            required
          />
          {validationErrors.unsubscribeGroupId && (
            <p className="tw-mt-1 tw-text-sm tw-text-red-600">{validationErrors.unsubscribeGroupId}</p>
          )}
        </div>
      </div>

      <div className="tw-flex tw-justify-end tw-space-x-3">
        {config && (
          <button
            type="button"
            onClick={() => setIsEditing(false)}
            className="tw-inline-flex tw-justify-center tw-rounded-md tw-border tw-border-gray-300 tw-bg-white tw-py-2 tw-px-4 tw-text-sm tw-font-medium tw-text-gray-700 tw-shadow-sm hover:tw-bg-gray-50 focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-blue-500 focus:tw-ring-offset-2"
          >
            Cancel
          </button>
        )}
        <button
          type="submit"
          className="tw-inline-flex tw-justify-center tw-rounded-md tw-border tw-border-transparent tw-bg-blue-600 tw-py-2 tw-px-4 tw-text-sm tw-font-medium tw-text-white tw-shadow-sm hover:tw-bg-blue-700 focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-blue-500 focus:tw-ring-offset-2"
        >
          Save Configuration
        </button>
      </div>
    </form>
  );
};

export default NotificationConfigForm;
