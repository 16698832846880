import React, { Fragment, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { ButtonGroup, Button } from 'reactstrap';
import cx from 'classnames';
import { Confirmation } from '@Base/Modal';

const flagLookup = {
  en: 'gb',
};

function LanguageToggle({ languages, onChange, selectedLanguage, confirmProps }) {
  const [showConfirm, setShowConfirm] = useState(false);
  const [lang, setLang] = useState('en');

  useEffect(() => {
    setLang(selectedLanguage);
  }, [selectedLanguage]);

  const { title, content, onConfirm = () => {}, onCancel = () => {} } = confirmProps;

  return (
    <Fragment>
      <ButtonGroup size="sm" className="language-toggle">
        {languages.map((pfx) => (
          <Button
            key={pfx}
            className={cx('language-btn', { selected: selectedLanguage === pfx })}
            outline
            onClick={() => {
              setLang(pfx);

              if (!content) {
                onChange(pfx);
              } else {
                setShowConfirm(true);
              }
            }}
          >
            {pfx.toUpperCase()}
          </Button>
        ))}
      </ButtonGroup>
      {content && (
        <Confirmation
          show={showConfirm}
          title={title}
          content={content}
          cancelCallback={() => {
            setShowConfirm(false);
            onCancel();
          }}
          confirmCallback={() => {
            setShowConfirm(false);
            onConfirm();
            onChange(lang);
          }}
        />
      )}
    </Fragment>
  );
}

LanguageToggle.propTypes = {
  languages: PropTypes.arrayOf(PropTypes.string),
  onChange: PropTypes.func,
  selectedLanguage: PropTypes.string,
  confirmProps: PropTypes.shape({
    title: PropTypes.string,
    content: PropTypes.string,
    onConfirm: PropTypes.func,
    onCancel: PropTypes.func,
  }),
};

LanguageToggle.defaultProps = {
  languages: [],
  onChange: () => {},
  selectedLanguage: 'en',
  confirmProps: {
    title: null,
    content: null,
    onConfirm: () => {},
    onCancel: () => {},
  },
};

export default LanguageToggle;
