import React, { Fragment, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { toggleHeight } from '@JS/utils/toggle';
import CandidateStatusList from './CandidateStatusList';
import IconSVG from '@Base/SVG/IconSVG';
import { ACTION_COLOUR } from '@JS/utils/colours';

function BookingStatusItem({ status, label, candidates, onStatusChange }) {
  const togglePaneRef = useRef();
  const [isExpanded, setIsExpanded] = useState(false);

  function handleToggle(paneElm, collapse) {
    setIsExpanded(collapse);
    toggleHeight(paneElm, !collapse);
  }

  return (
    <Fragment>
      <div
        className="d-flex align-items-center candidate-toggle"
        role="button"
        tabIndex="0"
        onClick={() => handleToggle(togglePaneRef.current, !isExpanded)}
        onKeyUp={() => handleToggle(togglePaneRef.current, !isExpanded)}
      >
        <IconSVG
          name="Chevron"
          fill={ACTION_COLOUR}
          className={`btn-chevron-toggle ${isExpanded ? 'toggle-up' : ''}`}
        />
        <div className="candidate-status-label">{label}</div>
        <div className="candidate-status-count">{`(${candidates.length})`}</div>
      </div>
      <div className="candidate-toggle-pane" ref={togglePaneRef}>
        <CandidateStatusList status={status} candidates={candidates} onStatusChange={onStatusChange} />
      </div>
    </Fragment>
  );
}

BookingStatusItem.propTypes = {
  status: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  candidates: PropTypes.arrayOf(PropTypes.shape()),
  onStatusChange: PropTypes.func,
};

BookingStatusItem.defaultProps = {
  candidates: [],
  onStatusChange: () => {},
};

export default BookingStatusItem;
