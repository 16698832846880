import React from 'react';
import PropTypes from 'prop-types';
import IconSVG from '../../SVG/IconSVG';
import { ACTION_COLOUR } from '@JS/utils/colours';

export default function AddFilter({ handleClick }) {
  return (
    <div className="action-text mt-3 mb-2" onClick={handleClick} onKeyUp={() => {}} role="button" tabIndex="0">
      <IconSVG name="Plus Circle" width="1.25em" className="icon-add-plus" fill={ACTION_COLOUR} />
      <span>Add New Filter</span>
    </div>
  );
}

AddFilter.propTypes = {
  handleClick: PropTypes.func,
};

AddFilter.defaultProps = {
  handleClick: () => {},
};
