import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { FormGroup, Label } from 'reactstrap';
import { useSelector } from 'react-redux';

import { vacancyStatusTypes } from '../../../../EventOptions';

function VacancyStatusInput({ selectedNode, onChange }) {
  const { categories } = useSelector((bigState) => bigState.categoryReducer);

  return (
    <>
      <FormGroup required>
        <Label>
          Status<span className="required">*</span>
        </Label>
        <Select
          required
          isMulti
          className="react-select"
          placeholder="Select Status"
          options={vacancyStatusTypes}
          value={vacancyStatusTypes.reduce((acc, cur) => {
            if (selectedNode?.status?.includes(cur.value)) acc.push(cur);
            return acc;
          }, [])}
          onChange={(val) => {
            onChange({ status: val.map((a) => a.value) });
          }}
        />
      </FormGroup>
      <FormGroup required>
        <Label>Roles</Label>
        <Select
          isMulti
          className="react-select "
          placeholder="Select a Role"
          options={categories.map((category) => ({ value: category.id, label: category.label }))}
          value={categories.reduce((acc, cur) => {
            if (selectedNode?.category?.includes(cur.id)) acc.push({ value: cur.id, label: cur.label });
            return acc;
          }, [])}
          onChange={(val) => {
            onChange({ category: val.map((a) => a.value) });
          }}
        />
      </FormGroup>
    </>
  );
}

VacancyStatusInput.propTypes = {
  selectedNode: PropTypes.object,
  onChange: PropTypes.func,
};

VacancyStatusInput.defaultProps = {
  selectedNode: {},
  onChange: () => {},
};

export default VacancyStatusInput;
