/* eslint-disable no-nested-ternary */
import PropTypes from 'prop-types';
import React, { useRef, useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { Button, Card, CardBody, Col, Row } from 'reactstrap';
import { IconSVG } from '@Base/SVG';
import { LoadingScreen } from '@Base/Loading';
import { retryableAPICall } from '@API/common-api-utils';
import { sendPersonalityTestReminder } from '@API/PersonalityTestAPI/PersonalityTestAPI';
import { downloadFiles } from '@API/FileAPI/FileAPI';
import { getPTData } from './utils';
import { checkPermissions } from '@JS/auth/AuthUtils';
import { ActionButton, IconButton } from '@Base/Buttons';
import { HeadingPushRight } from '@Base/Headings';
import { useLanguagePack, useOutsideClick, useMounted } from '@Base/hooks';
import { EmailForm } from '../../Communication';
import PersonalityTestCompletedContainer from './PersonalityTestCompletedContainer';
import PersonalityTestRequestedContainer from './PersonalityTestRequestedContainer';
import ActionToBePerformedNoDataState from '../Other/ActionToBePerformedNoDataState';
import personalityTest from '../../../assets/img/nodata/personality-test.png';
import idchecknodata from '../../../assets/img/nodata/idchecknodata2.png';

function PersonalityTestDetails({ summary, updateSummary, candidate, isTouch, onSuccess, onError }) {
  const languagePack = useLanguagePack('personality-test');
  const [ptSummary, setPtSummary] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [requestingDetails] = useState(false);
  const [isInfoSectionExpanded, setIsInfoSectionExpanded] = useState(false);
  const [reminderSending, setReminderSending] = useState(false);
  const [isActionOpen, setIsActionOpen] = useState(false);
  const [isDownloadingPDF, setIsDownloadingPDF] = useState(false);

  const wrapperRef = useRef();

  let status = 'NOT_REQUESTED';
  if (summary && summary.statusHistory && summary.statusHistory.length > 0) {
    const orderedStatuses = summary.statusHistory.sort((a, b) => {
      return new Date(b.statusDateTime) - new Date(a.statusDateTime);
    });
    const latestStatus = orderedStatuses[0];
    status = latestStatus.status;
  }

  useOutsideClick(
    wrapperRef,
    () => {
      setIsActionOpen(false);
    },
    '.slide-pane.candidate-slider',
  );

  const isMounted = useMounted();

  async function downloadPDF() {
    setIsDownloadingPDF(true);
    await downloadFiles(ptSummary.reports.map((r) => r.reportFileId));
    setIsDownloadingPDF(false);
  }

  useEffect(() => {
    if (
      isMounted() &&
      ['REQUESTED', 'REMINDER_1_SENT', 'REMINDER_2_SENT', 'PART_RECEIVED', 'RECEIVED'].includes(status)
    ) {
      getPTData(
        candidate.id,
        (resp) => {
          setIsLoading(false);
          if (resp?.personalityTestUrl) {
            setPtSummary(resp);
          } else {
            onError(
              languagePack.personalityTestLoadError ||
                'Could not load Personality Test information please try again later',
            );
          }
        },
        () => {
          setIsLoading(false);
          onError(
            languagePack.personalityTestLoadError ||
              'Could not load Personality Test information please try again later',
          );
        },
      );
    } else {
      setIsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [candidate.id, isMounted, onError, status]);

  function handleError() {
    onError(languagePack.personalityTestReqError);
  }

  function handleSubmissionSuccess(message, rtwResponse) {
    setIsInfoSectionExpanded(!isInfoSectionExpanded);
    setPtSummary(rtwResponse?.personalityTest);
    updateSummary(rtwResponse?.personalityTest?.statusHistory);
    onSuccess(languagePack.personalityTestReqSuccess);
  }

  async function sendReminder() {
    setReminderSending(true);
    const result = await retryableAPICall(() => sendPersonalityTestReminder(candidate.id));
    if (typeof result !== 'string') {
      onSuccess(languagePack.personalityTestSuccessfullySentReminderMessage);
      updateSummary(result.statusHistory);
    } else {
      onError(languagePack.personalityTestErrorSendingReminderMessage);
    }
    setReminderSending(false);
  }

  const menuItems = [];

  if (['REQUESTED', 'REMINDER_1_SENT', 'REMINDER_2_SENT'].includes(status) && ptSummary?.personalityTestUrl) {
    menuItems.push({
      action: () => {
        navigator.clipboard.writeText(ptSummary.personalityTestUrl).then(
          () => {
            toast.success(`${languagePack.personalityTestURL} ${languagePack.copiedToClipboard}`);
          },
          () => {
            toast.error(languagePack.copyToClipboardError);
          },
        );
        setIsActionOpen(false);
      },
      icon: 'Paper Clip',
      id: 1,
      isDisabled: false,
      label: `Copy URL to clipboard`,
      permissions: ['pt:read', 'pth:read'],
      tooltipContent: 'You are unable to edit records that are over 12 months old',
    });
  }

  if (status === 'NOT_REQUESTED' || status === 'FAILED') {
    menuItems.push({
      action: async () => {
        setIsInfoSectionExpanded(!isInfoSectionExpanded);
        setIsActionOpen(false);
      },
      icon: 'Paper Plane',
      id: 2,
      label: requestingDetails ? languagePack.personalityTestRequestingLabel : languagePack.personalityTestReqInfoBtn,
      permissions: ['pt:create', 'pth:create'],
    });
  } else if (['REQUESTED', 'REMINDER_1_SENT'].includes(status)) {
    menuItems.push({
      action: async () => {
        sendReminder();
        setIsActionOpen(false);
      },
      icon: 'Paper Plane',
      id: 2,
      label: reminderSending
        ? languagePack.personalityTestSendReminderSendingBtnLabel
        : status === 'REQUESTED'
          ? languagePack.personalityTestSendReminderBtnLabel
          : languagePack.personalityTestSendFinalReminderBtnLabel,
      permissions: ['pt:create', 'pth:create'],
    });
  }

  if (status === 'RECEIVED' && ptSummary?.reports?.length > 0 && ptSummary?.reports[0]?.reportFileId) {
    menuItems.push({
      action: async () => {
        downloadPDF();
        setIsActionOpen(false);
      },
      icon: 'File',
      id: 3,
      isDisabled: isDownloadingPDF,
      label: isDownloadingPDF ? languagePack.personalityTestDlInProgressBtn : languagePack.personalityTestDlBtn,
      permissions: ['pt:create', 'pth:create'],
    });
  }

  return (
    <Card>
      <CardBody>
        {isLoading ? (
          <LoadingScreen notFixed />
        ) : (
          <>
            {status !== 'NOT_REQUESTED' &&
              status !== 'REQUESTED' &&
              status !== 'REMINDER_1_SENT' &&
              status !== 'REMINDER_2_SENT' && (
                <HeadingPushRight
                  headingAs="h4"
                  headingClassName="d-flex align-items-center"
                  headingText={`${languagePack.personalityTestTitle || 'Personality Test'} - ${
                    languagePack[status] || 'Unknown'
                  }`}
                  isTouch={isTouch}
                >
                  {menuItems.length === 1 && checkPermissions(menuItems[0].permissions) && (
                    <IconButton
                      action={menuItems[0].action}
                      className="ms-2"
                      disabled={menuItems[0].isDisabled}
                      label={menuItems[0].label}
                      SVGIcon={<IconSVG name={menuItems[0].icon} />}
                    />
                  )}
                  {menuItems.length > 1 && (
                    <div ref={wrapperRef}>
                      <ActionButton
                        buttonLabel={languagePack.actionBtnLabel || 'Actions'}
                        className="ellipsis-opts-btn"
                        iconName="Ellipsis"
                        iconOnly={false}
                        isOpen={isActionOpen}
                        menuItems={menuItems}
                        onToggle={(isOpen) => {
                          setIsActionOpen(isOpen);
                        }}
                        title="Actions"
                        tooltipContent={languagePack.tooltipContent || 'Select an Action for the Personality Test'}
                      />
                    </div>
                  )}
                </HeadingPushRight>
              )}
            {!isInfoSectionExpanded && status === 'NOT_REQUESTED' && (
              <Row className="mb-4">
                <Col>
                  <ActionToBePerformedNoDataState
                    image={personalityTest}
                    title="Personality Test Not Requested"
                    button={
                      <Button
                        onClick={menuItems[0].action}
                        className="ms-2"
                        disabled={menuItems[0].isDisabled}
                        label={menuItems[0].label}
                      >
                        Request Personality Test
                      </Button>
                    }
                    description="The candidate has not been requested to complete a personality test yet"
                  />
                </Col>
              </Row>
            )}
            {isInfoSectionExpanded && (
              <Row>
                <Col>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <img
                      src={personalityTest}
                      alt="No Data"
                      style={{ maxWidth: '100%', marginBottom: '2px', opacity: 0.7, width: '80px' }}
                    />
                    <h2 style={{ marginTop: '0', marginLeft: '10px' }}>{languagePack.requestPersonalityTestTitle}</h2>
                  </div>
                  <EmailForm
                    actionType="PERSONALITY_TEST_REQUEST"
                    applicants={[
                      {
                        applicantEmail: candidate.applicantProfile.communication.emailAddress,
                        applicantId: candidate.id,
                        funnelId: candidate.funnelId,
                      },
                    ]}
                    onError={handleError}
                    onSuccess={(message, data) => {
                      if (data?.status === 'FAILED') {
                        toast.error(
                          languagePack[data?.reason] ||
                            'Could not request personality test at this time. Please try again later.',
                        );
                      } else {
                        handleSubmissionSuccess(message, data);
                      }
                    }}
                    sendBtnLabel={languagePack.personalityTestRequestBtnLabel}
                    wrapperClassName="mt-4"
                  />
                </Col>
              </Row>
            )}
            {['FAILED'].includes(status) && (
              <Row>
                <Col className="mt-3">
                  <p>{languagePack.personalityTestFailed}</p>
                </Col>
              </Row>
            )}
            {['REQUESTED', 'REMINDER_1_SENT', 'REMINDER_2_SENT'].includes(status) && (
              <PersonalityTestRequestedContainer
                status={status}
                languagePack={languagePack}
                testURL={ptSummary?.personalityTestUrl}
              />
            )}
            {['RECEIVED', 'PART_RECEIVED'].includes(status) && (
              <PersonalityTestCompletedContainer languagePack={languagePack} ptSummary={ptSummary} summary={summary} />
            )}
          </>
        )}
      </CardBody>
    </Card>
  );
}

PersonalityTestDetails.propTypes = {
  candidate: PropTypes.shape(),
  isTouch: PropTypes.bool,
  onError: PropTypes.func,
  onSuccess: PropTypes.func,
  summary: PropTypes.shape(),
  updateSummary: PropTypes.func,
};

PersonalityTestDetails.defaultProps = {
  candidate: {},
  isTouch: false,
  onError: () => {},
  onSuccess: () => {},
  summary: {},
  updateSummary: () => {},
};

export default PersonalityTestDetails;
