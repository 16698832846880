import React, { useEffect, useCallback, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import styled from 'styled-components';

import { retryableAPICall } from '@API/common-api-utils';
import { getDescription, deleteDescription } from '@API/SchedulingAPI/DescriptionsAPI';

import { useMounted, usePageLoading } from '@Base/hooks';
import { useTableState, DataTable } from '@Base/Tables';
import { DeleteButton, CreateButton } from '@Base/Buttons';
import { Confirmation } from '@Base/Modal';
import Can from '@Base/RBAC/Can/Can';

import { deleteObjFromArray, updateObjInArray, addObjToArray } from '@JS/utils/arrayOfObjects';
import { checkPermissions } from '@JS/auth/AuthUtils';

import {
  SearchInput,
  EnhancedCard,
  EnhancedCardTitle,
  ClientAdminNoDataMessage,
  PageColumn,
  ClientAdminSlider,
  utils,
} from '../../Common';
import { DescriptionEditor } from './components';

import { useDebounce } from '../../Common/utils';

const StyledTable = styled(DataTable)`
  .rt-thead {
    background-color: #f8f9fa !important;
    padding: 0.35rem 0;
  }
`;

const RestrictedCreateButton = Can(CreateButton);

async function deleteDesc(id, onSuccess = () => {}, onError = () => {}) {
  if (id) {
    const resp = await retryableAPICall(() => deleteDescription(id));

    if (typeof resp === 'string' && resp.length) {
      onError();
    } else {
      onSuccess();
    }
  }
}

function ScheduleDescriptions({ tablePrefs }) {
  const isMounted = useMounted();
  const { pageState, setPageResolved, setPageRejected } = usePageLoading();
  const { tableState, setTableData, setTableRejected } = useTableState({ rowsPerPage: tablePrefs.pageSize });
  const [showConfirm, setShowConfirm] = useState(false);
  const [deleteDescId, setDeleteDescId] = useState();
  const [descriptionObj, setDescriptionObj] = useState({});
  const [isEditing, setIsEditing] = useState(false);
  const [isSliderOpen, setIsSliderOpen] = useState(false);
  const [defaultDesc, setDefaultDesc] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const debouncedSearchTerm = useDebounce(searchTerm, 300);
  const [originalData, setOriginalData] = useState([]);

  const requestData = useCallback(
    async (callback = () => {}) => {
      const resp = await retryableAPICall(() => getDescription());

      if (isMounted()) {
        if (typeof resp === 'string') {
          setTableRejected();
          callback(resp);
        } else {
          const sortedDescriptions = resp.sort((a, b) => a.name?.localeCompare(b.name));
          setOriginalData(sortedDescriptions);
          setTableData({
            data: sortedDescriptions,
            totalResults: resp.length,
          });

          if (resp.length) {
            let defDesc = resp.filter(({ default: def }) => def);
            if (!defDesc.length) defDesc = [resp[0].id];
            setDefaultDesc(defDesc);
          }

          callback();
        }
      }
    },
    [isMounted, setTableData, setTableRejected],
  );

  const filteredData = useMemo(() => {
    if (!debouncedSearchTerm) return originalData;

    return originalData.filter((desc) => {
      const searchLower = debouncedSearchTerm.toLowerCase();
      const nameMatch = desc.name?.toLowerCase().includes(searchLower);
      const descriptionText = desc.description?.replace(/<[^>]*>/g, '');
      const descriptionMatch = descriptionText?.toLowerCase().includes(searchLower);

      return nameMatch || descriptionMatch;
    });
  }, [debouncedSearchTerm, originalData]);

  useEffect(() => {
    setTableData({
      data: filteredData,
      totalResults: filteredData.length,
    });
  }, [filteredData, setTableData]);

  useEffect(() => {
    requestData((errorStatus) => {
      if (errorStatus) {
        setPageRejected(errorStatus);
      } else {
        setPageResolved();
      }
    });
  }, [requestData, setPageRejected, setPageResolved]);

  const columns = [
    {
      Header: 'Name',
      id: 'descName',
      Cell: ({ row: { original } }) => {
        const { name } = original;

        if (checkPermissions(['admin:schedule:update'])) {
          return (
            <a
              href="#descName"
              onClick={(e) => {
                e.preventDefault();
                setDescriptionObj(original);
                setIsEditing(true);
                setIsSliderOpen(true);
              }}
            >
              {name}
            </a>
          );
        }
        return name;
      },
    },
    {
      Header: 'Description',
      id: 'descDetails',
      Cell: ({ row: { original } }) => {
        const { description } = original;
        return <div className="truncate-overflow" dangerouslySetInnerHTML={{ __html: description }} />;
      },
    },
  ];

  if (checkPermissions(['admin:schedule:delete'])) {
    columns.push({
      width: 63,
      id: 'action',
      className: 'action-cell',
      Cell: ({ row: { original } }) => {
        const { id } = original;
        return (
          <DeleteButton
            label="Delete Description"
            action={() => {
              setDeleteDescId(id);
              setShowConfirm(true);
            }}
            className="btn-sm xs"
            iconOnly
            floatRight={false}
          />
        );
      },
    });
  }

  return (
    <PageColumn state={pageState}>
      <EnhancedCard className="mt-3" style={{ maxWidth: '1600px', width: '100%' }}>
        <EnhancedCardTitle title="Descriptions" subtitle="Manage your scheduling descriptions">
          <RestrictedCreateButton
            permissions={['admin:schedule:create']}
            action={() => {
              setIsEditing(false);
              setDescriptionObj({});
              setIsSliderOpen(true);
            }}
            label="Create a new Description"
            className="mb-auto"
          />
        </EnhancedCardTitle>

        <div className="d-flex align-items-center gap-3">
          <SearchInput
            value={searchTerm}
            className="w-100"
            type="text"
            placeholder="Search descriptions by name or content"
            onChange={(e) => setSearchTerm(e)}
          />
        </div>

        {!tableState.data.length ? (
          <ClientAdminNoDataMessage
            title={searchTerm ? 'No descriptions match your search' : 'You currently have no descriptions'}
            message={searchTerm ? 'Try adjusting your search terms' : ''}
            btnProps={
              !searchTerm
                ? {
                    onClick: () => {
                      setIsEditing(false);
                      setDescriptionObj({});
                      setIsSliderOpen(true);
                    },
                    label: 'Create Description',
                  }
                : {}
            }
          />
        ) : (
          <StyledTable
            id="sched-desc"
            className="mt-3"
            isLoading={tableState.isLoading}
            isRejected={tableState.isRejected}
            data={tableState.data}
            columns={columns}
            totalResults={tableState.totalResults}
            pageSize={tableState.rowsPerPage}
            noDataText="You currently have no descriptions"
            errorText="There has been an error loading descriptions, please try again later"
            hasSelectColumn={checkPermissions(['admin:schedule:update'])}
            isSelectRadio
            selectHeaderLabel="Default"
            selected={defaultDesc}
            onSelect={() => {}}
            showPagination={false}
          />
        )}
      </EnhancedCard>

      <ClientAdminSlider
        title={`${isEditing ? 'Edit' : 'Create'} Description`}
        isSliderOpen={isSliderOpen}
        closeSlider={() => setIsSliderOpen(false)}
      >
        <DescriptionEditor
          isEditing={isEditing}
          data={descriptionObj}
          onSave={(descObj) => {
            setIsSliderOpen(false);

            const updatedArr = isEditing
              ? updateObjInArray(tableState.data, descObj, descObj.id)
              : addObjToArray(tableState.data, descObj);

            if (updatedArr.length) {
              setTableData({
                data: updatedArr,
                totalResults: updatedArr.length,
              });
              setOriginalData(updatedArr);
            }
          }}
          onCancel={() => setIsSliderOpen(false)}
        />
      </ClientAdminSlider>

      <Confirmation
        content="Are you sure you want to delete this description?"
        show={showConfirm}
        cancelCallback={() => setShowConfirm(false)}
        confirmCallback={() => {
          deleteDesc(
            deleteDescId,
            () => {
              toast.success('Description successfully deleted');
              const updatedArr = deleteObjFromArray(tableState.data, deleteDescId);
              setTableData({
                data: updatedArr,
                totalResults: updatedArr.length,
              });
              setOriginalData(updatedArr);
            },
            () => {
              toast.error('Error deleting description');
            },
          );
          setShowConfirm(false);
        }}
      />
    </PageColumn>
  );
}

ScheduleDescriptions.propTypes = {
  tablePrefs: PropTypes.shape({
    pageSize: PropTypes.number,
  }),
};

ScheduleDescriptions.defaultProps = {
  tablePrefs: {
    pageSize: 25,
  },
};

function mapStateToProps(state) {
  const { tablePrefs } = state;
  return { tablePrefs };
}

export default connect(mapStateToProps)(ScheduleDescriptions);
