/* eslint-disable jsx-a11y/label-has-for */
import React, { Fragment, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Row, Col, FormGroup, Label, Input } from 'reactstrap';
import { toast } from 'react-toastify';
import { STATUS_OPTIONS } from '../../../Applications/Interviews/InterviewStatusSelect';
import EditingContext from './EditingContext';
import { Confirmation } from '@Base/Modal';
import { retryableAPICall } from '@API/common-api-utils';
import { updateInviteStatus } from '@API/SchedulingAPI/InterviewAPI';
import { addBookingCandidates, removeBookingCandidates } from '@JS/actions/bookingActions';

async function moveCandidates(status = '', inviteIds = [], onSuccess = () => {}, onError = () => {}) {
  if (status.length && inviteIds.length) {
    const resp = await retryableAPICall(() => updateInviteStatus(status, inviteIds));

    if (typeof resp === 'string') {
      onError();
    } else {
      onSuccess();
    }
  }
}

function Checkbox({ children, checked, onChange }) {
  return (
    <FormGroup check inline>
      <Label check>
        <Input
          type="checkbox"
          className="standard-checkbox small"
          checked={checked}
          onChange={(e) => onChange(e.target.checked)}
        />
        <span>{children}</span>
      </Label>
    </FormGroup>
  );
}

Checkbox.propTypes = {
  children: PropTypes.string.isRequired,
  checked: PropTypes.bool,
  onChange: PropTypes.func,
};

Checkbox.defaultProps = {
  checked: false,
  onChange: () => {},
};

function CandidateStatusList({
  candidates,
  status,
  onStatusChange,
  applyCandidateIds,
  removeCandidateIds,
  slotBookings,
}) {
  const [selectedCandidates, setSelectedCandidates] = useState([]);
  const [moveToStatus, setMoveToStatus] = useState();
  const [showConfirm, setShowConfirm] = useState(false);
  const [selectStatus, setSelectStatus] = useState();

  useEffect(() => {
    // allows management of select after change has occured
    setSelectStatus(status);
  }, [status]);

  function handleCheckAll(checked, slotId) {
    const allowSelect = (slotBookings[slotId] || []).length < 1000;
    const allIds = candidates.map((cand) => cand.candidateId);
    const candidateIds = checked && allowSelect ? allIds : [];
    setSelectedCandidates(candidateIds);

    if (checked && allowSelect) {
      applyCandidateIds(slotId, candidateIds);
    } else {
      removeCandidateIds(slotId, allIds);
    }
  }

  function handleCandidateCheck(candidateId, checked, slotId) {
    const allowSelect = (slotBookings[slotId] || []).length < 1000;
    const clone = [...selectedCandidates];

    if (checked && allowSelect) {
      clone.push(candidateId);
    } else {
      const idx = clone.indexOf(candidateId);
      clone.splice(idx, 1);
    }

    setSelectedCandidates(clone);

    if (checked && allowSelect) {
      applyCandidateIds(slotId, clone);
    } else {
      removeCandidateIds(slotId, [candidateId]);
    }
  }

  return (
    <div>
      <EditingContext.Consumer>
        {({ isEditable, slotId }) => (
          <Fragment>
            {isEditable && (
              <Row>
                <Col className="pt-2">
                  <Checkbox
                    checked={!!candidates.length && selectedCandidates.length === candidates.length}
                    onChange={(checked) => handleCheckAll(checked, slotId)}
                  >
                    Select all
                  </Checkbox>
                </Col>
                <Col className="d-flex justify-content-end">
                  <FormGroup className="mb-0">
                    <Label for="statusSelect" className="me-2 mb-0">
                      Move to:
                    </Label>
                    <select
                      id="statusSelect"
                      className="select"
                      value={selectStatus}
                      onChange={(e) => {
                        if (selectedCandidates.length) {
                          setSelectStatus(e.target.value);
                          setMoveToStatus(e.target.value);
                          setShowConfirm(true);
                        } else {
                          toast.warn('No candidates selected');
                        }
                      }}
                    >
                      <option>Select...</option>
                      {STATUS_OPTIONS.map(({ value, label }) => {
                        if (value === status) return null;
                        return (
                          <option key={value} value={value}>
                            {label}
                          </option>
                        );
                      })}
                    </select>
                  </FormGroup>
                </Col>
              </Row>
            )}
            <div className="candidate-list">
              {candidates.map((candidate) => {
                const { firstName, surname, candidateId } = candidate;
                const nameStr = `${firstName} ${surname}`;

                let displayText = nameStr;
                if (candidate?.vacancyDetails?.vacancyReference) {
                  displayText = `${nameStr} - ${candidate?.vacancyDetails?.vacancyReference}`;
                }

                return (
                  <div key={candidateId} className="candidate-select">
                    {isEditable ? (
                      <Checkbox
                        checked={selectedCandidates.includes(candidateId)}
                        onChange={(checked) => handleCandidateCheck(candidateId, checked, slotId)}
                      >
                        {displayText}
                      </Checkbox>
                    ) : (
                      nameStr
                    )}
                  </div>
                );
              })}
            </div>
            <Confirmation
              content="Are you sure you want to move the selected candidates?"
              show={showConfirm}
              cancelCallback={() => setShowConfirm(false)}
              confirmCallback={() => {
                const inviteIds = candidates.reduce((acc, { candidateId, inviteId }) => {
                  if (selectedCandidates.includes(candidateId)) acc.push(inviteId);
                  return acc;
                }, []);

                moveCandidates(
                  moveToStatus,
                  inviteIds,
                  () => {
                    toast.success('Candidates moved successfully');
                    // reset select
                    setSelectStatus(status);
                    onStatusChange(moveToStatus, selectedCandidates);
                  },
                  () => {
                    toast.error('Error moving candidates');
                  },
                );

                setShowConfirm(false);
              }}
            />
          </Fragment>
        )}
      </EditingContext.Consumer>
    </div>
  );
}

CandidateStatusList.propTypes = {
  candidates: PropTypes.arrayOf(PropTypes.shape()),
  status: PropTypes.string,
  onStatusChange: PropTypes.func,
  applyCandidateIds: PropTypes.func,
  removeCandidateIds: PropTypes.func,
  slotBookings: PropTypes.objectOf(PropTypes.arrayOf(PropTypes.string)),
};

CandidateStatusList.defaultProps = {
  candidates: [],
  status: null,
  onStatusChange: () => {},
  applyCandidateIds: () => {},
  removeCandidateIds: () => {},
  slotBookings: {},
};

function mapStateToProps(state) {
  const {
    bookings: { slotBookings },
  } = state;
  return { slotBookings };
}

function mapDispatchToProps(dispatch) {
  return {
    applyCandidateIds: (id, candidateIds) => {
      dispatch(addBookingCandidates(id, candidateIds));
    },
    removeCandidateIds: (id, candidateIds) => {
      dispatch(removeBookingCandidates(id, candidateIds));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CandidateStatusList);
