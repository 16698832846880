import PropTypes from 'prop-types';
import React from 'react';
import { retryableAPICall } from '@API/common-api-utils';
import Integration from './Integration';
import { submitToUbeya, getSubmission } from '@API/Integrations/UbeyaAPI';
import { checkPermissions } from '@JS/auth/AuthUtils';

function UbeyaIntegration({ applicantId, candidateProfileSummary }) {
  const formSubmit = async () => {
    try {
      const result = await retryableAPICall(() => submitToUbeya(applicantId));

      const { data, message, httpStatus } = result;

      if (httpStatus == 'NOT_FOUND') {
        return {
          status: 'NOT_FOUND_ERROR',
          message:
            'The candidate can not be found on Ubeya - please check with your line manager if the candidate is on SAP and the record has been transferred to Ubeya from SAP.',
          data: {},
        };
      } else if (httpStatus == 'FORBIDDEN') {
        return {
          status: 'NOT_FOUND_ERROR',
          message: message,
          data: {},
        };
      }
      return {
        status: 'SUCCESS',
        message: `The Candidate has been successfully updated in Ubeya. Ubeya id = ${data}`,
        data: {},
      };
    } catch (error) {
      console.error('Error during form submission:', error);
      return { status: 'ERROR', message: 'An unexpected error occurred during data fetching.' };
    }
  };

  async function getData() {
    try {
      const resp = await retryableAPICall(() => getSubmission(applicantId));

      if (resp === 'NOT_FOUND_ERROR') {
        return 'NOT_FOUND_ERROR';
      }
      const { data, message, httpStatus } = resp;

      if (httpStatus == 'NOT_FOUND') {
        return {
          status: 'FAILED',
          message:
            'The candidate can not be found on Ubeya - please check with your line manager if the candidate is on SAP and the record has been transferred to Ubeya from SAP.',
          data: {},
        };
      } else if (httpStatus == 'FORBIDDEN') {
        return {
          status: 'NOT_FOUND_ERROR',
          message: message,
          data: {},
        };
      }

      return {
        status: 'SUCCESS',
        message: `The Candidate has been successfully updated in Ubeya. Ubeya id = ${data}`,
      };
    } catch (error) {
      console.error('Error fetching data:', error);
      return {
        status: 'ERROR',
        message: 'An unexpected error occurred during data fetching.',
      };
    }
  }

  const hideSumitButton = checkPermissions(['sap:write']);

  return (
    <Integration
      title="Ubeya"
      applicantId={applicantId}
      integrationName="Ubeya"
      formSubmit={formSubmit}
      getSubmission={getData}
      componentName="UbeyaIntegration"
      hideClearButton
      isSumbitHidden={!hideSumitButton}
      candidateProfileSummary={candidateProfileSummary}
    />
  );
}

UbeyaIntegration.propTypes = {
  applicantId: PropTypes.string,
};

UbeyaIntegration.defaultProps = {
  applicantId: null,
};

export default UbeyaIntegration;
