import React, { forwardRef } from 'react';
import cn from 'classnames';

const Input = forwardRef(({ className, ...props }, ref) => {
  return (
    <input
      ref={ref}
      className={cn(
        'tw-flex tw-h-10 tw-w-full tw-rounded-md tw-border tw-border-input tw-bg-background tw-px-3 tw-py-2 tw-text-base tw-ring-offset-background focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-primary focus:tw-ring-offset-2 tw-transition-colors tw-duration-200 tw-ease-in-out placeholder:tw-text-muted-foreground disabled:tw-cursor-not-allowed disabled:tw-opacity-50 md:tw-text-sm',
        className,
      )}
      {...props}
    />
  );
});

Input.displayName = 'Input';

export { Input };

export default Input;
