import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { doLogout } from '@JS/auth/AuthUtils';

function InitialsDropdown({ firstName, lastName, isTouch }) {
  const userInitials = firstName && lastName ? (firstName.slice(0, 1) + lastName.slice(0, 1)).toUpperCase() : '?';

  return (
    <UncontrolledDropdown>
      <DropdownToggle nav className="ci-link">
        <div className="circle-initials">{userInitials}</div>
      </DropdownToggle>
      <DropdownMenu
        end
        modifiers={[
          {
            name: 'flip',
            options: {
              fallbackPlacements: ['top', 'right'],
            },
          },
          {
            name: 'offset',
            options: {
              offset: isTouch ? [0, 0] : [0, -8],
            },
          },
        ]}
      >
        <DropdownItem onClick={() => doLogout()}>
          <i className="fa fa-lock" />
          Logout
        </DropdownItem>
      </DropdownMenu>
    </UncontrolledDropdown>
  );
}

InitialsDropdown.propTypes = {
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  isTouch: PropTypes.bool,
};

InitialsDropdown.defaultProps = {
  firstName: 'T',
  lastName: 'F',
  isTouch: false,
};

function mapStateToProps(state) {
  const {
    userData: {
      userDetails: { firstName, lastName },
    },
  } = state;
  return { firstName, lastName };
}

export default connect(mapStateToProps)(InitialsDropdown);
