/* eslint-disable no-underscore-dangle */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { getAddressObject, formatAddress } from './utils';
import PostcodeInput from './PostcodeInput';
import CountrySelect from './CountrySelect';

const ukInputs = [
  {
    id: 'buildingName',
    label: 'Building Name',
    required: false,
  },
  {
    id: 'addressLine1',
    label: 'Address Line 1',
    required: false,
    minCharacters: 4,
  },
  {
    id: 'addressLine2',
    label: 'Address Line 2',
    required: false,
  },
  {
    id: 'addressLine3',
    label: 'Address Line 3',
    required: false,
  },
  {
    id: 'city',
    label: 'City',
    required: false,
  },
  {
    id: 'county',
    label: 'County',
    required: false,
  },
  {
    id: 'postCode',
    label: 'Postcode',
    required: true,
  },
  {
    id: 'country',
    label: 'Country',
    required: true,
    type: 'select',
  },
  {
    id: 'lat',
    label: 'Latitude',
    required: true,
    type: 'number',
  },
  {
    id: 'long',
    label: 'Longitude',
    required: true,
    type: 'number',
  },
];

const genericInputs = [
  {
    id: 'buildingName',
    label: 'Building Name',
    required: false,
  },
  {
    id: 'addressLine1',
    label: 'Address Line 1',
    required: false,
  },
  {
    id: 'addressLine2',
    label: 'Address Line 2',
    required: false,
  },
  {
    id: 'addressLine3',
    label: 'Address Line 3',
    required: false,
  },
  {
    id: 'city',
    label: 'Town/City',
    required: false,
  },
  {
    id: 'county',
    label: 'State/Province',
    required: false,
  },
  {
    id: 'postCode',
    label: 'Postal Code/PLZ',
    required: true,
  },
  {
    id: 'country',
    label: 'Country',
    required: true,
    type: 'select',
  },
  {
    id: 'lat',
    label: 'Latitude',
    required: true,
    type: 'number',
  },
  {
    id: 'long',
    label: 'Longitude',
    required: true,
    type: 'number',
  },
];

function ManualAddressInputs({ idNameSpace, value, onChange, errors, formContext, isRequired, country }) {
  const [isExpanded, setIsExpanded] = useState(false);
  const inputs = country === 'GB' ? ukInputs : genericInputs;

  function handleChange(id, val) {
    const updatedAddr = { ...value, [id]: val };
    const formattedAddress = formatAddress(updatedAddr);
    const cleanAddr = getAddressObject({
      ...updatedAddr,
      formattedAddress,
    });
    onChange(cleanAddr);
  }

  function handleCountryChange(option) {
    handleChange('country', option);
  }

  function toggleExpanded() {
    setIsExpanded(!isExpanded);
  }

  const addressSummary = value
    ? [value.buildingName, value.addressLine1, value.city, value.county, value.postCode].filter(Boolean).join(', ')
    : 'No address details entered';

  const hasErrors = Object.values(errors || {}).some((err) => err && err.__errors && err.__errors.length > 0);

  return (
    <div className="tw-mt-4 tw-border tw-border-gray-300 tw-rounded-sm tw-shadow-sm manual-address-field">
      <div
        className={`tw-flex tw-justify-between tw-items-center tw-p-4 tw-cursor-pointer  tw-border-gray-200 ${hasErrors ? 'tw-bg-red-50' : 'tw-bg-gray-50'}`}
        onClick={toggleExpanded}
      >
        <div className="tw-flex tw-flex-col">
          <h3 className="tw-text-sm tw-font-medium tw-text-gray-700">Full Address Details</h3>
          <p className={`tw-text-xs ${hasErrors ? 'tw-text-red-500' : 'tw-text-gray-500'}`}>
            {hasErrors ? 'There are errors in the address details' : addressSummary}
          </p>
        </div>
        <div className="tw-flex tw-items-center">
          {hasErrors && <span className="tw-mr-2 tw-text-red-500 tw-text-sm">⚠️</span>}
          <svg
            className={`tw-h-5 tw-w-5 tw-text-gray-500 tw-transition-transform tw-duration-200 ${isExpanded ? 'tw-transform tw-rotate-180' : ''}`}
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
          </svg>
        </div>
      </div>

      {isExpanded && (
        <div className="tw-p-4 tw-flex tw-flex-col tw-gap-4">
          {inputs.map(({ id, label, required, minCharacters, type }) => {
            const req = isRequired && required;
            const isErr = errors[id] && errors[id].__errors && errors[id].__errors.length > 0;
            const idStr = `${idNameSpace}-${id}`;

            return (
              <div key={id} className="tw-w-full manual-address-field">
                {id === 'country' ? (
                  <CountrySelect isErr={isErr} id={idStr} onChange={handleCountryChange} value={value} />
                ) : id === 'postCode' && country === 'GB' ? (
                  <>
                    <label
                      className={`tw-block tw-mb-2 tw-text-sm tw-font-medium ${isErr ? 'tw-text-red-500' : 'tw-text-gray-700'}`}
                      htmlFor={idStr}
                    >
                      {label}
                      {req && <span className="tw-text-red-500">*</span>}
                    </label>
                    <PostcodeInput
                      formContext={formContext}
                      id={idStr}
                      value={value ? value[id] : ''}
                      onChange={(val) => handleChange(id, val)}
                      isErr={isErr}
                    />
                    {isErr && <p className="tw-mt-1 tw-text-xs tw-text-red-500">{errors[id].__errors[0]}</p>}
                  </>
                ) : type === 'number' ? (
                  <div className="tw-w-full">
                    <label
                      className={`tw-block tw-mb-2 tw-text-sm tw-font-medium ${isErr ? 'tw-text-red-500' : 'tw-text-gray-700'}`}
                      htmlFor={idStr}
                    >
                      {label}
                      {req && <span className="tw-text-red-500">*</span>}
                    </label>
                    <input
                      type="number"
                      id={idStr}
                      data-testid={idStr}
                      className={`tw-w-full tw-px-3 tw-py-2 tw-text-sm tw-border tw-rounded-md tw-shadow-sm 
                        ${isErr ? 'tw-border-red-500 tw-ring-red-500' : 'tw-border-gray-300'}
                        focus:tw-outline-none focus:tw-ring-1 focus:tw-border-blue-500 focus:tw-ring-blue-500`}
                      value={value ? value[id] : ''}
                      onChange={(e) => {
                        handleChange(id, e.target.value);
                      }}
                      required={req}
                      step="0.000001"
                    />
                    {isErr && <p className="tw-mt-1 tw-text-xs tw-text-red-500">{errors[id].__errors[0]}</p>}
                  </div>
                ) : (
                  <div className="tw-w-full">
                    <label
                      className={`tw-block tw-mb-2 tw-text-sm tw-font-medium ${isErr ? 'tw-text-red-500' : 'tw-text-gray-700'}`}
                      htmlFor={idStr}
                    >
                      {label}
                      {req && <span className="tw-text-red-500">*</span>}
                    </label>
                    <input
                      type="text"
                      id={idStr}
                      data-testid={idStr}
                      className={`tw-w-full tw-px-3 tw-py-2 tw-text-sm tw-border tw-rounded-md tw-shadow-sm 
                        ${isErr ? 'tw-border-red-500 tw-ring-red-500' : 'tw-border-gray-300'}
                        focus:tw-outline-none focus:tw-ring-1 focus:tw-border-blue-500 focus:tw-ring-blue-500`}
                      value={value ? value[id] : ''}
                      onChange={(e) => {
                        handleChange(id, e.target.value);
                      }}
                      minLength={minCharacters}
                      required={req}
                    />
                    {isErr && <p className="tw-mt-1 tw-text-xs tw-text-red-500">{errors[id].__errors[0]}</p>}
                  </div>
                )}
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
}

ManualAddressInputs.propTypes = {
  idNameSpace: PropTypes.string,
  country: PropTypes.string,
  value: PropTypes.shape(),
  onChange: PropTypes.func,
  errors: PropTypes.shape(),
  isRequired: PropTypes.bool,
  formContext: PropTypes.shape(),
};

ManualAddressInputs.defaultProps = {
  idNameSpace: 'manual-address',
  country: 'GB',
  value: {},
  formContext: {},
  onChange: () => {},
  errors: {},
  isRequired: false,
};

export default ManualAddressInputs;
