import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import Select from 'react-select';
import { updateActiveAccount } from '@JS/actions/userActions';
import { ModalPopup } from '../Modal';
import { useLanguagePack, useMounted } from '../hooks';

function getAccountName(arr, id) {
  const account = arr.find(({ accountId }) => accountId === id);
  if (account && account.accountName) return account.accountName;
  return '';
}

const accountAccessPropType = PropTypes.arrayOf(
  PropTypes.shape({
    accountId: PropTypes.string,
    accountName: PropTypes.string,
    default: PropTypes.bool,
  }),
);

function SelectBox({ accountAccess, selectedAccountId, onChange }) {
  const selectedAccount = accountAccess.reduce(
    (acc, { accountId, accountName, default: def }) =>
      accountId === selectedAccountId || def ? { value: accountId, label: accountName } : acc,
    {},
  );

  return (
    <Select
      className="react-select"
      onChange={({ value }) => onChange(value)}
      options={accountAccess.map(({ accountId, accountName }) => ({ value: accountId, label: accountName }))}
      placeholder="Choose Account"
      value={selectedAccount}
    />
  );
}

SelectBox.propTypes = {
  accountAccess: accountAccessPropType,
  selectedAccountId: PropTypes.string,
  onChange: PropTypes.func,
};

SelectBox.defaultProps = {
  accountAccess: [],
  selectedAccountId: '',
  onChange: () => {},
};

function AccountSelect({
  accountAccess,
  activeAccount,
  applyActiveAccount,
  isModal,
  modalProps,
  isPassive,
  ignoreActiveAccount,
  children,
}) {
  const isMounted = useMounted();
  const langPack = useLanguagePack('account-selector');
  const [accObj, setAccObj] = useState({});

  useEffect(() => {
    if (isMounted()) setAccObj(activeAccount);
  }, [activeAccount, isMounted]);

  function handleChange(accountId, cb = () => {}) {
    const accountName = getAccountName(accountAccess, accountId);
    setAccObj({ id: accountId, name: accountName });
    cb(accountName);
  }

  function handleApply(accountId, accountName) {
    applyActiveAccount(accountId, accountName);
    toast.success('Account Successfully Switched');
  }

  if (accountAccess.length < 2) return null;

  let accAccess = [...accountAccess].sort((a, b) => a.accountName.localeCompare(b.accountName));
  if (ignoreActiveAccount) {
    accAccess = accAccess.filter(({ accountId }) => accountId !== activeAccount.id);
  }

  if (isModal && Object.keys(modalProps).length) {
    return (
      <ModalPopup
        {...modalProps}
        onOkay={() => handleApply(accObj.id, accObj.name)}
        onToggle={(popupState) => {
          modalProps.onToggle(popupState);
          setAccObj({});
        }}
      >
        <p className="mb-3">{langPack.modalInputPara || 'Please select the account you would like to view'}</p>
        <SelectBox accountAccess={accAccess} onChange={handleChange} selectedAccountId={accObj.id} />
      </ModalPopup>
    );
  }

  if (isPassive) return children({ accounts: accAccess, activeAccount: accObj });

  return (
    <SelectBox
      accountAccess={accAccess}
      onChange={(accountId) => handleChange(accountId, (accountName) => handleApply(accountId, accountName))}
      selectedAccountId={accObj.id}
    />
  );
}

AccountSelect.propTypes = {
  accountAccess: accountAccessPropType,
  activeAccount: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
  }),
  applyActiveAccount: PropTypes.func,
  isModal: PropTypes.bool,
  modalProps: PropTypes.shape({
    onToggle: PropTypes.func,
  }),
  isPassive: PropTypes.bool,
  ignoreActiveAccount: PropTypes.bool,
  children: PropTypes.func,
};

AccountSelect.defaultProps = {
  accountAccess: [],
  activeAccount: {},
  applyActiveAccount: () => {},
  isModal: false,
  modalProps: {
    onToggle: () => {},
  },
  isPassive: false,
  ignoreActiveAccount: false,
  children: () => {},
};

function mapStateToProps(state) {
  const {
    userData: {
      userDetails: {
        data: { accountAccess = [] },
        activeAccountId,
        activeAccountName,
      },
    },
  } = state;

  return { accountAccess, activeAccount: { id: activeAccountId, name: activeAccountName } };
}

function mapDispatchToProps(dispatch) {
  return {
    applyActiveAccount: (accountId, accountName) =>
      dispatch(
        updateActiveAccount({
          value: accountId,
          label: accountName,
        }),
      ),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AccountSelect);
