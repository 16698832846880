import React, { useState, useCallback, useEffect } from 'react';
import { Input } from 'reactstrap';
import { Search, X } from 'lucide-react';

const SearchInput = ({ value, onChange, className, placeholder }) => {
  return (
    <div className="tw-relative tw-flex tw-items-center tw-w-full">
      <Search className="tw-absolute tw-left-3 tw-text-gray-400" size={20} />
      <Input
        type="text"
        value={value}
        onChange={(e) => onChange(e?.target?.value)}
        className={`!tw-pl-10 tw-pr-10 tw-h-10 tw-border tw-border-gray-300 tw-rounded-lg tw-focus:ring-2 tw-focus:ring-blue-100 tw-focus:border-blue-400 ${className}`}
        placeholder={placeholder}
      />
      {value && (
        <button
          onClick={() => onChange('')}
          className="tw-absolute tw-right-3 tw-text-gray-400 tw-hover:text-gray-600 tw-focus:outline-none"
          aria-label="Clear search"
        >
          <X size={18} />
        </button>
      )}
    </div>
  );
};

export default SearchInput;
