import React from 'react';
import Select from 'react-select';
import { getTimeZones } from '../../utils/dateTimeUtils';

const TimezoneDropdown = ({ onTimezoneChange, currentTimezone }) => {
  const timeZones = getTimeZones();

  return (
    <div>
      <label>Time Zone</label>
      <Select
        options={timeZones}
        placeholder="Select timezone"
        isSearchable
        className="react-select "
        value={timeZones.find((tz) => tz.value === currentTimezone)}
        onChange={(selected) => onTimezoneChange(selected.value)}
      />
    </div>
  );
};

export default TimezoneDropdown;
