import React, { useState, useEffect } from 'react';
import { ThumbsUp, ThumbsDown } from 'lucide-react';
import { ModalPopup } from '../Modal';
import { toast } from 'react-toastify';
import { submitFeedback } from '@API/AnalyticsAPI/AnalyticsAPI';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';

const FEEDBACK_COOKIE = 'user_feedback';
const ACTIONS = {
  UP: 'THUMBS_UP',
  DOWN: 'THUMBS_DOWN',
};

export default function FeedbackWidget({
  feature,
  uniqueId,
  position = 'inline',
  txtStyle,
  label,
  size = 'sm',
  feedbackType,
  style,
}) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [comment, setComment] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [previousFeedback, setPreviousFeedback] = useState(null);
  const [hoveredButton, setHoveredButton] = useState(null);
  const [currentAction, setCurrentAction] = useState(null);

  useEffect(() => {
    const allFeedback = JSON.parse(localStorage.getItem(FEEDBACK_COOKIE) || '{}');
    const feedbackKey = `${feedbackType}_${uniqueId}`;
    setPreviousFeedback(allFeedback[feedbackKey] || null);
  }, [feedbackType, uniqueId]);

  const iconSize = {
    xs: 14,
    sm: 16,
    md: 20,
    lg: 24,
  }[size];

  const containerStyle = {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    ...style,
  };

  const buttonStyle = (isActive, isHovered, isDownButton) => ({
    display: 'flex',
    alignItems: 'center',
    padding: '0px',
    border: 'none',
    background: 'transparent',
    cursor: isSubmitting || previousFeedback ? 'not-allowed' : 'pointer',
    borderRadius: '50%',
    marginTop: '3px',
    opacity: isSubmitting || previousFeedback ? 0.5 : 1,
    color: isActive || isHovered ? (isDownButton ? '#dc2626' : '#16a34a') : '#737373',
    transition: 'all 0.2s ease',
  });

  const textStyle = txtStyle
    ? txtStyle
    : {
        fontSize: '14px',
        color: '#737373',
        marginRight: '8px',
      };

  const saveFeedback = (feedbackData) => {
    const allFeedback = JSON.parse(localStorage.getItem(FEEDBACK_COOKIE) || '{}');
    const feedbackKey = `${feedbackType}_${uniqueId}`;
    allFeedback[feedbackKey] = {
      action: feedbackData.action,
      timestamp: new Date().toISOString(),
      ...(feedbackData.comment && { comment: feedbackData.comment }),
    };
    localStorage.setItem(FEEDBACK_COOKIE, JSON.stringify(allFeedback));
    setPreviousFeedback(allFeedback[feedbackKey]);
  };

  const handleFeedback = async (action) => {
    if (previousFeedback) return;
    setCurrentAction(action);
    setIsModalOpen(true);
  };

  const handleSubmit = async () => {
    if (isSubmitting) return;
    try {
      setIsSubmitting(true);
      const payload = {
        feature,
        action: currentAction,
        comment: comment || null,
        platform: 'ATS',
        uniqueId,
      };
      await submitFeedback(payload);
      saveFeedback({ action: currentAction, comment });
      toast.success('Thank you for your feedback!');
      setIsModalOpen(false);
      setComment('');
      setCurrentAction(null);
    } catch (error) {
      console.error('Failed to submit feedback:', error);
      toast.error(error.message || 'Failed to submit feedback. Please try again.');
    } finally {
      setIsSubmitting(false);
    }
  };

  const getModalContent = () => {
    const isPositive = currentAction === ACTIONS.UP;
    return {
      title: isPositive ? 'What did you like?' : 'Help us improve',
      placeholder: isPositive ? 'Tell us what worked well (optional)' : 'What could we improve?',
      okayLabel: 'Submit Feedback',
    };
  };

  return (
    <div style={containerStyle}>
      <Tippy content={previousFeedback ? "You've already provided feedback" : 'This was helpful'}>
        <button
          type="button"
          onClick={() => handleFeedback(ACTIONS.UP)}
          onMouseEnter={() => setHoveredButton(ACTIONS.UP)}
          onMouseLeave={() => setHoveredButton(null)}
          disabled={isSubmitting || previousFeedback}
          style={buttonStyle(previousFeedback?.action === ACTIONS.UP, hoveredButton === ACTIONS.UP, false)}
        >
          <ThumbsUp size={iconSize} />
        </button>
      </Tippy>

      <Tippy content={previousFeedback ? "You've already provided feedback" : 'This needs improvement'}>
        <button
          type="button"
          onClick={() => handleFeedback(ACTIONS.DOWN)}
          onMouseEnter={() => setHoveredButton(ACTIONS.DOWN)}
          onMouseLeave={() => setHoveredButton(null)}
          disabled={isSubmitting || previousFeedback}
          style={buttonStyle(previousFeedback?.action === ACTIONS.DOWN, hoveredButton === ACTIONS.DOWN, true)}
        >
          <ThumbsDown size={iconSize} />
        </button>
      </Tippy>
      {label && <span style={textStyle}>{label}</span>}

      {currentAction && (
        <ModalPopup
          isOpen={isModalOpen}
          onToggle={(isOpen) => {
            setIsModalOpen(isOpen);
            if (!isOpen) {
              setCurrentAction(null);
              setComment('');
            }
          }}
          title={getModalContent().title}
          onOkay={handleSubmit}
          onCancel={() => {
            setIsModalOpen(false);
            setCurrentAction(null);
            setComment('');
          }}
          okayLabel={getModalContent().okayLabel}
          size="md"
        >
          <textarea
            value={comment}
            onChange={(e) => setComment(e.target.value)}
            placeholder={getModalContent().placeholder}
            style={{
              minHeight: '100px',
              width: '100%',
              padding: '8px',
              resize: 'none',
              border: '1px solid #d1d5db',
              borderRadius: '4px',
              opacity: isSubmitting ? 0.5 : 1,
            }}
            disabled={isSubmitting}
          />
        </ModalPopup>
      )}
    </div>
  );
}
