import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { ReactFlowProvider } from '@xyflow/react';
import { toast } from 'react-toastify';

import { getWorkflowById } from '@API/AdvancedWorkflowAPI';

import { Loader } from '@Base/Loading';

import { convertWorkflowFromServer } from './DragAndDrop/utils';
import WorkflowWrapper from './DragAndDrop/WorkflowWrapper';
import { HoverProvider } from './HoverContext';
import HeaderPanel from './HeaderPanel';
import { PageColumn } from '../../Common';

const templates = {
  InviteToInterview: {
    name: 'Right to Work',
    nodes: [
      {
        id: '1',
        type: 'input',
        position: {
          x: 307.69642678170413,
          y: 45.35547886350864,
        },
        data: {
          label: 'Right To Work Status Change',
          actionType: '',
        },
        width: 150,
        height: 58,
        selected: false,
        positionAbsolute: {
          x: 307.69642678170413,
          y: 45.35547886350864,
        },
        dragging: false,
      },
      {
        id: '2',
        type: 'DecisionNode',
        position: {
          x: 308.51279797422865,
          y: 163.7293017795369,
        },
        data: {
          label: 'Right to work Status is Rejected?',
          actionType: '',
        },
        width: 223,
        height: 58,
        selected: false,
        positionAbsolute: {
          x: 308.51279797422865,
          y: 163.7293017795369,
        },
        dragging: false,
      },
      {
        id: '3',
        type: 'default',
        position: {
          x: 275.0415790807309,
          y: 311.4924876264413,
        },
        data: {
          label: 'Change funnel stage to Rejected',
          actionType: 'UPDATE_FUNNEL_STAGE',
        },
        width: 150,
        height: 40,
        selected: false,
        positionAbsolute: {
          x: 275.0415790807309,
          y: 311.4924876264413,
        },
        dragging: false,
      },
      {
        id: '4',
        type: 'output',
        position: {
          x: 389.5175865003003,
          y: 427.8127740502865,
        },
        data: {
          label: 'Exit Workflow',
          actionType: '',
        },
        width: 150,
        height: 40,
        selected: true,
        positionAbsolute: {
          x: 389.5175865003003,
          y: 427.8127740502865,
        },
        dragging: false,
      },
    ],
    edges: [
      {
        id: 'e1-2',
        source: '1',
        target: '2',
      },
      {
        id: 'e2-3',
        source: '2',
        target: '3',
        label: 'Yes',
        sourceHandle: 'a',
      },
      {
        id: 'e2-4',
        source: '2',
        target: '4',
        label: 'No',
        sourceHandle: 'b',
      },
      {
        id: 'e3-4',
        source: '3',
        target: '4',
      },
    ],
  },
  BLANK_WORKFLOW: {
    name: 'New Workflow',
    nodes: [],
    edges: [],
  },
  NEW_CANDIDATE_EMAIL: {
    name: 'NEW CANDIDATE EMAIL',
    nodes: [
      {
        type: 'startNode',
        data: {
          actionType: 'CREATE_CANDIDATE_EVENT',
        },
        measured: {
          width: 133,
          height: 50,
        },
        position: {
          x: 643,
          y: 117,
        },
        id: '0',
        selected: true,
        style: {
          border: 'none',
        },
      },
      {
        data: {
          label: 'Exit Workflow',
          actionType: 'EXIT',
        },
        type: 'endNode',
        id: '1',
        measured: {
          width: 127,
          height: 38,
        },
        position: {
          x: 646,
          y: 317,
        },
        selected: false,
        style: {
          border: 'none',
        },
      },
      {
        id: '2',
        type: 'actionNode',
        position: {
          x: 648.5,
          y: 202.5,
        },
        data: {
          label: '',
          actionType: 'SEND_EMAIL',
        },
        measured: {
          width: 117,
          height: 50,
        },
        selected: false,
        dragging: false,
        style: {
          border: 'none',
        },
      },
    ],
    edges: [
      {
        id: 'e0-2',
        source: '0',
        target: '2',
        type: 'DefaultEdge',
      },
      {
        id: 'e2-1',
        source: '2',
        target: '1',
        type: 'DefaultEdge',
      },
    ],
  },
};

function WorkflowEditPanel({ goBackToEdit, id, templateId, onSave, onDelete }) {
  const [isSaving, setIsSaving] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [loading, setLoading] = useState(true); // For loading spinner
  const [workflowObj, setWorkflowObj] = useState(null); // Store fetched or template data

  useEffect(() => {
    // Fetch workflow by ID
    const fetchWorkflow = async () => {
      try {
        if (id && id !== 'new') {
          try {
            const workflowData = await getWorkflowById(id); // Fetch workflow from API
            setWorkflowObj(convertWorkflowFromServer(workflowData));
          } catch (error) {
            toast.error('Failed to load workflow');
            goBackToEdit();
          }
        } else if (templateId) {
          const template = templates[templateId];
          if (template) {
            setWorkflowObj(template);
          } else {
            goBackToEdit();
            toast.error('Template not found');
          }
        } else {
          goBackToEdit();
          toast.error('No workflow ID or template ID provided');
        }
      } catch (e) {
        goBackToEdit();
        toast.error('Error with Workflow, please try again');
      } finally {
        setLoading(false);
      }
    };

    fetchWorkflow();
  }, [id, templateId]);

  if (loading) {
    return (
      <PageColumn className="d-flex justify-content-center align-items-center">
        <Loader className="my-5" />
      </PageColumn>
    );
  }

  return (
    <HoverProvider>
      <ReactFlowProvider>
        <div className="d-flex flex-column" style={{ flex: '1 0', paddingLeft: 0, paddingRight: 0 }}>
          <HeaderPanel
            goBackToEdit={goBackToEdit}
            initialWorkflow={workflowObj}
            setIsSaving={setIsSaving}
            onSave={(workflow, workflowID, isNew) => {
              setWorkflowObj(workflow);
              setIsSaving(false);
              onSave(workflowID, workflow, isNew);
            }}
            onDelete={onDelete}
            isSaving={isSaving}
            isDeleting={isDeleting}
            setIsDeleting={setIsDeleting}
            resetWorkflow={() => setWorkflowObj(workflowObj)} // Reset workflow
          />
          <WorkflowWrapper
            initialEdges={workflowObj.edges}
            initialNodes={workflowObj.nodes}
            isSaving={isSaving || isDeleting}
          />
        </div>
      </ReactFlowProvider>
    </HoverProvider>
  );
}

WorkflowEditPanel.propTypes = {
  goBackToEdit: PropTypes.func,
  id: PropTypes.string, // ID to fetch the workflow
  templateId: PropTypes.string, // Template ID to populate the workflow
};

WorkflowEditPanel.defaultProps = {
  goBackToEdit: () => {},
  id: null,
  templateId: null,
};

export default WorkflowEditPanel;
