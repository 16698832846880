import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import moment from 'moment';
import { Input } from 'reactstrap';
import { toast } from 'react-toastify';
import { connect } from 'react-redux';
import { FormGroup } from '@Base/Forms/Custom/CommonComponents';
import { ValueDisplay } from '@Base/ValueDisplay';
import { DatePickerInput } from '@Base/Forms/Custom/DatePicker';
import { setRRData } from '@JS/actions/rotaReadyActions';
import DynamicSelect from './DynamicSelect';

const optionPropType = PropTypes.arrayOf(
  PropTypes.shape({
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    label: PropTypes.string,
  }),
);

function IntegrationInput(props) {
  const {
    dataMapper,
    id,
    isDisabled,
    label,
    options,
    onChange,
    required,
    error,
    isFile,
    type,
    helpText,
    readOnly,
    isClearable,
    inputProps,
    uploader,
    url,
  } = props;
  let { value } = props;

  if (readOnly && type === 'select') {
    const selectedOpt = options.find(({ value: val }) => val === value);
    if (selectedOpt) {
      const { label: optLabel } = selectedOpt;
      if (optLabel) value = optLabel;
    }
  }

  return (
    <FormGroup
      error={error}
      helpText={readOnly ? '' : helpText}
      id={id}
      label={label}
      labelProps={{ sm: '3' }}
      required={required}
      row
    >
      {readOnly ? (
        <ValueDisplay isFile={isFile} value={value} />
      ) : (
        <>
          {type === 'select' ? (
            url !== undefined ? (
              <DynamicSelect
                dataMapper={dataMapper}
                id={id}
                isClearable={isClearable}
                isDisabled={isDisabled}
                onChange={onChange}
                url={url}
                value={value}
              />
            ) : (
              <Select
                classNamePrefix="react-select"
                id={id}
                isClearable={isClearable}
                isDisabled={isDisabled}
                onChange={(selected) => onChange(selected ? selected.value : '')}
                options={options}
                value={options.find(({ value: val }) => val === value) || null}
              />
            )
          ) : (
            <>
              {type === 'date' ? (
                <DatePickerInput
                  id={id}
                  onChange={(val) => {
                    if (val) {
                      onChange(val.format('yyyy-MM-DD'));
                    } else {
                      onChange(null);
                    }
                  }}
                  openDirection="up"
                  value={value.length ? moment(value) : null}
                  pastDates={inputProps?.isPastDatesAllowed}
                />
              ) : (
                <>
                  {type === 'number' ? (
                    <Input
                      id={id}
                      min="0"
                      onChange={(e) => onChange(Number(e.target.value))}
                      onFocus={(e) => e.target.select()}
                      type="number"
                      value={value}
                      {...inputProps}
                    />
                  ) : (
                    <>
                      {/^text|textarea|email$/.test(type) ? (
                        <Input
                          id={id}
                          onChange={(e) => onChange(e.target.value)}
                          type={type}
                          value={value}
                          {...inputProps}
                        />
                      ) : (
                        <>
                          {type === 'checkbox' ? (
                            <Input
                              checked={value}
                              className="standard-checkbox small position-relative ms-0 mt-2"
                              id={id}
                              onChange={(e) => onChange(e.target.checked)}
                              type="checkbox"
                            />
                          ) : (
                            <>
                              {type === 'fileUpload' ? (
                                <Input
                                  accept="application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/rtf, text/richtext, application/x-rtf, text/plain"
                                  className="m-auto h-100"
                                  id={id}
                                  onChange={async (event) => {
                                    if (event.target.files[0]) {
                                      await uploader(event.target.files[0], url).then((res) => {
                                        if (res) {
                                          onChange(res);
                                        } else {
                                          toast.error('File upload failed');
                                        }
                                      });
                                    }
                                  }}
                                  type="file"
                                />
                              ) : null}
                            </>
                          )}
                        </>
                      )}
                    </>
                  )}
                </>
              )}
            </>
          )}
        </>
      )}
    </FormGroup>
  );
}

IntegrationInput.propTypes = {
  dataMapper: PropTypes.func,
  id: PropTypes.string.isRequired,
  isDisabled: PropTypes.bool,
  label: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
  options: optionPropType,
  onChange: PropTypes.func,
  isFile: PropTypes.bool,
  required: PropTypes.bool,
  error: PropTypes.string,
  readOnly: PropTypes.bool,
  type: PropTypes.string,
  helpText: PropTypes.string,
  isClearable: PropTypes.bool,
  inputProps: PropTypes.shape(),
  uploader: PropTypes.func,
};

IntegrationInput.defaultProps = {
  dataMapper: null,
  isDisabled: false,
  value: null,
  options: [],
  onChange: () => {},
  required: false,
  isFile: false,
  error: null,
  readOnly: false,
  type: 'text',
  helpText: null,
  isClearable: true,
  inputProps: {},
  uploader: () => {},
};

function mapDispatchToProps(dispatch) {
  return {
    setRRDataz: (data) => {
      dispatch(setRRData(data));
    },
  };
}

export default connect(null, mapDispatchToProps)(IntegrationInput);
