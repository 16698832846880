import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import applyRules from 'react-jsonschema-form-conditionals';
import Engine from 'json-rules-engine-simplified';
import Form from 'react-jsonschema-form';
import { CardBody } from 'reactstrap';
import { toast } from 'react-toastify';

import { CancelButton, EditButton } from '@Base/Buttons';
import { useMounted, usePageLoading } from '@Base/hooks';
import LimitedTextArea from '@Base/Forms/Custom/RTE/RTE';
import InternationalAddressLookup from '@Base/Forms/Custom/InternationalAddressLookup/InternationalAddressLookup';
import DatePicker from '@Base/Forms/Custom/DatePicker/DatePicker';
import DDSelect from '@Base/Forms/Custom/DDSelect/DDSelect';
import RadioBtns from '@Base/Forms/Custom/RadioBtns/RadioBtns';
import { ImageUpload, FileUpload } from '@Base/Forms/Custom/FileUpload';
import { RowsWrapper } from '@Base/ValueDisplay';

import { retryableAPICall } from '@API/common-api-utils';
import { getCompanyProfileForAccount, updateCompanyProfile } from '@API/CompanyAPI/CompanyAPI';

import {
  companyProfileSchema,
  companyProfileUiSchema,
} from '@JS/model/form-schemas/company-profile/CompanyProfileSchema';
import { transformErrors, validateFields } from '@JS/utils/validation-helper';

import ProfileImage from './ProfileImage';
import { EnhancedCard, EnhancedCardTitle, PageColumn, ClientAdminSlider } from '../../Common';

function mapLatLongToGeolocation(address) {
  const { lat, long, ...rest } = address;
  return {
    name: '',
    address: {
      ...rest,
      geoLocation: {
        coordinates: [long, lat],
      },
    },
  };
}

function mapGeolocationToLatLong(address) {
  const {
    address: {
      geoLocation: { coordinates },
    },
  } = address;
  const [long, lat] = coordinates[0];

  return {
    address: {
      ...address.address,
      lat,
      long,
    },
  };
}

const FormWithConditionals = applyRules(companyProfileSchema, companyProfileUiSchema, [], Engine)(Form);

function CompanyProfile({ openSlider, onClose, setIsCreateMode }) {
  const isMounted = useMounted();
  const { pageState, setPageResolved, setPageRejected } = usePageLoading();
  const [isSliderOpen, setIsSliderOpen] = useState(false);
  const [companyObj, setCompanyObj] = useState({});
  const [isSaving, setIsSaving] = useState(false);

  async function loadCompanyProfile() {
    try {
      const resp = await retryableAPICall(() => getCompanyProfileForAccount());

      if (isMounted()) {
        if (typeof resp === 'string') {
          setPageRejected(resp);
        } else {
          const { profile = {}, documents = [], locations = [], id } = resp;
          const { logoFullUrl = '', bannerUrl = '', additionalFields = {} } = profile;

          setCompanyObj({
            ...profile,
            logo: logoFullUrl,
            banner: bannerUrl,
            documents,
            address: locations && locations.length ? mapGeolocationToLatLong(locations[0]) : {},
            phone: (additionalFields || {}).phoneNumber,
            companyId: id,
          });
          setPageResolved();
        }
      }
    } catch (error) {
      if (isMounted()) {
        setPageRejected(error);
      }
    }
  }

  useEffect(() => {
    loadCompanyProfile();
  }, [isMounted, setPageResolved, setPageRejected]);

  useEffect(() => {
    setIsSliderOpen(openSlider);
  }, [openSlider]);

  function handleCloseSlider() {
    setIsSliderOpen(false);
    if (openSlider) onClose();
  }

  async function handleSubmit({ formData }) {
    const { companyId } = companyObj;

    setIsSaving(true);
    setCompanyObj({ ...companyObj, ...formData });

    const documents = formData.documents ? [...formData.documents] : [];
    const locations = [mapLatLongToGeolocation(formData.address.address)];

    const updatedData = {
      ...formData,
      logoFullUrl: formData.logo,
      bannerUrl: formData.banner,
      additionalFields: {
        phoneNumber: formData.phone,
      },
    };

    delete updatedData.logo;
    delete updatedData.address;
    delete updatedData.documents;
    delete updatedData.phone;

    const resp = await retryableAPICall(() => updateCompanyProfile(companyId, updatedData, locations, documents));

    if (typeof resp === 'string') {
      toast.error('There was an error updating the profile. Please try later.');
    } else {
      toast.success('Successfully updated the profile');
    }

    setIsSaving(false);
    handleCloseSlider();
  }

  const saveBtnText = isSaving ? 'Saving...' : 'Save Profile';

  return (
    <Fragment>
      <PageColumn state={pageState}>
        <EnhancedCard>
          <EnhancedCardTitle title="Company Profile" subtitle="Information describing your branch/location">
            <EditButton action={() => setIsCreateMode(true)} floatRight={false} label="Edit" className="tw-mb-auto" />
          </EnhancedCardTitle>
          <CardBody>
            <RowsWrapper
              rows={[
                { title: 'Logo', value: companyObj.logo ? <ProfileImage src={companyObj.logo} alt="Logo" /> : null },
                {
                  title: 'Banner',
                  value: companyObj.banner ? (
                    <ProfileImage src={companyObj.banner} alt="Banner" className="tw-w-full" />
                  ) : null,
                },
                { title: 'Address', value: companyObj?.address?.address?.formattedAddress },
                { title: 'Company Sector', value: companyObj.industry },
                { title: 'Website URL', value: companyObj.website },
                { title: 'Phone number', value: companyObj.phone },
                { title: 'Founded Year', value: companyObj.foundedYear },
                { title: 'Size', value: companyObj.size },
                { title: 'Description / Profile', html: companyObj.description },
                {
                  title: 'Documents',
                  value: (companyObj.documents || []).map(({ originalFileName }) => originalFileName),
                  asList: true,
                },
              ]}
            />
          </CardBody>
        </EnhancedCard>
      </PageColumn>
      <ClientAdminSlider title="Edit Company Profile" isSliderOpen={isSliderOpen} closeSlider={handleCloseSlider}>
        <FormWithConditionals
          formData={companyObj}
          onSubmit={handleSubmit}
          noHtml5Validate
          showErrorList={false}
          validate={validateFields}
          transformErrors={transformErrors}
          fields={{
            rte: LimitedTextArea,
            addressLookup: InternationalAddressLookup,
            datePicker: DatePicker,
            select: DDSelect,
            radioBtns: RadioBtns,
            imageUpload: ImageUpload,
            fileUpload: FileUpload,
          }}
        >
          <EditButton isLoading={isSaving} disabled={isSaving} label={saveBtnText} type="submit" />
          <CancelButton disabled={isSaving} label="Cancel" type="submit" action={handleCloseSlider} />
        </FormWithConditionals>
      </ClientAdminSlider>
    </Fragment>
  );
}

CompanyProfile.propTypes = {
  openSlider: PropTypes.bool,
  onClose: PropTypes.func,
  setIsCreateMode: PropTypes.func,
};

CompanyProfile.defaultProps = {
  openSlider: false,
  onClose: () => {},
  setIsCreateMode: () => {},
};

export default CompanyProfile;
