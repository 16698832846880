import axios from 'axios';
import { getCommonHeaders } from '../common-api-utils';
import config from '../../config/config';

const { interviewerAI, api } = config;

export async function getInterviewAIConfig() {
  const result = await axios.get(`${api.middlewareAPIURL}/interviewerai${interviewerAI.configPath}`, {
    headers: getCommonHeaders(),
  });
  if (result.status === 201 || result.status === 200) return result.data;
  return null;
}

export async function getInterviewAIAvailableInterviews() {
  const result = await axios.get(`${api.middlewareAPIURL}/interviewerai${interviewerAI.availableInterviewsPath}`, {
    headers: getCommonHeaders(),
  });
  if (result.status === 201 || result.status === 200) return result.data;
  return null;
}

export async function getInterviewAIStatus(candidateId) {
  const result = await axios.get(`${api.middlewareAPIURL}/interviewerai/status/${candidateId}`, {
    headers: getCommonHeaders(),
  });
  if (result.status === 201 || result.status === 200) return result.data;
  return null;
}

export async function requestInterviewAI(candidateId, interviewId, subject, message, replyTo, fromName) {
  const response = await fetch(`${api.middlewareAPIURL}/interviewerai/request`, {
    method: 'POST',
    headers: getCommonHeaders(),
    body: JSON.stringify({
      candidateId,
      interviewId,
      emailTemplate: {
        subject,
        content: message,
        replyTo,
        fromName,
      },
    }),
  });

  if (!response.ok) {
    return 'Failed to request Interviewer AI interview';
  }
  return response.json();
}
