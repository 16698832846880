import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { ModalPopup } from '@Base/Modal';
import { retryableAPICall } from '@API/common-api-utils';
import { downloadLargeFile, getVideoVTT } from '@API/FileAPI/FileAPI';
import { Loader } from '@Base/Loading';
import { Video } from '@Base/Media';
import { IconSVG } from '@Base/SVG';
import VideoTranscriptionLink from './VideoTranscriptionLink';

async function fetchVideoFile(fileId, onSuccess = () => {}, onError = () => {}) {
  if (fileId) {
    try {
      const blob = await retryableAPICall(() => downloadLargeFile(fileId, { forceDownload: false }));

      // Check if the blob's type is "application/octet-stream" and change it to "video/mp4"
      let blobWithType = blob;
      if (blob.type === 'application/octet-stream') {
        blobWithType = new Blob([blob], { type: 'video/mp4' });
      }

      const blobUrl = URL.createObjectURL(blobWithType);
      onSuccess(blobUrl);
    } catch (error) {
      console.error('Error fetching video file:', error);
      onError();
    }
  }
}

function VideoModalLink({ candidateId, fileId, transcription }) {
  const [modalOpen, setModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [src, setSrc] = useState();
  const [vtt, setVtt] = useState();
  if (!fileId) return null;

  return (
    <Fragment>
      <a
        href="#review-video"
        className="d-inline-flex align-items-center"
        onClick={(e) => {
          e.preventDefault();

          if (!src) {
            setIsLoading(true);
            setModalOpen(true);
            fetchVideoFile(
              fileId,
              (file) => {
                setSrc(file);
                setIsLoading(false);
              },
              () => {
                setIsError(true);
                setIsLoading(false);
              },
            );
          } else {
            setModalOpen(true);
          }
        }}
      >
        <IconSVG name="Video" styles={{ marginRight: '0.5em' }} />
        Review Video
      </a>
      {/* <VideoTranscriptionLink candidateId={candidateId} fileId={fileId} transcription={transcription} /> */}
      <ModalPopup
        isOpen={modalOpen}
        onToggle={() => {
          setModalOpen(false);
          setIsError(false);
        }}
        hideCancelButton
        okayLabel="Close"
        minHeight="65px"
      >
        {isLoading ? (
          <Loader />
        ) : (
          <div className="d-flex justify-content-center align-items-center">
            {isError && !src ? (
              <p className="text-danger">Sorry the preview could not be loaded at this time</p>
            ) : (
              <Video src={src} maxWidth="600px" vtt={vtt} />
            )}
          </div>
        )}
      </ModalPopup>
    </Fragment>
  );
}

VideoModalLink.propTypes = {
  fileId: PropTypes.string,
  candidateId: PropTypes.string,
  transcription: PropTypes.string,
};

VideoModalLink.defaultProps = {
  fileId: null,
  candidateId: null,
  transcription: null,
};

export default VideoModalLink;
