/* eslint-disable jsx-a11y/label-has-for */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Form, Input } from 'reactstrap';
import { toast } from 'react-toastify';
import { connect } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';

import { createFunnel, updateFunnel } from '@API/CandidateAPI/FunnelManagement';
import { retryableAPICall } from '@API/common-api-utils';

import { trimFormData } from '@JS/utils/general-utils';
import { requestStatuses } from '@JS/constants/requestStatuses';
import validation, { mapErrors } from '@JS/utils/validation';
import { checkPermissions } from '@JS/auth/AuthUtils';

import { FormGroup } from '@Base/Forms/Custom/CommonComponents';
import { useFormKeypress, useLanguagePack } from '@Base/hooks';
import { CancelButton, CreateButton } from '@Base/Buttons';

import StageInput from './StageInput';

async function proxyCall(data, id) {
  if (id) return updateFunnel({ ...data, id });
  return createFunnel(data);
}

function validateStages(stages) {
  const stageErrors = stages.reduce(
    (acc, stage) => ({
      ...acc,
      ...(!stage.name.length ? { [stage.id]: 'Required field' } : {}),
    }),
    {},
  );

  return {
    messages: stageErrors,
    hasErrors: Object.keys(stageErrors).length,
  };
}

const defaultStages = [
  {
    name: 'Rejected',
    order: 0,
    type: 'REJECTED',
    id: uuidv4(),
  },
  {
    name: 'New',
    order: 1,
    type: 'DEFAULT',
    id: uuidv4(),
  },
  {
    name: 'Hired',
    order: 2,
    type: 'HIRED',
    id: uuidv4(),
  },
];

const defaultClientData = {
  name: '',
  stages: [...defaultStages],
};

function FunnelEditor({ isEditing, data, onSave, onCancel }) {
  const formRef = useFormKeypress();
  const [isSaving, setIsSaving] = useState(false);
  const [clientData, setClientData] = useState({ ...defaultClientData });
  const [errors, setErrors] = useState({});
  const languagePack = useLanguagePack('funnel-editor');
  const hasTalenthubPermission = checkPermissions(['talenthub:plus']);

  useEffect(() => {
    setClientData((prevState) => ({
      ...prevState,
      ...data,
      ...(data?.stages?.length
        ? {
            stages: data.stages.map(({ stageId, ...rest }) => ({ ...rest, id: stageId || rest.id })),
          }
        : {}),
    }));
  }, [data]);

  function handleChange(id, value) {
    const updated = { ...clientData, [id]: value };
    setClientData(updated);
  }

  async function handleSave() {
    setIsSaving(true);
    const trimmedData = trimFormData(clientData);
    setClientData(trimmedData);
    const baseErrObj = validation([{ id: 'name', required: true }], trimmedData);
    const { messages, hasErrors } = mapErrors(baseErrObj);
    const { messages: stageMessages, hasErrors: stageErrors } = validateStages(trimmedData.stages);
    setErrors({ ...messages, stages: { ...stageMessages } });

    if (!hasErrors && !stageErrors) {
      const { stages, ...rest } = trimmedData;

      const serverData = {
        ...rest,
        stages: stages.map(({ id, isNew, ...stageProps }) => {
          const updatedStage = {
            ...stageProps,
            // Remove ID if creating a fresh funnel / new stage
            // eslint-disable-next-line no-nested-ternary
            ...(isEditing ? (isNew ? { id: 'NEW' } : { id }) : {}),
          };

          if (!hasTalenthubPermission) {
            delete updatedStage.supplementalInfo;
          }

          return updatedStage;
        }),
      };
      delete serverData.type;

      // console.log(JSON.stringify(serverData, null, '\t'));

      const resp = await retryableAPICall(() => proxyCall(serverData, data.id));

      if (typeof resp === 'string') {
        let reasonStr = '';
        if (isEditing) {
          if (resp === requestStatuses.NOT_FOUND_ERROR) {
            reasonStr = '. Could not update funnel';
          } else if (resp === requestStatuses.PERMISSION_DENIED) {
            reasonStr = '. You do not have permission to edit this funnel';
          }
        }

        if (resp === requestStatuses.ALREADY_EXISTS_ERROR) {
          reasonStr = '. Unable to create funnel as this name has already been used';
        }

        toast.error(`Error ${isEditing ? 'updating' : 'creating'} funnel${reasonStr}`);
      } else {
        toast.success(`Funnel successfully ${isEditing ? 'updated' : 'created'}`);
        onSave(resp);
      }
    }

    setIsSaving(false);
  }

  const saveBtnText = isSaving ? 'Saving...' : `${isEditing ? 'Update' : 'Create'} Funnel`;

  return (
    <Form
      className="workflow-editor"
      innerRef={formRef}
      onSubmit={(e) => {
        e.preventDefault();
      }}
    >
      <FormGroup
        className="disabled-margin-bottom pb-2 pt-3"
        error={errors.name}
        id="name"
        label={languagePack.funnelNameTitle || ''}
        required
      >
        <Input
          id="name"
          onChange={(e) => handleChange('name', e.target.value)}
          type="text"
          value={clientData.name || ''}
        />
      </FormGroup>
      <FormGroup
        className="disabled-margin-bottom pb-2 mt-2"
        id="stages"
        label={languagePack.stagesTitle || ''}
        required
      >
        <StageInput
          errors={errors.stages}
          isEditing={isEditing}
          languagePack={languagePack}
          onChange={(val) => handleChange('stages', val)}
          stages={clientData.stages}
        />
      </FormGroup>
      <CreateButton action={handleSave} disabled={isSaving} isLoading={isSaving} label={saveBtnText} />
      <CancelButton action={onCancel} disabled={isSaving} label={languagePack.cancelBtnLabel} />
    </Form>
  );
}

FunnelEditor.propTypes = {
  data: PropTypes.shape(),
  isEditing: PropTypes.bool,
  onSave: PropTypes.func,
  onCancel: PropTypes.func,
  // activeAccountId: PropTypes.string,
  // activeAccountName: PropTypes.string,
  // totalAccounts: PropTypes.number,
};

FunnelEditor.defaultProps = {
  data: {},
  isEditing: false,
  onSave: () => {},
  onCancel: () => {},
  // activeAccountId: undefined,
  // activeAccountName: undefined,
  // totalAccounts: 0,
};

function mapStateToProps(state) {
  const {
    userData: {
      userDetails: {
        activeAccountId,
        activeAccountName,
        data: { accountAccess = [] },
      },
    },
  } = state;

  return { totalAccounts: accountAccess.length, activeAccountId, activeAccountName };
}

export default connect(mapStateToProps)(FunnelEditor);
