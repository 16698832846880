import React, { useEffect, useCallback, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import Tippy from '@tippyjs/react';
import styled from 'styled-components';

import { useMounted, usePageLoading } from '@Base/hooks';
import { useTableState, DataTable } from '@Base/Tables';
import { DeleteButton, CreateButton } from '@Base/Buttons';
import { Confirmation } from '@Base/Modal';
import { TippyTruncate } from '@Base/Truncate';
import Can from '@Base/RBAC/Can/Can';

import { retryableAPICall } from '@API/common-api-utils';
import { getEventType, deleteEventType } from '@API/SchedulingAPI/EventTypeAPI';

import { deleteObjFromArray, updateObjInArray, addObjToArray } from '@JS/utils/arrayOfObjects';
import { checkPermissions } from '@JS/auth/AuthUtils';

import { EventTypeEditor, EVENT_FORMATS } from './components';
import {
  SearchInput,
  EnhancedCard,
  EnhancedCardTitle,
  ClientAdminNoDataMessage,
  PageColumn,
  ClientAdminSlider,
  utils,
} from '../../Common';

import { useDebounce } from '../../Common/utils';

const StyledTable = styled(DataTable)`
  .rt-thead {
    background-color: #f8f9fa !important;
    padding: 0.35rem 0;
  }
`;

const RestrictedCreateButton = Can(CreateButton);

async function deleteType(id, onSuccess = () => {}, onError = () => {}) {
  if (id) {
    const resp = await retryableAPICall(() => deleteEventType(id));

    if (typeof resp === 'string' && resp.length) {
      onError();
    } else {
      onSuccess();
    }
  }
}

function ScheduleEventTypes({ tablePrefs, activeAccount, totalAccounts }) {
  const isMounted = useMounted();
  const { pageState, setPageResolved, setPageRejected } = usePageLoading();
  const { tableState, setTableData, setTableRejected } = useTableState({ rowsPerPage: tablePrefs.pageSize });
  const [showConfirm, setShowConfirm] = useState(false);
  const [deleteTypeId, setDeleteTypeId] = useState();
  const [typeObj, setTypeObj] = useState({});
  const [isEditing, setIsEditing] = useState(false);
  const [isSliderOpen, setIsSliderOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const debouncedSearchTerm = useDebounce(searchTerm, 300);
  const [originalData, setOriginalData] = useState([]);

  const requestData = useCallback(
    async (callback = () => {}) => {
      const resp = await retryableAPICall(() => getEventType());

      if (isMounted()) {
        if (typeof resp === 'string') {
          setTableRejected();
          callback(resp);
        } else {
          const sortedEvents = resp.sort((a, b) => a.name?.localeCompare(b.name));
          setOriginalData(sortedEvents);
          setTableData({
            data: sortedEvents,
            totalResults: resp.length,
          });

          callback();
        }
      }
    },
    [isMounted, setTableData, setTableRejected],
  );

  // Filter data based on search term
  const filteredData = useMemo(() => {
    if (!debouncedSearchTerm) return originalData;

    return originalData.filter((eventType) => {
      const searchLower = debouncedSearchTerm.toLowerCase();
      const nameMatch = eventType.name?.toLowerCase().includes(searchLower);
      const formatMatch = EVENT_FORMATS[eventType.format]?.toLowerCase().includes(searchLower);
      const descriptionText = eventType.description?.replace(/<[^>]*>/g, '');
      const descriptionMatch = descriptionText?.toLowerCase().includes(searchLower);

      return nameMatch || formatMatch || descriptionMatch;
    });
  }, [debouncedSearchTerm, originalData]);

  useEffect(() => {
    setTableData({
      data: filteredData,
      totalResults: filteredData.length,
    });
  }, [filteredData, setTableData]);

  useEffect(() => {
    requestData((errorStatus) => {
      if (errorStatus) {
        setPageRejected(errorStatus);
      } else {
        setPageResolved();
      }
    });
  }, [requestData, setPageRejected, setPageResolved]);

  const columns = [
    {
      Header: 'Event Type Name',
      id: 'typeName',
      Cell: ({ row: { original } }) => {
        const { name, accountId, readOnly } = original;

        if (checkPermissions(['admin:schedule:update']) && utils.isEditable(accountId, activeAccount.id, readOnly)) {
          return (
            <a
              href="#typeName"
              onClick={(e) => {
                e.preventDefault();
                setTypeObj(original);
                setIsEditing(true);
                setIsSliderOpen(true);
              }}
            >
              {name}
            </a>
          );
        }
        return name;
      },
    },
    {
      Header: 'Event Format',
      id: 'typeFormat',
      Cell: ({ row: { original } }) => {
        const { format } = original;
        return EVENT_FORMATS[format] || '';
      },
    },
    {
      Header: 'Event Description',
      id: 'typeDesc',
      Cell: ({ row: { original } }) => {
        const { description } = original;

        return (
          <Tippy content={description.replace(/(<([^>]+)>)/gi, '')} theme="ats" maxWidth="200px" delay={[1000, null]}>
            <div className="truncate-overflow" dangerouslySetInnerHTML={{ __html: description }} />
          </Tippy>
        );
      },
    },
  ];

  if (totalAccounts > 1) {
    columns.push(
      {
        Header: 'EDITABLE',
        id: 'readOnly',
        width: 100,
        headerClassName: 'text-center',
        className: 'text-center',
        accessor: (r) => (r.readOnly ? 'No' : 'Yes'),
      },
      {
        Header: 'SHARED WITH',
        id: 'share',
        width: 200,
        Cell: ({ row: { original } }) => {
          let { sharedWith = [] } = original;
          sharedWith = sharedWith.filter(({ accountId }) => accountId !== activeAccount.id);

          if (!sharedWith.length) return null;
          if (sharedWith.length === totalAccounts - 1) return 'All';

          const accountNames = sharedWith.map(({ accountName }) => accountName).join(', ');
          return <TippyTruncate>{accountNames}</TippyTruncate>;
        },
      },
    );
  }

  if (checkPermissions(['admin:schedule:delete'])) {
    columns.push({
      width: 63,
      id: 'action',
      className: 'action-cell',
      Cell: ({ row: { original } }) => {
        const { id, accountId, readOnly } = original;
        const editable = utils.isEditable(accountId, activeAccount.id, readOnly);
        const deletable = utils.isDeletable(accountId, activeAccount.id);

        return (
          <Tippy
            content="Only the owner can delete this"
            theme="ats"
            maxWidth="150px"
            disabled={editable && deletable}
            delay={[1000, null]}
          >
            <div>
              <DeleteButton
                label="Delete Event Type"
                action={() => {
                  setDeleteTypeId(id);
                  setShowConfirm(true);
                }}
                disabled={!editable || !deletable}
                className="btn-sm xs"
                iconOnly
                floatRight={false}
              />
            </div>
          </Tippy>
        );
      },
    });
  }

  return (
    <PageColumn state={pageState}>
      <EnhancedCard className="mt-3" style={{ maxWidth: '1600px', width: '100%' }}>
        <EnhancedCardTitle title="Event Types" subtitle="Manage your scheduling event types">
          <RestrictedCreateButton
            permissions={['admin:schedule:create']}
            action={() => {
              setIsEditing(false);
              setTypeObj({});
              setIsSliderOpen(true);
            }}
            label="Create a new Event Type"
            className="mb-auto"
          />
        </EnhancedCardTitle>

        <div className="d-flex align-items-center gap-3">
          <SearchInput
            value={searchTerm}
            className="w-100"
            type="text"
            placeholder="Search event types by name, format or description"
            onChange={(e) => setSearchTerm(e)}
          />
        </div>

        {!tableState.data.length ? (
          <ClientAdminNoDataMessage
            title={searchTerm ? 'No event types match your search' : 'You currently have no event types'}
            message={searchTerm ? 'Try adjusting your search terms' : ''}
            btnProps={
              !searchTerm
                ? {
                    onClick: () => {
                      setIsEditing(false);
                      setTypeObj({});
                      setIsSliderOpen(true);
                    },
                    label: 'Create Event Type',
                  }
                : {}
            }
          />
        ) : (
          <StyledTable
            id="sched-evt-types"
            className="mt-3"
            isLoading={tableState.isLoading}
            isRejected={tableState.isRejected}
            data={tableState.data}
            tblHeightOffset={300}
            rowHeight={80}
            columns={columns}
            totalResults={tableState.totalResults}
            pageSize={tableState.rowsPerPage}
            noDataText="You currently have no event types"
            errorText="There has been an error loading event types, please try again later"
            showPagination={false}
            hasSelectColumn={false}
          />
        )}
      </EnhancedCard>

      <ClientAdminSlider
        title={`${isEditing ? 'Edit' : 'Create'} Event Type`}
        isSliderOpen={isSliderOpen}
        closeSlider={() => setIsSliderOpen(false)}
      >
        <EventTypeEditor
          isEditing={isEditing}
          data={typeObj}
          totalAccounts={totalAccounts}
          activeAccount={activeAccount}
          onSave={(evTypeObj) => {
            setIsSliderOpen(false);

            const updatedArr = isEditing
              ? updateObjInArray(tableState.data, evTypeObj, evTypeObj.id)
              : addObjToArray(tableState.data, evTypeObj);

            if (updatedArr.length) {
              setTableData({
                data: updatedArr,
                totalResults: updatedArr.length,
              });
              setOriginalData(updatedArr);
            }
          }}
          onCancel={() => setIsSliderOpen(false)}
        />
      </ClientAdminSlider>

      <Confirmation
        content="Are you sure you want to delete this event type?"
        show={showConfirm}
        cancelCallback={() => setShowConfirm(false)}
        confirmCallback={() => {
          deleteType(
            deleteTypeId,
            () => {
              toast.success('Event Type successfully deleted');
              const updatedArr = deleteObjFromArray(tableState.data, deleteTypeId);
              setTableData({
                data: updatedArr,
                totalResults: updatedArr.length,
              });
              setOriginalData(updatedArr);
            },
            () => {
              toast.error('Error deleting event type');
            },
          );
          setShowConfirm(false);
        }}
      />
    </PageColumn>
  );
}

ScheduleEventTypes.propTypes = {
  tablePrefs: PropTypes.shape({
    pageSize: PropTypes.number,
  }),
  activeAccount: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
  }),
  totalAccounts: PropTypes.number,
};

ScheduleEventTypes.defaultProps = {
  tablePrefs: {
    pageSize: 25,
  },
  activeAccount: {},
  totalAccounts: 0,
};

function mapStateToProps(state) {
  const {
    tablePrefs,
    userData: {
      userDetails: {
        data: { accountAccess = [] },
        activeAccountId,
        activeAccountName,
      },
    },
  } = state;

  return {
    tablePrefs,
    activeAccount: { id: activeAccountId, name: activeAccountName },
    totalAccounts: accountAccess.length,
  };
}

export default connect(mapStateToProps)(ScheduleEventTypes);
