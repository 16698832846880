import { retryableAPICall } from '@API/common-api-utils';
import { getFlexiFormViewIdsByType, getEditFormToken } from '@API/FormsAPI/ATSFormsAPI';
import { getInviteNotes } from '@API/EventAPI/InviteNotesAPI';
import { updateInviteStatus } from '@API/SchedulingAPI/InterviewAPI';

export async function getInterviewForms(onSuccess = () => {}, onError = () => {}) {
  const resp = await retryableAPICall(() => getFlexiFormViewIdsByType('INTERVIEW'));
  if (typeof resp !== 'string') {
    onSuccess(Object.entries(resp).map(([key, value]) => ({ value: key, label: value })));
  } else {
    onError();
  }
}

export async function getInviteNotesData(inviteIds, fncs = {}) {
  const { onSuccess = () => {}, onError = () => {}, onComplete = () => {} } = fncs;

  const resp = await retryableAPICall(() => getInviteNotes(inviteIds));

  if (typeof resp !== 'string') {
    onSuccess(resp);
  } else {
    onError();
  }

  onComplete();
}

export async function generateToken(formId, onSuccess = () => {}, onError = () => {}) {
  if (formId) {
    const resp = await retryableAPICall(() => getEditFormToken(formId));

    if (typeof resp === 'string') {
      onError();
    } else {
      const { token } = resp;
      if (token) onSuccess(token);
    }
  }
}

export async function inviteStatusChange(status, inviteId, onSuccess = () => {}, onError = () => {}) {
  if (status && inviteId) {
    const resp = await retryableAPICall(() => updateInviteStatus(status, [inviteId]));

    if (typeof resp !== 'string') {
      onSuccess();
    } else {
      onError();
    }
  }
}
