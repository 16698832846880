import React from 'react';
import { GraduationCap } from 'lucide-react';
import ExpandableSection from './ExpandableSection';
import moment from 'moment/moment';

/**
 * Education Section Component
 * Displays an applicant's education history in an expandable section
 *
 * @param {Object} props Component props
 * @param {Object} props.applicant The applicant data object
 */
const EducationSummary = ({ applicant }) => {
  // Don't render if no education data
  if (!applicant?.applicantProfile?.education) {
    return null;
  }

  return (
    <ExpandableSection
      sectionId="education"
      title="Education"
      icon={<GraduationCap size={16} />}
      defaultExpanded={false}
      hideWhenEmpty={true}
    >
      <div className="tw-mt-2">
        <p className="tw-text-sm tw-mb-3">
          <span className="tw-font-medium">Highest Educational Achievement:</span> {applicant.highestEducation || applicant.applicantProfile?.highestEducation}
        </p>

        {/* Education History Entries */}
        <div className="tw-mt-3">
          {applicant.applicantProfile.education.map((edu, index) => {
            // Format the end date if available
            const endDateFormatted = edu.endDate ?
              `${moment(edu.endDate).format('YYYY')}` : // Just show the year
              'Present';

            const startDateFormatted = edu.startDate ?
              `${moment(edu.endDate).format('YYYY')}` : // Just show the year
              undefined;

            // Get qualification if available
            const qualification = edu.subjectName ?
              ` - ${edu.subjectName}` :
              '';

            return (
              <div
                key={index}
                className={`tw-py-2 ${
                  index !== applicant.applicantProfile.education.length - 1
                    ? 'tw-border-b tw-border-dashed tw-border-black/5'
                    : ''
                }`}
              >
                <div className="tw-flex tw-items-center tw-flex-wrap tw-gap-2">
                  {startDateFormatted &&
                   <span className="tw-bg-purple-50 tw-text-purple-700 tw-px-2 tw-py-0.5 tw-rounded-full tw-text-xs tw-font-medium tw-whitespace-nowrap">
                    {startDateFormatted}
                  </span>
                  }
                  <span className="tw-bg-purple-50 tw-text-purple-700 tw-px-2 tw-py-0.5 tw-rounded-full tw-text-xs tw-font-medium tw-whitespace-nowrap">
                    {startDateFormatted && " - "}{endDateFormatted}
                  </span>
                  <span className="tw-font-medium tw-text-gray-600 tw-text-sm">{edu.institution?.name}</span>
                  <span className="tw-text-gray-500 tw-text-sm">{qualification}</span>
                  {edu.current && (
                    <span className="tw-inline-block tw-bg-green-100 tw-text-green-700 tw-text-xs tw-px-2 tw-py-0.5 tw-rounded-full tw-font-medium tw-shadow-sm">
                      Current
                    </span>
                  )}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </ExpandableSection>
  );
};

export default EducationSummary;
