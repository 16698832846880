import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { DATE_FORMAT } from '../../utils/dateTimeUtils';

function RecurrenceHeading({ recurrence, startDate, endDate }) {
  if (!startDate || !endDate || !recurrence || recurrence === 'null') return null;

  const isWeekly = recurrence === 'WEEKLY';
  const endDateFormat = isWeekly ? 'Do MMMM YYYY' : DATE_FORMAT;

  const startDay = startDate.day();
  const endDay = endDate.day();
  const diff = endDay - startDay;
  const lookBack = diff >= 0 ? diff : 7 + diff;

  let endDayClone = moment(endDate);
  // last instance of day before end date
  if (isWeekly) endDayClone = endDayClone.subtract(lookBack, 'days');

  return (
    <h6>
      {'Repeats '}
      {isWeekly ? 'every ' : 'daily '}
      {isWeekly ? `${startDate.format('dddd')} ` : ''}
      {'until '}
      {endDayClone.format(endDateFormat)}
    </h6>
  );
}

RecurrenceHeading.propTypes = {
  recurrence: PropTypes.string,
  startDate: PropTypes.instanceOf(moment),
  endDate: PropTypes.instanceOf(moment),
};

RecurrenceHeading.defaultProps = {
  recurrence: null,
  startDate: null,
  endDate: null,
};

export default RecurrenceHeading;
