import React from 'react';
import PropTypes from 'prop-types';
import ColorPicker from '@Base/ColorPicker/ColorPicker';

function ColorInput({ id, value, onChange, className, swatchClassName }) {
  return (
    <ColorPicker className={className} color={value} id={id} onChange={onChange} swatchClassName={swatchClassName} />
  );
}

ColorInput.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  swatchClassName: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
};

ColorInput.defaultProps = {
  className: '',
  swatchClassName: '',
  id: 'colorInput',
  value: '#000000',
  onChange: () => {},
};

export default ColorInput;
