import React, { useState, useCallback, useEffect } from 'react';
import { Plus } from 'lucide-react';
import FilterButton from './FilterButton';

const FilterBar = ({
  filters = {},
  filterData = {},
  loadingFilters = [],
  onAddFilter,
  onFilterChange = () => {},
  initialFilters = [],
  requiredFilters = [],
}) => {
  const [activeFilters, setActiveFilters] = useState({});
  const [showDropdown, setShowDropdown] = useState(false);
  const [showFilterOptions, setShowFilterOptions] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    const initial = {};
    initialFilters.forEach((filter) => {
      if (filters[filter]) {
        initial[filter] = filters[filter].defaultValue;
      }
    });
    setActiveFilters(initial);
  }, []);

  const handleClickOutside = useCallback((e) => {
    if (!e.target.closest('.filter-dropdown')) {
      setShowDropdown(false);
      setShowFilterOptions(null);
    }
  }, []);

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [handleClickOutside]);

  const addFilter = async (filterName) => {
    await onAddFilter?.(filterName);
    setActiveFilters((prev) => ({
      ...prev,
      [filterName]: filters[filterName].defaultValue,
    }));
    setShowDropdown(false);
    setShowFilterOptions(filterName);
  };

  const removeFilter = (filter) => {
    if (requiredFilters.includes(filter)) return;
    const newFilters = { ...activeFilters };
    delete newFilters[filter];
    setActiveFilters(newFilters);
    onFilterChange(newFilters);
  };

  const updateFilterValue = (filter, value) => {
    const newFilters = {
      ...activeFilters,
      [filter]: value,
    };
    setActiveFilters(newFilters);
    onFilterChange(newFilters);
  };

  const remainingFilters = Object.keys(filters).filter((filter) => !activeFilters[filter]);

  const renderFilterComponent = (filterName) => {
    if (loadingFilters.includes(filterName)) {
      return (
        <div className="tw-p-4 tw-text-center">
          <div className="tw-animate-spin tw-h-5 tw-w-5 tw-border-2 tw-border-blue-500 tw-border-t-transparent tw-rounded-full tw-mx-auto" />
          <div className="tw-mt-2 tw-text-sm tw-text-gray-500">Loading...</div>
        </div>
      );
    }

    const FilterComponent = filters[filterName].component;

    return (
      <FilterComponent
        {...filters[filterName]}
        {...filterData[filterName]}
        value={activeFilters[filterName]}
        onChange={(value) => {
          updateFilterValue(filterName, value);
        }}
      />
    );
  };

  return (
    <div className="tw-w-full tw-bg-white">
      <div className="tw-p-4 tw-border-b">
        <div className="tw-flex tw-items-center tw-gap-2 tw-flex-wrap tw-ml-2">
          Filter by:
          {Object.entries(activeFilters).map(([filter, value]) => (
            <div key={filter} className="tw-relative filter-dropdown">
              <FilterButton
                filter={filter}
                value={value}
                label={filters[filter]?.label}
                options={filterData[filter]?.options || filters[filter].options || []}
                onClick={(e) => {
                  e.stopPropagation();
                  setShowFilterOptions(showFilterOptions === filter ? null : filter);
                  setShowDropdown(false);
                }}
                onRemove={() => removeFilter(filter)}
                isOpen={showFilterOptions === filter}
                isRequired={requiredFilters.includes(filter)}
              />
              {showFilterOptions === filter && (
                <div className="tw-absolute tw-z-20 tw-mt-2 tw-w-80 tw-bg-white tw-rounded-lg tw-shadow-lg tw-border">
                  {renderFilterComponent(filter)}
                </div>
              )}
            </div>
          ))}
          {remainingFilters.length > 0 && (
            <div className="tw-relative filter-dropdown">
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  setShowDropdown(!showDropdown);
                  setShowFilterOptions(null);
                }}
                className="tw-flex tw-items-center tw-gap-1.5 tw-px-3 tw-py-1.5 tw-text-sm tw-text-gray-600  hover:tw-bg-gray-100 tw-rounded-lg"
              >
                <Plus className="tw-h-3.5 tw-w-3.5" />
                <span>More</span>
              </button>
              {showDropdown && (
                <div className="tw-absolute tw-z-10 tw-mt-2 tw-w-56 tw-bg-white tw-rounded-lg tw-shadow-lg tw-border">
                  <div className="tw-p-2">
                    <input
                      type="text"
                      className="tw-w-full tw-px-3 tw-py-2 tw-border tw-rounded-lg focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-blue-500"
                      placeholder="Search filters..."
                      value={searchTerm}
                      onChange={(e) => setSearchTerm(e.target.value)}
                    />
                  </div>
                  <div className="tw-max-h-60 tw-overflow-y-auto tw-pb-2">
                    {remainingFilters
                      .filter((filter) => filter.toLowerCase().includes(searchTerm.toLowerCase()))
                      .map((filter) => (
                        <button
                          key={filter}
                          onClick={() => addFilter(filter)}
                          className="tw-w-full tw-px-4 tw-py-2 tw-text-left hover:tw-bg-gray-100"
                        >
                          {filters[filter]?.label}
                        </button>
                      ))}
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default FilterBar;
