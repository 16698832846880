import PropTypes from 'prop-types';
import React, { useState, useEffect, useCallback } from 'react';
import Select from 'react-select';
import { LoadingScreen } from '@Base/Loading';
import { retryableAPICall } from '@API/common-api-utils';
import { useLanguagePack, useMounted } from '@Base/hooks';
import { getInterviewAIAvailableInterviews } from '@API/InterviewerAIAPI/InterviewerAIAPI';

// Move config loading outside component to prevent recreation
let configPromise = null;

function InterviewSelect({ selectedInterview, onInterviewSelect, onError, labelClassName, selectClassName, showLoader }) {
  const languagePack = useLanguagePack('interviewer-ai');
  const [isLoading, setIsLoading] = useState(false);
  const [selInterview, setSelInterview] = useState(undefined);
  const [availableTests, setAvailableTests] = useState([]);
  const isMounted = useMounted();

  const handleError = useCallback((message) => {
    onError(message || languagePack.interviewerAILoadError || 'Could not load Interviewer AI information please try again later');
  }, [onError, languagePack.interviewerAILoadError]);

  useEffect(() => {
    const getConfig = async () => {
      setIsLoading(true);
      try {
        configPromise = retryableAPICall(() => getInterviewAIAvailableInterviews());
        const resp = await configPromise;

        if (typeof resp === 'string') {
          handleError();
        } else if (resp?.availableTests) {
          setAvailableTests(resp.availableTests);
          
          // Handle selectedInterview state
          if (selectedInterview) {
            if (typeof selectedInterview === 'string') {
              const foundTest = resp.availableTests.find(
                (at) => at.partnerTestId === selectedInterview
              );
              if (foundTest) {
                setSelInterview({
                  label: foundTest.name,
                  value: foundTest.partnerTestId,
                  totalInterviewTime: foundTest.totalInterviewTime
                });
              }
            } else {
              setSelInterview(selectedInterview);
            }
          } else {
            setSelInterview(null);
          }
        } else {
          handleError();
        }
      } catch (error) {
        handleError();
      } finally {
        setIsLoading(false);
      }
    };

    if (isMounted()) {
      getConfig();
    }
    return () => {
      configPromise = null;
    };
  }, [handleError, isMounted, selectedInterview]);

  const selectOptions = (availableTests || [])
    .filter(test => test.status === 'Live')
    .map(test => ({
      label: test.name,
      value: test.partnerTestId,
      totalInterviewTime: test.totalInterviewTime
    }));

  if (isLoading && showLoader) {
    return <LoadingScreen notFixed />;
  }

  return (
    <div className="tw-mb-5">
      <label className={`form-label tw-mr-2 ${labelClassName}`}>Select Interview Type:</label>
      <Select
        className={`inline-filter-select ${selectClassName}`}
        classNamePrefix="react-select"
        onChange={onInterviewSelect}
        options={selectOptions}
        value={selInterview}
        placeholder="Select an interview type..."
      />
    </div>
  );
}

InterviewSelect.propTypes = {
  selectedInterview: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string,
    totalInterviewTime: PropTypes.number
  }),
  onInterviewSelect: PropTypes.func.isRequired,
  labelClassName: PropTypes.string,
  selectClassName: PropTypes.string,
  showLoader: PropTypes.bool,
  onError: PropTypes.func.isRequired
};

InterviewSelect.defaultProps = {
  selectedInterview: null,
  labelClassName: null,
  selectClassName: null,
  showLoader: true,
};

export default InterviewSelect;
