import { toast } from 'react-toastify';
import { retryableAPICall } from '@API/common-api-utils';
import { getEditFormToken, getFlexiFormViewIdsByType, getGlobalFlexiFormViewByType } from '@API/FormsAPI/ATSFormsAPI';

export async function generateToken(formId, onSuccess = () => {}) {
  if (formId) {
    const resp = await retryableAPICall(() => getEditFormToken(formId));

    if (typeof resp === 'string') {
      toast.error('Error generating token');
    } else {
      const { token } = resp;
      if (token) onSuccess(token);
    }
  }
}

export async function fetchFormId(onSuccess = () => {}) {
  const resp = await retryableAPICall(() => getGlobalFlexiFormViewByType('REFEREE'));

  if (typeof resp === 'string') {
    toast.error('Error fetching referee form');
  } else {
    onSuccess(resp?.id);
  }
}

export async function fetchReferenceFormId(onSuccess = () => {}) {
  const resp = await retryableAPICall(() => getFlexiFormViewIdsByType('REFERENCE'));

  if (typeof resp === 'string') {
    toast.error('Error fetching referee form');
  } else {
    const [id] = Object.keys(resp);
    if (id) {
      onSuccess(id);
    } else {
      toast.error('Error fetching referee form');
    }
  }
}
