import { retryableAPICall } from '@API/common-api-utils';
import { searchVacancy, searchCandidates } from '@API/SearchAPI/SearchAPI';
import { getVacancy } from '@API/VacancyAPI';
import { getApplicant, getCandidateProfileSummary } from '@API/CandidateAPI/CandidateAPI';

export const SEARCH_LIMIT = 10;

export async function getVacancies(args = {}, onSuccess = () => {}, onError = () => {}) {
  const {
    keywords,
    searchFields,
    page,
    pageSize = SEARCH_LIMIT,
    sort = [{ field: 'createdDateTime', order: 'DESC' }],
    filters = [],
  } = args;

  const resp = await retryableAPICall(() =>
    searchVacancy(
      keywords,
      searchFields,
      filters,
      [
        'id',
        'positionProfile.title',
        'positionProfile.endDate',
        'positionProfile.startDate',
        'applicationSummary',
        'positionReference',
        'status',
        'accountName',
        'accountId',
        'validFrom',
        'validTo',
        'createdDateTime',
        'completedApplications',
        'lastApplicationDateTime',
        'posterDetails',
      ],
      page,
      pageSize,
      sort,
    ),
  );

  if (typeof resp === 'string') {
    onError(resp);
  } else {
    onSuccess(resp);
  }
}

export async function getVacancyDetails(id) {
  const resp = await retryableAPICall(() => getVacancy(id, true));
  return resp;
}

export async function getCandidates(args = {}, onSuccess = () => {}, onError = () => {}) {
  const {
    keywords,
    searchFields,
    page,
    pageSize = SEARCH_LIMIT,
    sort = [{ field: 'submittedDateTime', order: 'DESC' }],
    filters = [],
  } = args;

  const resp = await retryableAPICall(() =>
    searchCandidates(
      keywords,
      searchFields,
      sort,
      filters,
      [
        'id',
        'userId',
        'vacancyDetails',
        'applicantProfile',
        'funnelStageId',
        'funnelId',
        'accountName',
        'accountId',
        'funnelStageName',
        'applicationFormMetaData.applicationFormId',
        'createdDateTime',
        'appliedOnDate',
        'profileSummary',
        'submittedDateTime',
        'tags',
      ],
      page,
      pageSize,
    ),
  );

  if (typeof resp === 'string') {
    onError(resp);
  } else {
    onSuccess(resp);
  }
}

export async function getApplicantData(id, onSuccess = () => {}, onError = () => {}) {
  const resp = await retryableAPICall(() => getApplicant(id));

  if (typeof resp === 'string') {
    onError(resp);
  } else {
    onSuccess(resp);
  }
}

export async function getCandidateSummary(id, onSuccess = () => {}, onError = () => {}) {
  const resp = await retryableAPICall(() => getCandidateProfileSummary(id));

  if (typeof resp === 'string') {
    onError(resp);
  } else {
    onSuccess(resp);
  }
}
