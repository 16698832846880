import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { TextButton } from '../../Base/Buttons';
import { performImposterCheck } from '../../../api/RTWAPI/RTWAPI';
import { toast } from 'react-toastify';
import moment from 'moment';

const RightToWorkImposterCheck = ({ onComplete, candidateId, rtwDetails }) => {
  const [imposterCheck, setImposterCheck] = useState({
    identityVerified: false,
    originalDocumentsSeen: false,
    verifierName: '',
  });

  const [errors, setErrors] = useState({
    identityVerified: false,
    originalDocumentsSeen: false,
    verifierName: false,
  });

  const [isSubmitting, setIsSubmitting] = useState(false);

  const validateForm = () => {
    const newErrors = {
      identityVerified: !imposterCheck.identityVerified,
      originalDocumentsSeen: !imposterCheck.originalDocumentsSeen,
      verifierName: !imposterCheck.verifierName.trim(),
    };

    setErrors(newErrors);
    return !Object.values(newErrors).some((error) => error);
  };

  const handleSubmit = async () => {
    if (validateForm()) {
      setIsSubmitting(true);
      const result = await performImposterCheck(candidateId, imposterCheck);

      if (typeof result === 'string') {
        toast.error('Could not submit the imposter check at this time');
      } else {
        onComplete();
      }
      setIsSubmitting(false);
    }
  };

  // If imposter check is completed, show the details
  if (rtwDetails?.imposterCheck?.dateChecked) {
    return (
      <div className="app-summary-section">
        <div className="app-summary-section__header">
          <h3 className="app-summary-section__title">Imposter Check</h3>
        </div>

        <div className="tw-space-y-4">
          <div className="tw-flex tw-items-center tw-space-x-2">
            <span className="tw-px-2 tw-py-1 tw-text-xs tw-font-medium tw-rounded-full tw-bg-green-100 tw-text-green-800">
              Completed
            </span>
            <span className="tw-text-sm tw-text-gray-500">
              on {moment(rtwDetails.imposterCheck.dateChecked).format('DD/MM/YYYY [at] HH:mm')}
            </span>
          </div>

          <div className="tw-space-y-3">
            <div>
              <div className="tw-text-sm tw-font-medium tw-text-gray-500">Verified By</div>
              <div className="tw-text-sm tw-text-gray-900">{rtwDetails.imposterCheck.verifierName}</div>
            </div>

            <div className="tw-space-y-2">
              <div className="tw-flex tw-items-center tw-space-x-2">
                <svg className="tw-h-4 tw-w-4 tw-text-green-500" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                </svg>
                <span className="tw-text-sm tw-text-gray-700">Identity verified</span>
              </div>

              <div className="tw-flex tw-items-center tw-space-x-2">
                <svg className="tw-h-4 tw-w-4 tw-text-green-500" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                </svg>
                <span className="tw-text-sm tw-text-gray-700">Original documents verified</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  // If not completed, show the form
  return (
    <div className="app-summary-section">
      <div className="app-summary-section__header">
        <h3 className="app-summary-section__title">Imposter Check</h3>
      </div>
      <p className="tw-text-sm tw-text-gray-600 tw-mb-6">
        You must perform an imposter check on the new employees first day of employment to verify that the person who
        has arrived for work matches the documents provided on their right to work check on Talent Funnel. By completing
        this form, you are confirming that you have physically inspected their original Right to Work documents and
        checked their identity against both the document they have presented you, and the documents we have for them
        below.
      </p>
      {/* Rest of the form code remains the same */}
      <div className="tw-space-y-6">
        <div className="tw-flex tw-items-start tw-group tw-flex-col">
          <div className="tw-flex tw-items-start">
            <div className="tw-flex tw-items-center tw-h-5">
              <input
                id="identityVerified"
                type="checkbox"
                className="tw-h-4 tw-w-4 tw-rounded tw-border-gray-300 tw-text-blue-600 focus:tw-ring-blue-500 focus:tw-ring-offset-gray-50"
                checked={imposterCheck.identityVerified}
                onChange={(e) => {
                  setImposterCheck({
                    ...imposterCheck,
                    identityVerified: e.target.checked,
                  });
                  if (e.target.checked) {
                    setErrors({ ...errors, identityVerified: false });
                  }
                }}
              />
            </div>
            <div className="tw-ml-3 tw-text-sm">
              <label
                htmlFor="identityVerified"
                className="tw-font-medium tw-text-gray-700 group-hover:tw-text-gray-900"
              >
                I declare that I have completed the imposter check
              </label>
            </div>
          </div>
          {errors.identityVerified && (
            <div className="tw-text-red-600 tw-text-sm tw-mt-1 tw-ml-7">
              You must verify the identity of the individual
            </div>
          )}
        </div>

        <div className="tw-flex tw-items-start tw-group tw-flex-col">
          <div className="tw-flex tw-items-start">
            <div className="tw-flex tw-items-center tw-h-5">
              <input
                id="originalDocumentsSeen"
                type="checkbox"
                className="tw-h-4 tw-w-4 tw-rounded tw-border-gray-300 tw-text-blue-600 focus:tw-ring-blue-500 focus:tw-ring-offset-gray-50"
                checked={imposterCheck.originalDocumentsSeen}
                onChange={(e) => {
                  setImposterCheck({
                    ...imposterCheck,
                    originalDocumentsSeen: e.target.checked,
                  });
                  if (e.target.checked) {
                    setErrors({ ...errors, originalDocumentsSeen: false });
                  }
                }}
              />
            </div>
            <div className="tw-ml-3 tw-text-sm">
              <label
                htmlFor="originalDocumentsSeen"
                className="tw-font-medium tw-text-gray-700 group-hover:tw-text-gray-900"
              >
                I declare that I have seen their original Right to Work Documents
              </label>
            </div>
          </div>
          {errors.originalDocumentsSeen && (
            <div className="tw-text-red-600 tw-text-sm tw-mt-1 tw-ml-7">
              You must confirm you have seen the original documents
            </div>
          )}
        </div>

        <div className="tw-mt-2">
          <label htmlFor="verifierName" className="tw-block tw-text-sm tw-font-medium tw-text-gray-700">
            Your Name and Job title
          </label>
          <input
            type="text"
            id="verifierName"
            className="tw-mt-1 tw-block tw-px-3 tw-rounded-md tw-py-2 tw-w-1/3 tw-border-gray-300 tw-bg-white tw-shadow-sm focus:tw-border-blue-500 focus:tw-ring-blue-500 focus:tw-ring-offset-gray-50 tw-text-sm"
            value={imposterCheck.verifierName}
            onChange={(e) => {
              setImposterCheck({
                ...imposterCheck,
                verifierName: e.target.value,
              });
              if (e.target.value.trim()) {
                setErrors({ ...errors, verifierName: false });
              }
            }}
            placeholder="e.g. Liam Dawson, Store Manager"
          />
          {errors.verifierName && <div className="tw-text-red-600 tw-text-sm tw-mt-1">Please enter your full name</div>}
        </div>
        <p className="tw-text-sm tw-text-gray-600 tw-mb-6">
          If your new joiner has an expired British or Irish passport, Birth Certificate (and accompanying
          documentation), or Certification of Naturalisation, in addition to the above you must also take a copy of the
          physical documentation, sign and date, then scan and email to the People Team. Once complete, all digital
          documentation must be deleted and any physical copies shredded.
        </p>
        <div className="tw-mt-6">
          <TextButton
            label={isSubmitting ? 'Submitting Imposter Check...' : 'Complete Imposter Check'}
            action={handleSubmit}
            disabled={isSubmitting}
          />
        </div>
      </div>
    </div>
  );
};

RightToWorkImposterCheck.propTypes = {
  onComplete: PropTypes.func.isRequired,
  candidateId: PropTypes.string.isRequired,
  rtwDetails: PropTypes.shape({
    imposterCheck: PropTypes.shape({
      identityVerified: PropTypes.bool,
      originalDocumentsSeen: PropTypes.bool,
      verifierName: PropTypes.string,
      dateChecked: PropTypes.string,
    }),
  }),
};

export default RightToWorkImposterCheck;
