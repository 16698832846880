import React, { useEffect, useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import queryString from 'query-string';
import Page503 from '../../Pages/Page503/Page503';
import { domUtils } from '../../../touch/utils';
import { redirectToLogin, isAuthenticated } from '@JS/auth/AuthUtils';
import config from '../../../config/config';

const { maintenance } = config;

export default function PrivateRoute() {
  let ignoreMaintenance = false;
  const location = useLocation();

  if (location?.search) {
    ignoreMaintenance = queryString.parse(location.search);
  }

  const [state, setState] = useState({
    isAuthed: false,
    isMaintenance: maintenance && ignoreMaintenance !== 'true',
    serverReturned: false,
  });

  useEffect(() => {
    const { body } = document;
    // IE11 doesn't support multiple add / remove :(
    domUtils.dataListToggle();
    body.classList.remove('modal-open');
    body.classList.remove('ReactModal__Body--open');

    async function fetchData() {
      try {
        const isAuthed = await isAuthenticated();
        setState({ isAuthed, serverReturned: true });
      } catch (error) {
        setState({ isAuthed: false, serverReturned: true });
      }
    }
    fetchData();
  }, [location]);

  if (!state.serverReturned) return null;
  // if maintenance display 503 page
  if (state.isMaintenance) return <Page503 />;

  if (state.isAuthed) {
    return <Outlet />;
  }

  redirectToLogin();
}
