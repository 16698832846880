import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Col, Row } from 'reactstrap';
import { CreateButton } from '../Buttons';
import { NoDataMessage } from '.';
import Can from '../RBAC/Can/Can';
import { checkPermissions } from '@JS/auth/AuthUtils';
import { ResolveLogo } from '../SVG';

const RestrictedCreateButton = Can(CreateButton);

function NoJobSplash({ onButtonClick, userFirstName, companyLogo }) {
  return (
    <NoDataMessage
      img={<ResolveLogo imgSrc={companyLogo} imgWidth="100px" svgWidth="7em" />}
      title={`Welcome${userFirstName ? ` ${userFirstName}!` : ''}`}
      message={
        checkPermissions(['vacancy:create'])
          ? 'Post your first job to get those candidates flowing!'
          : 'There are currently no vacancies to display'
      }
    >
      <Fragment>
        <Row className="mb-5">
          <Col>
            <RestrictedCreateButton
              permissions={['vacancy:create']}
              action={onButtonClick}
              label="Post a Job"
              className="float-none mt-3"
            />
          </Col>
        </Row>
        <Row className="mt-5">
          <Col>
            <p>
              If you want some help getting started view our tutorials and help articles <a href="#help">here</a>
            </p>
          </Col>
        </Row>
      </Fragment>
    </NoDataMessage>
  );
}

NoJobSplash.propTypes = {
  onButtonClick: PropTypes.func,
  userFirstName: PropTypes.string,
  companyLogo: PropTypes.string,
};

NoJobSplash.defaultProps = {
  onButtonClick: () => {},
  userFirstName: null,
  companyLogo: null,
};

function mapStateToProps(state) {
  const {
    companyData: {
      companyDetails: { companyLogo },
    },
    userData: { userDetails },
    styles,
  } = state;
  const { logoUrl } = styles;

  return {
    userFirstName: userDetails.firstName || null,
    companyLogo: logoUrl || companyLogo,
  };
}

export default connect(mapStateToProps)(NoJobSplash);
