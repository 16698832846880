import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Form } from 'reactstrap';
import { toast } from 'react-toastify';

import { useFormKeypress, batchUpdateCssVars, useMounted, usePageLoading } from '@Base/hooks';
import ErrorFeedback from '@Base/ErrorFeedback/ErrorFeedback';
import { validateColors } from '@Base/ColorPicker/ColorPicker';
import { CreateButton, IconButton } from '@Base/Buttons';

import { saveStyleSettings } from '@API/AccountAPI';
import { retryableAPICall } from '@API/common-api-utils';

import { setStyles } from '@JS/actions/styleActions';
import { setVarsObject, gradientCreator } from '@JS/utils/style-utils';

import TalentHubBranding from './TalentHubBranding';
import ImageInput from './ImageInput';
import ColorInput from './ColorInput';
import { EnhancedCard, EnhancedCardTitle, PageColumn } from '../Common';

function BrandingForm({ styles, applyStyles }) {
  const formRef = useFormKeypress();
  const isMounted = useMounted();
  const { pageState, setPageResolved, setPageRejected } = usePageLoading();
  const cachedFormData = useRef({});
  const [formData, setFormData] = useState({});
  const [isSaving, setIsSaving] = useState(false);
  const [errors, setErrors] = useState({});

  useEffect(() => {
    try {
      const { gradientColour2, gradientColour4, ...rest } = styles;
      if (!Object.keys(cachedFormData.current).length) {
        cachedFormData.current = { ...rest };
      }
      setFormData({ ...rest });
      setPageResolved();
    } catch (error) {
      if (isMounted()) {
        setPageRejected(error);
      }
    }
  }, [styles, isMounted, setPageResolved, setPageRejected]);

  function handleColorChange(id, color) {
    const colorStr = color.startsWith('#') ? color : `#${color}`;
    const updatedData = { ...formData, [id]: colorStr };
    setErrors({ ...errors, ...validateColors(id, colorStr) });
    setFormData(updatedData);
  }

  async function handleSave() {
    setIsSaving(true);

    const { logo, logoUrl, ...colors } = formData;
    const colorErrObj = Object.entries(colors).reduce(
      (acc, [inpId, color]) => ({ ...acc, ...validateColors(inpId, color) }),
      {},
    );
    setErrors(colorErrObj);

    const hasErrors = Object.values(colorErrObj).some((errMsg) => !!errMsg.length);

    if (!hasErrors) {
      const resp = await retryableAPICall(() =>
        saveStyleSettings({
          ...colors,
          ...(logo ? { logo } : {}),
        }),
      );

      if (typeof resp === 'string') {
        toast.error('Error saving styles');
      } else {
        toast.success('Brand styles saved successfully');
        applyStyles({
          ...colors,
          ...(logo ? { logoUrl: `data:image/jpeg;base64,${logo.data}` } : {}),
        });
        batchUpdateCssVars(setVarsObject({ ...gradientCreator(...Object.values(colors)) }));
      }
    }

    setIsSaving(false);
  }

  const isDiff = JSON.stringify(formData) !== JSON.stringify(cachedFormData.current);

  return (
    <PageColumn permissions={['admin:branding']} state={pageState}>
      <EnhancedCard className="tw-mb-4">
        <EnhancedCardTitle title="ATS Branding" subtitle="Style the Talent Funnel ATS to match your brand" />
        <Form innerRef={formRef}>
          <div className="tw-mb-2">
            <div className="tw-flex tw-flex-row">
              <label className="tw-mt-1 tw-w-1/6 tw-font-normal" htmlFor="primaryColour">
                Primary Color:
              </label>
              <div className="tw-w-1/4">
                <ColorInput
                  id="primaryColour"
                  value={formData.primaryColour}
                  onChange={(val) => handleColorChange('primaryColour', val)}
                />
                <ErrorFeedback message={errors.primaryColour} />
              </div>
            </div>
          </div>
          <div className="tw-mb-2">
            <div className="tw-flex tw-flex-row">
              <label className="tw-mt-1 tw-w-1/6 tw-font-normal" htmlFor="secondaryColour">
                Secondary Color:
              </label>
              <div className="tw-w-1/4">
                <ColorInput
                  id="secondaryColour"
                  value={formData.secondaryColour}
                  onChange={(val) => handleColorChange('secondaryColour', val)}
                />
                <ErrorFeedback message={errors.secondaryColour} />
              </div>
            </div>
          </div>
          <div className="tw-mb-4">
            <div className="tw-flex tw-flex-row">
              <label className="tw-w-1/6 tw-font-normal" htmlFor="logo">
                Logo:
              </label>
              <div className="tw-w-1/3">
                <ImageInput
                  value={formData.logo || formData.logoUrl}
                  onChange={(val) => setFormData({ ...formData, logo: val })}
                />
              </div>
            </div>
          </div>
          <div className="tw-mt-4">
            <CreateButton
              label={isSaving ? 'Saving...' : 'Save'}
              isLoading={isSaving}
              disabled={isSaving}
              floatRight={false}
              action={(e) => {
                e.preventDefault();
                handleSave();
              }}
            />
            {isDiff && (
              <IconButton
                className="tw-ms-2"
                iconClassName="btn-undo"
                label="Undo"
                disabled={isSaving}
                floatRight={false}
                action={(e) => {
                  e.preventDefault();
                  setFormData({ ...cachedFormData.current });
                }}
              />
            )}
          </div>
        </Form>
      </EnhancedCard>
      <TalentHubBranding />
    </PageColumn>
  );
}

BrandingForm.propTypes = {
  styles: PropTypes.shape({
    primaryColour: PropTypes.string,
    secondaryColour: PropTypes.string,
    gradientColour2: PropTypes.string,
    gradientColour4: PropTypes.string,
    logoUrl: PropTypes.string,
  }),
  applyStyles: PropTypes.func,
};

BrandingForm.defaultProps = {
  styles: {
    primaryColour: '#7c89e3',
    secondaryColour: '#7c89e3',
  },
  applyStyles: () => {},
};

function mapStateToProps(state) {
  const { styles } = state;
  return { styles };
}

function mapDispatchToProps(dispatch) {
  return {
    applyStyles: (styleObj) => dispatch(setStyles(styleObj)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(BrandingForm);
