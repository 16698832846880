import React, { useState, useEffect } from 'react';
import { Table } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { updateVacancyCategories } from '@API/VacancyAPI';
import { retryableAPICall } from '@API/common-api-utils';

import { useLanguagePack, useMounted, usePageLoading } from '@Base/hooks';
import { CreateButton, IconButton } from '@Base/Buttons';
import { Confirmation } from '@Base/Modal';

import { setCategories } from '@JS/actions/categoriesActions';

import { EnhancedCard, PageColumn, EnhancedCardTitle } from '../../Common';

function VacancySettings() {
  const languagePack = useLanguagePack('vacancy-settings');
  const isMounted = useMounted();
  const { pageState, setPageResolved } = usePageLoading();
  const [isSaving, setIsSaving] = useState(false);
  const [newCategoryValue, setNewCategoryValue] = useState('');
  const [categoryToRemove, setCategoryToRemove] = useState(undefined);
  const dispatch = useDispatch();

  const { categories } = useSelector((state) => state.categoryReducer);

  useEffect(() => {
    if (isMounted()) {
      setPageResolved();
    }
  }, [isMounted, setPageResolved]);

  async function saveCategories(categoriesToSave) {
    try {
      setIsSaving(true);
      const sortedCategories = [...categoriesToSave].sort((a, b) => a.label.localeCompare(b.label));
      const resp = await retryableAPICall(() => updateVacancyCategories(sortedCategories));

      if (typeof resp === 'string') {
        toast.error(languagePack.couldNotUpdateCategories);
      } else {
        dispatch(setCategories(resp.categories));
      }
    } catch (error) {
      toast.error(languagePack.couldNotUpdateCategories);
    } finally {
      setIsSaving(false);
    }
  }

  function removeCategory() {
    const updatedCategories = categories.filter((category) => category.id !== categoryToRemove.id);
    saveCategories(updatedCategories).finally(() => {
      setCategoryToRemove(undefined);
    });
  }

  function addCategory() {
    const updatedCategories = [...categories, { id: newCategoryValue, label: newCategoryValue }];
    saveCategories(updatedCategories).then(() => {
      setNewCategoryValue('');
    });
  }

  return (
    <>
      <PageColumn state={pageState}>
        <EnhancedCard>
          <EnhancedCardTitle title="Vacancy Settings" subtitle="Manage your vacancy data" />
          <div>
            <div className="tw-mb-4">
              <p className="tw-text-gray-600">{languagePack.rolesExplanation}</p>
            </div>
            {categories.length > 0 && (
              <Table className="tw-max-w-[500px]">
                <tbody>
                  {categories.map((category, index) => (
                    <tr key={`${category.label}-${index}`}>
                      <td>{category.label}</td>
                      <td className="tw-w-[50px]">
                        <IconButton
                          iconOnly
                          iconClassName="fa fa-times"
                          size="sm"
                          action={() => setCategoryToRemove(category)}
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            )}
            {categories.length === 0 && !pageState.isRejected && (
              <div className="tw-mb-3">
                <p className="tw-font-bold">{languagePack.noCategoriesLabel}</p>
              </div>
            )}

            {!pageState.isPending && !pageState.isRejected && (
              <div className="tw-space-y-2">
                <input
                  placeholder={languagePack.addCategoryPlaceholder}
                  className="tw-w-[500px] tw-px-3 tw-py-2 tw-mb-1 tw-border tw-border-gray-300 tw-rounded-lg tw-focus:ring-2 tw-focus:ring-blue-200"
                  value={newCategoryValue}
                  onChange={(e) => setNewCategoryValue(e.currentTarget.value)}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') addCategory();
                  }}
                  disabled={isSaving}
                />
                <div>
                  <CreateButton
                    floatRight={false}
                    label={languagePack.addCategoryButtonLabel}
                    action={addCategory}
                    disabled={!newCategoryValue || newCategoryValue === '' || isSaving}
                    isLoading={isSaving}
                  />
                </div>
              </div>
            )}
          </div>
        </EnhancedCard>
      </PageColumn>
      <Confirmation
        content={languagePack.removeAreYouSureCategoryContent}
        title={`${languagePack.removeAreYouSureCategoryTitle} ${categoryToRemove?.label}`}
        confirmCallback={removeCategory}
        show={!!categoryToRemove}
        cancelCallback={() => setCategoryToRemove(undefined)}
      />
    </>
  );
}

export default VacancySettings;
