import React from 'react';
import PropTypes from 'prop-types';
import { IconSVG } from '@Base/SVG';

function RightToWorkMessage({ level, content }) {
  if (content === undefined) {
    return null;
  }

  let cssClass;

  switch (level) {
    case 'INFO':
      cssClass = 'text-info';
      break;
    case 'WARNING':
      cssClass = 'text-warning';
      break;
    case 'DANGER':
      cssClass = 'text-danger';
      break;
    default:
      cssClass = 'text-info';
  }
  return (
    <div className={cssClass}>
      <IconSVG name="Exclamation Circle" className="warning-icon" />
      <p className="inline ms-2" style={{ fontSize: '14px', fontWeight: 'bold' }}>
        {content}
      </p>
    </div>
  );
}

RightToWorkMessage.propTypes = {
  level: PropTypes.string,
  content: PropTypes.string,
};

RightToWorkMessage.defaultProps = {
  level: 'INFO',
  content: undefined,
};

export default RightToWorkMessage;
