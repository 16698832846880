/* eslint-disable no-nested-ternary */
import { useState, useEffect, useCallback, useRef, useContext } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { Col, Form, Row } from 'reactstrap';
import { Link } from 'react-router-dom';
import Tippy from '@tippyjs/react';

// import moment from 'moment';
import { useFormKeypress, useMounted } from '@Base/hooks';
import IntegrationInput from './IntegrationInput';
import { retryableAPICall } from '@API/common-api-utils';
import { CancelButton, CreateButton } from '@Base/Buttons';
import { WorkflowContext } from '@Base/contexts/WorflowContext';
import validation, { mapErrors } from '@JS/utils/validation';
import { addPolarisUser, addGlobalConfig } from '@JS/actions/polarisActions';
import {
  getLinkedAccount,
  getGlobalConfig,
  getSiteLevelConfig,
  checkPolarisSubmission,
  submitToPolaris,
} from '@API/Integrations/PolarisAPI';
import polarisInputConfig from './polarisInputConfig';
import { updateArr } from '@JS/utils/arrayOfObjects';
import { requestStatuses } from '@JS/constants/requestStatuses';

function isCreateRestricted(res, data) {
  const buttonRestrictions = res?.find(({ component }) => component === 'polaris_submit_button') || {};

  if (!buttonRestrictions?.restrictions?.length) return false;

  const isRestricted = buttonRestrictions.restrictions.some(({ component, enableState }) => {
    if (component === 'RTW') {
      const statesToCheck = ['REJECTED', 'CONTINUOUS', 'TIME_LIMITED'];
      const shouldCheckOutcomeStatus = statesToCheck.some((state) => enableState.includes(state));

      if (shouldCheckOutcomeStatus) {
        return data.rightToWork.data.outcomeStatus
          ? !enableState.some((state) => state === data.rightToWork.data.outcomeStatus)
          : true;
      }

      return data.rightToWork.status ? !enableState.some((state) => state === data.rightToWork.status) : true;
    }

    if (component === 'FLEXI_FORM') {
      return data.genericForm.length > 0
        ? data.genericForm.every((edoc) => {
            return enableState.some((state) => state !== edoc.status);
          })
        : false;
    }

    if (component === 'REFERENCES') {
      return data.references.status ? enableState.some((state) => state !== data.references.status) : true;
    }
  });

  return isRestricted ? buttonRestrictions.restrictionMsg : false;
}

function customValidation(inputs, formData, onboardingData) {
  const inputValidation = inputs.reduce((acc, input) => {
    const { id } = input;

    if (id in formData) {
      if (id === 'payMethod' && formData.payMethod === 'BACS') {
        const bankDetails = onboardingData?.sections
          .flatMap((section) => section.questions)
          .flat()
          .find((question) => question.fieldId === 'bankDetails');

        if (!bankDetails) {
          acc[id] = { invalid: true, errors: ['Please provide Bank Details if using BACS'] };
        } else if (!bankDetails?.value?.bankAccNumber) {
          acc[id] = { invalid: true, errors: ['Bank account number is required using BACS'] };
        } else if (!bankDetails?.value?.bankAccountName) {
          acc[id] = { invalid: true, errors: ['Bank account name is required using BACS'] };
        } else if (!bankDetails?.value?.bankSortCode) {
          acc[id] = { invalid: true, errors: ['Bank sccount sort code is required using BACS'] };
        }
      }
    }

    return acc;
  }, {});

  return inputValidation;
}

async function checkPolarisStatus(onSuccess = () => {}, candidateId) {
  let resp;

  if (candidateId) {
    resp = await retryableAPICall(() => checkPolarisSubmission(candidateId));
  } else {
    resp = await retryableAPICall(() => getLinkedAccount());
  }

  if (typeof resp === 'string' && resp.length) {
    if (resp !== 'NOT_FOUND_ERROR') {
      toast.error('Error fetching Polaris information. Please try again later or contact support');
    }
  } else {
    onSuccess(resp);
  }
}

function isFetchSuccess(resp, respName) {
  if (typeof resp === 'string') {
    if (resp !== 'NOT_FOUND_ERROR') {
      toast.error(`Error fetching Polaris ${respName}. Please try again later or contact support`);
    }

    return false;
  }

  return true;
}

async function fetchServerConfigs(applicantAccountId, onSuccess = () => {}) {
  const fetchCalls = [getGlobalConfig, getSiteLevelConfig];

  const [cfgResp, siteResp] = await Promise.all(
    fetchCalls.map((fetchCall) => retryableAPICall(() => fetchCall(applicantAccountId))),
  );

  const isCfgSuccess = isFetchSuccess(cfgResp, 'Config');
  const isSiteSuccess = isFetchSuccess(siteResp, 'Site Level');

  const compiledObject = {
    ...(isCfgSuccess ? { ...cfgResp } : {}),
    ...(isSiteSuccess ? { ...siteResp } : {}),
  };

  if (isSiteSuccess || isCfgSuccess) onSuccess(compiledObject);
}

function lookupValues(config, formData = {}) {
  const dataObj = polarisInputConfig.reduce((acc, inp) => {
    const { id, configId, serverDataIds } = inp;

    if (serverDataIds && config[configId]) {
      const serverVals = config[configId];
      const [dataName, confName, confId] = serverDataIds;

      if (formData[dataName]) {
        const serverObject = serverVals.find((obj) => obj[confName] === formData[dataName]);
        if (serverObject) return { ...acc, [id]: serverObject[confId] };
      }
    } else if (formData[id]) {
      return { ...acc, [id]: formData[id] };
    }

    return acc;
  }, {});

  return dataObj;
}

function mapConfigToOptions(configArr = []) {
  return configArr.map((conf) => {
    if (typeof conf === 'string') {
      return { value: conf, label: conf };
    }

    const {
      ID,
      name,
      value,
      label,
      job_id: jobId,
      job_name: jobName,
      role_hierarchy_id: roleId,
      role_name: roleName,
      department_id: deptId,
      department_name: deptName,
      t_and_c_id: tacId,
      t_and_c_name: tacName,
    } = conf;

    return {
      value: jobId || roleId || deptId || tacId || ID || value,
      label: jobName || roleName || deptName || tacName || name || label,
    };
  });
}

function deleteOption(arr, val) {
  const idx = arr.findIndex(({ value }) => value === val);
  return updateArr(arr, idx);
}

const initFormData = polarisInputConfig.reduce(
  (acc, { id, type, defaultValue }) => ({ ...acc, [id]: type === 'number' ? defaultValue : '' }),
  {},
);

function PolarisForm({
  applicantId,
  applicantAccountId,
  connected,
  accountIdToSiteId: accSiteMap,
  setPolarisDomain,
  globalConfig,
  setGlobalConfig,
  globalConfigLoaded,
  onBoardingAnswers,
  candidateProfileSummary,
}) {
  const { data, isLoading } = useContext(WorkflowContext);
  const isMounted = useMounted();
  const formRef = useFormKeypress();
  const [isAuthorised, setIsAuthorised] = useState(false);
  const [formData, setFormData] = useState(initFormData);
  const [isSaving, setIsSaving] = useState(false);
  const [formKey, setFormKey] = useState('');
  const [errors, setErrors] = useState({});
  const [submissionStatus, setSubmissionStatus] = useState('INCOMPLETE');
  const [failReason, setFailReason] = useState();
  const validationConfig = useRef([]);

  const checkStatus = useCallback(
    (dataObj, noFormData) => {
      const { statusReason, status, ...fData } = dataObj;

      if (status === 'FAILED') {
        setSubmissionStatus('FAILED');
        setFailReason(statusReason);
        if (noFormData) toast.error('Error submitting form, please try again or contact support');
      } else if (status === 'SUCCESS') {
        setSubmissionStatus('COMPLETE');
        setFailReason();
        if (noFormData) toast.success('Form submitted successfully');
      }

      if (!noFormData && !!Object.keys(fData).length && globalConfigLoaded) {
        setFormData(lookupValues(globalConfig, fData));
      }
    },
    [globalConfig, globalConfigLoaded],
  );

  useEffect(() => {
    if (connected) {
      setIsAuthorised(true);

      if (isMounted()) {
        fetchServerConfigs(applicantAccountId, (resp) => {
          const keyLen = Object.keys(resp).length;

          if (keyLen) {
            const {
              // contractTypes,
              // jobClasses,
              payFrequencies,
              payMethods,
              rateFrequencies,
              jobs,
              hierarchyRoles,
              departments,
              tacs,
            } = resp;

            // const hasContractTypes = !!(Array.isArray(contractTypes) && contractTypes.length);
            // const hasJobClasses = !!(Array.isArray(jobClasses) && jobClasses.length);
            const hasPayFrequencies = !!(Array.isArray(payFrequencies) && payFrequencies.length);
            const hasPayMethods = !!(Array.isArray(payMethods) && payMethods.length);
            const hasRateFrequencies = !!(Array.isArray(rateFrequencies) && rateFrequencies.length);
            const hasJobs = !!(Array.isArray(jobs) && jobs.length);
            const hasRoles = !!(Array.isArray(hierarchyRoles) && hierarchyRoles.length);
            const hasDepts = !!(Array.isArray(departments) && departments.length);
            const hasTacs = !!(Array.isArray(tacs) && tacs.length);

            const hasRequiredFields =
              hasPayFrequencies && hasPayMethods && hasRateFrequencies && hasJobs && hasRoles && hasDepts && hasTacs;

            setGlobalConfig(resp, keyLen > 7 && hasRequiredFields);
          }
        });

        validationConfig.current = polarisInputConfig.reduce((acc, { id, type, required, inputProps }) => {
          const { min, max } = inputProps || {};
          return [...acc, { id, required, ...(type === 'number' ? { type, min, max } : {}) }];
        }, []);
      }
    }
  }, [connected, globalConfigLoaded, isMounted, setGlobalConfig]);

  useEffect(() => {
    if (connected && applicantId && isMounted()) {
      checkPolarisStatus(checkStatus, applicantId);
    }
  }, [applicantId, checkStatus, connected, isMounted]);

  useEffect(() => {
    if (isMounted() && !connected) {
      checkPolarisStatus((resp) => {
        const { domainUrl, accountIdToSiteId } = resp;
        // const advSettings = getAdvancedSettings(rest);
        setPolarisDomain(domainUrl, accountIdToSiteId);
      });
    }
  }, [connected, isMounted, setPolarisDomain]);

  function handleChange(id, value) {
    let updatedData = { ...formData, [id]: value };

    if (id === 'contractType') {
      if (
        (/^variable|seasonal$/.test(value) && updatedData.rateFrequency === 'year') ||
        (/^permanent$/.test(value) && updatedData.rateFrequency !== 'year')
      ) {
        updatedData = {
          ...updatedData,
          rateFrequency: '',
        };
        setFormKey(Math.random().toString(10));
      }
    }

    setFormData(updatedData);
  }

  async function handleSave() {
    setIsSaving(true);

    const validationCfg = [...validationConfig.current];

    const errObj = validation(validationCfg, formData);

    const polarisValidation = customValidation(validationCfg, formData, onBoardingAnswers);

    const { messages, hasErrors } = mapErrors({
      ...errObj,
      ...polarisValidation,
    });

    setErrors(messages);

    if (!hasErrors) {
      const { contractType, jobClass, visaNotes, endDate, isHouseLogin, empLivingIn, ...rest } = formData;

      const serverData = {
        ...rest,
        candidateId: applicantId,
        ...(contractType?.length ? { contractType } : {}),
        ...(jobClass?.length ? { jobClass } : {}),
        ...(isHouseLogin ? { isHouseLogin: true } : { isHouseLogin: false }),
        ...(empLivingIn ? { empLivingIn: true } : { empLivingIn: false }),
        ...(visaNotes?.length ? { visaNotes } : {}),
        ...(endDate?.length ? { endDate } : {}),
      };

      // console.log(serverData);

      const resp = await retryableAPICall(() => submitToPolaris(serverData));

      if (typeof resp === 'string') {
        let errMsg = '';
        if (resp === requestStatuses.GENERAL_ERROR) {
          errMsg = 'Error submitting form, please try again or contact support';
        } else if (resp === requestStatuses.INVALID_CREDENTIALS) {
          errMsg = 'Error submitting form. Invalid Credentials. Please check settings and try again.';
        }

        toast.error(errMsg);
      }

      checkStatus(resp, true);
    }

    setIsSaving(false);
  }

  if (!isAuthorised) return null;

  const isComplete = submissionStatus === 'COMPLETE';
  const isFailed = submissionStatus === 'FAILED';

  function reset() {
    setFormData({ ...initFormData });
    setErrors({});
    setFormKey(Math.random().toString(10));
  }

  const hasKeyMaps = Object.entries(accSiteMap).reduce((acc, [accId, keys]) => {
    if (keys.private?.length && keys.public?.length) acc.push(accId);
    return acc;
  }, []);

  const isCreateButtonRestricted = isSaving || isLoading || isCreateRestricted(data, candidateProfileSummary);

  return (
    <>
      <hr />
      <Row>
        <Col>
          <div className="d-flex align-items-center mb-2">
            {/* eslint-disable-next-line max-len */}
            <h4 className="mb-0 me-auto">{`Polaris - ${
              isComplete ? 'Completed' : isFailed ? 'Failed' : 'Pending'
            }`}</h4>
          </div>
          {!hasKeyMaps.length && (
            <div className="alert alert-warning">
              <p>
                Account site mapping is not set up.{' '}
                <Link to="/settings/polaris">Please go to Polaris settings to create a site mapping</Link>
              </p>
            </div>
          )}
          {failReason && (
            <div className="alert alert-danger">
              <p>{failReason}</p>
            </div>
          )}
          <Form innerRef={formRef} key={formKey}>
            {polarisInputConfig.map(({ id, label, type, required, inputProps, configId }) => {
              let options = [];

              if (globalConfig.hiddenInputs && globalConfig.hiddenInputs.includes(id)) {
                return null;
              }

              if (type === 'select') {
                if (globalConfig[configId]) {
                  options = mapConfigToOptions(globalConfig[configId]);

                  if (options.length === 1 && !formData[id]) {
                    handleChange(id, options[0].value);
                  }

                  if (id === 'rateFrequency') {
                    if (formData.contractType !== 'permanent') {
                      options = deleteOption(options, 'year');
                    } else {
                      options = deleteOption(options, 'hour');
                      options = deleteOption(options, 'week');
                    }
                  }
                }
              }

              let value = formData[id] || '';
              if (type === 'number' && !formData[id]) value = 0;
              // to bool
              if (type === 'checkbox' && typeof formData[id] !== 'boolean') value = formData[id] === 1;

              return (
                <IntegrationInput
                  key={id}
                  type={type}
                  label={label}
                  id={id}
                  options={options}
                  value={value}
                  onChange={(val) => {
                    handleChange(id, val);
                  }}
                  required={required}
                  error={errors[id]}
                  readOnly={isComplete}
                  inputProps={inputProps}
                  isClearable={type === 'select' && options.length > 1}
                />
              );
            })}
            {!isComplete && (
              <div className="float-end d-flex">
                <CancelButton
                  className="mt-2"
                  label="Clear"
                  isLoading={isSaving}
                  disabled={isSaving}
                  action={() => reset()}
                />
                <Tippy
                  // eslint-disable-next-line max-len
                  content={isCreateButtonRestricted ? isCreateButtonRestricted || 'Cannot submit at this time' : null}
                  theme="ats"
                  disabled={!isCreateButtonRestricted}
                >
                  <div>
                    <CreateButton
                      className="mt-2"
                      label={isSaving ? 'Submitting data...' : 'Submit Candidate to Polaris'}
                      isLoading={isSaving || isLoading}
                      disabled={isCreateButtonRestricted}
                      action={(e) => {
                        e.preventDefault();
                        handleSave();
                      }}
                    />
                  </div>
                </Tippy>
              </div>
            )}
          </Form>
        </Col>
      </Row>
    </>
  );
}

PolarisForm.propTypes = {
  accountIdToSiteId: PropTypes.shape(),
  applicantAccountId: PropTypes.string,
  applicantId: PropTypes.string,
  candidateProfileSummary: PropTypes.shape(),
  connected: PropTypes.bool,
  globalConfig: PropTypes.shape(),
  globalConfigLoaded: PropTypes.bool,
  onBoardingAnswers: PropTypes.shape(),
  setGlobalConfig: PropTypes.func,
  setPolarisDomain: PropTypes.func,
};

PolarisForm.defaultProps = {
  applicantId: null,
  applicantAccountId: null,
  candidateProfileSummary: null,
  connected: false,
  accountIdToSiteId: {},
  setPolarisDomain: () => {},
  globalConfig: {},
  setGlobalConfig: () => {},
  globalConfigLoaded: false,
  onBoardingAnswers: {},
  // lastUpdateDate: null,
};

function mapStateToProps(state) {
  const {
    polaris: {
      // domainUrl,
      connected,
      accountIdToSiteId,
      globalConfig,
      globalConfigLoaded,
      // lastUpdateDate,
    },
  } = state;

  return {
    // domainUrl,
    accountIdToSiteId,
    connected,
    globalConfig,
    globalConfigLoaded,
    // lastUpdateDate,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setPolarisDomain: (domainUrl, accountIdToSiteId) => {
      dispatch(addPolarisUser(domainUrl, accountIdToSiteId));
    },
    setGlobalConfig: (configObj, isComplete) => {
      dispatch(addGlobalConfig(configObj, isComplete));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(PolarisForm);
