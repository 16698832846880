// eslint-disable-next-line no-unused-vars
import React, { Fragment } from 'react';
import { Col, Row } from 'reactstrap';
import PropTypes from 'prop-types';
import moment from 'moment';
import { TagContainer, TagContainerContext } from '@Base/Tags';
import { IconSVG } from '@Base/SVG';
import { BadgePill } from '@Base/Badge';
import { checkPermissions } from '@JS/auth/AuthUtils';

const now = moment();

function isUnder18(dateOfBirth) {
  const dob = moment(dateOfBirth);
  const age = moment.duration(now.diff(dob)).years();
  return age < 18;
}

function CandidateContactSummary({ completedApplicationForm, languagePack, hasTitles }) {
  if (!completedApplicationForm) return null;

  const {
    id,
    applicantProfile: { communication, location, personalDetails },
  } = completedApplicationForm;

  const { givenName, familyName, dateOfBirth } = personalDetails || {};
  const { phone, emailAddress } = communication || {};
  const { mobilePhone } = phone || {};
  const { postCode } = location || {};

  return (
    <>
      {givenName && familyName && (
        <Row className="candidate-name">
          <Col>
            <h2 className="detail-value-block mb-0">{`${givenName} ${familyName}`}</h2>
          </Col>
        </Row>
      )}
      {id && checkPermissions(['talentfunnel:admin']) && (
        <Row className="candidate-name">
          <Col>
            <p className="detail-value-block mb-0">Candidate ID - {id}</p>
          </Col>
        </Row>
      )}
      <Row className="candidate-email">
        <Col>
          {hasTitles && <p className="detail-title-block">{languagePack.email || 'Email'}</p>}
          <p className="detail-value-block">
            {!hasTitles && <IconSVG className="me-2" name="Email Solid" />}
            {emailAddress}
          </p>
        </Col>
      </Row>
      {mobilePhone && (
        <Row className="candidate-phone">
          <Col>
            {hasTitles && <p className="detail-title-block">{languagePack.phone || 'Phone'}</p>}
            <p className="detail-value-block">
              {!hasTitles && <IconSVG className="me-2" name="Phone" />}
              {mobilePhone.charAt(0) !== '0' ? `+${mobilePhone}` : mobilePhone}
            </p>
          </Col>
        </Row>
      )}
      {postCode && (
        <Row className="candidate-postcode">
          <Col>
            {hasTitles && <p className="detail-title-block">{languagePack.postcode || 'Postcode'}</p>}
            <p className="detail-value-block">{postCode}</p>
          </Col>
        </Row>
      )}
      {dateOfBirth && (
        <Row className="candidate-warnings">
          <Col>
            {isUnder18(dateOfBirth) && (
              <BadgePill
                label={
                  <>
                    {languagePack.under18}
                    <i
                      className="fa fa-exclamation-triangle"
                      style={{ position: 'relative', color: 'white', marginLeft: '5px' }}
                    />
                  </>
                }
                status="failed"
              />
            )}
          </Col>
        </Row>
      )}
      <Row className="mt-2 candidate-tags">
        <Col>
          <TagContainerContext.Consumer>
            {({ onTagsChange }) => <TagContainer applicantId={id} onChange={(tags) => onTagsChange(tags, id)} />}
          </TagContainerContext.Consumer>
        </Col>
      </Row>
    </>
  );
}

CandidateContactSummary.propTypes = {
  languagePack: PropTypes.shape(),
  completedApplicationForm: PropTypes.shape({
    id: PropTypes.string,
    applicantProfile: PropTypes.shape({
      communication: PropTypes.shape({
        emailAddress: PropTypes.string,
        phone: PropTypes.shape({
          mobilePhone: PropTypes.string,
        }),
      }),
      location: PropTypes.shape({
        postCode: PropTypes.string,
      }),
      personalDetails: PropTypes.shape({
        givenName: PropTypes.string,
        familyName: PropTypes.string,
        dateOfBirth: PropTypes.string,
      }),
    }),
  }),
  hasTitles: PropTypes.bool,
};

CandidateContactSummary.defaultProps = {
  languagePack: {},
  completedApplicationForm: null,
  hasTitles: true,
};

export default CandidateContactSummary;
