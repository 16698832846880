import axios from 'axios';
import fileDownload from 'js-file-download';
import { getCommonHeaders } from '../common-api-utils';
import config from '../../config/config';

const { genericForms, api } = config;
const basePath = api.middlewareAPIURL + genericForms.basePath;

/**
 * All API calls expected to be called inside retryableAPICall function to manage error handling.
 */
export async function getGenericFormDetails(candidateId) {
  const result = await axios.get(`${basePath}/${candidateId}`, { headers: getCommonHeaders() });

  if (result.status === 201 || result.status === 200) return result.data;
  return null;
}

export async function genericFormRequest(candidateId, formIds, subject, content, replyTo, fromName, attachments) {
  const result = await axios.post(
    `${basePath}`,
    {
      candidateId,
      emailContext: {
        attachments,
        fromName,
        htmlContent: content,
        replyTo,
        subject,
      },
      formIds,
    },
    { headers: getCommonHeaders() },
  );

  if (result.status === 201 || result.status === 200) return result.data;
  return null;
}

export async function sendGenericFormReminder(formReqId) {
  const result = await axios.post(`${basePath}/reminder/${formReqId}`, null, { headers: getCommonHeaders() });

  if (result.status === 201 || result.status === 200) return result.data;
  return null;
}

export async function downloadGenericForms(formIds, fileNames) {
  const url = `${api.middlewareAPIURL}${genericForms.basePath}${genericForms.downloadeDocPath}?ids=${formIds.join(
    ',',
  )}`;

  const result = await axios.get(url, { headers: getCommonHeaders() });

  const blob = await (await fetch(`data:application/pdf;base64,${result.data.data}`)).blob();

  let fileName = result.data.fileName;
  if (fileNames && fileNames[formIds[0]]) {
    fileName = fileNames[formIds[0]];
  }

  if (result.status === 200) return fileDownload(blob, fileName);
  return null;
}
