/* eslint-disable jsx-a11y/label-has-for */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { FormGroup, Input, Label } from 'reactstrap';

import { spliceItem } from '../utils';

function LogicMultiOptions({ value, options, onChange, identifier }) {
  const [selected, setSelected] = useState([]);

  useEffect(() => {
    setSelected(value);
  }, [value]);

  return options.map(({ id, text }, i) => (
    <FormGroup check key={id}>
      <Label check>
        <Input
          type="checkbox"
          className="standard-checkbox small"
          checked={selected.includes(identifier === 'id' ? id : text)}
          onChange={(e) => {
            const {
              target: { checked },
            } = e;
            const idx = selected.findIndex((val) => val === (identifier === 'id' ? id : text));
            const updated = checked ? [...selected, identifier === 'id' ? id : text] : spliceItem(selected, idx);
            setSelected(updated);
            onChange(updated);
          }}
        />
        <span>{text || `Option ${i + 1}`}</span>
      </Label>
    </FormGroup>
  ));
}

LogicMultiOptions.propTypes = {
  value: PropTypes.arrayOf(PropTypes.string),
  options: PropTypes.arrayOf(PropTypes.shape()),
  onChange: PropTypes.func,
  identifier: PropTypes.string,
};

LogicMultiOptions.defaultProps = {
  value: [],
  options: [],
  onChange: () => {},
  identifier: 'text',
};

export default LogicMultiOptions;
