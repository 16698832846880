import React from 'react';
import { ChevronDown, ChevronRight } from 'lucide-react';
import { checkPermissions } from '@JS/auth/AuthUtils';

const MenuItem = ({ item, uri, depth = 0, isOpen, activeTab, handleClick, handleSectionToggle, openSections }) => {
  const hasSubmenu = item.submenu && item.submenu.length > 0;
  const isExpanded = openSections.includes(uri);

  if (!checkPermissions(item.permissions)) {
    return null;
  }

  const findFirstAvailableSubmenuItem = (menuItem) => {
    if (!menuItem.submenu) {
      return checkPermissions(menuItem.permissions) ? menuItem : null;
    }

    for (const subItem of menuItem.submenu) {
      if (checkPermissions(subItem.permissions)) {
        if (subItem.submenu) {
          const foundItem = findFirstAvailableSubmenuItem(subItem);
          if (foundItem) return foundItem;
        } else {
          return subItem;
        }
      }
    }
    return null;
  };

  const handleMenuClick = () => {
    if (!isOpen && hasSubmenu) {
      // When menu is closed and item has submenu, find and click first available item
      const firstAvailableItem = findFirstAvailableSubmenuItem(item);
      if (firstAvailableItem) {
        handleClick(firstAvailableItem.uri, item.uri);
      }
    } else if (hasSubmenu && uri) {
      handleSectionToggle(uri);
    } else {
      handleClick(item.uri);
    }
  };

  return (
    <div>
      <button
        onClick={handleMenuClick}
        className={`
          tw-flex tw-items-center tw-w-full tw-p-2 
          tw-hover:bg-gray-700 tw-rounded-lg tw-transition-colors
          ${!isOpen && 'justify-center'}
          ${depth > 0 && isOpen && 'tw-pl-8 tw-text-sm'}
          ${activeTab === item.uri && 'tw-bg-gray-200'}
          ${depth === 0 && 'tw-font-semibold tw-text-[#1a2e56]'}
        `}
      >
        <span className="tw-flex tw-items-center tw-gap-3">
          {item.icon}
          {isOpen && <span style={{ textWrap: 'nowrap', overflow: 'hidden' }}>{item.title}</span>}
        </span>
        {hasSubmenu && isOpen && (
          <span className="tw-ml-auto">{isExpanded ? <ChevronDown size={16} /> : <ChevronRight size={16} />}</span>
        )}
      </button>

      {hasSubmenu && isOpen && isExpanded && (
        <div className="tw-ml-4 mt-1 tw-space-y-1">
          {item.submenu.map((subItem, index) => (
            <MenuItem
              key={index}
              item={subItem}
              depth={depth + 1}
              isOpen={isOpen}
              activeTab={activeTab}
              handleClick={handleClick}
              handleSectionToggle={handleSectionToggle}
              openSections={openSections}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default MenuItem;
