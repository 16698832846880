import React, { Fragment, useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import Select from 'react-select';
import { connect } from 'react-redux';
import { Input } from 'reactstrap';
import { retryableAPICall } from '@API/common-api-utils';
import { searchQuestions } from '@API/FormsAPI/ATSFormsAPI';
import { formatFilters, getFilteredFields } from '../filter-utils';
import { SelectInput } from '../../Forms/Custom/CommonComponents';
import { useMounted } from '../../hooks';

async function fetchMappedAnswerColumns(filters, onSuccess = () => {}, filteredFields = []) {
  const resp = await retryableAPICall(() => searchQuestions('', filters, ['mappedAnswers']));

  if (Array.isArray(resp)) {
    const opts = resp
      .map((opt) => ({ ...opt, value: opt.text, label: opt.text }))
      // filter out other field types
      .filter(({ fieldType }) => fieldType === 'string')
      // if field is already filtered don't allow it to be an options
      // this avoids duplicate filters
      .filter(({ text }) => !filteredFields.includes(text));

    onSuccess(opts);
  } else {
    toast.error('Error loading question list');
  }
}

function FormQuestionFilter({ activeFilters, filterName, filterValues, onChange }) {
  const isMounted = useMounted();
  const [opts, setOpts] = useState([]);

  const {
    question: { fieldType: questionType, text: questionText, fieldOptions: fieldAnswerOpts },
    answer,
  } = filterValues;

  useEffect(() => {
    const existingFilters = formatFilters(activeFilters[filterName]);
    // get array of fields already filtered
    const filteredFields = getFilteredFields(existingFilters);
    // get account filter from existing filters
    const accountFilter = existingFilters.filter(({ field }) => field === 'accountId');
    // compile list of filters
    const filters = [...accountFilter, { field: 'formType', operation: 'EQ', value: ['APPLICATION_FORM'] }];

    fetchMappedAnswerColumns(
      filters,
      (resp) => {
        if (isMounted())
          setOpts([
            {
              text: 'UTM Campaign',
              fieldType: 'string',
              fieldIds: ['utm.utmCampaign'],
              lastAnswered: '2025-03-11 02:00:59',
              value: 'UTM Campaign',
              label: 'UTM Campaign',
            },
            {
              text: 'UTM Medium',
              fieldType: 'string',
              fieldIds: ['utm.utmMedium'],
              lastAnswered: '2025-03-11 02:00:59',
              value: 'UTM Medium',
              label: 'UTM Medium',
            },
            {
              text: 'UTM Source',
              fieldType: 'string',
              fieldIds: ['utm.utmSource'],
              lastAnswered: '2025-03-11 02:00:59',
              value: 'UTM Source',
              label: 'UTM Source',
            },
            ...resp,
          ]);
      },
      filteredFields,
    );
  }, [activeFilters, filterName, isMounted]);

  const handleSelect = useCallback(
    (selectedOpt) => {
      const { text = '', availableOptions } = selectedOpt || {};
      let { fieldType = '' } = selectedOpt || {};
      let fieldOptions = [];

      if (availableOptions) {
        fieldType = 'array';
        fieldOptions = availableOptions;
      }

      onChange({
        ...filterValues,
        question: { text, fieldType, fieldOptions },
        // on clear reset field values array
        ...(!selectedOpt ? { answer: '' } : {}),
      });
    },
    [filterValues, onChange],
  );

  const handleAnswerChange = useCallback(
    (str = '') => {
      onChange({ ...filterValues, answer: str });
    },
    [filterValues, onChange],
  );

  const answerInputProps = {
    className: 'mt-2',
    placeholder: 'Candidate Answer',
    value: answer || '',
  };

  return (
    <div className="form-question-filter">
      <Select
        classNamePrefix="react-select"
        id="filter-question-select"
        isClearable
        onChange={handleSelect}
        options={opts}
        placeholder="Search Questions"
        value={questionText ? { value: questionText, label: questionText } : null}
      />
      {questionType === 'string' && (
        <>
          <Input {...answerInputProps} onChange={(e) => handleAnswerChange(e.target.value)} />
          <p className="help-block">Use * for wildcard search. E.g. And*</p>
        </>
      )}
      {questionType === 'array' && (
        <SelectInput
          {...answerInputProps}
          onChange={handleAnswerChange}
          options={fieldAnswerOpts.map((opt) => ({ value: opt, label: opt }))}
        />
      )}
    </div>
  );
}

FormQuestionFilter.propTypes = {
  activeFilters: PropTypes.shape(),
  filterName: PropTypes.string,
  filterValues: PropTypes.shape({
    question: PropTypes.shape({
      text: PropTypes.string,
      fieldType: PropTypes.string,
      fieldOptions: PropTypes.arrayOf(PropTypes.string),
    }),
    answer: PropTypes.string,
  }),
  onChange: PropTypes.func,
};

FormQuestionFilter.defaultProps = {
  activeFilters: {},
  filterName: null,
  filterValues: {},
  onChange: () => {},
};

function mapStateToProps(state) {
  const { filters } = state;
  const { activeFilters } = filters;
  return { activeFilters };
}

export default connect(mapStateToProps)(FormQuestionFilter);
