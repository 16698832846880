import PropTypes from 'prop-types';
import React from 'react';
import { getSubmission, submitToSona } from '@API/Integrations/SonaAPI';
import { retryableAPICall } from '@API/common-api-utils';
import Integration from './Integration';

const sonaInputConfig = [
  {
    id: 'location',
    label: 'Location',
    type: 'select',
    required: true,
    exclude: true,
    url: 'integration/data/sona/org-unit/Location',
    dataMapper: (data) => {
      return { label: data.label, value: data.value };
    },
  },
  {
    id: 'department',
    label: 'Department',
    type: 'select',
    required: true,
    exclude: true,
    url: 'integration/data/sona/org-unit/Department',
    dataMapper: (data) => {
      return { label: data.label, value: data.value };
    },
  },
  {
    id: 'post',
    label: 'Post',
    type: 'select',
    required: true,
    exclude: true,
    url: 'integration/data/sona/jobs',
    dataMapper: (data) => {
      return { label: data.label, value: data.value };
    },
  },
  {
    id: 'postStartDate',
    label: 'Start Date',
    required: true,
    type: 'date',
  },
  {
    id: 'contractType',
    label: 'Contract Type',
    type: 'select',
    required: true,
    url: 'integration/data/sona/contractTypes',
  },
  {
    id: 'contractHours',
    label: 'Contract Hours',
    type: 'number',
    defaultValue: 0,
    decimalPlaces: 1,
    inputProps: {
      min: 0,
      step: 0.1,
    },
  },
  {
    id: 'contractDays',
    label: 'Contract Days',
    type: 'number',
    required: true,
    decimalPlaces: 0,
    defaultValue: 0,
    inputProps: {
      step: 1,
      min: 0,
      max: 7,
    },
  },
  {
    id: 'salaryValue',
    label: 'Salary/Hourly Rate',
    type: 'number',
    required: true,
    decimalPlaces: 2,
    defaultValue: 0,
    inputProps: {
      min: 1,
      max: 999999999,
      step: 0.01,
    },
  },
  {
    id: 'salaryType',
    label: 'Salary Type',
    type: 'select',
    required: true,
    options: [
      { label: 'Hourly', value: 'hourly' },
      { label: 'Salary', value: 'salaried' },
    ],
  },
];

const labelMap = {
  contractType: 'contractTypeLabel',
  department: 'departmentLabel',
  location: 'locationLabel',
  post: 'postLabel',
};

function valueSetter(url, value, isDisabled, isComplete, type, formData, id) {
  let convertedValue = type === 'number' && !formData[id] ? 0 : type === 'checkbox' ? formData[id] === 1 : value;

  if (isComplete && labelMap[id]) {
    convertedValue = formData[labelMap[id]];
  }

  return { url, value: convertedValue, isDisabled, isComplete, type, formData: { ...formData, [id]: convertedValue } };
}

function SonaForm({ applicantId, candidateProfileSummary }) {
  const formSubmit = async (applicantId, formData) => {
    try {
      const { status, message, ...rest } = await retryableAPICall(() =>
        submitToSona(
          {
            dataFields: {
              location: formData.location,
              department: formData.department,
              post: formData.post,
              postStartDate: formData.postStartDate,
              contractType: formData.contractType,
              contractHours: formData.contractHours,
              contractDays: formData.contractDays,
              salaryValue: formData.salaryValue,
              salaryType: formData.salaryType,
            },
          },
          applicantId,
        ),
      );
      return status === 'NOT_FOUND_ERROR' ? 'NOT_FOUND_ERROR' : { status, message, data: rest };
    } catch (error) {
      console.error('Error during form submission:', error);
      return { status: 'ERROR', message: 'An unexpected error occurred during data fetching.' };
    }
  };

  const handleChange = (id, value, formData, setFormData) => {
    setFormData({ ...formData, [id]: value });
  };

  async function getSonaData(applicantId) {
    try {
      const resp = await retryableAPICall(() => getSubmission(applicantId));

      if (resp === 'NOT_FOUND_ERROR' || resp === '') {
        return 'NOT_FOUND_ERROR';
      }
      const { status, message, supplementaryDataFields } = resp;

      if (status !== 'SUCCESS') {
        return {
          status: status,
          message: message,
          data: supplementaryDataFields.dataFields,
        };
      }

      return {
        status: 'SUCCESS',
        data: supplementaryDataFields.dataFields,
      };
    } catch (error) {
      console.error('Error fetching data:', error);
      return {
        status: 'ERROR',
        message: 'An unexpected error occurred during data fetching.',
      };
    }
  }

  const validationConfig = () => {
    return sonaInputConfig.reduce((acc, { id, type, required, decimalPlaces, inputProps }) => {
      const { min, max } = inputProps || {};
      return [...acc, { id, required, decimalPlaces, ...(type === 'number' ? { type, min, max } : {}) }];
    }, []);
  };
  return (
    <Integration
      title="Sona"
      applicantId={applicantId}
      candidateProfileSummary={candidateProfileSummary}
      integrationName="Sona"
      formConfig={sonaInputConfig}
      formSubmit={formSubmit}
      valueSetter={valueSetter}
      getSubmission={getSonaData}
      componentName="sona"
      customOnChange={handleChange}
      getValidationConfig={validationConfig}
    />
  );
}

SonaForm.propTypes = {
  applicantId: PropTypes.string,
};

SonaForm.defaultProps = {
  applicantId: null,
};

export default SonaForm;
