import PropTypes from 'prop-types';
import { visaTypes } from '@e4s-developers/e4s-form-widgets';

import { randomStr } from '@JS/utils/general-utils';

export const ID_PREFIX = 'atsFlexi';

export const DEFAULT_SCHEMAS = { rules: [], schema: {}, uiSchema: {} };

export const FORM_TYPES = [
  { color: 'primary', type: 'GENERIC', value: 'Flexi-Form' },
  {
    color: 'warning',
    max: 1,
    type: 'ONBOARDING',
    value: 'Onboarding',
  },
  { color: 'success', type: 'INTERVIEW', value: 'Interview' },
  {
    color: 'secondary',
    max: 1,
    type: 'REFERENCE',
    value: 'Reference',
  },
  { color: 'primary', type: 'APPLICATION_FORM', value: 'Application' },
];

export const DEFAULT_QUESTION = {
  config: {},
  id: '',
  number: 0,
  required: false,
  screening: {
    answers: [],
    field: '',
    isScreeningQuestion: false,
  },
  text: '',
  type: undefined,
};

export const DEFAULT_OPTION = {
  checked: false,
  text: '',
};

export const DEFAULT_RULE = {
  rule: '',
  value: 1,
};

export const PATTERNS = {
  alphaNumericOnly: '^[a-zA-Z0-9]*$',
  bankDetailsSortCode: '^\\d{2}-\\d{2}-\\d{2}$',
  digitsOnly: '^\\d*$',
  nin: '^(?!BG)(?!GB)(?!NK)(?!KN)(?!TN)(?!NT)(?!ZZ)(?:[A-CEGHJ-PR-TW-Z][A-CEGHJ-NPR-TW-Z])(?:\\s*\\d\\s*){6}([A-D]|\\s)$',
  ukPostcode: '^[a-zA-Z]{1,2}\\d[a-zA-Z\\d]?\\s*\\d[a-zA-Z]{2}$',
};

export const wrapperRefPropType = PropTypes.oneOfType([
  PropTypes.func,
  PropTypes.shape({
    current: PropTypes.instanceOf(Element),
  }),
]);

export function createID() {
  return `${ID_PREFIX}-${randomStr(8)}`;
}

export function createBaseQuestion() {
  return {
    ...DEFAULT_QUESTION,
    id: createID(),
    number: 1,
  };
}

export function createBaseSection() {
  return {
    id: createID(),
    name: '',
    questions: [{ ...createBaseQuestion() }],
  };
}

export function setFormToOptions(forms) {
  return forms.reduce((acc, { id, name }) => {
    acc.push({ id, label: name });
    return acc;
  }, []);
}

export function countFormTypes(formArr = []) {
  return formArr.reduce((acc, { type }) => {
    if (!acc[type]) acc[type] = 0;
    acc[type] += 1;
    return acc;
  }, {});
}

export function hasUniqueOpts(optsArr) {
  const uniqueText = optsArr.reduce((acc, opt) => {
    const { text } = opt;
    if (!acc.includes(text)) acc.push(text);
    return acc;
  }, []);
  return uniqueText.length === optsArr.length;
}

export function spliceItem(arr, idx, obj) {
  const clone = [...arr];
  if (obj) {
    clone.splice(idx, 1, obj);
  } else {
    clone.splice(idx, 1);
  }
  return clone;
}

function arrayDelete(arr, item, count = 1) {
  const clone = [...arr];
  const idx = clone.indexOf(item);
  if (~idx) clone.splice(idx, count);
  return clone;
}

export function updateArray(entries, arr = [], optionalDelete) {
  return entries.reduce(
    (acc, [key, val]) => {
      let copy = [...acc];
      if (val.status === 'required' && !copy.includes(key)) copy.push(key);
      if (val.status === 'disabled') copy = arrayDelete(copy, key);
      if (optionalDelete && val.status === 'optional') {
        copy = arrayDelete(copy, key);
      }
      return copy;
    },
    [...arr],
  );
}

export function updateObject(entries, obj = {}) {
  return entries.reduce(
    (acc, [key, val]) => {
      if (val.title && acc[key]) acc[key].title = val.title;
      if (val.status === 'disabled') delete acc[key];
      return acc;
    },
    { ...obj },
  );
}

export function moveQuestion(sections = [], from = [0, 0], to = [0, 0]) {
  const clone = [...sections];
  const [fSidx, fQidx] = from;
  const [tSidx, tQidx] = to;

  if (clone[fSidx].questions[fQidx] && clone[tSidx].questions.length > tQidx) {
    // remove existing question
    const question = clone[fSidx].questions.splice(fQidx, 1)[0];
    // add into section array
    clone[tSidx].questions.splice(tQidx, 0, question);
  }

  return clone;
}

const TYPE_LOGIC = {
  boolean: ['Has answered'],
  multi: ['Is One of the following answers', 'Is Not One of the following answers'],
  visaDetails: ['Is One of the following answers', 'Is Not One of the following answers'],
  text: [
    'Is Equal To',
    'Is Not Exactly Equal To',
    'Has answered',
    // 'Contains', For the future
  ],
  // date: [ For the future
  //   'Date After',
  //   'Date Before',
  //   'Date Equal To',
  // ],
};

const TYPE_DICT = {
  // date: 'date', For the future
  address: 'boolean',
  bankDetails: 'boolean',
  bankDetailsUKValidated: 'boolean',
  internationalAddressLookup: 'boolean',
  checkboxes: 'multi',
  date: 'boolean',
  dateOfBirth: 'text',
  dropdown: 'multi',
  dynamicSelect: 'text',
  email: 'text',
  fileUpload: 'boolean',
  largeFileUpload: 'boolean',
  hmrcDeclaration: 'text',
  informationalText: 'text',
  isoDate: 'text',
  multipleChoice: 'multi',
  nin: 'boolean',
  number: 'text',
  phoneNumber: 'text',
  presetDropdown: 'text',
  signature: 'boolean',
  text: 'text',
  textarea: 'text',
  ukPostcode: 'text',
  url: 'text',
  video: 'boolean',
  visaDetails: 'visaDetails',
};

export function getConditionOptionValues(type) {
  return TYPE_DICT[type] ? TYPE_LOGIC[TYPE_DICT[type]] : [];
}

export function getValueInputType(type) {
  return TYPE_DICT[type] ? TYPE_DICT[type] : null;
}

export function setLogicObject(condStr, val = 'empty') {
  switch (condStr) {
    case 'Has answered':
      return { not: { not: val } };
    case 'Is Equal To':
      return { not: { equal: val } };
    case 'Is Not Exactly Equal To':
      return { or: [{ equal: val }, { not: { not: 'empty' } }] };
    case 'Is One of the following answers':
      return { not: { or: val.map((v) => ({ equal: v })) } };
    case 'Is Not One of the following answers':
      return { or: [...val.map((v) => ({ equal: v })), { not: { not: 'empty' } }] };
    default:
      return null;
  }
}

export function getCondition(logic = {}, type) {
  let condition = '';
  let value;

  const condObjs = Object.entries(logic);

  if (condObjs.length) {
    const [cond1, cond2] = condObjs[0];

    if (cond1 === 'not') {
      const logicObjs = Object.entries(cond2);

      if (logicObjs.length) {
        const [cond, val] = logicObjs[0];

        if (cond === 'not') {
          condition = 'Has answered';
          value = 'empty';
        } else if (cond === 'equal') {
          condition = 'Is Equal To';
          value = val;
        } else if (cond === 'or') {
          condition = 'Is One of the following answers';
          value = val.reduce((acc, obj) => [...acc, ...Object.values(obj).map((str) => str)], []);
        }
      }
    } else if (cond1 === 'or' && Array.isArray(cond2)) {
      const inputType = getValueInputType(type);

      condition = 'Is Not One of the following answers';
      value = cond2.reduce(
        (acc, obj) => [...acc, ...Object.values(obj).reduce((acc2, v) => (typeof v === 'string' ? [v] : acc2), [])],
        [],
      );

      if (inputType === 'text') {
        condition = 'Is Not Exactly Equal To';
        value = value.join(',');
      }
    }
  }

  return { condition, value };
}

export function findIndexes(sections, sectionId, questionId) {
  const sIdx = sections.findIndex(({ id }) => id === sectionId);
  const qIdx = sections[sIdx]?.questions.findIndex(({ id }) => id === questionId);

  return {
    questionIndex: qIdx || 0,
    sectionIndex: sIdx || 0,
  };
}

export function getQuestion(sections, sectionId, questionId) {
  const sIdx = sections.findIndex(({ id }) => id === sectionId);
  return sections[sIdx].questions.find(({ id }) => id === questionId);
}

export function updateLogic(sections, sectionId, questionId, logicObject) {
  const { sectionIndex, questionIndex } = findIndexes(sections, sectionId, questionId);
  const question = getQuestion(sections, sectionId, questionId);

  const updatedConfig = {
    ...question.config,
    ...(logicObject ? { logic: { ...logicObject } } : {}),
  };

  if (!logicObject && updatedConfig.logic) {
    delete updatedConfig.logic;
  }

  const updatedQuestion = {
    ...question,
    config: {
      ...updatedConfig,
    },
  };

  const updatedQuestions = spliceItem(sections[sectionIndex].questions, questionIndex, updatedQuestion);
  return spliceItem(sections, sectionIndex, {
    ...sections[sectionIndex],
    questions: updatedQuestions,
  });
}

function bankDetailsSchema(isRequired, config = {}) {
  const { bankDetails } = config;

  const reqArr = ['bankAccountName', 'bankSortCode', 'bankAccNumber'];

  if (bankDetails?.bankName) reqArr.push('bankName');

  return {
    properties: {
      // if bankName needed add to schema
      ...(bankDetails?.bankName && {
        bankName: {
          title: 'Bank Name',
          type: 'string',
        },
      }),
      // bankRefNo: {
      //   type: 'string',
      //   title: 'Bank Reference Number',
      //   pattern: PATTERNS.alphaNumericOnly,
      // },
      bankAccNumber: {
        minLength: 8,
        pattern: PATTERNS.digitsOnly,
        title: 'Bank Account Number',
        type: 'string',
      },
      bankAccountName: {
        title: 'Name of Account Holder',
        type: 'string',
      },
      bankSortCode: {
        pattern: PATTERNS.bankDetailsSortCode,
        title: 'Bank Sort Code',
        type: 'string',
      },
    },
    required: isRequired ? [...reqArr] : [],
    type: 'object',
  };
}

// TODO:
// form description
// * default val for bool and multichoice too?
function propertyLookup(type, items = [], isRequired, config = {}, value = '', attachmentType = 'OTHER') {
  switch (type) {
    case 'dropdown':
    case 'multipleChoice':
      return {
        enum: items,
        type: 'string',
      };
    case 'checkboxes':
      return {
        items: { enum: items, type: 'string' },
        type: 'array',
        uniqueItems: true,
      };
    case 'date':
      return { format: 'date', type: 'string' };
    case 'address':
      return {
        properties: { formattedAddress: { type: 'string' } },
        required: isRequired ? ['formattedAddress'] : [],
        type: 'object',
      };
    case 'internationalAddressLookup':
      return {
        additionalProperties: {
          countryCode: config.countryCode || 'GB',
        },
        properties: {
          formattedAddress: { type: 'string' },
          addressLine1: { type: 'string', minLength: 4 },
          addressLine2: { type: 'string' },
          addressLine3: { type: 'string' },
          city: { type: 'string' },
          county: { type: 'string' },
          postCode: { type: 'string' },
        },
        required: isRequired ? ['formattedAddress', 'addressLine1', 'city', 'postCode'] : [],
        type: 'object',
      };
    case 'video':
      return {
        properties: {
          duration: {
            title: 'duration',
            type: 'number',
          },
          id: {
            title: 'id',
            type: 'string',
          },
          url: {
            title: 'video',
            type: 'string',
          },
        },
        type: 'object',
      };
    case 'bankDetails':
      return { ...bankDetailsSchema(isRequired, config) };
    case 'bankDetailsUKValidated':
      return { ...bankDetailsSchema(isRequired, config) };
    case 'visaDetails':
      return {
        additionalProperties: {
          url: config.url,
        },
        properties: {
          visaExpiryDate: {
            title: 'Visa Expiry Date',
            type: 'string',
          },
          visaNumber: {
            title: 'Visa Number',
            type: 'string',
          },
          visaType: {
            title: 'Visa Type',
            type: 'string',
          },
        },
        required: isRequired ? ['visaType'] : [],
        type: 'object',
      };
    case 'email':
      return { format: 'email', type: 'string' };
    case 'url':
      return { format: 'uri', type: 'string' };
    case 'fileUpload':
      return {
        attachmentType: attachmentType || 'OTHER',
        format: 'data-url',
        isRequired,
        type: 'object',
      };
    case 'largeFileUpload':
      return {
        attachmentType: 'OTHER',
        format: 'data-url',
        isRequired,
        type: 'object',
      };
    case 'cvUpload':
      return {
        attachmentType: 'CV',
        format: 'data-url',
        type: 'object',
      };
    case 'signature':
      return { format: 'data-url', type: 'string' };
    case 'informationalText':
      return { type: 'string', value };
    case 'phoneNumber':
      return { maxLength: 15, minLength: 8, type: 'string' };
    case 'nin':
      return { pattern: PATTERNS.nin, type: 'string' };
    case 'ukPostcode':
      return {
        maxLength: 8,
        minLength: 6,
        pattern: PATTERNS.ukPostcode,
        type: 'string',
      };
    case 'number':
      return { type: 'number' };
    case 'dynamicSelect':
      return {
        additionalProperties: {
          url: config.url,
        },
        type: 'string',
      };
    case 'presetDropdown':
    case 'dateOfBirth':
    case 'isoDate':
    case 'hmrcDeclaration':
    default:
      return { type: 'string' };
  }
}

function typeLookup(field = {}, props = {}) {
  const formatDict = {
    'data-url': 'fileUpload',
    date: 'date',
    email: 'email',
    uri: 'url',
  };

  const widgetDict = {
    checkboxes: 'checkboxes',
    dateWidget: 'date',
    dobWidget: 'dateOfBirth',
    dynamicSelect: 'dynamicSelect',
    hidden: 'hidden',
    hmrcWidget: 'hmrcDeclaration',
    isoDateWidget: 'isoDate',
    ninWidget: 'nin',
    phoneNumber: 'phoneNumber',
    postcodeWidget: 'ukPostcode',
    presetDropdown: 'presetDropdown',
    radio: 'multipleChoice',
    select: 'dropdown',
    signature: 'signature',
    textarea: 'textarea',
  };

  const fieldDict = {
    addressLookup: 'address',
    internationalAddressLookup: 'internationalAddressLookup',
    bankDetails: 'bankDetails',
    bankDetailsUKValidated: 'bankDetailsUKValidated',
    fileUpload: 'fileUpload',
    largeFileUpload: 'largeFileUpload',
    informationalText: 'informationalText',
    video: 'video',
    visaDetails: 'visaDetails',
  };

  if (Object.keys(field).length) {
    const fieldProps = props[field.id];
    const isWidget = !!(fieldProps && fieldProps['ui:widget']);
    const isField = !!(fieldProps && fieldProps['ui:field']);

    if (field.format && !isWidget && !isField) return formatDict[field.format];
    if (field.type) {
      if (field.type === 'array' && field.items) return 'checkboxes';

      if (field.type === 'string' || field.type === 'object') {
        if (fieldProps) {
          if (isWidget && widgetDict[props[field.id]['ui:widget']]) {
            return widgetDict[props[field.id]['ui:widget']];
          }

          if (isField && fieldDict[props[field.id]['ui:field']]) {
            return fieldDict[props[field.id]['ui:field']];
          }
        }
      } else if (field.type === 'number') {
        return 'number';
      }
    }
  }

  return 'text';
}

function setConfig(type, field, props = {}) {
  let config = {};

  if (props[field.id] && props[field.id]['ui:options']) {
    config = {
      ...config,
      ...Object.entries(props[field.id]['ui:options']).reduce((acc, [key, val]) => {
        acc[key] = val;
        return acc;
      }, {}),
    };
  }

  if (props[field.id] && props[field.id].classNames) {
    config = {
      ...config,
      classNames: props[field.id].classNames.replace('is-sensitive', '').trim(),
    };
  }

  if (/checkboxes|multipleChoice|dropdown/.test(type)) {
    let options = [];
    if (field.enum) options = field.enum;
    if (field?.items?.enum) options = field.items.enum;

    config = {
      ...config,
      options: options.map((opt, i) => ({ checked: false, id: `${field.id}-${i}`, text: opt })),
    };
  }

  if (/checkboxes|text/.test(type)) {
    const { maxLength, minLength, maxItems, minItems, pattern } = field;

    const rule = {
      ...(maxLength ? { maxLength } : {}),
      ...(minLength ? { minLength } : {}),
      ...(maxItems ? { maxItems } : {}),
      ...(minItems ? { minItems } : {}),
    };

    config = {
      ...config,
      ...(typeof pattern === 'string' ? { pattern } : {}),
      ...(Object.keys(rule).length ? { rule } : {}),
    };
  }

  if (type === 'bankDetails' || type === 'bankDetailsUKValidated') {
    const {
      properties: { bankName },
    } = field;

    config = {
      ...config,
      bankDetails: {
        bankName: !!bankName,
      },
    };
  }

  if (/visaDetails/.test(type)) {
    config = {
      ...config,
      options: visaTypes.map((opt) => ({ checked: false, id: opt.visa_type_id, text: opt.visa_common_name })),
    };
  }

  return config;
}

function fieldLookup(type, id, config) {
  const widgetDict = {
    // isoDate: 'isoDateWidget',
    checkboxes: 'checkboxes',
    dateOfBirth: 'dobWidget',
    dropdown: 'select',
    hmrcDeclaration: 'hmrcWidget',
    multipleChoice: 'radio',
    phoneNumber: 'phoneNumber',
    signature: 'signature',
    textarea: 'textarea',
    ukPostcode: 'postcodeWidget',
  };

  let uiOpts = {};
  let isSensitive = false;
  let isRestricted = false;

  if (config) {
    isSensitive = config.sensitive;
    isRestricted = config.restricted;

    if (isSensitive || isRestricted) {
      // for fields that don't have other ui:options objects
      uiOpts = {
        'ui:options': {
          ...(isSensitive ? { sensitive: isSensitive } : {}),
          ...(isRestricted ? { restricted: isRestricted } : {}),
        },
      };
    }

    if (config.countryCode) {
      uiOpts = {
        'ui:options': {
          ...uiOpts['ui:options'],
          countryCode: config.countryCode,
        },
      };
    }
  }

  const hasClassNames = config && config.classNames && config.classNames.length;
  let classNames = {};
  if (hasClassNames) classNames = { classNames: config.classNames };
  if (isSensitive) {
    classNames = {
      classNames: `is-sensitive${hasClassNames ? ` ${config.classNames}` : ''}`,
    };
  }

  let widget;
  if (widgetDict[type]) widget = widgetDict[type];
  if (widget) {
    return {
      [id]: {
        'ui:widget': widget,
        ...uiOpts,
        ...classNames,
      },
    };
  }

  switch (type) {
    case 'date':
      return {
        [id]: {
          'ui:options': {
            currentDateOnly: config.currentDateOnly || false,
            limitToFutureDates: config.limitToFutureDates || false,
            limitToPastDates: config.limitToPastDates || false,
            ...uiOpts['ui:options'],
          },
          'ui:widget': 'dateWidget',
          ...classNames,
        },
      };
    case 'isoDate':
      return {
        [id]: {
          'ui:options': {
            currentDateOnly: config.currentDateOnly || false,
            limitToFutureDates: config.limitToFutureDates || false,
            limitToPastDates: config.limitToPastDates || false,
            ...uiOpts['ui:options'],
          },
          'ui:widget': 'isoDateWidget',
          ...classNames,
        },
      };
    case 'address':
      return {
        [id]: {
          'ui:field': 'addressLookup',
          ...uiOpts,
          ...classNames,
        },
      };
    case 'internationalAddressLookup':
      return {
        [id]: {
          'ui:options': {
            countryCode: config.countryCode || 'GB',
            ...uiOpts['ui:options'],
          },
          'ui:field': 'internationalAddressLookup',
          ...classNames,
        },
      };
    case 'url':
      return {
        [id]: {
          'ui:placeholder': 'http://',
          ...uiOpts,
          ...classNames,
        },
      };
    case 'video':
      return {
        [id]: {
          'ui:field': 'video',
          'ui:help': 'Limited to 2 minutes',
          'ui:options': {
            download: config.download || false,
            ...uiOpts['ui:options'],
          },
          ...classNames,
        },
      };
    case 'bankDetails':
      return {
        [id]: {
          classNames: `is-sensitive${hasClassNames ? ` ${config.classNames}` : ''}`,
          'ui:field': 'bankDetails',
          'ui:options': {
            ...uiOpts['ui:options'],
            charLimits: {
              bankAccNumber: 8,
              bankAccountName: 50,
              bankName: 100,
            },
            sensitive: true,
          },
        },
      };
    case 'bankDetailsUKValidated':
      return {
        [id]: {
          classNames: `is-sensitive${hasClassNames ? ` ${config.classNames}` : ''}`,
          'ui:field': 'bankDetailsUKValidated',
          'ui:options': {
            ...uiOpts['ui:options'],
            charLimits: {
              bankAccNumber: 8,
              bankAccountName: 50,
              bankName: 100,
            },
            sensitive: true,
          },
        },
      };
    case 'visaDetails':
      return {
        [id]: {
          'ui:field': 'visaDetails',
          'ui:options': {
            url: config.url || '',
            ...uiOpts['ui:options'],
          },
          ...classNames,
        },
      };
    case 'nin':
      return {
        [id]: {
          classNames: `is-sensitive${hasClassNames ? ` ${config.classNames}` : ''}`,
          'ui:options': {
            ...uiOpts['ui:options'],
            sensitive: true,
          },
          'ui:widget': 'ninWidget',
        },
      };
    case 'dynamicSelect':
      return {
        [id]: {
          'ui:options': {
            url: config.url || '',
            ...uiOpts['ui:options'],
          },
          'ui:widget': 'dynamicSelect',
          ...classNames,
        },
      };
    case 'presetDropdown':
      return {
        [id]: {
          'ui:options': {
            dropdownType: config.dropdownType || 'maritalStatusBasic',
            ...uiOpts['ui:options'],
          },
          'ui:widget': 'presetDropdown',
          ...classNames,
        },
      };
    case 'informationalText':
      return {
        [id]: {
          'ui:field': 'informationalText',
          'ui:options': {
            ...uiOpts['ui:options'],
          },
          ...classNames,
        },
      };
    case 'fileUpload':
      return {
        [id]: {
          'ui:field': 'fileUpload',
          ...uiOpts,
          ...classNames,
        },
      };
    case 'largeFileUpload':
      return {
        [id]: {
          'ui:field': 'largeFileUpload',
          ...uiOpts,
          ...classNames,
        },
      };
    default:
      return hasClassNames || Object.keys(uiOpts).length
        ? {
            [id]: {
              ...uiOpts,
              ...classNames,
            },
          }
        : undefined;
  }
}

function findQuestion(arr, sid, qid) {
  // Find an object where the id matches sid
  const section = arr.find((item) => item.id === sid);

  // If we found such an object and it has a questions property...
  if (section && Array.isArray(section.questions)) {
    // Find a question where the id matches qid
    const question = section.questions.find((item) => item.id === qid);
    return question;
  }

  return null;
}

function createSchemaRule(logicObject, sId, qId, allSections) {
  if (logicObject && Object.keys(logicObject).length) {
    const {
      conditions: {
        field: { sectionId, questionId },
        logic = {},
      },
      type,
    } = logicObject;

    let condition = `${sectionId}.${questionId}`;
    const conditionQuestion = findQuestion(allSections, sectionId, questionId);

    if (conditionQuestion && conditionQuestion.type === 'visaDetails') {
      condition = `${condition}.visaType`;
    }

    return {
      conditions: {
        [condition]: {
          ...logic,
        },
      },
      event: {
        params: {
          field: `${sId}.${qId}`,
        },
        type,
      },
    };
  }

  return {};
}

function createScreeningLogic(answer, field, hide, currentId) {
  return [
    {
      conditions: {
        [currentId]: {
          or: answer.map((a) => {
            return { equal: a };
          }),
        },
      },
      event: {
        params: {
          field: hide,
        },
        type: 'remove',
      },
    },
    {
      conditions: {
        [currentId]: {
          or: answer.map((a) => {
            return { equal: a };
          }),
        },
      },
      event: {
        params: {
          field: [field],
        },
        type: 'screeningDecision',
      },
    },
  ];
}

function createScreeningRules(items, id) {
  if (items.length > 0) {
    const relatedToQuestion = items.filter((a) => {
      const questionKey = Object.keys(a.conditions).map((key) => {
        return key.split('.')[1];
      })[0];
      return questionKey === id;
    });

    if (relatedToQuestion.length > 0) {
      const screenEvent = relatedToQuestion.find((a) => {
        return a.event.type === 'screeningDecision';
      });

      const removeEvent = relatedToQuestion.find((a) => {
        return a.event.type === 'remove';
      });

      return {
        answers: Object.values(removeEvent.conditions)[0].or.map((a) => a.equal),
        field: Array.isArray(removeEvent.event.params.field) ? screenEvent.event.params.field[0] : '',
        isScreeningQuestion: true,
      };
    }
  }

  return { answers: [], field: '', isScreeningQuestion: false };
}

export function questionsToSchema(questions, sectionIdx, sectionId, sectionsIdsAfterCurrent, sections) {
  const required = [];
  let properties = {};
  const order = [];
  let fields = {};
  const logicRules = [];
  const screeningRules = [];

  let questionIdsAfterCurrent = questions.map((a) => `${sectionId}.${a.id}`);

  if (sectionIdx === 0) {
    properties = { cid: { type: 'string' }, iid: { type: 'string' }, rid: { type: 'string' } };
    order.push('cid');
    order.push('rid');
    order.push('iid');
    fields = {
      cid: { 'ui:widget': 'hidden' },
      iid: { 'ui:widget': 'hidden' },
      rid: { 'ui:widget': 'hidden' },
    };
  }

  questions.forEach((question) => {
    // remove current index
    questionIdsAfterCurrent = questionIdsAfterCurrent.filter((a) => a !== `${sectionId}.${question.id}`);

    const options = question.config?.options?.map(({ text }) => text || '');
    const props = propertyLookup(
      question.type,
      options,
      question.required,
      question.config,
      question.value,
      question.attachmentType,
    );
    const field = fieldLookup(question.type, question.id, question.config);

    const logic = createSchemaRule(question.config?.logic, sectionId, question.id, sections);

    properties[question.id] = {
      ...props,
      title: question.text || '',
      ...(question.config?.rule || {}),
      ...(typeof question?.config?.pattern === 'string' ? { pattern: question.config?.pattern } : {}),
    };

    if (question.required) required.push(question.id);
    order.push(question.id);

    if (question?.screening?.isScreeningQuestion) {
      const newRules = createScreeningLogic(
        question.screening.answers,
        question.screening.field,
        [...sectionsIdsAfterCurrent, ...questionIdsAfterCurrent],
        `${sectionId}.${question.id}`,
      );

      screeningRules.push(...newRules);

      properties[question.id].screening = {
        answers: question.screening.answers,
        field: question.screening.field,
        isScreeningQuestion: true,
      };
    }

    if (field) fields = { ...fields, ...field };

    if (Object.keys(logic).length) logicRules.push(logic);
  });

  return {
    fields,
    logicRules,
    order,
    properties,
    required,
    screeningRules,
  };
}

export function createSchemas(sections, name) {
  const sectionFields = {};
  let logRules = [];
  let screenRules = [];

  let sectionsIdsAfterCurrent = sections.map((a) => a.id);

  const sectionObj = sections.reduce((acc, { id, name: sName, questions }, i) => {
    if (!acc[id]) {
      // remove current index
      sectionsIdsAfterCurrent = sectionsIdsAfterCurrent.filter((a) => a !== id);

      const { required, properties, order, fields, logicRules, screeningRules } = questionsToSchema(
        questions,
        i,
        id,
        sectionsIdsAfterCurrent,
        sections,
      );

      acc[id] = {
        properties: { ...properties },
        required,
        title: sName,
        type: 'object',
      };

      sectionFields[id] = {
        'ui:order': order,
        ...fields,
      };

      // It is very important that logic qustions go before screening
      // we cannot apply logic to areas that have been hidden.
      logRules = [...logRules, ...logicRules];
      screenRules = [...screenRules, ...screeningRules];
    }
    return acc;
  }, {});

  const schema = {
    properties: { ...sectionObj },
    title: name,
    type: 'object',
  };

  const uiSchema = {
    'ui:order': Object.keys(sectionObj),
    ...sectionFields,
  };

  return { rules: [...logRules, ...screenRules], schema, uiSchema };
}

function orderFields(order = [], fields = {}, required = []) {
  return order.reduce((acc, id, i) => {
    if (fields[id]) {
      const isObjectType = false;
      // object types worry about their own required array
      // NB. This may need to become more complex if some question types
      // have configurable required sub fields this was set up for address
      // address has a single field that triggers required or not
      const objectReq = fields[id].required || [];
      const isInReq = required.includes(id);

      acc.push({
        ...fields[id],
        id,
        number: i + 1,
        required: isObjectType && !isInReq ? !!objectReq.length : isInReq,
      });
    }
    return acc;
  }, []);
}

function createLogic(logicRules, questionId) {
  return logicRules.reduce((acc, { conditions, event }) => {
    const {
      type,
      params: { field },
    } = event;
    // Coz Server! ¯\_(ツ)_/¯
    const eventField = Array.isArray(field) ? field[0] : field;

    if (eventField.includes(questionId)) {
      const conditionConfig = Object.entries(conditions).reduce((cond, [key, valObj]) => {
        const [sectionId, qId] = key.split('.');

        return {
          field: {
            questionId: qId,
            sectionId,
          },
          logic: { ...valObj },
        };
      }, {});

      return {
        conditions: conditionConfig,
        type,
      };
    }

    return acc;
  }, {});
}

function createQuestions(schema = {}, uiSchema = {}, logicRules = []) {
  const { properties = {}, required = [] } = schema;
  const { 'ui:order': order = [] } = uiSchema;

  const orderedFields = orderFields(order, properties, required);

  let questions = orderedFields.reduce((acc, field) => {
    const { id, title, value, required: req } = field;

    const type = typeLookup(field, uiSchema);
    const containsScreening = logicRules?.map((e) => e.event.type).includes('screeningDecision');
    const screeningQuestions = logicRules?.reduce((memo, e) => {
      if (e.event.type === 'screeningDecision') {
        memo.push(Object.keys(e.conditions)[0]);
      }
      return memo;
    }, []);

    // we need to seperate out the logic of hiding from the screening hiding
    const [screeningRules, logicR] = logicRules.reduce(
      (memo, event) => {
        if (
          containsScreening &&
          (screeningQuestions.includes(Object.keys(event.conditions)[0]) || event.event.type === 'screeningDecision')
        ) {
          memo[0].push(event);
        } else {
          memo[1].push(event);
        }
        return memo;
      },
      [[], []],
    );

    const logic = createLogic(logicR, id);

    const screening = createScreeningRules(screeningRules, id);

    if (type !== 'hidden') {
      const conf = {
        config: {
          ...setConfig(type, field, uiSchema),
          ...(Object.keys(logic).length ? { logic } : {}),
        },
        id,
        required: req,
        screening: {
          answers: screening?.isScreeningQuestion ? screening?.answers : [],
          field: screening?.isScreeningQuestion ? screening.field : '',
          isScreeningQuestion: screening?.isScreeningQuestion,
        },
        text: title,
        type,
        value,
      };

      if (field.attachmentType) {
        conf.attachmentType = field.attachmentType;
      }

      acc.push(conf);
    }

    return acc;
  }, []);

  if (!questions.length) questions = [{ ...createBaseQuestion() }];

  return questions;
}

export function createSections(formSchemas) {
  const { schema = {}, uiSchema = {}, rules = [] } = formSchemas;
  const { properties = {} } = schema;
  const { 'ui:order': order = [] } = uiSchema;

  let sections = order.reduce((acc, id) => {
    if (properties[id]) {
      const isSection = properties[id].type === 'object';
      const isHidden = !isSection && typeLookup({ ...properties[id], id }, uiSchema) === 'hidden';

      const sectionSchema = isSection ? properties[id] : schema;
      const sectionUiSchema = isSection ? uiSchema[id] : uiSchema;

      const name = isSection ? properties[id].title : '';
      const questions = createQuestions(sectionSchema, sectionUiSchema, rules);
      const previewEnabled = questions.every((q) => {
        if (q.config.options) {
          return hasUniqueOpts(q.config.options) && q.config.options.every((op) => !!op.text.length);
        }
        return q.text && q.text !== '' && q.type;
      });

      if (!isHidden) {
        acc.push({
          id,
          name,
          previewEnabled,
          questions,
        });
      }
    }
    return acc;
  }, []);

  if (!sections.length) sections = [{ ...createBaseSection() }];

  return sections;
}
