/* eslint-disable import/no-unresolved */
/* eslint-disable jsx-a11y/label-has-for */
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import Iframe from 'react-iframe';
import { Col } from 'reactstrap';
import config from '../../../../../../../config/config';
import { ErrorMessage } from '@Base/ErrorScreen';
import ScreenSizeSelector from '@Base/ScreenSizeSelector';

const {
  forms: { genericStylingForm },
} = config;

function FormStylesIFrame({ onIframeLoaded, iframeState }) {
  const [isBigEnough, setIsBigEnough] = useState(false);
  const [width, setWidth] = useState(500);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 1500) {
        setIsBigEnough(true);
      } else if (window.innerWidth < 1500) {
        setIsBigEnough(false);
      }
    };
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <Col>
      {iframeState === 'FAILED' && (
        <div className="iframe-error-message min-vh-100 d-flex position-absolute m-auto bg-white py-5">
          <ErrorMessage error={500} />
        </div>
      )}
      <div
        className="w-100 min-vh-100 d-flex py-5 form-editor-background overflow-auto"
        styles={{ opacity: iframeState === 'SUCCESS' ? 1 : 0 }}
      >
        <Iframe
          allow="camera;microphone"
          frameBorder="0"
          height="1px"
          id="form"
          onLoad={onIframeLoaded}
          seamless="seamless"
          src={genericStylingForm}
          styles={{ margin: 'auto', height: '80vh', paddingLeft: '20px', paddingRight: '20px' }}
          width={isBigEnough ? width : 400}
        />
        {isBigEnough ? (
          <div style={{ top: '75vh', left: 35, position: iframeState === 'SUCCESS' ? 'absolute' : 'block' }}>
            <ScreenSizeSelector initialWidth={650} onChange={(val) => setWidth(val)} />
          </div>
        ) : null}
      </div>
    </Col>
  );
}

FormStylesIFrame.propTypes = {
  iframeState: PropTypes.string,

  onIframeLoaded: PropTypes.func,
};

FormStylesIFrame.defaultProps = {
  iframeState: 'UNKNOWN',
  onIframeLoaded: () => {},
};

export default FormStylesIFrame;
