import { Component } from 'react';
import React from 'react';
import Select from 'react-select';
import { getCommonHeaders, retryableAPICall } from '@API/common-api-utils';
import axios from 'axios';
import config from '../../../../../config/config';

const { api } = config;

class DDSelect extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedOpt: undefined,
      data: this.props.schema.data ? this.props.schema.data : [],
    };

    this.handleChange = this.handleChange.bind(this);
    this.getData = this.getData.bind(this);
  }

  handleChange(options) {
    let val;

    if (typeof options === 'object' && !Array.isArray(options)) {
      val = options;
    } else {
      val = options && options.length > 0 ? options : undefined;
    }

    this.setState({ selectedOpt: val });
    if (!val) {
      this.props.onChange(undefined);
    } else if (this.props.schema.isMultiple) {
      this.props.onChange(val.map((opt) => opt.value));
    } else {
      this.props.onChange(val.value);
    }
  }

  async getData() {
    let data = [];
    // If this item has the path set in the schema then it is a remote call

    if (this.props.schema.path !== undefined && this.state.data.length === 0) {
      let response = await retryableAPICall(async () => {
        let result = await axios.get(`${api.middlewareAPIURL}${this.props.schema.path}`, {
          headers: getCommonHeaders(),
        });

        if (result.status === 200) {
          return result.data;
        }
      });

      if (response !== undefined) {
        Object.keys(response).forEach((key) => data.push({ value: key, label: response[key] }));
      }

      this.props.schema['data'] = data;
    } else if (this.props.schema.data) {
      data = this.props.schema.data;
    } else {
      data = this.state.data;
    }

    this.setState({ data: data }, () => {
      let selectedOpt;

      if (this.props.schema.isMultiple && this.props.formData && Array.isArray(this.props.formData)) {
        let items = this.props.formData.map((value) => data.find((item) => item.value === value));
        if (items.length === 0) {
          selectedOpt = undefined;
        } else {
          selectedOpt = items;
        }
      } else if (!this.props.formData && this.props.schema.data.length === 1) {
        selectedOpt = this.props.schema.data[0];
        this.handleChange(this.props.schema.data[0]);
      } else {
        selectedOpt =
          this.props.formData && Object.keys(this.props.formData.toString()).length > 0
            ? data.find((item) => item.value === this.props.formData)
            : undefined;
      }
      this.setState({ selectedOpt: selectedOpt });
    });
  }

  componentDidMount() {
    let selectedOpt;

    if (this.props.schema.isMultiple && this.props.formData) {
      selectedOpt = this.state.data.filter((item) => this.props.formData.find((data) => item.value === data));
    } else {
      selectedOpt =
        this.props.formData && Object.keys(this.props.formData.toString()).length > 0
          ? this.state.data.find((item) => item.value === this.props.formData)
          : undefined;
      if (!selectedOpt && !this.props.formData && this.state.data.length === 1) this.handleChange(this.state.data[0]);
      selectedOpt = [selectedOpt];
    }

    this.setState({ selectedOpt });
  }

  render() {
    let defaultVal;

    if (this.state.selectedOpt) {
      defaultVal = this.state.selectedOpt;
    } else if (this.props.schema.default) {
      defaultVal = this.props.schema.data.find((dataObj) => dataObj.value === this.props.schema.default);
      setTimeout(() => this.handleChange(defaultVal), 10);
    }

    return (
      <React.Fragment>
        {this.props.schema && (
          <label className="control-label" htmlFor="root_referenceCode">
            {this.props.schema.title}
            {this.props.required && <span className="required">*</span>}
          </label>
        )}
        <span id={this.props.name}>
          <Select
            value={defaultVal}
            onChange={this.handleChange}
            options={this.state.data}
            isMulti={this.props.schema.isMultiple ? this.props.schema.isMultiple : false}
            classNamePrefix="react-select"
            defaultValue={defaultVal}
            isDisabled={this.props.readonly}
          />
        </span>
      </React.Fragment>
    );
  }
}

export default DDSelect;
