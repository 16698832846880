import React from 'react';
import PropTypes from 'prop-types';
import { FormGroup, Label } from 'reactstrap';
import Select from 'react-select';
import { checkPermissions } from '../../../../../../../../../js/auth/AuthUtils';

function SendIntegrationInput({ option, setOption }) {
  const availableIntegrations = [];
  const intergrationOperation = [
    { label: 'Create Employee', value: 'CREATE' },
    { label: 'Delete Employee', value: 'DELETE' },
  ];
  if (checkPermissions(['ubeya:admin'])) {
    availableIntegrations.push({ label: 'Ubeya', value: 'UBEYA' });
  }
  if (checkPermissions(['sap:write'])) {
    availableIntegrations.push({ label: 'SAP', value: 'SAP' });
  }

  if (checkPermissions(['flow:write'])) {
    availableIntegrations.push({ label: 'Flow', value: 'FLOW' });
  }

  if (checkPermissions(['flow:write'])) {
    availableIntegrations.push({ label: 'Mapal', value: 'MAPAL' });
  }

  return (
    <>
      <FormGroup required>
        <Label>
          Status<span className="required">*</span>
        </Label>
        <Select
          className="react-select"
          placeholder="Select Status"
          options={availableIntegrations}
          value={availableIntegrations.find((a) => a.value === option?.integrationType)}
          onChange={(val) => setOption({ integrationType: val.value })}
        />
      </FormGroup>
      {(option?.integrationType === 'FLOW' || option?.integrationType === 'MAPAL') && (
        <FormGroup required>
          <Label>
            Operation<span className="required">*</span>
          </Label>
          <Select
            className="react-select"
            placeholder="Select Status"
            options={intergrationOperation}
            value={intergrationOperation.find((a) => a.value === option?.operation)}
            onChange={(val) => setOption({ operation: val.value })}
          />
        </FormGroup>
      )}
    </>
  );
}

SendIntegrationInput.propTypes = {
  option: PropTypes.shape(),
  setOption: PropTypes.func,
};

SendIntegrationInput.defaultProps = {
  option: {},
  setOption: () => {},
};

export default SendIntegrationInput;
