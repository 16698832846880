import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { ModalPopup, Confirmation } from '@Base/Modal';
import { DeleteButton } from '@Base/Buttons';

import LogicQuestionSelect from './LogicQuestionSelect';
import LogicConditionSelect from './LogicConditionSelect';
import LogicValueInput from './LogicValueInput';
import { updateLogic, getQuestion, setLogicObject, getCondition } from '../utils';
import ErrorMessage from './ErrorMessage';

function LogicModal({ isOpen, onClose, sections, currentQuestion, onChange }) {
  const [selectedQuestion, setSelectedQuestion] = useState('');
  const [selectedQType, setSelectedQType] = useState();
  const [selectedQOpts, setSelectedQOpts] = useState([]);
  const [selectedCondition, setSelectedCondition] = useState();
  const [logicValue, setLogicValue] = useState();
  const [showDeleteBtn, setShowDeleteBtn] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isScreeningQuestion, setIsScreeningQuestion] = useState(false);

  const { sectionId, questionId, logic } = currentQuestion;

  useEffect(() => {
    if (logic && Object.keys(logic).length) {
      const {
        field: { sectionId: sId, questionId: qId },
        logic: logicObj,
      } = logic.conditions;

      const { type, config } = getQuestion(sections, sId, qId);
      const { condition, value } = getCondition(logicObj, type);
      const multiOpts = config?.options || [];

      setSelectedQuestion(`${sId}.${qId}`);
      setSelectedQType(type);
      setSelectedQOpts(multiOpts);
      setSelectedCondition(condition);
      setLogicValue(value);
      setShowDeleteBtn(true);
    }
  }, [logic, sections]);

  function reset() {
    setSelectedQuestion('');
    setIsScreeningQuestion(false);
    setSelectedQType();
    setSelectedQOpts([]);
    setSelectedCondition();
    setShowDeleteBtn(false);
    setIsSubmitted(false);
  }

  function handleQuestionChange(q) {
    const { type, value } = q;
    const [sId, qId] = value.split('.');
    const { config, screening } = getQuestion(sections, sId, qId);

    const multiOpts = config?.options || [];

    setIsScreeningQuestion(screening.isScreeningQuestion);
    setSelectedQuestion(value);

    // If we are showing a screening question do let them
    // attempt to create logic
    if (!screening.isScreeningQuestion) {
      setSelectedQType(type);
      setSelectedQOpts(multiOpts);
    }
  }

  function handleOkay() {
    setIsSubmitted(true);

    if (Object.keys(selectedQuestion).length && selectedCondition && logicValue && logicValue.length) {
      const [sId, qId] = selectedQuestion.split('.');

      const conditionLogic = setLogicObject(selectedCondition, logicValue);

      let newQuestionId = qId;

      // Visa needs to look at the visa type child prop
      if (selectedQType === 'visaDetails') {
        newQuestionId += '.visaType';
      }

      const logicObj = {
        conditions: {
          field: {
            questionId: newQuestionId,
            sectionId: sId,
          },
          logic: conditionLogic,
        },
        type: 'remove',
      };

      const updatedSections = updateLogic(sections, sectionId, questionId, logicObj);

      onChange(updatedSections, sectionId, questionId);
      onClose();
      reset();
    }
  }

  function handleDelete() {
    const updatedSections = updateLogic(sections, sectionId, questionId);
    onChange(updatedSections, sectionId, questionId);
    reset();
    onClose();
  }

  return (
    <Fragment>
      <ModalPopup
        isOpen={isOpen}
        onToggle={() => {
          onClose();
          reset();
        }}
        title="Logic"
        okayLabel="Save Logic"
        onOkay={handleOkay}
        closeOnOkay={false}
        footer={({ OkayButton, CancelButton }) => (
          <Fragment>
            {showDeleteBtn && (
              <DeleteButton
                label="Delete Logic"
                floatRight={false}
                className="me-auto"
                action={() => setShowConfirm(true)}
              />
            )}
            <CancelButton />
            {!isScreeningQuestion && <OkayButton />}
          </Fragment>
        )}
      >
        <div className="logic-wrapper">
          <div className="mb-2 logic-input-wrapper">
            <LogicQuestionSelect
              sections={sections}
              sectionId={sectionId}
              questionId={questionId}
              value={selectedQuestion}
              onChange={handleQuestionChange}
            />
            <ErrorMessage isInvalid={isSubmitted && !selectedQuestion.length}>Question Required</ErrorMessage>
          </div>
          <div className="mb-2 logic-input-wrapper">
            {!isScreeningQuestion && (
              <LogicConditionSelect
                type={selectedQType}
                value={selectedCondition}
                onChange={(condStr) => {
                  setSelectedCondition(condStr);
                  if (condStr === 'Has answered') setLogicValue('empty');
                }}
              />
            )}
            <ErrorMessage isInvalid={isSubmitted && !selectedCondition}>Condition Required</ErrorMessage>
          </div>
          <div className="logic-input-wrapper">
            {!isScreeningQuestion && (
              <LogicValueInput
                type={selectedQType}
                condition={selectedCondition}
                multiOptions={selectedQOpts}
                value={logicValue === 'empty' ? '' : logicValue}
                onChange={setLogicValue}
              />
            )}
            <ErrorMessage isInvalid={isSubmitted && (!logicValue || !logicValue.length)}>Value Required</ErrorMessage>
          </div>
          <ErrorMessage isInvalid={isScreeningQuestion}>Cannot apply logic to a screening question</ErrorMessage>
        </div>
      </ModalPopup>
      <Confirmation
        title="Delete Logic?"
        show={showConfirm}
        content="Are you sure you want to delete the logic?"
        confirmCallback={() => {
          setShowConfirm(false);
          handleDelete();
        }}
        cancelCallback={() => setShowConfirm(false)}
      />
    </Fragment>
  );
}

LogicModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  sections: PropTypes.arrayOf(PropTypes.shape()),
  currentQuestion: PropTypes.shape({
    sectionId: PropTypes.string,
    questionId: PropTypes.string,
    logic: PropTypes.shape({
      type: PropTypes.string,
      conditions: PropTypes.shape({
        field: PropTypes.shape({
          sectionId: PropTypes.string,
          questionId: PropTypes.string,
        }),
        logic: PropTypes.shape(),
        value: PropTypes.oneOfType([
          PropTypes.string,
          PropTypes.number,
          PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
        ]),
      }),
    }),
  }),
  onChange: PropTypes.func,
};

LogicModal.defaultProps = {
  isOpen: false,
  onClose: () => {},
  sections: [],
  currentQuestion: {
    sectionIndex: 0,
    questionIndex: 0,
    logic: {},
  },
  onChange: () => {},
};

export default LogicModal;
