/* eslint-disable import/no-unresolved */
/* eslint-disable jsx-a11y/label-has-for */
import PropTypes from 'prop-types';
import React from 'react';
import InputRange from 'react-input-range';
import { Col, FormGroup, Label } from 'reactstrap';
import { SelectInput } from '@Base/Forms/Custom/CommonComponents';

function Sections({ currentMUITheme, customFonts, changeStyle }) {
  return (
    <>
      <FormGroup>
        <Label for="logo">Font</Label>
        <SelectInput
          onChange={(val) => {
            changeStyle('typography.h5.fontFamily', val);
          }}
          options={customFonts.map((a) => {
            return { label: a.url, value: a.url };
          })}
          value={currentMUITheme?.typography?.h5.fontFamily}
        />
      </FormGroup>
      <FormGroup>
        <Label for="logo">Font Size</Label>
        <Col className="w-75 py-3 ms-2">
          <InputRange
            maxValue={48}
            minValue={16}
            onChange={(val) => {
              changeStyle('typography.h5.fontSize', val);
            }}
            value={currentMUITheme?.typography?.h5.fontSize}
          />
        </Col>
      </FormGroup>
      <FormGroup>
        <Label for="logo">Font Weight</Label>
        <Col className="w-75 py-3 ms-2">
          <InputRange
            maxValue={1000}
            minValue={100}
            onChange={(val) => {
              changeStyle('typography.h5.fontWeight', val);
            }}
            step={100}
            value={currentMUITheme?.typography?.h5.fontWeight}
          />
        </Col>
      </FormGroup>
      <FormGroup>
        <Label for="logo">Line Height</Label>
        <Col className="w-75 py-3 ms-2">
          <InputRange
            maxValue={3}
            minValue={0.5}
            onChange={(val) => {
              changeStyle('typography.h5.lineHeight', val);
            }}
            step={0.1}
            value={currentMUITheme?.typography?.h5.lineHeight}
          />
        </Col>
      </FormGroup>
      <FormGroup>
        <Label for="logo">Letter Spacing</Label>
        <Col className="w-75 py-3 ms-2">
          <InputRange
            maxValue={150}
            minValue={1}
            onChange={(val) => {
              changeStyle('typography.h5.letterSpacing', `${val / 100}em`);
            }}
            value={Number.parseFloat(currentMUITheme?.typography?.h5.letterSpacing.replace('em', '')) * 100}
          />
        </Col>
      </FormGroup>
    </>
  );
}

Sections.propTypes = {
  changeStyle: PropTypes.func,
  currentMUITheme: PropTypes.shape(),
  customFonts: PropTypes.arrayOf(PropTypes.shape()),
};

Sections.defaultProps = {
  changeStyle: () => {},
  currentMUITheme: {},
  customFonts: [],
};

export default Sections;
