import React, { Fragment, useCallback, useReducer, useState } from 'react';
import PropTypes from 'prop-types';
import 'quill-emoji/dist/quill-emoji.css';
import { useDropzone } from 'react-dropzone';
import axios from 'axios';
import config from '../../../config/config';
import { getCommonHeadersNoContentType } from '@API/common-api-utils';
// import { fileExtension } from '@Base/Forms/Custom/FileUpload/UploadWrapper';
import 'react-circular-progressbar/dist/styles.css';
import { useLanguage } from '@Base/hooks';
import { attachmentsContainer } from '@JS/language/pages';
import UploadArea from './UploadArea';
import UploadProgress from './UploadProgress';

function pluralize(str, count) {
  return `${str}${count > 1 ? 's' : ''}`;
}

const UPDATE_ACTIVE_UPLOADS = 'UPDATE_ACTIVE_UPLOADS';
const UPDATE_FILES_FAILED = 'UPDATE_FILES_FAILED';
const UPDATE_FILES_SUCCESS = 'UPDATE_FILES_SUCCESS';
const RESET_STATE = 'RESET_STATE';

const UPLOAD_STATUS = {
  WAITING: 'Waiting',
  UPLOADING: 'Uploading',
  SUCCESS: 'Success',
  FAILED: 'Failed',
};

const initialState = {
  activeUploads: {},
  filesFailed: {},
  filesSuccess: {},
};

function attachmentDataReducer(state, action) {
  const { type, id, payload } = action;

  switch (type) {
    case UPDATE_ACTIVE_UPLOADS:
      return {
        ...state,
        activeUploads: {
          ...state.activeUploads,
          [id]: payload,
        },
      };
    case UPDATE_FILES_FAILED:
      return {
        ...state,
        filesFailed: {
          ...state.filesFailed,
          [id]: payload,
        },
      };
    case UPDATE_FILES_SUCCESS:
      return {
        ...state,
        filesSuccess: {
          ...state.filesSuccess,
          [id]: payload,
        },
      };
    case RESET_STATE:
      return { ...initialState };
    default:
      return state;
  }
}

function AttachmentForm(props) {
  const { applicantId, pushChange, onSuccess, onError, accept } = props;

  const [attachmentData, dispatchAttachmentData] = useReducer(attachmentDataReducer, initialState);
  const [isUploading, setIsUploading] = useState(false);
  const [totalFiles, setTotalFiles] = useState(0);
  const { langPack: languagePack } = useLanguage(attachmentsContainer);

  // eslint-disable-next-line no-unused-vars
  async function uploadFile(file, path, asObj) {
    const data = new FormData();
    data.append('file', file);

    const uploadConf = {
      headers: getCommonHeadersNoContentType(),
      onUploadProgress(progress) {
        const percentCompleted = Math.round((progress.loaded * 100) / progress.total);
        dispatchAttachmentData({
          type: UPDATE_ACTIVE_UPLOADS,
          id: [file.name],
          payload: { status: UPLOAD_STATUS.UPLOADING, percentage: percentCompleted },
        });
      },
    };
    try {
      const uploadResponse = await axios.post(config.api.middlewareAPIURL + path, data, uploadConf);

      if (uploadResponse.status === 201) {
        dispatchAttachmentData({
          type: UPDATE_ACTIVE_UPLOADS,
          id: [file.name],
          payload: { status: UPLOAD_STATUS.SUCCESS, percentage: 100 },
        });
        dispatchAttachmentData({
          type: UPDATE_FILES_SUCCESS,
          id: [file.name],
          payload: file,
        });

        return uploadResponse.data;
      }

      return false;
    } catch (error) {
      dispatchAttachmentData({
        type: UPDATE_ACTIVE_UPLOADS,
        id: [file.name],
        payload: { status: UPLOAD_STATUS.FAILED, percentage: 100 },
      });
      dispatchAttachmentData({
        type: UPDATE_FILES_FAILED,
        id: [file.name],
        payload: file,
      });

      return false;
    }
  }

  const onDrop = useCallback(
    async (acceptedFiles) => {
      dispatchAttachmentData({
        type: RESET_STATE,
      });

      setIsUploading(true);
      setTotalFiles(acceptedFiles.length);
      // let totalSize = 0;

      acceptedFiles.forEach((file) => {
        // totalSize += file.size;
        dispatchAttachmentData({
          type: UPDATE_ACTIVE_UPLOADS,
          id: [file.name],
          payload: { status: UPLOAD_STATUS.WAITING, percentage: 0 },
        });
      });

      const uploadedFiles = [];
      // eslint-disable-next-line no-restricted-syntax
      for (const file of acceptedFiles) {
        // eslint-disable-next-line no-await-in-loop
        const result = await uploadFile(file, `/applicant/file/${applicantId}`, true);
        if (typeof result === 'object') {
          uploadedFiles.push(result);
        }
      }

      if (uploadedFiles.length) {
        pushChange(uploadedFiles);

        if (uploadedFiles.length === acceptedFiles.length) {
          onSuccess(`${uploadedFiles.length} ${pluralize('attachment', uploadedFiles.length)} uploaded successfully`);
        }
      }

      if (uploadedFiles.length < acceptedFiles.length) {
        const errFiles = acceptedFiles.length - uploadedFiles.length;
        // eslint-disable-next-line max-len
        onError(
          `Error uploading ${errFiles} ${pluralize('attachment', errFiles.length)}. Please check file ${pluralize(
            'type',
            errFiles.length,
          )} and ${pluralize('size', errFiles.length)}.`,
        );
      }

      setIsUploading(false);
    },
    [applicantId, onError, onSuccess, pushChange],
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept,
    onDrop,
  });

  function getContainerClass() {
    return isDragActive ? 'dropzone-container active' : 'dropzone-container';
  }

  return (
    <>
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <div {...getRootProps({ className: getContainerClass(), 'data-cy': 'dropzone' })}>
        {!isUploading ? (
          <UploadArea inputProps={{ ...getInputProps() }} isDragActive={isDragActive} languagePack={languagePack} />
        ) : (
          <UploadProgress attachmentData={attachmentData} languagePack={languagePack} totalFiles={totalFiles} />
        )}
      </div>
      <p className="file-display">{languagePack.allowedFiles}</p>
      {Object.keys(attachmentData.filesFailed).length > 0 && (
        <>
          <p className="text-danger">The following files could not be uploaded</p>
          {Object.values(attachmentData.filesFailed).map((file, i) => (
            // eslint-disable-next-line react/no-array-index-key
            <p key={`file=${i}`} className="text-danger">
              {file.name}
            </p>
          ))}
        </>
      )}
    </>
  );
}

AttachmentForm.propTypes = {
  accept: PropTypes.shape(),
  applicantId: PropTypes.string,
  onError: PropTypes.func,
  onSuccess: PropTypes.func,
  pushChange: PropTypes.func,
};

AttachmentForm.defaultProps = {
  accept: {},
  applicantId: '',
  onError: () => {},
  onSuccess: () => {},
  pushChange: () => {},
};

export default AttachmentForm;
