import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { SectionToggle } from '@Base/SectionDividers';
import { FormGroup } from '@Base/Forms/Custom/CommonComponents';
import { EditButton } from '@Base/Buttons';
import { useMounted } from '@Base/hooks';

function S4SiteMapping({ accountAccess, siteIds, onUpdate, accountIdToSiteId }) {
  const isMounted = useMounted();
  const [siteMap, setSiteMap] = useState({});
  const [isUpdating, setIsUpdating] = useState(false);

  useEffect(() => {
    if (isMounted() && Object.keys(accountIdToSiteId).length) {
      setSiteMap((prevState) => ({ ...prevState, ...accountIdToSiteId }));
    }
  }, [accountIdToSiteId, isMounted]);

  function handleChange(accountId, siteId) {
    const updated = { ...siteMap, [accountId]: siteId };
    setSiteMap(updated);
  }

  function handleUpdate() {
    setIsUpdating(true);
    onUpdate(siteMap, () => {
      setIsUpdating(false);
    });
  }

  return (
    <Fragment>
      {!Object.keys(siteMap).length && (
        <div className="alert alert-warning">
          <p>Account site mapping is not set up</p>
        </div>
      )}
      <SectionToggle heading="Site Mapping" chevronFullRight={false}>
        {accountAccess.map((acc) => {
          const { accountId, accountName } = acc;

          return (
            <FormGroup
              key={accountId}
              row
              id={accountId}
              label={accountName}
              labelProps={{
                sm: '4',
              }}
              colProps={{
                sm: '6',
              }}
            >
              <select
                id={accountId}
                className="select"
                value={siteMap[accountId] || ''}
                onChange={(e) => {
                  handleChange(accountId, e.target.value);
                }}
              >
                <option value="" disabled>
                  Choose...
                </option>
                {siteIds.map(({ ID, name }) => (
                  <option key={ID} value={ID}>
                    {name}
                  </option>
                ))}
              </select>
            </FormGroup>
          );
        })}
        <EditButton
          className="mt-2"
          floatRight={false}
          label={`${isUpdating ? 'Updating' : 'Update'} Site Mapping`}
          action={handleUpdate}
        />
      </SectionToggle>
    </Fragment>
  );
}

S4SiteMapping.propTypes = {
  accountAccess: PropTypes.arrayOf(PropTypes.shape()),
  siteIds: PropTypes.arrayOf(PropTypes.shape()),
  onUpdate: PropTypes.func,
  accountIdToSiteId: PropTypes.shape(),
};

S4SiteMapping.defaultProps = {
  accountAccess: [],
  siteIds: [],
  onUpdate: () => {},
  accountIdToSiteId: {},
};

export default S4SiteMapping;
