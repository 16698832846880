import React, { useEffect, useState } from 'react';
import ReactTags from 'react-tag-autocomplete';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import Can from '../RBAC/Can/Can';
import TagDisplay from './TagDisplay';
import { checkPermissions } from '@JS/auth/AuthUtils';
import { useLanguage, useMounted } from '../hooks';
import { getTags, getSelectedTags, applyTags, createNewTag, sortTags } from './utils';
import { mergeArrayOfObjects } from '@JS/utils/arrayOfObjects';
import { setAllTags, addTag } from '@JS/actions/tagActions';
import { tagsList } from '@JS/language/pages';

const RestrictedReactTags = Can(ReactTags);

function blurInp() {
  const inps = document.querySelectorAll('.react-tags__search-input');
  if (inps.length) inps[0].blur();
}

function applyTag(applicantId, tagId) {
  applyTags([applicantId], {
    addTagIds: [tagId],
    onError: () => toast.error('Error applying tag.'),
  });
}

function TagContainer({ applicantId, onChange, tags, tagsLoaded, setTags, addNewTag }) {
  const isMounted = useMounted();
  const { langPack: languagePack } = useLanguage(tagsList);
  const [applicantTags, setApplicantTags] = useState([]);
  const [applicantTagsIds, setApplicantTagsIds] = useState([]);
  const [applicantTagsError, setApplicantTagsError] = useState(null);
  const [suggestionsError, setSuggestionsError] = useState(false);
  const isAllowedToCreateTags = checkPermissions(['tags:write']);

  useEffect(() => {
    if (isMounted()) {
      blurInp();

      if (!tags.length && !tagsLoaded) {
        getTags(
          (tagArr) => setTags(tagArr),
          () => setSuggestionsError(true),
        );
      }

      getSelectedTags(
        [applicantId],
        (tagArr) => {
          setApplicantTags(tagArr);
          setApplicantTagsIds(tagArr.map((tag) => tag.id));
        },
        () => setApplicantTagsError(true),
      );
    }
  }, [applicantId, applicantTagsIds.length, isMounted, setTags, tags, tagsLoaded]);

  function handleDelete(id) {
    applyTags([applicantId], {
      removeTagIds: [id],
      onError: () => toast.error('Error deleting tag, please try again.'),
      onSuccess: () => {
        const newAppTags = applicantTags.filter((tag) => tag.id !== id);
        setApplicantTags(newAppTags);
        setApplicantTagsIds(newAppTags.map((tag) => tag.id));
        onChange(newAppTags);
      },
    });
  }

  function handleChange(tag) {
    blurInp();

    const updatedTags = [...applicantTags, tag];
    setApplicantTags(updatedTags);
    setApplicantTagsIds(updatedTags.map(({ id }) => id));
    onChange(updatedTags);
  }

  function handleAddition(tag) {
    // Regular expression to check for special characters
    const invalidCharRegex = /[!#$%'()*+,/;=?@[\]"<>\\^`{|}]/g;

    if (invalidCharRegex.test(tag.name)) {
      // Toast an error if the tag name contains invalid characters for a URL
      toast.error('Tag name contains invalid characters, please remove them');
      return; // Exit the function if invalid characters are found
    }

    if (!tag.id && isAllowedToCreateTags) {
      createNewTag(
        tag.name,
        (newTag) => {
          addNewTag(newTag);
          applyTag(applicantId, newTag.id);
          handleChange(newTag);
        },
        () => toast.error('Error creating tag, please try again'),
      );
    } else {
      applyTag(applicantId, tag.id);
      handleChange(tag);
    }
  }

  function filterSuggestions(suggestion, query) {
    return !applicantTagsIds.includes(suggestion.id) && suggestion.name.toLowerCase().includes(query.toLowerCase());
  }

  if (suggestionsError && applicantTagsError) {
    return <p>{languagePack.tagFetchErrorLabel || 'Error'}</p>;
  }

  return (
    <RestrictedReactTags
      permissions={['candidate:tags']}
      tags={applicantTags}
      onDelete={() => {}}
      onAddition={handleAddition}
      suggestions={mergeArrayOfObjects(applicantTags, sortTags(tags))}
      suggestionsFilter={filterSuggestions}
      noSuggestionsText={isAllowedToCreateTags ? languagePack.noMatchesLabel || 'No Matches' : 'No Matches'}
      minQueryLength={0}
      placeholderText={languagePack.addTagLabel || '+ Add Tag'}
      tagComponent={(tagInfo) => <TagDisplay tag={tagInfo.tag} onDelete={handleDelete} />}
      maxSuggestionsLength={30}
      allowNew={isAllowedToCreateTags}
    />
  );
}

TagContainer.propTypes = {
  applicantId: PropTypes.string,
  onChange: PropTypes.func,
  tags: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      label: PropTypes.string,
    }),
  ),
  tagsLoaded: PropTypes.bool,
  setTags: PropTypes.func,
  addNewTag: PropTypes.func,
};

TagContainer.defaultProps = {
  applicantId: '',
  onChange: () => {},
  tags: [],
  tagsLoaded: false,
  setTags: () => {},
  addNewTag: () => {},
};

function mapStateToProps(state) {
  const { tags } = state;
  return {
    tags: tags.tags || [],
    tagsLoaded: tags.tagsLoaded,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setTags: (tags) => {
      dispatch(setAllTags(tags));
    },
    addNewTag: (tag) => {
      dispatch(addTag(tag));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(TagContainer);
